export const days = value => {
  let dayList = [];
  for (let i = 1; i <= value; i++) {
    if (i < 10) {
      dayList.push({ id: i, name: "0" + i.toString(), type: "day" });
    } else {
      dayList.push({ id: i, name: i.toString(), type: "day" });
    }
  }
  return dayList;
};

export const months = [
  { id: 1, name: "January", days: 31, type: "month" },
  { id: 2, name: "February", days: 28, type: "month" },
  { id: 3, name: "March", days: 31, type: "month" },
  { id: 4, name: "April", days: 30, type: "month" },
  { id: 5, name: "May", days: 31, type: "month" },
  { id: 6, name: "June", days: 30, type: "month" },
  { id: 7, name: "July", days: 31, type: "month" },
  { id: 8, name: "August", days: 31, type: "month" },
  { id: 9, name: "September", days: 30, type: "month" },
  { id: 10, name: "October", days: 31, type: "month" },
  { id: 11, name: "November", days: 30, type: "month" },
  { id: 12, name: "December", days: 31, type: "month" }
];

export const years = () => {
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear() - 2;
  let yearList = [];
  for (let i = 1; i <= 7; i++) {
    yearList.push({ id: i, name: (currentYear++).toString(), type: "year" });
  }
  return yearList;
};

export const checkLeapYear = year => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const minutes = () => {
  let minuteList = [];
  for (let i = 0; i <= 59; i += 15) {
    if (i < 10) {
      minuteList.push({ id: i, name: "0" + i.toString(), type: "minute" });
    } else {
      minuteList.push({ id: i, name: i.toString(), type: "minute" });
    }
  }
  return minuteList;
};

export const hours = () => {
  let hourList = [];
  for (let i = 1; i <= 24; i++) {
    if (i === 24) {
      hourList.push({ id: 0, name: "00", type: "hour" });
    } else if (i < 10) {
      hourList.push({ id: i, name: "0" + i.toString(), type: "hour" });
    } else {
      hourList.push({ id: i, name: i.toString(), type: "hour" });
    }
  }
  return hourList;
};
