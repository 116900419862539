export const TAGS = "tags";
export const MARKETS = "markets";
export const DELETE = "delete";
export const TABLE = "table";
export const COMPETITOR_ANAYTICS = "competitorAnalytics";
export const APPLY_RULE_PROMPT = "APPLY_RULE_PROMPT";
export const TABLE_INPUT_SUBMIT = "TABLE_INPUT_SUBMIT";
export const PRODUCT_APPROVE = "PRODUCT_APPROVE";
export const CAMPAIGN_ANALYTICS_SAVE = "CAMPAIGN_ANALYTICS_SAVE";

export const XSMALL = "xsmall";
export const SMALL = "small";
export const MEDIUM = "medium";
export const LARGE = "large";
export const XLARGE = "xlarge";
