import React, { Component } from "react";
import { connect } from "react-redux";

import { toggleScrolling, closeErrorMsg } from "../ducks/general";
import { toggleSidepanel } from "ducks/sidepanel";

import PageTop from "./PageTop";

class ViewContainer extends Component {
  render() {
    return (
      <div className="flex h-full">
        <div className="flex-1 flex flex-col">
          <div
            className="scroll-container flex-1 overflow-auto"
            onScroll={e => {
              if (e.target.classList.value.includes("scroll-container")) {
                if (e.target.scrollTop > 0 && !this.props.isScrollingView) {
                  this.props.toggleScrolling();
                } else if (
                  e.target.scrollTop === 0 &&
                  this.props.isScrollingView
                ) {
                  this.props.toggleScrolling();
                }
              }
            }}
          >
            <div className="w-full h-full relative ">
              <div className="xxl:p-4 px-4 py-2">
                {this.props.hasPageButtons ? (
                  <div
                    className={`bg-grey-lighter float-right ${!this.props
                      .topVisible && ""}`}
                  >
                    <PageTop
                      viewTitle={{
                        handleToggleTop: this.props.handleToggleTop,
                        topVisible: this.props.topVisible,
                        hasTitle:
                          this.props.viewHeaderConfig &&
                          this.props.viewHeaderConfig.title
                      }}
                      sidepanel={{
                        isOpen: this.props.isSidePanelOpen,
                        handleOpen: this.props.toggleSidepanel
                      }}
                    />
                  </div>
                ) : (
                  <div className="py-4" />
                )}
                {this.props.topVisible && (
                  <div className="flex-1 text-center">
                    <div className="xxl:text-3xl xl:text-2xl text-xl text-grey-darker font-sans tracking-small">
                      <span className="font-light">
                        {this.props.viewHeaderConfig &&
                          this.props.viewHeaderConfig.title}
                      </span>
                    </div>
                    {this.props.viewHeaderConfig &&
                      this.props.viewHeaderConfig.text && (
                        <div className="font-sans font-light text-grey-darkest xxl:text-lg xl:text-md text-sm xxl:w-2/3 xl:w-2/3 w-full mt-2">
                          {this.props.viewHeaderConfig.text}
                        </div>
                      )}
                  </div>
                )}
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSidePanelOpen: state.sidepanel.isSidePanelOpen,
    isScrollingView: state.general.isScrollingView,
    hideViewHeader: state.general.hideViewHeader,
    error: state.general.errorMsg,
    toggleMessage: state.general.toggleMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleScrolling: modal => dispatch(toggleScrolling(modal)),
    toggleSidepanel: () => dispatch(toggleSidepanel()),
    closeErrorMsg: () => dispatch(closeErrorMsg())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewContainer);
