import React from "react";

import Button from "components/Button";

export const FormContentWrapper = props => {
  return (
    <div className="h-full flex flex-col max-w-full">
      <div className="flex flex-row bg-table-grey xxl:py-6 py-4 px-4">
        <div className="flex-1 max-w-full truncate text-grey-darker xxl:font-thin font-light text-xxl xxl:text-2xl xxl:border-r-2 border-r-1 border-grey">
          {props.formConfig.formTitle}
        </div>

        <div
          className="cursor-pointer flex hover:text-red-dark text-grey-dark justify-end pl-6"
          onClick={props.handleCancel}
        >
          <i className="self-center form-exit-button material-icons xxl:text-2xl text-lg">
            close
          </i>
        </div>
      </div>

      <div className="flex-1 overflow-x-hidden overflow-y-auto max-w-full">
        <form
          className="px-4 break-words h-full max-w-full"
          onSubmit={e => {
            e.preventDefault();
          }}
          id={props.formName}
          noValidate
        >
          {props.children}
        </form>
      </div>
      {props.hasDelete && (
        <div className="w-full pin-l flex flex-row bg-white p-4">
          <span className="flex ">
            <i
              onClick={props.handleDelete}
              className="material-icons text-white bg-red cursor-pointer rounded-full h-6 w-6 flex justify-center items-center text-lg hover:bg-red-dark"
            >
              delete
            </i>
            <p className="text-red font-light pl-2">{props.deleteMessage}</p>
          </span>
        </div>
      )}

      <div
        className={` ${
          props.hasCustomFooter ? "" : "xxl:pt-4 xxl:pb-6"
        }  py-3 bg-teal-lightest w-full`}
      >
        <div className="w-full h-full bg-teal-lightest z-30 flex flex-row font-sans">
          {props.hasCustomFooter ? (
            props.customFooter
          ) : (
            <>
              <div className="flex-1 flex justify-start items-center px-2">
                <div className="cursor-pointer">
                  {props.formConfig.footerLeft}
                </div>
              </div>
              {props.formConfig.buttonLabel ? (
                <div className="flex-1 flex justify-end px-2">
                  {props.canSave ? (
                    <Button
                      form={props.formName}
                      bgColor="primary"
                      label={props.formConfig.buttonLabel}
                      type="button"
                      onClick={e => props.handleSubmit(e)}
                    />
                  ) : (
                    <Button
                      bgColor="disabled"
                      label={props.formConfig.buttonLabel}
                    />
                  )}
                </div>
              ) : (
                <div className="my-4" />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
