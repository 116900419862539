import React from "react";

export const CircleButton = props => {
  return (
    <div
      className={`xxl:w-10 xxl:h-10 h-8 w-8 flex border-none justify-center rounded-full no-outline
        ${
          props.active
            ? props.canUse
              ? "bg-white text-grey-darker-text hover:text-white hover:bg-teal-dark cursor-pointer"
              : "bg-table-grey text-grey cursor-not-allowed"
            : "bg-teal-dark text-white"
        }`}
      onClick={() => props.onClick && props.canUse && props.onClick()}
      onMouseEnter={() =>
        props.onMouseEnter && props.canUse && props.onMouseEnter(props.name)
      }
      onMouseLeave={() =>
        props.onMouseLeave && props.canUse && props.onMouseLeave(props.name)
      }
    >
      <i className="material-icons cursor-pointer self-center xxl:text-2xl text-lg">
        {props.active ? props.iconOne : props.iconTwo}
      </i>
    </div>
  );
};
