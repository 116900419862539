import React, { Component } from "react";
import { connect } from "react-redux";

import { closeModal } from "ducks/modal";
import { saveTag, addTag, removeTag, setExistingTagError } from "ducks/tags";

import { ModalContentWrapper } from "containers/Modals/ModalContainer/ModalContentWrapper";

class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagInput: "",
      newTags: [],
      addNewTag: false,
      showTags: false,
      displayExpandTag: true
    };
  }

  onChange = e => {
    this.setState({
      tagInput: e.target.value,
      addNewTag: true,
      showTags: true,
      displayExpandTag: false
    });
    if (e.target.value.length < 1) {
      this.setState({
        addNewTag: false,
        showTags: false,
        displayExpandTag: true
      });
    }
  };

  saveNewTagEnter = e => {
    let code = e.keyCode || e.which;
    if (code === 13 && e.target.value.length > 0) {
      this.setState({
        addNewTag: false,
        tagInput: "",
        displayExpandTag: true,
        showTags: false
      });
      this.props.saveTag(this.state.tagInput, this.props.data);
    }
  };

  saveNewTagClick = () => {
    this.setState({
      addNewTag: false,
      tagInput: "",
      displayExpandTag: true,
      showTags: false
    });
    this.props.saveTag(this.state.tagInput, this.props.data);
  };

  handleRemoveTag = tag => {
    this.props.removeTag(tag, this.props.data);
  };

  addTag = tag => {
    this.props.addTag(tag, this.props.data);
  };

  handleClose = () => {
    this.props.closeModal();
  };

  showTags = () => {
    this.setState(prevState => ({ showTags: !prevState.showTags }));
  };

  render() {
    const modalConfig = {
      handleClose: this.handleClose,
      buttonLabel: "done",
      titleInput: {
        value: this.state.tagInput,
        onChange: this.onChange,
        placeholder: "Find or Create a new tag here...",
        onKeyPress: this.saveNewTagEnter,
        activeTyping: this.state.addNewTag
      }
    };

    const filteredTags = this.props.tagList
      .filter(tags => !this.props.tagsByProduct.find(tag => tags.id === tag.id))
      .filter(tag =>
        tag.name.toLowerCase().includes(this.state.tagInput.toLowerCase())
      );

    const tagsByProduct = this.state.showTags
      ? this.props.tagsByProduct.filter(tag =>
          tag.name.toLowerCase().includes(this.state.tagInput.toLowerCase())
        )
      : this.state.displayExpandTag &&
        this.props.tagsByProduct
          .filter(tag =>
            tag.name.toLowerCase().includes(this.state.tagInput.toLowerCase())
          )
          .slice(0, 3);

    const existingTag = this.props.tagList.find(
      tag => tag.name === this.props.existingTag
    );
    const existingTagByProduct =
      existingTag && tagsByProduct.find(tag => tag.name === existingTag.name);

    return (
      <ModalContentWrapper modalConfig={modalConfig} size={this.props.size}>
        <div className="h-full w-full">
          {existingTag && !existingTagByProduct && (
            <div className="flex flex-col bg-table-grey font-sans font-light text-sm justify-center items-center p-2">
              <div className="text-grey text-grey-darker">
                <span className="font-medium">{existingTag.name}</span> already
                exists, would you like to use it ?
              </div>
              <div className="w-full flex flex-row justify-center mt-1">
                <div
                  className="text-red-dark hover:text-red text-xs font-medium  mx-3 cursor-pointer"
                  onClick={() => {
                    this.props.setExistingTagError(null);
                  }}
                >
                  No
                </div>

                <div
                  className="text-teal-dark hover:text-teal mx-3 font-medium text-xs cursor-pointer"
                  onClick={() => {
                    this.addTag(existingTag);
                    this.props.setExistingTagError(null);
                  }}
                >
                  Yes, use it!
                </div>
              </div>
            </div>
          )}
          {existingTag && existingTagByProduct && (
            <div className="flex flex-col bg-table-grey font-sans font-light text-sm justify-center items-center p-2">
              <div className="text-grey text-grey-darker">
                <span className="font-medium">{existingTag.name}</span> has
                already been selected.
              </div>
              <div className="w-full flex flex-row justify-center mt-1">
                <div
                  className="text-teal-dark hover:text-teal font-medium text-xs mx-3 cursor-pointer"
                  onClick={() => this.props.setExistingTagError(null)}
                >
                  OK !
                </div>
              </div>
            </div>
          )}

          <div className="pl-3">
            <div className="text-sm font-sans font-light text-grey-dark pl-3 pt-3">
              {this.props.data.length > 1 ? (
                <div className="py-4">
                  Selected Products:{" "}
                  <span className="text-teal-dark font-medium pl-1">
                    {this.props.data.length}
                  </span>{" "}
                </div>
              ) : (
                <div className="py-4">
                  Selected Product:{" "}
                  <span className="text-teal-dark font-medium pl-1">
                    {this.props.data.name
                      ? this.props.data.name
                      : this.props.data[0].name}
                  </span>
                </div>
              )}
              <span className="inline-block font-medium pr-1">
                {this.props.tagsByProduct.length}
              </span>
              Selected Tags:
            </div>
            {this.props.tagsByProduct.length < 1 &&
              this.state.tagInput.length < 1 && (
                <div className="rounded-full w-full text-grey-dark font-sans text-xs font-light mb-1 py-4 cursor-default text-center">
                  <div className="px-2 inline-block">
                    Create a new or add an existing tag
                  </div>
                </div>
              )}

            {this.state.addNewTag || this.props.tagsByProduct.length > 0 ? (
              <>
                <div className="flex flex-row flex-wrap p-2">
                  {this.state.addNewTag && (
                    <div className="bg-teal rounded-full text-white font-sans text-xs font-light px-1 mr-2 mb-2 py-1 cursor-default">
                      <div className="flex items-center">
                        <div className="px-2 inline-block">
                          <span className="pr-1">Add</span>{" "}
                          <span className="font-medium">
                            {this.state.tagInput}
                          </span>
                        </div>
                        <i
                          onClick={() => this.saveNewTagClick()}
                          className="material-icons text-sm p-1 hover:bg-teal-darker cursor-pointer rounded-full"
                        >
                          add
                        </i>
                      </div>
                    </div>
                  )}
                  {this.props.tagsByProduct.length > 0 &&
                    tagsByProduct.map((tag, i) => (
                      <div
                        key={i}
                        className="inline-block bg-teal-dark rounded-full text-white font-sans text-xs font-light px-1 mr-2 mb-2 py-1 cursor-default"
                      >
                        <div className="flex items-center">
                          <span className="font-medium pl-2">
                            {tag.amountOfProducts}
                          </span>
                          <span className="pl-4 pr-3">{tag.name}</span>
                          <i
                            onClick={() => this.handleRemoveTag(tag)}
                            className="material-icons text-sm cursor-pointer rounded-full hover:bg-teal-light p-1"
                          >
                            close
                          </i>
                        </div>
                      </div>
                    ))}
                  {this.state.displayExpandTag &&
                    this.props.tagsByProduct.length > 3 && (
                      <div
                        className={`inline-block ${
                          this.state.showTags
                            ? "bg-teal hover:bg-teal-dark"
                            : "bg-teal-dark hover:bg-teal"
                        } rounded-full text-white font-sans text-xs font-light px-1 mr-2 mb-2 cursor-default`}
                      >
                        <div className="h-full flex items-center justify-center">
                          <i
                            onClick={() => this.showTags(tagsByProduct)}
                            className="material-icons text-xl cursor-pointer rounded-full px-2 py-1"
                          >
                            more_horiz
                          </i>
                        </div>
                      </div>
                    )}
                </div>
              </>
            ) : null}
            <div className="text-sm font-sans font-light text-grey-dark pl-3">
              <span className="inline-block font-medium pr-1">
                {filteredTags.length}
              </span>{" "}
              Available Tags:
            </div>

            <div className="flex flex-row flex-wrap p-2">
              {this.props.tagList.length > 0 &&
                filteredTags.map((tag, i) => (
                  <div
                    key={i}
                    className="cursor-default inline-block bg-table-grey rounded-full text-grey-darker font-sans text-xs font-lighter px-1 mr-2 mb-2 py-1"
                  >
                    <div className="flex items-center">
                      <span className="pl-4 pr-3">{tag.name}</span>
                      <i
                        onClick={() => this.addTag(tag)}
                        className="material-icons text-sm p-1 hover:bg-teal-dark hover:text-white rounded-full cursor-pointer"
                      >
                        add
                      </i>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </ModalContentWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(closeModal()),
    saveTag: (name, product) => dispatch(saveTag(name, product)),
    addTag: (tag, product) => dispatch(addTag(tag, product)),
    removeTag: (tag, product) => dispatch(removeTag(tag, product)),
    setExistingTagError: tag => dispatch(setExistingTagError(tag))
  };
};

const mapStateToProps = state => {
  return {
    tagList: state.tags.tagList,
    tagsByProduct: state.tags.tagsByProduct,
    loadingTags: state.tags.loading,
    existingTag: state.tags.existingTag
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
