import React, { Component } from "react";
import PropTypes from "prop-types";

class NavItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  handleClick = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  };
  render() {
    const { navLink: NavLink, ...props } = this.props;
    const groupClassList =
      window.location.pathname.includes(props.item.link) ||
      (props.selectedNavItem && props.selectedNavItem === props.item.id)
        ? "bg-grey-darker nav-link px-2 font-light text-xs font-sans text-white flex hover:bg-teal-dark hover:text-white m-0 py-4"
        : "bg-grey-darker nav-link px-2 font-light text-xs font-sans text-grey-dark hover:text-white flex hover:bg-teal-dark hover:text-white m-0 py-4";

    let linkClassList = props.first
      ? "bg-grey-darker nav-link no-underline inline-block h-full flex-1 border-l-4 border-grey-darker hover:bg-teal-dark  hover:border-teal-dark border-solid"
      : "nav-link no-underline inline-block h-full flex-1 hover:bg-teal-dark bg-grey-darkest border-l-4 border-grey-darkest hover:border-teal-dark border-solid";

    linkClassList =
      props.selectedNavItem && props.selectedNavItem === props.item.id
        ? linkClassList + " text-white"
        : linkClassList + " text-grey-dark hover:text-white";

    const isActive =
      window.location.pathname.includes(props.item.link) ||
      (props.selectedNavItem && props.selectedNavItem === props.item.id);

    if (isActive) {
      linkClassList =
        "nav-link text-white no-underline inline-block h-full flex-1 hover:bg-teal-dark bg-grey-darker border-l-4 border-teal-dark border-solid";
    }

    let subItems = null;
    if (props.item.navItems) {
      subItems = props.item.navItems.map((item, index) => {
        return <NavItem key={index} item={item} navLink={NavLink} />;
      });
    }

    return (
      <li>
        {props.item.isView ? (
          <div className="h-full hover:bg-teal-dark hover:text-white flex">
            <NavLink
              to={props.item.restricted ? false : props.item.link}
              className={linkClassList}
              activeClassName={`${
                props.item.restricted ? "text-grey" : "text-white"
              }`}
            >
              <div
                className="font-sans flex flex-row font-light text-xs inline-block py-4 pl-1"
                onClick={() => {
                  this.handleClick();
                }}
              >
                <i className="self-center material-icons mr-1">
                  {props.item.icon ? props.item.icon : "multiline_chart"}
                </i>
                <div className="self-center ">{props.item.name}</div>
              </div>
            </NavLink>
          </div>
        ) : (
          <div className={groupClassList} onClick={this.handleClick}>
            <div className="flex-1 inline-block">
              <i className="material-icons align-middle mr-2">list</i>
              <span className="inline-block align-middle">
                {props.item.name}
              </span>
            </div>
            <div className="inline-block">
              {this.state.open ? (
                <i className="material-icons align-middle mr-2">expand_more</i>
              ) : (
                <i className="material-icons align-middle mr-2">
                  chevron_right
                </i>
              )}
            </div>
          </div>
        )}
        {this.state.open && (
          <ul className="list-reset overflow-hidden">{subItems}</ul>
        )}
      </li>
    );
  }
}

export default NavItem;

NavItem.propTypes = {
  item: PropTypes.object,
  first: PropTypes.bool,
  navLink: PropTypes.func,
  icon: PropTypes.string
};
