import axios from "axios";

import { setCampaignPagination } from "./tablePagination";
import { handleError } from "ducks/general";
import { closeFormpanel } from "./formpanel";
const SET_ALL_CAMPAIGNS = "SET_ALL_CAMPAIGNS";
const SET_CHANNELS = "SET_CHANNELS";
const SELECTED_CAMPAIGN_ID = "SELECTED_CAMPAIGN_ID";
const CAMPAIGNS_TABLE_LOADING = "CAMPAIGNS_TABLE_LOADING";
const CAMPAIGNS_SET_NO_DATA = "CAMPAIGNS_SET_NO_DATA";
const SET_CAMPAIGNS_DROPDOWN = "SET_CAMPAIGNS_DROPDOWN";

export const getAllCampaignsDropdown = () => {
  return (dispatch) => {
    if (localStorage.getItem("channel")) {
      return axios
        .get(
          process.env.REACT_APP_API_HOST +
            "/price-optimisation/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/campaigns"
        )
        .then((res) => {
          dispatch(setCamapignsDropdown(res.data));
        })
        .catch((err) => {
          dispatch(handleError(err));
        });
    }
  };
};

export const getAllCampaigns = (page, rowSize, attr, direction) => {
  return (dispatch) => {
    dispatch(tableLoading(true));
    dispatch(setNoData(false));
    if (localStorage.getItem("channel")) {
      let url =
        attr && direction
          ? process.env.REACT_APP_API_HOST +
            "/price-optimisation/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/campaigns/pagination?page=" +
            page +
            "&size=" +
            rowSize +
            "&sort=" +
            attr +
            "," +
            direction
          : process.env.REACT_APP_API_HOST +
            "/price-optimisation/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/campaigns/pagination?page=" +
            page +
            "&size=" +
            rowSize;
      return axios
        .get(url)
        .then((res) => {
          dispatch(setAllCampaigns(res.data));
          dispatch(
            setCampaignPagination(
              page,
              rowSize,
              res.data.totalPages,
              attr,
              direction
            )
          );
          dispatch(tableLoading(false));
          if (res.data.content.length < 1) {
            dispatch(setNoData(true));
          }
        })
        .catch((err) => {
          dispatch(handleError(err));
          dispatch(tableLoading(false));
          dispatch(setNoData(true));
        });
    }
  };
};

export const createCampaign = (data, page, rowSize, attr, direction) => {
  return (dispatch) => {
    axios
      .post(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/campaigns",
        data
      )
      .then((res) => {
        dispatch(getAllCampaigns(page, rowSize, attr, direction));
        dispatch(closeFormpanel());
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const updateCampaign = (data, page, rowSize, attr, direction) => {
  return (dispatch) => {
    axios
      .put(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/campaigns/" +
          data.id,
        data
      )
      .then((res) => {
        dispatch(getAllCampaigns(page, rowSize, attr, direction));
        dispatch(closeFormpanel());
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

// export const getChannels = (id) => {
//   return (dispatch) => {
//     axios
//       .get(
//         process.env.REACT_APP_API_HOST +
//           "/price-optimisation/markets/" +
//           localStorage.getItem("market") +
//           "/channels"
//       )
//       .then((res) => {
//         dispatch(setChannels(res.data));
//       })
//       .catch((err) => {
//         dispatch(handleError(err));
//       });
//   };
// };

export const selectedCampaign = (id) => {
  return {
    type: SELECTED_CAMPAIGN_ID,
    selectedCampaign: id,
  };
};

const setAllCampaigns = (campaigns, currentPage, rowSize) => {
  return {
    type: SET_ALL_CAMPAIGNS,
    campaigns: campaigns.content,
    totalCampaigns: campaigns.totalElements,
  };
};

const setChannels = (channels) => {
  return {
    type: SET_CHANNELS,
    channels: channels,
  };
};

const tableLoading = (value) => {
  return {
    type: CAMPAIGNS_TABLE_LOADING,
    campTableLoading: value,
  };
};

export const setNoData = (value) => {
  return {
    type: CAMPAIGNS_SET_NO_DATA,
    noData: value,
  };
};

const setCamapignsDropdown = (data) => {
  return {
    type: SET_CAMPAIGNS_DROPDOWN,
    campaignsDropdown: data,
  };
};

const initialState = {
  campaigns: [],
  campaignsDropdown: [],
  channels: [],
  totalCampaigns: null,
  selectedCampaign: null,
  campTableLoading: true,
  noData: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ALL_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.campaigns,
        totalCampaigns: action.totalCampaigns,
      };
    case SET_CHANNELS:
      return {
        ...state,
        channels: action.channels,
      };
    case SELECTED_CAMPAIGN_ID:
      return {
        ...state,
        selectedCampaign: action.selectedCampaign,
      };
    case CAMPAIGNS_TABLE_LOADING:
      return {
        ...state,
        campTableLoading: action.campTableLoading,
      };
    case CAMPAIGNS_SET_NO_DATA:
      return {
        ...state,
        noData: action.noData,
      };
    case SET_CAMPAIGNS_DROPDOWN:
      return {
        ...state,
        campaignsDropdown: action.campaignsDropdown.filter(
          (campaign) => campaign.type !== "Auto"
        ),
      };
    default:
      return state;
  }
}
