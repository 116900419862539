import axios from "axios";

const PRODUCT_PERFORMACE_SET_CONFIG = "PRODUCT_PERFORMACE_SET_CONFIG";
const PRODUCT_PERFORMACE_SET_ATTRIBUTES = "PRODUCT_PERFORMACE_SET_ATTRIBUTES";
const PRODUCT_PERFORMACE_SET_NO_DATA = "PRODUCT_PERFORMACE_SET_NO_DATA";
const PRODUCT_PERFORMACE_SET_DATA = "PRODUCT_PERFORMACE_SET_DATA";
const PRODUCT_PERFORMACE_CLEAR_DATA = "PRODUCT_PERFORMACE_CLEAR_DATA";
const SET_TALBE_LOADING_PRODUCT_PERFORMANCE =
  "SET_TALBE_LOADING_PRODUCT_PERFORMANCE";
const SET_PAGE_PRODUCT_PERFORMANCE = "SET_PAGE_PRODUCT_PERFORMANCE";
const SET_ROW_SIZE_PRODUCT_PERFORMANCE = "SET_ROW_SIZE_PRODUCT_PERFORMANCE";

export const getDropdownOptions = () => {
  return (dispatch) => {
    dispatch(
      setAttributes([
        { id: 1, name: "Brand" },
        { id: 2, name: "Category" },
        // { id: 3, name: "EAN" },
      ])
    );
    dispatch(
      setConfig({
        operators: [
          { id: 1, name: ">" },
          { id: 2, name: "<" },
          { id: 3, name: ">=" },
          { id: 4, name: "<=" },
          { id: 5, name: "=" },
          { id: 6, name: "!=" },
        ],
        dates: [
          { id: 1, name: "1 Day", code: 1 },
          { id: 2, name: "3 Days", code: 3 },
          { id: 3, name: "7 Days", code: 7 },
          { id: 4, name: "14 Days", code: 14 },
        ],
      })
    );
  };
};

export const getProductPerformance = (idList, rowSize) => {
  return (dispatch) => {
    dispatch(clearAllProductsPerformance());

    dispatch(setNoDataProductPerformance(false));
    dispatch(tableLoadingProductPerformance(true));
    return axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/product/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/product-performance?" +
          idList
      )
      .then((res) => {
        dispatch(setProductPerformance(idList, res.data, rowSize));
        dispatch(tableLoadingProductPerformance(false));
        dispatch(setNoDataProductPerformance(false));

        if (res.data.length < 1) {
          dispatch(setNoDataProductPerformance(true));
        }
      })
      .catch((err) => {
        console.log(err);

        dispatch(tableLoadingProductPerformance(false));
        dispatch(setNoDataProductPerformance(true));
      });
  };
};

const setConfig = (config) => {
  return {
    type: PRODUCT_PERFORMACE_SET_CONFIG,
    config: config,
  };
};

const setAttributes = (attributes) => {
  return {
    type: PRODUCT_PERFORMACE_SET_ATTRIBUTES,
    attributes: attributes,
  };
};

const setProductPerformance = (
  queryString,
  productPerformanceData,
  rowSize
) => {
  const split = (input, output = []) => {
    if (!input.length) return output;
    output.push(input.slice(0, rowSize));
    return split(input.slice(rowSize), output);
  };
  let paginateData = split(productPerformanceData);
  return {
    type: PRODUCT_PERFORMACE_SET_DATA,
    queryString: queryString,
    productPerformanceData: productPerformanceData,
    rowSize,
    paginateData,
  };
};

const tableLoadingProductPerformance = (value) => {
  return {
    type: SET_TALBE_LOADING_PRODUCT_PERFORMANCE,
    tableLoading: value,
  };
};

const setNoDataProductPerformance = (value) => {
  return {
    type: PRODUCT_PERFORMACE_SET_NO_DATA,
    productPerformanceNoData: value,
  };
};

export const clearAllProductsPerformance = () => {
  return {
    type: PRODUCT_PERFORMACE_CLEAR_DATA,
  };
};

export const setCurretPageClientSide = (page) => {
  return {
    type: SET_PAGE_PRODUCT_PERFORMANCE,
    page,
  };
};

export const setRowRize = (rowSize, data) => {
  const split = (input, output = []) => {
    if (!input.length) return output;
    output.push(input.slice(0, rowSize));
    return split(input.slice(rowSize), output);
  };
  let paginateData = split(data);
  return {
    type: SET_ROW_SIZE_PRODUCT_PERFORMANCE,
    productPerformancePages: paginateData,
    rowSize,
  };
};

const initialState = {
  productPerformanceData: [],
  queryString: null,
  productPerformanceNoData: true,
  productPerformanceLoading: false,
  config: {
    operators: [],
    dates: [],
  },
  attributes: [],
  tableLoading: false,
  productPerformancePages: [],
  productPerformancePaginationData: [],
  totalProductPerformance: null,
  rowSize: 20,
  currentPage: 0,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PRODUCT_PERFORMACE_SET_CONFIG:
      return {
        ...state,
        config: action.config,
      };
    case PRODUCT_PERFORMACE_SET_ATTRIBUTES:
      return {
        ...state,
        attributes: action.attributes,
      };
    case PRODUCT_PERFORMACE_SET_DATA:
      let productPerformanceData = [...action.productPerformanceData];
      productPerformanceData.map((product, i) => {
        product.id = i;
      });
      return {
        ...state,
        productPerformanceData: action.productPerformanceData,
        queryString: action.queryString,
        productPerformancePages: action.paginateData,
        productPerformancePaginationData: action.paginateData[0],
        totalProductPerformance: action.productPerformanceData.length,
        rowSize: action.rowSize,
        currentPage: 0,
      };
    case PRODUCT_PERFORMACE_SET_NO_DATA:
      return {
        ...state,
        productPerformanceNoData: action.productPerformanceNoData,
      };

    case PRODUCT_PERFORMACE_CLEAR_DATA:
      return {
        ...state,
        productPerformanceData: [],
        queryString: null,
        productPerformanceNoData: true,
        productPerformanceLoading: false,
      };
    case SET_TALBE_LOADING_PRODUCT_PERFORMANCE:
      return {
        ...state,
        productPerformanceLoading: action.tableLoading,
      };
    case SET_PAGE_PRODUCT_PERFORMANCE:
      return {
        ...state,
        productPerformancePaginationData:
          state.productPerformancePages[action.page],
        currentPage: action.page,
      };
    case SET_ROW_SIZE_PRODUCT_PERFORMANCE:
      return {
        ...state,
        productPerformancePages: action.productPerformancePages,
        productPerformancePaginationData: action.productPerformancePages[0],
        rowSize: action.rowSize,
      };
    default:
      return state;
  }
}
