import React, { Component } from "react";

class MenuItemChannel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClick = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  render() {
    let sorting = this.props.data.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    const groupClassList =
      this.props.selectedChannel &&
      this.props.selectedChannel === this.props.data.id
        ? "bg-teal-dark cursor-pointer px-2 font-light text-xs font-sans text-white flex flex-row hover:bg-teal m-0 h-10"
        : "bg-teal-dark cursor-pointer px-2 font-light text-xs font-sans text-white flex flex-row hover:bg-teal m-0 h-10";
    return (
      <>
        <div className={groupClassList} onClick={this.handleClick}>
          <div className="flex-1 h-full flex flex-row items-center">
            <span className="inline-block">Change Channel</span>
          </div>
          <div className="h-full flex flex-row items-center">
            {this.state.open ? (
              <i className="material-icons self-center mr-2">expand_more</i>
            ) : (
              <i className="material-icons self-center mr-2">chevron_right</i>
            )}
          </div>
        </div>

        {this.state.open && (
          <ul className="list-reset overflow-hidden">
            {sorting &&
              sorting.map((data) => {
                return (
                  <div
                    key={data.id}
                    className={`${
                      this.props.selectedChannel &&
                      this.props.selectedChannel.toString() ===
                        data.id.toString()
                        ? "bg-teal"
                        : "bg-grey-darker hover:bg-teal-dark"
                    } font-light font-sans p-2 text-xs`}
                    onClick={() =>
                      this.props.setSelectedChannel(
                        localStorage.getItem("market"),
                        localStorage.getItem("name"),
                        data.domain,
                        localStorage.getItem("country"),
                        localStorage.getItem("features"),
                        localStorage.getItem("channels"),
                        data.id
                      )
                    }
                  >
                    {data.name}
                  </div>
                );
              })}
          </ul>
        )}
      </>
    );
  }
}

export default MenuItemChannel;
