import React, { Component } from "react";
import { connect } from "react-redux";
import UserSettings from "components/UserSettings";
import ViewContainer from "containers/ViewContainer";
import {
  setUserDetails,
  updatePassword,
  updatePasswordResponse,
  addUser,
  updateAddUserResponse,
  getUserGroups,
  getAllUsers,
  updateUsers,
  updateUserDetails,
  updateUserDetailsResponse,
  addRemoveInviteUser,
  handleInviteUserOnChange,
  handleInviteUserGroupSelect,
  wipeAllSettings
} from "ducks/settings";

class Settings extends Component {
  componentDidMount() {
    this.props.getUserGroups();
    this.props.setUserDetails();
  }

  componentWillUnmount() {
    this.props.wipeAllSettings();
  }

  render() {
    return (
      <ViewContainer hasPageButtons={true}>
        <UserSettings
          isAdmin={localStorage.getItem("features").includes("ManageUsers")}
          profile={this.props.userDetails}
          updateUserDetails={this.props.updateUserDetails}
          updateUserDetailsResponse={this.props.updateUserDetailsResponse}
          userDetailsResponse={this.props.userDetailsResponse}
          changePassword={this.props.updatePassword}
          passwordResponse={this.props.passwordResponse}
          updatePasswordResponse={this.props.updatePasswordResponse}
          addUser={this.props.addUser}
          addUserResponse={this.props.addUserResponse}
          updateAddUserResponse={this.props.updateAddUserResponse}
          userGroups={this.props.userGroups}
          getAllUsers={this.props.getAllUsers}
          usersList={this.props.userList}
          updateUsers={this.props.updateUsers}
          successfullUserInviteList={
            this.props.successfullUserInviteList.length > 0
              ? this.props.successfullUserInviteList
              : []
          }
          inviteUsers={this.props.inviteUsers}
          addRemoveInviteUser={this.props.addRemoveInviteUser}
          handleInviteUserOnChange={this.props.handleInviteUserOnChange}
          handleInviteUserGroupSelect={this.props.handleInviteUserGroupSelect}
        />
      </ViewContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    userDetails: state.settings.userDetails,
    userDetailsResponse: state.settings.userDetailsResponse,
    passwordResponse: state.settings.passwordResponse,
    addUserResponse: state.settings.addUserResponse,
    userGroups: state.settings.userGroups,
    userList: state.settings.userList,
    successfullUserInviteList: state.settings.successfullUserInviteList,
    inviteUsers: state.settings.inviteUsers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    wipeAllSettings: () => dispatch(wipeAllSettings()),
    setUserDetails: () => dispatch(setUserDetails()),
    updateUserDetails: (id, values, email) =>
      dispatch(updateUserDetails(id, values, email)),
    updateUserDetailsResponse: value =>
      dispatch(updateUserDetailsResponse(value)),
    updatePassword: (currentPassword, newPassword) =>
      dispatch(updatePassword(currentPassword, newPassword)),
    updatePasswordResponse: value => dispatch(updatePasswordResponse(value)),
    addUser: users => dispatch(addUser(users)),
    updateAddUserResponse: value => dispatch(updateAddUserResponse(value)),
    getUserGroups: () => dispatch(getUserGroups()),
    getAllUsers: () => dispatch(getAllUsers()),
    updateUsers: (user, value) => dispatch(updateUsers(user, value)),
    addRemoveInviteUser: (handler, index) =>
      dispatch(addRemoveInviteUser(handler, index)),
    handleInviteUserOnChange: (event, name, index) =>
      dispatch(handleInviteUserOnChange(event, name, index)),
    handleInviteUserGroupSelect: (event, name, index) =>
      dispatch(handleInviteUserGroupSelect(event, name, index))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
