import React from "react";
export default () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="clip-path">
        <circle
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="22.5"
          cy="22.5"
          r="22.5"
          transform="translate(382 318)"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g
      id="Mask_Group_1"
      data-name="Mask Group 1"
      transform="translate(-382 -318)"
      clip-path="url(#clip-path)"
    >
      <rect
        id="Rectangle_1"
        data-name="Rectangle 1"
        width="47"
        height="16"
        transform="translate(381 332)"
        fill="#fff"
      />
      <rect
        id="Rectangle_2"
        data-name="Rectangle 2"
        width="47"
        height="16"
        transform="translate(381 316)"
        fill="#ae1c28"
      />
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="47"
        height="16"
        transform="translate(381 348)"
        fill="#21468b"
      />
    </g>
  </svg>
);
