import React from "react";
export default () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 25V43.7197C17.1968 44.4962 19.5501 44.9411 22 44.9946V25H44.8627C44.9534 24.1792 45 23.345 45 22.5C45 20.9589 44.8451 19.454 44.5499 18H22V0.00544739C19.5501 0.0588617 17.1968 0.503843 15 1.28029V18H0.450082C0.154936 19.454 0 20.9589 0 22.5C0 23.345 0.0465833 24.1792 0.137322 25H15Z"
      fill="#002F6C"
    />
    <path
      d="M22 25H44.8627C43.6191 36.2499 34.0814 45 22.5 45C22.3329 45 22.1662 44.9982 22 44.9946V25Z"
      fill="white"
    />
    <path
      d="M22 18H44.5499C42.4652 7.72992 33.3853 0 22.5 0C22.3329 0 22.1662 0.00182298 22 0.00544739V18Z"
      fill="white"
    />
    <path
      d="M15 1.28029V18H0.450089C2.03116 10.2111 7.63577 3.88318 15 1.28029Z"
      fill="white"
    />
    <path
      d="M15 25V43.7197C7.01485 40.8974 1.09855 33.6954 0.137329 25H15Z"
      fill="white"
    />
  </svg>
);
