import React, { Component } from "react";
import ModifiedMultiSelectDropdown from "components/MultiSelectDropdown/ModifiedMultiSelectDropdown";
import LineChart from "components/Charts/LineChart";
import BarChart from "components/Charts/BarChart";
import PieChart from "components/Charts/PieChart";
import { SmallLoadingSpinner } from "components/SmallLoadingSpinner/SmallLoadingSpinner";
import { TooltipLeft } from "components/Tooltips";

class Widget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openFilter: false,
      colorList: [
        "pink-light",
        "blue-light",
        "orange-light",
        "green-light",
        "red-light",
      ],
      newColorArray: [],
      activeFilter: [],
      showTooltop: false,
    };
  }

  componentWillMount() {
    this.props.getData(this.props.config);
    this.setState({
      newColorArray: [],
    });
  }

  triggerGetDateOnChange = () => {
    this.props.getData(this.props.config);
  };

  handleSelected = (name, selected, item) => {
    this.props.handleSelected(name, selected);
  };

  applyFilter = () => {
    this.props.getData(this.props.config);
  };

  render() {
    let labels = this.props.chartData && this.props.chartData.labels;
    let notShowingData =
      this.props.chartData &&
      this.props.selected.filter((item) => !labels.includes(item.name));
    let showingData = this.props.chartData && this.props.chartData.labels;

    return (
      <div className="flex flex-col h-full w-full">
        <div className="flex flex-row w-full justify-between px-2 text-grey-dark  relative py-4">
          <span className="w-full whitespace-no-wrap">
            {this.props.displayName}
          </span>

          <ModifiedMultiSelectDropdown
            name={this.props.name}
            width="w-full"
            options={this.props.options}
            selected={this.props.selected}
            handleSelect={this.handleSelected}
            placeholder={this.props.placeholder}
            applyFilter={this.applyFilter}
          />
        </div>
        <hr />
        <div className="flex flex-row flex-wrap pt-2 h-10 mb-4">
          {this.props.loadingData
            ? null
            : showingData &&
              showingData.map((item, i) => {
                return (
                  <span className="flex self-center whitespace-no-wrap ">
                    <span
                      className={`bg-${this.state.colorList[i]} rounded-full h-2 w-2 self-center mx-1`}
                    ></span>
                    <p className="text-xs text-grey">{item}</p>
                  </span>
                );
              })}
          {notShowingData &&
            notShowingData.map((item, i) => {
              return (
                <span
                  onMouseEnter={() =>
                    this.setState({ showTooltop: { [i]: true } })
                  }
                  onMouseLeave={() =>
                    this.setState({ showTooltop: { [i]: false } })
                  }
                  className="flex self-center whitespace-no-wrap relative"
                >
                  <span
                    className={`bg-grey rounded-full h-2 w-2 self-center mx-1`}
                  ></span>
                  <p className="text-xs text-grey">{item.name}</p>
                  {this.state.showTooltop && this.state.showTooltop[i] && (
                    <div className="absolute z-20 right-0 -mr-24 -mt-2">
                      <TooltipLeft text="no data available" />
                    </div>
                  )}
                </span>
              );
            })}
        </div>
        <div className=" flex flex-row items-end h-full">
          {this.props.chartData === null ? (
            <h1 className="text-grey h-full w-full flex flex-row justify-center items-center">
              No Data
            </h1>
          ) : this.props.loadingData ? (
            <div className="h-full w-full flex items-center justify-center">
              {" "}
              {SmallLoadingSpinner}{" "}
            </div>
          ) : (
            <>
              {this.props.chartType === "line" && (
                <LineChart
                  data={this.props.chartData ? this.props.chartData : []}
                  labels={showingData.length > 0 ? showingData : []}
                />
              )}
              {this.props.chartType === "bar" && (
                <BarChart
                  activeFilter={this.state.activeFilter}
                  data={this.props.chartData ? this.props.chartData : []}
                />
              )}
              {this.props.chartType === "pie" && (
                <PieChart
                  data={this.props.chartData ? this.props.chartData : []}
                  labels={showingData.length > 0 ? showingData : []}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Widget;
