import React from "react";
export const Button = props => {
  let specificOptions;
  switch (props.bgColor) {
    case "primary":
      specificOptions =
        "cursor-pointer rounded-sm bg-teal-dark hover:bg-teal tracking-small uppercase";
      break;
    case "secondary":
      specificOptions =
        "cursor-pointer rounded-sm bg-red hover:bg-red-dark tracking-small uppercase";
      break;
    case "disabled":
      specificOptions =
        "rounded-sm cursor-not-allowed bg-grey-light tracking-small uppercase";
      break;
    default:
      specificOptions =
        "cursor-pointer rounded-sm bg-teal-dark hover:bg-teal tracking-small uppercase";
  }
  const { form, type, onClick, label } = props;
  return (
    <div>
      <button
        form={form}
        type={type}
        onClick={onClick}
        className={`${
          props.smallButton
            ? " h-8 text-xs px-2"
            : "px-3  h-10 xxl:text-sm text-xs xxl:font-light"
        } border-none outline-none text-white uppercase ${specificOptions}`}
      >
        {label}
      </button>
    </div>
  );
};

export default Button;
