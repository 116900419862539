import React from "react";

const QueryDropdown = (props) => {
  let keyCap = props.keyVal.charAt(0).toLowerCase() + props.keyVal.slice(1);
  let keyName = keyCap.replace(/\s/g, "");
  let item =
    props.keyVal.length > 0 &&
    props.filterKeyOptions.find((item) =>
      item.name.toLowerCase().includes(keyName.toLowerCase())
    );
  let filteredQueryOptions =
    props.value.length > 0
      ? // ? props.queryOptions.length > 0
        props.queryOptions.filter((item) =>
          item.name.toLowerCase().includes(props.value.toLowerCase())
        )
      : // : []
        props.queryOptions;

  return (
    <ul
      className={`shadow-md xxl:max-h-15 max-h-10 rounded-lg overflow-y-auto overflow-x-hidden mt-10 px-0 border-1 border-solid border-grey-lighter absolute z-30 min-w-10 max-w-10
      `}
    >
      <span className="relative flex">
        {item.type === "interval" &&
          filteredQueryOptions.length < 1 &&
          props.value.length > 0 && (
            <i
              onClick={() =>
                props.handlePriceQuery(props.indexEdit, props.value)
              }
              className={`
              bg-${props.colorList[item.id]}er
             material-icons flex self-center text-white items-center h-full z-20 absolute right-0 pin-top cursor-pointer`}
            >
              add
            </i>
          )}
        <input
          autofocus
          onKeyDown={(e) =>
            props.handleKeyDown(props.indexEdit, e, filteredQueryOptions)
          }
          onChange={props.handleChange}
          value={props.value}
          className={`bg-${
            props.colorList[item.id]
          } placeholder-white w-full text-white
         font-light focus:border-none focus:outline-none py-3 pl-2 `}
          name={props.name}
          type="text"
          placeholder={
            item.type === "interval"
              ? filteredQueryOptions.length < 1
                ? "Enter " + props.keyVal
                : " "
              : " "
          }
        />
      </span>
      {filteredQueryOptions.map((option, i) => {
        let nameCap =
          option.name.charAt(0).toUpperCase() + option.name.slice(1);
        let name = nameCap.replace(/([a-z])([A-Z])/g, "$1 $2");
        return (
          <li
            key={i}
            className={`${
              props.cursor === i ? "bg-" + props.colorList[item.id] + "er" : " "
            }${
              "bg-" + props.colorList[item.id]
            } cursor-pointer py-3  px-6 hover:bg-${
              props.colorList[item.id]
            }er text-white relative border-t-1 border-grey-lighter text-center`}
            onClick={
              item.type === "interval"
                ? () => props.handlePriceQuery(props.indexEdit, option)
                : () => props.handleGroupQuery(props.indexEdit, option)
            }
          >
            {name}
          </li>
        );
      })}
    </ul>
  );
};

export default QueryDropdown;
