import React from "react";

//TODO: ADD LEFT, TOP AND BOTTOM VERSIONS - THIS REFERS TO THE DIRECTION OF THE ARROW
export const TooltipRight = (props) => {
  return (
    <div className="bg-grey-darker xxl:text-xs text-xxs font-sans text-white shadow-md min-w-full">
      <div className="relative flex w-full">
        <div className="flex-1 self-center text-center px-3 py-2 uppercase">
          {props.text}
        </div>
        <div className="w-4 absolute pin-r -mr-4 h-full flex">
          <div className="tooltip-triangle-right self-center"></div>
        </div>
      </div>
    </div>
  );
};

export const TooltipLeft = (props) => {
  return (
    <div className="bg-grey-darker xxl:text-xs text-xxs font-sans text-white shadow-md min-w-full">
      <div className="relative flex w-full">
        <div className="flex-1 self-center text-center px-3 py-2 uppercase">
          {props.text}
        </div>
        <div className="w-4 absolute pin-l -ml-2 h-full flex">
          <div className="tooltip-triangle-left self-center"></div>
        </div>
      </div>
    </div>
  );
};
