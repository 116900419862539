import React from "react";

const SingleDropdown = (props) => {
  let keyCap = props.keyVal.charAt(0).toLowerCase() + props.keyVal.slice(1);
  let keyName = keyCap.replace(/\s/g, "");
  let item =
    props.keyVal.length > 0 &&
    props.filterKeyOptions.find((item) => item.name.includes(keyName));

  let noDate =
    props.options && props.options.length > 0
      ? props.options.filter((item) => {
          if (
            props.filterValues.length > 0 &&
            Object.keys(props.filterValues)
              .map((key) => "Date" in props.filterValues[key])
              .includes(true)
          ) {
            return !item.name.includes("Date");
          } else return item;
        })
      : [];

  let filteredOptions =
    props.value.length > 0
      ? noDate.length > 0
        ? noDate.filter((item) =>
            item.name.toLowerCase().includes(props.value.toLowerCase())
          )
        : []
      : noDate;

  return (
    <ul
      className={`shadow-md bg-white text-grey xxl:max-h-15 max-h-10 rounded-lg overflow-y-auto overflow-x-hidden mt-10 px-0 border-1 border-solid border-grey-lighter absolute z-30 min-w-10 max-w-10`}
    >
      <input
        autofocus="true"
        onKeyDown={(e) =>
          props.handleKeyDown(props.indexEdit, e, filteredOptions, item)
        }
        onChange={props.handleChange}
        value={props.value}
        className={`${
          props.keyVal.length > 0 &&
          "bg-" +
            props.colorList[item.id] +
            " placeholder-white w-full text-white"
        } font-light focus:border-none focus:outline-none py-3 pl-2 `}
        name={props.name}
        type="text"
      />
      {filteredOptions.map((option, i) => {
        let nameCap =
          option.name.charAt(0).toUpperCase() + option.name.slice(1);
        let name = nameCap.replace(/([a-z])([A-Z])/g, "$1 $2");
        return (
          <li
            key={i}
            className={`${
              props.keyVal.length > 0
                ? "text-white bg-" + props.colorList[item.id]
                : props.filterValues.some((obj) => obj.hasOwnProperty(name))
                ? "text-grey bg-grey-dark"
                : "bg-white text-grey"
            } ${
              props.cursor === i
                ? "bg-" + props.colorList[item.id] + "er text-white"
                : " "
            }  ${
              props.filterValues.some((obj) => obj.hasOwnProperty(name))
                ? "bg-grey-light cursor-not-allowed"
                : ""
            } cursor-pointer py-3  px-6 hover:bg-${
              props.keyVal.length > 0
                ? props.colorList[item.id] + "er"
                : "teal-dark"
            } hover:text-white relative border-t-1 border-grey-lighter text-center`}
            onClick={
              props.filterValues.some((obj) => obj.hasOwnProperty(name))
                ? null
                : () => props.handleSelect(props.indexEdit, option)
            }
          >
            <span
              className={`${
                props.keyVal.length < 1
                  ? "bg-" + props.colorList[option.id]
                  : props.filterValues.some((obj) => obj.hasOwnProperty(name))
                  ? "bg-grey-dark"
                  : " "
              } absolute w-1 h-full self-center left-0 top-0`}
            ></span>
            {name}
          </li>
        );
      })}
    </ul>
  );
};

export default SingleDropdown;
