import axios from "axios";
import { handleError } from "./general";
const EXPORT_PRODUCTS = "EXPORT_PRODUCTS";
const PRODUCTS_LOADING = "PRODUCTS_LOADING";
const EXPORT_PRICE_POSITION = "EXPORT_PRICE_POSITION";
const PRICE_POSITION_LOADING = "PRICE_POSITION_LOADING";

export const getExportProducts = () => {
  return (dispatch) => {
    dispatch(setProductsLoading(true));
    const method = "GET";
    const url =
      process.env.REACT_APP_API_HOST +
      "/product/markets/" +
      localStorage.getItem("market") +
      "/channels/" +
      localStorage.getItem("channel") +
      "/products/prices/export";
    return axios
      .request({ url, method, responseType: "blob" })
      .then((res) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "export.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch(exportProducts(res));
        dispatch(setProductsLoading(false));
      })
      .catch((err) => {
        dispatch(handleError(err));
        dispatch(setProductsLoading(false));
      });
  };
};
export const getExportPricePosition = (query) => {
  return (dispatch) => {
    dispatch(setPricePositionLoading(true));
    const method = "GET";
    let url = query
      ? process.env.REACT_APP_API_HOST +
        "/price-optimisation/markets/" +
        localStorage.getItem("market") +
        "/channels/" +
        localStorage.getItem("channel") +
        "/competitor-analytics/price-position/export?" +
        query.substr(1)
      : process.env.REACT_APP_API_HOST +
        "/price-optimisation/markets/" +
        localStorage.getItem("market") +
        "/channels/" +
        localStorage.getItem("channel") +
        "/competitor-analytics/price-position/export";

    return axios
      .request({ url, method, responseType: "blob" })
      .then((res) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "export.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch(exportPricePosition(res));
        dispatch(setPricePositionLoading(false));
      })
      .catch((err) => {
        dispatch(handleError(err));
        dispatch(setPricePositionLoading(false));
      });
  };
};

const exportProducts = (res) => {
  return {
    type: EXPORT_PRODUCTS,
    prodctRes: res,
  };
};

const setProductsLoading = (value) => {
  return {
    type: PRODUCTS_LOADING,
    productLoading: value,
  };
};
const exportPricePosition = (res) => {
  return {
    type: EXPORT_PRICE_POSITION,
    prodctRes: res,
  };
};

const setPricePositionLoading = (value) => {
  return {
    type: PRICE_POSITION_LOADING,
    pricePositionLoading: value,
  };
};

const initialState = {
  prodctRes: null,
  productLoading: false,
  pricePositionRes: null,
  pricePositionLoading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case EXPORT_PRODUCTS:
      return {
        ...state,
        prodctRes: action.prodctRes,
      };
    case PRODUCTS_LOADING:
      return {
        ...state,
        productLoading: action.productLoading,
      };
    case EXPORT_PRICE_POSITION:
      return {
        ...state,
        pricePositionRes: action.pricePositionRes,
      };
    case PRICE_POSITION_LOADING:
      return {
        ...state,
        pricePositionLoading: action.pricePositionLoading,
      };
    default:
      return state;
  }
}
