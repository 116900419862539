import axios from "axios";

import { setPriceRulePagination } from "./tablePagination";
import { handleError } from "ducks/general";
const GET_PRICE_RULES = "GET_PRICE_RULES";
const TOGGLE_SHOW_ALL_DATA = "TOGGLE_SHOW_ALL_DATA";
const SET_COMPETITORS = "SET_COMPETITORS";
const PRICE_RULE_TABLE_LOADING = "PRICE_RULE_TABLE_LOADING";
const CLEAR_ALL_PRICE_RULES = "CLEAR_ALL_PRICE_RULES";
const SET_CONFIG = "SET_CONFIG";
const PRICE_RULE_SET_NO_DATA = "PRICE_RULE_SET_NO_DATA";
const SET_PREVIEW = "SET_PREVIEW";
const SET_NO_DATA_PREVIEW = "SET_NO_DATA_PREVIEW";
const LOAD_PREVIEW = "LOAD_PREVIEW";
const SET_WARNINGS_PRICE_RULE = "SET_WARNINGS_PRICE_RULE";
const REMOVE_WARNINGS = "REMOVE_WARNINGS";

//  GET PRICE RULES
export const getAllPriceRules = (page, rowSize, attr, direction) => {
  return (dispatch) => {
    dispatch(clearAllPriceRules());
    dispatch(tableLoading(true));
    dispatch(setNoData(false));
    if (localStorage.getItem("channel")) {
      let url =
        attr && direction
          ? process.env.REACT_APP_API_HOST +
            "/price-optimisation/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/price-rules/pagination?page=" +
            page +
            "&size=" +
            rowSize +
            "&sort=" +
            attr +
            "," +
            direction
          : process.env.REACT_APP_API_HOST +
            "/price-optimisation/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/price-rules/pagination?page=" +
            page +
            "&size=" +
            rowSize;
      return axios
        .get(url)
        .then((res) => {
          dispatch(setAllPriceRules(res.data));
          dispatch(
            setPriceRulePagination(
              page,
              rowSize,
              res.data.totalPages,
              attr,
              direction
            )
          );
          dispatch(showAllData(true));
          dispatch(tableLoading(false));
          if (res.data.content.length < 1) {
            dispatch(setNoData(true));
          }
        })
        .catch((err) => {
          dispatch(handleError(err));
          dispatch(tableLoading(false));
          dispatch(setNoData(true));
        });
    }
  };
};

export const getPriceRulesByCampaignId = (
  id,
  page,
  rowSize,
  attr,
  direction
) => {
  return (dispatch) => {
    dispatch(clearAllPriceRules());
    dispatch(tableLoading(true));
    dispatch(setNoData(false));
    let url =
      attr && direction
        ? process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/campaigns/" +
          id +
          "/price-rules/pagination?page=" +
          page +
          "&size=" +
          rowSize +
          "&sort=" +
          attr +
          "," +
          direction
        : process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/campaigns/" +
          id +
          "/price-rules/pagination?page=" +
          page +
          "&size=" +
          rowSize;
    return axios
      .get(url)
      .then((res) => {
        dispatch(setAllPriceRules(res.data));
        dispatch(
          setPriceRulePagination(
            page,
            rowSize,
            res.data.totalPages,
            attr,
            direction
          )
        );
        dispatch(showAllData(false));
        dispatch(tableLoading(false));
        if (res.data.content.length < 1) {
          dispatch(setNoData(true));
        }
      })
      .catch((err) => {
        dispatch(tableLoading(false));
        dispatch(handleError(err));
        dispatch(setNoData(true));
      });
  };
};

export const updatePriceRule = (updRule, page, rowSize, attr, direction) => {
  return (dispatch) => {
    return axios
      .put(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/price-rules/" +
          updRule.id,
        updRule
      )
      .then((res) => {
        dispatch(getAllPriceRules(page, rowSize, attr, direction));
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const addNewPriceRule = (newRule, page, rowSize, attr, direction) => {
  return (dispatch) => {
    return axios
      .post(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/price-rules",
        newRule
      )
      .then((res) => {
        dispatch(getAllPriceRules(page, rowSize, attr, direction));
      })
      .catch((err) => dispatch(handleError(err)));
  };
};

export const getAllCompetitors = () => {
  return (dispatch) => {
    if (localStorage.getItem("market") && localStorage.getItem("channel")) {
      return axios
        .get(
          process.env.REACT_APP_API_HOST +
            "/price-optimisation/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/competitors"
        )
        .then((res) => dispatch(setCompetitors(res.data)))
        .catch((err) => dispatch(handleError(err)));
    }
  };
};

export const getConfig = () => {
  return (dispatch) => {
    return axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/price-rules/options"
      )
      .then((res) => dispatch(setConfig(res.data)))
      .catch((err) => dispatch(handleError(err)));
  };
};

export const getPreview = (data) => {
  return (dispatch) => {
    dispatch(setNoDataPreview(false));
    dispatch(loadingPreviewData(true));
    axios
      .post(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/price-rules/preview",
        data
      )
      .then((res) => {
        dispatch(loadingPreviewData(false));

        dispatch(setPreview(res.data));
        dispatch(setNoDataPreview(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(loadingPreviewData(false));
        dispatch(setNoDataPreview(true));
      });
  };
};

export const deletePriceRule = (remove, page, rowSize, attr, direction) => {
  return (dispatch) => {
    return axios
      .delete(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/price-rules/" +
          remove
      )

      .then((res) => {
        dispatch(getAllPriceRules(page, rowSize, attr, direction));
      })
      .catch((err) => dispatch(handleError(err)));
  };
};

const loadingPreviewData = (value) => {
  return {
    type: LOAD_PREVIEW,
    loadPreview: value,
  };
};

const setNoDataPreview = (value) => {
  return {
    type: SET_NO_DATA_PREVIEW,
    noDataPreview: value,
  };
};

const setPreview = (data) => {
  return {
    type: SET_PREVIEW,
    preview: data,
  };
};
const setConfig = (data) => {
  return {
    type: SET_CONFIG,
    config: data,
  };
};

const setAllPriceRules = (data) => {
  return {
    type: GET_PRICE_RULES,
    priceRules: data.content,
    totalPriceRules: data.totalElements,
  };
};

const showAllData = (value) => {
  return {
    type: TOGGLE_SHOW_ALL_DATA,
    showAllData: value,
  };
};

const setCompetitors = (data) => {
  return {
    type: SET_COMPETITORS,
    competitorList: data,
  };
};

const clearAllPriceRules = () => {
  return {
    type: CLEAR_ALL_PRICE_RULES,
  };
};

const tableLoading = (value) => {
  return {
    type: PRICE_RULE_TABLE_LOADING,
    priceRuleTableLoading: value,
  };
};

export const setNoData = (value) => {
  return {
    type: PRICE_RULE_SET_NO_DATA,
    noData: value,
  };
};

const setWarnings = (data) => {
  return {
    type: SET_WARNINGS_PRICE_RULE,
    warnings: data,
  };
};

export const closeWarnings = () => {
  return {
    type: REMOVE_WARNINGS,
  };
};

const initialState = {
  priceRuleList: [],
  totalPriceRules: null,
  showAllData: true,
  competitorList: [],
  priceRuleTableLoading: true,
  config: [],
  noData: false,
  preview: null,
  noDataPreview: true,
  loadPreview: false,
  warnings: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PRICE_RULES:
      return {
        ...state,
        priceRuleList: action.priceRules,
        totalPriceRules: action.totalPriceRules,
      };

    case TOGGLE_SHOW_ALL_DATA:
      return {
        ...state,
        showAllData: action.showAllData,
      };
    case SET_COMPETITORS:
      return {
        ...state,
        competitorList: action.competitorList,
      };
    case PRICE_RULE_TABLE_LOADING:
      return {
        ...state,
        priceRuleTableLoading: action.priceRuleTableLoading,
      };
    case CLEAR_ALL_PRICE_RULES:
      return {
        ...state,
        priceRuleList: [],
      };
    case SET_CONFIG:
      return {
        ...state,
        config: action.config,
      };
    case PRICE_RULE_SET_NO_DATA:
      return {
        ...state,
        noData: action.noData,
      };
    case SET_PREVIEW:
      return {
        ...state,
        preview: action.preview,
      };
    case SET_NO_DATA_PREVIEW:
      return {
        ...state,
        noDataPreview: action.noDataPreview,
      };
    case LOAD_PREVIEW:
      return {
        ...state,
        loadPreview: action.loadPreview,
      };
    case SET_WARNINGS_PRICE_RULE:
      return {
        ...state,
        warnings: action.warnings,
      };
    case REMOVE_WARNINGS:
      return {
        ...state,
        warnings: [],
      };
    default:
      return state;
  }
}
