import React, { Component } from "react";

import { Pie } from "react-chartjs-2";
class PieChart extends Component {
  render() {
    let colorList = ["#fa7ea8", "#6cb2eb", "#faad63", "#51d88a", "#ef5753"];
    let data = [];
    let labels = [" "];
    // data =
    Object.keys(this.props.data).length > 0 &&
      this.props.data.dataSets.map(
        (obj) =>
          Object.keys(obj.data).map((key) => {
            return data.push(obj.data[key]);
          })
        // })
      );
    labels = Object.keys(this.props.data).length > 0 && this.props.data.labels;
    let cleanData = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let roundInteger = data[i].toFixed(2);
        cleanData.push(parseFloat(roundInteger));
      }
    }
    let datasets = [
      {
        label: labels.length > 0 ? labels : [""],
        data: cleanData.length > 0 ? cleanData : [],

        backgroundColor: colorList,
      },
    ];
    let chartData = {
      labels: labels.length > 0 ? labels : [""],
      datasets,
    };

    let chartOptions = {
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              display: false,
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              display: false,
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          padding: 20,
        },
      },
    };

    return <Pie data={chartData} options={chartOptions} />;
  }
}

export default PieChart;
