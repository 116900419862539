import axios from "axios";
import { setCompetitorAnalyticsPagination } from "./tablePagination";
import { handleError } from "./general";
const SET_COMPETITOR_ANALYTICS = "SET_COMPETITOR_ANALYTICS";
const SET_NO_DATA_CA = "SET_NO_DATA_CA";
const TABLE_LOADING = "TABLE_LOADING";
const CLEAR_ALL_COMPETITOR_ANALYTICS = "CLEAR_ALL_COMPETITOR_ANALYTICS";
const SET_COMPETITOR_ANALYTICS_PRODUCTS = "SET_COMPETITOR_ANALYTICS_PRODUCTS";
const NO_DATA_PRODUCT_COMP_ANALYTICS = "NO_DATA_PRODUCT_COMP_ANALYTICS";
const LOADING_PRODUCTS_COMP_ANALYTICS = "LOADING_PRODUCTS_COMP_ANALYTICS";
const CLEAR_PRODUCTS_COMPETITOR_ANALTYICS =
  "CLEAR_PRODUCTS_COMPETITOR_ANALTYICS";

export const getAnalytics = (value, page, rowSize, attr, direction) => {
  return dispatch => {
    dispatch(clearAllCompetitorAnalytics());
    dispatch(tableLoading(true));
    dispatch(setNoDataCA(false));
    return axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/competitor-analytics?page=" +
          page +
          "&size=" +
          rowSize +
          "&attributeId=" +
          value.attribute +
          "&competitorId=" +
          value.competitor
      )
      .then(res => {
        dispatch(
          setCompetitorAnalyticsPagination(
            page,
            rowSize,
            res.data.totalPages,
            attr,
            direction
          )
        );
        dispatch(setAnalytics(res.data));
        dispatch(tableLoading(false));
        if (res.data.content.length < 1) {
          dispatch(setNoDataCA(true));
        }
      })
      .catch(err => {
        dispatch(tableLoading(false));
        dispatch(handleError(err));
        dispatch(setNoDataCA(true));
      });
  };
};

export const getProductsInCompetitorAnalytics = data => {
  return dispatch => {
    dispatch(loadingProductsCompetitorAnalytics(true));
    return axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/competitor-analytics/products?name=" +
          data.category +
          "&attributeId=" +
          data.attribute +
          "&competitorId=" +
          data.competitor
      )
      .then(res => {
        dispatch(loadingProductsCompetitorAnalytics(false));
        dispatch(setProductsInCompetitorAnalytics(res.data));
        if (res.data.higher < 1 && res.data.same < 1 && res.data.lowers < 1) {
          dispatch(noDataProductsCompAnalytics(true));
        }
      })
      .catch(err => {
        dispatch(handleError(err));
        dispatch(loadingProductsCompetitorAnalytics(false));

        dispatch(noDataProductsCompAnalytics(true));
      });
  };
};

export const clearProductsInCompetitorAnalytics = () => {
  return {
    type: CLEAR_PRODUCTS_COMPETITOR_ANALTYICS
  };
};

const noDataProductsCompAnalytics = value => {
  return {
    type: NO_DATA_PRODUCT_COMP_ANALYTICS,
    noDataProductsCompAnalytics: value
  };
};

const loadingProductsCompetitorAnalytics = value => {
  return {
    type: LOADING_PRODUCTS_COMP_ANALYTICS,
    loadingProductsCompetitorAnalytics: value
  };
};

const setProductsInCompetitorAnalytics = data => {
  return {
    type: SET_COMPETITOR_ANALYTICS_PRODUCTS,
    setHigherProducts: data.higher,
    setSameProducts: data.same,
    setLowerProducts: data.lowers
  };
};

const setAnalytics = data => {
  return {
    type: SET_COMPETITOR_ANALYTICS,
    competitorAnalytics: data.content,
    totalCompetitorAnalytics: data.numberOfElements
  };
};

export const setNoDataCA = value => {
  return {
    type: SET_NO_DATA_CA,
    compAnalyticsNoData: value
  };
};

const tableLoading = value => {
  return {
    type: TABLE_LOADING,
    competitiorAnalyticsTableLoading: value
  };
};

const clearAllCompetitorAnalytics = () => {
  return {
    type: CLEAR_ALL_COMPETITOR_ANALYTICS
  };
};

const initState = {
  competitorAnalytics: [],
  competitiorAnalyticsTableLoading: false,
  compAnalyticsNoData: true,
  totalCompetitorAnalytics: null,
  setHigherProducts: [],
  setSameProducts: [],
  setLowerProducts: [],
  noDataProductsCompAnalytics: false,
  loadingProductsCompetitorAnalytics: false
};

export default function reducer(state = initState, action = {}) {
  switch (action.type) {
    case SET_COMPETITOR_ANALYTICS:
      return {
        ...state,
        competitorAnalytics: action.competitorAnalytics,
        totalCompetitorAnalytics: action.totalCompetitorAnalytics
      };
    case SET_NO_DATA_CA:
      return {
        ...state,
        compAnalyticsNoData: action.compAnalyticsNoData
      };
    case TABLE_LOADING:
      return {
        ...state,
        competitiorAnalyticsTableLoading:
          action.competitiorAnalyticsTableLoading
      };
    case CLEAR_ALL_COMPETITOR_ANALYTICS:
      return {
        ...state,
        competitorAnalytics: []
      };
    case SET_COMPETITOR_ANALYTICS_PRODUCTS:
      return {
        ...state,
        setHigherProducts: action.setHigherProducts,
        setSameProducts: action.setSameProducts,
        setLowerProducts: action.setLowerProducts
      };
    case NO_DATA_PRODUCT_COMP_ANALYTICS:
      return {
        ...state,
        noDataProductsCompAnalytics: action.noDataProductsCompAnalytics
      };
    case LOADING_PRODUCTS_COMP_ANALYTICS:
      return {
        ...state,
        loadingProductsCompetitorAnalytics:
          action.loadingProductsCompetitorAnalytics
      };
    case CLEAR_PRODUCTS_COMPETITOR_ANALTYICS:
      return {
        ...state,
        setHigherProducts: [],
        setSameProducts: [],
        setLowerProducts: []
      };
    default:
      return state;
  }
}
