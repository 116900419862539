import React from "react";
export const SmallCircleButton = (props) => {
  return (
    <div className="h-full flex flex-row items-center ">
      <div
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onClick={props.onClick}
        className={`material-icons-outlined p-2 xxl:text-lg text-md cursor-pointer ${
          props.success
            ? "bg-teal-dark text-white"
            : props.error
            ? "bg-red-dark text-white"
            : "text-grey-darker bg-table-grey hover:bg-teal-dark  hover:text-white"
        }  rounded-full  `}
      >
        {props.icon}
      </div>
      <div className="w-1 relative h-full">
        <div
          className={`absolute ml-1 h-full flex flex-row items-center ${
            props.showtooltip ? "visible" : "invisible"
          }`}
        >
          <div className="tooltip-triangle-left" />
          <div className="bg-grey-darker text-white px-2 py-1 xxl:text-xs text-xxs uppercase">
            {props.name}
          </div>
        </div>
      </div>
    </div>
  );
};
