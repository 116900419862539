import React, { Component } from "react";

import PropTypes from "prop-types";
import { Input } from "./Input";
import Dropdown from "./Dropdown";
import { async } from "q";

export default class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userOpen: false,
      editUser: false,
      firstName: "",
      lastName: "",
      email: "",
      passwordOpen: false,
      addUserOpen: false,
      manageUserOpen: false,
      showOldPassword: false,
      oldPassword: "",
      showNewPassword: false,
      newPassword: "",
      showNewPasswordCheck: false,
      newPasswordCheck: "",
      newPasswordError: false,
      oldPasswordError: false,
      activeTab: "all",
      selectedUser: null,
      inviteUsers: [
        { selectedUserGroup: {}, email: "", firstName: "", lastName: "" }
      ],
      canInviteUsers: false
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email
    });
  }

  updateUserDetails = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    this.props.updateUserDetails(
      this.props.profile.id,
      {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email
      },
      user.email
    );
  };

  toggleVisibility = name => {
    switch (name) {
      case "userOpen":
        return this.setState(prevState => ({
          userOpen: !prevState.userOpen,
          passwordOpen: false,
          addUserOpen: false,
          manageUserOpen: false
        }));
      case "passwordOpen":
        return this.setState(prevState => ({
          userOpen: false,
          passwordOpen: !prevState.passwordOpen,
          addUserOpen: false,
          manageUserOpen: false
        }));
      case "addUserOpen":
        return this.setState(prevState => ({
          userOpen: false,
          passwordOpen: false,
          addUserOpen: !prevState.addUserOpen,
          manageUserOpen: false
        }));
      case "manageUserOpen":
        this.props.getAllUsers();
        this.setState(prevState => ({
          userOpen: false,
          passwordOpen: false,
          addUserOpen: false,
          manageUserOpen: !prevState.manageUserOpen
        }));
        break;
      default:
        return;
    }
  };

  handleChange = (event, name) => {
    this.setState({ [name]: event.target.value });
    if (name === "oldPassword") {
      this.setState({ oldPasswordError: false });
      this.props.updatePasswordResponse(null);
    }
    if (name === "newPassword" || name === "newPasswordCheck") {
      this.setState({ newPasswordError: false });
    }
    if (name === "email") {
      this.props.updateUserDetailsResponse(null);
    }
  };

  changePassword = () => {
    if (this.state.oldPassword.length < 1) {
      this.setState({ oldPasswordError: true });
    }
    if (
      this.state.newPassword.length < 2 ||
      this.state.newPasswordCheck.length < 2 ||
      this.state.newPassword !== this.state.newPasswordCheck
    ) {
      this.setState({ newPasswordError: true });
    }

    if (
      this.state.newPassword === this.state.newPasswordCheck &&
      this.state.newPassword.length >= 2 &&
      this.state.newPasswordCheck.length >= 2 &&
      this.state.oldPassword.length > 0
    ) {
      this.props.changePassword(this.state.oldPassword, this.state.newPassword);
    }
  };

  handleSelectedUserEmail = id => {
    if (id === this.state.selectedUser) {
      this.setState({ selectedUser: null });
    } else {
      this.setState({ selectedUser: id });
    }
  };

  clearPasswordUpdateSuccess = () => {
    this.props.clearPasswordSuccess(false);
    this.props.updatePasswordResponse(null);
    this.setState({ oldPassword: "", newPassword: "", newPasswordCheck: "" });
  };

  handleSelect = (name, option) => {
    this.setState({ [name]: option });
  };

  handleInviteUserCanInviteCheck = () => {
    let canInviteUsers = true;
    this.props.inviteUsers.map(user => {
      if (
        user.email.length > 0 &&
        (Object.keys(user.selectedUserGroup).length > 0 ||
          this.props.userGroups.length === 1)
      ) {
        return;
      } else {
        canInviteUsers = false;
      }
    });
    this.setState({ canInviteUsers });
  };

  addRemoveInviteUser = (handler, index) => {
    this.props.addRemoveInviteUser(handler, index);
    this.handleInviteUserCanInviteCheck();
  };

  handleInviteUserOnChange = (event, name, index) => {
    this.props.handleInviteUserOnChange(event.target.value, name, index);
    this.handleInviteUserCanInviteCheck();
  };

  handleInviteUserGroupSelect = (name, option, index) => {
    this.props.handleInviteUserGroupSelect(name, option, index);
  };

  handleEmailErrorCheck = (res, email) => {
    let error = false;
    if (res && res.includes(email)) {
      error = true;
    }
    return error;
  };

  handleInviteUsersSubmit = () => {
    let inviteUsers = [...this.props.inviteUsers];
    if (this.props.userGroups.length === 1) {
      inviteUsers.map(user => {
        return (user.selectedUserGroup = this.props.userGroups[0]);
      });
    }
    this.props.addUser(inviteUsers);
  };

  render() {
    const circleButton = (icon, name) => {
      return (
        <div
          onClick={() => this.toggleVisibility(name)}
          className={`cursor-pointer xxl:w-10 xxl:h-10 h-8 w-8 flex justify-center items-center bg-white rounded-full  ${
            this.state[name]
              ? "text-white bg-teal-dark"
              : "text-grey-dark hover:text-teal-dark"
          }`}
        >
          <i className="material-icons xxl:text-lg text-md">{icon}</i>
        </div>
      );
    };

    const passwordVisibiltyIcon = (name, error) => {
      return (
        <i
          className={`cursor-pointer ${
            error ? "hover:text-red" : "hover:text-teal-dark"
          }`}
          onClick={() => this.toggleVisibility(name)}
        >
          visibility
        </i>
      );
    };

    const emailAddress = user => {
      return user.active ? (
        <div key={user.id} className="flex flex-col pr-3 pb-3">
          <div
            onClick={() => this.handleSelectedUserEmail(user.id)}
            className={`flex flex-row items-center py-1 cursor-pointer ${
              this.state.selectedUser && this.state.selectedUser === user.id
                ? "text-red-dark"
                : "hover:text-teal-dark"
            }`}
          >
            <i className="material-icons xxl:text-lg text-md">check_circle</i>
            {user.email}
          </div>
          {this.state.selectedUser && this.state.selectedUser === user.id && (
            <button
              onClick={() => {
                this.props.updateUsers(user, "inactivate");
                this.setState({ selectedUser: null });
              }}
              className="cursor-pointer rounded-sm bg-red-dark hover:bg-red tracking-small py-1 border-none outline-none text-white text-xs uppercase"
            >
              INACTIVATE
            </button>
          )}
        </div>
      ) : (
        <div key={user.id} className="flex flex-col pr-3 pb-3">
          <div
            onClick={() => this.handleSelectedUserEmail(user.id)}
            className={`flex flex-row items-center py-1 cursor-pointer ${
              this.state.selectedUser && this.state.selectedUser === user.id
                ? "text-teal-dark"
                : "text-grey hover:text-teal-dark"
            }`}
          >
            <i className="material-icons-outlined xxl:text-lg text-md">
              cancel
            </i>
            {user.email}
          </div>
          {this.state.selectedUser && this.state.selectedUser === user.id && (
            <button
              onClick={() => {
                this.props.updateUsers(user, "activate");
                this.setState({ selectedUser: null });
              }}
              className="cursor-pointer rounded-sm bg-teal-dark hover:bg-teal tracking-small py-1 border-none outline-none text-white text-xs uppercase"
            >
              ACTIVATE
            </button>
          )}
        </div>
      );
    };

    let usersList = this.props.usersList.filter(item => {
      return item.email !== this.props.profile.email;
    });

    return (
      <div className="flex w-full flex-col justify-between px-6 font-sans">
        <div className="py-8 text-grey-darker">
          <div className="flex flex-row items-center ">
            {circleButton("person", "userOpen")}
            <span
              className={`inline-block px-2 xxl:text-lg text-md font-light ${this
                .state.userOpen && "text-teal-dark"}`}
            >
              Profile
            </span>
            {this.state.userOpen && (
              <div
                onClick={() =>
                  this.setState(prevState => ({
                    editUser: !prevState.editUser
                  }))
                }
                className={`ml-6 flex xxl:text-md text-md cursor-pointer ${
                  this.state.editUser ? "text-teal-dark" : "text-grey"
                }`}
              >
                <span
                  className={`border-b-2 px-1 inline-block  ${
                    this.state.editUser ? "border-teal-dark" : "border-grey"
                  }`}
                >
                  Edit
                </span>
                <i className="material-icons self-center text-lg pl-3">edit</i>
              </div>
            )}
          </div>
          {this.state.userOpen ? (
            <div className="h-18">
              {this.props.userDetailsResponse === "success" ? (
                <div className="flex flex-row items-center pt-4">
                  <div className="font-sans self-center font-light text-teal-dark">
                    Your details have been updated
                  </div>
                  <i
                    className="material-icons-outlined xxl:text-3xl text-2xl cursor-pointer text-teal-dark hover:text-teal px-4"
                    onClick={() => {
                      this.props.updateUserDetailsResponse(null);
                      this.setState({ editUser: false });
                    }}
                  >
                    close
                  </i>
                </div>
              ) : this.state.editUser ? (
                <div className="flex flex-row items-center pt-4">
                  <div className="pr-2">
                    <Input
                      label="first name"
                      onChange={event => this.handleChange(event, "firstName")}
                      value={this.state.firstName}
                      type="text"
                      icon="person"
                    />
                  </div>
                  <div className="pr-2">
                    <Input
                      label="last name"
                      onChange={event => this.handleChange(event, "lastName")}
                      value={this.state.lastName}
                      type="text"
                      icon="person"
                    />
                  </div>
                  <div className="pr-2">
                    <Input
                      label="email"
                      onChange={event => this.handleChange(event, "email")}
                      value={this.state.email}
                      type="text"
                      icon="mail"
                      error={
                        this.props.userDetailsResponse === "error" ||
                        this.props.userDetailsResponse === "409"
                      }
                      errorMessage={
                        this.props.userDetailsResponse === "409"
                          ? "Email Already Exists"
                          : "Invalid Email"
                      }
                    />
                  </div>

                  <button
                    onClick={() => this.updateUserDetails()}
                    className="cursor-pointer self-end mr-4 bg-teal-dark hover:bg-teal rounded-sm tracking-small h-10 self-start px-2 text-center border-none outline-none focus:outline-none text-white xxl:font-light xxl:text-sm text-xs uppercase"
                  >
                    UPDATE
                  </button>
                </div>
              ) : (
                <div className="flex flex-col pt-2">
                  <div className="pt-2">
                    <span className="capitalize font-light">Name:</span>{" "}
                    {this.props.profile.firstName +
                      " " +
                      this.props.profile.lastName}
                  </div>
                  <div className="pt-2">
                    <span className="capitalize font-light">Email:</span>{" "}
                    {this.props.profile.email}
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>

        <div className="py-8 text-grey-darker">
          <div className="flex flex-row items-center ">
            {circleButton("lock", "passwordOpen")}
            <span
              className={`inline-block px-2 xxl:text-lg text-md font-light ${this
                .state.passwordOpen && "text-teal-dark"}`}
            >
              Change Password
            </span>
          </div>

          {this.state.passwordOpen && (
            <div className="flex flex-col">
              <div className="h-10" />
              <div className="flex flex-row pt-2">
                {this.props.passwordResponse === "success" ? (
                  <div className="font-sans self-center font-light text-teal-dark">
                    Your password has been updated
                  </div>
                ) : (
                  <>
                    <div className="pr-2">
                      <Input
                        required={true}
                        label="old password"
                        onChange={event =>
                          this.handleChange(event, "oldPassword")
                        }
                        value={this.state.oldPassword}
                        errorMessage={
                          this.props.passwordResponse === "error" &&
                          "Invalid password."
                        }
                        error={
                          this.props.passwordResponse === "error" ||
                          this.state.oldPasswordError
                        }
                        type={this.state.showOldPassword ? "text" : "password"}
                        icon={passwordVisibiltyIcon(
                          "showOldPassword",
                          this.state.oldPasswordError
                        )}
                      />
                    </div>
                    <div className="px-2">
                      <Input
                        required={true}
                        label="new password"
                        onChange={event =>
                          this.handleChange(event, "newPassword")
                        }
                        value={this.state.newPassword}
                        error={this.state.newPasswordError}
                        errorMessage={
                          this.state.newPasswordError
                            ? this.state.newPassword !==
                              this.state.newPasswordCheck
                              ? "Passwords do not match."
                              : "Password too short."
                            : ""
                        }
                        type={this.state.showNewPassword ? "text" : "password"}
                        icon={passwordVisibiltyIcon(
                          "showNewPassword",
                          this.state.newPasswordError
                        )}
                      />
                    </div>
                    <div className="px-2">
                      <Input
                        required={true}
                        label="confirm new password"
                        onChange={event =>
                          this.handleChange(event, "newPasswordCheck")
                        }
                        error={this.state.newPasswordError}
                        value={this.state.newPasswordCheck}
                        type={
                          this.state.showNewPasswordCheck ? "text" : "password"
                        }
                        icon={passwordVisibiltyIcon(
                          "showNewPasswordCheck",
                          this.state.newPasswordError
                        )}
                      />
                    </div>
                  </>
                )}

                {this.props.passwordResponse === "success" ? (
                  <i
                    className="material-icons-outlined xxl:text-3xl text-2xl cursor-pointer text-teal-dark hover:text-teal px-4"
                    onClick={() => this.props.updatePasswordResponse(null)}
                  >
                    close
                  </i>
                ) : (
                  <div className="px-2 self-end">
                    <button
                      onClick={() => this.changePassword()}
                      className="cursor-pointer rounded-sm bg-teal-dark hover:bg-teal tracking-small h-10 px-8 border-none outline-none text-white xxl:font-light xxl:text-sm text-xs uppercase"
                    >
                      SAVE
                    </button>
                  </div>
                )}
              </div>
              <span className="text-sm text-grey-dark font-light pt-2">
                {this.props.passwordResponse === null ||
                  (this.props.passwordResponse === "error" &&
                    "Passwords must be atleast 8 characters.")}
              </span>
            </div>
          )}
        </div>

        {this.props.isAdmin && (
          <>
            <div className="py-8 text-grey-darker">
              <div className="flex flex-row items-center ">
                {circleButton("person_add", "addUserOpen")}
                <span
                  className={`inline-block px-2 xxl:text-lg text-md font-light ${this
                    .state.addUserOpen && "text-teal-dark"}`}
                >
                  Invite Users
                </span>
              </div>
              {this.state.addUserOpen && (
                <div className="flex flex-col w-full">
                  <div className="h-10 flex flex-row flex wrap items-center">
                    {this.props.successfullUserInviteList.length > 0 && (
                      <div className="flex flex-row text-xs text-teal-dark font-light">
                        Successfully invited:{" "}
                        {this.props.successfullUserInviteList.map(user => {
                          return (
                            <span className="font-normal inline-block px-2">
                              {user.email}
                            </span>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  {this.props.inviteUsers.map((row, i) => {
                    return (
                      <div className="w-full flex flex-row flex-wrap">
                        <div className="pr-2">
                          <Dropdown
                            label="User Groups"
                            name="selectedUserGroup"
                            index={i}
                            required={true}
                            selected={
                              this.props.userGroups.length === 1
                                ? this.props.userGroups[0]
                                : this.props.inviteUsers[i].selectedUserGroup
                            }
                            handleSelect={this.handleInviteUserGroupSelect}
                            placeholder={
                              this.props.userGroups.length > 0
                                ? "Select User Group"
                                : "No Groups Available"
                            }
                            options={
                              this.props.userGroups.length > 0
                                ? this.props.userGroups
                                : []
                            }
                          />
                        </div>
                        <div className="pr-2">
                          <Input
                            label="email"
                            onChange={event =>
                              this.handleInviteUserOnChange(event, "email", i)
                            }
                            value={this.props.inviteUsers[i].email}
                            type="text"
                            icon="mail"
                            required={true}
                            error={
                              this.props.addUserResponse &&
                              this.handleEmailErrorCheck(
                                this.props.addUserResponse,
                                this.props.inviteUsers[i].email
                              )
                            }
                            errorMessage={
                              this.props.addUserResponse === "error"
                                ? "Invalid Email"
                                : this.handleEmailErrorCheck(
                                    this.props.addUserResponse,
                                    this.props.inviteUsers[i].email
                                  ) && "Email Already Exists"
                            }
                          />
                        </div>
                        <div className="flex flex-row">
                          <div className="pr-2">
                            <Input
                              label="first name"
                              onChange={event =>
                                this.handleInviteUserOnChange(
                                  event,
                                  "firstName",
                                  i
                                )
                              }
                              value={this.props.inviteUsers[i].firstName}
                              type="text"
                              icon="person"
                            />
                          </div>
                          <div className="pr-2">
                            <Input
                              label="last name"
                              onChange={event =>
                                this.handleInviteUserOnChange(
                                  event,
                                  "lastName",
                                  i
                                )
                              }
                              value={this.props.inviteUsers[i].lastName}
                              type="text"
                              icon="person"
                            />
                          </div>

                          {this.props.inviteUsers.length > 1 && (
                            <div
                              onClick={() =>
                                this.addRemoveInviteUser("remove", i)
                              }
                              className="cursor-pointer xxl:self-end self-center xxl:mt-0 mt-6 inline-block xxl:w-8 xxl:h-8 h-6 w-6 mr-2 flex justify-center text-grey-dark hover:text-teal-dark items-center bg-white rounded-full"
                            >
                              <i className="material-icons xxl:text-lg text-md">
                                remove
                              </i>
                            </div>
                          )}
                          {this.props.inviteUsers.length - 1 === i && (
                            <div
                              onClick={() => this.addRemoveInviteUser("add", i)}
                              className="cursor-pointer xxl:self-end self-center xxl:mt-0 mt-6 inline-block xxl:w-8 xxl:h-8 h-6 w-6 flex justify-center text-grey-dark hover:text-teal-dark items-center bg-white rounded-full"
                            >
                              <i className="material-icons xxl:text-lg text-md">
                                add
                              </i>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className="self-start pt-4">
                    <button
                      onClick={() => this.handleInviteUsersSubmit()}
                      className={`${
                        this.state.canInviteUsers
                          ? "cursor-pointer bg-teal-dark hover:bg-teal"
                          : "cursor-not-allowed bg-grey-light"
                      }  rounded-sm tracking-small h-10 self-start px-2 text-center border-none outline-none focus:outline-none text-white xxl:font-light xxl:text-sm text-xs uppercase `}
                    >
                      INVITE USERS
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="py-8 text-grey-darker">
              <div className="flex flex-row items-center">
                {circleButton("people_alt", "manageUserOpen")}
                <span
                  className={`inline-block px-2 xxl:text-lg text-md font-light ${this
                    .state.manageUserOpen && "text-teal-dark"}`}
                >
                  Manage Users
                </span>
                {this.state.manageUserOpen && (
                  <>
                    <div
                      onClick={() => this.setState({ activeTab: "all" })}
                      className={`ml-6 border-b-2 px-4 xxl:text-md text-md cursor-pointer ${
                        this.state.activeTab === "all"
                          ? "border-teal-dark text-teal-dark"
                          : "border-grey text-grey"
                      }`}
                    >
                      All
                    </div>
                    <div
                      onClick={() => this.setState({ activeTab: "active" })}
                      className={`border-b-2 px-4 xxl:text-md text-md cursor-pointer ${
                        this.state.activeTab === "active"
                          ? "border-teal-dark text-teal-dark"
                          : "border-grey text-grey"
                      }`}
                    >
                      Active
                    </div>
                    <div
                      onClick={() => this.setState({ activeTab: "inactive" })}
                      className={`border-b-2 px-4 xxl:text-md text-md cursor-pointer ${
                        this.state.activeTab === "inactive"
                          ? "border-teal-dark text-teal-dark"
                          : "border-grey text-grey"
                      }`}
                    >
                      Inactive
                    </div>
                  </>
                )}
              </div>

              {this.state.manageUserOpen && (
                <div className="flex flex-row xxl:w-1/2 flex-wrap pt-2">
                  {usersList.length < 1 && (
                    <div className="p-3 font-light font-sans text-sm text-grey-dark">
                      No Users Available
                    </div>
                  )}
                  {this.state.activeTab === "all" &&
                    usersList.map(user => {
                      return emailAddress(user);
                    })}
                  {this.state.activeTab === "active" &&
                    usersList
                      .filter(user => user.active)
                      .map(user => {
                        return emailAddress(user);
                      })}
                  {this.state.activeTab === "inactive" &&
                    usersList
                      .filter(user => !user.active)
                      .map(user => {
                        return emailAddress(user);
                      })}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}
UserSettings.propTypes = {
  isAdmin: PropTypes.bool,
  profile: PropTypes.object,
  changePassword: PropTypes.func,
  passwordResponse: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  updatePasswordResponse: PropTypes.func,
  addUser: PropTypes.func,
  addUserResponse: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  updateAddUserResponse: PropTypes.func,
  userGroups: PropTypes.array,
  usersList: PropTypes.array,
  updateUsers: PropTypes.func
};
