import React, { Component } from "react";
import { connect } from "react-redux";

import { LargeLoadingSpinner } from "components/LargeLoadingSpinner/LargeLoadingSpinner";
import ProductCard from "components/ProductCard/ProductCard";
import CreateProductCard from "components/CreateProductCard/CreateProductCard";
import {
  searchProducts,
  clearSearchResults,
  clearSearchError,
} from "ducks/productMatching";

import { getAllCompetitors } from "ducks/priceRule";

import SmallSingleDropdown from "components/SmallSingleDropdown/SmallSingleDropdown";
import { SearchBar } from "components/SearchBar/SearchBar";

class ProductMatchingGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createNew: false,
      selectedCompetitior: null,
      matchingViewSelectedCompetitior: null,
      currentSelectedDomain: null,
      searchValue: "",
      ownProduct: {},
      competitorList: [],
      matchingCompetitorList: [],
    };
  }

  componentWillUnmount() {
    this.closeMatching();
  }

  toggleCreate = () => {
    this.setState({ createNew: true });
    this.props.getAllCompetitors();
  };

  handleSelected = (name, option) => {
    this.setState({ [name]: option });
  };

  handleMatchingViewSearch = (event) => {
    this.handleSearch(event, event.target.value, null, "productCard");
  };

  setMatchingViewStates = (value, competitor) => {
    this.setState(
      {
        currentSelectedDomain: {
          id: this.state.matchingViewSelectedCompetitior
            ? this.state.matchingViewSelectedCompetitior.id
            : this.state.currentSelectedDomain
            ? this.state.currentSelectedDomain.id
            : competitor.id,
          name: this.state.matchingViewSelectedCompetitior
            ? this.state.matchingViewSelectedCompetitior.name
            : this.state.currentSelectedDomain
            ? this.state.currentSelectedDomain.name
            : competitor.domainName,
        },
      },
      () => {
        this.props.searchProducts(
          value,
          { name: this.state.currentSelectedDomain.name },
          this.props.matchView,
          "productCard"
        );
      }
    );
  };

  setSearchingStates = (competitor) => {
    this.setState({
      currentSelectedDomain: {
        id: this.state.selectedCompetitior
          ? this.state.selectedCompetitior.id
          : competitor.id,
        name: this.state.selectedCompetitior
          ? this.state.selectedCompetitior.name
          : competitor.domainName,
      },
    });
    this.props.searchProducts(
      this.state.searchValue,
      {
        name: this.state.selectedCompetitior.name,
      },
      this.props.matchView,
      "createCard"
    );
  };

  handleSearch = (event, value, competitor, type) => {
    this.setState({
      searchValue: event.target.value,
    });
    if (type === "productCard") {
      if (event.which === 13 || event.keyCode === 13) {
        this.setMatchingViewStates(value, competitor);
      }
    } else {
      if (event.which === 13 || event.keyCode === 13) {
        this.setSearchingStates(competitor);
      }
    }
  };

  handleClickSearch = (competitor, type) => {
    if (type === "productCard") {
      this.setMatchingViewStates(this.state.searchValue, competitor);
    } else {
      this.setSearchingStates(competitor);
    }
  };

  closeMatching = () => {
    this.props.getAllCompetitors();
    this.setState({
      selectedCompetitior: null,
      matchingViewSelectedCompetitior: null,
      currentSelectedDomain: null,
      searchValue: "",
      createNew: false,
    });
    this.props.clearSearchResults();
  };

  handleUpdateProductCompetitor = (type, product) => {
    this.props.handleProducts({
      ...product,
      actionType: type,
      newlyUpdated: true,
    });
  };

  render() {
    let competitorList = this.props.competitorList
      .filter((comp) => {
        return !this.props.productMatchingCompetitors.some((item) => {
          return item.domainName === comp.domain;
        });
      })
      .map((comp) => ({ name: comp.domain, id: comp.id }));

    let matchingCompetitorList = this.props.competitorList
      .filter((comp) => {
        return !this.props.productMatchingCompetitors.some((item) => {
          return item.domainName === comp.domain;
        });
      })
      .map((comp) => ({ name: comp.domain, id: comp.id }));

    if (this.state.currentSelectedDomain) {
      const foundSelected = matchingCompetitorList.find(
        (comp) => comp.id === this.state.currentSelectedDomain.id
      );
      if (!foundSelected) {
        matchingCompetitorList.push(this.state.currentSelectedDomain);
      }
    }

    return (
      <div className="py-4 min-h-full max-h-full flex flex-col">
        <div className="w-full flex flex-row justify-end h-10 pb-2">
          {this.props.matchView && (
            <div className="w-full flex flex-col items-end">
              <div className="w-1/2 flex flex-row px-6 items-center  relative z-20">
                <div className=" w-1/4 pr-2">
                  <SmallSingleDropdown
                    selected={
                      this.state.matchingViewSelectedCompetitior
                        ? this.state.matchingViewSelectedCompetitior
                        : this.state.currentSelectedDomain
                    }
                    options={matchingCompetitorList}
                    handleSelected={this.handleSelected}
                    name="matchingViewSelectedCompetitior"
                  />
                </div>
                <div className="w-1/3 px-2">
                  <SearchBar
                    onKeyDown={this.handleMatchingViewSearch}
                    onChange={this.handleMatchingViewSearch}
                    value={this.state.searchValue}
                    placeholder="Search"
                    loading={this.props.searchLoading}
                    handleClickSearch={() =>
                      this.handleClickSearch(null, "productCard")
                    }
                    icon="search"
                  />
                </div>
                <div className="text-grey-darker font-light xxl:text-sm text-xs px-2">
                  {this.props.searchResults.length > 0 ? (
                    <>
                      <span className="font-normal">
                        {this.props.searchResults.length}
                      </span>{" "}
                      products found
                    </>
                  ) : (
                    <div className="text-red-dark font-normal">
                      No Products Found
                    </div>
                  )}
                </div>
                <div className=" flex-1 flex justify-end ">
                  <i
                    onClick={() => this.closeMatching()}
                    className="p-2 xxl:text-lg text-md material-icons text-grey-darker rounded-full cursor-pointer bg-table-grey hover:bg-teal-dark  hover:text-white"
                  >
                    visibility_off
                  </i>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex-1 min-h-full max-h-full flex">
          {this.props.productMatchingCompetitorsLoading ? (
            <div className="w-full h-80 flex justify-center items-center">
              {LargeLoadingSpinner}
            </div>
          ) : (
            <div className="flex-1 w-full flex flex-row flex-wrap max-h-full min-h-1">
              <div
                className={` h-68 ${
                  this.props.matchView && this.props.searchResults.length > 0
                    ? "xxl:w-1/2 xl:w-1/2 lg:w-1/2 w-full"
                    : "xxl:w-1/3 xl:w-1/3 lg:w-1/2 w-full"
                }`}
              >
                <ProductCard
                  ownProduct={true}
                  matchView={this.props.matchView}
                  product={this.props.singleProduct}
                  price={this.props.price && this.props.price.currentPrice ? this.props.price.currentPrice : ""}
                />
              </div>

              {this.props.matchView ? (
                <div className="xxl:flex-1 xl:flex-1 lg:flex-1 w-full flex items-center flex-col xxl:overflow-y-auto xl:overflow-y-auto lg:overflow-y-auto xxl:max-h-full  xl:max-h-full lg:max-h-full min-h-1">
                  <div className="w-full flex flex-row flex-wrap">
                    {this.props.searchResults.map((product, i) => {
                      return (
                        <div key={i} className="w-full h-68 xxl:mb-2">
                          <ProductCard
                            ownProduct={false}
                            product={product}
                            matchView={this.props.matchView}
                            onClick={this.handleUpdateProductCompetitor}
                            closeMatching={this.closeMatching}
                            active={product.active === "Y" ? true : false}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <>
                  {this.props.productMatchingCompetitors
                    .filter((item) => item.userState !== "UNMATCHED")
                    .map((competitor, i) => {
                      let verifiedProduct = competitor.userState === "VERIFIED";
                      return (
                        <div
                          key={i}
                          className="xxl:w-1/3 xl:w-1/3 lg:w-1/2 h-68 w-full"
                        >
                          <ProductCard
                            ownProduct={false}
                            showProductState={true}
                            active={
                              competitor.product.active === "Y" ? true : false
                            }
                            userState={competitor.userState}
                            product={
                              competitor.product
                                ? competitor.product
                                : competitor
                            }
                            updatedBy={competitor.updatedBy}
                            showUpdatedBy={true}
                            handleClickSearch={this.handleClickSearch}
                            error={this.props.errorList.includes(
                              competitor.domainName
                            )}
                            clearSearchError={this.props.clearSearchError}
                            verified={verifiedProduct ? true : false}
                            onClick={this.handleUpdateProductCompetitor}
                            matchView={this.props.matchView}
                            handleSearch={this.handleSearch}
                          />
                        </div>
                      );
                    })}

                  {this.props.loadingNewMatch && (
                    <div className="xxl:w-1/3 xl:w-1/3 lg:w-1/2 h-68 w-full flex items-center justify-center">
                      {LargeLoadingSpinner}
                    </div>
                  )}

                  {this.state.createNew && !this.props.matchView && (
                    <div className="xxl:w-1/3 xl:w-1/3 lg:w-1/2 h-68 w-full">
                      <CreateProductCard
                        name="selectedCompetitior"
                        selected={this.state.selectedCompetitior}
                        handleSelected={this.handleSelected}
                        handleSearch={this.handleSearch}
                        searchValue={this.state.searchValue}
                        searchLoading={this.props.searchLoading}
                        searchError={this.props.searchError}
                        cancel={this.closeMatching}
                        options={competitorList}
                        handleClickSearch={this.handleClickSearch}
                      />
                    </div>
                  )}

                  {competitorList.length > 0 &&
                    !this.state.createNew &&
                    !this.props.matchView && (
                      <div className="xxl:w-1/3 xl:w-1/3 lg:w-1/2 w-full h-68 flex items-center justify-center">
                        <div
                          onClick={() => this.toggleCreate()}
                          className="material-icons w-12 h-12 flex items-center justify-center hover:text-white hover:bg-teal-dark bg-table-grey rounded-full flex items-center text-grey-darker cursor-pointer text-4xl"
                        >
                          add
                        </div>
                      </div>
                    )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchProducts: (string, selected, isMatchingView, type) =>
      dispatch(searchProducts(string, selected, isMatchingView, type)),
    clearSearchResults: () => dispatch(clearSearchResults()),
    // updateProductCompetitor: (type, product, domainName, row) =>
    //   dispatch(updateProductCompetitor(type, product, domainName, row)),
    clearSearchError: (competitor) => dispatch(clearSearchError(competitor)),
    getAllCompetitors: () => dispatch(getAllCompetitors()),
  };
};

const mapStateToProps = (state) => {
  return {
    productMatchingCompetitorsLoading:
      state.productMatching.productMatchingCompetitorsLoading,
    productMatchingCompetitors:
      state.productMatching.productMatchingCompetitors,
    searchLoading: state.productMatching.searchLoading,
    searchError: state.productMatching.searchError,
    searchResults: state.productMatching.searchResults,
    competitorList: state.priceRule.competitorList,
    matchView: state.productMatching.matchView,
    selectedMenuItem: state.sidepanel.selectedMenuItem,
    markets: state.markets.markets,
    errorList: state.productMatching.errorList,
    loadingNewMatch: state.productMatching.loadingNewMatch,
    price: state.products.pricePositionChart,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductMatchingGrid);
