import React, { Component } from "react";
import { connect } from "react-redux";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import SingleSelectDropdown from "components/SingleSelectDropdown";
import { InputText } from "components/InputText";
import InputNumber from "components/InputNumber";
import { TooltipRight } from "components/Tooltips";
import { SmallLoadingSpinner } from "components/SmallLoadingSpinner/SmallLoadingSpinner";
import {
  filterPricePosition,
  setFiltered,
  getPricePositionData,
} from "ducks/pricePosition";
import { getBrandsByMarket, getCategoriesByMarket } from "ducks/products";
import { getAllTags } from "ducks/tags";
import { getExportPricePosition } from "ducks/exportData";
import { getAllCampaignsDropdown } from "ducks/campaigns";
import { getAllCompetitors } from "ducks/priceRule";

class PricePositionFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSave: false,
      name: "",
      category: {},
      brand: {},
      tags: [],
      lowestCompetitor: {},
      hoverTooltip: false,
      selectedTag: [],
      searchedTags: [],
      searching: false,
      categoryOptions: [],
      brandOptions: [],
      competitorOptions: [],
      articleNumber: "",
      numberOfCompetitors: "",
      numberOfCompetitorsOperator: {},
      showChannelSelection: false,

      priceDiffOperatorOptions: [
        { id: 1, name: "<" },
        { id: 2, name: "<=" },
        { id: 3, name: "=" },
        { id: 4, name: ">=" },
        { id: 5, name: ">" },
      ],
      priceDiffOperator: {},
      priceDiff: "",
      priceDiffOperatorError: false,
      priceDiffError: false,
    };
  }

  componentDidMount() {}

  handleSelected = (name, selected) => {
    if (name === "priceDiffOperator") {
      this.setState({ priceDiffOperatorError: false });
    }
    if (name === "salesPriceOperator") {
      this.setState({ salesPriceOperatorError: false });
    }
    if (
      Array.isArray(selected) &&
      selected.find((selected) => selected.name == "Select All") !== undefined
    ) {
      if (selected.map((select) => select.name.includes("Select All"))) {
        if (
          name == "category" &&
          this.state.category.length != this.props.categories.length
        ) {
          this.setState({
            [name]: this.props.categories,
          });
        } else if (
          name == "category" &&
          this.state.category.length == this.props.categories.length
        ) {
          this.setState({
            [name]: [],
          });
        } else if (
          name == "brand" &&
          this.state.brand.length != this.props.brands.length
        ) {
          this.setState({
            [name]: this.props.brands,
          });
        } else if (
          name == "brand" &&
          this.state.brand.length == this.props.brands.length
        ) {
          this.setState({
            [name]: [],
          });
        }
      }
    } else {
      this.setState({ [name]: selected, canSave: true });
    }
  };

  handleExport = () => {
    let priceDiffError = false;
    let salesPriceError = false;
    if (
      Object.keys(this.state.priceDiffOperator).length < 1 &&
      this.state.priceDiff.length > 0
    ) {
      priceDiffError = true;
      this.setState({ priceDiffOperatorError: true });
    }
    if (
      Object.keys(this.state.priceDiffOperator).length > 0 &&
      this.state.priceDiff.length < 1
    ) {
      priceDiffError = true;
      this.setState({ priceDiffError: true });
    }

    if (priceDiffError) {
      return;
    } else {
      let queryString = "";
      let categoryIds = "&category=".concat(this.state.category.name);
      let brandIds = "&brand=".concat(this.state.brand.name);
      let tagIds = this.buildQueryString(this.state.selectedTag, "&tagIds=");
      let articleNumber = "&articleNumber=".concat(this.state.articleNumber);

      let lowestCompetitor = "&lowestCompetitorName=".concat(
        this.state.lowestCompetitor.domain
      );
      let name = "&name=".concat(this.state.name);

      if (Object.keys(this.state.category).length > 0) {
        queryString = queryString.concat(categoryIds);
      }
      if (Object.keys(this.state.brand).length > 0) {
        queryString = queryString.concat(brandIds);
      }
      if (this.state.selectedTag.length > 0) {
        queryString = queryString.concat(tagIds);
      }
      if (Object.keys(this.state.lowestCompetitor).length > 0) {
        queryString = queryString.concat(lowestCompetitor);
      }
      if (this.state.articleNumber.length > 0) {
        queryString = queryString.concat(articleNumber);
      }

      if (this.state.name.length > 0) {
        queryString = queryString.concat(name);
      }
      if (
        Object.keys(this.state.priceDiffOperator).length > 0 &&
        this.state.priceDiff.length > 0
      ) {
        queryString = queryString.concat(
          "&priceDiffOperator=" +
            this.state.priceDiffOperator.name +
            "&priceDiff=" +
            this.state.priceDiff.toString()
        );
      }
      if (
        Object.keys(this.state.numberOfCompetitorsOperator).length > 0 &&
        this.state.numberOfCompetitors.length > 0
      ) {
        queryString = queryString.concat(
          "&numberOfCompetitorsOperator=" +
            this.state.numberOfCompetitorsOperator.name +
            "&numberOfCompetitors=" +
            this.state.numberOfCompetitors.toString()
        );
      }
      this.props.getExportPricePosition(queryString);
    }
  };

  clearFilters = () => {
    this.setState({
      name: "",
      category: [],
      brand: [],
      selectedTag: [],
      numberOfCompetitors: "",
      lowestCompetitor: [],
      articleNumber: "",
      priceDiff: "",
      priceDiffOperator: {},
      numberOfCompetitorsOperator: {},
    });
    this.props.setFiltered(false);
    this.props.getPricePositionData(1, 20, null, null);
  };

  buildQueryString = (items, name) => {
    let urlQuery = name;
    if (name === "&tagIds=") {
      items.forEach((item, i) => {
        if (i + 1 < items.length) {
          urlQuery = urlQuery.concat(item.id + ",");
        } else {
          urlQuery = urlQuery.concat(item.id);
        }
      });
      return urlQuery;
    } else {
      items.forEach((item, i) => {
        if (i + 1 < items.length) {
          urlQuery = urlQuery.concat(item.name + ",");
        } else {
          urlQuery = urlQuery.concat(item.name);
        }
      });
      return urlQuery;
    }
  };

  tooltipOnMouseEnter = () => {
    this.setState({
      hoverTooltip: true,
    });
  };
  tooltipOnMouseLeave = () => {
    this.setState({
      hoverTooltip: false,
    });
  };

  tagSelect = (tag) => {
    if (
      this.state.selectedTag.find((selectedTag) => selectedTag.id === tag.id)
    ) {
      return this.removeSelectedTag(tag.id);
    } else {
      this.setState((state) => {
        const selectedTag = state.selectedTag.concat(tag);
        return {
          selectedTag,
        };
      });
    }
  };

  removeSelectedTag = (id) => {
    let remove = this.state.selectedTag.filter((tag) => tag.id !== id);
    if (remove == undefined) {
      this.setState({ selectedTag: [] });
    } else {
      this.setState({ selectedTag: remove });
    }
  };

  handleSearchTags = (e) => {
    let tagList = [];
    let newListTags = [];

    let truncatedArray = this.props.tagList;
    let shortList = truncatedArray.slice(0, 1);
    //check if input is not empty
    if (e.target.value !== "") {
      this.setState({ searching: true });

      tagList = this.props.tagList;

      if (tagList.length <= 0) {
        this.state.truncate
          ? (tagList = shortList)
          : (tagList = this.props.tagList);
      }
      //to lowercase and find match
      newListTags = tagList.filter((tag) => {
        const tlc = tag.name.toLowerCase();
        const filter = e.target.value.toLowerCase();
        return tlc.includes(filter);
      });
      //otherwise return original list
    } else if (e.target.value === "") {
      this.setState({ searching: false });
      this.state.truncate
        ? (newListTags = shortList)
        : (newListTags = this.props.tagList);
    }
    this.setState({
      searchedTags: newListTags,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      canSave: true,
    });
    if (event.target.name === "priceDiff") {
      this.setState({ priceDiffError: false });
    }
  };

  handleFilterPricePosition = () => {
    let priceDiffError = false;
    let salesPriceError = false;
    if (
      Object.keys(this.state.priceDiffOperator).length < 1 &&
      this.state.priceDiff.length > 0
    ) {
      priceDiffError = true;
      this.setState({ priceDiffOperatorError: true });
    }
    if (
      Object.keys(this.state.priceDiffOperator).length > 0 &&
      this.state.priceDiff.length < 1
    ) {
      priceDiffError = true;
      this.setState({ priceDiffError: true });
    }

    if (priceDiffError) {
      return;
    } else {
      let queryString = "";
      let categoryIds = "&category=".concat(this.state.category.name);
      let brandIds = "&brand=".concat(this.state.brand.name);
      let tagIds = this.buildQueryString(this.state.selectedTag, "&tagIds=");
      let articleNumber = "&articleNumber=".concat(this.state.articleNumber);

      let lowestCompetitor = "&lowestCompetitorName=".concat(
        this.state.lowestCompetitor.domain
      );
      let name = "&name=".concat(this.state.name);

      if (Object.keys(this.state.category).length > 0) {
        queryString = queryString.concat(categoryIds);
      }
      if (Object.keys(this.state.brand).length > 0) {
        queryString = queryString.concat(brandIds);
      }
      if (this.state.selectedTag.length > 0) {
        queryString = queryString.concat(tagIds);
      }
      if (Object.keys(this.state.lowestCompetitor).length > 0) {
        queryString = queryString.concat(lowestCompetitor);
      }
      if (this.state.articleNumber.length > 0) {
        queryString = queryString.concat(articleNumber);
      }

      if (this.state.name.length > 0) {
        queryString = queryString.concat(name);
      }
      if (
        Object.keys(this.state.priceDiffOperator).length > 0 &&
        this.state.priceDiff.length > 0
      ) {
        queryString = queryString.concat(
          "&priceDiffOperator=" +
            this.state.priceDiffOperator.name +
            "&priceDiff=" +
            this.state.priceDiff.toString()
        );
      }
      if (
        Object.keys(this.state.numberOfCompetitorsOperator).length > 0 &&
        this.state.numberOfCompetitors.length > 0
      ) {
        queryString = queryString.concat(
          "&numberOfCompetitorsOperator=" +
            this.state.numberOfCompetitorsOperator.name +
            "&numberOfCompetitors=" +
            this.state.numberOfCompetitors.toString()
        );
      }
      this.props.filterPricePosition(1, 20, queryString, null, null);
    }
  };

  render() {
    let selectAll = [{ id: 0, name: "Select All" }];
    let tagList = [];
    if (this.state.truncate && !this.state.searching) {
      tagList = this.props.tagList.slice(0, 1);
    } else if (this.state.searching) {
      tagList = this.state.searchedTags;
    } else {
      tagList = this.props.tagList;
    }
    return (
      <>
        <div className="bg-white h-full w-full mb-4">
          <div className="flex flex-row w-full">
            <div className="flex flex-col w-3/5">
              <div className="flex flex-row justify-start">
                <div className="px-2 w-1/3">
                  <InputText
                    label="Name"
                    name="name"
                    width="w-full"
                    value={this.state.name}
                    onChange={this.handleChange}
                    placeholder="Name"
                  />
                </div>
                <div className="px-2 w-1/3">
                  <InputText
                    label="Article Number"
                    name="articleNumber"
                    width="w-full"
                    value={this.state.articleNumber}
                    onChange={this.handleChange}
                    placeholder="Article Number"
                  />
                </div>
                <div className="px-2 w-1/3">
                  <SingleSelectDropdown
                    name="brand"
                    label="Brand"
                    width="w-full"
                    options={this.props.brands}
                    selected={this.state.brand}
                    handleSelect={this.handleSelected}
                    placeholder="Select Brands"
                  />
                </div>
              </div>
              <div className="flex flex-row justify-start">
                <div className="px-2 w-1/3">
                  <SingleSelectDropdown
                    name="category"
                    label="Category"
                    width="w-full"
                    options={this.props.categories}
                    selected={this.state.category}
                    handleSelect={this.handleSelected}
                    placeholder="Select Categories"
                  />
                </div>
                <div className="px-2 w-1/3">
                  <SingleSelectDropdown
                    name="lowestCompetitor"
                    label="Lowest Competitor"
                    width="w-full"
                    options={this.props.competitors}
                    selected={this.state.lowestCompetitor}
                    handleSelect={this.handleSelected}
                    placeholder="Lowest Competitor"
                  />
                </div>
              </div>
              <div className="flex flex-row justify-start">
                <div className="w-1/3 pt-2 pl-2 ">
                  <div className="text-grey-dark font-light text-xs pb-1">
                    No. of Competitors
                  </div>
                  <div className="border-1 borer-grey-light flex flex-row pb-2">
                    <div className="px-2 ">
                      <SingleSelectDropdown
                        name="numberOfCompetitorsOperator"
                        label="Operator"
                        width="w-16"
                        options={this.state.priceDiffOperatorOptions}
                        selected={this.state.numberOfCompetitorsOperator}
                        handleSelect={this.handleSelected}
                        error={this.state.numberOfCompetitorsError}
                      />
                    </div>
                    <div className="px-2 flex-1">
                      <InputNumber
                        label="No. Competitors"
                        name="numberOfCompetitors"
                        width="w-full"
                        value={this.state.numberOfCompetitors}
                        onChange={this.handleChange}
                        placeholder="Price"
                        error={this.state.numberOfCompetitorError}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-1/4 pt-2 pl-2 ">
                  <div className="text-grey-dark font-light text-xs pb-1">
                    Price Diff
                  </div>
                  <div className="border-1 borer-grey-light flex flex-row pb-2">
                    <div className="px-2 ">
                      <SingleSelectDropdown
                        name="priceDiffOperator"
                        label="Operator"
                        width="w-16"
                        options={this.state.priceDiffOperatorOptions}
                        selected={this.state.priceDiffOperator}
                        handleSelect={this.handleSelected}
                        error={this.state.priceDiffOperatorError}
                      />
                    </div>
                    <div className="px-2 flex-1">
                      <InputNumber
                        label="Price"
                        name="priceDiff"
                        width="w-full"
                        value={this.state.priceDiff}
                        onChange={this.handleChange}
                        placeholder="Price"
                        error={this.state.priceDiffError}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-2/5 h-full mt-6 ml-4">
              <div className="flex inline-block w-1/6 pt-2 h-17">
                <i className="material-icons xxl:text-2xl text-lg self-center text-grey-darker">
                  search
                </i>
                <input
                  className="appearance-none border-none cursor-pointer font-light text-sm focus:text-teal text-teal-dark input-placeholder focus:outline-none p-2"
                  placeholder="Tags"
                  onChange={(e) => this.handleSearchTags(e)}
                />
              </div>
              <div className="flex flex-row flex-wrap overflow-y-scroll max-h-10 xxxl:max-h-20">
                {tagList.map((tag) => {
                  return (
                    <div
                      key={tag.id}
                      onClick={() => this.tagSelect(tag)}
                      className={`${
                        this.state.selectedTag.find(
                          (selectedTag) => selectedTag.id === tag.id
                        )
                          ? "bg-teal-dark text-white"
                          : "bg-table-grey text-grey-darker"
                      } flex flex-row my-1 items-center font-light xxl:text-sm text-xs rounded-full py-1 pr-2 pl-3 xxl:mx-2 mx-1 flex whitespace-no-wrap ${
                        this.state.selectedTag.find(
                          (selectedTag) => selectedTag.id === tag.id
                        )
                          ? "hover:bg-teal-darker hover:text-white "
                          : "hover:bg-teal hover:text-white"
                      } cursor-pointer`}
                    >
                      {tag.name}{" "}
                      <i className="material-icons xxl:text-lg text-md ml-1">
                        {this.state.selectedTag.find(
                          (selectedTag) => selectedTag.id === tag.id
                        )
                          ? "clear"
                          : "add"}
                      </i>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full justify-end">
            <div className="flex flex-col w-2/5 items-end flex-wrap mr-12">
              <div className="flex-1 flex justify-end items-end">
                <div
                  className="xxl:py-2 xxl:px-3 py-1 px-2 h-10 flex items-center justify-center xxl:text-sm text-xs text-teal-dark hover:text-teal mx-2"
                  onClick={() => this.clearFilters()}
                >
                  <span className="cursor-pointer font-light font-sans">
                    CLEAR
                  </span>
                </div>

                <div
                  className="xxl:py-2 xxl:px-3 py-1 px-2 h-10 flex items-center justify-center bg-teal-dark hover:bg-teal xxl:text-sm text-xs text-white mx-2 rounded-sm cursor-pointer"
                  onClick={() => this.handleFilterPricePosition()}
                >
                  FILTER
                </div>

                <div className="pl-2 flex flex-row h-10">
                  <div className="w-1 h-full relative ">
                    {this.state.hoverTooltip && (
                      <div className="h-6 absolute pin-r mr-4 xxl:mt-1">
                        <TooltipRight text="EXPORT" />
                      </div>
                    )}
                  </div>

                  <div
                    onClick={() => this.handleExport()}
                    onMouseEnter={this.tooltipOnMouseEnter}
                    onMouseLeave={this.tooltipOnMouseLeave}
                    className="h-full px-2 flex items-center justify-center text-white bg-teal-dark hover:bg-teal cursor-pointer rounded-sm"
                  >
                    <i className="material-icons">
                      {this.props.pricePositionLoading
                        ? SmallLoadingSpinner
                        : "save_alt"}
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-end ">
            {this.props.pricePositionLoading && (
              <div className="w-full flex justify-end py-1">
                <div className="text-grey-dark font-light xxl:text-sm text-xs pr-10">
                  Please wait. Exporting may take a minute.
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPricePositionData: (page, rowSize, attr, direction) =>
      dispatch(getPricePositionData(page, rowSize, attr, direction)),
    getBrandsByMarket: () => dispatch(getBrandsByMarket()),
    getCategoriesByMarket: () => dispatch(getCategoriesByMarket()),
    getAllTags: () => dispatch(getAllTags()),
    filterPricePosition: (page, rowSize, idList, attr, direction) =>
      dispatch(filterPricePosition(page, rowSize, idList, attr, direction)),
    getExportPricePosition: (query) => dispatch(getExportPricePosition(query)),
    setFiltered: (val) => dispatch(setFiltered(val)),
    getAllCompetitors: () => dispatch(getAllCompetitors()),
  };
};

const mapStateToProps = (state) => {
  return {
    categories: state.products.categories,
    brands: state.products.brands,
    tagList: state.tags.tagList,
    competitors: state.priceRule.competitorList,
    pricePositionLoading: state.exportData.pricePositionLoading,
    idList: state.pricePosition.idList,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PricePositionFilter);
