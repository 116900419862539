import React, { Component } from "react";
import { connect } from "react-redux";
import { InputText } from "components/InputText";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import { FormContentWrapper } from "containers/Forms/FormContainer/FormContentWrapper";
import { closeFormpanel } from "ducks/formpanel";
import * as FORM_ACTION from "utils/form-actions";
import SingleSelectDropdown from "components/SingleSelectDropdown";
import InputNumber from "components/InputNumber";
// import {channels, priceRules} from "services/priceCampaign";
import moment from "moment";
import { createCampaign, updateCampaign } from "ducks/campaigns";

import {
  days,
  years,
  months,
  checkLeapYear,
  minutes,
  hours,
} from "utils/dates";

class PriceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSave: false,
      priceCampaign: "",
      priority: "",
      start: {
        day: {},
        month: {},
        year: {},
        hour: {},
        minutes: {},
      },
      end: {
        day: {},
        month: {},
        year: {},
        hour: {},
        minutes: {},
      },
      noEndDateChecked: false,
      // selectedChannels: [],
      priceCampaignError: false,
      priorityError: false,
      priorityFormatError: false,
      startError: {
        day: false,
        month: false,
        year: false,
        hour: false,
        minutes: false,
      },
      endError: false,
      // selectedChannelsError: false,
      startDayOptions: [],
      endDayOptions: [],
      yearOptions: [],
      hourOptions: [],
      minuteOptions: [],
      currentDayOption: [],
      currentHourOption: [],
      currentMinuteOption: [],
      currentMonthsOption: [],
      currentDay: {},
      currentYear: {},
      currentMonth: {},
      currentHour: {},
      startToday: false,
      startThisYear: false,
      startThisMonth: false,
      startThisHour: false,
      endThisHour: false,
      endToday: false,
      endThisMonth: false,
      endThisYear: false,
    };
  }

  componentDidMount() {
    let now = new Date();
    let currentHour = now.getHours();
    let currentMinute = now.getMinutes();
    let currentDay = now.getDate();
    let currentYear = now.getFullYear();
    let currentMonth = now.getMonth() + 1;
    //CHECK CURRENT HOUR AND MAKE ALL PAST HOURS UNAVAILABLE
    let allHours = hours();
    let findHour = allHours.find((hour) => parseInt(hour.name) == currentHour);
    let currentIndexHours = allHours.indexOf(findHour);
    let availableHours = allHours.slice(currentIndexHours, allHours.length + 1);
    //CHECK CURRENT MINUTES AND MAKE ALL PAST MINUTES UNAVAILABLE
    let allMinutes = minutes();
    let findMinute = allMinutes.find(
      (minute) =>
        parseInt(minute.name) == (Math.ceil(currentMinute / 15) * 15) % 60
    );
    let currentIndexMinutes = allMinutes.indexOf(findMinute);
    let availableMinutes = allMinutes.slice(
      currentIndexMinutes,
      allMinutes.length + 1
    );
    //CHECK CURRENT DAY AND MAKE ALL PAST DAYS UNAVAILABLE
    let allDays = days(31);
    let findDay = allDays.find((day) => parseInt(day.name) == currentDay);
    let currentIndexDays = allDays.indexOf(findDay);
    let availableDays = allDays.slice(currentIndexDays, allDays.length + 1);
    //CHECK CURRENT MONTH AND MAKE ALL PAST MONTHS UNAVAILABLE
    let allMonths = months;
    let findMonth = allMonths.find((month) => month.id == currentMonth);
    let currentIndexMonths = allMonths.indexOf(findMonth);
    let availableMonths = allMonths.slice(
      currentIndexMonths,
      allMonths.length + 1
    );

    //SET YEAROPTIONS FROM TODAY AND 10 YEARS FORWARD
    let yearOptions = [];
    let i = 0;
    for (i = currentYear; i <= currentYear + 10; i++) {
      yearOptions.push({
        id: i,
        name: JSON.stringify(i),
        type: "year",
      });
    }
    this.setState(
      {
        startDayOptions: days(31),
        endDayOptions: days(31),
        yearOptions: yearOptions,
        hourOptions: hours(),
        minuteOptions: minutes(),
        currentDayOption: availableDays,
        currentHourOption: availableHours,
        currentMinuteOption: availableMinutes,
        currentMonthsOption: availableMonths,
        currentHour: currentHour,
        currentDay: currentDay,
        currentYear: currentYear,
        currentMonth: currentMonth,
      },
      () => {
        if (this.props.formState === FORM_ACTION.STATE_EDIT) {
          let campaignData = this.props.data;
          let localStartingDate = moment(campaignData.startDate)
            .local()
            .format("YYYY-MM-DD HH:mm");

          let localEndingDate = campaignData.to
            ? moment(campaignData.endDate).local().format("YYYY-MM-DD HH:mm")
            : {};

          let start = localStartingDate.split(" ");
          let startDate = start[0].split("-");
          let startTime = start[1].split(":");

          let end = campaignData.to ? localEndingDate.split(" ") : {};
          let endDate = campaignData.to && end[0].split("-");
          let endTime = campaignData.to && end[1].split(":");

          start.day = this.state.startDayOptions.find(
            (day) => day.id === parseInt(startDate[2])
          );

          start.year = this.state.yearOptions.find(
            (year) => year.name === startDate[0]
          )
            ? this.state.yearOptions.find((year) => year.name === startDate[0])
            : {
                id: parseInt(startDate[0]),
                name: startDate[0],
                type: "year",
              };
          start.month = months.find(
            (month) => month.id === parseInt(startDate[1])
          );
          start.hour = this.state.hourOptions.find(
            (hour) => hour.id === parseInt(startTime[0])
          );
          start.minutes = this.state.minuteOptions.find(
            (minute) => minute.id === parseInt(startTime[1])
          );

          end.day = campaignData.to
            ? this.state.endDayOptions.find(
                (day) => day.id === parseInt(endDate[2])
              )
            : {};

          end.year = campaignData.to
            ? this.state.yearOptions.find((year) => year.name === endDate[0])
            : {};

          end.month = campaignData.to
            ? months.find((month) => month.id === parseInt(endDate[1]))
            : {};
          end.hour = campaignData.to
            ? this.state.hourOptions.find(
                (hour) => hour.id === parseInt(endTime[0])
              )
            : {};
          end.minutes = campaignData.to
            ? this.state.minuteOptions.find(
                (minute) => minute.id === parseInt(endTime[1])
              )
            : {};

          if (!campaignData.to) {
            this.setState({ noEndDateChecked: true });
          }

          this.setState({
            priceCampaign: campaignData.name,
            priority: campaignData.priority,

            start: start,
            end: end,
            // selectedChannels: campaignData.channels,
            yearOptions: yearOptions,
          });
        }
      }
    );
  }

  //INPUT AND SELECT FUNCTIONS//
  handleChange = (name, event) => {
    if (name === "priority" && parseInt(event.target.value) < 1) {
      this.setState({ priorityFormatError: true });
    } else {
      this.setState({
        [name]: event.target.value,
        [name + "Error"]: false,
        canSave: true,
        priorityFormatError: false,
      });
    }
  };

  handleSelected = (name, selected) => {
    this.setState({ [name]: selected, [name + "Error"]: false, canSave: true });
  };

  handleSelectedStartDate = (name, selected) => {
    if (
      name === "hour" ||
      name === "day" ||
      name === "month" ||
      name === "year"
    ) {
      if (
        (selected.type === "year" &&
          this.state.currentYear === parseInt(selected.name) &&
          this.state.start.month &&
          this.state.currentMonth === this.state.start.month.id &&
          this.state.start.day &&
          this.state.currentDay === parseInt(this.state.start.day.name) &&
          this.state.start.hour &&
          this.state.currentHour === parseInt(this.state.start.hour.name)) ||
        (selected.type === "month" &&
          this.state.start.year &&
          this.state.currentYear === parseInt(this.state.start.year.name) &&
          this.state.currentMonth === selected.id &&
          this.state.start.day &&
          this.state.currentDay === parseInt(this.state.start.day.name) &&
          this.state.start.hour &&
          this.state.currentHour === parseInt(this.state.start.hour.name)) ||
        (selected.type === "day" &&
          this.state.start.year &&
          this.state.currentYear === parseInt(this.state.start.year.name) &&
          this.state.start.month &&
          this.state.currentMonth === this.state.start.month.id &&
          this.state.currentDay === parseInt(selected.name) &&
          this.state.start.hour &&
          this.state.currentHour === parseInt(this.state.start.hour.name)) ||
        (selected.type === "hour" &&
          this.state.start.year &&
          this.state.currentYear === parseInt(this.state.start.year.name) &&
          this.state.start.month &&
          this.state.currentMonth === this.state.start.month.id &&
          this.state.start.day &&
          this.state.currentDay === parseInt(this.state.start.day.name) &&
          this.state.currentHour === parseInt(selected.name))
      ) {
        this.setState({
          startThisHour: true,
        });
      } else {
        this.setState({
          startThisHour: false,
        });
      }
    }
    if (name === "day" || name === "month" || name === "year")
      if (
        (selected.type === "year" &&
          this.state.currentYear === parseInt(selected.name) &&
          this.state.start.month &&
          this.state.currentMonth === this.state.start.month.id &&
          this.state.start.day &&
          this.state.currentDay === parseInt(this.state.start.day.name)) ||
        (selected.type === "month" &&
          this.state.start.year &&
          this.state.currentYear === parseInt(this.state.start.year.name) &&
          this.state.currentMonth === selected.id &&
          this.state.start.day &&
          this.state.currentDay === parseInt(this.state.start.day.name)) ||
        (selected.type === "day" &&
          this.state.start.year &&
          this.state.currentYear === parseInt(this.state.start.year.name) &&
          this.state.start.month &&
          this.state.currentMonth === this.state.start.month.id &&
          this.state.currentDay === parseInt(selected.name))
      ) {
        this.setState({
          startToday: true,
        });
      } else {
        this.setState({
          startToday: false,
        });
      }
    if (name === "month" || name === "year") {
      if (
        (selected.type === "year" &&
          this.state.currentYear === parseInt(selected.name) &&
          this.state.currentMonth === this.state.start.month.id) ||
        (selected.type === "month" &&
          this.state.currentMonth === selected.id &&
          this.state.currentYear === parseInt(this.state.start.year.name))
      ) {
        this.setState({
          startThisMonth: true,
        });
      } else {
        this.setState({
          startThisMonth: false,
        });
      }
    }
    if (name === "year") {
      if (
        this.state.start.year &&
        this.state.currentYear == parseInt(selected.name)
      ) {
        this.setState({
          startThisYear: true,
        });
      } else {
        this.setState({
          startThisYear: false,
        });
      }
    }
    let start = { ...this.state.start };
    let startError = { ...this.state.startError };
    start[name] = selected;
    startError[name] = false;
    this.setState({ start, startError, canSave: true }, () => {
      this.rebuildDates(selected, start, "start", "startDayOptions");
    });
  };

  handleSelectedEndDate = (name, selected) => {
    if (
      name === "hour" ||
      name === "day" ||
      name === "month" ||
      name === "year"
    ) {
      if (
        (selected.type === "year" &&
          this.state.currentYear === parseInt(selected.name) &&
          this.state.end.month &&
          this.state.currentMonth === this.state.end.month.id &&
          this.state.end.day &&
          this.state.currentDay === parseInt(this.state.end.day.name) &&
          this.state.end.hour &&
          this.state.currentHour === parseInt(this.state.end.hour.name)) ||
        (selected.type === "month" &&
          this.state.end.year &&
          this.state.currentYear === parseInt(this.state.end.year.name) &&
          this.state.currentMonth === selected.id &&
          this.state.end.day &&
          this.state.currentDay === parseInt(this.state.end.day.name) &&
          this.state.end.hour &&
          this.state.currentHour === parseInt(this.state.end.hour.name)) ||
        (selected.type === "day" &&
          this.state.end.year &&
          this.state.currentYear === parseInt(this.state.end.year.name) &&
          this.state.end.month &&
          this.state.currentMonth === this.state.end.month.id &&
          this.state.currentDay === parseInt(selected.name) &&
          this.state.end.hour &&
          this.state.currentHour === parseInt(this.state.end.hour.name)) ||
        (selected.type === "hour" &&
          this.state.end.year &&
          this.state.currentYear === parseInt(this.state.end.year.name) &&
          this.state.end.month &&
          this.state.currentMonth === this.state.end.month.id &&
          this.state.end.day &&
          this.state.currentDay === parseInt(this.state.end.day.name) &&
          this.state.currentHour === parseInt(selected.name))
      ) {
        this.setState({
          endThisHour: true,
        });
      } else {
        this.setState({
          endThisHour: false,
        });
      }
    }
    if (name === "day" || name === "month" || name === "year")
      if (
        (selected.type === "year" &&
          this.state.currentYear === parseInt(selected.name) &&
          this.state.end.month &&
          this.state.currentMonth === this.state.end.month.id &&
          this.state.end.day &&
          this.state.currentDay === parseInt(this.state.end.day.name)) ||
        (selected.type === "month" &&
          this.state.end.year &&
          this.state.currentYear === parseInt(this.state.end.year.name) &&
          this.state.currentMonth === selected.id &&
          this.state.end.day &&
          this.state.currentDay === parseInt(this.state.end.day.name)) ||
        (selected.type === "day" &&
          this.state.end.year &&
          this.state.currentYear === parseInt(this.state.end.year.name) &&
          this.state.end.month &&
          this.state.currentMonth === this.state.end.month.id &&
          this.state.currentDay === parseInt(selected.name))
      ) {
        this.setState({
          endToday: true,
        });
      } else {
        this.setState({
          endToday: false,
        });
      }
    if (name === "month" || name === "year") {
      if (
        (selected.type === "year" &&
          this.state.currentYear === parseInt(selected.name) &&
          this.state.currentMonth === this.state.end.month.id) ||
        (selected.type === "month" &&
          this.state.currentMonth === selected.id &&
          this.state.currentYear === parseInt(this.state.end.year.name))
      ) {
        this.setState({
          endThisMonth: true,
        });
      } else {
        this.setState({
          endThisMonth: false,
        });
      }
    }
    if (name === "year") {
      if (
        this.state.end.year &&
        this.state.currentYear == parseInt(selected.name)
      ) {
        this.setState({
          endThisYear: true,
        });
      } else {
        this.setState({
          endThisYear: false,
        });
      }
    }
    let end = { ...this.state.end };
    end[name] = selected;
    this.setState({ end, endError: false, canSave: true }, () => {
      this.rebuildDates(selected, end, "end", "endDayOptions");
    });
  };
  //END INPUT AND SELECT FUNCTIONS//

  //DATE PARSING FUNCTIONS//
  rebuildDates = (selected, data, name, dayOptions) => {
    if (
      selected.type === "month" &&
      selected.days !== 31 &&
      data.day.id === 31
    ) {
      data.day = {};
      this.setState({ [dayOptions]: days(selected.days), [name]: data });
    }
    if (selected.type === "year" && data.month.id === 2) {
      if (checkLeapYear(selected.name)) {
        this.setState({ [dayOptions]: days(29) });
      } else {
        if (data.day.id === 29) {
          data.day = {};
          this.setState({ [name]: data });
        }
        this.setState({ [dayOptions]: days(28) });
      }
    }
  };

  parseTime = (time) => {
    return (time = time < 10 ? "0" + time : time);
  };
  //END DATE PARSING FUNCTIONS//

  //FORM ERROR-HANDLING, SUBMIT AND CLOSE FUNCTION//
  handleErrorCheck = () => {
    let error = false;
    let startErrorFlag = false;
    let endErrorFlag = false;
    let startError = { ...this.state.startError };
    // let endError = {...this.state.endError};
    if (this.state.priceCampaign.length < 1) {
      error = true;
      this.setState({ priceCampaignError: true });
    }
    if (this.state.priority.length < 1) {
      error = true;
      this.setState({ priorityError: true, priorityFormatError: false });
    }
    // if (this.state.selectedChannels.length < 1) {
    //   error = true;
    //   this.setState({ selectedChannelsError: true });
    // }
    Object.keys(this.state.start).forEach((key) => {
      if (Object.keys(this.state.start[key]).length < 1) {
        startError[key] = true;
        startErrorFlag = true;
        error = true;
      }
    });

    if (!this.state.noEndDateChecked) {
      Object.keys(this.state.end).forEach((key) => {
        if (Object.keys(this.state.end[key]).length < 1) {
          endErrorFlag = true;
        }
      });
    }

    if (endErrorFlag && !this.state.noEndDateChecked) {
      error = true;
    } else if (endErrorFlag && this.state.noEndDateChecked) {
      error = false;
      endErrorFlag = false;
    }

    if (startErrorFlag) {
      this.setState({ startError });
    }
    if (endErrorFlag) {
      this.setState({ endError: endErrorFlag });
    }
    return error;
  };

  handleSubmit = (event) => {
    let { start } = this.state;
    let { end } = this.state;
    let startDate =
      start.year.name +
      "/" +
      this.parseTime(start.month.id) +
      "/" +
      start.day.name +
      " " +
      start.hour.name +
      ":" +
      start.minutes.name +
      ":00:00";
    let endDate =
      end.year.name +
      "/" +
      this.parseTime(end.month.id) +
      "/" +
      end.day.name +
      " " +
      end.hour.name +
      ":" +
      end.minutes.name +
      ":00:00";

    let data = {
      name: this.state.priceCampaign,
      startDate:
        moment.utc(moment(startDate)).format("YYYY-MM-DDTHH:mm:ss") + "Z",

      endDate: this.state.noEndDateChecked
        ? null
        : moment.utc(moment(endDate)).format("YYYY-MM-DDTHH:mm:ss") + "Z",
      priority: parseInt(this.state.priority),
      // SENDING ONLY ID UNTIL BE REMOVES
      channels: [
        {
          id: parseInt(localStorage.getItem("channel")),
        },
      ],
    };

    if (!this.handleErrorCheck()) {
      if (this.props.formState === FORM_ACTION.STATE_EDIT) {
        data.id = this.props.data.id;
        this.props.updateCampaign(
          data,
          this.props.currentPage,
          this.props.rowSize,
          this.props.sortingAttr,
          this.props.sortingDirection
        );
      } else {
        this.props.createCampaign(
          data,
          this.props.currentPage,
          this.props.rowSize,
          this.props.sortingAttr,
          this.props.sortingDirection
        );
      }
    }
  };

  handleCancel = () => {
    this.props.closeFormpanel();
  };

  handleNoEndData = () => {
    this.setState((prevState) => ({
      noEndDateChecked: !prevState.noEndDateChecked,
      canSave: true,
      endError: false,
    }));
  };
  //END FORM SUBMIT AND CLOSE FUNCTION//

  render() {
    return (
      <FormContentWrapper
        formConfig={{
          formTitle:
            this.props.formState === FORM_ACTION.STATE_EDIT
              ? "Edit Campaign"
              : "Create New Campaign",
          buttonLabel:
            this.props.formState === FORM_ACTION.STATE_EDIT
              ? "UPDATE"
              : "CREATE",
        }}
        formName="campaigns"
        handleSubmit={this.handleSubmit}
        handleCancel={this.handleCancel}
        canSave={this.state.canSave}
      >
        <div className="w-full flex flex-row my-8">
          <div className="flex-1 pr-4">
            <InputText
              width="w-full"
              label="Campaign Name"
              value={this.state.priceCampaign}
              onChange={(event) => this.handleChange("priceCampaign", event)}
              placeholder="Name of Campaign"
              required={true}
              error={this.state.priceCampaignError}
            />
          </div>
          <div className="w-1/4 flex flex-col">
            <InputNumber
              width="w-full"
              label="Priority"
              name="priority"
              value={this.state.priority}
              onChange={(event) => this.handleChange("priority", event)}
              placeholder="0000"
              required={true}
              error={this.state.priorityError}
              showErrorMessage={this.state.priorityFormatError}
              errorMessage="Invalid Format"
            />
          </div>
        </div>

        <h4 className="font-light font-sans text-teal-dark mb-1">Start Date</h4>
        <div className="flex flex-row w-full flex-wrap">
          <div className="w-1/3 pr-4">
            <SingleSelectDropdown
              width="w-full"
              label="Year"
              name="year"
              options={this.state.yearOptions}
              selected={this.state.start.year}
              handleSelect={this.handleSelectedStartDate}
              placeholder="Year"
              required={true}
              error={this.state.startError.year}
            />
          </div>
          <div className="w-1/3 pr-4">
            <SingleSelectDropdown
              width="w-full"
              label="Month"
              name="month"
              options={
                this.state.startThisYear
                  ? this.state.currentMonthsOption
                  : months
              }
              selected={this.state.start.month}
              handleSelect={this.handleSelectedStartDate}
              placeholder="Month"
              required={true}
              error={this.state.startError.month}
            />
          </div>
          <div className="w-1/3 pr-4">
            <SingleSelectDropdown
              width="w-full"
              label="Day"
              name="day"
              options={
                this.state.startThisMonth
                  ? this.state.currentDayOption
                  : this.state.startDayOptions
              }
              selected={this.state.start.day}
              handleSelect={this.handleSelectedStartDate}
              placeholder="Day"
              required={true}
              error={this.state.startError.day}
            />
          </div>
          <div className="w-1/3 pr-4">
            <SingleSelectDropdown
              width="w-full"
              label="Hour"
              name="hour"
              options={
                this.state.startToday
                  ? this.state.currentHourOption
                  : this.state.hourOptions
              }
              selected={this.state.start.hour}
              handleSelect={this.handleSelectedStartDate}
              placeholder="Hour"
              required={true}
              error={this.state.startError.hour}
            />
          </div>
          <div className="w-1/3 pr-4">
            <SingleSelectDropdown
              width="w-full"
              label="Minutes"
              name="minutes"
              options={
                this.state.startThisHour
                  ? this.state.currentMinuteOption
                  : this.state.minuteOptions
              }
              selected={this.state.start.minutes}
              handleSelect={this.handleSelectedStartDate}
              placeholder="Minutes"
              required={true}
              error={this.state.startError.minutes}
            />
          </div>
        </div>

        <h4 className="font-light font-sans text-teal-dark mt-8 mb-1 flex flex-row">
          End Date{" "}
          {this.state.endError && (
            <span className="inline-block text-red text-sm px-2">
              An End Date must be selected.
            </span>
          )}
        </h4>

        <div className="w-full flex flex-row py-2">
          <i
            className="material-icons text-teal-dark text-xl cursor-pointer"
            onClick={() => this.handleNoEndData()}
          >
            {this.state.noEndDateChecked
              ? "check_circle"
              : "radio_button_unchecked"}
          </i>
          <div className="px-2 font-sans font-light text-sm text-grey-darker">
            Campaign has no End Date.
          </div>
        </div>

        <div className="flex flex-row w-full flex-wrap">
          <div className="w-1/3 pr-4">
            <SingleSelectDropdown
              width="w-full"
              label="Year"
              name="year"
              options={
                this.state.noEndDateChecked ? [] : this.state.yearOptions
              }
              selected={this.state.noEndDateChecked ? {} : this.state.end.year}
              placeholder={
                this.state.noEndDateChecked && this.state.end.year
                  ? this.state.end.year.name
                  : "Year"
              }
              handleSelect={this.handleSelectedEndDate}
              error={this.state.endError.year}
            />
          </div>
          <div className="w-1/3 pr-4">
            <SingleSelectDropdown
              width="w-full"
              label="Month"
              name="month"
              options={
                this.state.noEndDateChecked
                  ? []
                  : this.state.endThisYear
                  ? this.state.currentMonthsOption
                  : months
              }
              selected={this.state.noEndDateChecked ? {} : this.state.end.month}
              handleSelect={this.handleSelectedEndDate}
              placeholder={
                this.state.noEndDateChecked && this.state.end.month
                  ? this.state.end.month.name
                  : "Month"
              }
              error={this.state.endError.month}
            />
          </div>
          <div className="w-1/3 pr-4">
            <SingleSelectDropdown
              width="w-full"
              label="Day"
              name="day"
              options={
                this.state.noEndDateChecked
                  ? []
                  : this.state.endThisMonth
                  ? this.state.currentDayOption
                  : this.state.endDayOptions
              }
              selected={this.state.end.day}
              handleSelect={this.handleSelectedEndDate}
              placeholder={
                this.state.noEndDateChecked && this.state.end.day
                  ? this.state.end.day.name
                  : "Day"
              }
              error={this.state.endError.day}
            />
          </div>
          <div className="w-1/3 pr-4">
            <SingleSelectDropdown
              width="w-full"
              label="Hour"
              name="hour"
              options={
                this.state.noEndDateChecked
                  ? []
                  : this.state.endToday
                  ? this.state.currentHourOption
                  : this.state.hourOptions
              }
              selected={this.state.noEndDateChecked ? {} : this.state.end.hour}
              placeholder={
                this.state.noEndDateChecked && this.state.end.hour
                  ? this.state.end.hour.name
                  : "Hour"
              }
              handleSelect={this.handleSelectedEndDate}
              error={this.state.endError.hour}
            />
          </div>
          <div className="w-1/3 pr-4">
            <SingleSelectDropdown
              width="w-full"
              label="Minutes"
              name="minutes"
              options={
                this.state.noEndDateChecked
                  ? []
                  : this.state.endThisHour
                  ? this.state.currentMinuteOption
                  : this.state.minuteOptions
              }
              selected={
                this.state.noEndDateChecked ? {} : this.state.end.minutes
              }
              placeholder={
                this.state.noEndDateChecked && this.state.end.minutes
                  ? this.state.end.minutes.name
                  : "Minutes"
              }
              handleSelect={this.handleSelectedEndDate}
              error={this.state.endError.minutes}
            />
          </div>
        </div>
        {/* <div className="w-full my-8 pr-4">
          <MultiSelectDropdown
            width="w-full"
            label="Channels"
            name="selectedChannels"
            options={this.props.channels}
            selected={this.state.selectedChannels}
            handleSelect={this.handleSelected}
            placeholder="Channels"
            required={true}
            error={this.state.selectedChannelsError}
          />
        </div> */}
      </FormContentWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeFormpanel: () => dispatch(closeFormpanel()),
    createCampaign: (data, page, rowSize, attr, direction) =>
      dispatch(createCampaign(data, page, rowSize, attr, direction)),
    updateCampaign: (data, page, rowSize, attr, direction) =>
      dispatch(updateCampaign(data, page, rowSize, attr, direction)),
  };
};

const mapStateToProps = (state) => {
  return {
    formState: state.formpanel.formState,
    currentPage: state.tablePagination.campaignPage,
    rowSize: state.tablePagination.campaignRowSize,
    sortingAttr: state.tablePagination.campaignSortingAttr,
    sortingDirection: state.tablePagination.campaignSortingDirection,
    // channels: state.campaigns.channels
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceList);
