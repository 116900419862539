import React, { Component } from "react";

import { connect } from "react-redux";
import {
  searchProducts,
  clearSearchResults,
  // updateProductCompetitor,
  clearSearchError,
} from "ducks/productMatching";
import { getProductMatchingCompetitors } from "ducks/productMatching";

import { getAllCompetitors } from "ducks/priceRule";

import ProductListCard from "components/ProductListCard/ProductListCard";
// import { updateProductTableInputsOnBlur } from "ducks/products";

class ProductMatchingList extends Component {
  handleUpdateProductCompetitor = (type, product) => {
    if (type === "verifyAll") {
      let productList = { actionType: "verifyAll", products: [] };
      this.props.productMatchingCompetitors.map((competitor) => {
        productList.products.push({
          ...competitor.product,
          actionType: "verify",
        });
      });
      this.props.handleProducts(productList);
    } else {
      this.props.handleProducts({
        ...product,
        actionType: type,
        newlyUpdated: true,
      });
    }
  };

  render() {
    let verifiedProducts = 0;
    this.props.productMatchingCompetitors.map((competitor) => {
      return competitor.userState === "VERIFIED" && verifiedProducts++;
    });
    const headers = [
      "",
      "",
      "Name",
      "Brand",
      "Category",
      "Price",
      "Active",
      "Article No",
      "Updated By",
      "Action",
      "Verified",
    ];

    return (
      <div className="w-full flex ">
        <div className="w-9/10 flex flex-col">
          <div className="w-full flex flex-row mb-4 mt-6">
            {headers.map((header, i) => (
              <div
                key={i}
                className="w-1/10 text-xs text-grey-darker text-center"
              >
                {header}
              </div>
            ))}
          </div>
          <ProductListCard
            id={this.props.singleProduct.id}
            competitor={null}
            image={this.props.singleProduct.imgUrl}
            name={this.props.singleProduct.name}
            brand={this.props.singleProduct.brand}
            category={this.props.singleProduct.category}
            price={this.props.price && this.props.price.currentPrice ? this.props.price.currentPrice : ""}
            articleNo={this.props.singleProduct.articleNumber}
            updatedBy={this.props.price ? this.props.price.priceLastModifiedBy : ""}
            verified={
              verifiedProducts +
              "/" +
              this.props.productMatchingCompetitors.length
            }
            ownProduct={true}
            disableButton={
              this.props.productMatchingCompetitors.length === verifiedProducts
            }
            onClick={this.handleUpdateProductCompetitor}
          />
          <div className="flex flex-col h-74 xxl:h-88 overflow-y-auto w-full">
            {this.props.productMatchingCompetitors
              .filter((item) => item.userState !== "UNMATCHED")
              .map((competitor) => {
                let verifiedProduct = competitor.userState === "VERIFIED";

                return (
                  <ProductListCard
                    id={competitor.product.id}
                    competitor={competitor.domainName}
                    image={competitor.product.imgUrl}
                    name={competitor.product.name}
                    brand={competitor.product.brand}
                    category={competitor.product.category}
                    price={competitor.product.price}
                    articleNo={competitor.product.articleNumber}
                    updatedBy={competitor.updatedBy}
                    verified={verifiedProduct ? true : false}
                    active={competitor.product.active === "Y" ? true : false}
                    ownProduct={false}
                    product={competitor.product}
                    onClick={this.handleUpdateProductCompetitor}
                  />
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // updateProductCompetitor: (type, product, domainName, row) =>
    //   dispatch(updateProductCompetitor(type, product, domainName, row)),
    getAllCompetitors: () => dispatch(getAllCompetitors()),
    getProductMatchingComp: (id) => dispatch(getProductMatchingCompetitors(id)),
  };
};

const mapStateToProps = (state) => {
  return {
    productMatchingCompetitorsLoading:
      state.productMatching.productMatchingCompetitorsLoading,
    productMatchingCompetitors:
      state.productMatching.productMatchingCompetitors,
    price: state.products.pricePositionChart,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductMatchingList);
