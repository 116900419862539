import React, { Component } from "react";
import QueryInput from "components/InlineFilterInput/QueryInput";
import QueryDropdown from "components/InlineFilterInput/QueryDropdown";
import SingleDropdown from "components/InlineFilterInput/SingleDropdown";
import MultiDropdown from "components/InlineFilterInput/MultiDropdown";
import TextInput from "components/InlineFilterInput/TextInput";
import MultiInput from "components/InlineFilterInput/MultiInput";
import DatePicker from "components/AdvancedDatePicker/AdvancedDatePicker";

class InlineFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedKeyValue: [],
      equalityOperator: [
        {
          id: 1,
          name: "Equal To",
          operator: "=",
        },
        {
          id: 2,
          name: "Not Equal To",
          operator: "≠",
        },
      ],
      logicOperators: [
        {
          id: 1,
          name: "And",
        },
        {
          id: 2,
          name: "Or",
        },
        {
          id: 3,
          name: "Not",
        },
        {
          id: 4,
          name: "End Query",
        },
      ],
      priceOperators: [
        {
          id: 1,
          name: ">=",
        },
        {
          id: 2,
          name: ">",
        },
        {
          id: 3,
          name: "=",
        },
        {
          id: 4,
          name: "<",
        },
        {
          id: 5,
          name: "=<",
        },
        {
          id: 6,
          name: "=<",
        },
      ],
      showAll: false,
      options: [],
      queryOptions: [],
      dateOptions: [],
      key: "",
      value: "",
      query: [],
      multiSelect: [],
      dropdown: false,
      cursor: -1,
      showDelete: false,
      expandFilter: true,
      targetQuery: "",
      openEditDropdown: false,
      editIsOpen: false,
      colorList: [
        "orange-light",
        "green-light",
        "red-light",
        "teal-dark",
        "blue-light",
        "pink-light",
        "indigo-light",
        "purple-light",
        "orange-light",
        "green-light",
        "red-light",
      ],
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);

    this.setState({
      options: this.props.filterKeyOptions,
      queryOptions: this.props.filterKeyOptions,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleChange = (e) => {
    let keyCap =
      this.state.key.charAt(0).toLowerCase() + this.state.key.slice(1);
    let keyName = keyCap.replace(/\s/g, "");
    let item =
      this.state.key.length > 0 &&
      this.props.filterKeyOptions.find((item) =>
        item.name.toLowerCase().includes(keyName.toLocaleLowerCase())
      );
    if (item.type === "interval") {
      let reg = /^\d+$/;
      if (reg.test(e.target.value)) {
        this.setState({
          value: e.target.value,
        });
      } else return;
    }
    this.setState({
      value: e.target.value,
    });
  };

  handleOutsideClick = (e) => {
    if (this.handleDropdown.contains(e.target)) {
      return;
    } else {
      this.setState({
        dropdown: false,
        cursor: -1,
        key: "",
        options: this.props.filterKeyOptions,
        query: [],
        editIsOpen: false,
        openEditDropdown: false,
        multiSelect: [],
        openEditDropdown: false,
      });
    }
  };

  handlePriceQuery = (index, item) => {
    if (this.state.queryOptions.length > 0) {
      this.setState({
        value: "",
        queryOptions: [],
        query: [...this.state.query, item.name],
      });
    } else {
      this.setState({
        value: "",
        queryOptions: this.state.priceOperators,
        query: [...this.state.query, this.state.value],
      });
    }

    if (this.state.queryOptions.length < 1 && this.state.query.length > 0) {
      let queryList = [];
      queryList = this.state.query.concat(this.state.value);
      this.state.editIsOpen
        ? this.props.editInput(this.state.key, index, queryList)
        : this.props.createFilterArray({
            [this.state.key]: queryList,
          });
      this.setState({
        dropdown: false,
        query: [],
        value: "",
        key: "",
        options: this.props.filterKeyOptions,
        value: "",
        cursor: -1,
        editIsOpen: false,
        openEditDropdown: false,
      });
    }
  };

  handleGroupQuery = (index, item) => {
    const objectsEqual = (o1, o2) => {
      return typeof o1 === "object" && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length &&
            Object.keys(o1).every((p) => objectsEqual(o1[p].name, o2[p].name))
        : o1 === o2;
    };
    if (item.name === "End Query") {
      this.state.editIsOpen
        ? this.props.editInput(this.state.key, index, this.state.query)
        : this.props.createFilterArray({ [this.state.key]: this.state.query });
      this.setState({
        dropdown: false,
        value: "",
        key: "",
        options: this.props.filterKeyOptions,
        value: "",
        cursor: -1,
      });
    } else {
      if (objectsEqual(this.state.queryOptions, this.props.filterKeyOptions)) {
        return this.setState({
          queryOptions: this.state.equalityOperator,
          query: [...this.state.query, "(" + item.name],
          targetQuery: item.name,
        });
      }
      if (objectsEqual(this.state.queryOptions, this.state.equalityOperator)) {
        return this.setState({
          queryOptions: this.props.filterValueOptions[
            this.state.targetQuery.toLowerCase()
          ],
          query: [...this.state.query, item.operator],
        });
      }
      if (
        this.state.targetQuery.length > 0 &&
        objectsEqual(
          this.state.queryOptions,
          this.props.filterValueOptions[this.state.targetQuery.toLowerCase()]
        )
      ) {
        return this.setState({
          queryOptions: this.state.logicOperators,
          query: [...this.state.query, item.name + ")"],
        });
      }
      if (objectsEqual(this.state.queryOptions, this.state.logicOperators)) {
        let modifiedQueryOptions = this.props.filterKeyOptions.filter(
          (key) => key.name !== "Build Query"
        );
        return this.setState({
          queryOptions: modifiedQueryOptions,
          query: [...this.state.query, item.name],
        });
      }
      this.setState({
        queryOptions: this.state.equalityOperator,
        query: [...this.state.query, "(" + item.name],
        targetQuery: item.name,
      });
    }
  };

  selectedMulti = (index, selectedItems, item) => {
    let selectedList = [...selectedItems];
    let selectedItem = selectedList.indexOf(
      selectedList.find((option) => option === item.name)
    );
    if (selectedItem === -1) {
      selectedList.push(item.name);
    } else {
      selectedList.splice(selectedItem, 1);
    }
    if (item.name === "Select All") {
      if (selectedList.length - 1 !== this.state.options.length) {
        let selectAll = [];
        this.state.options.map((item) => selectAll.push(item.name));
        this.setState({
          multiSelect: selectAll,
        });
      } else {
        this.setState({
          multiSelect: [],
        });
      }
    } else if (this.state.key.length < 1 && this.state.value.length < 1) {
      let keyCap = item.name.charAt(0).toUpperCase() + item.name.slice(1);
      let keyName = keyCap.replace(/([a-z])([A-Z])/g, "$1 $2");
      this.setState({
        key: keyName,
        value: "",
        cursor: -1,
        options: this.props.tags,
      });
    } else if (this.state.key.length > 0) {
      this.setState({
        multiSelect: selectedList,
      });
    }
    if (item.name === "End Selection") {
      this.setState({
        dropdown: false,
        multiSelect: [],
        value: "",
        key: "",
        options: this.props.filterKeyOptions,
        value: "",
        cursor: -1,
        editIsOpen: false,
        openEditDropdown: false,
      });
      this.state.editIsOpen
        ? this.props.editInput(this.state.key, index, this.state.multiSelect)
        : this.props.createFilterArray({
            [this.state.key]: this.state.multiSelect,
          });
    }
  };

  handleSelect = (index, item) => {
    if (this.state.editIsOpen) {
      this.props.editInput(this.state.key, index, item.name);
      this.setState({
        openEditDropdown: false,
        value: "",
        key: "",
        options: this.props.filterKeyOptions,
        value: "",
        cursor: -1,
        editIsOpen: false,
      });
    }

    if (this.state.key.length < 1) {
      let keyCap = item.name.charAt(0).toUpperCase() + item.name.slice(1);
      let keyName = keyCap.replace(/([a-z])([A-Z])/g, "$1 $2");
      if (item.name === "Date") {
        this.setState({
          key: keyName,
          value: "",
          cursor: -1,
        });
      } else if (keyName === "Build Query") {
        this.setState({
          key: "Query",
          query: [],
          queryOptions: this.props.filterKeyOptions.filter(
            (key) => key.name !== "Build Query"
          ),
          value: "",
          cursor: -1,
        });
      } else if (keyName.includes("Price") || item.type === "interval") {
        this.setState({
          key: keyName,
          query: [],
          queryOptions: this.state.priceOperators,
          value: "",
          cursor: -1,
        });
      } else {
        this.setState({
          key: keyName,
          options: this.props.filterValueOptions[item.name.toLowerCase()],
          value: "",
          cursor: -1,
        });
      }
    } else {
      this.setState({
        dropdown: false,
        openEditDropdown: false,
        value: "",
        key: "",
        options: this.props.filterKeyOptions,
        value: "",
        cursor: -1,
        editIsOpen: false,
      });
      this.state.editIsOpen
        ? this.props.editInput(this.state.key, index, item.name)
        : this.props.createFilterArray({ [this.state.key]: item.name });
    }
  };

  openDropdown = () => {
    this.setState({
      dropdown: true,
      openEditDropdown: false,
      editIsOpen: false,
      key: "",
      options: this.props.filterKeyOptions,
      multiSelect: [],
      query: [],
    });
  };

  handleKeyDown = (index, e, options, item) => {
    const { cursor } = this.state;
    if (
      e.keyCode === 13 &&
      item.type === "interval" &&
      this.state.queryOptions.length < 1
    ) {
      this.handlePriceQuery(index, this.state.value);
    }
    if (e.keyCode === 13 && cursor > -1) {
      item.type === "interval"
        ? this.handlePriceQuery(index, options[this.state.cursor])
        : item.type === "query"
        ? this.handleSelectQuery(index, options[this.state.cursor])
        : this.handleSelect(index, options[this.state.cursor]);
    } else if (e.keyCode === 38 && cursor > 0) {
      this.setState((prevState) => ({
        cursor: prevState.cursor - 1,
      }));
    } else if (e.keyCode === 40 && cursor < options.length - 1) {
      this.setState((prevState) => ({
        cursor: prevState.cursor + 1,
      }));
    }
  };

  expandMore = () => {
    this.setState((prevState) => ({
      expandFilter: !prevState.expandFilter,
    }));
  };

  showAllSelected = () => {
    this.setState((prevState) => ({
      showAll: !prevState.showAll,
    }));
  };

  saveDate = (startDate, endDate, index) => {
    let start = "From: " + startDate;
    let end = " To: " + endDate;

    if (this.state.editIsOpen) {
      this.props.editInput("Date", index, start + end);
    } else {
      this.props.createFilterArray({ Date: start + end });
    }
    this.props.setDates(startDate, endDate);
    this.setState({
      key: "",
      dropdown: false,
      editIsOpen: false,
      openEditDropdown: false,
    });
  };

  reopenFilter = (item, index) => {
    let key = item.name.charAt(0).toUpperCase() + item.name.slice(1);
    let keyName = key.replace(/\s/g, "");
    let autoFillSelected = [];
    if (item.name === "tag") {
      autoFillSelected =
        this.props.filterValues.length > 0 &&
        this.props.filterValues.find((val) =>
          Object.keys(val).map((key) => key === "Tag")
        );
    }
    this.setState(
      (prevState) => ({
        openEditDropdown: { [index]: !prevState.openEditDropdown[index] },
        key: keyName,
        multiSelect: autoFillSelected.Tag,
        options: this.props.filterValueOptions[item.name.toLowerCase()],
        editIsOpen: !prevState.editIsOpen,
      }),
      () =>
        !this.state.openEditDropdown[index]
          ? this.setState({
              key: "",
              editIsOpen: false,
            })
          : null
    );
  };

  setType = (item, index) => {
    switch (item.type) {
      case "text":
        return (
          <TextInput
            value={this.state.value}
            keyVal={this.state.key}
            handleChange={this.handleChange}
            handleKeyDown={this.handleKeyDown}
            colorList={this.state.colorList}
            filterKeyOptions={this.props.filterKeyOptions}
            handleSelect={this.handleSelect}
            filterValues={this.props.filterValues}
            name={this.props.name}
            indexEdit={index}
          />
        );
      case "singleDropdown":
        return (
          <SingleDropdown
            value={this.state.value}
            options={this.state.options}
            keyVal={this.state.key}
            handleChange={this.handleChange}
            handleKeyDown={this.handleKeyDown}
            colorList={this.state.colorList}
            filterKeyOptions={this.props.filterKeyOptions}
            handleSelect={this.handleSelect}
            filterValues={this.props.filterValues}
            name={this.props.name}
            indexEdit={index}
            editIsOpen={this.state.editIsOpen}
          />
        );
      case "multiDropdown":
        return (
          <MultiDropdown
            value={this.state.value}
            options={this.state.options}
            keyVal={this.state.key}
            handleChange={this.handleChange}
            handleKeyDown={this.handleKeyDown}
            colorList={this.state.colorList}
            filterKeyOptions={this.props.filterKeyOptions}
            handleSelect={this.selectedMulti}
            filterValues={this.props.filterValues}
            name={this.props.name}
            multiSelect={this.state.multiSelect}
            indexEdit={index}
          />
        );
      case "interval":
        return (
          <QueryDropdown
            value={this.state.value}
            queryOptions={this.state.queryOptions}
            keyVal={this.state.key}
            handleChange={this.handleChange}
            handleKeyDown={this.handleKeyDown}
            colorList={this.state.colorList}
            filterKeyOptions={this.props.filterKeyOptions}
            handleSelect={this.handleSelect}
            filterValues={this.props.filterValues}
            name={this.props.name}
            handlePriceQuery={this.handlePriceQuery}
            handleGroupQuery={this.handleGroupQuery}
            indexEdit={index}
          />
        );
      case "query":
        return (
          <QueryDropdown
            value={this.state.value}
            queryOptions={this.state.queryOptions}
            keyVal={this.state.key}
            filterValues={this.props.filterValues}
            handleChange={this.handleChange}
            handlePriceQuery={this.handlePriceQuery}
            handleGroupQuery={this.handleGroupQuery}
            handleKeyDown={this.handleKeyDown}
            colorList={this.state.colorList}
            filterKeyOptions={this.props.filterKeyOptions}
            handleSelect={this.handleSelect}
            name={this.props.name}
            indexEdit={index}
          />
        );
      case "date":
        return (
          <DatePicker
            openDropdown={true}
            showTime={false}
            dateRange={[]}
            hasNoEndDateOption={false}
            saveDate={this.saveDate}
            hideCancel={true}
            indexEdit={index}
            noPastDate={true}
          />
        );
      default:
        return (
          <SingleDropdown
            value={this.state.value}
            options={this.state.options}
            keyVal={this.state.key}
            filterValues={this.props.filterValues}
            handleChange={this.handleChange}
            handleKeyDown={this.handleKeyDown}
            colorList={this.state.colorList}
            filterKeyOptions={this.props.filterKeyOptions}
            handleSelect={this.handleSelect}
            name={this.props.name}
            indexEdit={index}
          />
        );
    }
  };

  render() {
    let keyCap =
      this.state.key.charAt(0).toLowerCase() + this.state.key.slice(1);
    let keyName = keyCap.replace(/\s/g, "");

    let item =
      this.state.key.length > 0 &&
      this.props.filterKeyOptions.find((item) =>
        item.name.toLowerCase().includes(keyName.toLocaleLowerCase())
      );
    let hasOptions = !this.state.options
      ? []
      : this.state.options && this.state.options.length > 0
      ? this.state.options.filter((item) => {
          if (
            this.props.filterValues.length > 0 &&
            Object.keys(this.props.filterValues)
              .map((key) => "Date" in this.props.filterValues[key])
              .includes(true)
          ) {
            return !item.name.includes("Date");
          } else return item;
        })
      : this.props.filterKeyOptions;

    return (
      <>
        <div
          ref={(node) => (this.handleDropdown = node)}
          className={`${
            this.state.expandFilter
              ? this.props.filterValues.length < 1
                ? "h-16"
                : "h-full min-h-16"
              : "h-16"
          } flex flex-row w-full  p-2 text-xs`}
        >
          <i
            onClick={hasOptions.length > 0 ? this.openDropdown : null}
            className="material-icons  cursor-pointer text-black bg-white self-start px-2 text-3xl relative text-sm"
          >
            tune
            <i className="material-icons self-center text-lg self-start  hover:text-teal-dark absolute pin-top text-teal -ml-3  text-sm">
              add_circle
            </i>
          </i>
          <div className=" w-full h-full flex flex-row relative">
            <div
              className={`${
                this.state.expandFilter ? " " : "overflow-hidden"
              } max-w-9/10  flex flex-row flex-wrap`}
            >
              {this.props.filterValues.length > 0 &&
                this.props.filterValues.map((keyPair, i) =>
                  Object.entries(keyPair).map(([key, value]) => {
                    let keyLower = key.charAt(0).toLowerCase() + key.slice(1);
                    let keyName = keyLower.replace(/\s/g, "");
                    let item2 = this.props.filterKeyOptions.find((item) =>
                      item.name
                        .toLowerCase()
                        .includes(keyName.toLocaleLowerCase())
                    );
                    let concattedVal = "";
                    if (
                      item2.type === "multiDropdown" &&
                      value.length > 3 &&
                      !this.state.showAll
                    ) {
                      concattedVal = value.slice(0, 3);
                    } else {
                      concattedVal = value;
                    }

                    let newValue = "";
                    if (item2.type === "query" || item2.type === "interval") {
                      newValue = concattedVal.join(" ");
                    } else if (item2.type === "multiDropdown") {
                      newValue = concattedVal.join(", ");
                    } else {
                      newValue = concattedVal;
                    }
                    return (
                      <>
                        <span
                          key={key}
                          className={` cursor-pointer rounded-lg text-white self-start ml-2 pl-2 flex flex-row h-8 mb-2 relative text-xs ${
                            this.state.openEditDropdown[i] &&
                            item2.type === "date"
                              ? "bg-white "
                              : "bg-" + this.state.colorList[item2.id]
                          }`}
                        >
                          <span
                            className="self-center"
                            onClick={() => this.reopenFilter(item2, i)}
                          >
                            <span className="font-light items-center py-2">
                              {key}:{" "}
                            </span>

                            <span className="font-medium pr-3 py-2 items-center pl-2">
                              {this.state.query.length > 0 &&
                              this.state.openEditDropdown[i]
                                ? this.state.query
                                : newValue}
                              {item2.type === "multiDropdown" &&
                                value.length > 3 && (
                                  <div className="ml-2 rounded-full h-5 w-5 bg-grey inline-block items-center self-center text-center">
                                    {value.length}
                                  </div>
                                )}
                            </span>
                          </span>
                          <span
                            className={`${
                              this.state.openEditDropdown[i] &&
                              item2.type === "date"
                                ? "bg-white "
                                : "bg-" +
                                  this.state.colorList[item2.id] +
                                  "er" +
                                  " text-white"
                            } rounded-r-lg flex flex-col h-full ml-2 w-8 overflow-hidden text-xs`}
                          >
                            {" "}
                            <i
                              onClick={() => this.props.removeItem(keyPair)}
                              className={`
                            material-icons text-sm flex items-center justify-center text-center self-center h-full cursor-pointer text-xs `}
                            >
                              clear
                            </i>{" "}
                          </span>

                          {this.state.openEditDropdown[i] && (
                            <div className=" h-20 absolute top-0  z-20">
                              {this.setType(item2, i)}
                            </div>
                          )}
                        </span>
                      </>
                    );
                  })
                )}
              <div className="relative flex text-xs mb-2">
                {/* {this.state.key.length > 0 && !this.state.editIsOpen ? (
                  item.type === "query" || item.type === "interval" ? (
                    <QueryInput
                      keyVal={this.state.key}
                      query={this.state.query}
                      colorList={this.state.colorList}
                      item={item}
                    />
                  ) : item.type === "multiDropdown" ? (
                    <MultiInput
                      keyVal={this.state.key}
                      multiSelect={this.state.multiSelect}
                      colorList={this.state.colorList}
                      item={item}
                      options={this.state.options}
                    />
                  ) : (
                    <span
                      className={`${
                        "bg-" +
                        this.state.colorList[item.id] +
                        " placeholder-white  text-white"
                      } rounded-lg text-white self-start ml-2 py-1 h-8 2 px-4 flex flex-row items-center `}
                    >
                      {item.type === "date" ? (
                        <DatePicker
                          openDropdown={true}
                          showTime={false}
                          dateRange={[]}
                          hasNoEndDateOption={false}
                          saveDate={this.saveDate}
                        />
                      ) : (
                        <>
                          {this.state.key} :
                          <span
                            className={`${
                              "bg-" +
                              this.state.colorList[item.id] +
                              "er" +
                              " text-white"
                            } rounded-lg flex flex-col h-6 ml-2 w-12 `}
                          ></span>
                        </>
                      )}
                    </span>
                  )
                ) : (
                  ""
                )} */}
                {this.state.dropdown && this.setType(item)}
                {hasOptions.length > 0 && this.props.filterValues.length > 0 && (
                  <i
                    onClick={this.openDropdown}
                    className="material-icons self-center text-3xl relative pl-4 self-start cursor-pointer text-teal hover:text-teal-dark mb-2"
                  >
                    add_circle
                  </i>
                )}

                <div
                  onClick={
                    this.props.filterValues.length > 0
                      ? this.props.handleSubmit
                      : null
                  }
                  className={`${
                    this.props.filterValues.length > 0
                      ? "bg-teal hover:bg-teal-dark cursor-pointer"
                      : "bg-grey cursor-not-allowed"
                  } cursor-pointer rounded-lg text-white self-start ml-4 self-center items-center px-4 h-8 mb-2 relative text-xs self-center text-xs flex flex-row `}
                >
                  Filter{" "}
                  <i className="material-icons text-white text-md self-center items-center h-full flex ml-2">
                    check
                  </i>
                </div>
              </div>
            </div>
            <span className="max-w-full flex">
              <div
                className={`${
                  this.state.dropdown ? " " : "flex-1"
                } flex flex-col h-full min-w-6`}
              >
                {/* <div className="flex flex-row h-full w-full">
                  <span className="absolute right-0 flex self-start mt-3"> */}
                {/* {this.props.filterValues.length > 0 && (
                      <span className="bg-grey-dark rounded-full h-5 w-5 text-white flex items-center justify-center self-center">
                        {this.props.filterValues.length}
                      </span>
                    )} */}
                {/* <i
                      onClick={this.expandMore}
                      className="material-icons text-grey self-center cursor-pointer"
                    >
                      {this.state.expandFilter
                        ? "keyboard_arrow_down"
                        : "keyboard_arrow_right"}
                    </i> */}
                {/* </span>
                </div> */}
              </div>
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default InlineFilter;
