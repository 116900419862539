import React, { Component } from "react";
import { connect } from "react-redux";
import ViewContainer from "containers/ViewContainer";
import Table from "components/Table";
import { openModal, closeModal } from "ducks/modal";
import { getAllTags, getCommonTagsByProducts } from "ducks/tags";
import { getBrandsByMarket, getCategoriesByMarket } from "ducks/products";
import {
  clearAllData,
  setSelectedProducts,
  handleProductsInputChange,
  updateProductTableInputsOnBlur,
  setCurretPageClientSide,
  setRowRize,
  sortCampaignAnalyticsData,
} from "ducks/campaignAnalytics";
import * as MODAL_TYPE from "utils/modal-types";
import CampaignAnalyticsFilter from "containers/Filters/CampaignAnalyticsFilter";

class CampaignAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topVisible: true,
      campaignAnalyticsData: [
        {
          noTableData: true,
          name: "",
          articleNumber: "",
          brand: "",
          category: "",
          price: "",
          // priceRule: "",
          purchasePrice: "",
          rrp: "",
          margin: "",
          dynamicPrice: "",
          discount: "",
          lowestCompetitorPrice: "",
          priceDiff: "",
          lowestCompetitorName: "",
          numberOfCompetitors: "",
        },
      ],
      selectedCampaignAnalytics: [],
      holdSelectedCampaignAnalytics: [],
      holdSelectedPageCampaignAnalytics: [],
      hoverAddButtonGroup: false,
    };
  }

  componentDidMount() {
    this.props.clearAllData();

    this.props.getAllTags();
    this.props.getCategoriesByMarket();
    this.props.getBrandsByMarket();
  }

  componentWillUnmount() {
    this.props.clearAllData();
  }

  openSaveModal = () => {
    this.props.openModal(
      this.props.discount,
      MODAL_TYPE.CAMPAIGN_ANALYTICS_SAVE,
      MODAL_TYPE.XLARGE
    );
  };

  selectCampaignAnalyticsRows = (rows, page, row) => {
    this.setState({
      holdSelectedCampaignAnalytics: rows,
      holdSelectedPageCampaignAnalytics: page,
      selectedCampaignAnalytics: row,
    });

    this.props.setSelectedProducts(row);
  };

  getProductData = (page, rowSize, attr, direction) => {
    if (this.props.isFiltered) {
      this.props.filterCampaignAnalytics(
        page,
        rowSize,
        this.props.idList,
        attr,
        direction
      );
    } else {
      this.props.getCampaignAnalyticsData(page, rowSize, attr, direction);
    }
  };

  circleButtonOnMouseEnterGroup = () => {
    this.setState({ hoverAddButtonGroup: true });
  };
  circleButtonOnMouseLeaveGroup = () => {
    this.setState({ hoverAddButtonGroup: false });
  };

  clearProductSelections = () => {
    this.setState({
      selectedCampaignAnalytics: [],
      holdSelectedCampaignAnalytics: [],
      holdSelectedPageCampaignAnalytics: [],
    });
  };

  setCurrentPage = (page) => {
    this.props.setCurretPageClientSide(page);
  };
  setCurrentRowSize = (row) => {
    this.props.setRowRize(row, this.props.campaignAnalytics);
  };

  manualSorting = (direction, attr) => {
    this.props.sortCampaignAnalyticsData(
      this.props.campaignAnalytics,
      attr,
      direction,
      this.props.rowSize
    );
  };
  openTableInputSingleSubmitModal = (row, col, value) => {
    console.log('')
  };
  render() {
    let campaignAnalyticsDataKeys = [
      "articleNumber",
      "brand",
      "category",
      "id",
      "price",
      "tags",
      "name",
      "numberOfCompetitors",
      "lowestCompetitorPrice",
      "lowestCompetitorName",
      "priceDiff",
      "mongoId",
      "ownPrice",
    ];

    let longest = 0;

    this.props.campaignAnalyticsPaginationData &&
      this.props.campaignAnalyticsPaginationData.map((item) => {
        if (
          item.pricePositions !== null &&
          item.pricePositions.length > longest
        ) {
          return (longest = item.pricePositions.length);
        } else return;
      });
    let parsedCampaignAnalyticsData = [];
    parsedCampaignAnalyticsData =
      this.props.campaignAnalyticsPaginationData &&
      this.props.campaignAnalyticsPaginationData.length > 0 &&
      this.props.campaignAnalyticsPaginationData.map((item) => {
        let mainData = {
          id: item.id,
          name: item.name,
          articleNumber: item.articleNumber ? item.articleNumber : " ",
          brand: item.brand
            ? typeof item.brand === "object"
              ? item.brand.name
              : item.brand
            : "-",
          category: item.category
            ? typeof item.category === "object"
              ? item.category.name
              : item.category
            : "-",
          price: item.ownPrice ? item.ownPrice : " ",
          // priceRule: item.priceRule ? item.priceRule : "",
          purchasePrice: item.purchasePrice ? item.purchasePrice : " ",
          rrp: item.recommendedRetailPrice ? item.recommendedRetailPrice : " ",
          margin: item.margin ? item.margin : " ",
          dynamicPrice: item.dynamicPrice ? item.dynamicPrice : "",
          discount: item.discount ? item.discount : "",
          lowestCompetitorPrice: item.lowestCompetitorPrice
            ? item.lowestCompetitorPrice
            : " ",
          priceDiff: item.priceDiff ? item.priceDiff : " ",
          lowestCompetitorName: item.lowestCompetitorName
            ? item.lowestCompetitorName
            : " ",
          numberOfCompetitors: item.numberOfCompetitors
            ? item.numberOfCompetitors
            : "0",
        };

        let position = [];
        let campaignAnalyticsEmpty = [];

        let currentLength =
          item.pricePositions === null ? 0 : item.pricePositions.length;

        if (item.pricePositions === null || item.pricePositions.length < 1) {
          for (let i = currentLength; i < longest; i++) {
            campaignAnalyticsEmpty.push({
              name: " ",
              price: " ",
            });
          }
          let total = position.concat(campaignAnalyticsEmpty);
          let snipped = total.slice(0, longest);
          snipped.map((item, i) => {
            let index = i + 1;
            return (mainData = {
              ...mainData,
              ["Position " + index]: {
                hideSorting: true,
                value: (
                  <>
                    {item.name}
                    <span className="font-medium">
                      {" "}
                      {item.price === null || item.price.length > 0 ? " " : "-"}
                      {item.price === null ? " " : item.price}
                    </span>
                  </>
                ),
              },
            });
          });
        }
        let pricePositions =
          item.pricePositions === null ? [] : item.pricePositions;
        pricePositions.map((item, i) => {
          if (!campaignAnalyticsDataKeys.includes(item)) {
            if (currentLength < longest) {
              for (let i = currentLength; i < longest; i++) {
                campaignAnalyticsEmpty.push({
                  name: " ",
                  price: " ",
                });
              }
            }
            position.push({
              name: item.name,
              price: item.price,
            });

            let total = position.concat(campaignAnalyticsEmpty);
            let snipped = total.slice(0, longest);
            snipped.map((item, i) => {
              let index = i + 1;
              return (mainData = {
                ...mainData,
                ["Position " + index]: {
                  hideSorting: true,
                  value: (
                    <>
                      {item.name}
                      <span className="font-medium w-full block">
                        {" "}
                        {item.price === null || item.price.length > 0
                          ? " "
                          : "-"}
                        {item.price === null ? " " : item.price}
                      </span>
                    </>
                  ),
                },
              });
            });
          }
        });
        return mainData;
      });
    let products =
      this.props.selectedProducts && this.props.selectedProducts.length > 0
        ? this.props.selectedProducts
        : this.props.campaignAnalytics;
    let selectedProducts =
      this.props.selectedProducts && this.props.selectedProducts.length > 0
        ? products && products.filter((product) => product.rrp > 0)
        : products &&
          products.filter((product) => product.recommendedRetailPrice > 0);
    let isSavable = false;

    products.find((product) => product.discount.value.length < 1) ||
    selectedProducts.length < 1
      ? (isSavable = false)
      : (isSavable = true);
    const saveButton =
      products.length > 0 && isSavable && this.props.idList.length > 0 ? (
        <div
          className="rounded bg-teal-dark cursor-pointer hover:bg-teal px-3 py-2 text-xs text-white font-sans"
          onClick={() => this.openSaveModal()}
        >
          Save Campaign
        </div>
      ) : (
        <div className="rounded bg-grey-light px-3 cursor-not-allowed py-2 text-xs text-white font-sans">
          Save Campaign
        </div>
      );
    const tableConfigProducts = {
      data:
        this.props.campaignAnalytics.length > 0 &&
        !this.props.campaignAnalyticsTableLoading
          ? parsedCampaignAnalyticsData
          : this.state.campaignAnalyticsData,

      name: "Campaign Analytics",
      manual: true,
      manualErrorMessage: this.props.tooMuchData ? (
        <span className="w-full text-center inline-block">
          Oops, you're trying to load too many products.
          <br /> Please narrow down your search. (Max 2000 products)
        </span>
      ) : (
        "Select filters and set a discount to get started."
      ),
      manualError: this.props.tooMuchData,
      manualSorting: this.manualSorting,
      // saveButton: saveButton,
      tableLoading: this.props.campaignAnalyticsTableLoading,
      noData: this.props.campaignAnalyticsNoData,
      // onDoubleClick: this.onDoubleClickProducts,
      // onClick: this.onClick,
      hiddenCols: ["id", "noTableData", "mongoId", "ownPrice"],
      // topRightButtons: topRightButtonsProduct,
      filter: true,
      selectRows: this.selectCampaignAnalyticsRows
        ? this.selectCampaignAnalyticsRows
        : [],
      selectAllRows: this.selectCampaignAnalyticsRows,
      selectedRows: this.state.holdSelectedCampaignAnalytics,
      selectedPages: this.state.holdSelectedPageCampaignAnalytics
        ? this.state.holdSelectedPageCampaignAnalytics
        : [],
      hasClientSidePagination: true,
      clientSidePagination: this.props.campaignAnalyticsPaginationData,
      dataLength: this.props.campaignAnalytics.length,
      currentPage: this.props.currentPage,
      setPageClientSide: this.setCurrentPage,
      rowSize: this.props.rowSize,
      totalPages: this.props.campaignAnalyticsPages.length,
      setRowRize: this.setCurrentRowSize,
      // attr: this.props.campaignAnalyticsSortingAttr,
      // direction: this.props.campaignAnalyticsSortingDirection,
      // isTableLarge: this.props.isTableLarge,
      handleInputSubmit: this.openTableInputSingleSubmitModal,

      handleInputChange: this.props.handleProductsInputChange,
      handleInputChangeOnBlur: this.props.updateProductTableInputsOnBlur,
    };

    return (
      <ViewContainer
        hasPageButtons={true}
        topVisible={false}
        handleToggleTop={this.handleToggleTop}
      >
        <div className="relative my-8 pt-4">
          <Table tableConfig={tableConfigProducts}>
            <CampaignAnalyticsFilter
              saveButton={saveButton}
              clearSelections={this.clearProductSelections}
            />
          </Table>
        </div>
      </ViewContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignAnalytics: state.campaignAnalytics.campaignAnalytics,
    selectedProducts: state.campaignAnalytics.selectedProducts,
    isFiltered: state.campaignAnalytics.isFiltered,
    idList: state.campaignAnalytics.idList,
    showAllProducts: state.campaignAnalytics.showAllProducts,
    campaignAnalyticsTableLoading:
      state.campaignAnalytics.campaignAnalyticsTableLoading,
    totalCampaignAnalytics: state.campaignAnalytics.totalCampaignAnalytics,
    campaignAnalyticsNoData: state.campaignAnalytics.campaignAnalyticsNoData,
    isTableLarge: state.sidepanel.isTableLarge,
    discount: state.campaignAnalytics.discount,
    viewState: state.campaignAnalytics.viewState,
    tagToFilterBy: state.campaignAnalytics.tagToFilterBy,
    tooMuchData: state.campaignAnalytics.tooMuchData,
    campaignAnalyticsPages: state.campaignAnalytics.campaignAnalyticsPages,
    totalCampaignAnalytics: state.campaignAnalytics.totalCampaignAnalytics,
    rowSize: state.campaignAnalytics.rowSize,
    campaignAnalyticsPaginationData:
      state.campaignAnalytics.campaignAnalyticsPaginationData,
    currentPage: state.campaignAnalytics.currentPage,
    setRowRize: state.campaignAnalytics.setRowRize,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (targetUnitData, modalType, modalSize) =>
      dispatch(openModal(targetUnitData, modalType, modalSize)),
    closeModal: () => dispatch(closeModal()),
    getAllTags: () => dispatch(getAllTags()),
    getCommonTagsByProducts: (products) =>
      dispatch(getCommonTagsByProducts(products)),
    // getCommonTagsByProducts: products =>
    //   dispatch(getCommonTagsByProducts(products)),
    getAllTags: () => dispatch(getAllTags()),
    getCategoriesByMarket: () => dispatch(getCategoriesByMarket()),
    getBrandsByMarket: () => dispatch(getBrandsByMarket()),
    clearAllData: () => dispatch(clearAllData()),
    setSelectedProducts: (rows) => dispatch(setSelectedProducts(rows)),
    handleProductsInputChange: (value, id, col) =>
      dispatch(handleProductsInputChange(value, id, col)),
    updateProductTableInputsOnBlur: (id, col, value) =>
      dispatch(updateProductTableInputsOnBlur(id, col, value)),
    setCurretPageClientSide: (page) => dispatch(setCurretPageClientSide(page)),
    setRowRize: (rowSize, data) => dispatch(setRowRize(rowSize, data)),
    sortCampaignAnalyticsData: (data, attr, direction, rowSize) =>
      dispatch(sortCampaignAnalyticsData(data, attr, direction, rowSize)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignAnalytics);
