import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal } from "ducks/modal";
import { closeFormpanel } from "ducks/formpanel";
import { addNewPriceRule } from "ducks/priceRule";
import { ModalContentWrapper } from "containers/Modals/ModalContainer/ModalContentWrapper";

class ApplyRulePrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canContinue: true
    };
  }

  handleContinue = () => {
    this.props.addNewPriceRule(
      this.props.data,
      this.props.page,
      this.props.rowSize,
      this.props.sortingAttr,
      this.props.sortingDirection
    );

    this.props.closeModal();
    this.props.closeFormpanel();
  };

  render() {
    const modalConfig = {
      buttonOnClick: this.handleContinue,
      buttonLabel: "Apply",
      title: "Just checking",
      hasBottomButtons: true,
      canSave: this.state.canContinue,
      closeText: "Cancel",
      handleClose: this.props.closeModal
    };

    return (
      <ModalContentWrapper modalConfig={modalConfig} size={this.props.size}>
        <div className="w-full text-center h-full flex flex-col justify-between py-8">
          <div className="font-sans font-light text-md">
            <span className="text-grey-dark text-center">
              Are you sure you want to apply price rule to,
            </span>
          </div>
          <div className="flex flex-wrap justify-center text-lg">
            <div className="font-medium text-grey-darker">
              {this.props.data.campaign.name}
            </div>
          </div>
          <div className="font-sans font-light text-md pb-2">
            {/* <span className="text-grey-dark text-center">
              <span className="font-normal text-sm">WARNING:</span> This action
              can not be undone!
            </span> */}
          </div>
        </div>
      </ModalContentWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    page: state.tablePagination.priceRulePage,
    rowSize: state.tablePagination.priceRuleRowSize,
    sortingAttr: state.tablePagination.priceRuleSortingAttr,
    sortingDirection: state.tablePagination.priceRuleSortingDirection
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeFormpanel: () => dispatch(closeFormpanel()),
    closeModal: () => dispatch(closeModal()),
    addNewPriceRule: (newRule, page, rowSize, attr, direction) =>
      dispatch(addNewPriceRule(newRule, page, rowSize, attr, direction))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyRulePrompt);
