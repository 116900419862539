import React, { Component } from "react";
import SingleSelectDropdown from "components/SingleSelectDropdown";

class NotificationsFilter extends Component {
  constructor() {
    super();

    this.state = {
      priorityOptions: [
        { id: 1, name: "Low" },
        { id: 2, name: "Medium" },
        { id: 3, name: "High" }
      ],
      categoryOptions: [
        { id: 1, name: "Campaign" },
        { id: 2, name: "Price" },
        { id: 3, name: "Match" }
      ],
      category: {},
      priority: {}
    };
  }

  handleSelected = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  queryBuilder = () => {
    let queryString = "";

    let priority = "priority=" + this.state.priority.name;
    let category = "category=" + this.state.category.name;
    if (this.state.priority && Object.keys(this.state.priority).length > 0) {
      queryString = queryString.concat(priority);
    }
    if (this.state.category && Object.keys(this.state.category).length > 0) {
      if (queryString.length > 0) {
        queryString = queryString.concat("&" + category);
      } else queryString = queryString.concat(category);
    }
    this.props.filterNotifications(queryString);
  };

  clearFilter = () => {
    this.setState({
      category: {},
      priority: {}
    });
    this.props.clearFilter();
  };

  render() {
    return (
      <div className="flex flex-row md:w-3/5 w-full justify-between">
        <div className="flex flex-col md:w-2/3 w-1/5 my-auto ">
          <SingleSelectDropdown
            width="full"
            name="category"
            label="category"
            placeholder="category"
            options={this.state.categoryOptions}
            selected={this.state.category}
            handleSelect={this.handleSelected}
          />
        </div>
        <div className="flex flex-col md:w-2/3 w-1/5 my-auto mx-2">
          <SingleSelectDropdown
            width="full"
            name="priority"
            label="priority"
            options={this.state.priorityOptions}
            selected={this.state.priority}
            handleSelect={this.handleSelected}
            placeholder="priority"
          />
        </div>
        <div className="flex flex-col xxxl:w-1/6 w-1/5 items-baseline pt-6 ">
          <div
            className="py-2 px-3 h-10 xxxl:px-6 flex my-auto items-end justify-center bg-teal-dark hover:bg-teal text-xs text-white cursor-pointer"
            onClick={this.queryBuilder}
          >
            FILTER
          </div>
        </div>
        <div className="flex flex-col xxxl:w-1/6 w-1/5 items-baseline pt-6 pl-2">
          <div
            className="py-2 px-3 h-10 xxxl:px-6 flex my-auto items-end justify-center bg-teal-dark hover:bg-teal text-xs text-white cursor-pointer"
            onClick={this.clearFilter}
          >
            CLEAR
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationsFilter;
