import React, { Component } from "react";
import { connect } from "react-redux";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import SingleSelectDropdown from "components/SingleSelectDropdown";
import { InputText } from "components/InputText";
import InputNumber from "components/InputNumber";
import { TooltipRight } from "components/Tooltips";
import { SmallLoadingSpinner } from "components/SmallLoadingSpinner/SmallLoadingSpinner";
import { filterPriceHistory, setFiltered } from "ducks/priceHistory";
import { getBrandsByMarket, getCategoriesByMarket } from "ducks/products";
// import { getAllTags } from "ducks/tags";
import { getAllCampaignsDropdown } from "ducks/campaigns";
import { getAllCompetitors } from "ducks/priceRule";
import moment from "moment";

class PriceHistoryFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSave: false,
      to: "",
      from: "",
      name: "",
      categories: [],
      brands: [],
      hoverTooltip: false,
      searching: false,
      categoryOptions: [],
      brandOptions: [],
      articleNumber: "",
      absolutePriceChangeFrom: "",
      absolutePriceChangeTo: "",
      relativePriceChangeFrom: "",
      relativePriceChangeTo: "",
      absolutePriceError: false,
      absolutePriceRangeError: false,
      relativePriceError: false,
      relativePriceRangeError: false,
      formatIsWrong: false,
      toError: false,
      fromError: false,
    };
  }

  componentDidMount() {
    this.setState({
      to: this.props.defaultToDate,
      from: this.props.defaultFromDate,
    });
  }

  handleSelected = (name, selected) => {
    if (
      Array.isArray(selected) &&
      selected.find((selected) => selected.name == "Select All") !== undefined
    ) {
      if (selected.map((select) => select.name.includes("Select All"))) {
        if (
          name == "categories" &&
          this.state.categories.length != this.props.categories.length
        ) {
          this.setState({
            [name]: this.props.categories,
          });
        } else if (
          name == "categories" &&
          this.state.categories.length == this.props.categories.length
        ) {
          this.setState({
            [name]: [],
          });
        } else if (
          name == "brands" &&
          this.state.brands.length != this.props.brands.length
        ) {
          this.setState({
            [name]: this.props.brands,
          });
        } else if (
          name == "brands" &&
          this.state.brands.length == this.props.brands.length
        ) {
          this.setState({
            [name]: [],
          });
        }
      }
    } else {
      this.setState({ [name]: selected, canSave: true });
    }
  };

  // handleExport = () => {
  //   // this.props.getExportProducts();
  // };

  clearFilters = () => {
    this.setState({
      name: "",
      categories: [],
      brands: [],
      articleNumber: "",
      absolutePriceChangeFrom: "",
      absolutePriceChangeTo: "",
      relativePriceChangeFrom: "",
      relativePriceChangeTo: "",
      to: moment().format("YYYY-MM-DD"),
      from: moment().subtract("weeks", 2).format("YYYY-MM-DD"),
    });
    this.props.setFiltered(false);
    let defaultQuery =
      "&from=".concat(moment(this.state.from).format("YYYY-MM-DD")) +
      "T00:00:00Z" +
      "&to=".concat(moment(this.state.to).format("YYYY-MM-DD")) +
      "T23:59:59Z";
    this.props.filterPriceHistory(1, 20, defaultQuery, null, null);
  };

  buildQueryString = (items, name) => {
    let urlQuery = name;
    items.forEach((item, i) => {
      if (i + 1 < items.length) {
        urlQuery = urlQuery.concat(item.id + ",");
      } else {
        urlQuery = urlQuery.concat(item.id);
      }
    });
    return urlQuery;
  };

  tooltipOnMouseEnter = () => {
    this.setState({
      hoverTooltip: true,
    });
  };
  tooltipOnMouseLeave = () => {
    this.setState({
      hoverTooltip: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      canSave: true,
      [event.target.name + "Error"]: false,
    });
    if (event.target.name === "to" || event.target.name === "from") {
      this.setState({
        formatIsWrong: false,
      });
    }
    if (event.target.name.includes("absolutePriceChange")) {
      this.setState({
        absolutePriceError: false,
        absolutePriceRangeError: false,
      });
    }
    if (event.target.name.includes("relativePriceChange")) {
      this.setState({
        relativePriceError: false,
        relativePriceRangeError: false,
      });
    }
  };

  priceValidation = () => {
    let error = false;
    if (
      (this.state.absolutePriceChangeFrom.length > 0 &&
        this.state.absolutePriceChangeTo.length < 1) ||
      (this.state.absolutePriceChangeTo.length > 0 &&
        this.state.absolutePriceChangeFrom.length < 1)
    ) {
      error = true;
      this.setState({ absolutePriceError: true });
    }
    if (
      (this.state.relativePriceChangeFrom.length > 0 &&
        this.state.relativePriceChangeTo.length < 1) ||
      (this.state.relativePriceChangeTo.length > 0 &&
        this.state.relativePriceChangeFrom.length < 1)
    ) {
      error = true;
      this.setState({ relativePriceError: true });
    }
    if (
      this.state.relativePriceChangeFrom.length > 0 &&
      this.state.relativePriceChangeTo.length > 0 &&
      parseInt(this.state.relativePriceChangeFrom) >
        parseInt(this.state.relativePriceChangeTo)
    ) {
      error = true;
      this.setState({ relativePriceRangeError: true });
    }
    if (
      this.state.absolutePriceChangeFrom.length > 0 &&
      this.state.absolutePriceChangeTo.length > 0 &&
      parseInt(this.state.absolutePriceChangeFrom) >
        parseInt(this.state.absolutePriceChangeTo)
    ) {
      error = true;
      this.setState({ absolutePriceRangeError: true });
    }
    return error;
  };

  dateCheck = () => {
    let error = false;
    if (this.state.to.length < 1) {
      error = true;
      this.setState({
        toError: true,
      });
    }
    if (this.state.from.length < 1) {
      error = true;
      this.setState({
        fromError: true,
      });
    }
    if (moment(this.state.from).isSameOrAfter(moment(this.state.to))) {
      error = true;
      this.setState({
        formatIsWrong: true,
      });
    }
    return error;
  };

  handleFilterPriceHistory = () => {
    if (this.priceValidation() || this.dateCheck()) {
      return;
    } else {
      let queryString =
        "&from=".concat(moment(this.state.from).format("YYYY-MM-DD")) +
        "T00:00:00Z" +
        "&to=".concat(moment(this.state.to).format("YYYY-MM-DD")) +
        "T23:59:59Z";
      let categoryIds = this.buildQueryString(
        this.state.categories,
        "&categoryIds="
      );
      let brandIds = this.buildQueryString(this.state.brands, "&brandIds=");
      let articleNumber = "&articleNumber=".concat(this.state.articleNumber);

      let name = "&name=".concat(this.state.name);

      if (this.state.categories.length > 0) {
        queryString = queryString.concat(categoryIds);
      }
      if (this.state.brands.length > 0) {
        queryString = queryString.concat(brandIds);
      }
      if (this.state.articleNumber.length > 0) {
        queryString = queryString.concat(articleNumber);
      }
      if (this.state.name.length > 0) {
        queryString = queryString.concat(name);
      }
      if (
        this.state.absolutePriceChangeFrom.length > 0 &&
        this.state.absolutePriceChangeTo.length > 0
      ) {
        queryString = queryString.concat(
          "&absolutePriceChangeFrom=" +
            this.state.absolutePriceChangeFrom.toString() +
            "&absolutePriceChangeTo=" +
            this.state.absolutePriceChangeTo.toString()
        );
      }
      if (
        this.state.relativePriceChangeFrom.length > 0 &&
        this.state.relativePriceChangeTo.length > 0
      ) {
        queryString = queryString.concat(
          "&relativePriceChangeFrom=" +
            this.state.relativePriceChangeFrom.toString() +
            "&relativePriceChangeTo=" +
            this.state.relativePriceChangeTo.toString()
        );
      }

      this.props.filterPriceHistory(1, 20, queryString, null, null);
    }
  };

  render() {
    let selectAll = [{ id: 0, name: "Select All" }];
    let maxDate = moment().format("YYYY-MM-DD");
    return (
      <>
        <div className="bg-white h-full w-full mb-4">
          <div className="flex flex-row w-full">
            <div className="flex flex-col w-4/5">
              <div className="flex flex-row justify-start">
                <div className="px-2 w-1/4">
                  <InputText
                    label="Name"
                    name="name"
                    width="w-full"
                    value={this.state.name}
                    onChange={this.handleChange}
                    placeholder="Name"
                  />
                </div>
                <div className="px-2 w-1/4">
                  <InputText
                    label="Article Number"
                    name="articleNumber"
                    width="w-full"
                    value={this.state.articleNumber}
                    onChange={this.handleChange}
                    placeholder="Article Number"
                  />
                </div>
                <div className="px-2 w-1/4">
                  <MultiSelectDropdown
                    name="brands"
                    label="Brands"
                    width="w-full"
                    options={selectAll.concat(this.props.brands)}
                    selected={this.state.brands}
                    handleSelect={this.handleSelected}
                    placeholder="Select Brands"
                  />
                </div>
                <div className="px-2 w-1/4">
                  <MultiSelectDropdown
                    name="categories"
                    label="Categories"
                    width="w-full"
                    options={selectAll.concat(this.props.categories)}
                    selected={this.state.categories}
                    handleSelect={this.handleSelected}
                    placeholder="Select Categories"
                  />
                </div>
              </div>
              <div className="flex flex-row w-full">
                <div className="w-1/4 pt-2 pl-2 ">
                  <div className="text-grey-dark font-light text-xs pb-1">
                    Absolute Price Change
                  </div>
                  <div className="border-1 borer-grey-light flex flex-row pb-2">
                    <div className="px-2  ">
                      <InputNumber
                        label="From"
                        name="absolutePriceChangeFrom"
                        width="w-full"
                        value={this.state.absolutePriceChangeFrom}
                        onChange={this.handleChange}
                        placeholder="Price"
                        error={
                          this.state.absolutePriceError ||
                          this.state.absolutePriceRangeError
                        }
                      />
                    </div>
                    <div className="px-2">
                      <InputNumber
                        label="To"
                        name="absolutePriceChangeTo"
                        width="w-full"
                        value={this.state.absolutePriceChangeTo}
                        onChange={this.handleChange}
                        placeholder="Price"
                        error={
                          this.state.absolutePriceError ||
                          this.state.absolutePriceRangeError
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="w-1/4 pt-2 pl-2 ">
                  <div className="text-grey-dark font-light text-xs pb-1">
                    Relative Price Change (%)
                  </div>
                  <div className="border-1 borer-grey-light flex flex-row pb-2">
                    <div className="px-2 flex-1 ">
                      <InputNumber
                        label="From"
                        name="relativePriceChangeFrom"
                        width="w-full"
                        value={this.state.relativePriceChangeFrom}
                        onChange={this.handleChange}
                        placeholder="Price"
                        error={
                          this.state.relativePriceError ||
                          this.state.relativePriceRangeError
                        }
                      />
                    </div>
                    <div className="px-2 flex-1">
                      <InputNumber
                        label="To"
                        name="relativePriceChangeTo"
                        width="w-full"
                        value={this.state.relativePriceChangeTo}
                        onChange={this.handleChange}
                        placeholder="Price"
                        error={
                          this.state.relativePriceError ||
                          this.state.relativePriceRangeError
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="w-1/4 pt-2 pl-2 ">
                  <label
                    className="block text-xs font-light text-grey-dark font-sans"
                    for="start"
                  >
                    Start date:
                  </label>
                  <span className="text-teal flex w-full  flex-col pr-6 text-2xl">
                    <input
                      className="py-4"
                      label="from date"
                      type="date"
                      id="start"
                      name="from"
                      onChange={this.handleChange}
                      value={moment(this.state.from).format("YYYY-MM-DD")}
                    ></input>
                    <span className="text-red text-xs">
                      {this.state.fromError
                        ? "You must enter a start date"
                        : this.state.formatIsWrong
                        ? "Check that start and end date is correct"
                        : null}
                    </span>
                  </span>
                </div>
                <div className="w-1/4 pt-2 pl-2 ">
                  <label
                    className="block text-xs font-light text-grey-dark font-sans "
                    for="end"
                  >
                    End date:
                  </label>
                  <span className="text-teal flex flex-col w-full h-20  text-2xl">
                    <input
                      className="py-4"
                      label="to date"
                      type="date"
                      id="end"
                      name="to"
                      onChange={this.handleChange}
                      value={moment(this.state.to).format("YYYY-MM-DD")}
                      max={maxDate}
                    ></input>
                    <span className="text-red text-xs">
                      {this.state.toError
                        ? "You must enter a start date"
                        : this.state.formatIsWrong
                        ? "Check that start and end date is correct"
                        : null}
                    </span>
                  </span>
                </div>
              </div>

              <div className="flex flex-row w-full">
                <div className="px-2 w-1/4 ">
                  {(this.state.absolutePriceError ||
                    this.state.absolutePriceRangeError) && (
                    <div className="w-full flex justify-end py-1">
                      <div className="text-red font-light xxl:text-xs text-xxs">
                        {this.state.absolutePriceError
                          ? "Please select a from and to value"
                          : "From value can not be higher than to"}
                      </div>
                    </div>
                  )}
                </div>
                <div className="px-2 w-1/4">
                  {(this.state.relativePriceError ||
                    this.state.relativePriceRangeError) && (
                    <div className="w-full flex justify-end py-1">
                      <div className="text-red font-light xxl:text-xs text-xxs">
                        {this.state.relativePriceError
                          ? "Please select a from and to value"
                          : "From value can not be higher than to"}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row w-full justify-end">
            <div className="flex flex-col w-2/5 items-end flex-wrap mr-12">
              <div className="flex-1 flex justify-end items-end">
                <div
                  className="xxl:py-2 xxl:px-3 py-1 px-2 h-10 flex items-center justify-center xxl:text-sm text-xs text-teal-dark hover:text-teal mx-2"
                  onClick={() => this.clearFilters()}
                >
                  <span className="cursor-pointer font-light font-sans">
                    CLEAR
                  </span>
                </div>

                <div
                  className="xxl:py-2 xxl:px-3 py-1 px-2 h-10 flex items-center justify-center bg-teal-dark hover:bg-teal xxl:text-sm text-xs text-white mx-2 rounded-sm cursor-pointer"
                  onClick={() => this.handleFilterPriceHistory()}
                >
                  FILTER
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBrandsByMarket: () => dispatch(getBrandsByMarket()),
    getCategoriesByMarket: () => dispatch(getCategoriesByMarket()),
    filterPriceHistory: (page, rowSize, idList, attr, direction) =>
      dispatch(filterPriceHistory(page, rowSize, idList, attr, direction)),
    setFiltered: (val) => dispatch(setFiltered(val)),
    getAllCompetitors: () => dispatch(getAllCompetitors()),
  };
};

const mapStateToProps = (state) => {
  return {
    categories: state.products.categories,
    brands: state.products.brands,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceHistoryFilter);
