import React, { Component } from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
class BarChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();

    this.state = {
      label: "",
      name: "",
      price: [],
      color: [],
      colorList: ["#fa7ea8", "#6cb2eb", "#faad63", "#51d88a", "#ef5753"],
    };
  }

  render() {
    let data = [];
    let labels = [];

    this.props.data && this.props.data.dataSets !== undefined &&
      this.props.data.dataSets.length > 0 &&
      this.props.data.dataSets.map((item) => {
         data = item.data
        labels=  item.labels
         }
         );

    let chartData = {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: this.state.colorList,
        },
      ],
    };

    let chartOptions = {
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
      legend: {
        display: false,
        labels: {
          fontColor: "red",
        },
      },
    };

    return (
      <>
        <Bar data={chartData} options={chartOptions} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(BarChart);
