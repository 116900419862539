import React from "react";
import { InputText } from "components/InputText";
import SingleSelectDropdown from "components/SingleSelectDropdown";

const SelectGroup = props => {
  const renderSummary = () => {
    return (
      <>
        <p className="flex-row h-8 text-grey-dark">
          Created By:{" "}
          <span className="float-right text-teal">{props.createdBy}</span>
        </p>
        {props.formState === "edit" ? (
          <p className="flex-row h-8 text-grey-dark">
            Created:
            <span className="float-right text-teal">{props.created}</span>
          </p>
        ) : null}
      </>
    );
  };
  return (
    <>
      <div className="flex flex-row flex-wrap xxl:px-4 first:mt-6">
        <div className="relative w-4/5 border-1 border-grey-lighter p-8 mb-4">
          <div>
            <ul className="w-2/3 m-0 inline-block align-top mb-2 -mt-2 ">
              <li
                onClick={index => props.handleActive(props.index, "group")}
                className={`cursor-pointer inline align-middle text-teal ${
                  props.active === "group" ? "font-bold" : "font-thin"
                } `}
              >
                {props.active === "group" ? (
                  <i className="material-icons text-4xl text-grey-light align-middle">
                    close
                  </i>
                ) : (
                  <i className="material-icons text-4xl text-grey-light align-middle">
                    add
                  </i>
                )}
                Group
              </li>

              <li
                onClick={index => props.handleActive(props.index, "expression")}
                className={`cursor-pointer ${
                  props.formprops === "create" && !props.data ? null : "ml-10"
                } align-middle inline text-teal ${
                  props.active === "expression" ? "font-bold" : "font-thin"
                } `}
              >
                {props.active === "expression" ? (
                  <i className="material-icons text-4xl text-grey-light align-middle">
                    close
                  </i>
                ) : (
                  <i className="material-icons text-4xl text-grey-light align-middle">
                    add
                  </i>
                )}
                Expression
              </li>
            </ul>
            {/* DONT ALLOW EDITING IF GROUP IS SELECTED WITHIN A GROUP */}
            {props.formState === "edit" && props.parent ? (
              <>
                <div className="w-1/3 mr-10 flex flex-col ">
                  <InputText
                    width="w-full"
                    label="Name"
                    value={props.parent.name}
                    placeholder="Name"
                  />

                  <InputText
                    width="w-full"
                    label="Description"
                    value={props.parent.description}
                    placeholder="Description"
                  />
                </div>{" "}
              </>
            ) : (
              <div className="flex flex-row w-1/2">
                <SingleSelectDropdown
                  width="w-full"
                  options={props.groupOptions}
                  selected={props.group ? props.group : {}}
                  name={`groupParent${props.index}`}
                  handleSelect={props.handleSelected}
                  placeholder="Select an a group"
                  error={props.error}
                />
              </div>
            )}

            <div className="absolute pt-8 pr-8 top-0 right-0 inline-block m-0 w-1/3 flex-col text-sm">
              {renderSummary()}
            </div>
          </div>
        </div>

        {props.attribute &&
          props.attribute.map((attri, i) => (
            <div
              key={i}
              className="w-4/5 bg-teal-lightest h-20 border-1 border-t-0 flex flex-row "
            >
              <span className="text-grey-dark  w-full m-auto flex items-center justify-center">
                <p>{attri.attribute.name}</p>
                <span className="mx-8">
                  {" "}
                  {props.relationalOperator[i].name === "EQUAL TO" ? "=" : null}
                  {props.relationalOperator[i].name === "NOT EQUAL TO"
                    ? "≠"
                    : null}
                  {props.relationalOperator[i].name === "LIKE" ? "LIKE" : null}
                </span>
                <p>{attri.value}</p>
              </span>
            </div>
          ))}
      </div>
    </>
  );
};

export default SelectGroup;
