import React, { Component } from "react";

export default class SmallSingleDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false
    };
  }

  toggleDropdown = () => {
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
  };

  render() {
    return (
      <div className="w-full flex text-teal-dark font-light relative pr-2">
        <div
          onClick={() => this.toggleDropdown()}
          className="w-full flex flex-row cursor-pointer"
        >
          <div className="flex-1 truncate self-center xxl:text-sm text-xs">
            {this.props.selected
              ? this.props.selected.name
              : "Select Competitor"}
          </div>

          <i className="w-4 material-icons self-center">unfold_more</i>
        </div>

        {this.state.showDropdown && (
          <ul className="absolute py-1 mt-10 shadow-md w-64 max-h-10 text-grey-darker overflow-y-auto border-1 border-grey-lighter bg-white">
            {this.props.options.map((option, i) => (
              <li
                key={i}
                className={`py-1 pl-2 w-full xxl:text-sm text-xs" hover:bg-teal-dark hover:text-white cursor-pointer flex flex-row ${this
                  .props.selected &&
                  this.props.selected.name === option.name &&
                  "bg-teal-dark text-white"}`}
                onClick={() => {
                  this.props.handleSelected(this.props.name, option);
                  this.setState({ showDropdown: false });
                }}
              >
                <div className="w-9/10 truncate">{option.name}</div>
                <div className="self-center material-icons text-white text-md">
                  {this.props.selected &&
                    this.props.selected.name === option.name &&
                    "check"}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}
