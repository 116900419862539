import React from "react";

export const Input = props => {
  const inputStyling =
    "bg-white border-solid border-grey-light focus:text-teal border-1 outline-none text-teal-darker font-light xxl:text-base text-sm font-sans xxl:h-10 xxl:px-3 p-2 input-placeholder";
  const errorInputStyling =
    "bg-red-lightest border-solid border-red-lighter border-1 outline-none text-red font-light xxl:text-base text-sm font-sans xxl:h-10 xxl:px-3 p-2 input-placeholder-error";

  return (
    <div>
      <label
        htmlFor={props.name}
        className="block text-xs font-light text-grey-dark font-sans py-1 capitalize"
      >
        {props.label}
        {props.required ? (
          <span className="text-red">
            {" "}
            *{props.error ? <span> {props.errorMessage}</span> : null}
          </span>
        ) : null}
      </label>
      <div className="relative">
        {props.icon ? (
          <div className="absolute pin-l ml-2 h-full">
            <div
              className={`flex items-center h-full material-icons xxl:text-xl text-lg ${
                props.error ? "text-red-lighter" : "text-grey-light"
              }`}
            >
              {props.icon}
            </div>
          </div>
        ) : null}
        <input
          autoComplete="off"
          disabled={props.disabled}
          className={`${props.disabled ? "input-disabled" : ""} ${
            props.width ? props.width : "w-50"
          } ${props.icon ? "xxl:pl-10 pl-8" : "pl-2"} ${
            props.error ? errorInputStyling : inputStyling
          }`}
          type={props.type}
          onChange={props.onChange}
          value={props.value}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  );
};
