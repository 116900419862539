import axios from "axios";
import { handleError } from "ducks/general";
import {
  getPricePositionChart,
  updateSingleProductTable,
} from "ducks/products";

const PRODUCT_MATCHING_SET_COMPETITORS = "PRODUCT_MATCHING_SET_COMPETITORS";
const PRODUCT_MATCHING_SET_COMPETITORS_LOADING =
  "PRODUCT_MATCHING_SET_COMPETITORS_LOADING";
const PRODUCT_MATCHING_SEARCH_RESULTS = "PRODUCT_MATCHING_SEARCH_RESULTS";
const PRODUCT_MATCHING_SEARCH_LOADING = "PRODUCT_MATCHING_SEARCH_LOADING";
const PRODUCT_MATCHING_CLEAR_SEARCH = "PRODUCT_MATCHING_CLEAR_SEARCH";
const PRODUCT_MATCHING_UPDATE_COMPETITOR_LIST =
  "PRODUCT_MATCHING_UPDATE_COMPETITOR_LIST";
const PRODUCT_MATCHING_SET_ERRORS = "PRODUCT_MATCHING_SET_ERRORS";
const PRODUCT_MATCHING_CLEAR_ERRORS = "PRODUCT_MATCHING_CLEAR_ERRORS";
const PRODUCT_MATCHING_LOADING_NEW_MATCH = "PRODUCT_MATCHING_LOADING_NEW_MATCH";
const PRODUCT_MATCHING_UPDATE_COMPETITOR_LIST_VERIFY_ALL =
  "PRODUCT_MATCHING_UPDATE_COMPETITOR_LIST_VERIFY_ALL";
  const CLEAR_PREV_DATA  ="CLEAR_PREV_DATA"

export const getProductMatchingCompetitors = (id) => {
  return (dispatch) => {
    dispatch(clearPrevData())
    dispatch(productMatchingCompetitorsLoading(true));
    return axios
      .get(process.env.REACT_APP_API_HOST + "/product-export/v2/matches/" + id)
      .then((res) => {
        dispatch(setProductMatchingCompetitors(res.data));
        dispatch(productMatchingCompetitorsLoading(false));
      })
      .catch((err) => {
        dispatch(productMatchingCompetitorsLoading(false));
        dispatch(setProductMatchingCompetitors([]));
      });
  };
};

export const searchProducts = (string, competitor, isMatchingView, type) => {
  return (dispatch) => {
    return axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/product-export/products/search?search=" +
          string +
          "&domainName=" +
          competitor.name
      )
      .then((res) => {
        if (res.data < 1 && !isMatchingView) {
          if (type === "createCard") {
            dispatch(setSearchResults([], true, false));
          } else {
            dispatch(searchErrors(competitor.name, true));
          }
        } else if (res.data < 1 && isMatchingView) {
          dispatch(setSearchResults([], true, true));
        } else {
          if (type === "createCard") {
            dispatch(setSearchResults(res.data, false, true));
          } else {
            dispatch(searchErrors(competitor.name, false));
            dispatch(setSearchResults(res.data, false, true));
          }
        }
      })
      .catch((err) => {
        dispatch(setSearchResults([], true, false));
        dispatch(setSearchLoading(false));
        dispatch(searchErrors(competitor.name, true));
      });
  };
};

export const updateCompetitiorVerify = (product, row) => {
  return (dispatch) => {
    return axios
      .post(
        process.env.REACT_APP_API_HOST +
          "/product-import/v2/matches/" +
          row.mongoId +
          "/verify?competitorDomain=" +
          product.domainName
      )
      .then((res) => {
        dispatch(getPricePositionChart(row));
        dispatch(updateCompetitiorList(res.data, "verify"));
      })
      .catch((err) => dispatch(handleError(err)));
  };
};

const buildQueryString = (items, name) => {
  let urlQuery = name;
  items.forEach((item, i) => {
    if (i + 1 < items.length) {
      urlQuery = urlQuery.concat(item + ",");
    } else {
      urlQuery = urlQuery.concat(item);
    }
  });
  return urlQuery;
};

export const updateCompetitiorVerifyAll = (products, row) => {
  return (dispatch) => {
    let verifyDomainNames = buildQueryString(
      products.map((product) => product.domainName),
      "?competitorDomains="
    );
    return axios
      .post(
        process.env.REACT_APP_API_HOST +
          "/product-import/v2/matches/" +
          row.mongoId +
          "/verifyAll" +
          verifyDomainNames
      )
      .then((res) => {
        dispatch(updateCompetitiorListVerifyAll(products));
      })
      .catch((err) => dispatch(handleError(err)));
  };
};

export const updateCompetitiorUnVerify = (product, row) => {
  return (dispatch) => {
    return axios
      .post(
        process.env.REACT_APP_API_HOST +
          "/product-import/v2/matches/" +
          row.mongoId +
          "/reset?competitorDomain=" +
          product.domainName
      )
      .then((res) => {
        dispatch(getPricePositionChart(row));
        dispatch(updateCompetitiorList(res.data, "unverify"));
      })
      .catch((err) => dispatch(handleError(err)));
  };
};

export const updateCompetitiorReMatch = (product, row) => {
  return (dispatch) => {
    dispatch(setLoadingNewMatch(true));
    return axios
      .post(
        process.env.REACT_APP_API_HOST + "/product-import/v2/matches/manual",
        {
          ownProductId: row.mongoId,
          competitorProductId: product.id,
        }
      )
      .then((res) => {
        dispatch(getPricePositionChart(row));
        dispatch(getProductMatchingCompetitors(row.mongoId));
        dispatch(clearSearchResults());
        dispatch(setLoadingNewMatch(false));
      })
      .catch((err) => dispatch(handleError(err)));
  };
};

export const updateCompetitiorUnMatch = (product, row) => {
  return (dispatch) => {
    return axios
      .post(
        process.env.REACT_APP_API_HOST +
          "/product-import/v2/matches/" +
          row.mongoId +
          "/unmatch?competitorDomain=" +
          product.domainName
      )
      .then((res) => {
        dispatch(getPricePositionChart(row));
        dispatch(updateCompetitiorList(res.data, "unmatch"));
      })
      .catch((err) => dispatch(handleError(err)));
  };
};

export const approveProductStatus = (row) => {
  return (dispatch) => {
    return axios
      .put(
        process.env.REACT_APP_API_HOST +
          "/product/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/products/" +
          row.id +
          "/match-status",
        {
          matchStatus: "Approved",
        }
      )
      .then((res) => {
        dispatch(updateSingleProductTable(row.id, "matchStatus", "Approved"));
      })
      .catch((err) => dispatch(handleError(err)));
  };
};

const setProductMatchingCompetitors = (res) => {
  let onlyActiveCompetitors = res.competitors ? res.competitors.filter(
    (comp) => comp.product.active === "Y"
  ) :  [];
  return {
    type: PRODUCT_MATCHING_SET_COMPETITORS,
    ownProduct: res.product ? res.product : {},
    productMatchingCompetitors: onlyActiveCompetitors
      ? onlyActiveCompetitors
      : [],
  };
};

const productMatchingCompetitorsLoading = (value) => {
  return {
    type: PRODUCT_MATCHING_SET_COMPETITORS_LOADING,
    productMatchingCompetitorsLoading: value,
  };
};

const setSearchResults = (res, value, canMatch) => {
  return {
    type: PRODUCT_MATCHING_SEARCH_RESULTS,
    searchResults: res,
    searchError: value,
    matchView: canMatch,
  };
};

const setSearchLoading = (value) => {
  return {
    type: PRODUCT_MATCHING_SEARCH_LOADING,
    searchLoading: value,
  };
};

export const clearSearchResults = () => {
  return {
    type: PRODUCT_MATCHING_CLEAR_SEARCH,
  };
};

export const updateCompetitiorList = (res, actionType) => {
  return {
    type: PRODUCT_MATCHING_UPDATE_COMPETITOR_LIST,
    res,
    actionType,
  };
};

export const updateCompetitiorListVerifyAll = (products) => {
  return {
    type: PRODUCT_MATCHING_UPDATE_COMPETITOR_LIST_VERIFY_ALL,
    products,
  };
};

const searchErrors = (competitor, value) => {
  return {
    type: PRODUCT_MATCHING_SET_ERRORS,
    competitor,
    value,
  };
};

export const clearSearchError = (competitor) => {
  return {
    type: PRODUCT_MATCHING_CLEAR_ERRORS,
    competitor,
  };
};

const setLoadingNewMatch = (value) => {
  return {
    type: PRODUCT_MATCHING_LOADING_NEW_MATCH,
    loadingNewMatch: value,
  };
};

const clearPrevData = () => {
  return {
    type: CLEAR_PREV_DATA
  }
}

const initialState = {
  searchLoading: false,
  loadingNewMatch: false,
  searchResults: [],
  searchError: false,
  productMatchingCompetitorsLoading: true,
  productMatchingCompetitors: [],
  ownProduct: {},
  matchView: false,
  productUpdateConfig: {},
  errorList: [],
};

export default function reducer(state = initialState, action = {}) {
  let newErrorList = [...state.errorList];
  let newCompList = [];
  switch (action.type) {
    case PRODUCT_MATCHING_SET_COMPETITORS_LOADING:
      return {
        ...state,
        productMatchingCompetitorsLoading:
          action.productMatchingCompetitorsLoading,
      };
    case PRODUCT_MATCHING_SET_COMPETITORS:
      return {
        ...state,
        productMatchingCompetitors: action.productMatchingCompetitors,
        ownProduct: action.ownProduct,
      };
    case PRODUCT_MATCHING_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.searchResults,
        searchError: action.searchError,
        matchView: action.matchView,
      };

    case PRODUCT_MATCHING_SEARCH_LOADING:
      return {
        ...state,
        searchLoading: action.searchLoading,
      };
    case PRODUCT_MATCHING_CLEAR_SEARCH:
      return {
        ...state,
        searchResults: [],
        errorList: [],
        searchError: false,
        matchView: false,
      };
    case PRODUCT_MATCHING_UPDATE_COMPETITOR_LIST:
      if (action.actionType === "unmatch") {
        state.productMatchingCompetitors.forEach((comp) => {
          if (comp.product.id !== action.res.product.id) {
            return newCompList.push(comp);
          }
        });
      } else {
        newCompList = state.productMatchingCompetitors.map((comp) => {
          if (comp.product.id === action.res.product.id) {
            comp.updatedBy = action.res.updatedBy;
            if (action.actionType === "verify") {
              comp.userState = "VERIFIED";
            }
            if (action.actionType === "unverify") {
              comp.userState = null;
            }
          }
          return comp;
        });
      }
      return {
        ...state,
        productMatchingCompetitors: newCompList,
      };
    case PRODUCT_MATCHING_UPDATE_COMPETITOR_LIST_VERIFY_ALL:
      newCompList = state.productMatchingCompetitors.map((comp) => {
        action.products.map((item) => {
          if (item.product.id === comp.product.id) {
            comp.userState = "VERIFIED";
          }
        });
        return comp;
      });

      return {
        ...state,
        productMatchingCompetitors: newCompList,
      };
    case PRODUCT_MATCHING_SET_ERRORS:
      if (action.value) {
        if (!state.errorList.includes(action.competitor)) {
          newErrorList.push(action.competitor);
        }
      } else {
        newErrorList.splice(newErrorList.indexOf(action.competitor), 1);
      }
      return {
        ...state,
        errorList: newErrorList,
      };
    case PRODUCT_MATCHING_CLEAR_ERRORS:
      if (newErrorList.includes(action.competitor.domainName)) {
        newErrorList.splice(
          newErrorList.indexOf(action.competitor.domainName),
          1
        );
      }
      return {
        ...state,
        errorList: newErrorList,
      };
    case PRODUCT_MATCHING_LOADING_NEW_MATCH:
      return {
        ...state,
        loadingNewMatch: action.loadingNewMatch,
      };
      case CLEAR_PREV_DATA:
        return {
          ...state,
          searchResults: [],
          productMatchingCompetitors: [],
          ownProduct: {},
          errorList: [],
        }
    default:
      return state;
  }
}
