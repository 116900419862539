import React, { Component } from "react";

import { Line } from "react-chartjs-2";

class LineChart extends Component {
  render() {
    let colorList = ["#fa7ea8", "#6cb2eb", "#faad63", "#51d88a", "#ef5753"];
    let labelList = [];
    let dataList = [];
    (this.props.data.length > 0 || Object.keys(this.props.data).length > 0) &&
      this.props.data.dataSets !== undefined &&
      this.props.data.dataSets.map((set, index) => {
        let label = set.label.split(" ");
        labelList.push(label[0]);
        for (let i = 0; i <= set.data.length - 1; i++) {
          dataList.push({
            name: [this.props.data.labels[i]],
            data: set.data[i],
          });
        }
      });

    let result = Object.values(
      dataList.reduce((c, { name, data }) => {
        c[name] = c[name] || { name, data: [] };
        c[name].data = c[name].data.concat(Array.isArray(data) ? data : [data]);
        return c;
      }, {})
    );
    let datasets =
      (result.length > 0 || Object.keys(result).length > 0) &&
      this.props.data.dataSets !== undefined &&
      result.map((set, i) => {
        return {
          label: set.name[0],
          data: set.data,
          fill: false,
          backgroundColor: colorList[i],
          pointRadius: 2,
          borderColor: colorList[i],
          borderWidth: 3,
          lineTension: 0,
        };
      });

    let chartData = {
      labels: labelList.length > 0 ? labelList : "",
      datasets: datasets ? datasets : [],
    };
    let chartOptions = {
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              paddingRight: 20,
              maxRotation: 60,
              minRotation: 60,
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          padding: 20,
        },
      },
    };
    return <Line data={chartData} options={chartOptions} />;
  }
}

export default LineChart;
