import React, { Component } from "react";
import { connect } from "react-redux";
import ViewContainer from "containers/ViewContainer";
import Table from "components/Table";
import { openModal, closeModal } from "ducks/modal";
import { getAllTags, getCommonTagsByProducts } from "ducks/tags";
import { getBrandsByMarket, getCategoriesByMarket } from "ducks/products";
import { getPricePositionData, filterPricePosition } from "ducks/pricePosition";
import * as MODAL_TYPE from "utils/modal-types";
import PricePositionFilter from "containers/Filters/PricePositionFilter";
import { analyticsData } from "services/competitorAnalytics";

class PricePosition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topVisible: true,

      pricePositionData: [
        {
          noTableData: true,
          name: "",
          articleNumber: "",
          brand: "",
          category: "",
          price: "",
          lowestCompetitorPrice: "",
          priceDiff: "",
          lowestCompetitorName: "",
          tags: [],
        },
      ],
      selectedPricePosition: [],
      holdSelectedPricePosition: [],
      holdSelectedPagePricePosition: [],
      hoverAddButtonGroup: false,
    };
  }

  componentDidMount() {
    this.props.getPricePositionData(1, 20, null, null);
    this.props.getAllTags();
    this.props.getCategoriesByMarket();
    this.props.getBrandsByMarket();
  }

  componentWillUnmount() {}

  selectPricePositionRows = (rows, page, row) => {
    this.setState({
      holdSelectedPricePosition: rows,
      holdSelectedPagePricePosition: page,
      selectedPricePosition: row,
    });
  };

  openTagModal = (data) => {
    this.props.getCommonTagsByProducts(this.state.selectedPricePosition);
    this.props.openModal(
      this.state.selectedPricePosition,
      MODAL_TYPE.TAGS,
      MODAL_TYPE.LARGE
    );
  };

  handleHoverRowButtonClicks = (icon, data) => {
    switch (icon) {
      case "visibility":
      case "edit":
      case "local_offer":
        this.openTagModal(data);
      default:
    }
  };

  onDoubleClickProducts = (row) => {};

  getProductData = (page, rowSize, attr, direction) => {
    if (this.props.isFiltered) {
      this.props.filterPricePosition(
        page,
        rowSize,
        this.props.idList,
        attr,
        direction
      );
    } else {
      this.props.getPricePositionData(page, rowSize, attr, direction);
    }
  };

  circleButtonOnMouseEnterGroup = () => {
    this.setState({ hoverAddButtonGroup: true });
  };
  circleButtonOnMouseLeaveGroup = () => {
    this.setState({ hoverAddButtonGroup: false });
  };

  clearProductSelections = () => {
    this.setState({
      selectedPricePosition: [],
      holdSelectedPricePosition: [],
      holdSelectedPagePricePosition: [],
    });
  };

  render() {
    const topRightButtonsProduct = [
      {
        id: 1,
        icon: "close",
        text: "clear all",
        onClick: () => this.setState({ selectedPricePosition: [] }),
      },
      {
        id: 2,
        icon: "local_offer",
        text: "tag",
        onClick: this.handleHoverRowButtonClicks,
      },
    ];

    let pricePositionDataKeys = [
      "articleNumber",
      "brand",
      "category",
      "id",
      "price",
      "tags",
      "name",
      "numberOfCompetitors",
      "lowestCompetitorPrice",
      "lowestCompetitorName",
      "priceDiff",
      "mongoId",
      "ownPrice",
    ];

    let longest = 0;

    this.props.pricePosition &&
      this.props.pricePosition.map((item) => {
        if (item.pricePositions.length > longest) {
          return (longest = item.pricePositions.length);
        } else return;
      });

    let parsedPricePositionData = [];
    parsedPricePositionData =
      this.props.pricePosition.length > 0 &&
      this.props.pricePosition.map((item) => {
        let mainData = {
          id: item.id,
          name: item.name,
          articleNumber: item.articleNumber ? item.articleNumber : " ",
          brand: item.brand
            ? typeof item.brand === "object"
              ? item.brand.name
              : item.brand
            : "-",
          category: item.category
            ? typeof item.category === "object"
              ? item.category.name
              : item.category
            : "-",
          price: item.ownPrice ? item.ownPrice : " ",
          numberOfCompetitors: item.numberOfCompetitors
            ? item.numberOfCompetitors
            : "0",
          lowestCompetitorPrice: item.lowestCompetitorPrice
            ? item.lowestCompetitorPrice
            : " ",
          priceDiff: item.priceDiff ? item.priceDiff : " ",
          lowestCompetitorName: item.lowestCompetitorName
            ? item.lowestCompetitorName
            : " ",
          tags: item.tags ? item.tags : [],
        };

        let position = [];
        let pricePositionEmpty = [];

        let currentLength = item.pricePositions.length;

        if (item.pricePositions.length < 1) {
          for (let i = currentLength; i < longest; i++) {
            pricePositionEmpty.push({
              name: " ",
              price: " ",
            });
          }
          let total = position.concat(pricePositionEmpty);
          let snipped = total.slice(0, longest);
          snipped.map((item, i) => {
            let index = i + 1;
            return (mainData = {
              ...mainData,
              ["Position " + index]: {
                hideSorting: true,
                value: (
                  <>
                    {item.name}
                    <span className="font-medium">
                      {" "}
                      {item.price === null || item.price.length > 0 ? " " : "-"}
                      {item.price === null ? " " : item.price}
                    </span>
                  </>
                ),
              },
            });
          });
        }
        item.pricePositions.map((item, i) => {
          if (!pricePositionDataKeys.includes(item)) {
            if (currentLength < longest) {
              for (let i = currentLength; i < longest; i++) {
                pricePositionEmpty.push({
                  name: " ",
                  price: " ",
                });
              }
            }
            position.push({
              name: item.name,
              price: item.price,
            });

            let total = position.concat(pricePositionEmpty);
            let snipped = total.slice(0, longest);
            snipped.map((item, i) => {
              let index = i + 1;
              return (mainData = {
                ...mainData,
                ["Position " + index]: {
                  hideSorting: true,
                  value: (
                    <>
                      {item.name}
                      <span className="font-medium w-full block">
                        {" "}
                        {item.price === null || item.price.length > 0
                          ? " "
                          : "-"}
                        {item.price === null ? " " : item.price}
                      </span>
                    </>
                  ),
                },
              });
            });
          }
        });
        return mainData;
      });

    const tableConfigProducts = {
      data:
        parsedPricePositionData.length > 0
          ? parsedPricePositionData
          : this.state.pricePositionData,

      name: "Price Position",
      tableLoading: this.props.pricePositionTableLoading,
      noData: this.props.pricePositionNoData,
      onDoubleClick: this.onDoubleClickProducts,
      onClick: this.onClick,
      hiddenCols: ["id", "noTableData", "mongoId", "ownPrice"],
      topRightButtons: topRightButtonsProduct,
      filter: true,
      selectRows: this.selectPricePositionRows,
      selectAllRows: this.selectPricePositionRows,
      selectedRows: this.state.holdSelectedPricePosition,
      selectedPages: this.state.holdSelectedPagePricePosition,
      paginationGetData: this.props.showAllProducts
        ? this.getProductData
        : this.paginationProductsByGroupId,
      dataLength: this.props.totalPricePosition,
      currentPage: this.props.currentPagePricePosition,
      rowSize: this.props.rowSizePricePosition,
      totalPages: this.props.totalPricePositionPages,
      attr: this.props.pricePositionSortingAttr,
      direction: this.props.pricePositionSortingDirection,
      isTableLarge: this.props.isTableLarge,
    };

    return (
      <ViewContainer
        hasPageButtons={true}
        topVisible={false}
        handleToggleTop={this.handleToggleTop}
      >
        <div className="relative my-8 pt-4">
          <Table tableConfig={tableConfigProducts}>
            <PricePositionFilter
              clearSelections={this.clearProductSelections}
            />
          </Table>
        </div>
      </ViewContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pricePosition: state.pricePosition.products,
    isFiltered: state.pricePosition.isFiltered,
    idList: state.pricePosition.idList,
    currentPagePricePosition: state.tablePagination.pricePositionPage,
    rowSizePricePosition: state.tablePagination.pricePositionRowSize,
    pricePositionSortingAttr: state.tablePagination.pricePositionSortingAttr,
    pricePositionSortingDirection:
      state.tablePagination.pricePositionSortingDirection,
    totalPricePositionPages: state.tablePagination.pricePositionTotalPages,
    showAllProducts: state.pricePosition.showAllProducts,
    pricePositionTableLoading: state.pricePosition.pricePositionTableLoading,
    totalPricePosition: state.pricePosition.totalPricePosition,
    pricePositionNoData: state.pricePosition.pricePositionNoData,
    isTableLarge: state.sidepanel.isTableLarge,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPricePositionData: (page, rowSize, attr, direction) =>
      dispatch(getPricePositionData(page, rowSize, attr, direction)),
    filterPricePosition: (page, rowSize, idList, attr, sorting) =>
      dispatch(filterPricePosition(page, rowSize, idList, attr, sorting)),
    openModal: (targetUnitData, modalType, modalSize) =>
      dispatch(openModal(targetUnitData, modalType, modalSize)),
    closeModal: () => dispatch(closeModal()),
    getAllTags: () => dispatch(getAllTags()),
    getCommonTagsByProducts: (products) =>
      dispatch(getCommonTagsByProducts(products)),
    // getCommonTagsByProducts: products =>
    //   dispatch(getCommonTagsByProducts(products)),
    getAllTags: () => dispatch(getAllTags()),
    getCategoriesByMarket: () => dispatch(getCategoriesByMarket()),
    getBrandsByMarket: () => dispatch(getBrandsByMarket()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PricePosition);
