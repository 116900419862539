import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      searchValue: "",
      searching: false
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = e => {
    if (this.singleDropdown.contains(e.target)) {
      return;
    } else {
      this.closeDropdown();
    }
  };

  closeDropdown = () => {
    this.setState({ dropdownOpen: false });
  };

  handleToggle = event => {
    this.setState(prevSate => ({
      dropdownOpen: !prevSate.dropdownOpen,
      searching: false,
      searchValue: ""
    }));
  };

  selectedItem = item => {
    this.setState({
      searching: false,
      searchValue: ""
    });
    this.props.options.forEach(option => {
      if (option.id === item.id && this.props.handleSelect) {
        if (this.props.selected.id === item.id) {
          this.props.handleSelect(this.props.name, {}, this.props.index);
        } else {
          this.props.handleSelect(this.props.name, option, this.props.index);
        }
      } else {
        return null;
      }
      if (this.props.removeInputError) {
        this.props.removeInputError(this.props.name);
      }
    });
    this.closeDropdown();
  };

  handleFilter = event => {
    let value = event.target.value;

    this.setState({
      searching: true,
      dropdownOpen: true,
      searchValue: value
    });
    if (this.props.removeInputError) {
      this.props.removeInputError(this.props.name);
    }
  };

  render() {
    const disabledStyling =
      "min-w-full max-w-full bg-grey-lighter border-solid border-grey-light border-1 text-grey-dark xxl:text-base text-sm font-sans font-light xxl:h-10 xxl:pl-3 p-2 pr-8 truncate cursor-not-allowed input-placeholder";
    const inputStyling =
      "min-w-full max-w-full bg-white border-solid border-grey-light border-1 focus:text-teal outline-none text-teal-darker font-light xxl:text-base text-sm font-sans xxl:h-10 xxl:pl-3 p-2 pr-8 truncate cursor-pointer input-placeholder";
    const errorInputStyling =
      "min-w-full max-w-full bg-red-lightest border-solid border-red-lighter border-1 text-red outline-none xxl:text-base font-light text-sm font-sans xxl:h-10 xxl:pl-3 p-2 pr-8 truncate cursor-pointer input-placeholder-error";
    let dropdownItemList;
    if (this.props.options && this.props.options.length > 0) {
      dropdownItemList = this.props.options.map(item =>
        item.name
          .toLowerCase()
          .includes(this.state.searchValue.toLowerCase()) ? (
          <li
            id={this.props.name}
            onClick={() => {
              this.selectedItem(item);
              if (this.props.removeInputError) {
                this.props.removeInputError(this.props.name);
              }
            }}
            className={`flex flex-row font-light list-reset px-2 xxl:text-base text-sm font-sans cursor-pointer ${
              this.props.selected && item.id === this.props.selected.id
                ? "bg-teal-dark text-white"
                : "bg-white text-grey-darker hover:bg-grey-lightest"
            }`}
            key={item.id}
          >
            <div className="h-full w-5/6 flex-no-grow">
              <div className="max-w-full break-words pl-1 pr-3 py-3">
                {item.name}
              </div>
            </div>
            {this.props.selected && item.id === this.props.selected.id ? (
              <div className="min-h-full flex-1 flex flex-row justify-end">
                <div className="material-icons self-center xxl:text-lg text-md text-white">
                  done
                </div>
              </div>
            ) : null}
          </li>
        ) : null
      );
    }
    return (
      <div ref={node => (this.singleDropdown = node)} key="this.props.key">
        <label
          htmlFor={this.props.name}
          className="block text-xs font-light text-grey-dark font-sans py-1"
        >
          {this.props.label}
          {this.props.required ? (
            <span className="text-red">
              {" "}
              *{this.props.error ? <span> Required.</span> : null}
            </span>
          ) : null}
        </label>
        <div
          className={`relative cursor-pointer ${
            this.props.width ? this.props.width : "w-50"
          }`}
        >
          <div onClick={this.handleToggle} className="w-full">
            <div
              className={`material-icons absolute h-full flex flex-row ${
                this.props.error
                  ? "text-red-dark"
                  : this.props.options && this.props.options.length > 0
                  ? "text-teal-darker"
                  : "text-grey-dark cursor-not-allowed"
              } pin-r mr-2 z-3 cursor-pointer`}
            >
              <div className="self-center">unfold_more</div>
            </div>
            <input
              autoComplete="off"
              disabled={
                this.props.options === undefined ||
                this.props.options.length < 1
              }
              required={this.props.required}
              className={
                this.props.error
                  ? errorInputStyling
                  : this.props.options === undefined ||
                    this.props.options.length < 1
                  ? disabledStyling
                  : inputStyling
              }
              type="text"
              name={this.props.name}
              value={
                this.props.options.length > 0
                  ? this.state.searching
                    ? this.state.searchValue
                    : this.props.selected &&
                      this.props.options.find(option => {
                        return option.id === this.props.selected.id;
                      })
                    ? this.props.options.find(option => {
                        return option.id === this.props.selected.id;
                      }).name
                    : this.props.selected.name === undefined
                    ? ""
                    : this.props.selected.name
                  : ""
              }
              placeholder={this.props.placeholder}
              onChange={this.handleFilter}
            />
          </div>
          {this.state.dropdownOpen &&
          this.props.options &&
          this.props.options.length > 0 ? (
            <ul
              className={`shadow-md ${
                this.props.dropdownHeight
                  ? this.props.dropdownHeight
                  : "xxl:max-h-15 max-h-10"
              } overflow-y-auto bg-white mt-2 px-0 border-1 border-solid border-grey-lighter overflow-y-auto absolute z-30 min-w-full max-w-full mb-8`}
            >
              {dropdownItemList}
            </ul>
          ) : null}
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  /** Usually pointed to this.state.something - Takes an object of a previously selected item. */
  /**{
      id: 1,
      name: "Option A"
    };*/
  selected: PropTypes.object,
  /** [
        {
          id: 1,
          name: "Option A"
        },
        {
          id: 2,
          name: "Option B"
        },
        {
          id: 3,
          name: "Option C"
        },
        {
          id: 4,
          name: "Option D"
        }
      ]
     **/
  options: PropTypes.array,
  /** Name for the Dropdown so that the selected item can be sorted correctly into the correct state. */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /** Pass a function that handles the selected items as you want. */
  /** handleSelect = (name, selectedItem) => {
    this.setState({name: selectedItem})
  } **/
  handleSelect: PropTypes.func,
  /** Pass a function that removes input errors. It will only need the name of the dropdown. */
  removeInputError: PropTypes.func,
  /** Label for the Dropdown. */
  label: PropTypes.string,
  /** To display errors. */
  error: PropTypes.bool,
  /** If it is required. */
  required: PropTypes.bool
};
