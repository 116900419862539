import React from "react";

const SelectableList = props => {
  return (
    <div className="flex flex-row w-full">
      <div className="flex-column w-1/2 ">
        <div className="flex flex-col mb-5">
          <p className="text-teal-dark font-light flex my-auto pl-6">Groups</p>
        </div>
        <div className="border-1 h-8 mx-4 my-2 border-grey-light flex flex-row rounded-full pl-3">
          <input
            autoComplete="off"
            placeholder="Search Groups"
            className="outline-none border-none xxl:text-sm text-xs truncate input-placeholder h-full flex-1 pr-1 focus:text-teal text-teal-dark font-light font-sans"
            type="text"
            onChange={props.handleChangeFilter}
            name="filteredGroups"
          />
          <div className="flex items-center pr-2 text-grey">
            <i className="material-icons text-lg">search</i>
          </div>
        </div>
        <ul className="px-4 text-white h-full overflow-x-scroll max-h-20 font-sans">
          {props.filteredGroups &&
            props.filteredGroups.map(group => {
              return (
                <li
                  className={`rounded-full my-2 h-8 flex flex-row items-center justify-center ${
                    props.selectedGroups &&
                    props.selectedGroups.find(select => select.id === group.id)
                      ? "bg-blue cursor-not-allowed"
                      : "bg-blue-light hover:bg-blue cursor-pointer"
                  } `}
                  key={group.id}
                  onClick={() => props.handleSelectecdGroup(group.id)}
                >
                  <p className="flex-1 truncate xxl:text-md text-sm px-3 text-center font-light">
                    {" "}
                    {group.name}{" "}
                  </p>
                  <div className="flex items-center mr-1">
                    <i
                      className={`material-icons p-1 text-lg rounded-full ${
                        props.selectedGroups &&
                        props.selectedGroups.find(
                          select => select.id === group.id
                        )
                          ? "cursor-not-allowed"
                          : "hover:bg-blue-light cursor-pointer"
                      } `}
                      onClick={() => props.handleSelectecdGroup(group.id)}
                    >
                      add
                    </i>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="flex-column w-1/2  ml-4">
        <div className="flex flex-col mb-5">
          <p className="text-teal-dark font-light flex my-auto pl-6">
            Selected Groups
            <span className="bg-table-grey text-grey-dark text-sm flex rounded-full h-6 w-6 items-center justify-center ml-3">
              {props.selectedGroups ? props.selectedGroups.length : "0"}{" "}
            </span>
          </p>
        </div>
        <div className="border-1 h-8 mx-4 my-2 border-grey-light flex flex-row rounded-full pl-3">
          <input
            autoComplete="off"
            placeholder="Search Groups"
            className="outline-none border-none truncate input-placeholder xxl:text-sm text-xs h-full flex-1 pr-1 focus:text-teal text-teal-dark font-light font-sans"
            type="text"
            onChange={props.handleChangeFilter}
            name="filteredSelectedGroups"
          />
          <div className="flex items-center pr-2 text-grey">
            <i className="material-icons text-lg">search</i>
          </div>
        </div>

        <ul className="px-4 text-white h-full overflow-x-scroll max-h-20 font-sans">
          {props.filteredSelectedGroups &&
            props.filteredSelectedGroups.map(group => {
              return (
                <li
                  className="rounded-full my-2 h-8 flex flex-row items-center justify-center bg-teal hover:bg-teal-dark "
                  key={group.id}
                >
                  <p className="flex-1 truncate pl-3 text-center font-light xxl:text-md text-sm">
                    {" "}
                    {group.name}{" "}
                  </p>
                  <div className="flex items-center mr-1">
                    <i
                      className="material-icons p-1 text-lg rounded-full hover:bg-teal cursor-pointer "
                      onClick={() => props.removeFromSelect(group.id)}
                    >
                      close
                    </i>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default SelectableList;
