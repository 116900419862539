import React, { Component } from "react";
import { connect } from "react-redux";

import * as FORM_TYPE from "utils/form-types";

import { closeFormpanel, changeFormState } from "ducks/formpanel";

import Products from "containers/Forms/Products/Products";
import PriceCampaign from "containers/Forms/PriceCampaign/PriceCampaign";
import PriceRule from "containers/Forms/PriceRule/PriceRule";
import Groups from "containers/Forms/Groups/Groups";
import Analytics from "containers/Forms/PriceCampaign/Analytics";

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formType: "",
      formWidthGrow: false,
      formWidth: null
    };
  }

  componentDidMount() {
    const updateformType = type => {
      this.setState({ formType: type });
    };

    switch (this.props.formType) {
      case FORM_TYPE.PRODUCTS:
        return updateformType(
          <Products
            data={this.props.targetUnitData}
            toggleFormWidth={this.toggleFormWidth}
          />
        );
      case FORM_TYPE.PRICECAMPAIGN:
        return updateformType(
          <PriceCampaign
            data={this.props.targetUnitData}
            toggleFormWidth={this.toggleFormWidth}
          />
        );
      case FORM_TYPE.PRICERULE:
        return updateformType(
          <PriceRule
            data={this.props.targetUnitData}
            toggleFormWidth={this.toggleFormWidth}
          />
        );
      case FORM_TYPE.GROUPLIST:
        return updateformType(
          <Groups
            data={this.props.targetUnitData}
            toggleFormWidth={this.toggleFormWidth}
          />
        );
      case FORM_TYPE.ANALYTICS:
        return updateformType(
          <Analytics
            data={this.props.targetUnitData}
            toggleFormWidth={this.toggleFormWidth}
          />
        );
      default:
        return;
    }
  }

  componentWillUnmount() {
    this.setState({ formWidth: null });
  }

  toggleFormWidth = width => {
    this.setState(prevState => ({ formWidthGrow: !prevState.formWidthGrow }));
  };

  render() {
    return (
      <div
        className={`h-screen ${
          this.state.formWidthGrow
            ? this.state.formWidth
              ? this.state.formWidth
              : "min-w-9/10 max-w-9/10"
            : "xxl:min-w-1/3 xl:min-w-1/2 lg:min-w-2/3 min-w-5/6 lg:max-w-2/3 max-w-5/6 xxl:max-w-1/3 xl:max-w-1/2"
        } bg-white h-screen fixed pin-r z-50 shadow-lg ${this.props.classList}`}
      >
        <div className="h-full w-full overflow-x-hidden">
          {this.state.formType}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    formType: state.formpanel.formType,
    targetUnitData: state.formpanel.targetUnitData,
    formState: state.formpanel.formState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeFormState: value => dispatch(changeFormState(value)),
    closeFormpanel: () => dispatch(closeFormpanel())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormContainer);
