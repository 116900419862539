import React, { Component } from "react";

import { cleanPrice } from "helpers/cleanPrice";
import { SmallCircleButton } from "components/SmallCircleButton/SmallCircleButton";
import { SearchBar } from "components/SearchBar/SearchBar";

export default class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      value: "",
    };
  }

  componentDidMount() {
    this.props.buttons &&
      this.props.buttons.map((button) => {
        this.setState({ [button.name]: false });
      });
  }

  toggleSearch = () => {
    this.setState((prevState) => ({ showSearch: !prevState.showSearch }));
    this.props.clearSearchError(this.props.product);
  };

  handleOnChange = (event) => {
    this.setState({ value: event.target.value });
    this.props.handleSearch(
      event,
      this.state.value,
      this.props.product,
      "productCard"
    );
  };

  handleClickSearch = () => {
    this.props.handleClickSearch(this.props.product, "productCard");
  };

  toggleTooltips = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <div className="w-full mb-4 p-2 px-6 h-full font-sans xxl:text-sm text-xs relative">
        <div
          className={`${
            this.props.ownProduct
              ? " "
              : !this.props.active
              ? "inactive-product-card "
              : ""
          } h-full w-full flex flex-col `}
        >
          <div className="w-full flex flex-row items-center h-6 mb-1">
            <div
              className={`${
                !this.state.showSearch && "flex-1 flex"
              } text-teal-dark`}
            >
              {this.props.product.domainName}
            </div>
            {this.state.showSearch ? (
              <div className="flex-1 flex items-center justify-center">
                <div className="xxl:w-full xl:w-full lg:w-full w-1/2 xxl:px-4 px-3">
                  <SearchBar
                    onKeyDown={this.handleOnChange}
                    onChange={this.handleOnChange}
                    value={this.state.value}
                    placeholder="Search"
                    loading={this.props.searchLoading}
                    rounded={true}
                    handleClickSearch={this.handleClickSearch}
                    icon="search"
                  />
                </div>
              </div>
            ) : (
              this.props.showProductState && (
                <div className="flex-1 flex flex-row text-teal-dark items-center justify-center">
                  {this.props.verified ? (
                    <div className="h-full flex items-center">
                      <i className="material-icons-outlined pr-2 text-lg">
                        check_circle_outline
                      </i>
                      Verified
                    </div>
                  ) : this.props.unmatched ? (
                    <div className="h-full flex items-center text-red-dark">
                      <i className="material-icons-outlined pr-2 text-lg">
                        cancel
                      </i>{" "}
                      Unmatched
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )
            )}
            <div
              className={`${
                !this.state.showSearch && "flex-1 flex"
              } justify-end`}
            >
              {this.props.product.url && (this.props.product.url.includes("https") || this.props.product.url.includes("http") ) && ( 

              <a
                href={this.props.product.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="material-icons-outlined p-2 xxl:text-lg text-md text-grey-darker rounded-full cursor-pointer bg-table-grey hover:bg-teal-dark  hover:text-white relative z-20">
                  shopping_cart
                </div>
              </a>
              )}
            </div>
          </div>
          <div className={` w-full px-2 flex-1 `}>
            <div className="inline-block w-1/3 h-full">
              {this.props.product.imgUrl ? (
                <img
                  className="max-h-7"
                  src={this.props.product.imgUrl}
                  alt="image"
                />
              ) : (
                <div className="h-full flex items-center justfy-center">
                  <div className="xxl:text-xs text-xxs text-grey font-light">
                    No Image Available
                  </div>
                </div>
              )}
            </div>
            <div className="pl-2 inline-block w-2/3 align-top">
              <ul className="text-grey-darker w-full">
                <li className="w-full py-1 flex flex-row">
                  <span className="font-light inline-block pr-2">Name</span>{" "}
                  <span className="font-medium inline-block flex-1 truncate">
                    {this.props.product.name}
                  </span>
                </li>
                <li className="w-full py-1 flex flex-row">
                  <span className="font-light inline-block pr-2">Brand</span>{" "}
                  <span className="font-medium inline-block flex-1 truncate">
                    {this.props.product.brand}
                  </span>
                </li>
                <li className="w-full py-1 flex flex-row">
                  <span className="font-light inline-block pr-2">Category</span>{" "}
                  <span className="font-medium inline-block flex-1 truncate">
                    {this.props.product.category}
                  </span>
                </li>
                <li className="w-full py-1 flex flex-row">
                  <span className="font-light inline-block pr-2">Price</span>{" "}
                  <span className="font-medium inline-block flex-1 truncate">
                    {this.props.price
                      ? this.props.price
                      : this.props.product.price}
                  </span>
                </li>
                {!this.props.ownProduct && (
                  <li className="w-full py-1 flex flex-row">
                    <span className="font-light inline-block pr-2">
                      {" "}
                      Active
                    </span>{" "}
                    <span className="font-medium inline-block flex-1 truncate">
                      {this.props.active ? (
                        "Active"
                      ) : (
                        <span className="text-grey">Not Active</span>
                      )}
                    </span>
                  </li>
                )}
                <li className="w-full py-1 flex flex-row">
                  <span className="font-light inline-block pr-2">
                    Article Nr
                  </span>{" "}
                  <span className="font-medium inline-block flex-1 truncate">
                    {this.props.product.articleNumber}
                  </span>
                </li>
                {this.props.showUpdatedBy && (
                  <li className="w-full py-1 flex flex-row">
                    <span className="font-light inline-block pr-2">
                      Updated By
                    </span>{" "}
                    <span className="font-medium inline-block flex-1 truncate">
                      {this.props.updatedBy ? this.props.updatedBy : "-"}
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="w-full flex flex-row h-10 my-2 justify-start  ">
            {!this.props.ownProduct && !this.props.matchView && (
              <>
                <SmallCircleButton
                  icon={"cancel"}
                  name={"Unmatch"}
                  onClick={() =>
                    this.props.onClick("unmatch", this.props.product)
                  }
                  onMouseEnter={() => this.toggleTooltips("unmatch", true)}
                  onMouseLeave={() => this.toggleTooltips("unmatch", false)}
                  showtooltip={this.state.unmatch}
                />
                <div className="pr-1" />

                {!this.props.unmatched ? (
                  <SmallCircleButton
                    icon={"compare_arrows"}
                    name={"Rematch"}
                    onClick={() => {
                      this.toggleSearch();
                    }}
                    onMouseEnter={() => this.toggleTooltips("rematch", true)}
                    onMouseLeave={() => this.toggleTooltips("rematch", false)}
                    showtooltip={this.state.rematch}
                  />
                ) : (
                  <div className="h-full flex items-center">
                    <div className="material-icons-outlined p-2 xxl:text-lg text-md cursor-not-allowed text-grey-dark bg-table-grey rounded-full">
                      close
                    </div>
                  </div>
                )}

                <div className="pr-1" />

                {!this.props.unmatched ? (
                  <SmallCircleButton
                    icon={
                      this.props.verified && this.state.verify
                        ? "undo"
                        : "check_circle_outline"
                    }
                    name={this.props.verified ? "Unverify" : "Verify"}
                    onClick={() =>
                      this.props.verified
                        ? this.props.onClick("unverify", this.props.product)
                        : this.props.onClick("verify", this.props.product)
                    }
                    onMouseEnter={() => this.toggleTooltips("verify", true)}
                    onMouseLeave={() => this.toggleTooltips("verify", false)}
                    showtooltip={this.state.verify}
                    success={this.props.verified}
                  />
                ) : (
                  <div className="h-full flex items-center">
                    <div className="material-icons-outlined p-2 xxl:text-lg text-md cursor-not-allowed text-grey-dark bg-table-grey rounded-full">
                      close
                    </div>
                  </div>
                )}
              </>
            )}
            {this.props.matchView && !this.props.ownProduct && (
              <SmallCircleButton
                icon={"check_circle_outline"}
                name={"Rematch"}
                onClick={() => {
                  this.props.onClick("rematch", this.props.product);
                  this.props.closeMatching();
                }}
                onMouseEnter={() => this.toggleTooltips("rematch", true)}
                onMouseLeave={() => this.toggleTooltips("rematch", false)}
                showtooltip={this.state.rematch}
              />
            )}

            <div className="h-3 py-1 w-full text-red-dark">
              {this.props.error && this.state.showSearch && "No Products Found"}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
