import React from "react";
import Button from "components/Button";

export const ModalContentWrapper = props => {
  return (
    <div className="h-full flex flex-col max-w-full">
      <div className="bg-table-grey">
        {props.modalConfig && props.modalConfig.titleInput ? (
          <div className="py-1 bg-table-grey">
            <input
              autoComplete="off"
              className="flex-1 w-full h-full p-4 bg-table-grey font-sans font-light text-grey-darker text-xl xxl:text-2xl outline-none cursor-pointer"
              placeholder={props.modalConfig.titleInput.placeholder}
              type="text"
              value={props.modalConfig.titleInput.value}
              onChange={e => props.modalConfig.titleInput.onChange(e)}
              onKeyPress={e => props.modalConfig.titleInput.onKeyPress(e)}
            />
          </div>
        ) : (
          <div className="font-sans py-4 font-light text-grey-darker px-2 text-center text-xl ">
            {props.modalConfig.title}
          </div>
        )}
      </div>

      <div className="flex-1 overflow-y-auto">{props.children}</div>

      <div
        className={`${
          props.modalConfig.warning ? "bg-red-lightest" : "bg-teal-lightest"
        } w-full py-3 flex flex-row justify-center items-center px-4`}
      >
        {props.modalConfig && props.modalConfig.hasBottomButtons ? (
          <>
            <div className="flex-1 flex justify-start">
              <div
                className={`${
                  props.modalConfig.warning
                    ? "text-red"
                    : "text-teal-dark hover:text-teal"
                } text-xs cursor-pointer uppercase`}
                onClick={() => props.modalConfig.handleClose()}
              >
                {props.modalConfig.closeText}
              </div>
            </div>
            {props.modalConfig.hideSubmitButton ? null : (
              <div className="flex-1 flex justify-end">
                {props.modalConfig.canSave ? (
                  <Button
                    onClick={() => props.modalConfig.buttonOnClick()}
                    bgColor={
                      props.modalConfig.warning ? "secondary" : "primary"
                    }
                    label={props.modalConfig.buttonLabel}
                  />
                ) : (
                  <Button
                    bgColor="disabled"
                    label={props.modalConfig.buttonLabel}
                  />
                )}
              </div>
            )}
          </>
        ) : (
          <div className="flex-1 flex justify-end">
            <div
              className="text-teal-dark hover:text-teal font-medium text-xs cursor-pointer uppercase px-6 py-2"
              onClick={() => props.modalConfig.handleClose()}
            >
              {props.modalConfig.buttonLabel}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
