import axios from "axios";
import { setPriceHistoryPagination } from "./tablePagination";
import { handleError } from "./general";

const SET_ALL_PRICE_HISTORY = "SET_ALL_PRICE_HISTORY";
const PRICE_HISTORY_IS_FILTERED = "PRICE_HISTORY_IS_FILTERED";
const PRICE_HISTORY_SET_NO_DATA = "PRICE_HISTORY_SET_NO_DATA";
const PRICE_HISTORY_TOGGLE_SHOW_ALL_DATA = "PRICE_HISTORY_TOGGLE_SHOW_ALL_DATA";
const PRICE_HISTORY_TABLE_LOADING = "PRICE_HISTORY_TABLE_LOADING";
const CLEAR_ALL_PRICE_HISTORY = "CLEAR_ALL_PRICE_HISTORY";
const PRICE_HISTORY_UPDATE_TAG_LIST = "PRICE_HISTORY_UPDATE_TAG_LIST";
const PRICE_HISTORY_REMOVE_FROM_TAG_LIST = "PRICE_HISTORY_REMOVE_FROM_TAG_LIST";
const PRICE_HISTORY_CLEAR_DATA = "PRICE_HISTORY_CLEAR_DATA";
const PRICE_HISTORY_LOADING = "PRICE_HISTORY_LOADING";
const PRICE_HISTORY_SET_ID_LIST = "PRICE_HISTORY_SET_ID_LIST";

export const filterPriceHistory = (page, rowSize, idList, attr, sorting) => {
  return (dispatch) => {
    let attribute = attr;
    if (attribute === "category") {
      attribute = "categoryName";
    }
    if (attribute === "brand") {
      attribute = "brandName";
    }
    dispatch(tableLoading(true));
    dispatch(setNoData(false));
    dispatch(clearAllPriceHistory());
    let url =
      attribute && sorting
        ? process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/price-history?page=" +
          page +
          "&size=" +
          rowSize +
          "&sort=" +
          attribute +
          "," +
          sorting +
          idList
        : process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/price-history?page=" +
          page +
          "&size=" +
          rowSize +
          idList;
    return axios
      .get(url)
      .then((res) => {
        dispatch(setFiltered(true));
        dispatch(setIdList(idList));
        dispatch(setAllPriceHistory(res.data));
        dispatch(
          setPriceHistoryPagination(
            page,
            rowSize,
            res.data.totalPages,
            attr,
            sorting
          )
        );
        dispatch(tableLoading(false));
        if (res.data.content.length < 1) {
          dispatch(setNoData(true));
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
        dispatch(tableLoading(false));
        dispatch(setNoData(true));
      });
  };
};

const showAllProducts = (value) => {
  return {
    type: PRICE_HISTORY_TOGGLE_SHOW_ALL_DATA,
    showAllProducts: value,
  };
};

const setAllPriceHistory = (products) => {
  return {
    type: SET_ALL_PRICE_HISTORY,
    products: products.content,
    totalPriceHistory: products.totalElements,
  };
};

const clearAllPriceHistory = () => {
  return {
    type: CLEAR_ALL_PRICE_HISTORY,
  };
};

export const setFiltered = (val) => {
  return {
    type: PRICE_HISTORY_IS_FILTERED,
    isFiltered: val,
  };
};

const tableLoading = (value) => {
  return {
    type: PRICE_HISTORY_TABLE_LOADING,
    priceHistoryTableLoading: value,
  };
};

export const setNoData = (value) => {
  return {
    type: PRICE_HISTORY_SET_NO_DATA,
    priceHistoryNoData: value,
  };
};

export const clearData = () => {
  return {
    type: PRICE_HISTORY_CLEAR_DATA,
  };
};

const setIdList = (ids) => {
  return {
    type: PRICE_HISTORY_SET_ID_LIST,
    idList: ids,
  };
};

const initialState = {
  idList: [],
  isFiltered: false,
  products: [],

  totalPriceHistory: null,
  showAllProducts: true,
  priceHistoryTableLoading: false,
  priceHistoryNoData: false,
  priceHistoryLoading: false,
  noProductData: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ALL_PRICE_HISTORY:
      return {
        ...state,
        products: action.products,
        totalPriceHistory: action.totalPriceHistory,
        totalPages: action.totalPages,
      };
    case PRICE_HISTORY_IS_FILTERED:
      return {
        ...state,
        isFiltered: action.isFiltered,
      };
    case PRICE_HISTORY_TOGGLE_SHOW_ALL_DATA:
      return {
        ...state,
        showAllProducts: action.showAllProducts,
      };
    case PRICE_HISTORY_TABLE_LOADING:
      return {
        ...state,
        priceHistoryTableLoading: action.priceHistoryTableLoading,
      };
    case CLEAR_ALL_PRICE_HISTORY:
      return {
        ...state,
        products: [],
      };
    case PRICE_HISTORY_SET_NO_DATA:
      return {
        ...state,
        priceHistoryNoData: action.priceHistoryNoData,
      };

    case PRICE_HISTORY_CLEAR_DATA:
      return {
        ...state,
        idList: [],
        isFiltered: false,
        selectedPriceHistory: [],
        totalPriceHistory: null,
        singleProduct: null,
        showAllProducts: true,
        priceHistoryTableLoading: true,
        analyticsNoData: false,
      };

    case PRICE_HISTORY_LOADING:
      return {
        ...state,
        priceHistoryLoading: action.priceHistoryLoading,
      };
    case PRICE_HISTORY_SET_ID_LIST:
      return {
        ...state,
        idList: action.idList,
      };
    default:
      return state;
  }
}
