import React, { Component } from "react";
import { connect } from "react-redux";
import { FormContentWrapper } from "containers/Forms/FormContainer/FormContentWrapper";
import { closeFormpanel } from "ducks/formpanel";
// import { getPricePositionChart } from "ducks/charts";
import {
  getProductbyId,
  clearSingleProd,
  lockProductPrice,
} from "ducks/products";
import InputNumber from "components/InputNumber";
import Button from "components/Button";

import {
  getProductMatchingCompetitors,
  // updateProductCompetitor,
  updateCompetitiorList,
  updateCompetitiorVerify,
  updateCompetitiorUnVerify,
  updateCompetitiorReMatch,
  updateCompetitiorUnMatch,
  approveProductStatus,
  updateCompetitiorVerifyAll,
} from "ducks/productMatching";
import { getAllCompetitors } from "ducks/priceRule";
import {
  getLastExecutedCampaign,
  setCurrentlySelectedProduct,
  getProductData,
  filterProducts,
  updateSavePriceSuccess,
  getPricePositionChart,
} from "ducks/products";

import { SmallLoadingSpinner } from "components/SmallLoadingSpinner/SmallLoadingSpinner";

import { openModal, closeModal } from "ducks/modal";

import { LargeLoadingSpinner } from "components/LargeLoadingSpinner/LargeLoadingSpinner";

import BarChart from "components/Charts/BarChart";

import ProductMatchingGrid from "./ProductMatchingGrid";
import ProductMatchingList from "./ProductMatchingList";

import { SmallCircleButton } from "components/SmallCircleButton/SmallCircleButton";

import * as MODAL_TYPE from "utils/modal-types";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formWidthGrow: false,
      canSave: true,
      name: "",
      articleNumber: "",
      brand: "",
      url: "",
      imageUrl: "",
      category: "",
      campaign: "",
      priceRule: {},
      competitorPrices: {},
      filtered: [],
      noSearch: false,
      currentView: "details",
      searchText: "",
      showChannelSelection: false,
      channel: {},
      price: "",
      priceLocked: false,
      lastModifiedBy: "",
      matchingLayout: "grid",
      direction: null,
      saveButton: false,
      lockButton: false,
      unLockButton: false,
      updatedProductsToVerify: false,
      originalVerifiedProducts: [],
      productsToVerify: [],
      productsToUnVerify: [],
      productsToReMatch: [],
      productsToUnMatch: [],
      approve: false,
    };
  }

  componentDidMount() {
    this.props.toggleFormWidth();
    this.props.getProductbyId(this.props.data);
    this.props.getProductMatchingComp(this.props.data.mongoId);
    // if(this.props.data.id) {
    //   this.props.getPricePositionChart(this.props.data.id);
    // }
    this.props.getLastExecutedCampaign(this.props.data.id);
    this.setState({
      price: this.props.data.price.value ? this.props.data.price.value : "",
    });
  }

  componentWillUnmount() {
    this.props.clearSingleProd();
    this.props.setCurrentlySelectedProduct(null);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.products[0] !== this.props.products[0]) {
      if (this.props.products.length > 0) {
        if (this.state.direction === "next") {
          this.updateProductList(0);
        } else if (this.state.direction === "prev") {
          this.updateProductList(this.props.products.length - 1);
        }
      }
    }
    return true;
  }

  handleProducts = (product) => {
    const rowData = this.props.products.find(
      (product) => product.id === this.props.currentlySelectedProduct
    );
    if (product.actionType === "verifyAll") {
      let productList = this.props.productMatchingCompetitors.filter(
        (product) => !product.userState
      );

      if (productList.length > 0) {
        this.props.updateCompetitiorVerifyAll(productList, rowData);
      }
    } else if (product.actionType === "verify") {
      this.props.updateCompetitiorVerify(product, rowData);
    } else if (product.actionType === "unverify") {
      this.props.updateCompetitiorUnVerify(product, rowData);
    } else if (product.actionType === "rematch") {
      this.props.updateCompetitiorReMatch(product, rowData);
    } else if (product.actionType === "unmatch") {
      this.props.updateCompetitiorUnMatch(product, rowData);
    }
  };

  updateProductList = (index) => {
    this.setState({ updatedProductsToVerify: false }, () => {
      this.props.getAllCompetitors();
      this.props.setCurrentlySelectedProduct(this.props.products[index].id);
      this.props.getProductbyId(this.props.products[index]);
      this.props.getProductMatchingComp(this.props.products[index].mongoId);
      this.props.getPricePositionChart(this.props.products[index].id);
      this.props.getLastExecutedCampaign(this.props.products[index].id);
      this.setState({
        price: this.props.products[index].price.value
          ? this.props.products[index].price.value
          : "",
      });
    });
  };

  nextProduct = () => {
    this.setState({ direction: "next" });
    const foundProduct = this.props.products.find(
      (product) => product.id === this.props.currentlySelectedProduct
    );
    const productIndex = this.props.products.indexOf(foundProduct);

    if (productIndex === this.props.products.length - 1) {
      if (this.props.totalProductPages > 1) {
        if (this.props.isFiltered) {
          this.props.filterProducts(
            this.props.currentPageProduct + 1,
            this.props.rowSizeProduct,
            this.props.idList,
            this.props.productSortingAttr,
            this.props.productSortingDirection
          );
        } else {
          this.props.getProductData(
            this.props.currentPageProduct + 1,
            this.props.rowSizeProduct,
            this.props.productSortingAttr,
            this.props.productSortingDirection
          );
        }
      }
    } else {
      if (
        this.props.totalProductPages === 1 &&
        productIndex === this.props.products.length - 1
      ) {
        return;
      } else if (
        this.props.totalProductPages === this.props.currentPageProduct &&
        productIndex === this.props.products.length - 1
      ) {
        return;
      } else {
        this.updateProductList(productIndex + 1);
      }
    }
  };

  prevProduct = () => {
    this.setState({ direction: "prev" });
    const foundProduct = this.props.products.find(
      (product) => product.id === this.props.currentlySelectedProduct
    );
    const productIndex = this.props.products.indexOf(foundProduct);

    if (productIndex === 0) {
      if (this.props.currentPageProduct !== 1) {
        if (this.props.isFiltered) {
          this.props.filterProducts(
            this.props.currentPageProduct - 1,
            this.props.rowSizeProduct,
            this.props.idList,
            this.props.productSortingAttr,
            this.props.productSortingDirection
          );
        } else {
          this.props.getProductData(
            this.props.currentPageProduct - 1,
            this.props.rowSizeProduct,
            this.props.productSortingAttr,
            this.props.productSortingDirection
          );
        }
      }
    } else {
      this.updateProductList(productIndex - 1);
    }
  };
  //CLOSES THE FORM
  handleCancel = () => {
    this.props.closeFormpanel();
  };

  handleChange = (event) => {
    this.props.updateSavePriceSuccess(false);
    if (event.target.value < 0) {
      return;
    } else {
      this.setState({ price: event.target.value });
    }
  };

  handlePriceLocked = (lockValue, name) => {
    if (this.state.price > 0) {
      this.props.lockProductPrice(
        this.props.data.id,
        parseFloat(this.state.price),
        lockValue
      );
    }
  };

  toggleTooltips = (name, value) => {
    this.setState({ [name]: value });
  };

  setConfirmInput = (label, value) => {
    return (
      <>
        <label
          htmlFor="invalid"
          className="block text-xs font-light text-grey-dark font-sans py-1 capitalize"
        >
          {label}
        </label>
        <input
          autoComplete="off"
          type="text"
          name="invalid"
          value={value}
          className="bg-white border-solid border-grey-light border-1 outline-none text-grey-darker w-48 xxl:text-base text-sm font-sans xxl:h-10 xxl:px-3 p-2 input-placeholder"
          disabled={true}
        />
      </>
    );
  };

  handleSearch = (e) => {
    this.setState({ searchText: e.target.value });
  };

  selectChannel = () => {
    if (this.props.channelOptions > 1) {
      this.setState((prevState) => ({
        showChannelSelection: !prevState.showChannelSelection,
      }));
    } else {
      window.open(this.props.singleProduct.url, "_blank");
    }
  };

  render() {

    let competitorsList = [];
    (!this.props.pricePositionChartLoading &&
    this.props.pricePositionChart && Object.keys(this.props.pricePositionChart).length > 0) && this.props.pricePositionChart.dataSets.map((item) => {
      item.labels.forEach((label, i) => {
        if(label !== "Granditude Price") {
          
          competitorsList.push({ name : item.labels[i], price: item.data[i] })
        }
        }
      )
            })

    // let data = [];
    // (!this.props.pricePositionChartLoading &&
    //   !this.props.loadingNewMatch && 
    //   this.props.pricePositionChart) &&
    //   this.props.pricePositionChart.dataSets.map((item) => 
    //     item.data.map((obj) =>
    //       data = obj.data
    //     )
    //     );
    //   let competitorsObj = [];
    //   for (let i = 0; i < competitorsList.length; i++) {
    //     console.log('list',competitorsList)
    //     competitorsObj.push({
    //       name: competitorsList[i],
    //       price: competitorsList[i],
    //     });
    //   }
    //   console.log('obj',competitorsObj)
    let competitors = [];
    // competitors =
    //  ( !this.props.pricePositionChartLoading &&
    //   !this.props.loadingNewMatch) &&
    //   competitorsList.filter((comp) => {
    //     console.log('this',comp)
    //     if (comp.name !== "Granditude Price") {
    //       return comp
    //         .toString()
    //         .toLowerCase()
    //         .includes(this.state.searchText.toLowerCase());
    //     }
    //   });
    //   console.log(competitors)
    const foundProduct = this.props.products.find(
      (product) => product.id === this.props.currentlySelectedProduct
    );

    const productIndex = this.props.products.indexOf(foundProduct);
    let productMatchingData = { ...this.props.singleProduct };
    productMatchingData.id = foundProduct ? foundProduct.mongoId : "";
    productMatchingData.imgUrl = productMatchingData.imageUrl;

    let customFooter = (
      <div className="w-full h-full flex flex-row realtive pt-1 xxl:pb-3 pb-2">
        <div className="flex-1 pl-4">
          <div className="h-full w-full flex items-center justify-end text-sm">
            <div
              onClick={() => {
                if (this.props.currentPageProduct === 1 && productIndex === 0) {
                  return;
                } else {
                  this.prevProduct();
                }
              }}
              className={`h-full w-32 flex items-center justify-end text-sm ${
                this.props.currentPageProduct === 1 && productIndex === 0
                  ? "text-grey cursor-default"
                  : "text-teal-dark cursor-pointer"
              }`}
            >
              <div className="material-icons px-2">arrow_back</div>
              Prev Product
            </div>
          </div>
        </div>

        <div className="flex-1 pl-12">
          <div
            className={`h-full w-32 flex items-center text-sm ${
              this.props.currentPageProduct === this.props.totalProductPages &&
              this.props.products.length - 1 === productIndex
                ? "text-grey cursor-default"
                : "text-teal-dark cursor-pointer"
            }`}
            onClick={() => {
              if (
                this.props.currentPageProduct ===
                  this.props.totalProductPages &&
                this.props.products.length - 1 === productIndex
              ) {
                return;
              } else {
                this.nextProduct();
              }
            }}
          >
            Next Product
            <div className="material-icons px-2">arrow_forward</div>
          </div>
        </div>

        <div className="h-full flex items-center justify-center pr-4 w-32">
          {foundProduct && foundProduct.matchStatus === "Approved" && (
            <div className="pr-8 h-10 text-teal-dark flex items-center justify-center">
              <i className="material-icons-outlined px-2">
                check_circle_outline
              </i>
              Approved
            </div>
          )}
          {foundProduct && foundProduct.matchStatus === "Reapprove" && (
            <Button
              onClick={() => this.props.approveProductStatus(foundProduct)}
              label="Re-Approve"
            />
          )}
          {foundProduct ? (
            (foundProduct.matchStatus === "Not Approved" ||
              !foundProduct.matchStatus) && (
              <Button
                onClick={() => this.props.approveProductStatus(foundProduct)}
                label="Approve"
              />
            )
          ) : (
            <div className="pr-4 h-10 flex items-center justify-center">
              {SmallLoadingSpinner}
            </div>
          )}
        </div>
      </div>
    );

    return (
      <FormContentWrapper
        formConfig={{
          formTitle:
            this.state.currentView === "details"
              ? "Product Details"
              : "Product Matching",
        }}
        formName="demandOverview"
        handleSubmit={this.handleSubmit}
        handleCancel={this.handleCancel}
        canSave={this.state.canSave}
        hasCustomFooter={true}
        customFooter={customFooter}
      >
        {this.props.productLoading ? (
          <div className="flex items-center justify-center text-grey-darker font-light font-sans  xxl:min-h-25 xxl:max-h-25 min-h-15 max-h-15">
            {" "}
            {LargeLoadingSpinner}{" "}
          </div>
        ) : this.props.noProductData ? (
          <div className="flex items-center justify-center text-grey-darker font-light font-sans  xxl:min-h-25 xxl:max-h-25 min-h-15 max-h-15">
            No Data Available
          </div>
        ) : (
          <div className="h-full flex flex-col">
            <div className="flex flex-row items-center mt-3">
              <div className="ml-6 ">
                <div
                  onClick={() => this.setState({ currentView: "details" })}
                  className={`font-sans inline-block px-4 py-1 my-1 cursor-pointer xxl:text-sm text-xs rounded-l-full ${
                    this.state.currentView === "details"
                      ? "bg-teal-dark text-white hover:bg-teal hover:text-white"
                      : "bg-table-grey text-grey-darker hover:bg-teal hover:text-white"
                  }`}
                >
                  Product Details
                </div>
                <div
                  onClick={() => this.setState({ currentView: "matching" })}
                  className={`font-sans inline-block px-4 py-1 my-1 cursor-pointer xxl:text-sm text-xs rounded-r-full ${
                    this.state.currentView === "matching"
                      ? "bg-teal-dark text-white hover:bg-teal hover:text-white"
                      : "bg-table-grey text-grey-darker hover:bg-teal hover:text-white"
                  }`}
                >
                  Product Matching
                </div>
              </div>
              {this.state.currentView === "matching" && (
                <div className="ml-2">
                  <div
                    onClick={() => this.setState({ matchingLayout: "grid" })}
                    className={`mx-1 material-icons-outlined ${
                      this.state.matchingLayout === "grid"
                        ? "bg-teal-dark text-white"
                        : "bg-table-grey hover:bg-teal-dark text-grey-darker hover:text-white"
                    } p-2 xxl:text-lg text-md  rounded-full cursor-pointer `}
                  >
                    apps
                  </div>
                  <div
                    onClick={() => this.setState({ matchingLayout: "list" })}
                    className={`mx-1 material-icons-outlined ${
                      this.state.matchingLayout === "list"
                        ? "bg-teal-dark text-white"
                        : "bg-table-grey hover:bg-teal-dark text-grey-darker hover:text-white"
                    } p-2 xxl:text-lg text-md  rounded-full cursor-pointer `}
                  >
                    list
                  </div>
                </div>
              )}
            </div>
            {this.state.currentView === "details" && this.props.singleProduct && (
              <div className="flex-1 w-full flex flex-row flex-wrap items-center justify-center pb-10">
                <div className="h-8/10 xxl:w-2/5 xl::w-2/5 lg:w-1/2 w-2/3 flex flex-col ">
                  <div className="flex flex-row">
                    <div className="flex-1 h-full flex items-center xxl:text-sm text-xs text-teal-dark break-words pr-4">
                      {this.props.singleProduct.name}
                    </div>
                    <div className="w-6">
                    {(this.props.singleProduct.url.includes("https") || this.props.singleProduct.url.includes("http") ) && ( 

                      <a
                        href={this.props.singleProduct.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-teal-dark text-right font-light flex items-center justify-end w-full"
                      >
                        <span className="ml-4 inline-block">
                          <i className="material-icons-outlined w-8 h-8 xxl:w-10 xxl:h-10 flex items-center justify-center xxl:text-xl text-lg text-grey-darker rounded-full cursor-pointer bg-table-grey  hover:text-white hover:bg-teal-dark">
                            shopping_cart
                          </i>
                        </span>
                      </a>
                    )}
                  </div>
                          
              
                </div>
                <div className="w-full flex-1 flex flex-row">
                                    <div className="flex-1  flex flex-col py-6 text-grey-darker">
                                      <div className="flex-1 font-medium xxl:text-sm text-xs">
                                        <span className="font-light inline-block pr-4">
                                          Article No
                                        </span>
                                        {this.props.singleProduct.articleNumber}
                                      </div>
                                      <div className="flex-1 font-medium xxl:text-sm text-xs">
                                        <span className="font-light inline-block pr-4">
                                          Brand
                                        </span>
                                        {this.props.singleProduct.brand}
                                      </div>
                                      <div className="flex-1 font-medium xxl:text-sm text-xs">
                                        <span className="font-light inline-block pr-4">
                                          Category
                                        </span>
                                        {this.props.singleProduct.category}
                                      </div>
                                      <div className="flex-1 font-medium xxl:text-sm text-xs">
                                        <span className="font-light inline-block pr-4">
                                          RRP
                                        </span>
                                        {!this.props.pricePositionChartLoading && this.props.pricePositionChart !== null ? 
                                        this.props.pricePositionChart.rrp
                                        
                                          : "-"}
                                      </div>
                                      <div className="flex-1 font-medium xxl:text-sm text-xs">
                                        <span className="font-light inline-block pr-4">
                                          Purchase Price
                                        </span>
                                        {this.props.pricePositionChart
                                          ? this.props.pricePositionChart.purchasePrice
                                            ? this.props.pricePositionChart.purchasePrice
                                            : "-"
                                          : "-"}
                                      </div>
                                      <div className="flex-1 font-medium xxl:text-sm text-xs">
                                        <span className="font-light inline-block pr-4">
                                          Margin
                                        </span>
                                        {this.props.pricePositionChart
                                          ? this.props.pricePositionChart.margin
                                            ? this.props.pricePositionChart.margin
                                            : "-"
                                          : "-"}
                                      </div>
                                    </div>
                                    <div className="flex-1 flex xxl:justify-center items-center ">
                                      {this.props.singleProduct.imageUrl ? (
                                        <img
                                          className="w-auto xxl:max-h-15 max-h-10"
                                          src={this.props.singleProduct.imageUrl}
                                          alt="Own Product"
                                        />
                                      ) : (
                                        <div className="xxl:text-xs text-xxs text-grey font-light">
                                          No Image Available
                                        </div>
                                      )}
                                    </div>
                                  </div>

                <div className="w-full flex-1 flex flex-row">
                    <div className="flex-1  flex flex-col pt-6">
                      <div className="font-medium xxl:text-sm text-xs text-blue flex flex-row mb-2">
                        <div className="font-normal inline-block pr-4">
                          Current Price
                        </div>
                        <div className="flex-1 flex justify-end pr-4">
                          {this.props.pricePositionChart !== null
                            ? this.props.pricePositionChart.currentPrice
                            : "-"}
                        </div>
                      </div>
                      <div className="font-normal xxl:text-sm text-xs text-teal-dark opacity-50 flex flex-row">
                        <div className="inline-block pr-4">
                          Granditude's Price
                        </div>
                        <div className="flex-1 flex justify-end pr-4">
                          {this.props.pricePositionChart !== null
                            ? this.props.pricePositionChart.granditudePrice
                            : "-"}
                        </div>
                      </div>

                      <div className=" pt-6 flex flex-row">
                        <InputNumber
                          label={
                            this.props.singleProduct.locked
                              ? "Fixed Price - Locked"
                              : "Fixed Price - Unlocked"
                          }
                          width="w-32"
                          value={this.state.price}
                          onlyShowValue={this.props.singleProduct.locked}
                          onChange={(event) => this.handleChange(event)}
                        />
                        <div className="h-full flex items-end pl-2">
                          <div className="xxl:h-10 h-8 flex flex-row">
                            <SmallCircleButton
                              icon={
                                this.props.singleProduct.locked
                                  ? "lock_open"
                                  : "lock"
                              }
                              name={
                                this.props.singleProduct.locked
                                  ? "Unlock"
                                  : "Lock"
                              }
                              onClick={() =>
                                this.handlePriceLocked(
                                  this.props.singleProduct.locked
                                    ? false
                                    : true,
                                  "lock"
                                )
                              }
                              onMouseEnter={() => {
                                this.props.singleProduct.locked
                                  ? this.toggleTooltips("unLockButton", true)
                                  : this.toggleTooltips("lockButton", true);
                              }}
                              onMouseLeave={() => {
                                this.props.singleProduct.locked
                                  ? this.toggleTooltips("unLockButton", false)
                                  : this.toggleTooltips("lockButton", false);
                              }}
                              showtooltip={
                                this.props.singleProduct.locked
                                  ? this.state.unLockButton
                                  : this.state.lockButton
                              }
                            />
                            <div className="pl-1" />
                            <SmallCircleButton
                              success={this.props.savePriceSuccess}
                              icon={
                                this.props.savePriceSuccess
                                  ? "check_circle_outline"
                                  : "save"
                              }
                              name={
                                this.props.savePriceSuccess ? "Saved" : "Save"
                              }
                              onClick={() =>
                                !this.props.savePriceSuccess &&
                                this.handlePriceLocked(null, "save")
                              }
                              onMouseEnter={() =>
                                this.toggleTooltips("saveButton", true)
                              }
                              onMouseLeave={() =>
                                this.toggleTooltips("saveButton", false)
                              }
                              showtooltip={this.state.saveButton}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-xxs text-grey-dark pt-4 font-light">
                        Last Updated:{" "}
                        <span className="inline-block pl-1 font-normal">
                          {this.props.pricePositionChart !== null
                            ? this.props.pricePositionChart.priceLastModifiedBy
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`${
                        this.props.lastExecutedCampaign !== null &&
                        ((this.props.lastExecutedCampaign &&
                          this.props.lastExecutedCampaign.length > 0) ||
                          Object.keys(this.props.lastExecutedCampaign).length >
                            0)
                          ? "flex-1"
                          : ""
                      } flex flex-col py-6 pl-6 text-grey-darker`}
                    >
                      <div
                        className={`${
                          this.props.lastExecutedCampaign !== null &&
                          (this.props.lastExecutedCampaign.length > 0 ||
                            Object.keys(this.props.lastExecutedCampaign)
                              .length > 0)
                            ? "flex-1"
                            : ""
                        }  text-teal-dark xxl:text-sm text-xs`}
                      >
                        Last Executed Campaign
                      </div>
                      {this.props.lastExecutedCampaign !== null &&
                      (this.props.lastExecutedCampaign.length > 0 ||
                        Object.keys(this.props.lastExecutedCampaign).length >
                          0) ? (
                        <>
                          <div className="flex-1 font-medium xxl:text-sm text-xs">
                            <span className="font-light inline-block pr-4">
                              Campaign
                            </span>
                            {this.props.lastExecutedCampaign.campaignName}
                          </div>
                          <div className="flex-1 font-medium xxl:text-sm text-xs">
                            <span className="font-light inline-block pr-4">
                              Price Rule
                            </span>
                            {this.props.lastExecutedCampaign.priceRuleName}
                          </div>
                          <div className="flex-1 font-medium xxl:text-sm text-xs">
                            <span className="font-light inline-block pr-4">
                              Target Price
                            </span>
                            {this.props.lastExecutedCampaign.targetPrice}
                          </div>
                          <div className="flex-1 font-medium xxl:text-sm text-xs">
                            <span className="font-light inline-block pr-4">
                              Target Competitor
                            </span>
                            {this.props.lastExecutedCampaign.targetCompetitor}
                          </div>
                        </>
                      ) : (
                        <div className="flex font-medium xxl:text-sm text-xs mt-6 ">
                          <span className="font-light inline-block pr-4">
                            No campaigns have been executed recently
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                
                <div className="xxl:border-l-1 xl:border-l-1 lg:border-l-1 xxl:w-1 xl:w-1 lg:w-1 h-full border-table-grey xxl:mx-8 xl:mx-8 lg:mx-8" />
              </div>
                <div className="h-8/10 w-2/5 flex flex-col">
                  <div className="h-2/5 ">
                    <div className="flex flex-col ">
                      <div className="text-teal-dark mb-3 flex flex-row items-center h-10 xxl:text-sm text-xs">
                        Competitors
                        {!this.props.pricePositionChartLoading &&
                          competitors.length > 8 && (
                            <div className="border-grey-light border-1 rounded-full flex-1 ml-10 pl-3 flex flex-row items-center py-1">
                              <input
                                autoComplete="off"
                                type="text"
                                value={this.state.searchText}
                                placeholder="Search Competitor"
                                onChange={(e) => this.handleSearch(e)}
                                className="input-placeholder outline-none text-teal-dark focus:text-teal flex-1 font-light h-full xxl:text-sm text-xs"
                              />
                              <i className="material-icons px-2 xxl:text-xl text-lg text-grey-darker">
                                search
                              </i>
                            </div>
                          )}
                      </div>

                      <div className="flex flex-col overflow-y-scroll h-full text-sm  max-h-6 min-h-6">
                        {this.props.pricePositionChartLoading ? (
                          <div className="w-full h-full flex items-center justify-center">
                            {LargeLoadingSpinner}
                          </div>
                        ) : this.state.noSearch ? (
                          competitorsList.map((item, i) => {
                            return (
                              <a
                              // key={i}
                              // href={item.product.url}
                              // target="_blank"
                              // rel="noopener noreferrer"
                              // className="flex-1 font-medium xxl:text-sm text-xs cursor-pointer"
                              >
                                <span className="font-light inline-block pr-4">
                                  {item.name}
                                </span>
                                {item.price}
                              </a>
                            );
                          })
                        ) : !this.props.pricePositionChartLoading &&
                        competitorsList.length > 0 ? (
                          competitorsList.map((comp, i) => {
                            return (
                              <a
                              // key={i}
                              // href={comp.product.url}
                              // target="_blank"
                              // rel="noopener noreferrer"
                              // className="flex-1 font-medium xxl:text-sm text-xs cursor-pointer"
                              >
                                <span className="font-light inline-block pr-4">
                                  {comp.name}
                                </span>
                                {comp.price}
                              </a>
                            );
                          })
                        ) : (
                          <div className="w-full h-full flex justify-center items-center font-light text-grey xxl:text-lg text-md">
                            No Competitors
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-1">
                    {this.props.pricePositionChartLoading ? (
                      <div className="w-full h-full flex items-center justify-center">
                        {LargeLoadingSpinner}
                      </div>
                    ) : (
                      <div className="h-20">
                        <BarChart
                          data={this.props.pricePositionChart}
                          title="Price Position"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

            )}
            {this.state.currentView === "matching" ? (
              this.state.matchingLayout === "grid" ? (
                <ProductMatchingGrid
                  singleProduct={productMatchingData}
                  rowData={foundProduct}
                  handleProducts={this.handleProducts}
                  productsToVerify={this.state.productsToVerify}
                  productsToUnMatch={this.state.productsToUnMatch}
                />
              ) : (
                <ProductMatchingList
                  singleProduct={productMatchingData}
                  rowData={foundProduct}
                  handleProducts={this.handleProducts}
                  productsToVerify={this.state.productsToVerify}
                  productsToUnMatch={this.state.productsToUnMatch}
                />
              )
            ) : null}
          </div>
        )}
      </FormContentWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeFormpanel: () => dispatch(closeFormpanel()),
    getProductbyId: (id) => dispatch(getProductbyId(id)),
    clearSingleProd: () => dispatch(clearSingleProd()),
    getPricePositionChart: (id) => dispatch(getPricePositionChart(id)),
    getProductMatchingComp: (id) => dispatch(getProductMatchingCompetitors(id)),
    getAllCompetitors: () => dispatch(getAllCompetitors()),
    lockProductPrice: (id, price, lockValue) =>
      dispatch(lockProductPrice(id, price, lockValue)),
    getLastExecutedCampaign: (id) => dispatch(getLastExecutedCampaign(id)),
    setCurrentlySelectedProduct: (id) =>
      dispatch(setCurrentlySelectedProduct(id)),
    getProductData: (page, rowSize, attr, direction) =>
      dispatch(getProductData(page, rowSize, attr, direction)),
    filterProducts: (page, rowSize, idList, attr, sorting) =>
      dispatch(filterProducts(page, rowSize, idList, attr, sorting)),
    updateSavePriceSuccess: (value) => dispatch(updateSavePriceSuccess(value)),
    // updateProductCompetitor: (product, row) =>
    //   dispatch(updateProductCompetitor(product, row)),
    updateCompetitiorList: (product) =>
      dispatch(updateCompetitiorList(product)),
    openModal: (targetUnitData, modalType, modalSize) =>
      dispatch(openModal(targetUnitData, modalType, modalSize)),
    closeModal: () => dispatch(closeModal()),
    updateCompetitiorVerify: (product, row) =>
      dispatch(updateCompetitiorVerify(product, row)),
    updateCompetitiorUnVerify: (product, row) =>
      dispatch(updateCompetitiorUnVerify(product, row)),
    updateCompetitiorReMatch: (product, row) =>
      dispatch(updateCompetitiorReMatch(product, row)),
    updateCompetitiorUnMatch: (product, row) =>
      dispatch(updateCompetitiorUnMatch(product, row)),
    approveProductStatus: (product) => dispatch(approveProductStatus(product)),
    updateCompetitiorVerifyAll: (products, row) =>
      dispatch(updateCompetitiorVerifyAll(products, row)),
  };
};

const mapStateToProps = (state) => {
  return {
    formState: state.formpanel.formState,
    singleProduct: state.products.singleProduct,
    productLoading: state.products.productLoading,
    noProductData: state.products.noProductData,
    pricePositionChart: state.products.pricePositionChart,
    pricePositionChartLoading: state.products.pricePositionChartLoading,
    productMatchingCompetitors:
      state.productMatching.productMatchingCompetitors,
    channelOptions: state.campaigns.channels,
    lastExecutedCampaign: state.products.lastExecutedCampaign,
    products: state.products.products,
    currentlySelectedProduct: state.products.currentlySelectedProduct,
    currentPageProduct: state.tablePagination.productPage,
    rowSizeProduct: state.tablePagination.productRowSize,
    productSortingAttr: state.tablePagination.productSortingAttr,
    productSortingDirection: state.tablePagination.productSortingDirection,
    totalProductPages: state.tablePagination.productTotalPages,
    idList: state.products.idList,
    isFiltered: state.products.isFiltered,
    savePriceSuccess: state.products.savePriceSuccess,
    loadingNewMatch: state.productMatching.loadingNewMatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
