import React from "react";
export default () => (
  <svg xmlns="http://www.w3.org/2000/svg"    width="100%"
  height="100%"
  viewBox="0 0 45 45"
  fill="none">
  <defs>
    <clipPath id="clip-path">
      <path id="Path_9" data-name="Path 9" d="M22.5,0A22.5,22.5,0,1,1,0,22.5,22.5,22.5,0,0,1,22.5,0Z" transform="translate(306 420)" fill="#012169"/>
    </clipPath>
  </defs>
  <g id="UK_Circle" data-name="UK Circle" transform="translate(-306 -420)" clip-path="url(#clip-path)">
    <path id="Path_6" data-name="Path 6" d="M22.5,0A22.5,22.5,0,1,1,0,22.5,22.5,22.5,0,0,1,22.5,0Z" transform="translate(306 420)" fill="#012169"/>
    <path id="Path_8" data-name="Path 8" d="M22.5,0A22.5,22.5,0,1,1,0,22.5,22.5,22.5,0,0,1,22.5,0Z" transform="translate(306 420)" fill="#012169"/>
    <path id="Path_7" data-name="Path 7" d="M0,27" transform="translate(307.5 427.5)" fill="none" stroke="#707070" stroke-width="1"/>
    <g id="Group_2" data-name="Group 2" transform="translate(-37.583 41.621) rotate(-7)">
      <rect id="Rectangle_24" data-name="Rectangle 24" width="8" height="76" transform="matrix(0.454, 0.891, -0.891, 0.454, 346.497, 421.678)" fill="#fff"/>
      <rect id="Rectangle_26" data-name="Rectangle 26" width="27" height="2" transform="matrix(-0.891, 0.454, -0.454, -0.891, 315.21, 444.708)" fill="#c8102e"/>
      <rect id="Rectangle_27" data-name="Rectangle 27" width="27" height="2" transform="matrix(-0.891, 0.454, -0.454, -0.891, 338.765, 429.708)" fill="#c8102e"/>
    </g>
    <g id="Group_3" data-name="Group 3" transform="translate(70.218 -35.055) rotate(7)">
      <rect id="Rectangle_25" data-name="Rectangle 25" width="7" height="76" transform="matrix(0.454, -0.891, 0.891, 0.454, 279.234, 427.915)" fill="#fff"/>
      <rect id="Rectangle_28" data-name="Rectangle 28" width="27" height="2" transform="matrix(-0.891, -0.454, 0.454, -0.891, 339.857, 454.965)" fill="#c8102e"/>
      <rect id="Rectangle_29" data-name="Rectangle 29" width="27" height="2" transform="matrix(-0.891, -0.454, 0.454, -0.891, 312.311, 442.879)" fill="#c8102e"/>
    </g>
    <rect id="Rectangle_30" data-name="Rectangle 30" width="11" height="57" transform="translate(323 415)" fill="#fff"/>
    <rect id="Rectangle_31" data-name="Rectangle 31" width="11" height="57" transform="translate(356 437) rotate(90)" fill="#fff"/>
    <rect id="Rectangle_32" data-name="Rectangle 32" width="5" height="62" transform="translate(326 411)" fill="#c8102e"/>
    <rect id="Rectangle_33" data-name="Rectangle 33" width="5" height="62" transform="translate(359.5 440) rotate(90)" fill="#c8102e"/>
  </g>
</svg>
);
