import React from "react";

function QueryInput(props) {
  return (
    <div>
      <span
        className={`bg-${
          props.colorList[props.item.id]
        } rounded-lg text-white self-start ml-2 py-1 h-8 px-4 flex flex-row items-center whitespace-no-wrap`}
      >
        {props.keyVal} :
        {props.query.length > 0 &&
          props.query.map((query, i) => (
            <span key={i} className="pl-2">
              {query}
            </span>
          ))}
        <span
          className={`bg-${
            props.colorList[props.item.id]
          }er rounded-lg flex flex-col h-6 ml-2 w-12 `}
        ></span>
      </span>
    </div>
  );
}

export default QueryInput;
