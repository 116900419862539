import React from "react";
import "./LargeLoadingSpinner.css";

export const LargeLoadingSpinner = (
  <div className="large-spinner loading"></div>
);

export const SmallLoadingSpinner = (
  <div className="small-spinner loading"></div>
);
