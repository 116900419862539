import axios from "axios";
import { setProductPagination } from "./tablePagination";
import { handleError } from "./general";
import store from "./";

const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
const PRODUCT_SET_ALL_BRANDS = "PRODUCT_SET_ALL_BRANDS";
const PRODUCT_SET_ALL_CAT = "PRODUCT_SET_ALL_CAT";
const PRODUCT_IS_FILTERED = "PRODUCT_IS_FILTERED";
const PRODUCT_SET_ID_LIST = "PRODUCT_SET_ID_LIST";
const SET_PRODUCT_BY_ID = " SET_PRODUCT_BY_ID";
const SET_PRODUCTS_TO_GROUP = "SET_PRODUCTS_TO_GROUP";
const PRODUCT_TOGGLE_SHOW_ALL_DATA = "PRODUCT_TOGGLE_SHOW_ALL_DATA";
const PRODUCT_TABLE_LOADING = "PRODUCT_TABLE_LOADING";
const CLEAR_ALL_PRODUCTS = "CLEAR_ALL_PRODUCTS";
const CLEAR_SINGLE_PRODUCT = "CLEAR_SINGLE_PRODUCT";
const PRODUCT_SET_NO_DATA = "PRODUCT_SET_NO_DATA";
const PRODUCT_UPDATE_TAG_LIST = "PRODUCT_UPDATE_TAG_LIST";
const PRODUCT_REMOVE_FROM_TAG_LIST = "PRODUCT_REMOVE_FROM_TAG_LIST";
const PRODUCT_CLEAR_DATA = "PRODUCT_CLEAR_DATA";
const PRODUCT_SET_NO_PRODUCT_DATA = "PRODUCT_SET_NO_PRODUCT_DATA";
const PRODUCT_LOADING = "PRODUCT_LOADING";
const PRICE_POSITION_CHART = "PRICE_POSITION_CHART";
const SET_PRODUCTS_BY_PRODUCT_IDS = "SET_PRODUCTS_BY_PRODUCT_IDS";
const CLEAR_ALL_PROD_IBY_PRODUCT_IDS = "CLEAR_ALL_PROD_BY_PRODUCT_IDS";
const PROD_BY_PRODUCT_IDS_LOADING = "PROD_BY_PRODUCT_IDS_LOADING";
const SET_NO_PRODUCT_BY_PRODUCT_IDS_DATA = "SET_NO_PRODUCT_BY_PRODUCT_IDS_DATA";
const PRICE_POSITION_CHART_LOADING = "PRICE_POSITION_CHART_LOADING";
const PRODUCT_UPDATE_SINGLE_PRODUCT_LOCKED =
  "PRODUCT_UPDATE_SINGLE_PRODUCT_LOCKED";
const PRODUCT_FILTER_LOCK_STATUS_UPDATE = "PRODUCT_FILTER_LOCK_STATUS_UPDATE";
const PRODUCT_HANDLE_TABLE_INPUT = "PRODUCT_HANDLE_TABLE_INPUT";
const PRODUCT_SET_INPUTS_ONBLUR = "PRODUCT_SET_INPUTS_ONBLUR";
const PRODUCT_TABLE_INPUT_SINGLE_SUBMIT = "PRODUCT_TABLE_INPUT_SINGLE_SUBMIT";
const PRODUCT_SET_LAST_EXECUTED_CAMPAIGN = "PRODUCT_SET_LAST_EXECUTED_CAMPAIGN";
const PRODUCT_SET_CURRENTLY_SELECTED_PRODUCT =
  "PRODUCT_SET_CURRENTLY_SELECTED_PRODUCT";
const PRODUCT_UPDATE_SINGLE_PRODUCT_TABLE =
  "PRODUCT_UPDATE_SINGLE_PRODUCT_TABLE";
const PRODUCT_UPDATE_SAVE_PRICE_SUCCESS = "PRODUCT_UPDATE_SAVE_PRICE_SUCCESS";
const CLEAR_CHART_DATA = "CLEAR_CHART_DATA"

export const getProductData = (page, rowSize, attr, sorting) => {
  return (dispatch) => {
    dispatch(clearData());
    dispatch(tableLoading(true));
    dispatch(setNoData(false));
    dispatch(clearAllProducts());
    let attribute = attr;
    if (attribute === "Margin(%)") {
      attribute = "margin";
    }
    if (attribute === "name") {
      attribute = "productName";
    }
    if (localStorage.getItem("channel")) {
      let url =
        attr && sorting
          ? process.env.REACT_APP_API_HOST +
            "/product/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/products?page=" +
            page +
            "&size=" +
            rowSize +
            "&sort=" +
            attribute +
            "," +
            sorting
          : process.env.REACT_APP_API_HOST +
            "/product/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/products?page=" +
            page +
            "&size=" +
            rowSize;

      return axios
        .get(url)
        .then((res) => {
          dispatch(setFiltered(false));
          dispatch(setAllProducts(res.data));
          dispatch(showAllProducts(true));
          dispatch(
            setProductPagination(
              page,
              rowSize,
              res.data.totalPages,
              attribute,
              sorting
            )
          );
          dispatch(tableLoading(false));
          if (res.data.content.length < 1) {
            dispatch(setNoData(true));
            dispatch(tableLoading(false));
          }
        })
        .catch((err) => {
          console.log(err);

          dispatch(tableLoading(false));
          dispatch(setNoData(true));
        });
    }
  };
};

export const getProductbyId = (row) => {
  return (dispatch) => {
    dispatch(productLoading(true));
    dispatch(setNoProductData(false));
    return axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/product/markets/" +
          localStorage.getItem("market") +
          "/products/" +
          row.id
      )
      .then((res) => {
        dispatch(getPricePositionChart(row));
        dispatch(setProductbyId(res.data));
        dispatch(productLoading(false));

        if (res.data.length < 1) {
          dispatch(setNoProductData(true));
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getPricePositionChart = (row) => {
  return (dispatch) => {
    dispatch(clearChartData())
    dispatch(setPricePositionChartLoading(true));
    return axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/products/" +
          row.id +
          "/graph/price-position"
      )
      .then((res) => {
        dispatch(setPricePositionChart(res.data));
        dispatch(setPricePositionChartLoading(false));
      })
      .catch((err) => {
        dispatch(setPricePositionChartLoading(false));
      });
  };
};

export const filterProducts = (page, rowSize, idList, attr, sorting) => {
  return (dispatch) => {
    dispatch(clearData());

    let attribute = attr;
    if (attribute === "Margin(%)") {
      attribute = "margin";
    }
    if (attribute === "name") {
      attribute = "productName";
    }
    dispatch(tableLoading(true));
    dispatch(setNoData(false));
    let url =
      attr && sorting
        ? process.env.REACT_APP_API_HOST +
          "/product/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/products?page=" +
          page +
          "&size=" +
          rowSize +
          "&sort=" +
          attribute +
          "," +
          sorting +
          idList
        : process.env.REACT_APP_API_HOST +
          "/product/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/products?page=" +
          page +
          "&size=" +
          rowSize +
          idList;
    return axios
      .get(url)
      .then((res) => {
        dispatch(setFiltered(true));
        dispatch(setIdList(idList));
        dispatch(setAllProducts(res.data));
        dispatch(
          setProductPagination(
            page,
            rowSize,
            res.data.totalPages,
            attribute,
            sorting
          )
        );
        dispatch(tableLoading(false));
        if (res.data.content.length < 1) {
          dispatch(setNoData(true));
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
        dispatch(tableLoading(false));
        dispatch(setNoData(true));
      });
  };
};
export const filterBySkuProducts = (page, rowSize, idList, attr, sorting) => {
  return (dispatch) => {
    dispatch(clearData());

    let attribute = attr;
    if (attribute === "Margin(%)") {
      attribute = "margin";
    }
    if (attribute === "name") {
      attribute = "productName";
    }
    dispatch(tableLoading(true));
    dispatch(setNoData(false));
    let url =
      attr && sorting
        ? process.env.REACT_APP_API_HOST +
          "/product/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/products?page=" +
          page +
          "&size=" +
          rowSize +
          "&sort=" +
          attribute +
          "," +
          sorting +
          idList
        : process.env.REACT_APP_API_HOST +
          "/product/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/products?page=" +
          page +
          "&size=" +
          rowSize +
          idList;
    return axios
      .get(url)
      .then((res) => {
        dispatch(setFiltered(true));
        dispatch(setIdList(idList));
        dispatch(setAllProducts(res.data));
        dispatch(
          setProductPagination(
            page,
            rowSize,
            res.data.totalPages,
            attribute,
            sorting
          )
        );
        dispatch(tableLoading(false));
        if (res.data.content.length < 1) {
          dispatch(setNoData(true));
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
        dispatch(tableLoading(false));
        dispatch(setNoData(true));
      });
  };
};

export const getBrandsByMarket = () => {
  return (dispatch) => {
    return axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/product/brands" +
          "/markets/" +
          localStorage.getItem("market")
      )
      .then((res) => {
        dispatch(setAllBrands(res.data));
      })
      .catch((err) => {
        console.log(err);
        // dispatch(handleError(err));
      });
  };
};

export const getCategoriesByMarket = () => {
  return (dispatch) => {
    if (localStorage.getItem("market")) {
      return axios
        .get(
          process.env.REACT_APP_API_HOST +
            "/product/categories" +
            "/markets/" +
            localStorage.getItem("market")
        )
        .then((res) => {
          dispatch(setAllCategories(res.data));
        })
        .catch((err) => {
          console.log(err);
          // dispatch(handleError(err));
        });
    }
  };
};

export const getProductsByGroup = (id, page, rowSize, attr, sorting) => {
  if (localStorage.getItem("market") && localStorage.getItem("channel")) {
    return (dispatch) => {
      dispatch(clearData());

      let attribute = attr;
      if (attribute === "Margin(%)") {
        attribute = "margin";
      }
      if (attribute === "name") {
        attribute = "productName";
      }
      dispatch(tableLoading(true));
      dispatch(clearAllProducts());
      dispatch(setNoData(false));

      return axios
        .get(
          process.env.REACT_APP_API_HOST +
            "/product/groups/" +
            id +
            "/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/products/pagination/?page=" +
            page +
            "&size=" +
            rowSize
        )
        .then((res) => {
          dispatch(setAllProducts(res.data));
          dispatch(showAllProducts(false));
          dispatch(
            setProductPagination(
              page,
              rowSize,
              res.data.totalPages,
              attribute,
              sorting
            )
          );
          dispatch(tableLoading(false));
          if (res.data.content.length < 1) {
            dispatch(setNoData(true));
          }
        })
        .catch((err) => {
          dispatch(handleError(err));
          dispatch(tableLoading(false));
          dispatch(setNoData(true));
        });
    };
  }
};

export const getProductsByProductIds = (id, value) => {
  return (dispatch) => {
    dispatch(productsByproductIdsLoading(true));
    dispatch(setNoProductsByProductIdsData(false));
    return axios
      .post(
        process.env.REACT_APP_API_HOST +
          "/product/markets/" +
          localStorage.getItem("market") +
          "/products",
        id
      )
      .then((res) => {
        dispatch(setProductsByproductIds(res.data));

        dispatch(productsByproductIdsLoading(false));

        if (res.data.length < 1) {
          dispatch(setNoProductsByProductIdsData(true));
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const lockProductPrice = (id, price, lock) => {
  let chartData = {
    id: id,
  };
  return (dispatch) => {
    if (lock) {
      return axios
        .put(
          process.env.REACT_APP_API_HOST +
            "/product/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/products/" +
            id,
          { lock, price }
        )
        .then((res) => {
          dispatch(updateSingleProductLocked(id, true));
        })
        .catch((err) => dispatch(handleError(err)));
    } else {
      let lockData;
      if (lock !== null) {
        lockData = {
          lock,
        };
      } else {
        lockData = {
          lock,
          price,
        };
      }
      return axios
        .put(
          process.env.REACT_APP_API_HOST +
            "/product/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/products/" +
            id,
          lockData
        )
        .then((res) => {
          if (lock !== null) {
            dispatch(updateSingleProductLocked(id, false));
          }
          if (lock === null) {
            dispatch(updateSavePriceSuccess(true));
            dispatch(getPricePositionChart(chartData));
            dispatch(updateSingleProductTable(id, "price", res.data.price));
            if (res.data.margin) {
              dispatch(updateSingleProductTable(id, "margin", res.data.margin));
            }
          }
        })
        .catch((err) => dispatch(handleError(err)));
    }
  };
};

export const submitTableInput = (id, col, value) => {
  return (dispatch) => {
    if (value === "") {
      value = null;
    }
    return axios
      .put(
        process.env.REACT_APP_API_HOST +
          "/product/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/products?attribute=" +
          col +
          "&value=" +
          value +
          "&productIds=" +
          id
      )
      .then((res) => {
        dispatch(
          tableInputOnSingleSubmit(id, col, {
            status: "success",
            data: res.data,
          })
        );
      })
      .catch((err) => {
        dispatch(
          tableInputOnSingleSubmit(id, col, {
            status: "error",
            data: null,
          })
        );
      });
  };
};

export const getLastExecutedCampaign = (id) => {
  return (dispatch) => {
    return axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/product/markets/" +
          localStorage.getItem("market") +
          "/products/" +
          id +
          "/last-executed-campaign"
      )
      .then((res) => {
        dispatch(setLastExecutedCampaign(res.data));
      })
      .catch((err) => console.log(err));
  };
};

export const tableInputOnSingleSubmit = (id, col, res) => {
  return {
    type: PRODUCT_TABLE_INPUT_SINGLE_SUBMIT,
    id,
    col,
    res,
  };
};

export const handleProductsInputChange = (value, id, col) => {
  return {
    type: PRODUCT_HANDLE_TABLE_INPUT,
    value,
    id,
    col,
  };
};

export const updateProductTableInputsOnBlur = (id, col, value) => {
  return {
    type: PRODUCT_SET_INPUTS_ONBLUR,
    id,
    col,
    value,
  };
};

const setNoProductsByProductIdsData = (value) => {
  return {
    type: SET_NO_PRODUCT_BY_PRODUCT_IDS_DATA,
    setNoProductsByProductIdsData: value,
  };
};

const productsByproductIdsLoading = (value) => {
  return {
    type: PROD_BY_PRODUCT_IDS_LOADING,
    productsByproductIdsLoading: value,
  };
};

export const clearProductByproductIds = () => {
  return {
    type: CLEAR_ALL_PROD_IBY_PRODUCT_IDS,
  };
};

const setProductsByproductIds = (data) => {
  return {
    type: SET_PRODUCTS_BY_PRODUCT_IDS,
    setProductsByproductIds: data,
  };
};

const setProductbyId = (data) => {
  return {
    type: SET_PRODUCT_BY_ID,
    singleProduct: data,
  };
};

const showAllProducts = (value) => {
  return {
    type: PRODUCT_TOGGLE_SHOW_ALL_DATA,
    showAllProducts: value,
  };
};

const setAllProducts = (products) => {
  return {
    type: SET_ALL_PRODUCTS,
    products: products.content,
    totalProducts: products.totalElements,
  };
};

const clearAllProducts = () => {
  return {
    type: CLEAR_ALL_PRODUCTS,
  };
};

const setAllBrands = (brands) => {
  return {
    type: PRODUCT_SET_ALL_BRANDS,
    brands: brands,
  };
};

const setAllCategories = (cat) => {
  return {
    type: PRODUCT_SET_ALL_CAT,
    categories: cat,
  };
};

export const setFiltered = (val) => {
  return {
    type: PRODUCT_IS_FILTERED,
    isFiltered: val,
  };
};

const setIdList = (ids) => {
  return {
    type: PRODUCT_SET_ID_LIST,
    idList: ids,
  };
};

const tableLoading = (value) => {
  return {
    type: PRODUCT_TABLE_LOADING,
    productsTableLoading: value,
  };
};
const productLoading = (value) => {
  return {
    type: PRODUCT_LOADING,
    productLoading: value,
  };
};

export const clearSingleProd = () => {
  return {
    type: CLEAR_SINGLE_PRODUCT,
  };
};
export const setNoData = (value) => {
  return {
    type: PRODUCT_SET_NO_DATA,
    productsNoData: value,
  };
};
export const setNoProductData = (value) => {
  return {
    type: PRODUCT_SET_NO_PRODUCT_DATA,
    noProductData: value,
  };
};

export const updateProductTagList = (tag, selectedProducts) => {
  return {
    type: PRODUCT_UPDATE_TAG_LIST,
    tag: tag,
    selectedProducts: selectedProducts,
  };
};

export const removeFromProductTagList = (tag, selectedProducts) => {
  return {
    type: PRODUCT_REMOVE_FROM_TAG_LIST,
    tag: tag,
    selectedProducts: selectedProducts,
  };
};

export const clearData = () => {
  return {
    type: PRODUCT_CLEAR_DATA,
  };
};

const clearChartData = () => {
  return {
    type: CLEAR_CHART_DATA
  }
}

const setPricePositionChart = (data) => {
  let productMatchingCompetitors = store.getState().productMatching
  .productMatchingCompetitors.length > 0 && store.getState().productMatching
    .productMatchingCompetitors;
    let dataCopy = [...data.bars];
    let removeInactiveData = dataCopy.filter((chart) => {
      if (productMatchingCompetitors && 
        productMatchingCompetitors.some((comp) =>
        comp.domainName.includes(chart.name)
        ) ||
        chart.name === "Granditude Price"
        ) {
          return chart;
        }else return []
      });

  let newStructure = {
    bars: removeInactiveData,
    currentPrice: data.currentPrice,
    granditudePrice: data.granditudePrice,
    margin: data.margin,
    priceLastModifiedBy: data.priceLastModifiedBy,
    purchasePrice: data.purchasePrice,
    rrp: data.rrp,
    rrpOffset: data.rrpOffset,
  };

  return {
    type: PRICE_POSITION_CHART,
    pricePositionChart: newStructure,
  };
};

const setPricePositionChartLoading = (value) => {
  return {
    type: PRICE_POSITION_CHART_LOADING,
    pricePositionChartLoading: value,
  };
};

const updateSingleProductLocked = (id, lockedValue) => {
  return {
    type: PRODUCT_UPDATE_SINGLE_PRODUCT_LOCKED,
    id,
    lockedValue,
  };
};

export const updateSingleProductTable = (id, col, value) => {
  return {
    type: PRODUCT_UPDATE_SINGLE_PRODUCT_TABLE,
    id,
    col,
    value,
  };
};

export const updateProductFilterLockStatus = (productFilterLockStatus) => {
  return {
    type: PRODUCT_FILTER_LOCK_STATUS_UPDATE,
    productFilterLockStatus,
  };
};

const setLastExecutedCampaign = (lastExecutedCampaign) => {
  return {
    type: PRODUCT_SET_LAST_EXECUTED_CAMPAIGN,
    lastExecutedCampaign,
  };
};

export const setCurrentlySelectedProduct = (currentlySelectedProduct) => {
  return {
    type: PRODUCT_SET_CURRENTLY_SELECTED_PRODUCT,
    currentlySelectedProduct,
  };
};

export const updateSavePriceSuccess = (value) => {
  return {
    type: PRODUCT_UPDATE_SAVE_PRICE_SUCCESS,
    value,
  };
};

const initialState = {
  currentlySelectedProduct: null,
  idList: [],
  isFiltered: false,
  categories: [],
  brands: [],
  products: [],
  tag: [],
  selectedProducts: [],
  totalProducts: null,
  singleProduct: null,
  productsByGroup: [],
  showAllProducts: true,
  productsTableLoading: false,
  productsNoData: false,
  productLoading: false,
  noProductData: false,
  pricePositionChart: null,
  pricePositionChartLoading: true,
  setNoProductsByProductIdsData: false,
  savePriceSuccess: false,
  productsByproductIdsLoading: false,
  setProductsByproductIds: [],
  productFilterLockStatus: "all",
  productFilterApprovedStatus: "all",
  lastExecutedCampaign: null,
};

export default function reducer(state = initialState, action = {}) {
  let products = [...state.products];
  switch (action.type) {
    case PRODUCT_UPDATE_SINGLE_PRODUCT_TABLE:
      products.map((product) => {
        if (product.id === action.id) {
          if (action.col === "price" || action.col === "margin") {
            product[action.col].value = action.value;
          } else {
            product[action.col] = action.value;
          }
        }
      });
      return {
        ...state,
        products,
      };
    case PRODUCT_SET_CURRENTLY_SELECTED_PRODUCT:
      return {
        ...state,
        currentlySelectedProduct: action.currentlySelectedProduct,
      };
    case PRODUCT_HANDLE_TABLE_INPUT:
      products.map((product) => {
        if (action.id === product.id) {
          if (action.col === "margin" && action.value >= 100) {
            return;
          } else {
            product[action.col].value = action.value;
            product[action.col].isChanged = true;
            product[action.col].error = false;
          }
        }
      });
      return {
        ...state,
        products,
      };
    case SET_ALL_PRODUCTS:
      let productsRes = [...action.products];
      productsRes.map((product) => {
        if (product.purchasePrice) {
          product.margin = {
            value: product.margin ? product.margin : "",
            isInput: true,
            isSaved: false,
            isBlurred: false,
            isChanged: false,
            error: false,
            concatString: "(%)",
          };
        } else {
          product.margin = {
            value: product.margin ? product.margin : "",
            concatString: "(%)",
          };
        }
        product.price = {
          value: product.price ? product.price : "",
          isInput: true,
          isSaved: false,
          isBlurred: false,
          isChanged: false,
          error: false,
        };
      });
      return {
        ...state,
        products: productsRes,
        totalProducts: action.totalProducts,
        totalPages: action.totalPages,
      };
    case PRODUCT_SET_INPUTS_ONBLUR:
      products.map((product) => {
        if (action.id === product.id) {
          product[action.col].isBlurred = action.value;
        }
        if (
          !action.isBlurred &&
          product[action.col].isSaved &&
          product[action.col].isChanged
        ) {
          product[action.col].isSaved = false;
          product[action.col].isChanged = false;
        }
      });
      return {
        ...state,
        products,
      };
    case PRODUCT_TABLE_INPUT_SINGLE_SUBMIT:
      products.map((product) => {
        if (action.id === product.id) {
          if (action.res.status === "success") {
            product[action.col].isSaved = true;
            product[action.col].isChanged = false;
            product.margin.value = action.res.data[0].margin
              ? action.res.data[0].margin
              : "";
            product.price.value = action.res.data[0].price
              ? action.res.data[0].price
              : "";
          } else {
            product[action.col].isSaved = false;
            product[action.col].isChanged = true;
            product[action.col].error = true;
          }
        }
      });

      return {
        ...state,
        products,
      };
    case PRODUCT_SET_ALL_BRANDS:
      return {
        ...state,
        brands: action.brands,
      };
    case PRODUCT_SET_ALL_CAT:
      return {
        ...state,
        categories: action.categories,
      };
    case PRODUCT_IS_FILTERED:
      return {
        ...state,
        isFiltered: action.isFiltered,
      };
    case PRODUCT_SET_ID_LIST:
      return {
        ...state,
        idList: action.idList,
      };
    case SET_PRODUCT_BY_ID:
      return {
        ...state,
        singleProduct: action.singleProduct,
      };
    case SET_PRODUCTS_TO_GROUP:
      return {
        ...state,
        products: action.productsByGroup,
        showAllProducts: false,
      };
    case PRODUCT_TOGGLE_SHOW_ALL_DATA:
      return {
        ...state,
        showAllProducts: action.showAllProducts,
      };
    case PRODUCT_TABLE_LOADING:
      return {
        ...state,
        productsTableLoading: action.productsTableLoading,
      };
    case CLEAR_ALL_PRODUCTS:
      return {
        ...state,
        products: [],
      };
    case CLEAR_SINGLE_PRODUCT:
      return {
        ...state,
        singleProduct: null,
      };
    case PRODUCT_SET_NO_DATA:
      return {
        ...state,
        productsNoData: action.productsNoData,
      };
    case PRODUCT_UPDATE_TAG_LIST:
      products.map((product) => {
        return action.selectedProducts.forEach((item) => {
          if (
            item.id === product.id &&
            !product.tags.find((tag) => tag.id === action.tag.id)
          ) {
            product.tags.push(action.tag);
          }
        });
      });
      return {
        ...state,
        products: products,
      };
    case PRODUCT_REMOVE_FROM_TAG_LIST:
      products.map((product) => {
        return action.selectedProducts.forEach((item) => {
          if (
            item.id === product.id &&
            product.tags.find((tag) => tag.id === action.tag.id)
          ) {
            product.tags.splice(
              product.tags.findIndex((tag) => tag.id === action.tag.id),
              1
            );
          }
        });
      });
      return {
        ...state,
        products: products,
      };
    case PRODUCT_CLEAR_DATA:
      return {
        ...state,
        idList: [],
        isFiltered: false,
        selectedProducts: [],
        totalProducts: null,
        singleProduct: null,
        productsByGroup: [],
        showAllProducts: true,
        productsTableLoading: true,
        productsNoData: false,
      };
    case PRODUCT_SET_NO_PRODUCT_DATA:
      return {
        ...state,
        noProductData: action.noProductData,
      };
    case PRODUCT_LOADING:
      return {
        ...state,
        productLoading: action.productLoading,
      };
    case PRICE_POSITION_CHART:
      let labels = [];
      let dataSets = [];
      action.pricePositionChart.bars.map((bar) => labels.push(bar.name));
      action.pricePositionChart.bars.map((bar) => dataSets.push(bar.price));
      let compSet = {
        rrp: action.pricePositionChart.rrp,
        currentPrice: action.pricePositionChart.currentPrice,
        granditudePrice: action.pricePositionChart.granditudePrice,
        margin: action.pricePositionChart.margin,
        rrpOffset: action.pricePositionChart.rrpOffset,
        priceLastModifiedBy: action.pricePositionChart.priceLastModifiedBy,
        purchasePrice: action.pricePositionChart.purchasePrice,
        dataSets: [{ labels: labels, data: dataSets }],
      };
      return {
        ...state,
        pricePositionChart: compSet,
      };

    case CLEAR_ALL_PROD_IBY_PRODUCT_IDS:
      return {
        ...state,
        setProductsByproductIds: [],
      };
    case SET_PRODUCTS_BY_PRODUCT_IDS:
      return {
        ...state,
        setProductsByproductIds: action.setProductsByproductIds,
      };
    case PROD_BY_PRODUCT_IDS_LOADING:
      return {
        ...state,
        productsByproductIdsLoading: action.productsByproductIdsLoading,
      };
    case SET_NO_PRODUCT_BY_PRODUCT_IDS_DATA:
      return {
        ...state,
        setNoProductsByProductIdsData: action.setNoProductsByProductIdsData,
      };
    case PRICE_POSITION_CHART_LOADING:
      return {
        ...state,
        pricePositionChartLoading: action.pricePositionChartLoading,
      };
    case PRODUCT_UPDATE_SINGLE_PRODUCT_LOCKED:
      let singleProductData = { ...state.singleProduct };
      let productList = [...state.products];
      singleProductData.locked = action.lockedValue;
      if (state.productFilterLockStatus !== "all") {
        productList.splice(
          productList.indexOf(
            productList.find((product) => product.id === action.id)
          ),
          1
        );
      }
      return {
        ...state,
        singleProduct: singleProductData,
        products: productList,
      };
    case PRODUCT_FILTER_LOCK_STATUS_UPDATE:
      return {
        ...state,
        productFilterLockStatus: action.productFilterLockStatus,
      };

    case PRODUCT_SET_LAST_EXECUTED_CAMPAIGN:
      return {
        ...state,
        lastExecutedCampaign: action.lastExecutedCampaign,
      };
    case PRODUCT_UPDATE_SAVE_PRICE_SUCCESS:
      return {
        ...state,
        savePriceSuccess: action.value,
      };
      case CLEAR_CHART_DATA:
        return {
          ...state,
          pricePositionChart: null,
        }
    default:
      return state;
  }
}
