import React, { Component } from "react";

class HandleError extends Component {
  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = e => {
    if (this.errorModal.contains(e.target)) {
      return;
    } else {
      this.props.closeErrorMsg();
    }
  };

  render() {
    return (
      <div
        ref={node => (this.errorModal = node)}
        className={`flex bg-red-light w-full h-18 border-b-4 border-red absolute z-60 ${this.props.classList}
        `}
      >
        <div className="flex-1 flex font-sans text-white font-light xxl:text-lg text-sm h-full text-center items-center justify-center">
          {this.props.error ? this.props.error : "An error has occurred"}
        </div>
        <div className="w-8 flex h-full items-center justify-end">
          <i
            onClick={this.props.closeErrorMsg}
            className="material-icons pr-4  text-white hover:text-red-dark cursor-pointer"
          >
            close
          </i>
        </div>
      </div>
    );
  }
}

export default HandleError;
