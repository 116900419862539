import axios from "axios";
import { setPricePositionPagination } from "./tablePagination";
import { handleError } from "./general";

const SET_ALL_PRICE_POSITION = "SET_ALL_PRICE_POSITION";
const PRODUCT_IS_FILTERED = "PRODUCT_IS_FILTERED";
const PRODUCT_SET_NO_DATA = "PRODUCT_SET_NO_DATA";
const PRODUCT_TOGGLE_SHOW_ALL_DATA = "PRODUCT_TOGGLE_SHOW_ALL_DATA";
const PRODUCT_TABLE_LOADING = "PRODUCT_TABLE_LOADING";
const CLEAR_ALL_PRODUCTS = "CLEAR_ALL_PRODUCTS";
const PRICE_POSITION_UPDATE_TAG_LIST = "PRICE_POSITION_UPDATE_TAG_LIST";
const PRICE_POSITION_REMOVE_FROM_TAG_LIST =
  "PRICE_POSITION_REMOVE_FROM_TAG_LIST";
const PRODUCT_CLEAR_DATA = "PRODUCT_CLEAR_DATA";
const PRODUCT_LOADING = "PRODUCT_LOADING";
const ANALYTICS_SET_ID_LIST = "ANALYTICS_SET_ID_LIST";

export const getPricePositionData = (page, rowSize, attr, sorting) => {
  return dispatch => {
    dispatch(tableLoading(true));
    dispatch(setNoData(false));
    dispatch(clearAllProducts());
    if (localStorage.getItem("market")) {
      let url =
        attr && sorting
          ? process.env.REACT_APP_API_HOST +
            "/price-optimisation/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/competitor-analytics/price-position?page=" +
            page +
            "&size=" +
            rowSize +
            "&sort=" +
            attr +
            "," +
            sorting
          : process.env.REACT_APP_API_HOST +
            "/price-optimisation/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/competitor-analytics/price-position?page=" +
            page +
            "&size=" +
            rowSize;

      return axios
        .get(url)
        .then(res => {
          dispatch(setFiltered(false));
          dispatch(setAllPricePosition(res.data));
          dispatch(showAllProducts(true));
          dispatch(
            setPricePositionPagination(
              page,
              rowSize,
              res.data.totalPages,
              attr,
              sorting
            )
          );
          dispatch(tableLoading(false));
          if (res.data.content.length < 1) {
            dispatch(setNoData(true));
          }
        })
        .catch(err => {
          console.log(err);

          dispatch(tableLoading(false));
          dispatch(setNoData(true));
        });
    }
  };
};

export const filterPricePosition = (page, rowSize, idList, attr, sorting) => {
  return dispatch => {
    dispatch(tableLoading(true));
    dispatch(setNoData(false));
    dispatch(clearAllProducts());
    let url =
      attr && sorting
        ? process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/competitor-analytics/price-position?page=" +
          page +
          "&size=" +
          rowSize +
          "&sort=" +
          attr +
          "," +
          sorting +
          idList
        : process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/competitor-analytics/price-position?page=" +
          page +
          "&size=" +
          rowSize +
          idList;
    return axios
      .get(url)
      .then(res => {
        dispatch(setFiltered(true));
        dispatch(setIdList(idList));
        dispatch(setAllPricePosition(res.data));
        dispatch(
          setPricePositionPagination(
            page,
            rowSize,
            res.data.totalPages,
            attr,
            sorting
          )
        );
        dispatch(tableLoading(false));
        if (res.data.content.length < 1) {
          dispatch(setNoData(true));
        }
      })
      .catch(err => {
        dispatch(handleError(err));
        dispatch(tableLoading(false));
        dispatch(setNoData(true));
      });
  };
};

const showAllProducts = value => {
  return {
    type: PRODUCT_TOGGLE_SHOW_ALL_DATA,
    showAllProducts: value
  };
};

const setAllPricePosition = products => {
  return {
    type: SET_ALL_PRICE_POSITION,
    products: products.content,
    totalPricePosition: products.totalElements
  };
};

const clearAllProducts = () => {
  return {
    type: CLEAR_ALL_PRODUCTS
  };
};

export const setFiltered = val => {
  return {
    type: PRODUCT_IS_FILTERED,
    isFiltered: val
  };
};

const tableLoading = value => {
  return {
    type: PRODUCT_TABLE_LOADING,
    pricePositionTableLoading: value
  };
};

export const setNoData = value => {
  return {
    type: PRODUCT_SET_NO_DATA,
    pricePositionNoData: value
  };
};

export const updatePricePositionTagList = (tag, selectedPricePosition) => {
  return {
    type: PRICE_POSITION_UPDATE_TAG_LIST,
    tag: tag,
    selectedPricePosition: selectedPricePosition
  };
};

export const removeFromPricePositionTagList = (tag, selectedPricePosition) => {
  return {
    type: PRICE_POSITION_REMOVE_FROM_TAG_LIST,
    tag: tag,
    selectedPricePosition: selectedPricePosition
  };
};

export const clearData = () => {
  return {
    type: PRODUCT_CLEAR_DATA
  };
};

const setIdList = ids => {
  return {
    type: ANALYTICS_SET_ID_LIST,
    idList: ids
  };
};

const initialState = {
  idList: [],
  isFiltered: false,
  products: [],
  tag: [],
  selectedPricePosition: [],
  totalPricePosition: null,
  showAllProducts: true,
  pricePositionTableLoading: true,
  pricePositionNoData: false,
  productLoading: false,
  noProductData: false
};

export default function reducer(state = initialState, action = {}) {
  let products = [...state.products];

  switch (action.type) {
    case SET_ALL_PRICE_POSITION:
      return {
        ...state,
        products: action.products,
        totalPricePosition: action.totalPricePosition,
        totalPages: action.totalPages
      };
    case PRODUCT_IS_FILTERED:
      return {
        ...state,
        isFiltered: action.isFiltered
      };
    case PRODUCT_TOGGLE_SHOW_ALL_DATA:
      return {
        ...state,
        showAllProducts: action.showAllProducts
      };
    case PRODUCT_TABLE_LOADING:
      return {
        ...state,
        pricePositionTableLoading: action.pricePositionTableLoading
      };
    case CLEAR_ALL_PRODUCTS:
      return {
        ...state,
        products: []
      };
    case PRODUCT_SET_NO_DATA:
      return {
        ...state,
        pricePositionNoData: action.pricePositionNoData
      };
    case PRICE_POSITION_UPDATE_TAG_LIST:
      products.map(product => {
        return action.selectedPricePosition.forEach(item => {
          if (
            item.id === product.id &&
            !product.tags.find(tag => tag.id === action.tag.id)
          ) {
            product.tags.push(action.tag);
          }
        });
      });
      return {
        ...state,
        products: products
      };
    case PRICE_POSITION_REMOVE_FROM_TAG_LIST:
      products.map(product => {
        return action.selectedPricePosition.forEach(item => {
          if (
            item.id === product.id &&
            product.tags.find(tag => tag.id === action.tag.id)
          ) {
            product.tags.splice(
              product.tags.findIndex(tag => tag.id === action.tag.id),
              1
            );
          }
        });
      });
      return {
        ...state,
        products: products
      };
    case PRODUCT_CLEAR_DATA:
      return {
        ...state,
        idList: [],
        isFiltered: false,
        selectedPricePosition: [],
        totalPricePosition: null,
        singleProduct: null,
        productsByGroup: [],
        showAllProducts: true,
        pricePositionTableLoading: true,
        analyticsNoData: false
      };

    case PRODUCT_LOADING:
      return {
        ...state,
        productLoading: action.productLoading
      };
    case ANALYTICS_SET_ID_LIST:
      return {
        ...state,
        idList: action.idList
      };
    default:
      return state;
  }
}
