import axios from "axios";
const CHART_SALES_OVER_TIME_BRAND = "CHART_SALES_OVER_TIME_BRAND";
const CHART_SALES_OVER_TIME_CATEGORY = "CHART_SALES_OVER_TIME_CATEGORY";
const KPI_SUM_SALES_OVER_TIME = "KPI_SUM_SALES_OVER_TIME";
const PIE_DIST_BY_BRAND = "PIE_DIST_BY_BRAND";
const PIE_DIST_BY_CATEGORIES = "PIE_DIST_BY_CATEGORIES";
const KPI_SUM_CONVERSION_OVER_TIME = "KPI_SUM_CONVERSION_OVER_TIME";
const KPI_AVG_SALES_PER_CLICK = "KPI_AVG_SALES_PER_CLICK";
const SET_OVER_TIME_BRAND_IS_LOADING = "SET_OVER_TIME_BRAND_IS_LOADING";
const SET_OVER_TIME_CATEGORY_IS_LOADING = "SET_OVER_TIME_CATEGORY_IS_LOADING";
const SET_DIST_BY_BRAND_IS_LOADING = "SET_DIST_BY_BRAND_IS_LOADING";
const SET_DIST_BY_CATEGORY_IS_LOADING = "SET_DIST_BY_CATEGORY_IS_LOADING";
const CLEAR_SALES_TIME_BRAND = "CLEAR_SALES_TIME_BRAND";
const CLEAR_SALES_TIME_CATEGORY = "CLEAR_SALES_TIME_CATEGORY";
const CLEAR_DATA_DIST_BRAND = "CLEAR_DATA_DIST_BRAND";
const CLEAR_DATA_DIST_CATEGORY = "CLEAR_DATA_DIST_CATEGORY";
const CLEAR_SUM_SALES_TIME = "CLEAR_SUM_SALES_TIME";
const CLEAR_SUM_COVERSION_TIME = "CLEAR_SUM_COVERSION_TIME";
const CLEAR_AVG_SALES_CLICK = "CLEAR_AVG_SALES_CLICK";

export const configSalesOverTimeBrand = (config) => {
  return (dispatch) => {
    dispatch(clearConfigSalesOverTimeBrand());
    dispatch(setConfigSalesOverTimeBrandLoading(true));
    return axios
      .post(
        process.env.REACT_APP_API_HOST + "/business-intelligence/dashboard",
        config
      )
      .then((res) => {
        dispatch(setConfigSalesOverTimeBrand(res.data));
        dispatch(setConfigSalesOverTimeBrandLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setConfigSalesOverTimeBrandLoading(false));
      });
  };
};

export const configSalesOverTimeCategory = (config) => {
  return (dispatch) => {
    dispatch(clearConfigSalesOverTimeCategory());
    dispatch(setConfigSalesOverTimeCategoryLoading(true));
    return axios
      .post(
        process.env.REACT_APP_API_HOST + "/business-intelligence/dashboard",
        config
      )
      .then((res) => {
        dispatch(setConfigSalesOverTimeCategory(res.data));
        dispatch(setConfigSalesOverTimeCategoryLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setConfigSalesOverTimeCategoryLoading(false));
      });
  };
};

export const configSumSalesOverTime = (config) => {
  return (dispatch) => {
    dispatch(clearConfigSumSalesOverTime());
    return axios
      .post(
        process.env.REACT_APP_API_HOST + "/business-intelligence/dashboard",
        config
      )
      .then((res) => {
        dispatch(setConfigSumSalesOverTime(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const configSumConversionOverTime = (config) => {
  return (dispatch) => {
    dispatch(clearConfigSumConversionOverTime());
    return axios
      .post(
        process.env.REACT_APP_API_HOST +
          "/business-intelligence/dashboard/multi",
        config
      )
      .then((res) => {
        dispatch(setConfigSumConversionOverTime(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const avgSalesPerClick = (config) => {
  return (dispatch) => {
    dispatch(clearAvgSalesPerClick());
    return axios
      .post(
        process.env.REACT_APP_API_HOST +
          "/business-intelligence/dashboard/multi",
        config
      )
      .then((res) => {
        dispatch(setAvgSalesPerClick(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const configDistributionalesByBrand = (config) => {
  return (dispatch) => {
    dispatch(clearConfigDistributionalesByBrand());
    dispatch(setConfigDistributionalesByBrandLoading(true));
    return axios
      .post(
        process.env.REACT_APP_API_HOST + "/business-intelligence/dashboard",
        config
      )
      .then((res) => {
        dispatch(setConfigDistributionalesByBrand(res.data));
        dispatch(setConfigDistributionalesByBrandLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setConfigDistributionalesByBrandLoading(false));
      });
  };
};

export const configDistributionalesByCategory = (config) => {
  return (dispatch) => {
    dispatch(clearConfigDistributionalesByCategory());
    dispatch(setConfigDistributionalesByCategoryLoading(true));
    return axios
      .post(
        process.env.REACT_APP_API_HOST + "/business-intelligence/dashboard",
        config
      )
      .then((res) => {
        dispatch(setConfigDistributionalesByCategory(res.data));
        dispatch(setConfigDistributionalesByCategoryLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setConfigDistributionalesByCategoryLoading(false));
      });
  };
};

export const setConfigSalesOverTimeBrandLoading = (value) => {
  return {
    type: SET_OVER_TIME_BRAND_IS_LOADING,
    configSalesOverTimeBrandLoading: value,
  };
};
export const setConfigSalesOverTimeCategoryLoading = (value) => {
  return {
    type: SET_OVER_TIME_CATEGORY_IS_LOADING,
    configSalesOverTimeCategoryLoading: value,
  };
};
export const setConfigDistributionalesByBrandLoading = (value) => {
  return {
    type: SET_DIST_BY_BRAND_IS_LOADING,
    configDistributionalesByBrandLoading: value,
  };
};
export const setConfigDistributionalesByCategoryLoading = (value) => {
  return {
    type: SET_DIST_BY_CATEGORY_IS_LOADING,
    configDistributionalesByCategoryLoading: value,
  };
};

const setConfigSalesOverTimeBrand = (data) => {
  return {
    type: CHART_SALES_OVER_TIME_BRAND,
    configSalesOverTimeBrand: data.body,
  };
};
const setConfigSalesOverTimeCategory = (data) => {
  return {
    type: CHART_SALES_OVER_TIME_CATEGORY,
    configSalesOverTimeCategory: data.body,
  };
};

const setConfigDistributionalesByBrand = (data) => {
  return {
    type: PIE_DIST_BY_BRAND,
    configDistributionalesByBrand: data.body,
  };
};
const setConfigDistributionalesByCategory = (data) => {
  return {
    type: PIE_DIST_BY_CATEGORIES,
    configDistributionalesByCategory: data.body,
  };
};
const setConfigSumSalesOverTime = (data) => {
  return {
    type: KPI_SUM_SALES_OVER_TIME,
    configSumSalesOverTime: data.body,
  };
};
const setConfigSumConversionOverTime = (data) => {
  return {
    type: KPI_SUM_CONVERSION_OVER_TIME,
    configSumConversionOverTime: data,
  };
};
const setAvgSalesPerClick = (data) => {
  return {
    type: KPI_AVG_SALES_PER_CLICK,
    avgSalesPerClick: data,
  };
};

const clearConfigSalesOverTimeBrand = () => {
  return {
    type: CLEAR_SALES_TIME_BRAND,
  };
};
const clearConfigSalesOverTimeCategory = () => {
  return {
    type: CLEAR_SALES_TIME_CATEGORY,
  };
};
const clearConfigDistributionalesByBrand = () => {
  return {
    type: CLEAR_DATA_DIST_BRAND,
  };
};
const clearConfigDistributionalesByCategory = () => {
  return {
    type: CLEAR_DATA_DIST_CATEGORY,
  };
};

const clearConfigSumSalesOverTime = () => {
  return {
    type: CLEAR_SUM_SALES_TIME,
  };
};
const clearConfigSumConversionOverTime = () => {
  return {
    type: CLEAR_SUM_COVERSION_TIME,
  };
};
const clearAvgSalesPerClick = () => {
  return {
    type: CLEAR_AVG_SALES_CLICK,
  };
};

const initialState = {
  configSalesOverTimeBrandList: null,
  configSalesOverTimeCategoryList: null,
  configDistributionalesByBrandList: null,
  configDistributionalesByCategoryList: null,
  configSumSalesOverTimeList: {},
  configSumConversionOverTimeList: {},
  avgSalesPerClickList: {},
  configSalesOverTimeBrandLoading: false,
  configSalesOverTimeCategoryLoading: false,
  configDistributionalesByBrandLoading: false,
  configDistributionalesByCategoryLoading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHART_SALES_OVER_TIME_BRAND:
      let limitedDataSetTimeBrand = [];
      if (action.configSalesOverTimeBrand.dataSets.length > 15) {
        limitedDataSetTimeBrand = action.configSalesOverTimeBrand.dataSets.splice(
          0,
          15
        );
      } else {
        limitedDataSetTimeBrand = action.configSalesOverTimeBrand.dataSets;
      }
      action.configSalesOverTimeBrand.dataSets = limitedDataSetTimeBrand;
      return {
        ...state,
        configSalesOverTimeBrandList: action.configSalesOverTimeBrand,
      };
    case CHART_SALES_OVER_TIME_CATEGORY:
      let limitedDataSetTimeCategory = [];
      if (action.configSalesOverTimeCategory.dataSets.length > 15) {
        limitedDataSetTimeCategory = action.configSalesOverTimeCategory.dataSets.splice(
          0,
          15
        );
      } else {
        limitedDataSetTimeCategory =
          action.configSalesOverTimeCategory.dataSets;
      }
      action.configSalesOverTimeCategory.dataSets = limitedDataSetTimeCategory;
      return {
        ...state,
        configSalesOverTimeCategoryList: action.configSalesOverTimeCategory,
      };
    case KPI_SUM_SALES_OVER_TIME:
      return {
        ...state,
        configSumSalesOverTimeList: action.configSumSalesOverTime,
      };
    case PIE_DIST_BY_BRAND:
      let limitedDataSetDistBrand = [];
      if (action.configDistributionalesByBrand.dataSets.length > 15) {
        limitedDataSetDistBrand = action.configDistributionalesByBrand.dataSets.splice(
          0,
          15
        );
      } else {
        limitedDataSetDistBrand = action.configDistributionalesByBrand.dataSets;
      }
      action.configDistributionalesByBrand.dataSets = limitedDataSetDistBrand;
      return {
        ...state,
        configDistributionalesByBrandList: action.configDistributionalesByBrand,
      };
    case PIE_DIST_BY_CATEGORIES:
      let limitedDataSetDistCategory = [];
      if (action.configDistributionalesByCategory.dataSets.length > 15) {
        limitedDataSetDistCategory = action.configDistributionalesByCategory.dataSets.splice(
          0,
          15
        );
      } else {
        limitedDataSetDistCategory =
          action.configDistributionalesByCategory.dataSets;
      }
      action.configDistributionalesByCategory.dataSets = limitedDataSetDistCategory;

      return {
        ...state,
        configDistributionalesByCategoryList:
          action.configDistributionalesByCategory,
      };
    case KPI_SUM_CONVERSION_OVER_TIME:
      return {
        ...state,
        configSumConversionOverTimeList: action.configSumConversionOverTime,
      };
    case KPI_AVG_SALES_PER_CLICK:
      return {
        ...state,
        avgSalesPerClickList: action.avgSalesPerClick,
      };
    case SET_OVER_TIME_BRAND_IS_LOADING:
      return {
        ...state,
        configSalesOverTimeBrandLoading: action.configSalesOverTimeBrandLoading,
      };
    case SET_OVER_TIME_CATEGORY_IS_LOADING:
      return {
        ...state,
        configSalesOverTimeCategoryLoading:
          action.configSalesOverTimeCategoryLoading,
      };
    case SET_DIST_BY_BRAND_IS_LOADING:
      return {
        ...state,
        configDistributionalesByBrandLoading:
          action.configDistributionalesByBrandLoading,
      };

    case SET_DIST_BY_CATEGORY_IS_LOADING:
      return {
        ...state,
        configDistributionalesByCategoryLoading:
          action.configDistributionalesByCategoryLoading,
      };
    case CLEAR_SALES_TIME_BRAND:
      return {
        ...state,
        configSalesOverTimeBrandList: null,
      };
    case CLEAR_SALES_TIME_CATEGORY:
      return {
        ...state,
        configSalesOverTimeCategoryList: null,
      };
    case CLEAR_DATA_DIST_BRAND:
      return {
        ...state,
        configDistributionalesByBrandList: null,
      };
    case CLEAR_DATA_DIST_CATEGORY:
      return {
        ...state,
        configDistributionalesByCategoryList: null,
      };
    case CLEAR_SUM_SALES_TIME:
      return {
        ...state,
        configSumSalesOverTimeList: {},
      };
    case CLEAR_SUM_COVERSION_TIME:
      return {
        ...state,
        configSumConversionOverTimeList: {},
      };

    case CLEAR_AVG_SALES_CLICK:
      return {
        ...state,
        avgSalesPerClickList: {},
      };

    default:
      return state;
  }
}
