const OPEN_FORMPANEL = "OPEN_FORMPANEL";
const CLOSE_FORMPANEL = "CLOSE_FORMPANEL";
const FORM_STATE = "FORM_STATE";
const FORM_EDIT = "FORM_EDIT";

const initialState = {
  isFormVisible: false,
  formType: 0,
  targetUnitData: {},
  formState: "",
  formEdit: false,
};

export function closeFormpanel() {
  return {
    type: CLOSE_FORMPANEL,
  };
}

export function openFormpanel(formState, targetUnitData, formType) {
  return {
    type: OPEN_FORMPANEL,
    formState: formState,
    targetUnitData: targetUnitData,
    formType: formType,
  };
}

export function changeFormState(formState) {
  return {
    type: FORM_STATE,
    formState: formState,
  };
}

export function toggleFormEdit(formEdit) {
  return {
    type: FORM_EDIT,
    formEdit: formEdit,
  };
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_FORMPANEL:
      return Object.assign({}, state, {
        isFormVisible: true,
        formState: action.formState,
        targetUnitData: action.targetUnitData,
        formType: action.formType,
      });
    case CLOSE_FORMPANEL:
      return Object.assign({}, state, { isFormVisible: false });
    case FORM_STATE:
      return Object.assign({}, state, { formState: action.formState });
    case FORM_EDIT:
      return Object.assign({}, state, { formEdit: action.formEdit });
    default:
      return state;
  }
}
