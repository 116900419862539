const SET_PRICE_RULE_PAGINATION = "SET_PRICE_RULE_PAGINATION";
const SET_CAMPAIGN_PAGINATION = "SET_CAMPAIGN_PAGINATION";
const SET_GROUP_PAGINATION = "SET_GROUP_PAGINATION";
const SET_PRODUCT_PAGINATION = "SET_PRODUCT_PAGINATION";
const SET_PRODUCT_PERFORMACE_PAGINATION = "SET_PRODUCT_PERFORMACE_PAGINATION";
const SET_PRICE_POSITION_PAGINATION = "SET_PRICE_POSITION_PAGINATION";
const SET_PRICE_HISTORY_PAGINATION = "SET_PRICE_HISTORY_PAGINATION";
const SET_COMPETITOR_ANALYTICS_PAGINATION =
  "SET_COMPETITOR_ANALYTICS_PAGINATION";
const SET_CAMPAIGN_ANALYTICS_PAGINATION = "SET_CAMPAIGN_ANALYTICS_PAGINATION";

export const setPriceRulePagination = (
  page,
  rowSize,
  totalPages,
  attr,
  direction
) => {
  return {
    type: SET_PRICE_RULE_PAGINATION,
    priceRulePage: page,
    priceRuleRowSize: rowSize,
    priceRuleTotalPages: totalPages,
    priceRuleSortingAttr: attr,
    priceRuleSortingDirection: direction,
  };
};

export const setCampaignPagination = (
  page,
  rowSize,
  totalPages,
  attr,
  direction
) => {
  return {
    type: SET_CAMPAIGN_PAGINATION,
    campaignPage: page,
    campaignRowSize: rowSize,
    campaignTotalPages: totalPages,
    campaignSortingAttr: attr,
    campaignSortingDirection: direction,
  };
};

export const setGroupPagination = (
  page,
  rowSize,
  totalPages,
  attr,
  direction
) => {
  return {
    type: SET_GROUP_PAGINATION,
    groupPage: page,
    groupRowSize: rowSize,
    groupTotalPages: totalPages,
    groupSortingAttr: attr,
    groupSortingDirection: direction,
  };
};

export const setProductPagination = (
  page,
  rowSize,
  totalPages,
  attr,
  direction
) => {
  return {
    type: SET_PRODUCT_PAGINATION,
    productPage: page,
    productRowSize: rowSize,
    productTotalPages: totalPages,
    productSortingAttr: attr,
    productSortingDirection: direction,
  };
};
export const setPricePositionPagination = (
  page,
  rowSize,
  totalPages,
  attr,
  direction
) => {
  return {
    type: SET_PRICE_POSITION_PAGINATION,
    pricePositionPage: page,
    pricePositionRowSize: rowSize,
    pricePositionTotalPages: totalPages,
    pricePositionSortingAttr: attr,
    pricePositionSortingDirection: direction,
  };
};

export const setProductPerformancePagination = (
  page,
  rowSize,
  totalPages,
  attr,
  direction
) => {
  return {
    type: SET_PRODUCT_PERFORMACE_PAGINATION,
    productPerformancePage: page,
    productPerformanceRowSize: rowSize,
    productPerformanceTotalPages: totalPages,
    productPerformanceSortingAttr: attr,
    productPerformanceSortingDirection: direction,
  };
};

export const setCompetitorAnalyticsPagination = (
  page,
  rowSize,
  totalPages,
  attr,
  direction
) => {
  return {
    type: SET_COMPETITOR_ANALYTICS_PAGINATION,
    competitorAnalyticsPage: page,
    competitorAnalyticsRowSize: rowSize,
    competitorAnalyticsTotalPages: totalPages,
    competitorAnalyticsSortingAttr: attr,
    competitorAnalyticsSortingDirection: direction,
  };
};
export const setPriceHistoryPagination = (
  page,
  rowSize,
  totalPages,
  attr,
  direction
) => {
  return {
    type: SET_PRICE_HISTORY_PAGINATION,
    priceHistoryPage: page,
    priceHistoryRowSize: rowSize,
    priceHistoryTotalPages: totalPages,
    priceHistorySortingAttr: attr,
    priceHistorySortingDirection: direction,
  };
};
export const setCampaignAnalyticsPagination = (
  page,
  rowSize,
  totalPages,
  attr,
  direction
) => {
  return {
    type: SET_CAMPAIGN_ANALYTICS_PAGINATION,
    campaignAnalyticsPage: page,
    campaignAnalyticsRowSize: rowSize,
    campaignAnalyticsTotalPages: totalPages,
    campaignAnalyticsSortingAttr: attr,
    campaignAnalyticsSortingDirection: direction,
  };
};

const initialState = {
  priceRulePage: 1,
  priceRuleRowSize: 20,
  priceRuleTotalPages: 1,
  priceRuleSortingAttr: null,
  priceRuleSortingDirection: null,
  campaignPage: 1,
  campaignRowSize: 20,
  campaignTotalPages: 1,
  campaignSortingAttr: null,
  campaignSortingDirection: null,
  groupsPage: 1,
  groupsRowSize: 20,
  groupsTotalPages: 1,
  groupsSortingAttr: null,
  groupsSortingDirection: null,
  productPage: 1,
  productRowSize: 20,
  productTotalPages: 1,
  productSortingAttr: null,
  productSortingDirection: null,
  pricePositionPage: 1,
  pricePositionRowSize: 20,
  pricePositionTotalPages: 1,
  pricePositionSortingAttr: null,
  pricePositionSortingDirection: null,
  productPerformancePage: 1,
  productPerformanceRowSize: 20,
  productPerformanceTotalPages: 1,
  productPerformanceSortingAttr: null,
  productPerformanceSortingDirection: null,
  competitorAnalyticsPage: 1,
  competitorAnalyticsRowSize: 20,
  competitorAnalyticsTotalPages: 1,
  competitorAnalyticsSortingAttr: null,
  competitorAnalyticsSortingDirection: null,
  priceHistoryPage: 1,
  priceHistoryRowSize: 20,
  priceHistoryTotalPages: 1,
  priceHistorySortingAttr: null,
  priceHistorySortingDirection: null,
  campaignAnalyticsPagePage: 1,
  campaignAnalyticsRowSizeRowSize: 20,
  campaignAnalyticsTotalPagesTotalPages: 1,
  campaignAnalyticsSortingAttrSortingAttr: null,
  campaignAnalyticsSortingDirectionSortingDirection: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PRICE_RULE_PAGINATION:
      return {
        ...state,
        priceRulePage: action.priceRulePage,
        priceRuleRowSize: action.priceRuleRowSize,
        priceRuleTotalPages: action.priceRuleTotalPages,
        priceRuleSortingAttr: action.priceRuleSortingAttr,
        priceRuleSortingDirection: action.priceRuleSortingDirection,
      };
    case SET_CAMPAIGN_PAGINATION:
      return {
        ...state,
        campaignPage: action.campaignPage,
        campaignRowSize: action.campaignRowSize,
        campaignTotalPages: action.campaignTotalPages,
        campaignSortingAttr: action.campaignSortingAttr,
        campaignSortingDirection: action.campaignSortingDirection,
      };
    case SET_GROUP_PAGINATION:
      return {
        ...state,
        groupPage: action.groupPage,
        groupRowSize: action.groupRowSize,
        groupTotalPages: action.groupTotalPages,
        groupSortingAttr: action.groupSortingAttr,
        groupSortingDirection: action.groupSortingDirection,
      };
    case SET_PRODUCT_PAGINATION:
      return {
        ...state,
        productPage: action.productPage,
        productRowSize: action.productRowSize,
        productTotalPages: action.productTotalPages,
        productSortingAttr: action.productSortingAttr,
        productSortingDirection: action.productSortingDirection,
      };
    case SET_PRODUCT_PERFORMACE_PAGINATION:
      return {
        ...state,
        productPerformancePage: action.productPerformancePage,
        productPerformanceRowSize: action.productPerformanceRowSize,
        productPerformanceTotalPages: action.productPerformanceTotalPages,
        productPerformanceSortingAttr: action.productPerformanceSortingAttr,
        productPerformanceSortingDirection:
          action.productPerformanceSortingDirection,
      };
    case SET_COMPETITOR_ANALYTICS_PAGINATION:
      return {
        ...state,
        competitorAnalyticsPage: action.competitorAnalyticsPage,
        competitorAnalyticsRowSize: action.competitorAnalyticsRowSize,
        competitorAnalyticsTotalPages: action.competitorAnalyticsTotalPages,
        competitorAnalyticsSortingAttr: action.competitorAnalyticsSortingAttr,
        competitorAnalyticsSortingDirection:
          action.competitorAnalyticsSortingDirection,
      };
    case SET_PRICE_POSITION_PAGINATION:
      return {
        ...state,
        pricePositionPage: action.pricePositionPage,
        pricePositionRowSize: action.pricePositionRowSize,
        pricePositionTotalPages: action.pricePositionTotalPages,
        pricePositionSortingAttr: action.pricePositionSortingAttr,
        pricePositionSortingDirection: action.pricePositionSortingDirection,
      };
    case SET_PRICE_HISTORY_PAGINATION:
      return {
        ...state,
        priceHistoryPage: action.priceHistoryPage,
        priceHistoryRowSize: action.priceHistoryRowSize,
        priceHistoryTotalPages: action.priceHistoryTotalPages,
        priceHistorySortingAttr: action.priceHistorySortingAttr,
        priceHistorySortingDirection: action.priceHistorySortingDirection,
      };
    case SET_CAMPAIGN_ANALYTICS_PAGINATION:
      return {
        ...state,
        campaignAnalyticsPage: action.campaignAnalyticsPage,
        campaignAnalyticsRowSize: action.campaignAnalyticsRowSize,
        campaignAnalyticsTotalPages: action.campaignAnalyticsTotalPages,
        campaignAnalyticsSortingAttr: action.campaignAnalyticsSortingAttr,
        campaignAnalyticsSortingDirection:
          action.campaignAnalyticsSortingDirection,
      };
    default:
      return state;
  }
}
