import React from "react";

const MultiDropdown = (props) => {
  let keyCap = props.keyVal.charAt(0).toLowerCase() + props.keyVal.slice(1);
  let keyName = keyCap.replace(/\s/g, "");
  let item =
    props.keyVal.length > 0 &&
    props.filterKeyOptions.find((item) =>
      item.name.toLowerCase().includes(keyName.toLowerCase())
    );

  let noDate =
    props.options && props.options.length > 0
      ? props.options.filter((item) => {
          if (
            props.filterValues.length > 0 &&
            Object.keys(props.filterValues)
              .map((key) => "Date" in props.filterValues[key])
              .includes(true)
          ) {
            return !item.name.includes("Date");
          } else return item;
        })
      : [];

  let filteredOptions =
    props.value.length > 0
      ? noDate.length > 0
        ? noDate.filter((item) =>
            item.name.toLowerCase().includes(props.value.toLowerCase())
          )
        : []
      : noDate;

  let selectedList = props.multiSelect ? props.multiSelect : [];
  return (
    <ul
      className={`shadow-md xxl:max-h-15 max-h-10 rounded-lg overflow-y-auto overflow-x-hidden mt-10 px-0 border-1 border-solid border-grey-lighter absolute z-30 min-w-10 max-w-10 `}
    >
      <input
        autofocus="true"
        onKeyDown={(e) => props.handleKeyDown(e, filteredOptions, item)}
        onChange={props.handleChange}
        value={props.value}
        className={`${
          "bg-" +
          props.colorList[item.id] +
          " placeholder-white w-full text-white"
        } font-light focus:border-none focus:outline-none  py-3 pl-2 `}
        name={props.name}
        type="text"
      />
      <li
        onClick={() =>
          props.handleSelect(props.indexEdit, selectedList, {
            id: null,
            name: "Select All",
          })
        }
        className={` ${
          "bg-" + props.colorList[item.id]
        } text-white w-full py-2 text-center sticky top-0 font-bold cursor-pointer hover:bg-${
          props.colorList[item.id]
        }er  `}
      >
        Select All
      </li>
      {filteredOptions.map((option, i) => {
        let nameCap =
          option.name.charAt(0).toUpperCase() + option.name.slice(1);
        let name = nameCap.replace(/([a-z])([A-Z])/g, "$1 $2");
        return (
          <li
            key={i}
            className={`${
              props.cursor === i ? "bg-" + props.colorList[item.id] + "er" : " "
            }  ${
              props.keyVal.length > 0
                ? props.multiSelect &&
                  props.multiSelect.find(
                    (obj) => obj.toLowerCase() === name.toLowerCase()
                  )
                  ? "bg-" + props.colorList[item.id] + "er"
                  : "bg-" + props.colorList[item.id]
                : "bg-" + props.colorList[option.id]
            } cursor-pointer py-3  px-6 hover:bg-${
              props.colorList[item.id]
            }er text-white  relative border-t-1 border-grey-lighter text-center`}
            onClick={() =>
              props.handleSelect(props.indexEdit, selectedList, option)
            }
          >
            {name}
          </li>
        );
      })}
      <li
        onClick={() =>
          props.handleSelect(props.indexEdit, selectedList, {
            id: null,
            name: "End Selection",
          })
        }
        className={` ${
          "bg-" + props.colorList[item.id]
        } text-white w-full py-2 text-center border-grey-lighter  font-bold cursor-pointer  border-t-1 sticky bottom-0 hover:bg-${
          props.colorList[item.id]
        }er  `}
      >
        End Selection
      </li>
    </ul>
    // </div>
  );
};

export default MultiDropdown;
