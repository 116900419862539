import React, { Component } from "react";
import { SearchBar } from "components/SearchBar/SearchBar";
import SmallSingleDropdown from "components/SmallSingleDropdown/SmallSingleDropdown";

export default class CreateProductCard extends Component {
  handleOnChange = (event) => {
    this.props.handleSearch(event, null, null, "createCard");
  };
  render() {
    return (
      <div className="w-full p-2 mb-4 px-6 h-full font-sans xxl:text-sm text-xs">
        <div className="h-full w-full flex flex-col">
          <div className="w-full flex flex-row items-center h-6 mb-1">
            <div className="w-1/2">
              <SmallSingleDropdown
                selected={this.props.selected}
                options={this.props.options}
                handleSelected={this.props.handleSelected}
                name={this.props.name}
              />
            </div>
          </div>

          <div className="flex-1 flex justify-center items-center">
            {this.props.selected && (
              <div className="w-2/4 py-2">
                <SearchBar
                  onKeyDown={this.handleOnChange}
                  onChange={this.handleOnChange}
                  value={this.props.searchValue}
                  placeholder="Search"
                  loading={this.props.searchLoading}
                  rounded={true}
                  icon="search"
                  handleClickSearch={this.props.handleClickSearch}
                />

                <div className="w-full text-center h-8 font-sans text-red-dark text-xs mt-2">
                  {this.props.searchError && "No Products Found"}
                </div>
              </div>
            )}
          </div>

          <div className="w-full flex flex-row justify-between h-10 my-2 bg-teal-lightest rounded">
            <div
              className="px-4 self-center text-teal-dark cursor-pointer"
              onClick={() => this.props.cancel()}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    );
  }
}
