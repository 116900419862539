import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { InputText } from "components/InputText";
import { FormContentWrapper } from "containers/Forms/FormContainer/FormContentWrapper";
import { closeFormpanel } from "ducks/formpanel";
import {
  createNewGroup,
  updatedGroup,
  previewProducts,
  productsPreviewLoading,
} from "ducks/groups";
import {
  getProductData,
  filterProducts,
  getProductsByGroup,
  getProductbyId,
  // getAllCategories,
  getCategoriesByMarket,
  // getAllBrands,
  getBrandsByMarket,
  clearData,
} from "ducks/products";
import * as FORM_ACTION from "utils/form-actions";
import SelectGroup from "./SelectGroup";
import SelectExpression from "./SelectExpression";
import SingleSelectDropdown from "components/SingleSelectDropdown";
import moment from "moment";
import { SmallLoadingSpinner } from "components/LargeLoadingSpinner/LargeLoadingSpinner";

class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formWidthGrow: false,
      canSave: false,
      name: "",
      createdBy: "",
      created: "",
      updated: "",
      items: "",
      description: "",
      firstExpCreated: "",
      firstExpUpdated: "",
      spCreatedBy: "",
      spCreated: "",
      secondExpCreated: "",
      secondExpUpdated: "",
      fpCreated: "",
      fpCreatedBy: "",
      attributeOptions: [],
      attributes1: {},
      attributes2: {},
      relationalOperator1: {},
      relationalOperator2: {},
      groupOptions: [],
      firstParent: null,
      secondParent: null,
      expressions: [],
      first: "expression",
      second: "expression",
      value1: {},
      value2: {},
      logicalOperatorOptions: [],
      relationalOperatorOptions: [],
      logicalOperator: {},
      groupParent1: null,
      groupParent2: null,
      showMore: false,
      showPreview: false,
      relationalOperatorExp1: {},
      relationalOperatorExp2: {},
      nameError: false,
      descriptionError: false,
      groupParent1Error: false,
      value1Error: false,
      relationalOperatorExp1Error: false,
      attributes1Error: false,
      value2Error: false,
      relationalOperatorExp2Error: false,
      attributes2Error: false,
    };
  }

  componentDidMount() {
    this.props.getCategoriesByMarket();
    this.props.getBrandsByMarket();
    const { data, toggleFormWidth } = this.props;
    toggleFormWidth();
    data &&
      this.props.config &&
      this.setState(
        {
          description: data.description,
          attributeOptions: Object.keys(this.props.config.attributes).map(
            (key) => this.props.config.attributes[key]
          ),
          groupOptions: this.props.groups,
          relationalOperatorOptions: Object.keys(
            this.props.config.relationalOperators
          ).map((key) => this.props.config.relationalOperators[key]),

          logicalOperatorOptions: Object.keys(
            this.props.config.logicalOperators
          ).map((key) => this.props.config.logicalOperators[key]),
        },
        () => {
          if (this.props.formState === FORM_ACTION.STATE_EDIT) {
            this.expressionOrGroup();
            this.setValue();
            this.setAttributes();
            this.setRelationalOperatorsParent();
            this.setRelationalOperatorsExpressions();
            this.setCreatedByAndCreated();
            let group = this.props.groups && this.props.groups;
            let index = group.findIndex(
              (item) => item.id === this.props.data.id
            );
            if (index > -1) {
              group.splice(index, 1);
            }

            this.setState({
              name: data.name,
              createdBy: data.createdBy,
              expressions: data.expressions,
              showMore:
                data.logicalOperator &&
                Object.keys(data.logicalOperator).length > 0
                  ? true
                  : false,
              logicalOperator: data.logicalOperator,
              groupOptions: group,
            });
          }
        }
      );
  }

  setCreatedByAndCreated = () => {
    const { firstParent, secondParent } = this.props.data;
    const { expressions } = this.props.data;
    //TOP LAYER AT/BY/UPD

    let updated = this.props.data.updated;
    let createdBy = this.props.data.createdBy;
    this.setState({
      createdBy: createdBy,
      created: this.props.data.created,
      updated: updated,
    });
    if (firstParent !== null && secondParent !== null) {
      //set parents createdby and created at
      // let fpCreatedDay = moment.(firstParent.created)local().format("YYYY-MM-DD");
      let fpCreated = moment(firstParent.created).local().format("YYYY-MM-DD");
      //  moment(fpCreatedDay)local().format("L");
      let fpCreatedBy = firstParent.createdBy;

      // let spCreatedDay = moment.(firstParent.created)local().format("YYYY-MM-DD");
      let spCreated = moment(firstParent.created).local().format("YYYY-MM-DD");
      // moment(spCreatedDay)local().format("L");
      let spCreatedBy = secondParent.createdBy;

      this.setState({
        fpCreatedBy,
        fpCreated,
        spCreatedBy,
        spCreated,
      });
    } else if (firstParent === null && secondParent !== null) {
      //SET FIRST EXPRESSIONS AT/BY AND SECOND PARENTS AT/BY

      // let firstExpCreatedDay = moment.(expressions[0].created)local().format("YYYY-MM-DD");
      let firstExpCreated = moment(expressions[0].created)
        .local()
        .format("YYYY-MM-DD");
      //  moment(firstExpCreatedDay)local().format("L");
      // let firstExpUpdateDay = moment.(expressions[0].updated)local().format("YYYY-MM-DD");
      let firstExpUpdated = moment(expressions[0].updated)
        .local()
        .format("YYYY-MM-DD");
      //  moment(firstExpUpdateDay)local().format("L");

      // let spCreatedDay = moment.(secondParent.created)local().format("YYYY-MM-DD");
      let spCreated = moment(secondParent.created).local().format("YYYY-MM-DD");
      //  moment(spCreatedDay)local().format("L");
      let spCreatedBy = secondParent.createdBy;

      this.setState({
        firstExpCreated,
        firstExpUpdated,
        spCreatedBy,
        spCreated,
      });
    } else if (firstParent !== null && secondParent === null) {
      // SET FIRST PARENT AT/BY AND SECOND EXPRESSION AT/BY
      let secondExpCreated = "";
      let secondExpUpdated = "";
      if (expressions.length > 0) {
        // let secondExpCreatedDay =
        secondExpCreated = moment(expressions[0].created)
          .local()
          .format("YYYY-MM-DD");
        // moment(secondExpCreatedDay)local().format("L");
        // let secondExpUpdateDay =
        secondExpUpdated = moment(expressions[0].updated)
          .local()
          .format("YYYY-MM-DD");
        // moment(secondExpUpdateDay)local().format("L");
      }

      // let fpCreatedDay =
      let fpCreated = moment(firstParent.created).local().format("YYYY-MM-DD");
      //  moment(fpCreatedDay)local().format("L");
      let fpCreatedBy = firstParent.createdBy;

      this.setState({
        secondExpCreated,
        secondExpUpdated,
        fpCreated,
        fpCreatedBy,
      });
    } else if (firstParent === null && secondParent === null) {
      // let firstExpCreatedDay =
      let firstExpCreated = moment(expressions[0].created)
        .local()
        .format("YYYY-MM-DD");
      // moment(firstExpCreatedDay)local().format("L");
      // let firstExpUpdateDay =
      let firstExpUpdated = moment(expressions[0].updated)
        .local()
        .format("YYYY-MM-DD");
      // moment(firstExpUpdateDay)local().format("L");

      //SET BOTH EXPRESSION AT/BY

      let secondExpCreated = "";
      let secondExpUpdated = "";

      if (expressions.length > 1) {
        // let secondExpCreatedDay =
        secondExpCreated = moment(expressions[1].created)
          .local()
          .format("YYYY-MM-DD");
        // moment(secondExpCreatedDay)local().format("L");
        // let secondExpUpdateDay =
        secondExpUpdated = moment(expressions[1].updated)
          .local()
          .format("YYYY-MM-DD");
        //  moment(secondExpUpdateDay)local().format("L");
      }

      this.setState({
        secondExpCreated,
        secondExpUpdated,
        firstExpCreated,
        firstExpUpdated,
      });
    }
  };

  setValue = () => {
    const { expressions } = this.props.data;
    const values = Object.keys(expressions).map(
      (key) => expressions[key].value
    );
    const attri = Object.keys(expressions).map(
      (key) => expressions[key].attribute.name
    );
    let value2 = {};
    if (
      this.props.data.firstParent !== null &&
      this.props.data.secondParent === null
    ) {
      if (attri[0] === "Brand") {
        value2 = this.props.brand.find((brand) => brand.name === values[0]);
      } else if (attri[0] === "Tag") {
        value2 = this.props.tags.find((tag) => tag.name === values[0]);
      } else if (attri[0] === "Category") {
        value2 = this.props.categories.find(
          (category) => category.name === values[0]
        );
      } else value2 = {};
    }
    this.setState({ value2 });
    let value1 = {};
    if (
      this.props.data.secondParent !== null &&
      this.props.data.firstParent === null
    ) {
      if (attri[0] === "Brand") {
        value1 = this.props.brand.find((brand) => brand.name === values[0]);
      } else if (attri[0] === "Tag") {
        value1 = this.props.tags.find((tag) => tag.name === values[0]);
      } else if (attri[0] === "Category") {
        value1 = this.props.categories.find(
          (category) => category.name === values[0]
        );
      } else value1 = {};
    }
    this.setState({ value1 });
    if (
      this.props.data.firstParent === null &&
      this.props.data.secondParent === null
    ) {
      let value1 = {};
      let value2 = {};

      if (attri[0] === "Brand") {
        value1 = this.props.brand.find((brand) => brand.name === values[0]);
      } else if (attri[0] === "Tag") {
        value1 = this.props.tags.find((tag) => tag.name === values[0]);
      } else if (attri[0] === "Category") {
        value1 = this.props.categories.find(
          (category) => category.name === values[0]
        );
      } else value1 = {};

      if (attri[1] === "Brand") {
        value2 = this.props.brand.find((brand) => brand.name === values[1]);
      } else if (attri[1] === "Tag") {
        value2 = this.props.tags.find((tag) => tag.name === values[1]);
      } else if (attri[1] === "Category") {
        value2 = this.props.categories.find(
          (category) => category.name === values[1]
        );
      } else value2 = {};
      this.setState({ value1, value2 });
    }
  };

  setAttributes = () => {
    const { expressions } = this.props.data;
    const attributes = Object.keys(expressions).map(
      (key) => expressions[key].attribute
    );
    let attributes1 = {};
    let attributes2 = {};
    if (
      this.props.data.secondParent !== null &&
      this.props.data.firstParent === null
    ) {
      attributes1 = attributes[0];
    } else if (
      this.props.data.secondParent === null &&
      this.props.data.firstParent !== null
    ) {
      attributes2 = attributes[0];
    } else if (
      this.props.data.secondParent === null &&
      this.props.data.firstParent === null
    ) {
      if (expressions.length > 1) {
        attributes1 = attributes[0];
        attributes2 = attributes[1];
      } else {
        //if only top form used
        attributes1 = attributes[0];
      }
    }

    this.setState({ attributes1, attributes2 });
  };

  setRelationalOperatorsParent = () => {
    const { firstParent, secondParent } = this.props.data;
    if (firstParent !== null) {
      const fpRelationalOperator = Object.keys(firstParent.expressions).map(
        (key) => firstParent.expressions[key].relationalOperator
      );
      const relationalOperator1 = fpRelationalOperator;
      this.setState({ relationalOperator1 });
    }
    if (secondParent !== null) {
      const spRelationalOperator = Object.keys(secondParent.expressions).map(
        (key) => secondParent.expressions[key].relationalOperator
      );
      const relationalOperator2 = spRelationalOperator;
      this.setState({ relationalOperator2 });
    }
  };

  setRelationalOperatorsExpressions = () => {
    const { expressions } = this.props.data;
    let relationalOperatorExp1 = {};
    let relationalOperatorExp2 = {};
    if (
      this.props.data.firstParent !== null &&
      this.props.data.secondParent === null
    ) {
      if (expressions.length > 0) {
        //check if top form is only group
        relationalOperatorExp2 = expressions[0].relationalOperator;
      } else {
        relationalOperatorExp1 = {};
        relationalOperatorExp2 = {};
      }
    } else if (
      this.props.data.firstParent === null &&
      this.props.data.secondParent !== null
    ) {
      relationalOperatorExp1 = expressions[0].relationalOperator;
    } else if (
      this.props.data.firstParent === null &&
      this.props.data.secondParent === null
    ) {
      if (expressions.length > 1) {
        relationalOperatorExp1 = expressions[0].relationalOperator;
        relationalOperatorExp2 = expressions[1].relationalOperator;
      } else {
        //if only top form used
        relationalOperatorExp1 = expressions[0].relationalOperator;
      }
    }
    this.setState({ relationalOperatorExp1, relationalOperatorExp2 });
  };

  expressionOrGroup = () => {
    const { firstParent, secondParent } = this.props.data;
    if (firstParent !== null && secondParent !== null) {
      this.setState({
        first: "group",
        second: "group",
        firstParent,
        secondParent,
      });
    } else if (firstParent !== null && secondParent === null) {
      this.setState({
        first: "group",
        second: "expression",
        firstParent,
        secondParent,
      });
    } else if (firstParent === null && secondParent !== null) {
      this.setState({
        first: "expression",
        second: "group",
        firstParent,
        secondParent,
      });
    } else if (firstParent === null && secondParent === null) {
      this.setState({
        first: "expression",
        second: "expression",
        firstParent,
        secondParent,
      });
    }
  };

  validationCheck = () => {
    let error = false;
    const { name, description } = this.state;

    if (name.length < 1) {
      error = true;
      this.setState({ nameError: true });
    }
    if (description === undefined || description.length < 1) {
      error = true;
      this.setState({ descriptionError: true });
    }
    if (this.state.second === "expression" && this.state.showMore === true) {
      if (this.state.value2 && Object.keys(this.state.value2).length < 1) {
        error = true;
        this.setState({ value2Error: true });
      }
      if (
        this.state.relationalOperatorExp2 &&
        Object.keys(this.state.relationalOperatorExp2).length < 1
      ) {
        error = true;
        this.setState({ relationalOperatorExp2Error: true });
      }
      if (
        this.state.attributes2 &&
        Object.keys(this.state.attributes2).length < 1
      ) {
        error = true;
        this.setState({ attributes2Error: true });
      }
    }
    if (this.state.first === "expression" && this.props.formState === "edit") {
      if (this.state.value1 && Object.keys(this.state.value1).length < 1) {
        error = true;
        this.setState({ value1Error: true });
      }
      if (
        this.state.relationalOperatorExp1 &&
        Object.keys(this.state.relationalOperatorExp1).length < 1
      ) {
        error = true;
        this.setState({ relationalOperatorExp1Error: true });
      }
      if (
        this.state.attributes1 &&
        Object.keys(this.state.attributes1).length < 1
      ) {
        error = true;
        this.setState({ attributes1Error: true });
      }
    }

    if (this.props.formState === "create") {
      if (this.state.first === "group" && this.state.groupParent1 === null) {
        error = true;
        this.setState({ groupParent1Error: true });
      }
      if (
        this.state.first === "expression" &&
        this.state.value1 &&
        Object.keys(this.state.value1).length < 1
      ) {
        error = true;
        this.setState({ value1Error: true });
      }
      if (
        this.state.first === "expression" &&
        this.state.relationalOperatorExp1 &&
        Object.keys(this.state.relationalOperatorExp1).length < 1
      ) {
        error = true;
        this.setState({ relationalOperatorExp1Error: true });
      }
      if (
        this.state.first === "expression" &&
        this.state.attributes1 &&
        Object.keys(this.state.attributes1).length < 1
      ) {
        error = true;
        this.setState({ attributes1Error: true });
      }
    }
    return error;
  };

  //HANDLE SELECTED TEXT
  handleActive = (index, select) => {
    index === 1
      ? this.setState({ first: select })
      : this.setState({ second: select });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: false,
      canSave: true,
    });
  };

  handleSelected = (name, selected) => {
    if (
      name === "logicalOperator" &&
      selected &&
      Object.keys(selected).length > 1
    ) {
      this.setState({
        [name]: selected,
        canSave: true,
        [name + "Error"]: false,
        showMore: true,
      });
    }
    if (
      name === "logicalOperator" &&
      selected &&
      Object.keys(selected).length < 1
    ) {
      this.setState({ [name]: selected, canSave: true, showMore: false });
    } else {
      this.setState({
        [name]: selected,
        canSave: true,
        [name + "Error"]: false,
      });
    }
  };

  handleSubmit = (e) => {
    const { updatedGroup, createNewGroup } = this.props;

    if (!this.validationCheck()) {
      this.props.closeFormpanel();

      if (this.props.formState === FORM_ACTION.STATE_EDIT) {
        let expression = [];
        if (
          (this.state.first === "expression" &&
            this.state.second === "expression") ||
          this.state.first === "expression"
        ) {
          if (
            (this.state.logicalOperator &&
              Object.keys(this.state.logicalOperator).length < 1) ||
            this.state.logicalOperator === null
          ) {
            expression = [
              {
                attribute:
                  this.state.first === "expression"
                    ? this.state.attributes1
                    : this.state.attributes2,
                relationalOperator:
                  this.state.first === "expression"
                    ? this.state.relationalOperatorExp1
                    : this.state.relationalOperatorExp2,
                value:
                  this.state.first === "expression"
                    ? this.state.value1.name
                    : this.state.value2.name,
              },
            ];
          } else if (
            this.state.first === "expression" &&
            this.state.second === "expression" &&
            this.state.logicalOperator &&
            Object.keys(this.state.logicalOperator).length > 0
          ) {
            expression = [
              {
                attribute: this.state.attributes1,
                relationalOperator: this.state.relationalOperatorExp1,
                value: this.state.value1.name,
              },
              {
                attribute: this.state.attributes2,
                relationalOperator: this.state.relationalOperatorExp2,
                value: this.state.value2.name,
              },
            ];
          } else {
            expression = [
              {
                attribute:
                  this.state.first === "expression"
                    ? this.state.attributes1
                    : this.state.attributes2,
                relationalOperator:
                  this.state.first === "expression"
                    ? this.state.relationalOperatorExp1
                    : this.state.relationalOperatorExp2,
                value:
                  this.state.first === "expression"
                    ? this.state.value1.name
                    : this.state.value2.name,
              },
            ];
          }
        } else if (
          (this.state.first === "group" || this.state.second === "group") &&
          this.state.logicalOperator &&
          Object.keys(this.state.logicalOperator).length > 0
        ) {
          expression = [
            {
              attribute:
                this.state.first === "expression"
                  ? this.state.attributes1
                  : this.state.attributes2,
              relationalOperator:
                this.state.first === "expression"
                  ? this.state.relationalOperatorExp1
                  : this.state.relationalOperatorExp2,
              value:
                this.state.first === "expression"
                  ? this.state.value1.name
                  : this.state.value2.name,
            },
          ];
        } else if (
          this.state.first === "group" &&
          this.state.logicalOperator &&
          Object.keys(this.state.logicalOperator).length < 1
        ) {
          expression = [];
        }

        let firstParent = null;
        let secondParent = null;

        if (this.state.first === "group" && this.state.second === "group") {
          firstParent = this.state.firstParent
            ? this.state.firstParent
            : this.state.groupParent1;
          secondParent = this.state.secondParent
            ? this.state.secondParent
            : this.state.groupParent2;
        } else if (
          this.state.first === "group" &&
          this.state.second === "expression"
        ) {
          firstParent = this.state.firstParent
            ? this.state.firstParent
            : this.state.groupParent1;
          secondParent = null;
        } else if (
          this.state.first === "expression" &&
          this.state.second === "group"
        ) {
          secondParent = this.state.secondParent
            ? this.state.secondParent
            : this.state.groupParent2;
          firstParent = null;
        } else if (
          this.state.first === "expression" &&
          this.state.second === "expression"
        ) {
          firstParent = null;
          secondParent = null;
        } else if (
          (this.state.first === "group" || this.state.first === "expression") &&
          this.state.logicalOperator &&
          Object.keys(this.state.logicalOperator).length < 1
        ) {
          secondParent = null;
        }

        const updExpression = {
          id: this.props.data.id,
          name: this.state.name,
          description: this.state.description,
          expressions:
            this.state.first === "group" && this.state.second === "group"
              ? []
              : expression,
          firstParent: firstParent,
          secondParent: secondParent,
          logicalOperator:
            this.state.logicalOperator &&
            Object.keys(this.state.logicalOperator).length > 0
              ? this.state.logicalOperator
              : null,
        };
        return updatedGroup(
          updExpression,
          this.props.currentPage,
          this.props.rowSize,
          null,
          null
        );
      } else {
        if (
          (this.state.logicalOperator &&
            Object.keys(this.state.logicalOperator).length < 1) ||
          this.state.logicalOperator === null
        ) {
          //checks if only top form is used
          const expression = [
            {
              attribute:
                this.state.first === "expression"
                  ? this.state.attributes1
                  : this.state.attributes2,
              relationalOperator:
                this.state.first === "expression"
                  ? this.state.relationalOperatorExp1
                  : this.state.relationalOperatorExp2,
              value:
                this.state.first === "expression"
                  ? this.state.value1.name
                  : this.state.value2.name,
            },
          ];

          const newGroup = {
            name: this.state.name,
            description: this.state.description,
            expressions: this.state.first === "group" ? [] : expression,
            firstParent:
              this.state.first === "group" ? this.state.groupParent1 : null,
            secondParent: this.state.groupParent2,
            logicalOperator: null,
          };
          return createNewGroup(
            newGroup,
            this.props.currentPage,
            this.props.rowSize,
            null,
            null
          );
        } else {
          //if both forms are used
          const expression =
            this.state.first === "expression" &&
            this.state.second === "expression" &&
            ((this.state.logicalOperator &&
              Object.keys(this.state.logicalOperator).length > 0) ||
              this.state.logicalOperator !== null)
              ? [
                  {
                    attribute: this.state.attributes1,
                    relationalOperator: this.state.relationalOperatorExp1,
                    value: this.state.value1.name,
                  },
                  {
                    attribute: this.state.attributes2,
                    relationalOperator: this.state.relationalOperatorExp2,
                    value: this.state.value2.name,
                  },
                ]
              : [
                  {
                    attribute:
                      this.state.first === "expression"
                        ? this.state.attributes1
                        : this.state.attributes2,
                    relationalOperator:
                      this.state.first === "expression"
                        ? this.state.relationalOperatorExp1
                        : this.state.relationalOperatorExp2,
                    value:
                      this.state.first === "expression"
                        ? this.state.value1.name
                        : this.state.value2.name,
                  },
                ];
          let firstParent = null;
          let secondParent = null;
          if (this.state.second === "group") {
            secondParent = this.state.groupParent2;
          }
          if (this.state.first === "group") {
            firstParent = this.state.groupParent1;
          }

          const newGroup = {
            name: this.state.name,
            description: this.state.description,
            expressions:
              this.state.first === "group" && this.state.second === "group"
                ? []
                : expression,
            firstParent: firstParent,
            secondParent: secondParent,
            logicalOperator: this.state.logicalOperator,
          };

          return createNewGroup(
            newGroup,
            this.props.page,
            this.props.rowSize,
            this.props.sortingAttr,
            this.props.sortingDirection
          );
        }
      }
    }
  };

  handleCancel = () => {
    this.props.closeFormpanel();
  };
  showProductPreview = () => {
    this.setState((prevState) => ({
      showPreview: !prevState.showPreview,
    }));

    this.productPreview();
  };
  productPreview = () => {
    if (!this.state.showPreview) {
      if (this.props.formState === FORM_ACTION.STATE_EDIT) {
        this.props.productsPreviewLoading(true);

        let expression = [];
        if (
          (this.state.first === "expression" &&
            this.state.second === "expression") ||
          this.state.first === "expression"
        ) {
          if (
            this.state.logicalOperator &&
            Object.keys(this.state.logicalOperator).length < 1
          ) {
            expression = [
              {
                attribute:
                  this.state.first === "expression"
                    ? this.state.attributes1
                    : this.state.attributes2,
                relationalOperator:
                  this.state.first === "expression"
                    ? this.state.relationalOperatorExp1
                    : this.state.relationalOperatorExp2,
                value:
                  this.state.first === "expression"
                    ? this.state.value1.name
                    : this.state.value2.name,
              },
            ];
          } else if (
            this.state.first === "expression" &&
            this.state.second === "expression" &&
            this.state.logicalOperator &&
            Object.keys(this.state.logicalOperator).length > 0
          ) {
            expression = [
              {
                attribute: this.state.attributes1,
                relationalOperator: this.state.relationalOperatorExp1,
                value: this.state.value1.name,
              },
              {
                attribute: this.state.attributes2,
                relationalOperator: this.state.relationalOperatorExp2,
                value: this.state.value2.name,
              },
            ];
          } else {
            expression = [
              {
                attribute:
                  this.state.first === "expression"
                    ? this.state.attributes1
                    : this.state.attributes2,
                relationalOperator:
                  this.state.first === "expression"
                    ? this.state.relationalOperatorExp1
                    : this.state.relationalOperatorExp2,
                value:
                  this.state.first === "expression"
                    ? this.state.value1.name
                    : this.state.value2.name,
              },
            ];
          }
        } else if (
          (this.state.first === "group" || this.state.second === "group") &&
          this.state.logicalOperator &&
          Object.keys(this.state.logicalOperator).length > 0
        ) {
          expression = [
            {
              attribute:
                this.state.first === "expression"
                  ? this.state.attributes1
                  : this.state.attributes2,
              relationalOperator:
                this.state.first === "expression"
                  ? this.state.relationalOperatorExp1
                  : this.state.relationalOperatorExp2,
              value:
                this.state.first === "expression"
                  ? this.state.value1.name
                  : this.state.value2.name,
            },
          ];
        } else if (
          this.state.first === "group" &&
          this.state.logicalOperator &&
          Object.keys(this.state.logicalOperator).length < 1
        ) {
          expression = [];
        }

        let firstParent = null;
        let secondParent = null;

        if (this.state.first === "group" && this.state.second === "group") {
          firstParent = this.state.firstParent
            ? this.state.firstParent
            : this.state.groupParent1;
          secondParent = this.state.secondParent
            ? this.state.secondParent
            : this.state.groupParent2;
        } else if (
          this.state.first === "group" &&
          this.state.second === "expression"
        ) {
          firstParent = this.state.firstParent
            ? this.state.firstParent
            : this.state.groupParent1;
          secondParent = null;
        } else if (
          this.state.first === "expression" &&
          this.state.second === "group"
        ) {
          secondParent = this.state.secondParent
            ? this.state.secondParent
            : this.state.groupParent2;
          firstParent = null;
        } else if (
          this.state.first === "expression" &&
          this.state.second === "expression"
        ) {
          firstParent = null;
          secondParent = null;
        } else if (
          (this.state.first === "group" || this.state.first === "expression") &&
          this.state.logicalOperator &&
          Object.keys(this.state.logicalOperator).length < 1
        ) {
          secondParent = null;
        }
        const group = {
          id: this.props.data.id,
          name: this.state.name,
          description: this.state.description,
          expressions:
            this.state.first === "group" && this.state.second === "group"
              ? []
              : expression,
          firstParent: firstParent,
          secondParent: secondParent,
          logicalOperator:
            this.state.logicalOperator &&
            Object.keys(this.state.logicalOperator).length > 0
              ? this.state.logicalOperator
              : null,
        };
        return this.props.previewProducts(group);
      } else {
        if (
          this.state.logicalOperator &&
          Object.keys(this.state.logicalOperator).length < 1
        ) {
          //checks if only top form is used
          const expression = [
            {
              attribute:
                this.state.first === "expression"
                  ? this.state.attributes1
                  : this.state.attributes2,
              relationalOperator:
                this.state.first === "expression"
                  ? this.state.relationalOperatorExp1
                  : this.state.relationalOperatorExp2,
              value:
                this.state.first === "expression"
                  ? this.state.value1.name
                  : this.state.value2.name,
            },
          ];

          const group = {
            name: this.state.name,
            description: this.state.description,
            expressions: this.state.first === "group" ? [] : expression,
            firstParent:
              this.state.first === "group" ? this.state.groupParent1 : null,
            secondParent: this.state.groupParent2,
            logicalOperator: null,
          };
          return this.props.previewProducts(group);
        } else {
          //if both forms are used
          const expression =
            this.state.first === "expression" &&
            this.state.second === "expression" &&
            this.state.logicalOperator &&
            Object.keys(this.state.logicalOperator).length > 0
              ? [
                  {
                    attribute: this.state.attributes1,
                    relationalOperator: this.state.relationalOperatorExp1,
                    value: this.state.value1.name,
                  },
                  {
                    attribute: this.state.attributes2,
                    relationalOperator: this.state.relationalOperatorExp2,
                    value: this.state.value2.name,
                  },
                ]
              : [
                  {
                    attribute:
                      this.state.first === "expression"
                        ? this.state.attributes1
                        : this.state.attributes2,
                    relationalOperator:
                      this.state.first === "expression"
                        ? this.state.relationalOperatorExp1
                        : this.state.relationalOperatorExp2,
                    value:
                      this.state.first === "expression"
                        ? this.state.value1.name
                        : this.state.value2.name,
                  },
                ];

          let firstParent = null;
          if (
            this.state.first === "expression" &&
            this.state.second === "group"
          ) {
            firstParent = this.state.groupParent2;
          } else if (this.state.first === "group") {
            firstParent = this.state.groupParent1;
          } else firstParent = null;

          let secondParent = null;
          if (
            this.state.first === "expression" &&
            this.state.second === "group"
          ) {
            secondParent = null;
          } else if (this.state.second === "group") {
            secondParent = this.state.groupParent2;
          } else secondParent = null;

          const group = {
            name: this.state.name,
            description: this.state.description,
            expressions:
              this.state.first === "group" && this.state.second === "group"
                ? []
                : expression,
            firstParent: firstParent,
            secondParent: secondParent,
            logicalOperator:
              this.state.logicalOperator &&
              Object.keys(this.state.logicalOperator).length > 0
                ? this.state.logicalOperator
                : null,
          };
          return this.props.previewProducts(group);
        }
      }
    }
  };

  renderSummaryTop = () => {
    return (
      <>
        <p className="flex-row h-8 text-grey-dark">
          Created By:{" "}
          <span className="float-right text-teal">{this.state.createdBy}</span>
        </p>
        <p className="flex-row h-8 text-grey-dark">
          Created:{" "}
          <span className="float-right text-teal">
            {this.props.formState === FORM_ACTION.STATE_EDIT
              ? this.state.created
              : null}
          </span>
        </p>
        <p className="flex-row flex h-8 text-grey-dark items-center relative">
          Items:{" "}
          <i
            onClick={this.showProductPreview}
            className="material-icons cursor-pointer items-center text-teal pl-2"
          >
            {!this.state.showPreview ? "visibility" : "visibility_off"}
          </i>
          <span className="w-full text-right text-teal pl-3 ">
            {this.state.showPreview ? (
              this.props.previewLoading ? (
                <div className="max-h-4 absolute right-0 top-0">
                  {SmallLoadingSpinner}
                </div>
              ) : (
                this.props.productPreview
              )
            ) : null}
          </span>
        </p>
      </>
    );
  };

  render() {
    const { firstParent, secondParent } = this.state;

    const fpExpression = firstParent
      ? this.state.firstParent.expressions
      : null;
    const fpAttribute =
      fpExpression && Object.keys(fpExpression).map((key) => fpExpression[key]);
    const spExpression = secondParent && secondParent.expressions;
    const spAttribute =
      spExpression && Object.keys(spExpression).map((key) => spExpression[key]);

    return (
      <FormContentWrapper
        formConfig={{
          formTitle:
            this.props.formState === FORM_ACTION.STATE_EDIT
              ? "Edit Group"
              : "Create Group",
          buttonLabel:
            this.props.formState === FORM_ACTION.STATE_EDIT
              ? "UPDATE"
              : "CREATE",
        }}
        formName="demandOverview"
        handleSubmit={this.handleSubmit}
        handleCancel={this.handleCancel}
        canSave={this.state.canSave}
      >
        <div className="flex mb-6 my-8 xxl:px-4">
          <div className="w-1/5 mr-10">
            <InputText
              width="w-full"
              label="Name"
              value={this.state.name}
              name="name"
              onChange={(event) => this.handleChange(event)}
              placeholder="Name"
              required
              error={this.state.nameError}
            />
          </div>
          <div className="w-3/5 ">
            <InputText
              width="w-full"
              label="Description"
              name="description"
              value={this.state.description}
              onChange={(event) => this.handleChange(event)}
              placeholder="Description"
              required
              error={this.state.descriptionError}
            />
          </div>
          <div className="w-1/5 ml-5 flex-col text-sm ml-10">
            {this.renderSummaryTop()}
          </div>
        </div>
        {this.state.first === "group" ? (
          <SelectGroup
            attributeOptions={this.state.attributeOption}
            parent={this.state.firstParent}
            attribute={fpAttribute}
            groupOptions={this.state.groupOptions}
            renderSummary={this.renderSummary}
            handleSelected={this.handleSelected}
            active={this.state.first}
            index={1}
            relationalOperator={this.state.relationalOperator1}
            handleActive={this.handleActive}
            formState={this.props.formState}
            created={this.state.fpCreated}
            createdBy={this.state.fpCreatedBy}
            group={this.state.groupParent1}
            error={this.state.groupParent1Error}
          />
        ) : (
          <SelectExpression
            data={this.props.groups}
            attributeOptions={this.state.attributeOptions}
            attribute={this.state.attributes1}
            handleSelected={this.handleSelected}
            handleActive={this.handleActive}
            items={this.state.items}
            value={this.state.value1}
            active={this.state.first}
            handleChange={(e) => this.handleChange(e)}
            relationalOperatorOptions={this.state.relationalOperatorOptions}
            relationalOperator={this.state.relationalOperatorExp1}
            formState={this.props.formState}
            brandOptions={this.props.brand}
            tagOptions={this.props.tags}
            categoryOptions={this.props.categories}
            index={1}
            id={1}
            created={this.state.firstExpCreated}
            updated={this.state.firstExpUpdated}
            errorAttribute={this.state.attributes1Error}
            errorOperator={this.state.relationalOperatorExp1Error}
            errorValue={this.state.value1Error}
          />
        )}
        <div className=" flex flex-row justify-center w-2/3 text-center font-bold my-10 py-4">
          <span className="text-teal">
            <SingleSelectDropdown
              data={this.props.groups}
              width="w-5/6"
              options={this.state.logicalOperatorOptions}
              selected={
                this.state.logicalOperator ? this.state.logicalOperator : {}
              }
              name="logicalOperator"
              handleSelect={this.handleSelected}
              placeholder=""
            />
          </span>
        </div>
        {(this.state.logicalOperator &&
          Object.keys(this.state.logicalOperator).length < 1) ||
        this.state.showMore === false ? null : (
          <>
            {this.state.second === "group" ? (
              <SelectGroup
                attributeOptions={this.state.attributeOptions}
                groupOptions={this.state.groupOptions}
                attribute={spAttribute}
                active={this.state.second}
                parent={this.state.secondParent}
                renderSummary={this.renderSummary}
                handleSelected={this.handleSelected}
                handleActive={this.handleActive}
                formState={this.props.formState}
                group={this.state.groupParent2}
                relationalOperator={this.state.relationalOperator2}
                created={this.state.spCreated}
                createdBy={this.state.spCreatedBy}
                index={2}
              />
            ) : (
              <SelectExpression
                data={this.props.groups}
                attributeOptions={this.state.attributeOptions}
                attribute={this.state.attributes2}
                active={this.state.second}
                handleSelected={this.handleSelected}
                handleActive={this.handleActive}
                items={this.state.items}
                value={this.state.value2}
                handleChange={(e) => this.handleChange(e)}
                relationalOperatorOptions={this.state.relationalOperatorOptions}
                relationalOperator={this.state.relationalOperatorExp2}
                formState={this.props.formState}
                brandOptions={this.props.brand}
                tagOptions={this.props.tags}
                categoryOptions={this.props.categories}
                created={this.state.secondExpCreated}
                updated={this.state.secondExpUpdated}
                id={2}
                index={2}
                errorAttribute={this.state.attributes2Error}
                errorOperator={this.state.relationalOperatorExp2Error}
                errorValue={this.state.value2Error}
              />
            )}
          </>
        )}
      </FormContentWrapper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createNewGroup: createNewGroup,
      closeFormpanel: closeFormpanel,
      updatedGroup: updatedGroup,
      previewProducts: previewProducts,
      productsPreviewLoading: productsPreviewLoading,
      getCategoriesByMarket: getCategoriesByMarket,
      getBrandsByMarket: getBrandsByMarket,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    closeFormpanel: closeFormpanel,
    formState: state.formpanel.formState,
    updatedGroup: updatedGroup,
    groups: state.groups.groupsNoPag,
    // page: state.tablePagination.groupPage,
    // rowSize: state.tablePagination.groupRowSize,
    // sortingAttr: state.tablePagination.groupSortingAttr,
    // sortingDirection: state.tablePagination.groupSortingDirection,
    config: state.groups.config,
    tags: state.tags.tagList,
    brand: state.products.brands,
    categories: state.products.categories,
    productPreview: state.groups.previewProducts,
    previewLoading: state.groups.previewLoading,
    currentPage: state.groups.currentPage,
    rowSize: state.groups.rowSize,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Groups);
