import React from "react";

const NotFound = () => {
  return (
    <div className="flex items-center h-full w-full">
      <h1 className="text-center w-full font-extrabold text-5xl text-grey-dark">
        404 Page Not Found
      </h1>
    </div>
  );
};

export default NotFound;
