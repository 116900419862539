import React, { Component } from "react";
import { connect } from "react-redux";
import SingleSelectDropdown from "components/SingleSelectDropdown";
import { InputNumber } from "grand_components";

import {
  getDropdownOptions,
  getProductPerformance,
} from "ducks/productPerformanceAnalytics";

import moment from "moment";

class ProductPerformanceAnalyticsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedShow: {},
      selectedPeriod: {},
      click: "",
      selectedClicks: {},
      conversionRate: "",
      selectedConversionRate: {},
      revenue: "",
      selectedRevenue: {},
      selectedShowError: false,
    };
  }

  componentDidMount() {
    this.props.getDropdownOptions();
  }

  handleSelected = (name, selected) => {
    this.setState({ [name]: selected, [name + "Error"]: false });
  };

  handleChange = (name, event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleValidation = () => {
    let error = false;
    if (Object.keys(this.state.selectedShow).length < 1) {
      error = true;
      this.setState({
        selectedShowError: true,
      });
    }
    return error;
  };

  clearAll = () => {
    this.setState({
      selectedShow: {},
      selectedPeriod: {},
      click: "",
      selectedClicks: {},
      conversionRate: "",
      selectedConversionRate: {},
      revenue: "",
      selectedRevenue: {},
      selectedShowError: false,
    });
  };

  filterProducts = () => {
    if (!this.handleValidation()) {
      let latestStart = "";
      latestStart =
        this.state.selectedPeriod.code === 1
          ? moment.utc().subtract(1, "days").format("YYYY-MM-DD") + "T00:00:00Z"
          : this.state.selectedPeriod.code === 3
          ? moment.utc().subtract(3, "days").format("YYYY-MM-DD") + "T00:00:00Z"
          : this.state.selectedPeriod.code === 7
          ? moment.utc().subtract(7, "days").format("YYYY-MM-DD") + "T00:00:00Z"
          : this.state.selectedPeriod.code === 14
          ? moment.utc().subtract(14, "days").format("YYYY-MM-DD") +
            "T00:00:00Z"
          : "";

      let latestEnd =
        moment.utc().subtract(1, "days").format("YYYY-MM-DD") + "T23:59:59Z";

      let previousStart = "";
      previousStart =
        this.state.selectedPeriod.code === 1
          ? moment.utc().subtract(2, "days").format("YYYY-MM-DD") + "T00:00:00Z"
          : this.state.selectedPeriod.code === 3
          ? moment.utc().subtract(12, "days").format("YYYY-MM-DD") +
            "T00:00:00Z"
          : this.state.selectedPeriod.code === 7
          ? moment.utc().subtract(14, "days").format("YYYY-MM-DD") +
            "T00:00:00Z"
          : this.state.selectedPeriod.code === 14
          ? moment.utc().subtract(28, "days").format("YYYY-MM-DD") +
            "T00:00:00Z"
          : "";

      let previousEnd = "";

      previousEnd =
        this.state.selectedPeriod.code === 1
          ? moment.utc().subtract(2, "days").format("YYYY-MM-DD") + "T23:59:59Z"
          : this.state.selectedPeriod.code === 3
          ? moment.utc().subtract(4, "days").format("YYYY-MM-DD") + "T23:59:59Z"
          : this.state.selectedPeriod.code === 7
          ? moment.utc().subtract(8, "days").format("YYYY-MM-DD") + "T23:59:59Z"
          : this.state.selectedPeriod.code === 14
          ? moment.utc().subtract(15, "days").format("YYYY-MM-DD") +
            "T23:59:59Z"
          : "";

      let queryString = "";
      let previousEndQuery = "&previousEnd=" + previousEnd;
      let previousStartQuery = "&previousStart=" + previousStart;
      let latestEndQuery = "&latestEnd=" + latestEnd;
      let latestStartQuery = "&latestStart=" + latestStart;
      let attrId = "&attribute=" + this.state.selectedShow.name.toLowerCase();

      let click =
        "&clicksOperator=" +
        this.state.selectedClicks.name +
        "&clicks=" +
        this.state.click;
      let conversionRate =
        "&conversionOperator=" +
        this.state.selectedConversionRate.name +
        "&conversion=" +
        this.state.conversionRate;
      let revenue =
        "&revenueOperator=" +
        this.state.selectedRevenue.name +
        "&revenue=" +
        this.state.revenue;

      if (this.state.selectedShow.id) {
        queryString = queryString.concat(attrId);
      }
      if (this.state.selectedPeriod.id) {
        queryString = queryString.concat(
          previousEndQuery,
          previousStartQuery,
          latestEndQuery,
          latestStartQuery
        );
      }
      if (this.state.click.length > 0 && this.state.selectedClicks.id) {
        queryString = queryString.concat(click);
      }
      if (
        this.state.conversionRate.length > 0 &&
        this.state.selectedConversionRate.id
      ) {
        queryString = queryString.concat(conversionRate);
      }
      if (this.state.revenue.length > 0 && this.state.selectedRevenue.id) {
        queryString = queryString.concat(revenue);
      }
      this.props.getProductPerformance(queryString, this.props.rowSize);
    } else return;
  };

  render() {
    return (
      <div className="bg-white w-full">
        <div className="flex-row flex w-full">
          <div className="px-2">
            <SingleSelectDropdown
              placeholder="Show"
              dropdownHeight="h-32"
              handleSelect={this.handleSelected}
              label="Show"
              name="selectedShow"
              options={this.props.attributes}
              selected={this.state.selectedShow}
              required={true}
              error={this.state.selectedShowError}
            />
          </div>
          <div className="px-2">
            <SingleSelectDropdown
              placeholder="Period"
              width="w-32"
              dropdownHeight="h-32"
              handleSelect={this.handleSelected}
              label="Period"
              name="selectedPeriod"
              options={this.props.config.dates}
              selected={this.state.selectedPeriod}
            />
          </div>

          {this.state.selectedPeriod.code && (
            <div className="flex flex-row font-sans font-light xxl:text-xs text-xxs items-end">
              <div className="flex flex-col px-4">
                <div className="text-teal-dark">Latest Start Time</div>
                <div className="text-grey-darker font-normal">
                  {this.state.selectedPeriod.code === 1 &&
                    moment().local().subtract(1, "days").format("L") + " 00:00"}
                  {this.state.selectedPeriod.code === 3 &&
                    moment().local().subtract(3, "days").format("L") + " 00:00"}
                  {this.state.selectedPeriod.code === 7 &&
                    moment().local().subtract(7, "days").format("L") + " 00:00"}
                  {this.state.selectedPeriod.code === 14 &&
                    moment().local().subtract(14, "days").format("L") +
                      " 00:00"}
                </div>
              </div>
              <div className="flex flex-col px-4">
                <div className="text-teal-dark">Latest End Time</div>
                <div className="text-grey-darker font-normal">
                  {moment().local().subtract(1, "days").format("L") + " 23:59"}
                </div>
              </div>

              <div className="flex flex-col px-4">
                <div className="text-teal-dark">Prev Start Time</div>
                <div className="text-grey-darker font-normal">
                  {this.state.selectedPeriod.code === 1 &&
                    moment().local().subtract(2, "days").format("L") + " 00:00"}
                  {this.state.selectedPeriod.code === 3 &&
                    moment().local().subtract(12, "days").format("L") +
                      " 00:00"}
                  {this.state.selectedPeriod.code === 7 &&
                    moment().local().subtract(14, "days").format("L") +
                      " 00:00"}
                  {this.state.selectedPeriod.code === 14 &&
                    moment().local().subtract(28, "days").format("L") +
                      " 00:00"}
                </div>
              </div>
              <div className="flex flex-col px-4">
                <div className="text-teal-dark">Prev End Time</div>
                <div className="text-grey-darker font-normal">
                  {this.state.selectedPeriod.code === 1 &&
                    moment().local().subtract(2, "days").format("L") + " 23:59"}
                  {this.state.selectedPeriod.code === 3 &&
                    moment().local().subtract(4, "days").format("L") + " 23:59"}
                  {this.state.selectedPeriod.code === 7 &&
                    moment().local().subtract(8, "days").format("L") + " 23:59"}
                  {this.state.selectedPeriod.code === 14 &&
                    moment().local().subtract(15, "days").format("L") +
                      " 23:59"}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex-row flex w-full pb-2">
          <div className="flex flex-col pt-2">
            <div className="text-xs font-light text-grey-dark font-sans pl-2">
              Clicks
            </div>
            <div className="px-2 flex flex-row">
              <SingleSelectDropdown
                width="w-16 mr-2"
                dropdownHeight="h-32"
                handleSelect={this.handleSelected}
                name="selectedClicks"
                options={this.props.config.operators}
                selected={this.state.selectedClicks}
              />
              <InputNumber
                width="w-16"
                onChange={(event) => this.handleChange("click", event)}
                value={this.state.click}
              />
            </div>
          </div>
          <div className="flex flex-col pt-2">
            <div className="text-xs font-light text-grey-dark font-sans pl-2">
              Conversion Rate
            </div>
            <div className="px-2 flex flex-row">
              <SingleSelectDropdown
                width="w-16 mr-2"
                dropdownHeight="h-32"
                handleSelect={this.handleSelected}
                name="selectedConversionRate"
                options={this.props.config.operators}
                selected={this.state.selectedConversionRate}
              />
              <InputNumber
                width="w-16"
                onChange={(event) => this.handleChange("conversionRate", event)}
                value={this.state.conversionRate}
              />
              <div className="self-center text-grey-dark font-sans font-light pl-2 pt-2">
                %
              </div>
            </div>
          </div>
          <div className="flex flex-col pt-2">
            <div className="text-xs font-light text-grey-dark font-sans pl-2">
              Revenue
            </div>
            <div className="px-2 flex flex-row">
              <SingleSelectDropdown
                width="w-16 mr-2"
                dropdownHeight="h-32"
                handleSelect={this.handleSelected}
                name="selectedRevenue"
                options={this.props.config.operators}
                selected={this.state.selectedRevenue}
              />
              <InputNumber
                width="w-16"
                onChange={(event) => this.handleChange("revenue", event)}
                value={this.state.revenue}
              />
            </div>
          </div>

          <div
            className="ml-12 self-end flex items-center xxl:py-2 xxl:px-3 py-1 px-2 h-10 xxl:text-sm text-xs text-teal-dark hover:text-teal"
            onClick={() => this.clearAll()}
          >
            <span className="cursor-pointer font-light font-sans">CLEAR</span>
          </div>

          <div
            className="ml-4 xxl:py-2 self-end flex items-center xxl:px-3 py-1 font-sans px-2 h-10 bg-teal-dark hover:bg-teal xxl:text-sm text-xs text-white rounded-sm cursor-pointer"
            onClick={() => this.filterProducts()}
          >
            GET REPORT
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.productPerformanceAnalytics.config,
    attributes: state.productPerformanceAnalytics.attributes,
    productPerformancePage: state.tablePagination.productPerformancePage,
    productPerformanceRowSize: state.tablePagination.productPerformanceRowSize,
    productPerformanceTotalPages:
      state.tablePagination.productPerformanceTotalPages,
    productPerformanceSortingAttr:
      state.tablePagination.productPerformanceSortingAttr,
    productPerformanceSortingDirection:
      state.tablePagination.productPerformanceSortingDirection,
    rowSize: state.productPerformanceAnalytics.rowSize,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDropdownOptions: () => dispatch(getDropdownOptions()),
    getProductPerformance: (queryString, rowSize) =>
      dispatch(getProductPerformance(queryString, rowSize)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductPerformanceAnalyticsFilter);
