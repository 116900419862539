import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal } from "ducks/modal";
import { closeFormpanel } from "ducks/formpanel";
import { ModalContentWrapper } from "containers/Modals/ModalContainer/ModalContentWrapper";
import { submitTableInput } from "ducks/products";

class TableInputSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canContinue: true
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEnterKeySubmit);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEnterKeySubmit);
  }

  handleEnterKeySubmit = event => {
    if (event.which === 13 || event.keyCode === 13) {
      this.props.submitTableInput(
        this.props.data.row.id,
        this.props.data.col,
        this.props.data.value
      );
      this.props.closeModal();
    }
  };

  handleContinue = () => {
    this.props.submitTableInput(
      this.props.data.row.id,
      this.props.data.col,
      this.props.data.value
    );
    this.props.closeModal();
  };

  render() {
    const modalConfig = {
      buttonOnClick: this.handleContinue,
      buttonLabel: "Update",
      title: "Just Checking",
      hasBottomButtons: true,
      canSave: this.state.canContinue,
      closeText: "Cancel",
      handleClose: this.props.closeModal,
      warning: false
    };
    return (
      <ModalContentWrapper modalConfig={modalConfig} size={this.props.size}>
        <div className="w-full text-center h-full flex flex-col justify-between py-4">
          <div className="font-sans font-light text-xs ">
            <span className="text-grey-darker text-center">
              Are you sure you want to update the {this.props.data.col} of
            </span>
            <div className="text-teal-dark px-4 py-1 font-medium">
              {this.props.data.row.name}
            </div>
          </div>

          <div className="text-lg ">
            <div className="text-grey-darker uppercase">
              <span className="text-sm">NEW {this.props.data.col}:</span>{" "}
              <span className="font-medium px-2">{this.props.data.value}</span>
            </div>
          </div>

          <div className="text-xs font-light px-4">
            <span className="text-red-dark font-medium">Warning: </span>Please
            keep in mind that you may have rounding rules. These rules will
            affect the resulting table data.
          </div>
        </div>
      </ModalContentWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeFormpanel: () => dispatch(closeFormpanel()),
    closeModal: () => dispatch(closeModal()),
    submitTableInput: (id, col, value) => {
      dispatch(submitTableInput(id, col, value));
    }
  };
};

export default connect(null, mapDispatchToProps)(TableInputSubmit);
