import React from "react";
export default () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 27V42.902C15.8861 44.2482 19.1053 45 22.5 45C23.004 45 23.5042 44.9834 24 44.9508V27H44.5499C44.8451 25.546 45 24.0411 45 22.5C45 20.24 44.6668 18.0579 44.0469 16H24V0.0492068C23.5042 0.016574 23.004 0 22.5 0C19.1053 0 15.8861 0.751803 13 2.098V16H0.953141C0.333195 18.0579 0 20.24 0 22.5C0 24.0411 0.154936 25.546 0.450082 27H13Z"
      fill="white"
    />
    <path
      d="M22 0.00543213C19.5501 0.0588464 17.1968 0.503827 15 1.28028V18H0.450082C0.154936 19.454 0 20.9589 0 22.5C0 23.345 0.0465832 24.1792 0.137321 25H15V43.7197C17.1968 44.4961 19.5501 44.9411 22 44.9945V25H44.8627C44.9534 24.1792 45 23.345 45 22.5C45 20.9589 44.8451 19.454 44.5499 18H22V0.00543213Z"
      fill="#002469"
    />
    <path
      d="M24 27H44.5499C42.5618 36.7945 34.2111 44.2786 24 44.9508V27Z"
      fill="#BA122B"
    />
    <path
      d="M24 16H44.0469C41.3964 7.20178 33.504 0.674927 24 0.0491943V16Z"
      fill="#BA122B"
    />
    <path
      d="M13 2.09796V16H0.953125C2.69617 10.2142 6.70587 5.41058 11.9501 2.62158C12.2948 2.43823 12.6449 2.26361 13 2.09796Z"
      fill="#BA122B"
    />
    <path
      d="M0.450073 27H13V42.902C12.9941 42.8992 12.9882 42.8965 12.9823 42.8937C6.62286 39.9207 1.8833 34.0607 0.450073 27Z"
      fill="#BA122B"
    />
  </svg>
);
