import axios from "axios";
import { setSelectedMenuItem } from "ducks/sidepanel";
import { openModal } from "ducks/modal";

import * as MODAL_TYPE from "utils/modal-types";

const SET_MARKET_LIST = "SET_MARKET_LIST";
const SET_LOADING = "SET_LOADING";
const SET_SHOW_NO_DATA = "SET_SHOW_NO_DATA";

export const getMarkets = () => {
  let id = [];
  let name = [];
  let domain = [];
  let country = [];
  return (dispatch) => {
    dispatch(setLoading(true));
    return axios
      .get(process.env.REACT_APP_API_HOST + "/price-optimisation/markets")
      .then((res) => {
        dispatch(setLoading(false));
        if (res.data.length > 1) {
          dispatch(setMarkets(res.data));
          if (
            !localStorage.getItem("name") ||
            !localStorage.getItem("market") ||
            !localStorage.getItem("domain") ||
            !localStorage.getItem("country")
          ) {
            dispatch(openModal([], MODAL_TYPE.MARKETS, MODAL_TYPE.SMALL));
          } else return;
        } else {
          res.data.map((data) => {
            id = data.id;
            name = data.name;
            domain = data.channels[0].domain;
            country = data.name;
          });
          dispatch(setMarkets(res.data));

          dispatch(
            setSelectedMenuItem(
              id,
              name,
              domain,
              country,
              localStorage.getItem("features"),
              undefined
            )
          );
        }
      })
      .catch((err) => {
        // handleError(err);
        console.log(err);
        dispatch(setLoading(false));
        dispatch(setShowNoData(true));
      });
  };
};

const setMarkets = (data) => {
  return {
    type: SET_MARKET_LIST,
    markets: data,
  };
};

const setLoading = (value) => {
  return {
    type: SET_LOADING,
    loaing: value,
  };
};

const setShowNoData = (value) => {
  return {
    type: SET_SHOW_NO_DATA,
    showNoData: value,
  };
};

const initialState = {
  markets: [],
  loading: true,
  showNoData: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MARKET_LIST:
      return {
        ...state,
        markets: action.markets,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_SHOW_NO_DATA:
      return {
        ...state,
        showNoData: action.showNoData,
      };
    default:
      return state;
  }
}
