import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

export default class CampaignDatePickerWithoutDropdown extends Component {
  static propTypes = {
    /** Date Range - From and to dates/times ? - True False **/
    dateRange: PropTypes.bool,
    /** Show Time - True False **/
    showTime: PropTypes.bool,
    /** No Past Date - Cant select a date/time in the past **/
    noPastDate: PropTypes.bool,
    /** Save Date - Function to get dates to save **/
    saveDate: PropTypes.func,
    /** Has No End Date Option - A checkbox if there will be no end date - True False  **/
    hasNoEndDateOption: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      yyyymmdd: "",
      currentDay: "",
      selectedStartDate: null,
      selectedEndDate: null,
      selectedStartTime: null,
      selectedEndTime: null,
      ongoingChecked: false,
      canSave: false,
    };
  }
  componentDidMount() {
    //TODO: SET STATE WITH INCOMING DATA
    this.setState({
      yyyymmdd: moment().format("YYYY-MM") + "-01",
      currentDay: moment().format("YYYY-MM-DD"),
    });

    if (this.props.update) {
      this.setState({
        selectedStartDate: this.props.selectedStartDate,
        selectedStartTime: this.props.selectedStartTime,
        selectedEndDate: this.props.selectedEndDate,
        selectedEndTime: this.props.selectedEndTime,
      });
    }
  }

  clearAllData = () => {
    this.setState({
      dropdownOpen: false,
      selectedStartDate: null,
      selectedEndDate: null,
      selectedStartTime: null,
      selectedEndTime: null,
      ongoingChecked: false,
      canSave: false,
    });
  };

  handleToggle = () => {
    this.setState((prevSate) => ({
      dropdownOpen: !prevSate.dropdownOpen,
    }));
  };

  closeDropdown = () => {
    this.setState({
      dropdownOpen: false,
    });
  };

  handleOutsideClick = (e) => {
    if (this.singleDropdown.contains(e.target)) {
      return;
    } else {
      this.closeDropdown();
    }
  };

  changeYear = (direction) => {
    let yyyymmdd = this.state.yyyymmdd.split("-");
    let currentDay = this.state.currentDay.split("-");
    if (direction === "++") {
      yyyymmdd[0] = (parseInt(yyyymmdd[0]) + 1).toString();
    } else {
      if (currentDay[0] !== yyyymmdd[0]) {
        yyyymmdd[0] = (parseInt(yyyymmdd[0]) - 1).toString();
      }
    }
    yyyymmdd = yyyymmdd.join("-");
    this.setState({ yyyymmdd: yyyymmdd.toString() });
  };

  changeMonth = (direction) => {
    let yyyymmdd = this.state.yyyymmdd.split("-");
    let currentDay = this.state.currentDay.split("-");

    if (direction === "++") {
      if (yyyymmdd[1] === "12") {
        yyyymmdd[1] = "01";
        yyyymmdd[0] = (parseInt(yyyymmdd[0]) + 1).toString();
      } else {
        let month = (parseInt(yyyymmdd[1]) + 1).toString();
        yyyymmdd[1] = month < 10 ? "0" + month : month;
      }
    } else {
      if (
        parseInt(currentDay[1]) === parseInt(yyyymmdd[1]) &&
        currentDay[0] === yyyymmdd[0]
      ) {
        return;
      } else {
        if (yyyymmdd[1] === "01") {
          yyyymmdd[1] = "12";
          yyyymmdd[0] = (parseInt(yyyymmdd[0]) - 1).toString();
        } else {
          let month = (parseInt(yyyymmdd[1]) - 1).toString();
          yyyymmdd[1] = month < 10 ? "0" + month : month;
        }
      }
    }

    yyyymmdd = yyyymmdd.join("-");
    this.setState({ yyyymmdd: yyyymmdd.toString() });
  };

  setSelectedDate = (date) => {
    let startDate =
      this.state.selectedStartDate &&
      this.state.selectedStartDate.split("-").join("");
    let incomingDate = date.split("-").join("");
    if (this.state.ongoingChecked || !this.props.dateRange) {
      this.setState({ selectedStartDate: date }, () => this.saveDates());
    } else {
      if (this.state.currentDay === date && this.state.selectedStartTime) {
        this.setState({ selectedStartTime: null, selectedEndTime: null }, () =>
          this.saveDates()
        );
      }
      if (!this.state.selectedStartDate) {
        this.setState({ selectedStartDate: date }, () => this.saveDates());
      } else if (startDate === incomingDate) {
        this.setState({ selectedEndDate: date }, () => this.saveDates());
      } else if (
        this.state.selectedStartDate &&
        incomingDate > startDate &&
        !this.state.selectedEndDate
      ) {
        this.setState({ selectedEndDate: date }, () => this.saveDates());
      } else {
        this.setState({ selectedStartDate: date, selectedEndDate: null }, () =>
          this.saveDates()
        );
      }
    }
  };

  setSelectedTime = (time) => {
    if (this.state.ongoingChecked || !this.props.dateRange) {
      this.setState({ selectedStartTime: time }, () => this.saveDates());
    } else {
      if (this.state.selectedStartTime && this.state.selectedEndTime) {
        this.setState({ selectedStartTime: null, selectedEndTime: null }, () =>
          this.saveDates()
        );
      } else {
        if (!this.state.selectedStartTime) {
          this.setState({ selectedStartTime: time }, () => this.saveDates());
        } else if (this.state.selectedStartTime && this.state.selectedEndTime) {
          this.setState(
            { selectedStartTime: time, selectedEndTime: null },
            () => this.saveDates()
          );
        } else if (this.state.selectedStartTime) {
          this.setState({ selectedEndTime: time }, () => this.saveDates());
        }
      }
    }
  };

  saveDates = () => {
    this.props.saveDate(
      this.state.selectedStartDate,
      this.state.selectedStartTime,
      this.state.selectedEndDate,
      this.state.selectedEndTime
    );
  };

  render() {
    let dateRange = [];
    if (this.state.selectedStartDate && this.state.selectedEndDate) {
      let startingDate = this.state.selectedStartDate;
      let endingDate = this.state.selectedEndDate;

      while (
        moment(startingDate, "YYYY-MM-DD").isSameOrBefore(
          moment(endingDate, "YYYY-MM-DD")
        )
      ) {
        dateRange.push(startingDate);
        startingDate = moment(startingDate, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
      }
    }

    const getFirstDay = (d) => {
      const indexOfDay = moment(d, "YYYY-MM-DD").day();
      return indexOfDay;
    };

    const getHowManyDays = (d) => {
      return moment(d, "YYYY-MM").daysInMonth();
    };

    const days = ["S", "M", "T", "W", "T", "F", "S"];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dates = [];

    let day = this.state.yyyymmdd;

    for (let i = 0; i < 35; i++) {
      if (i < getFirstDay(this.state.yyyymmdd)) {
        dates.push(null);
      } else if (
        parseInt(day.split("-")[2]) <= getHowManyDays(this.state.yyyymmdd) &&
        parseInt(day.split("-")[1]) ===
          parseInt(this.state.yyyymmdd.split("-")[1])
      ) {
        dates.push(day);
        day = moment(day, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
      } else {
        dates.push(null);
      }
    }

    const splitDate = this.state.yyyymmdd.split("-");
    const splitStartDate =
      this.state.selectedStartDate && this.state.selectedStartDate.split("-");

    const times = [];
    let time = "00:00";

    for (let i = 0; i < 96; i++) {
      times.push(time);
      time = moment(time, "HH:mm").add(15, "minutes").format("HH:mm");
    }

    let yyyymmdd = this.state.yyyymmdd.split("-");
    let currentDay = this.state.currentDay.split("-");
    return (
      <div
        className="flex flex-col"
        ref={(node) => (this.singleDropdown = node)}
      >
        <div
          className={` ${
            this.props.showTime ? "w-74" : "w-70"
          } flex flex-col bg-white `}
        >
          <div className="text-sm xxl:text-md flex flex-row w-full items-center pt-2 pb-2 pl-8 border-b-1 border-table-grey mb-2">
            <div className="text-teal-dark font-medium text-right pr-1 w-24">
              {this.state.selectedStartDate ? (
                this.state.selectedStartDate
              ) : (
                <span className="text-grey-light font-medium">YYYYMMDD</span>
              )}
            </div>
            {this.props.showTime && (
              <div className="text-teal font-normal w-8">
                {this.state.selectedStartTime ? (
                  this.state.selectedStartTime
                ) : (
                  <span className="text-grey-light font-normal">00:00</span>
                )}
              </div>
            )}

            {this.props.dateRange && (
              <>
                <div className="w-10 text-center pl-4 ml-1 text-grey-dark text-xxs font-medium">
                  TO
                </div>

                <div className="text-blue font-medium w-24 text-right pr-1">
                  {this.state.selectedEndDate
                    ? this.state.selectedEndDate
                    : !this.state.ongoingChecked && (
                        <span className="text-grey-light font-medium ">
                          YYYYMMDD
                        </span>
                      )}
                  {this.state.ongoingChecked && "No End Date"}
                </div>
                {this.props.showTime && (
                  <div className="text-blue-light font-normal w-8">
                    {this.state.selectedEndTime
                      ? this.state.selectedEndTime
                      : !this.state.ongoingChecked && (
                          <span className="text-grey-light font-normal ">
                            00:00
                          </span>
                        )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex flex-row pl-4">
            <div
              className={`bg-white ${
                this.props.showTime ? "w-2/3" : "w-full pr-4"
              }   flex flex-col font-sans text-grey-darker`}
            >
              <div className="flex flex-row w-full justify-center pb-4">
                {parseInt(currentDay[1]) === parseInt(yyyymmdd[1]) &&
                currentDay[0] === yyyymmdd[0] ? (
                  <>
                    <div className="px-2 cursor-not-allowed h-full flex items-center justify-center">
                      <i className="material-icons text-xl inline-block text-grey-light ">
                        first_page
                      </i>
                    </div>
                    <div className="pr-4 cursor-not-allowed h-full flex items-center justify-center">
                      <i className="material-icons text-xl text-grey-light ">
                        chevron_left
                      </i>
                    </div>
                  </>
                ) : parseInt(currentDay[1]) !== parseInt(yyyymmdd[1]) &&
                  currentDay[0] === yyyymmdd[0] ? (
                  <>
                    <div className="px-2 cursor-not-allowed h-full flex items-center justify-center">
                      <i className="material-icons text-xl inline-block text-grey-light ">
                        first_page
                      </i>
                    </div>
                    <div
                      className="pr-4 cursor-pointer h-full flex items-center justify-center"
                      onClick={() => this.changeMonth("--")}
                    >
                      <i className="material-icons text-xl hover:text-teal opacity-65 hover:opacity-100">
                        chevron_left
                      </i>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="px-2 cursor-pointer h-full flex items-center justify-center"
                      onClick={() => this.changeYear("--")}
                    >
                      <i className="material-icons text-xl hover:text-teal inline-block opacity-65 hover:opacity-100">
                        first_page
                      </i>
                    </div>
                    <div
                      className="pr-4 cursor-pointer h-full flex items-center justify-center"
                      onClick={() => this.changeMonth("--")}
                    >
                      <i className="material-icons text-xl hover:text-teal opacity-65 hover:opacity-100">
                        chevron_left
                      </i>
                    </div>
                  </>
                )}

                <div className=" text-xs text-teal font-medium w-5/6 text-center h-full flex items-center justify-center">
                  <span>
                    {
                      months[
                        splitDate[1] < 10
                          ? parseInt(splitDate[1][1]) - 1
                          : parseInt(splitDate[1]) - 1
                      ]
                    }
                  </span>
                  <span className="inline-block pl-2">{splitDate[0]}</span>
                </div>
                <div
                  className="pl-4 cursor-pointer h-full flex items-center justify-center"
                  onClick={() => this.changeMonth("++")}
                >
                  <i className="material-icons text-xl hover:text-teal opacity-65 hover:opacity-100">
                    chevron_right
                  </i>
                </div>
                <div
                  className="px-2 cursor-pointer h-full flex items-center justify-center"
                  onClick={() => this.changeYear("++")}
                >
                  <i className="material-icons text-xl hover:text-teal inline-block opacity-65 hover:opacity-100">
                    last_page
                  </i>
                </div>
              </div>
              <div className="flex flex-row">
                {days.map((day) => (
                  <div className="w-1/7 text-center text-grey-dark text-xs">
                    {day}
                  </div>
                ))}
              </div>
              <div className="flex flex-row flex-wrap">
                {dates.map((date) => {
                  let currentDateToInt = this.state.currentDay
                    .split("-")
                    .join("");
                  let dateToInt = date ? date.split("-").join("") : "";
                  let dateDay = date ? date.split("-")[2] : "";
                  let rangeContainsDay = false;
                  let dateLessThanCurrentDate = false;
                  if (parseInt(dateToInt) < parseInt(currentDateToInt)) {
                    dateLessThanCurrentDate = true;
                  }
                  if (dateRange.length > 0) {
                    dateRange.map((range) => {
                      if (date === range) {
                        return (rangeContainsDay = true);
                      }
                    });
                  }

                  return (
                    <div
                      onClick={() =>
                        !dateLessThanCurrentDate &&
                        this.setSelectedDate(
                          splitDate[0] + "-" + splitDate[1] + "-" + dateDay
                        )
                      }
                      className={`w-1/7 my-1 text-xs font-medium text-center flex justify-center items-center 

                        ${dateLessThanCurrentDate && "text-grey-light"}
                  
                  ${
                    rangeContainsDay &&
                    this.state.selectedStartDate !==
                      this.state.selectedEndDate &&
                    "bg-teal text-white "
                  }
                  ${
                    dateRange.length > 0 &&
                    dateRange[0] === date &&
                    this.state.selectedStartDate !==
                      this.state.selectedEndDate &&
                    "rounded-l-full "
                  } 

                  ${
                    dateRange.length > 0 &&
                    dateRange[dateRange.length - 1] === date &&
                    this.state.selectedStartDate !==
                      this.state.selectedEndDate &&
                    "rounded-r-full "
                  }
                    `}
                    >
                      <div
                        className={`h-5 w-5  flex justify-center items-center
                    ${
                      date === this.state.currentDay &&
                      dateRange.length < 1 &&
                      "bg-table-grey text-grey-dark rounded-full cursor-pointer"
                    }
                    ${dateLessThanCurrentDate && "cursor-not-allowed"}
                      ${
                        splitStartDate
                          ? parseInt(dateDay) === parseInt(splitStartDate[2]) &&
                            splitStartDate[1] === splitDate[1] &&
                            splitStartDate[0] === splitDate[0] &&
                            "bg-teal text-white  cursor-pointer"
                          : "cursor-pointer"
                      } 
                      ${
                        dateRange.length > 0
                          ? dateRange[0] === date
                            ? "rounded-full bg-teal-dark cursor-pointer"
                            : "cursor-pointer"
                          : "rounded-full cursor-pointer"
                      }
                    `}
                      >
                        {dateDay}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {this.props.showTime && (
              <>
                <div className="border-l-1 border-table-grey w-1 ml-3" />
                <div className="h-48 w-1/4 ml-1 text-xs text-grey-darker relative flex flex-col items-center">
                  <div className="absolute w-full pin-t ">
                    <div className="bg-white text-xs w-full flex justify-center items-center px-2 pt-1.5">
                      <div className="text-teal font-medium">Select Time</div>
                    </div>
                    <div className="h-4 gradient-fade-bottom" />
                  </div>

                  <div className="h-full px-4 overflow-y-auto flex flex-col py-8">
                    {times.map((t) => {
                      let currentTimeToInt = moment()
                        .format("hh:mm")
                        .split(":")
                        .join("");
                      let tToInt = t.split(":").join("");
                      let selectedStartTimeToInt =
                        this.state.selectedStartTime &&
                        this.state.selectedStartTime.split(":").join("");
                      let timeLessThanCurrentTime = false;
                      let timeLessThanSelectedStartTime = false;

                      let startDateEqualToCurrentDate =
                        this.state.selectedStartDate &&
                        this.state.selectedStartDate === this.state.currentDay;
                      let endDateEqualToCurrentDate =
                        this.state.selectedEndDate &&
                        this.state.selectedEndDate === this.state.currentDay;
                      if (parseInt(tToInt) < parseInt(currentTimeToInt)) {
                        timeLessThanCurrentTime = true;
                      }
                      if (
                        parseInt(tToInt) < parseInt(selectedStartTimeToInt) &&
                        endDateEqualToCurrentDate
                      ) {
                        timeLessThanSelectedStartTime = true;
                      }

                      if (
                        this.state.selectedEndDate &&
                        this.state.selectedStartDate &&
                        this.state.selectedStartTime &&
                        !endDateEqualToCurrentDate
                      ) {
                        timeLessThanCurrentTime = false;
                        timeLessThanSelectedStartTime = false;
                      }

                      return (
                        <div
                          onClick={() => {
                            if (
                              startDateEqualToCurrentDate &&
                              (timeLessThanCurrentTime ||
                                timeLessThanSelectedStartTime)
                            ) {
                              return;
                            } else {
                              this.setSelectedTime(t);
                            }
                          }}
                          className={`h-5 py-1 flex justify-center items-center text-xs mb-1 px-4 font-medium  rounded-full

                            ${
                              startDateEqualToCurrentDate &&
                              (timeLessThanCurrentTime ||
                                timeLessThanSelectedStartTime) &&
                              "cursor-not-allowed"
                            }
                  ${
                    this.state.selectedStartTime &&
                    t === this.state.selectedStartTime
                      ? "bg-teal text-white cursor-pointer"
                      : "cursor-pointer"
                  }
                  ${
                    this.state.selectedEndTime &&
                    t === this.state.selectedEndTime
                      ? "bg-blue text-white cursor-pointer"
                      : "cursor-pointer"
                  }
                `}
                        >
                          <span
                            className={`${
                              startDateEqualToCurrentDate &&
                              (timeLessThanCurrentTime ||
                                timeLessThanSelectedStartTime) &&
                              "text-grey-light"
                            }`}
                          >
                            {t}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="absolute w-full pin-b ">
                    <div className="h-4 gradient-fade-top" />
                  </div>
                </div>
              </>
            )}
          </div>

          <div
            className="text-xs text-teal font-light font-sans cursor-pointer pl-4 ml-1 pt-2"
            onClick={() => this.clearAllData()}
          >
            Clear
          </div>
        </div>
      </div>
    );
  }
}
