import React, { Component } from "react";

export default class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRowDropdown: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  nextPage = (direction) => {
    switch (direction) {
      case "left":
        if (this.props.currentPage + 1 >= 1) {
          this.props.setPageClientSide(this.props.currentPage - 1);
        }
        break;
      case "right":
        if (this.props.currentPage + 1 < this.props.totalPages) {
          this.props.setPageClientSide(this.props.currentPage + 1);
        }
        break;
      default:
    }
  };

  setRows = (rows) => {
    this.props.setRowRize(rows);
    this.props.clearData();
  };

  toggleRowDropdown = () => {
    this.setState((prevState) => ({
      showRowDropdown: !prevState.showRowDropdown,
    }));
  };

  handleOutsideClick = (e) => {
    if (this.rowDropdown && this.rowDropdown.contains(e.target)) {
      return;
    } else {
      this.closeDropdown();
    }
  };

  closeDropdown = () => {
    this.setState({ showRowDropdown: false });
  };

  render() {
    return (
      <div className="flex flex-row w-full justify-center h-12 items-center py-2">
        {this.props.showPagination && (
          <>
            <div className="font-sans text-grey-darker text-xs px-2">
              <div className="font-medium inline-block px-1">Rows:</div>
              <div
                ref={(node) => (this.rowDropdown = node)}
                className="relative inline-block w-12 pl-1 cursor-pointer"
              >
                <div onClick={() => this.toggleRowDropdown()}>
                  <div className="material-icons absolute pin-r text-sm h-full flex items-center text-teal-dark">
                    unfold_more
                  </div>
                  {this.props.rowSize}
                </div>
                {this.state.showRowDropdown && (
                  <div className="bg-white absolute w-full pin-l pin-t -mt-19 text-grey-darker font-sans text-xs shadow-md text-left z-18 font-medium">
                    <ul className="border-1 border-grey-light">
                      <li
                        onClick={() => this.setRows(20)}
                        className={`px-2 py-1 ${
                          this.props.rowSize === 20
                            ? "bg-teal text-white"
                            : "hover:bg-teal hover:text-white"
                        }`}
                      >
                        20
                      </li>
                      <li
                        onClick={() => this.setRows(50)}
                        className={`px-2 py-1 ${
                          this.props.rowSize === 50
                            ? "bg-teal text-white"
                            : "hover:bg-teal hover:text-white"
                        }`}
                      >
                        50
                      </li>
                      <li
                        onClick={() => this.setRows(100)}
                        className={`px-2 py-1 ${
                          this.props.rowSize === 100
                            ? "bg-teal text-white"
                            : "hover:bg-teal hover:text-white"
                        }`}
                      >
                        100
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center px-6">
              <div className="font-sans text-grey-darker text-xs px-2">
                <div className="font-medium inline-block px-1">
                  {this.props.rowSize * this.props.currentPage}-
                  {(this.props.currentPage + 1) * this.props.rowSize >
                  this.props.dataLength
                    ? this.props.dataLength
                    : (this.props.currentPage + 1) * this.props.rowSize}
                </div>
                of
                <div className="font-medium inline-block px-1">
                  {this.props.dataLength}
                </div>
              </div>

              {this.props.currentPage === 0 ? (
                <div className="material-icons rounded-full bg-table-grey cursor-not-allowed text-table-grey-dark mx-1">
                  keyboard_arrow_left
                </div>
              ) : (
                <div
                  onClick={() => this.nextPage("left")}
                  className="material-icons rounded-full  cursor-pointer hover:text-white hover:bg-teal-dark hover:border-teal text-grey-dark mx-1"
                >
                  keyboard_arrow_left
                </div>
              )}

              {this.props.totalPages === 0 ||
              this.props.currentPage + 1 === this.props.totalPages ? (
                <div className="material-icons rounded-full bg-table-grey cursor-not-allowed text-table-grey-dark mx-1">
                  keyboard_arrow_right
                </div>
              ) : (
                <div
                  onClick={() => this.nextPage("right")}
                  className="material-icons  rounded-full  cursor-pointer hover:text-white hover:bg-teal-dark hover:border-teal text-grey-dark mx-1"
                >
                  keyboard_arrow_right
                </div>
              )}
            </div>
            <div className="flex flex-row items-center font-sans text-grey-darker text-xs px-6">
              <div className="px-2">Page:</div>
              <div className="rounded-full bg-teal-dark text-white ml-1 mr-2 w-6 h-6 flex justify-center items-center font-medium">
                {this.props.currentPage + 1}
              </div>

              <div className="font-sans text-grey-darker text-xs">of</div>
              <div className="font-medium ml-2 inline-block ">
                {this.props.totalPages}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
