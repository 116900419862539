import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal } from "ducks/modal";
import {
  createCampaign,
  updateCampaign,
  clearSavingProgress,
} from "ducks/campaignAnalytics";
import { InputText } from "components/InputText";
import moment from "moment";
import { ModalContentWrapper } from "containers/Modals/ModalContainer/ModalContentWrapper";
import { LargeLoadingSpinner } from "components/LargeLoadingSpinner/LargeLoadingSpinner";

import CampaignDatePickerWithoutDropdown from "components/CampaignDatePicker/CampaignDatePickerWithoutDropdown";

class CampaignAnalyticsSave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canContinue: true,
      campaignName: "",
      startDate: null,
      endDate: null,
      canSave: false,
      loaded: false,
      dates: {
        selectedStartDate: null,
        selectedStartTime: null,
        selectedEndDate: null,
        selectedEndTime: null,
      },
      discounts: [],
      products: [],
    };
  }

  componentDidMount() {
    let discounts = [];
    let prodIdList = [];
    let productList = [];
    let onlyRrpProducts = [];
    if (this.props.selectedProducts.length > 0) {
      productList = this.props.selectedProducts;
      onlyRrpProducts =
        productList && productList.filter((product) => product.rrp > 0);
    } else {
      productList = this.props.campaignAnalytics;
      onlyRrpProducts =
        productList &&
        productList.filter((product) => product.recommendedRetailPrice > 0);
    }

    onlyRrpProducts &&
      onlyRrpProducts.map((item) => {
        if (item.discount.value === this.props.discount) {
          prodIdList = prodIdList.concat(item.id);
        } else {
          discounts.push({
            discount: parseFloat(item.discount.value),
            productIds: item.id,
          });
        }
      });
    if (this.props.discount) {
      discounts.push({
        discount: parseFloat(this.props.discount),
        productIds: prodIdList,
      });
    }

    let combineList = discounts.reduce((a, v) => {
      if (a[v.discount]) {
        a[v.discount].productIds = a[v.discount].productIds.concat(
          v.productIds
        );
      } else {
        if (v.productIds.length === undefined) {
          a[v.discount] = {
            discount: v.discount,
            productIds: [v.productIds],
          };
        } else {
          a[v.discount] = v;
        }
      }
      return a;
    }, {});
    let discountsList = [];
    Object.keys(combineList).map((key) => discountsList.push(combineList[key]));

    this.setState({
      discounts: discountsList,
      products: onlyRrpProducts,
    });
    if (this.props.viewState === "update") {
      let startingDate = moment(this.props.discountInfo.startDate)
        .local()
        .format("YYYY-MM-DD HH:mm");

      let endingDate = moment(this.props.discountInfo.endDate)
        .local()
        .format("YYYY-MM-DD HH:mm");

      let dates = { ...this.state.dates };
      let splitStartDate = startingDate && startingDate.split(" ");
      let splitEndDate = endingDate && endingDate.split(" ");
      dates.selectedStartDate = splitStartDate[0];
      dates.selectedStartTime = splitStartDate[1];
      dates.selectedEndDate = splitEndDate[0];
      dates.selectedEndTime = splitEndDate[1];

      this.setState(
        {
          dates,
        },
        () => {
          this.setState({
            loaded: true,
            startDate: splitStartDate[0] + "T" + splitStartDate[1] + ":00Z",
            endDate: splitEndDate[0] + "T" + splitEndDate[1] + ":00Z",
            campaignName: this.props.discountInfo.name,
            startTime: splitStartDate[1],
            endTime: splitEndDate[1],
          });
        }
      );
    }
  }

  componentWillMount() {
    this.props.clearSavingProgress();
  }

  handleApprove = () => {
    this.props.closeModal();
  };

  saveDate = (from, timeFrom, to, timeTo) => {
    this.setState({
      startDate: moment
        .utc(moment(from + "T" + timeFrom))
        .format("YYYY-MM-DDTHH:mm"),

      endDate: moment.utc(moment(to + "T" + timeTo)).format("YYYY-MM-DDTHH:mm"),
      startTime: timeFrom,
      endTime: timeTo,
    });
  };

  onChange = (event) => {
    this.setState({ campaignName: event.target.value });
  };

  saveCampaign = () => {
    let selectedProducts = this.props.selectedProducts.map(
      (products) => products.id
    );
    let campaign = {
      campaign: {
        name: this.state.campaignName,
        startDate: this.state.startDate + ":00Z",
        endDate: this.state.endDate + ":00Z",
        channels: [
          {
            id: localStorage.getItem("channel"),
          },
        ],
      },
      discounts: this.state.discounts,
    };
    if (this.props.viewState === "update") {
      this.props.updateCampaign(this.props.discountInfo.id, campaign);
    } else {
      this.props.createCampaign(campaign);
    }
  };

  render() {
    let canSave = false;

    if (
      this.state.campaignName.length > 0 &&
      this.state.startDate &&
      this.state.endDate &&
      this.state.startTime &&
      this.state.endTime
    ) {
      canSave = true;
    }

    let selectedProducts = this.props.selectedProducts.map(
      (products) => products.id
    );

    const modalConfig = {
      title:
        this.props.viewState === "update"
          ? "Update Discount Campaign"
          : "Create Discount Campaign",
      hasBottomButtons: true,
      closeText: "Cancel",
      handleClose: this.props.closeModal,
      warning: false,
      buttonOnClick: this.props.saved
        ? this.props.closeModal
        : this.saveCampaign,
      buttonLabel: this.props.saved
        ? "Okay"
        : this.props.viewState === "update"
        ? "Update"
        : "Create",
      canSave: canSave,
    };

    return (
      <ModalContentWrapper modalConfig={modalConfig} size={this.props.size}>
        <div className="w-full h-full flex flex-col px-4 items-center justify-center">
          {this.props.savingInProgress ? (
            <>{LargeLoadingSpinner}</>
          ) : this.props.saved ? (
            <div className="flex flex-col w-full items-center">
              <div>
                <i className="material-icons text-teal text-9xl">
                  check_circle
                </i>
              </div>
              <div className="text-lg text-teal font-light pt-4">Saved!</div>
            </div>
          ) : (
            <>
              <div className="pl-6 pb-2 ml-1 w-74 flex flex-row">
                <InputText
                  required={true}
                  width="w-40"
                  label="Campaign Name"
                  placeholder="Campaign Name"
                  onChange={this.onChange}
                  value={this.state.campaignName}
                />
                <div className="flex-1 flex-row flex pb-1 self-end text-sm font-sans text-teal">
                  <div className="w-1/2 text-right pr-4">
                    <span className="text-xxs font-light text-grey-darker inline-block pr-2">
                      Discount:{" "}
                    </span>
                    {this.props.discount ? this.props.discount + " %" : "-"}
                  </div>
                  <div className="w-1/2 text-left">
                    <span className="text-xxs font-light text-grey-darker inline-block pr-2">
                      Selected:{" "}
                    </span>
                    {this.state.products ? this.state.products.length : 0}
                  </div>
                </div>
              </div>
              {this.props.viewState === "update" ? (
                this.state.loaded ? (
                  <CampaignDatePickerWithoutDropdown
                    dateRange={true}
                    showTime={true}
                    hasNoEndDateOption={false}
                    canSave={this.canSave}
                    saveDate={this.saveDate}
                    selectedStartDate={this.state.dates.selectedStartDate}
                    selectedStartTime={this.state.dates.selectedStartTime}
                    selectedEndDate={this.state.dates.selectedEndDate}
                    selectedEndTime={this.state.dates.selectedEndTime}
                    update={true}
                  />
                ) : null
              ) : (
                <CampaignDatePickerWithoutDropdown
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  dateRange={true}
                  showTime={true}
                  hasNoEndDateOption={false}
                  canSave={this.canSave}
                  saveDate={this.saveDate}
                />
              )}
            </>
          )}
        </div>
      </ModalContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignAnalytics: state.campaignAnalytics.campaignAnalytics,
    viewState: state.campaignAnalytics.viewState,
    productIdList: state.campaignAnalytics.productIdList,
    discount: state.campaignAnalytics.discount,
    singleCampaignData: state.campaignAnalytics.singleCampaignData,
    savingInProgress: state.campaignAnalytics.savingInProgress,
    saved: state.campaignAnalytics.saved,
    selectedProducts: state.campaignAnalytics.selectedProducts,
    discountInfo: state.campaignAnalytics.discountInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    createCampaign: (campaign) => dispatch(createCampaign(campaign)),
    updateCampaign: (id, campaign) => dispatch(updateCampaign(id, campaign)),
    clearSavingProgress: () => dispatch(clearSavingProgress()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignAnalyticsSave);
