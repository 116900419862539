import React, { Component } from "react";

import { Link } from "react-router-dom";
import moment from "moment";

class NotificationDropdown extends Component {
  render() {
    return (
      <div className="flex flex-col max-w-16 w-50 max-h-20 py-4 px-2 shadow-md tag-notifications">
        <div className="flex flex-row text-grey text-sm text-left">
          Notifications
        </div>
        <ul className="flex flex-col  overflow-y-scroll w-full text-center ">
          {this.props.notifications.map((notification, i) => {
            return (
              <Link
                key={notification.id}
                to={{
                  pathname: "/notifications",
                  state: {
                    id: i,
                  },
                }}
              >
                <li
                  key={notification.id}
                  className="flex flex-col p-4 cursor-pointer "
                >
                  <span className="text-grey-darker text-sm flex flex-row">
                    <span className="flex flex-col self-center">
                      <span
                        className={`rounded-full h-2 w-2  ${
                          notification.read ? "bg-grey" : "bg-red"
                        }  mr-4 `}
                      ></span>
                    </span>
                    <span className="flex flex-col text-left">
                      {notification.message}
                    </span>
                  </span>
                  <span className="text-grey text-xs flex flex-row ml-6">
                    {moment(notification.created)
                      .local()
                      .format("YYYY-MM-DD HH:mm")}
                  </span>
                </li>
              </Link>
            );
          })}
        </ul>
        <Link to="/notifications">
          <div className="text-grey text-sm w-full text-center py-2 ">
            Show All Notifications
          </div>
        </Link>
      </div>
    );
  }
}

export default NotificationDropdown;
