import React, { Component } from "react";
import ViewContainer from "containers/ViewContainer";
import InlineFilter from "containers/Filters/InlineFilter";
import { connect } from "react-redux";
import Widget from "components/Widget/Widget";
import KpiBox from "components/KpiBox/KpiBox";
import { getBrandsByMarket, getCategoriesByMarket } from "ducks/products";
import { getAllCompetitors } from "ducks/priceRule";
import { mockBrands, mockCategories } from "services/brands";
import {
  configSalesOverTimeBrand,
  configSalesOverTimeCategory,
  configSumSalesOverTime,
  configDistributionalesByBrand,
  configDistributionalesByCategory,
  configSumConversionOverTime,
  avgSalesPerClick,
  setConfigSalesOverTimeBrandLoading,
  setConfigSalesOverTimeCategoryLoading,
  setConfigDistributionalesByBrandLoading,
  setConfigDistributionalesByCategoryLoading,
} from "ducks/businessIntelligence";
import moment from "moment";

class BusinessIntelligence extends Component {
  constructor(props) {
    super(props);
    this.salesOverTimeB = React.createRef();
    this.salesOverTimeC = React.createRef();
    this.distByB = React.createRef();
    this.distByC = React.createRef();
    this.sumoverTime = React.createRef();
    this.convOverTime = React.createRef();
    this.avgSalesPerClick = React.createRef();
    this.state = {
      topVisible: true,
      filterValues: [],
      brands: [],
      categories: [],
      brandsByDist: [],
      categoriesByDist: [],
      isLoading: false,
      filterKeyOptions: [
        {
          id: 3,
          name: "Date",
          color: "indigo-light",
          type: "date",
        },
      ],

      startDate: "",
      endDate: "",
      channel: "",
    };
  }

  componentDidMount() {
    this.setState({
      filterValues: this.state.filterValues.concat({
        Date: `From: ${moment()
          .subtract("weeks", 2)
          .format("YYYY-MM-DD")} To: ${moment().format("YYYY-MM-DD")}`,
      }),
    });
    this.props.getBrandsByMarket();
    this.props.getCategoriesByMarket();
    this.props.getAllCompetitors();
    let preselectedBrands = [];
    let preselectedCategories = [];
    if (this.props.brands.length > 5) {
      for (let i = 0; i < 5; i++) {
        preselectedBrands.push(this.props.brands[i]);
        this.setState({
          brands: preselectedBrands,
          brandsByDist: preselectedBrands,
        });
      }
    } else {
      for (let i = 0; i < this.props.brands.length; i++) {
        preselectedBrands.push(this.props.brands[i]);
        this.setState({
          brands: preselectedBrands,
          brandsByDist: preselectedBrands,
        });
      }
    }
    if (this.props.categories.length > 5) {
      for (let i = 0; i < 5; i++) {
        preselectedCategories.push(this.props.categories[i]);
        this.setState({
          categories: preselectedCategories,
          categoriesByDist: preselectedCategories,
        });
      }
    } else {
      for (let i = 0; i < this.props.categories.length; i++) {
        preselectedCategories.push(this.props.categories[i]);
        this.setState({
          categories: preselectedCategories,
          categoriesByDist: preselectedCategories,
        });
      }
    }
    this.setState(
      {
        brandOptions: this.props.brands,
        categoryOptions: this.props.categories,
        brands: preselectedBrands,
        categories: preselectedCategories,
        brandsByDist: preselectedBrands,
        categoriesByDist: preselectedCategories,
        market: localStorage.getItem("market"),
        channel: localStorage.getItem("channel"),
      },
      () => {
        this.salesOverTimeB.current.triggerGetDateOnChange();
        this.salesOverTimeC.current.triggerGetDateOnChange();
        this.distByB.current.triggerGetDateOnChange();
        this.distByC.current.triggerGetDateOnChange();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    let preselectedBrands = [];
    let preselectedCategories = [];
    if (this.state.channel !== localStorage.getItem("channel")) {
      this.setState({
        market: localStorage.getItem("market"),
        channel: localStorage.getItem("channel"),

        brands: [],
        brandsByDist: [],
        categoriesByDist: [],
        categories: [],
      });
      this.props.setConfigSalesOverTimeBrandLoading(true);
      this.props.setConfigSalesOverTimeCategoryLoading(true);
      this.props.setConfigDistributionalesByBrandLoading(true);
      this.props.setConfigDistributionalesByCategoryLoading(true);
      this.props.getBrandsByMarket();
      this.props.getCategoriesByMarket();
      this.props.getAllCompetitors();

      this.sumoverTime.current.triggerGetDateOnChange();
      this.convOverTime.current.triggerGetDateOnChange();
      this.avgSalesPerClick.current.triggerGetDateOnChange();
    }

    if (this.state.brandOptions.length !== this.props.brands.length) {
      this.setState({
        brandOptions: this.props.brands,
      });
      if (this.props.brands.length > 5) {
        for (let i = 0; i < 5; i++) {
          preselectedBrands.push(this.props.brands[i]);
          this.setState({
            brands: preselectedBrands,
            brandsByDist: preselectedBrands,
          });
        }
      } else {
        for (let i = 0; i < this.props.brands.length; i++) {
          preselectedBrands.push(this.props.brands[i]);
          this.setState({
            brands: preselectedBrands,
            brandsByDist: preselectedBrands,
          });
        }
      }
      setTimeout(
        () => {
          this.salesOverTimeB.current.triggerGetDateOnChange();
          this.distByB.current.triggerGetDateOnChange();
        },
        () => {
          this.props.setConfigSalesOverTimeBrandLoading(false);
          this.props.setConfigSalesOverTimeCategoryLoading(false);
          this.props.setConfigDistributionalesByBrandLoading(false);
          this.props.setConfigDistributionalesByCategoryLoading(false);
        },
        500
      );
    }

    if (this.state.categoryOptions.length !== this.props.categories.length) {
      this.setState({
        categoryOptions: this.props.categories,
      });
      if (this.props.categories.length > 5) {
        for (let i = 0; i < 5; i++) {
          preselectedCategories.push(this.props.categories[i]);
          this.setState({
            categories: preselectedCategories,
            categoriesByDist: preselectedCategories,
          });
        }
      } else {
        for (let i = 0; i < this.props.categories.length; i++) {
          preselectedCategories.push(this.props.categories[i]);
          this.setState({
            categories: preselectedCategories,
            categoriesByDist: preselectedCategories,
          });
        }
      }
      setTimeout(() => {
        this.salesOverTimeC.current.triggerGetDateOnChange();
        this.distByC.current.triggerGetDateOnChange();
      }, 500);
    } else return;
  }

  setDates = (startDate, endDate) => {
    let formattedStartDate = startDate + "T" + ":00:00:00Z";
    let formattedEndDate = endDate + "T" + ":23:59:59Z";
    this.setState({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  editInput = (item, index, selected) => {
    let filteredValues = [...this.state.filterValues];
    filteredValues.splice(index, 1, { [item]: selected });
    this.setState({
      filterValues: filteredValues,
    });
  };

  handleFilterCampaignAnalytics = () => {
    this.salesOverTimeB.current.triggerGetDateOnChange();
    this.salesOverTimeC.current.triggerGetDateOnChange();
    this.distByB.current.triggerGetDateOnChange();
    this.distByC.current.triggerGetDateOnChange();
    this.sumoverTime.current.triggerGetDateOnChange();
    this.convOverTime.current.triggerGetDateOnChange();
    this.avgSalesPerClick.current.triggerGetDateOnChange();
  };

  createFilterArray = (obj) => {
    this.setState({
      filterValues: this.state.filterValues.concat(obj),
    });
  };

  removeItemFiltererArray = (val) => {
    let arr = [...this.state.filterValues];
    let newArr = arr.filter((item) => item !== val);
    this.setState({
      filterValues: newArr,
    });
  };

  handleSelected = (name, selected) => {
    this.setState({
      [name]: selected,
    });
  };

  render() {
    //CHART

    let configSalesOverTimeBrand = {
      entity: "salesOrderItem",
      aggregation: "SUM",
      frequency: "DATE",
      measure: "sales_order_item.unit_price",
      dimensions: ["brand.name", "sales_order_item.order_date"],
      filters: [
        {
          field: "brand.name",
          labels: this.state.brands.map((brand) => brand.name),
        },
        {
          field: "sales_order_item.order_date",
          date:
            this.state.startDate.length > 0
              ? this.state.startDate
              : moment
                  .utc()
                  .subtract("weeks", 2)
                  .format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
          operator: ">=",
        },
        {
          field: "sales_order_item.order_date",
          date:
            this.state.endDate.length > 0
              ? this.state.endDate
              : moment.utc().format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
          operator: "<=",
        },
      ],
    };
    //CHART
    let configSalesOverTimeCategory = {
      entity: "salesOrderItem",
      aggregation: "SUM",
      frequency: "DATE",
      measure: "sales_order_item.unit_price",
      dimensions: ["category.name", "sales_order_item.order_date"],
      filters: [
        {
          field: "product.channel_id",
          number: localStorage.getItem("channel"),
          operator: "=",
        },
        {
          field: "category.name",
          labels: this.state.categories.map((category) => category.name),
        },
        {
          field: "sales_order_item.order_date",
          date:
            this.state.startDate.length > 0
              ? this.state.startDate
              : moment
                  .utc()
                  .subtract("weeks", 2)
                  .format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
          operator: ">=",
        },
        {
          field: "sales_order_item.order_date",
          date:
            this.state.endDate.length > 0
              ? this.state.endDate
              : moment.utc().format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
          operator: "<=",
        },
      ],
    };
    //KPI

    let configSumSalesOverTime = {
      entity: "salesOrderItem",
      aggregation: "SUM",
      frequency: "DATE",
      measure: "sales_order_item.unit_price",
      dimensions: [],
      filters: [
        {
          field: "product.channel_id",
          number: localStorage.getItem("channel"),
          operator: "=",
        },
        {
          field: "sales_order_item.order_date",
          date:
            this.state.startDate.length > 0
              ? this.state.startDate
              : moment
                  .utc()
                  .subtract("weeks", 2)
                  .format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
          operator: ">=",
        },
        {
          field: "sales_order_item.order_date",
          date:
            this.state.endDate.length > 0
              ? this.state.endDate
              : moment.utc().format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
          operator: "<=",
        },
      ],
    };

    let configSumConversionOverTime = {
      function: "CONVERSION_RATE",
      queries: {
        a: {
          entity: "salesOrderItem",
          aggregation: "COUNT",
          measure: "sales_order_item.unit_price",
          dimensions: [],
          filters: [
            {
              field: "product.channel_id",
              number: localStorage.getItem("channel"),
              operator: "=",
            },
            {
              field: "sales_order_item.order_date",
              date:
                this.state.startDate.length > 0
                  ? this.state.startDate
                  : moment
                      .utc()
                      .subtract("weeks", 2)
                      .format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
              operator: ">=",
            },
            {
              field: "sales_order_item.order_date",
              date:
                this.state.endDate.length > 0
                  ? this.state.endDate
                  : moment.utc().format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
              operator: "<=",
            },
          ],
        },
        b: {
          entity: "productClicks",
          aggregation: "SUM",
          measure: "product_clicks.clicks",
          dimensions: [],
          filters: [
            {
              field: "product.channel_id",
              number: localStorage.getItem("channel"),
              operator: "=",
            },
            {
              field: "product_clicks.click_date",
              date:
                this.state.startDate.length > 0
                  ? this.state.startDate
                  : moment
                      .utc()
                      .subtract("weeks", 2)
                      .format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
              operator: ">=",
            },
            {
              field: "product_clicks.click_date",
              date:
                this.state.endDate.length > 0
                  ? this.state.endDate
                  : moment.utc().format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
              operator: "<=",
            },
          ],
        },
      },
    };

    let avgSalesPerClick = {
      function: "CONVERSION_RATE",
      queries: {
        a: {
          entity: "salesOrderItem",
          aggregation: "SUM",
          measure: "sales_order_item.unit_price",
          dimensions: [],
          filters: [
            {
              field: "product.channel_id",
              number: localStorage.getItem("channel"),
              operator: "=",
            },
            {
              field: "sales_order_item.order_date",
              date:
                this.state.startDate.length > 0
                  ? this.state.startDate
                  : moment
                      .utc()
                      .subtract("weeks", 2)
                      .format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
              operator: ">=",
            },
            {
              field: "sales_order_item.order_date",
              date:
                this.state.endDate.length > 0
                  ? this.state.endDate
                  : moment.utc().format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
              operator: "<=",
            },
          ],
        },
        b: {
          entity: "productClicks",
          aggregation: "SUM",
          measure: "product_clicks.clicks",
          dimensions: [],
          filters: [
            {
              field: "product.channel_id",
              number: localStorage.getItem("channel"),
              operator: "=",
            },
            {
              field: "product_clicks.click_date",
              date:
                this.state.startDate.length > 0
                  ? this.state.startDate
                  : moment
                      .utc()
                      .subtract("weeks", 2)
                      .format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
              operator: ">=",
            },
            {
              field: "product_clicks.click_date",
              date:
                this.state.endDate.length > 0
                  ? this.state.endDate
                  : moment.utc().format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
              operator: "<=",
            },
          ],
        },
      },
    };

    //PIE
    let configDistributionalesByBrand = {
      entity: "salesOrderItem",
      aggregation: "SUM",
      measure: "sales_order_item.unit_price",
      dimensions: ["brand.name"],
      filters: [
        {
          field: "product.channel_id",
          number: localStorage.getItem("channel"),
          operator: "=",
        },
        {
          field: "brand.name",
          labels: this.state.brandsByDist.map((brand) => brand.name),
        },
        {
          field: "sales_order_item.order_date",
          date:
            this.state.startDate.length > 0
              ? this.state.startDate
              : moment
                  .utc()
                  .subtract("weeks", 2)
                  .format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
          operator: ">=",
        },
        {
          field: "sales_order_item.order_date",
          date:
            this.state.endDate.length > 0
              ? this.state.endDate
              : moment.utc().format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
          operator: "<=",
        },
      ],
    };
    //PIE
    let configDistributionalesByCategory = {
      entity: "salesOrderItem",
      aggregation: "SUM",
      measure: "sales_order_item.unit_price",
      dimensions: ["category.name"],
      filters: [
        {
          field: "product.channel_id",
          number: localStorage.getItem("channel"),
          operator: "=",
        },
        {
          field: "category.name",
          labels: this.state.categoriesByDist.map((category) => category.name),
        },
        {
          field: "sales_order_item.order_date",
          date:
            this.state.startDate.length > 0
              ? this.state.startDate
              : moment()
                  .subtract("weeks", 2)
                  .format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
          operator: ">=",
        },
        {
          field: "sales_order_item.order_date",
          date:
            this.state.endDate.length > 0
              ? this.state.endDate
              : moment().format("YYYY-MM-DD" + "T" + "HH:mm:ss") + "Z",
          operator: "<=",
        },
      ],
    };
    return (
      <ViewContainer
        hasPageButtons={true}
        topVisible={false}
        handleToggleTop={this.handleToggleTop}
      >
        <div className="flex flex-col h-screen w-full justify-center px-10">
          <div className="flex w-full justify-center mt-10 pt-4 items-center  bg-white">
            <InlineFilter
              name="analyticsFilter"
              filterValues={this.state.filterValues}
              createFilterArray={this.createFilterArray}
              removeItem={this.removeItemFiltererArray}
              filterKeyOptions={this.state.filterKeyOptions}
              filterValueOptions={{
                brand: this.props.brands,
                category: this.props.categories,
                competitor: this.props.competitors,
              }}
              setDates={this.setDates}
              handleSubmit={this.handleFilterCampaignAnalytics}
              editInput={this.editInput}
            />
          </div>
          <div className="flex flex-row h-full w-full mt-2">
            <div className="flex flex-col w-5/6">
              <div className="flex flex-row h-2/4 w-full my-2">
                <div className="bg-white flex-auto w-1/2 ml-2 mr-4">
                  <Widget
                    selected={this.state.brands}
                    options={this.props.brands}
                    placeholder="filter brands"
                    handleSelected={this.handleSelected}
                    name="brands"
                    displayName="Sales Over Time (Brands)"
                    chartType={"line"}
                    chartData={this.props.configSalesOverTimeBrandList}
                    config={configSalesOverTimeBrand}
                    loadingData={this.props.configSalesOverTimeBrandLoading}
                    getData={this.props.configSalesOverTimeBrand}
                    ref={this.salesOverTimeB}
                  />
                </div>
                <div className="bg-white flex-auto w-1/2 mx-4">
                  <Widget
                    selected={this.state.categories}
                    options={this.props.categories}
                    name="categories"
                    displayName="Sales Over Time (Categories)"
                    placeholder="filter categories"
                    handleSelected={this.handleSelected}
                    chartType={"line"}
                    chartData={this.props.configSalesOverTimeCategoryList}
                    config={configSalesOverTimeCategory}
                    loadingData={this.props.configSalesOverTimeCategoryLoading}
                    getData={this.props.configSalesOverTimeCategory}
                    ref={this.salesOverTimeC}
                  />
                </div>
              </div>
              <div className="flex flex-row h-2/4 w-full my-2 justify-between">
                <div className="bg-white flex-auto w-1/2 ml-2 mr-4">
                  <Widget
                    selected={this.state.brandsByDist}
                    options={this.props.brands}
                    name="brandsByDist"
                    placeholder="filter brands"
                    handleSelected={this.handleSelected}
                    chartType={"pie"}
                    displayName="Distribution By Brands"
                    chartData={this.props.configDistributionalesByBrandList}
                    config={configDistributionalesByBrand}
                    loadingData={
                      this.props.configDistributionalesByBrandLoading
                    }
                    getData={this.props.configDistributionalesByBrand}
                    ref={this.distByB}
                  />
                </div>
                <div className="bg-white flex-auto w-1/2  mx-4">
                  <Widget
                    selected={this.state.categoriesByDist}
                    options={this.props.categories}
                    name="categoriesByDist"
                    placeholder="filter categories"
                    handleSelected={this.handleSelected}
                    chartType={"pie"}
                    displayName="Distribution By Categories"
                    loadingData={
                      this.props.configDistributionalesByCategoryLoading
                    }
                    chartData={this.props.configDistributionalesByCategoryList}
                    config={configDistributionalesByCategory}
                    getData={this.props.configDistributionalesByCategory}
                    ref={this.distByC}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col h-full w-1/5 mt-2 pb-4">
              <div className="bg-white flex-auto h-full mx-2 mb-2">
                <KpiBox
                  displayName="Sum Of Sales"
                  config={configSumSalesOverTime}
                  getData={this.props.configSumSalesOverTime}
                  data={
                    this.props.configSumSalesOverTimeList
                      ? this.props.configSumSalesOverTimeList
                      : {}
                  }
                  ref={this.sumoverTime}
                />
              </div>
              <div className="bg-white flex-auto h-full m-2">
                <KpiBox
                  displayName="Conversion Rate"
                  config={configSumConversionOverTime}
                  getData={this.props.configSumConversionOverTime}
                  data={
                    this.props.configSumConversionOverTimeList
                      ? this.props.configSumConversionOverTimeList
                      : {}
                  }
                  ref={this.convOverTime}
                />
              </div>
              <div className="bg-white flex-auto mx-2  h-full mt-2">
                <KpiBox
                  displayName="Avg. Sales Per Click"
                  config={avgSalesPerClick}
                  getData={this.props.avgSalesPerClick}
                  data={
                    this.props.avgSalesPerClickList
                      ? this.props.avgSalesPerClickList
                      : {}
                  }
                  ref={this.avgSalesPerClick}
                />
              </div>
            </div>
          </div>
        </div>
      </ViewContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    brands: state.products.brands,
    categories: state.products.categories,
    competitors: state.priceRule.competitorList,
    configSalesOverTimeBrandList:
      state.businessIntelligence.configSalesOverTimeBrandList,
    configSalesOverTimeCategoryList:
      state.businessIntelligence.configSalesOverTimeCategoryList,
    configSumSalesOverTimeList:
      state.businessIntelligence.configSumSalesOverTimeList,
    configDistributionalesByBrandList:
      state.businessIntelligence.configDistributionalesByBrandList,
    configDistributionalesByCategoryList:
      state.businessIntelligence.configDistributionalesByCategoryList,
    configSumConversionOverTimeList:
      state.businessIntelligence.configSumConversionOverTimeList,
    avgSalesPerClickList: state.businessIntelligence.avgSalesPerClickList,
    configSalesOverTimeBrandLoading:
      state.businessIntelligence.configSalesOverTimeBrandLoading,
    configSalesOverTimeCategoryLoading:
      state.businessIntelligence.configSalesOverTimeCategoryLoading,
    configDistributionalesByBrandLoading:
      state.businessIntelligence.configDistributionalesByBrandLoading,
    configDistributionalesByCategoryLoading:
      state.businessIntelligence.configDistributionalesByCategoryLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBrandsByMarket: () => dispatch(getBrandsByMarket()),
    configSalesOverTimeBrand: (config) =>
      dispatch(configSalesOverTimeBrand(config)),
    configSalesOverTimeCategory: (config) =>
      dispatch(configSalesOverTimeCategory(config)),
    configSumSalesOverTime: (config) =>
      dispatch(configSumSalesOverTime(config)),
    configDistributionalesByBrand: (config) =>
      dispatch(configDistributionalesByBrand(config)),
    configDistributionalesByCategory: (config) =>
      dispatch(configDistributionalesByCategory(config)),
    configSumConversionOverTime: (config) =>
      dispatch(configSumConversionOverTime(config)),
    avgSalesPerClick: (config) => dispatch(avgSalesPerClick(config)),
    getCategoriesByMarket: () => dispatch(getCategoriesByMarket()),
    getAllCompetitors: () => dispatch(getAllCompetitors()),
    setConfigSalesOverTimeBrandLoading: (value) =>
      dispatch(setConfigSalesOverTimeBrandLoading(value)),
    setConfigSalesOverTimeCategoryLoading: (value) =>
      dispatch(setConfigSalesOverTimeCategoryLoading(value)),
    setConfigDistributionalesByBrandLoading: (value) =>
      dispatch(setConfigDistributionalesByBrandLoading(value)),
    setConfigDistributionalesByCategoryLoading: (value) =>
      dispatch(setConfigDistributionalesByCategoryLoading(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessIntelligence);
