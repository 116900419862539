import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal } from "ducks/modal";
import { closeFormpanel } from "ducks/formpanel";
import { deletePriceRule } from "ducks/priceRule";
import { deleteCampaignAnalytics } from "ducks/campaignAnalytics";
import { ModalContentWrapper } from "containers/Modals/ModalContainer/ModalContentWrapper";

class Delete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canContinue: true,
      name: "",
      id: "",
      type: "",
    };
  }

  componentDidMount() {
    let type = !this.props.data.priceRule
      ? this.props.data.campaign.type
      : this.props.data.priceRule.map((rule) => rule.campaign.type);
    if (this.props.data.priceRule && type.includes("Auto")) {
      let { data } = this.props;
      data.priceRule.map((data) => {
        this.setState({
          name: data.name,
          id: data.campaign.id,
          type: data.campaign.type,
        });
      });
    } else {
      this.setState({
        name: !this.props.data.priceRule
          ? this.props.data.name
          : this.props.data.priceRule[0].name,
        id: !this.props.data.priceRule
          ? this.props.data.id
          : this.props.data.priceRule[0].id,
        type: type,
      });
    }
  }

  handleContinue = () => {
    let { data } = this.props;
    this.state.type === "Auto"
      ? this.props.deleteCampaignAnalytics(
          this.state.id,
          data.currentPage,
          data.rowSize,
          data.attr,
          data.direction
        )
      : this.props.deletePriceRule(this.state.id);
    this.props.closeModal();
    this.props.closeFormpanel();
  };

  render() {
    const modalConfig = {
      buttonOnClick: this.handleContinue,
      buttonLabel: "yes, Delete",
      title: "Just checking",
      hasBottomButtons: true,
      canSave: this.state.canContinue,
      closeText: "Cancel",
      handleClose: this.props.closeModal,
      warning: true,
    };
    return (
      <ModalContentWrapper modalConfig={modalConfig} size={this.props.size}>
        <div className="w-full text-center h-full flex flex-col justify-between py-8">
          <div className="font-sans font-light text-md">
            <span className="text-grey-dark text-center">
              Are you sure you want to delete,
            </span>
          </div>
          <div className="flex flex-wrap justify-center text-lg">
            <div className="font-medium text-grey-darker">
              {this.state.name}
            </div>
          </div>
          <div className="font-sans font-light text-md pb-2">
            <span className="text-grey-dark text-center">
              <span className="font-normal text-sm">WARNING:</span> This action
              can not be undone!
            </span>
          </div>
        </div>
      </ModalContentWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeFormpanel: () => dispatch(closeFormpanel()),
    closeModal: () => dispatch(closeModal()),
    deletePriceRule: (id) => dispatch(deletePriceRule(id)),
    deleteCampaignAnalytics: (id, page, rowSize, attr, direction) =>
      dispatch(deleteCampaignAnalytics(id, page, rowSize, attr, direction)),
  };
};

export default connect(null, mapDispatchToProps)(Delete);
