import axios from "axios";
import { logout } from "services/logout.jsx";

export const authMiddleware = store => next => action => {
  if (
    store.getState().general.appLoaded &&
    (!localStorage.getItem("user") ||
      !localStorage.getItem("market") ||
      !localStorage.getItem("name") ||
      !localStorage.getItem("domain")) &&
    store.getState().sidepanel.selectedMenuDomain &&
    store.getState().sidepanel.selectedMenuItem &&
    store.getState().sidepanel.selectedMenuName
  ) {
    logout();
  }
  if (typeof action === "function") {
    return axios
      .get(process.env.REACT_APP_API_HOST + "/auth/token/check")
      .then(res => {
        next(action);
      })
      .catch(error => {
        return axios
          .post(process.env.REACT_APP_API_HOST + "/auth/token")
          .then(res => {
            next(action);
          })
          .catch(error => {
            logout();
            return Promise.reject(error);
          });
      });
  } else {
    next(action);
  }
};
