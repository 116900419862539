import React, { Component } from "react";
import PropTypes from "prop-types";

export default class InputNumber extends Component {
  constructor() {
    super();
    this.state = {
      dropdownOpen: false
    };
  }

  componentDidMount() {
    if (this.props.hasCurrency) {
      document.addEventListener("mousedown", this.handleOutsideClick);
    }
  }

  componentWillUnmount() {
    if (this.props.hasCurrency) {
      document.removeEventListener("mousedown", this.handleOutsideClick);
    }
  }

  handleOutsideClick = e => {
    if (this.currencyDropdown.contains(e.target)) {
      return;
    } else {
      this.closeDropdown();
    }
  };

  closeDropdown = () => {
    this.setState({ dropdownOpen: false });
  };

  handleToggle = () => {
    this.setState(prevSate => ({
      dropdownOpen: !prevSate.dropdownOpen
    }));
  };

  selectedItem = id => {
    this.props.options.forEach(item => {
      if (this.props.handleSelect && id === item.id) {
        this.props.handleSelect(this.props.name, item);
      }
    });
    this.closeDropdown();
  };

  render() {
    const buttonStyling =
      "bg-white hover:bg-grey-lightest cursor-pointer xxl:text-base text-xs outline-none xxl:h-10 h-8 xxl:pl-3 py-2 px-3 text-grey-darker font-light font-sans border-l-0 border-1 border-solid border-grey-light";
    const errorButtonStyling =
      "bg-red-lightest hover:bg-red-lighter cursor-pointer xxl:text-base outline-none text-sm font-sans xxl:h-10 h-8 xxl:pl-3 py-2 px-3 font-light text-red hover:text-red-darker border-l-0 border-1 border-solid border-red-light";
    const inputStyling =
      "bg-white border-solid border-grey-light border-1 focus:text-teal outline-none text-teal-darker xxl:text-base text-xs font-light font-sans xxl:h-10 h-8 xxl:pl-3 p-2 input-placeholder";
    const errorInputStyling =
      "bg-red-lightest border-solid border-red-lighter border-1 text-red outline-none xxl:text-base text-xs font-light font-sans xxl:h-10 h-8 xxl:pl-3 p-2 input-placeholder-error";

    let dropdownItemList =
      this.props.options &&
      this.props.options.map(item => {
        return (
          <li
            onClick={() => this.selectedItem(item.id)}
            className={`${
              this.props.selected && item.id === this.props.selected.id
                ? "bg-teal-dark text-white"
                : "bg-white text-grey-darker hover:bg-grey-lightest"
            } text-center uppercase w-full h-auto list-reset py-3 px-1 xxl:text-sm text-xs font-sans font-light cursor-pointer`}
            key={item.id}
          >
            {item.symbol} - {item.isoCurrencyCode}
          </li>
        );
      });
    return (
      <div
        className={this.props.width ? this.props.width : "xxl:w-50 w-32"}
        ref={node => (this.currencyDropdown = node)}
      >
        <label
          htmlFor={this.props.name}
          className="block text-xs font-light text-grey-dark font-sans py-1 capitalize"
        >
          <span className={`${!this.props.label && "invisible"}`}>
            {this.props.label ? this.props.label : "-"}
          </span>

          {this.props.required ? (
            <span className="text-red">
              {" "}
              *{this.props.error ? <span> Required.</span> : null}
            </span>
          ) : null}
        </label>
        <div
          className={`${
            this.props.width ? this.props.width : "xxl:w-50 w-32"
          } flex flex-row flex-no-wrap`}
        >
          <div className="w-full flex flex-col">
            {this.props.onlyShowValue ? (
              <div
                className={`${
                  this.props.width ? this.props.width : "xxl:w-50 w-32"
                } xxl:text-base text-xs text-teal-dark outline-none xxl:h-10 xxl:pl-3 py-1 px-3 font-light border-solid border-white border-1`}
              >
                {this.props.value}
              </div>
            ) : (
              <input
                autoComplete="off"
                className={`${
                  this.props.width ? this.props.width : "xxl:w-50 w-32"
                } ${
                  this.props.error || this.props.showErrorMessage
                    ? errorInputStyling
                    : inputStyling
                }`}
                type="number"
                name={this.props.name}
                onChange={this.props.onChange}
                value={this.props.value}
                min={this.props.min}
                max={this.props.max}
                step={this.props.step}
              />
            )}

            <div className="relative w-full">
              {this.props.hasCurrency && this.state.dropdownOpen && (
                <ul className="w-full absolute xxl:max-h-15 max-h-10 shadow-md bg-white mt-2 px-0 border-1 border-solid border-grey-lighter overflow-y-auto overflow-x-hidden z-20">
                  {dropdownItemList}
                </ul>
              )}
            </div>
          </div>
          {this.props.hasCurrency && (
            <div
              onClick={() => this.handleToggle()}
              className={
                this.props.error || this.props.showErrorMessage
                  ? errorButtonStyling
                  : buttonStyling
              }
            >
              {this.props.selected && this.props.selected.symbol !== undefined
                ? this.props.selected.symbol
                : this.props.options[0].symbol}
            </div>
          )}
        </div>
        <div
          className={`text-xs font-light text-red font-sans ${this.props
            .errorMessage && "h-1 my-1"}`}
        >
          {this.props.showErrorMessage &&
            this.props.errorMessage &&
            this.props.errorMessage}
        </div>
      </div>
    );
  }
}

InputNumber.propTypes = {
  /** A function that handles its onchange event */

  onChange: PropTypes.func,
  /** Unique name of the input field */

  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  /** Placeholder of the input field */

  placeholder: PropTypes.string,
  /** If the input field is required */

  required: PropTypes.bool,
  /** object.includes("name") */

  error: PropTypes.bool,
  /** Unique name of the currency dropdown */

  currencyName: PropTypes.string,
  /** If input is in edit state */

  edit: PropTypes.bool,
  /** If the input has currency  */

  hasCurrency: PropTypes.bool,
  /** Function to handle selected items */

  dropdownSelect: PropTypes.func,
  /** Object of currently selected, this usually goes with the edit state */

  currentlySelected: PropTypes.object,
  /** Object of dropdown options */

  dropdownOptions: PropTypes.object,
  width: PropTypes.string
};
