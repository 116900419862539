import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal } from "ducks/modal";

import Table from "components/Table";
import { ModalContentWrapper } from "containers/Modals/ModalContainer/ModalContentWrapper";

class TableModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canContinue: true,
      productData: [
        {
          noTableData: true,
          id: "",
          name: "",
          articleNumber: "",
          brand: "",
          category: ""
        }
      ]
    };
  }

  render() {
    let parsedProductData =
      this.props.setProductsByproductIds &&
      this.props.setProductsByproductIds.map(item => {
        return {
          id: item.articleNumber,
          name: {
            hideSorting: true,
            value: item.productName
              ? item.productName.length > 20
                ? item.productName.slice(0, 20) + "..."
                : item.productName
              : "-"
          },
          articleNumber: {
            hideSorting: true,
            value: item.articleNumber
          },
          brand: {
            hideSorting: true,
            value: item.brand
              ? item.brand.length > 20
                ? item.brand.slice(0, 20) + "..."
                : typeof item.brand === "object"
                ? item.brand.name
                : item.brand
              : "-"
          },
          category: {
            hideSorting: true,
            value: item.category
              ? item.category.length > 20
                ? item.category.slice(0, 20) + "..."
                : typeof item.category === "object"
                ? item.category.name
                : item.category
              : "-"
          }
        };
      });

    const tableConfigProducts = {
      data:
        parsedProductData.length > 0
          ? parsedProductData
          : this.state.productData,
      name: "Products",
      tableLoading: this.props.productsByproductIdsLoading,
      noData: this.props.setNoProductsByProductIdsData,
      // onDoubleClick: this.onDoubleClickProducts,
      onClick: this.onClick,
      openExpanded: true,
      hideExpanding: true,
      hiddenCols: [
        "id",
        "noTableData",
        "parentId",
        "mongoId",
        "extId",
        "created",
        "channel",
        "updated",
        "exists",
        "purchasePriceScd",
        "recommendedRetailPriceScd",
        "salesPriceScd",
        "tags"
      ],
      hideSorting: true

      // notExpandable: true

      // selectRows: this.selectProductRows,
      // selectAllRows: this.selectProductRows,
      // paginationGetData: this.props.showAllProducts
      //   ? this.getProductData
      //   : this.paginationProductsByGroupId,
      // dataLength: this.props.totalProducts,
      // currentPage: this.props.currentPageProduct,
      // rowSize: this.props.rowSizeProduct,
      // totalPages: this.props.totalProductPages,
      // attr: this.props.productSortingAttr,
      // direction: this.props.productSortingDirection
    };

    const modalConfig = {
      title: "Products",
      hasBottomButtons: true,
      closeText: "Close",
      handleClose: this.props.closeModal,
      warning: false,
      hideSubmitButton: true
    };

    return (
      <ModalContentWrapper modalConfig={modalConfig} size={this.props.size}>
        <Table tableConfig={tableConfigProducts} />
      </ModalContentWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    setNoProductsByProductIdsData: state.products.setNoProductsByProductIdsData,
    productsByproductIdsLoading: state.products.productsByproductIdsLoading,
    setProductsByproductIds: state.products.setProductsByproductIds
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(closeModal())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableModal);
