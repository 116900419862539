import React, { Component } from "react";
import { connect } from "react-redux";

import * as MODAL_TYPE from "utils/modal-types";

import { closeModal } from "ducks/modal";

import Tags from "containers/Modals/Tags/Tags";
import Markets from "containers/Modals/Markets/Markets";
import Delete from "containers/Modals/Delete/Delete";
import Table from "containers/Modals/Table/Table";
import CompetitorAnalyticsModal from "containers/Modals/CompetitorAnalytics/CompetitorAnalytics";
import ApplyRulePrompt from "../ApplyRulePrompt/ApplyRulePrompt";
import TableInputSubmit from "../TableInputSubmit/TableInputSubmit";
import ApproveProduct from "../ApproveProduct/ApproveProduct";
import CampaignAnalyticsSave from "../CampaignAnalyticsSave/CampaignAnalyticsSave";

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: "",
    };
  }

  componentDidMount() {
    const updateModalType = (type) => {
      this.setState({
        modalType: type,
      });
    };
    switch (this.props.modalType) {
      case MODAL_TYPE.TAGS:
        document.addEventListener("mousedown", this.handleOutsideClick);
        updateModalType(
          <Tags data={this.props.targetUnitData} size={this.props.modalSize} />
        );
        break;
      case MODAL_TYPE.MARKETS:
        return updateModalType(
          <Markets
            data={this.props.targetUnitData}
            size={this.props.modalSize}
          />
        );
      case MODAL_TYPE.DELETE:
        return updateModalType(
          <Delete
            data={this.props.targetUnitData}
            size={this.props.modalSize}
          />
        );
      case MODAL_TYPE.TABLE:
        return updateModalType(
          <Table data={this.props.targetUnitData} size={this.props.modalSize} />
        );
      case MODAL_TYPE.COMPETITOR_ANAYTICS:
        return updateModalType(
          <CompetitorAnalyticsModal
            data={this.props.targetUnitData}
            size={this.props.modalSize}
          />
        );
      case MODAL_TYPE.APPLY_RULE_PROMPT:
        return updateModalType(
          <ApplyRulePrompt
            data={this.props.targetUnitData}
            size={this.props.modalSize}
          />
        );
      case MODAL_TYPE.TABLE_INPUT_SUBMIT:
        return updateModalType(
          <TableInputSubmit
            data={this.props.targetUnitData}
            size={this.props.modalSize}
          />
        );
      case MODAL_TYPE.PRODUCT_APPROVE:
        return updateModalType(
          <ApproveProduct
            data={this.props.targetUnitData}
            size={this.props.modalSize}
          />
        );
      case MODAL_TYPE.CAMPAIGN_ANALYTICS_SAVE:
        return updateModalType(
          <CampaignAnalyticsSave
            data={this.props.targetUnitData}
            size={this.props.modalSize}
          />
        );

      default:
        return;
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (this.modalBox.contains(e.target)) {
      return;
    } else {
      this.props.closeModal();
    }
  };

  render() {
    return (
      <div className="absolute z-70 w-full h-full">
        <div className="w-full h-full relative">
          <div className="absolute w-full h-full bg-blue-darkest opacity-65" />
          <div className="w-full h-full absolute flex items-center justify-center">
            {this.props.modalSize === MODAL_TYPE.XLARGE && (
              <div
                ref={(node) => (this.modalBox = node)}
                className="xxxl:w-1/4 xxl:w-1/3 xl:w-1/3 lg:w-7/10 md:w-7/10 xxxl:h-2/4 xxl:h-6/10 w-8/10 h-7/10 bg-white"
              >
                {this.state.modalType}
              </div>
            )}
            {this.props.modalSize === MODAL_TYPE.LARGE && (
              <div
                ref={(node) => (this.modalBox = node)}
                className="xxl:w-1/4 xxl:h-2/4 w-1/3 h-2/4 bg-white"
              >
                {this.state.modalType}
              </div>
            )}

            {this.props.modalSize === MODAL_TYPE.MEDIUM && (
              <div
                ref={(node) => (this.modalBox = node)}
                className="xxl:w-1/4 xxl:h-2/4 min-w-2/3 h-2/4 bg-white"
              >
                {this.state.modalType}
              </div>
            )}
            {this.props.modalSize === MODAL_TYPE.SMALL && (
              <div
                ref={(node) => (this.modalBox = node)}
                className="w-72 h-72 bg-white"
              >
                {this.state.modalType}
              </div>
            )}
            {this.props.modalSize === MODAL_TYPE.XSMALL && (
              <div
                ref={(node) => (this.modalBox = node)}
                className="w-70 h-70 bg-white"
              >
                {this.state.modalType}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modalType: state.modal.modalType,
    targetUnitData: state.modal.targetUnitData,
    modalSize: state.modal.modalSize,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
