import React from "react";

export const TopRightButtons = props => {
  return (
    <div className="flex flex-row h-full bg-teal-dark px-6 text-white font-sans text-xxs uppercase rounded-l-sm w-full">
      {props.buttons.map((button, i) => {
        return (
          <div
            key={i}
            onClick={() => {
              button.onClick(button.icon, props.selectedRows);
              if (button.text.includes("clear")) {
                props.clearData();
              }
            }}
            className="opacity-75 hover:opacity-100 h-full flex flex-row items-center px-3 cursor-pointer"
          >
            <span className="px-1">{button.text}</span>
            <i key={button.id} className="material-icons text-lg">
              {button.icon}
            </i>
          </div>
        );
      })}
    </div>
  );
};
