import React, { Component } from "react";
import { connect } from "react-redux";
import ViewContainer from "containers/ViewContainer";
import Table from "components/Table";

import { getBrandsByMarket, getCategoriesByMarket } from "ducks/products";
import { filterPriceHistory } from "ducks/priceHistory";
import PriceHistoryFilter from "containers/Filters/PriceHistoryFilter";
import moment from "moment";

class PriceHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topVisible: true,
      defaultToDate: "",
      defaultFromDate: "",
      priceHistoryData: [
        {
          noTableData: true,
          name: "",
          articleNumber: "",
          brand: "",
          category: "",
          priceChange: "",
          relativePriceChange: "",
        },
      ],
      channel: "",
      hoverAddButtonGroup: false,
    };
  }

  componentDidMount() {
    let defaultQueryString =
      "&from=".concat(moment().subtract("weeks", 2).format("YYYY-MM-DD")) +
      "T00:00:00Z" +
      "&to=".concat(moment().format("YYYY-MM-DD")) +
      "T23:59:59Z";
    this.props.filterPriceHistory(1, 20, defaultQueryString, null, null);
    this.props.getCategoriesByMarket();
    this.props.getBrandsByMarket();
    this.setState({
      defaultToDate: moment().format("YYYY-MM-DD"),
      defaultFromDate: moment().subtract("weeks", 2).format("YYYY-MM-DD"),
      channel: localStorage.getItem("channel"),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.channel !== localStorage.getItem("channel")) {
      let defaultQueryString =
        "&from=".concat(moment().subtract("weeks", 2).format("YYYY-MM-DD")) +
        "T00:00:00Z" +
        "&to=".concat(moment().format("YYYY-MM-DD")) +
        "T23:59:59Z";
      this.setState({
        channel: localStorage.getItem("channel"),
      });
      this.props.filterPriceHistory(1, 20, defaultQueryString, null, null);
    }
  }

  getPriceHistoryData = (page, rowSize, attr, direction) => {
    if (this.props.isFiltered) {
      this.props.filterPriceHistory(
        page,
        rowSize,
        this.props.idList,
        attr,
        direction
      );
    } else {
      let defaultQueryString =
        "&from=".concat(moment().subtract("weeks", 2).format("YYYY-MM-DD")) +
        "T00:00:00Z" +
        "&to=".concat(moment().format("YYYY-MM-DD")) +
        "T23:59:59Z";
      this.props.filterPriceHistory(1, 20, defaultQueryString, null, null);
    }
  };

  circleButtonOnMouseEnterGroup = () => {
    this.setState({ hoverAddButtonGroup: true });
  };
  circleButtonOnMouseLeaveGroup = () => {
    this.setState({ hoverAddButtonGroup: false });
  };

  render() {
    let parsedpriceHistoryData = [];
    parsedpriceHistoryData =
      this.props.priceHistory.length > 0 &&
      this.props.priceHistory.map((item) => {
        let mainData = {
          id: item.id,
          name: item.name,
          articleNumber: item.articleNumber ? item.articleNumber : " ",
          brand: item.brandName,
          category: item.categoryName,
          priceChange: item.priceChange ? item.priceChange : "0",
          relativePriceChange: item.relativePriceChange
            ? item.relativePriceChange
            : "0",
          currentPrice: item.currentPrice ? item.currentPrice : "0",
          formerPrice: item.formerPrice ? item.formerPrice : "0",
        };
        return mainData;
      });

    const tableConfigPriceHistory = {
      data:
        parsedpriceHistoryData.length > 0
          ? parsedpriceHistoryData
          : this.state.priceHistoryData,
      name: "Price History Analytics",
      tableLoading: this.props.priceHistoryTableLoading,
      noData: this.props.priceHistoryNoData,
      onDoubleClick: this.onDoubleClickProducts,
      onClick: this.onClick,
      hiddenCols: [
        "id",
        "noTableData",
        "brandId",
        "categroyId",
        "currentPrice",
        "formerPrice",
      ],
      filter: true,
      paginationGetData: this.getPriceHistoryData,
      dataLength: this.props.totalPriceHistory,
      currentPage: this.props.currentPagePriceHistory,
      rowSize: this.props.rowSizePriceHistory,
      totalPages: this.props.totalPriceHistoryPages,
      attr: this.props.priceHistorySortingAttr,
      direction: this.props.priceHistorySortingDirection,
    };

    return (
      <ViewContainer
        hasPageButtons={true}
        topVisible={false}
        handleToggleTop={this.handleToggleTop}
      >
        <div className="relative my-8 pt-4">
          <Table tableConfig={tableConfigPriceHistory}>
            <PriceHistoryFilter
              clearSelections={this.clearProductSelections}
              defaultToDate={this.state.defaultToDate}
              defaultFromDate={this.state.defaultFromDate}
            />
          </Table>
        </div>
      </ViewContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    priceHistory: state.priceHistory.products,
    isFiltered: state.priceHistory.isFiltered,
    idList: state.priceHistory.idList,
    currentPagePriceHistory: state.tablePagination.priceHistoryPage,
    rowSizePriceHistory: state.tablePagination.priceHistoryRowSize,
    priceHistorySortingAttr: state.tablePagination.priceHistorySortingAttr,
    priceHistorySortingDirection:
      state.tablePagination.priceHistorySortingDirection,
    totalPriceHistoryPages: state.tablePagination.priceHistoryTotalPages,
    showAllProducts: state.priceHistory.showAllProducts,
    priceHistoryTableLoading: state.priceHistory.priceHistoryTableLoading,
    totalPriceHistory: state.priceHistory.totalPriceHistory,
    priceHistoryNoData: state.priceHistory.priceHistoryNoData,
    // isTableLarge: state.sidepanel.isTableLarge,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    filterPriceHistory: (page, rowSize, idList, attr, sorting) =>
      dispatch(filterPriceHistory(page, rowSize, idList, attr, sorting)),
    getCategoriesByMarket: () => dispatch(getCategoriesByMarket()),
    getBrandsByMarket: () => dispatch(getBrandsByMarket()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceHistory);
