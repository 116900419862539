import React, { Component } from "react";
import { connect } from "react-redux";
// import { visitedNotification } from "ducks/notifications";
import NotificationDropdown from "components/NotificationDropdown/NotificationsDropdown";
import { getAllNotifications } from "ducks/notifications";

import { Link } from "react-router-dom";
import { CircleButton } from "components/CircleButton";
import { TooltipRight } from "components/Tooltips";
import NorwegianFlag from "assets/flags/norwegian_flag";
import SwedishFlag from "assets/flags/swe_flag";
import DanishFlag from "assets/flags/danish_flag";
import FinishFlag from "assets/flags/finish_flag";
import UKFlag from "assets/flags/uk_flag";
import GermanFlag from "assets/flags/german_flag";
import NetherlandsFlag from "assets/flags/netherlands_flag";

class PageTop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoverTooltip: false,
      showNotifications: false,
      userName: false,
      fullscreen: false,
      settings: false,
      notification: false,
    };
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);

    this.props.getAllNotifications();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }
  tooltipOnMouseEnter = (name) => {
    this.setState({
      [name]: true,
    });
  };
  tooltipOnMouseLeave = (name) => {
    this.setState({
      [name]: false,
    });
  };

  handleOutsideClick = (e) => {
    if (this.closeDropdown.contains(e.target)) {
      return;
    } else {
      this.setState({
        showNotifications: false,
      });
    }
  };
  setFlag = () => {
    switch (localStorage.getItem("name").toLowerCase()) {
      case "norge":
        return <NorwegianFlag />;
      case "norway":
        return <NorwegianFlag />;
      case "sverige":
        return <SwedishFlag />;
      case "sweden":
        return <SwedishFlag />;
      case "dansk":
        return <DanishFlag />;
      case "denmark":
        return <DanishFlag />;
      case "tyskland":
        return <GermanFlag />;
      case "germany":
        return <GermanFlag />;
      case "finland":
        return <FinishFlag />;
      case "uk":
        return <UKFlag />;
      case "storbritannien":
        return <UKFlag />;
      case "netherlands":
        return <NetherlandsFlag />;
      case "nederländerna":
        return <NetherlandsFlag />;
      default:
        return;
    }
  };

  showNotifications = () => {
    this.setState((prevState) => ({
      showNotifications: !prevState.showNotifications,
    }));
  };

  render() {
    let userName = localStorage.getItem("user") && localStorage.getItem("user");
    return (
      <div className={`w-full flex flex-row ${this.props.classList}`}>
        <div className="flex-1 flex flex-row justify-end">
          <div className="w-1 h-full relative ">
            {this.state.notification && (
              <div className="h-6 absolute pin-r mr-4 xxl:mt-1">
                <TooltipRight text="NOTIFICATIONS" />
              </div>
            )}
          </div>
          <div ref={(node) => (this.closeDropdown = node)} className="relative">
            <CircleButton
              canUse={true}
              onClick={this.showNotifications}
              active={this.props.sidepanel.isOpen}
              iconOne="notification_important"
              iconTwo="notification_important"
              onMouseEnter={this.tooltipOnMouseEnter}
              onMouseLeave={this.tooltipOnMouseLeave}
              name="notification"
            />
            {this.props.notifications &&
              this.props.notifications.filter(
                (notification) => notification.read === false
              ).length > 0 && (
                <div
                  className={`${
                    this.props.notifications.length > 9 ? "h-5 w-5" : "h-4 w-4"
                  }  absolute rounded-full flex items-center justify-center bg-red -ml-1 -mt-3`}
                >
                  <span className="text-white text-xs">
                    {this.props.notifications.filter(
                      (notification) => notification.read === false
                    ).length > 99
                      ? "99+"
                      : this.props.notifications.filter(
                          (notification) => notification.read === false
                        ).length}
                  </span>
                </div>
              )}
            <div className="absolute bg-white z-30 -ml-25 mt-4">
              {this.state.showNotifications && (
                <NotificationDropdown
                  showNotifications={this.showNotifications}
                  notifications={this.props.notifications}
                />
              )}
            </div>
          </div>

          {/* {userName && (
            <div className="pl-2 flex flex-row xxl:h-10 h-8">
              <div className="w-1 h-full relative ">
                {this.state.userName && (
                  <div className="h-6 min-w-16 absolute pin-r mr-1 xxl:mt-1">
                    <TooltipRight
                      text={
                        JSON.parse(userName).firstName +
                        " " +
                        JSON.parse(userName).lastName
                      }
                    />
                  </div>
                )}
              </div>
              <div
                onMouseEnter={() => this.tooltipOnMouseEnter("userName")}
                onMouseLeave={() => this.tooltipOnMouseLeave("userName")}
                className="xxl:w-10 xxl:h-10 h-8 w-8 cursor-pointer rounded-full uppercase flex items-center justify-center text-teal-dark tracking-wide xxl:text-lg text-md font-sans bg-white relative ml-3"
              >
                {JSON.parse(userName).firstName[0] +
                  JSON.parse(userName).lastName[0]}
              </div>
            </div>
          )} */}

          {localStorage.getItem("market") && (
            <div className="xxl:w-10 xxl:h-10 h-8 w-8 relative ml-3">
              {this.setFlag()}
            </div>
          )}

          <div className="pl-2 flex flex-row xxl:h-10 h-8">
            <div className="w-1 h-full relative ">
              {this.state.fullscreen && (
                <div className="h-6 absolute pin-r mr-4 xxl:mt-1">
                  <TooltipRight text="FULLSCREEN" />
                </div>
              )}
            </div>

            <CircleButton
              canUse={true}
              onClick={this.props.sidepanel.handleOpen}
              active={this.props.sidepanel.isOpen}
              iconOne="fullscreen"
              iconTwo="fullscreen_exit"
              onMouseEnter={this.tooltipOnMouseEnter}
              onMouseLeave={this.tooltipOnMouseLeave}
              name="fullscreen"
            />
          </div>
          {this.props.viewTitle.hasTitle && (
            <CircleButton
              canUse={true}
              onClick={this.props.viewTitle.handleToggleTop}
              active={this.props.viewTitle.topVisible}
              iconOne="unfold_less"
              iconTwo="unfold_more"
            />
          )}
          <div className="pl-2 flex flex-row xxl:h-10 h-8">
            <div className="w-1 h-full relative ">
              {this.state.settings && (
                <div className="h-6 absolute pin-r mr-4 xxl:mt-1">
                  <TooltipRight text="SETTINGS" />
                </div>
              )}
            </div>
            <Link to="/settings" className="text-white">
              <CircleButton
                canUse={true}
                active={!window.location.href.includes("settings")}
                iconOne="settings"
                iconTwo="settings"
                onMouseEnter={this.tooltipOnMouseEnter}
                onMouseLeave={this.tooltipOnMouseLeave}
                name="settings"
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllNotifications: () => dispatch(getAllNotifications()),
  };
};

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.notifications,
    visited: state.notifications.visited,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageTop);
