import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal, openModal } from "ducks/modal";
import * as MODAL_TYPE from "utils/modal-types";

import { getPreview } from "ducks/priceRule";
import {
  getProductsByProductIds,
  clearProductByproductIds
} from "ducks/products";
import { LargeLoadingSpinner } from "../LargeLoadingSpinner/LargeLoadingSpinner";

class PreviewPanel extends Component {
  constructor() {
    super();

    this.state = {
      mLessThan: [],
      mOneToFive: [],
      mSixToTen: [],
      mElevenToTwenty: [],
      mTwentyoneToThirty: [],
      mThirtyOneToForty: [],
      mMoreThan: [],
      rrpMoreThan: [],
      rrpZero: [],
      rrpOneToFour: [],
      rrpFive: [],
      rrpSixToNine: [],
      rrpTen: [],
      rrpElevenToNineteen: [],
      rrpTwenty: [],
      rrptwentyOneToTwentyNine: [],
      rrpThirty: [],
      rrpLessThan: [],
      setPreview: false
    };
  }

  componentDidMount() {
    this.setPreview();
  }

  componentWillUnmount() {
    this.props.getPreview();
    clearTimeout(this.setPreview());
  }

  handleShowProducts = selected => {
    this.props.clearProductByproductIds();
    let productIds = [];

    selected.map(item => {
      return productIds.push(item.productId);
    });
    this.props.getProductsByProductIds(productIds);
    if (selected.length > 0) {
      this.props.openModal(
        this.props.setProductsByproductIds,
        MODAL_TYPE.TABLE,
        MODAL_TYPE.MEDIUM
      );
    }
  };

  setPreview = () => {
    if (!this.props.data) {
      setTimeout(() => {
        this.setPreview();
      }, 1000);
    } else {
      return this.sortingMargin(), this.sortingRRP();
    }
  };

  sortingMargin = () => {
    let array = this.props.data;

    array.map(data => {
      if (data.margin === null) {
        return {
          setPreview: true
        };
      } else if (Math.round(data.margin) < 0) {
        this.setState(state => {
          const mLessThan = state.mLessThan.concat(data);
          return {
            mLessThan,
            setPreview: true
          };
        });
      } else if (Math.round(data.margin) >= 1 && Math.round(data.margin) <= 5) {
        this.setState(state => {
          const mOneToFive = state.mOneToFive.concat(data);
          return {
            mOneToFive,
            setPreview: true
          };
        });
      } else if (
        Math.round(data.margin) >= 6 &&
        Math.round(data.margin) <= 10
      ) {
        this.setState(state => {
          const mSixToTen = state.mSixToTen.concat(data);
          return {
            mSixToTen,
            setPreview: true
          };
        });
      } else if (
        Math.round(data.margin) >= 11 &&
        Math.round(data.margin) <= 20
      ) {
        this.setState(state => {
          const mElevenToTwenty = state.mElevenToTwenty.concat(data);
          return {
            mElevenToTwenty,
            setPreview: true
          };
        });
      } else if (
        Math.round(data.margin) >= 21 &&
        Math.round(data.margin) <= 30
      ) {
        this.setState(state => {
          const mTwentyoneToThirty = state.mTwentyoneToThirty.concat(data);
          return {
            mTwentyoneToThirty,
            setPreview: true
          };
        });
      } else if (
        Math.round(data.margin) >= 31 &&
        Math.round(data.margin) <= 40
      ) {
        this.setState(state => {
          const mThirtyOneToForty = state.mThirtyOneToForty.concat(data);
          return {
            mThirtyOneToForty,
            setPreview: true
          };
        });
      } else {
        this.setState(state => {
          const mMoreThan = state.mMoreThan.concat(data);
          return {
            mMoreThan,
            setPreview: true
          };
        });
      }
    });
  };
  sortingRRP = () => {
    this.props.data &&
      this.props.data.map(data => {
        if (data.rrp === null) {
          return {
            setPreview: true
          };
        } else if (data.rrp > 0) {
          this.setState(state => {
            const rrpMoreThan = state.rrpMoreThan.concat(data);
            return {
              rrpMoreThan,
              setPreview: true
            };
          });
        } else if (Math.round(data.rrp) === 0) {
          this.setState(state => {
            const rrpZero = state.rrpZero.concat(data);
            return {
              rrpZero,
              setPreview: true
            };
          });
        } else if (Math.round(data.rrp) <= -1 && Math.round(data.rrp) >= -4) {
          this.setState(state => {
            const rrpOneToFour = state.rrpOneToFour.concat(data);
            return {
              rrpOneToFour,
              setPreview: true
            };
          });
        } else if (Math.round(data.rrp) === -5) {
          this.setState(state => {
            const rrpFive = state.rrpFive.concat(data);
            return {
              rrpFive,
              setPreview: true
            };
          });
        } else if (Math.round(data.rrp) <= -6 && Math.round(data.rrp) >= -9) {
          this.setState(state => {
            const rrpSixToNine = state.rrpSixToNine.concat(data);
            return {
              rrpSixToNine,
              setPreview: true
            };
          });
        } else if (Math.round(data.rrp) === -10) {
          this.setState(state => {
            const rrpTen = state.rrpTen.concat(data);
            return {
              rrpTen,
              setPreview: true
            };
          });
        } else if (Math.round(data.rrp) <= -11 && Math.round(data.rrp) >= -19) {
          this.setState(state => {
            const rrpElevenToNineteen = state.rrpElevenToNineteen.concat(data);
            return {
              rrpElevenToNineteen,
              setPreview: true
            };
          });
        } else if (Math.round(data.rrp) === -20) {
          this.setState(state => {
            const rrpTwenty = state.rrpTwenty.concat(data);
            return {
              rrpTwenty,
              setPreview: true
            };
          });
        } else if (Math.round(data.rrp) <= -21 && Math.round(data.rrp) >= -29) {
          this.setState(state => {
            const rrptwentyOneToTwentyNine = state.rrptwentyOneToTwentyNine.concat(
              data
            );
            return {
              rrptwentyOneToTwentyNine,
              setPreview: true
            };
          });
        } else if (Math.round(data.rrp) === -30) {
          this.setState(state => {
            const rrpThirty = state.rrpThirty.concat(data);
            return {
              rrpThirty,
              setPreview: true
            };
          });
        } else if (Math.round(data.rrp) <= -31) {
          this.setState(state => {
            const rrpLessThan = state.rrpLessThan.concat(data);
            return {
              rrpLessThan,
              setPreview: true
            };
          });
        }
      });
  };
  render() {
    return (
      <div className=" table table-fixed w-full h-full border-collapse mt-6">
        {this.props.loadPreview ? (
          LargeLoadingSpinner
        ) : this.props.noData ? (
          <h2 className="uppercase text-grey-dark font-thin">
            No Data Available
          </h2>
        ) : (
          <>
            <div className="table-row my-4 ">
              <p className="text-teal-light font-thin table-cell text-center">
                Margin (%)
              </p>
              <p className="text-grey font-thin table-cell text-center">
                Items
              </p>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() => this.handleShowProducts(this.state.mLessThan)}
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                &#60; 0
              </div>
              <div className="py-2 text-sm table-cell  text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.mLessThan.length < 1 ||
                this.state.mLessThan === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.mLessThan.length}
                  </div>
                )}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() => this.handleShowProducts(this.state.mOneToFive)}
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                1 &#9473; 5{" "}
              </div>
              <div className=" py-2 text-sm table-cell text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.mOneToFive.length < 1 ||
                this.state.mOneToFive === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.mOneToFive.length}
                  </div>
                )}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() => this.handleShowProducts(this.state.mSixToTen)}
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                6 &#9473; 10
              </div>
              <div className=" py-2 text-sm table-cell  text-grey-dark py-1 h-8 w-12 text-center ">
                {" "}
                {this.state.mSixToTen.length < 1 ||
                this.state.mSixToTen === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.mSixToTen.length}
                  </div>
                )}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() =>
                this.handleShowProducts(this.state.mElevenToTwenty)
              }
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                11 &#9473; 20
              </div>
              <div className=" py-2 text-sm table-cell text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.mElevenToTwenty.length < 1 ||
                this.state.mElevenToTwenty === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.mElevenToTwenty.length}
                  </div>
                )}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() =>
                this.handleShowProducts(this.state.mTwentyoneToThirty)
              }
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                21 &#9473; 30{" "}
              </div>
              <div className=" py-2 text-sm table-cell  text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.mTwentyoneToThirty.length < 1 ||
                this.state.mTwentyoneToThirty === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.mTwentyoneToThirty.length}
                  </div>
                )}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() =>
                this.handleShowProducts(this.state.mThirtyOneToForty)
              }
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                31 &#9473; 40{" "}
              </div>
              <div className=" py-2 text-sm table-cell text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.mThirtyOneToForty.length < 1 ||
                this.state.mThirtyOneToForty === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.mThirtyOneToForty.length}
                  </div>
                )}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() => this.handleShowProducts(this.state.mMoreThan)}
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                &#62; 40{" "}
              </div>
              <div className=" py-2 text-sm table-cell  text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.mMoreThan.length < 1 ||
                this.state.mMoreThan === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {" "}
                    {this.state.mMoreThan.length}
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-row justify-start my-4">
              <p className="text-teal-light font-thin flex flex-col">RRP (%)</p>
            </div>

            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() => this.handleShowProducts(this.state.rrpMoreThan)}
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                &#62; 0
              </div>
              <div className="py-2 text-sm table-cell  text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.rrpMoreThan.length < 1 ||
                this.state.rrpMoreThan === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.rrpMoreThan.length}
                  </div>
                )}{" "}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() => this.handleShowProducts(this.state.rrpZero)}
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                {" "}
                = 0{" "}
              </div>
              <div className=" py-2 text-sm table-cell text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.rrpZero.length < 1 ||
                this.state.rrpZero === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.rrpZero.length}
                  </div>
                )}{" "}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() => this.handleShowProducts(this.state.rrpOneToFour)}
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                -1 &#9473; -4
              </div>
              <div className=" py-2 text-sm table-cell  text-grey-dark py-1 h-8 w-12 text-center ">
                {" "}
                {this.state.rrpOneToFour.length < 1 ||
                this.state.rrpOneToFour === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.rrpOneToFour.length}
                  </div>
                )}{" "}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() => this.handleShowProducts(this.state.rrpFive)}
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                -5
              </div>
              <div className=" py-2 text-sm table-cell text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.rrpFive.length < 1 ||
                this.state.rrpFive === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.rrpFive.length}
                  </div>
                )}{" "}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() => this.handleShowProducts(this.state.rrpSixToNine)}
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                -6 &#9473; -9
              </div>
              <div className=" py-2 text-sm table-cell text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.rrpSixToNine.length < 1 ||
                this.state.rrpSixToNine === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.rrpSixToNine.length}
                  </div>
                )}{" "}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() => this.handleShowProducts(this.state.rrpTen)}
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                -10
              </div>
              <div className=" py-2 text-sm table-cell text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.rrpTen.length < 1 ||
                this.state.rrpTen === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.rrpTen.length}
                  </div>
                )}{" "}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() =>
                this.handleShowProducts(this.state.rrpElevenToNineteen)
              }
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                -11 &#9473; -19
              </div>
              <div className=" py-2 text-sm table-cell  text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.rrpElevenToNineteen.length < 1 ||
                this.state.rrpElevenToNineteen === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.rrpElevenToNineteen.length}
                  </div>
                )}{" "}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() => this.handleShowProducts(this.state.rrpTwenty)}
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                -20
              </div>
              <div className=" py-2 text-sm table-cell text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.rrpTwenty.length < 1 ||
                this.state.rrpTwenty === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.rrpTwenty.length}
                  </div>
                )}{" "}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() =>
                this.handleShowProducts(this.state.rrptwentyOneToTwentyNine)
              }
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                -21 &#9473; -29
              </div>
              <div className=" py-2 text-sm table-cell  text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.rrptwentyOneToTwentyNine.length < 1 ||
                this.state.rrptwentyOneToTwentyNine === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.rrptwentyOneToTwentyNine.length}
                  </div>
                )}{" "}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() => this.handleShowProducts(this.state.rrpThirty)}
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                -30
              </div>
              <div className=" py-2 text-sm table-cell  text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.rrpThirty.length < 1 ||
                this.state.rrpThirty === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.rrpThirty.length}
                  </div>
                )}{" "}
              </div>
            </div>
            <div
              className="table-row hover:bg-grey-lightest py-2"
              onClick={() => this.handleShowProducts(this.state.rrpLessThan)}
            >
              <div className="text-teal-dark py-2 text-sm table-cell text-center font-semibold">
                &#60; -31
              </div>
              <div className=" py-2 text-sm table-cell  text-grey-dark py-1 h-8 w-12 text-center">
                {this.state.rrpLessThan.length < 1 ||
                this.state.rrpLessThan === undefined ? null : (
                  <div className=" bg-grey-light rounded-full py-1 px-2 w-1/2">
                    {this.state.rrpLessThan.length}
                  </div>
                )}{" "}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    noData: state.priceRule.noDataPreview,
    loadPreview: state.priceRule.loadPreview,
    setNoProductsByProductIdsData: state.products.setNoProductsByProductIdsData,
    productsByproductIdsLoading: state.products.productsByproductIdsLoading,
    setProductsByproductIds: state.products.setProductsByproductIds
  };
};

const mapDispachToProps = dispatch => {
  return {
    getPreview: () => dispatch(getPreview()),
    getProductsByProductIds: id => dispatch(getProductsByProductIds(id)),
    clearProductByproductIds: () => dispatch(clearProductByproductIds()),
    openModal: (targetUnitData, modalType, modalSize) =>
      dispatch(openModal(targetUnitData, modalType, modalSize))
  };
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(PreviewPanel);
