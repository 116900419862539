import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal } from "ducks/modal";
import Table from "components/Table";
import { ModalContentWrapper } from "containers/Modals/ModalContainer/ModalContentWrapper";

class CompetitorAnalyticsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canContinue: true,
      productData: [
        {
          noTableData: true,
          id: "",
          name: "",
          articleNumber: "",
          brand: "",
          category: ""
        }
      ],
      active: 1
    };
  }

  handleActiveTable = i => {
    this.setState({
      active: i
    });
  };

  render() {
    let parsedHigherData =
      this.props.setHigherProducts &&
      this.props.setHigherProducts.map(item => {
        return {
          id: item.articleNumber,
          name: {
            hideSorting: true,
            value: item.name
              ? item.name.length > 20
                ? item.name.slice(0, 20) + "..."
                : item.name
              : "-"
          },
          articleNumber: {
            hideSorting: true,
            value: item.articleNumber
          },
          brand: {
            hideSorting: true,
            value: item.brand
              ? item.brand.length > 20
                ? item.brand.slice(0, 20) + "..."
                : typeof item.brand === "object"
                ? item.brand.name
                : item.brand
              : "-"
          },
          category: {
            hideSorting: true,
            value: item.category
              ? item.category.length > 20
                ? item.category.slice(0, 20) + "..."
                : typeof item.category === "object"
                ? item.category.name
                : item.category
              : "-"
          }
          // campaigns: item.campaigns
        };
      });
    let parsedSameData =
      this.props.setSameProducts &&
      this.props.setSameProducts.map(item => {
        return {
          id: item.articleNumber,
          name: {
            hideSorting: true,
            value: item.name
              ? item.name.length > 20
                ? item.name.slice(0, 20) + "..."
                : item.name
              : "-"
          },
          articleNumber: {
            hideSorting: true,
            value: item.articleNumber
          },
          brand: {
            hideSorting: true,
            value: item.brand
              ? item.brand.length > 20
                ? item.brand.slice(0, 20) + "..."
                : typeof item.brand === "object"
                ? item.brand.name
                : item.brand
              : "-"
          },
          category: {
            hideSorting: true,
            value: item.category
              ? item.category.length > 20
                ? item.category.slice(0, 20) + "..."
                : typeof item.category === "object"
                ? item.category.name
                : item.category
              : "-"
          }
        };
      });
    let parsedLowerData =
      this.props.setLowerProducts &&
      this.props.setLowerProducts.map(item => {
        return {
          id: item.articleNumber,
          name: {
            hideSorting: true,
            value: item.name
              ? item.name.length > 20
                ? item.name.slice(0, 20) + "..."
                : item.name
              : "-"
          },
          articleNumber: {
            hideSorting: true,
            value: item.articleNumber
          },
          brand: {
            hideSorting: true,
            value: item.brand
              ? item.brand.length > 20
                ? item.brand.slice(0, 20) + "..."
                : typeof item.brand === "object"
                ? item.brand.name
                : item.brand
              : "-"
          },
          category: {
            hideSorting: true,
            value: item.category
              ? item.category.length > 20
                ? item.category.slice(0, 20) + "..."
                : typeof item.category === "object"
                ? item.category.name
                : item.category
              : "-"
          }
        };
      });

    const tableConfigHigher = {
      data:
        parsedHigherData.length > 0 ? parsedHigherData : this.state.productData,
      name: "Products",
      tableLoading: this.props.loadingProductsCompetitorAnalytics,
      noData: this.props.noDataProductsCompAnalytics,
      // onDoubleClick: this.onDoubleClickProducts,
      onClick: this.onClick,
      openExpanded: true,
      hideExpanding: true,
      hiddenCols: [
        "id",
        "noTableData",
        "parentId",
        "mongoId",
        "extId",
        "created",
        "channel",
        "updated",
        "exists",
        "purchasePriceScd",
        "recommendedRetailPriceScd",
        "salesPriceScd",
        "tags"
      ]
    };
    const tableConfigSame = {
      data: parsedSameData.length > 0 ? parsedSameData : this.state.productData,
      name: "Products",
      tableLoading: this.props.loadingProductsCompetitorAnalytics,
      noData: this.props.noDataProductsCompAnalytics,
      // onDoubleClick: this.onDoubleClickProducts,
      onClick: this.onClick,
      openExpanded: true,
      hideExpanding: true,
      hiddenCols: [
        "id",
        "noTableData",
        "parentId",
        "mongoId",
        "extId",
        "created",
        "channel",
        "updated",
        "exists",
        "purchasePriceScd",
        "recommendedRetailPriceScd",
        "salesPriceScd",
        "tags"
      ]
    };
    const tableConfigLower = {
      data:
        parsedLowerData.length > 0 ? parsedLowerData : this.state.productData,
      name: "Products",
      tableLoading: this.props.loadingProductsCompetitorAnalytics,
      noData: this.props.noDataProductsCompAnalytics,
      // onDoubleClick: this.onDoubleClickProducts,
      onClick: this.onClick,
      openExpanded: true,
      hideExpanding: true,
      hiddenCols: [
        "id",
        "noTableData",
        "parentId",
        "mongoId",
        "extId",
        "created",
        "channel",
        "updated",
        "exists",
        "purchasePriceScd",
        "recommendedRetailPriceScd",
        "salesPriceScd",
        "tags"
      ]
    };

    const modalConfig = {
      title: "Products",
      hasBottomButtons: true,
      closeText: "Close",
      handleClose: this.props.closeModal,
      warning: false,
      hideSubmitButton: true
    };

    return (
      <ModalContentWrapper modalConfig={modalConfig} size={this.props.size}>
        <ul className="flex flex-row w-full justify-center">
          <li
            onClick={() => this.handleActiveTable(1)}
            className={`flex flex-col px-4 font-thin cursor-pointer ${
              this.state.active === 1 ? "text-teal border-b-2 border-teal" : " "
            }`}
          >
            Higher
          </li>
          <li
            onClick={() => this.handleActiveTable(2)}
            className={`flex flex-col px-4 font-thin cursor-pointer ${
              this.state.active === 2 ? "text-teal border-b-2 border-teal" : " "
            }`}
          >
            Same
          </li>
          <li
            onClick={() => this.handleActiveTable(3)}
            className={`flex flex-col px-4 font-thin cursor-pointer ${
              this.state.active === 3 ? "text-teal border-b-2 border-teal" : " "
            }`}
          >
            Lower
          </li>
        </ul>

        {this.state.active === 1 ? (
          <Table tableConfig={tableConfigHigher} />
        ) : null}
        {this.state.active === 2 ? (
          <Table tableConfig={tableConfigSame} />
        ) : null}
        {this.state.active === 3 ? (
          <Table tableConfig={tableConfigLower} />
        ) : null}
      </ModalContentWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    setNoProductsByProductIdsData: state.products.setNoProductsByProductIdsData,
    productsByproductIdsLoading: state.products.productsByproductIdsLoading,
    setHigherProducts: state.competitorAnalytics.setHigherProducts,
    setSameProducts: state.competitorAnalytics.setSameProducts,
    setLowerProducts: state.competitorAnalytics.setLowerProducts,
    loadingProductsCompetitorAnalytics:
      state.competitorAnalytics.loadingProductsCompetitorAnalytics,
    noDataProductsCompAnalytics:
      state.competitorAnalytics.noDataProductsCompAnalytics
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(closeModal())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetitorAnalyticsModal);
