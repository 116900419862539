import React from "react";
import SingleSelectDropdown from "components/SingleSelectDropdown";

const SelectExpression = props => {
  const renderSummary = () => {
    return (
      <>
        {props.formState === "edit" ? (
          <p className="flex-row h-8 text-grey-dark">
            Created:{" "}
            <span className="float-right text-teal">{props.created}</span>
          </p>
        ) : // <p className="flex-row h-8 text-grey-dark">
        //   Updated:
        //   <span className="float-right text-teal">{props.updated}</span>
        // </p>
        null}
      </>
    );
  };

  let valueOptions = [];
  if (props.attribute === undefined) {
    valueOptions = [];
  } else if (props.attribute.name === "Category") {
    valueOptions = props.categoryOptions;
  } else if (props.attribute.name === "Brand") {
    valueOptions = props.brandOptions;
  } else if (props.attribute.name === "Tag") {
    valueOptions = props.tagOptions;
  } else valueOptions = [];
  return (
    <>
      {" "}
      <div className="flex mb-4 mt-6 xxl:px-4 relative w-4/5">
        <div className="absolute pt-8 pr-16 top-0 right-0 inline-block m-0 w-1/3 flex-col text-sm">
          {renderSummary()}
        </div>
        <ul className="w-2/3 m-0 inline-block align-top pl-8 pt-6">
          {Object.entries(props.data).length < 1 ? null : (
            <li
              onClick={index => props.handleActive(props.index, "group")}
              className={`cursor-pointer inline align-middle text-teal ${
                props.active === "group" ? "font-bold" : "font-thin"
              } `}
            >
              {props.active === "group" ? (
                <i className="material-icons text-4xl text-grey-light align-middle">
                  close
                </i>
              ) : (
                <i className="material-icons text-4xl text-grey-light align-middle">
                  add
                </i>
              )}
              Group
            </li>
          )}
          <li
            onClick={index => props.handleActive(props.index, "expression")}
            className={`cursor-pointer ${
              Object.entries(props.data).length < 1 ? null : "ml-10"
            } align-middle inline text-teal ${
              props.active === "expression" ? "font-bold" : "font-thin"
            } `}
          >
            {props.active === "expression" ? (
              <i className="material-icons text-4xl text-grey-light align-middle">
                close
              </i>
            ) : (
              <i className="material-icons text-4xl text-grey-light align-middle">
                add
              </i>
            )}
            Expression
          </li>
        </ul>
      </div>
      <div className="flex flex-row mb-4 xxl:px-4 ">
        <div className="flex justify-between mb-10 w-2/3 pl-8 ">
          <div className=" 2/5 ">
            <SingleSelectDropdown
              width="w-full"
              options={props.attributeOptions}
              selected={props.attribute ? props.attribute : {}}
              name={`attributes${props.id}`}
              handleSelect={(a, b) => props.handleSelected(a, b)}
              placeholder="Select an attribute"
              error={props.errorAttribute}
            />
          </div>
          <div className=" w-1/5 mx-8">
            <SingleSelectDropdown
              width="w-full"
              options={props.relationalOperatorOptions}
              selected={props.relationalOperator}
              name={`relationalOperatorExp${props.id}`}
              handleSelect={(a, b) => props.handleSelected(a, b)}
              placeholder="Relation"
              error={props.errorOperator}
            />
          </div>
          <div className=" w-2/5">
            <SingleSelectDropdown
              width="w-full"
              options={valueOptions}
              selected={props.value ? props.value : {}}
              name={`value${props.id}`}
              handleSelect={(a, b) => props.handleSelected(a, b)}
              placeholder="Select Value"
              error={props.errorValue}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectExpression;
