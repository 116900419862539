import React, { Component } from "react";
import { connect } from "react-redux";
import SingleSelectDropdown from "components/SingleSelectDropdown";
import { getAnalytics } from "ducks/competitorAnalytics";

class CompetitorsFilter extends Component {
  constructor() {
    super();

    this.state = {
      attributeOptions: [],

      competitorOptions: []
    };
  }

  componentDidMount() {
    let config = this.props.config && this.props.config.attributes;
    let index = config.findIndex(item => item.name === "Tag");
    if (index > -1) {
      config.splice(index, 1);
    }
    this.setState({
      competitorOptions:
        this.props.competitors.length > 0 ? this.props.competitors : [],
      attributeOptions: config
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.competitors && props.competitors !== state.competitorOptions) {
      return {
        competitorOptions: props.competitors
      };
    }
    return null;
  }

  render() {
    return (
      <div className="flex flex-row md:w-3/5 w-full justify-between">
        <div className="flex flex-col md:w-2/3 w-1/5 my-auto ">
          <SingleSelectDropdown
            width="full"
            name="attribute"
            label="show"
            placeholder="Show"
            options={this.state.attributeOptions}
            selected={this.props.attribute}
            handleSelect={this.props.handleSelected}
          />
        </div>
        <div className="flex flex-col md:w-2/3 w-1/5 my-auto mx-2">
          <SingleSelectDropdown
            width="full"
            name="competitor"
            label="competitors"
            options={this.state.competitorOptions}
            selected={this.props.competitor}
            handleSelect={this.props.handleSelected}
            placeholder="select competitor"
          />
        </div>
        <div className="flex flex-col md:w-2/3 w-1/5 items-baseline pt-6 ">
          <div
            className="py-2 px-3 h-10 flex my-auto items-end justify-center bg-teal-dark hover:bg-teal text-xs text-white cursor-pointer"
            onClick={() => this.props.handleFilter()}
          >
            CREATE REPORT
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToprops = state => {
  return {
    competitors: state.priceRule.competitorList,
    config: state.groups.config
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAnalytics: (values, page, rowSize, attr, direction) =>
      dispatch(getAnalytics(values, page, rowSize, attr, direction))
  };
};
export default connect(mapStateToprops, mapDispatchToProps)(CompetitorsFilter);
