import React, { Component } from "react";

import { SmallCircleButton } from "components/SmallCircleButton/SmallCircleButton";

export default class ProductListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verify: false,
      unMatch: false,
    };
  }
  toggleTooltips = (name, value) => {
    this.setState({ [name]: value });
  };
  render() {
    return (
      <div
        key={this.props.articleNo}
        // 52532
        className={`w-full flex flex-row h-18 items-center my-2 ${
          this.props.ownProduct ? " " : !this.props.active ? "relative " : ""
        }`}
      >
        <div
          // 52532
          className={`w-full flex flex-row  items-center my-2 ${
            this.props.ownProduct
              ? "bg-table-grey "
              : !this.props.active
              ? "inactive-product-list-card "
              : ""
          }`}
        >
          <div className="w-1/10 px-1 h-full flex items-center text-xs text-teal-dark justify-center text-center">
            {this.props.competitor}
          </div>
          <div className="w-1/10 h-18 px-1 h-full flex items-center text-xs text-grey-darker justify-center text-center">
            <img
              className="object-scale-down h-18 w-full"
              src={this.props.image}
              alt="X"
            />
          </div>
          <div
            className={`w-1/10 px-1 h-full flex items-center text-xs text-grey-darker justify-center text-center ${
              this.props.ownProduct && "bg-table-grey"
            }`}
          >
            {this.props.name
              ? this.props.name.length > 25
                ? this.props.name.slice(0, 25) + "..."
                : this.props.name
              : "-"}
          </div>
          <div
            className={`w-1/10 px-1 h-full flex items-center text-xs text-grey-darker justify-center text-center ${
              this.props.ownProduct && "bg-table-grey"
            }`}
          >
            {this.props.brand
              ? this.props.brand.length > 25
                ? this.props.brand.slice(0, 25) + "..."
                : this.props.brand
              : "-"}
          </div>
          <div
            className={`w-1/10 px-1 h-full flex items-center text-xs text-grey-darker justify-center text-center ${
              this.props.ownProduct && "bg-table-grey"
            }`}
          >
            {this.props.category
              ? this.props.category.length > 25
                ? this.props.category.slice(0, 25) + "..."
                : this.props.category
              : "-"}
          </div>
          <div
            className={`w-1/10 px-1 h-full flex items-center text-xs text-grey-darker justify-center text-center ${
              this.props.ownProduct && "bg-table-grey"
            }`}
          >
            {this.props.price}
          </div>
          <div
            className={`w-1/10 px-1 h-full flex items-center text-xs text-grey-darker justify-center text-center ${
              this.props.ownProduct && "bg-table-grey"
            }`}
          >
            {!this.props.ownProduct &&
              (this.props.active ? (
                "Active"
              ) : (
                <span className="text-grey">Not Active</span>
              ))}
          </div>
          <div
            className={`w-1/10 px-1 h-full flex items-center text-xs text-grey-darker justify-center text-center ${
              this.props.ownProduct && "bg-table-grey"
            }`}
          >
            {this.props.articleNo}
          </div>
          <div
            className={`w-1/10 px-1 h-full flex items-center text-xs text-grey-darker justify-center text-center ${
              this.props.ownProduct && "bg-table-grey"
            }`}
          >
            {this.props.updatedBy}
          </div>
          <div
            className={`w-1/10 px-1 h-full flex items-center text-xs text-grey-darker justify-center text-center ${
              this.props.ownProduct && "bg-table-grey"
            }`}
          >
            {this.props.ownProduct ? (
              this.props.disableButton ? (
                <div className="bg-table-grey-dark text-grey-dark rounded-sm p-2 cursor-not-allowed">
                  Verify All
                </div>
              ) : (
                <div
                  onClick={() => this.props.onClick("verifyAll", null)}
                  className="bg-teal-dark text-white hover:bg-teal rounded-sm p-2 cursor-pointer"
                >
                  Verify All
                </div>
              )
            ) : (
              <>
                {/* unmatched */}
                <SmallCircleButton
                  icon={
                    this.state.unmatch && this.props.unmatched
                      ? "undo"
                      : "cancel"
                  }
                  name={this.props.unmatched ? "Cancel Unmatch" : "Unmatch"}
                  onClick={() =>
                    this.props.unmatched
                      ? this.props.onClick("cancelUnmatch", this.props.product)
                      : this.props.onClick("unmatch", this.props.product)
                  }
                  onMouseEnter={() => this.toggleTooltips("unmatch", true)}
                  onMouseLeave={() => this.toggleTooltips("unmatch", false)}
                  showtooltip={this.state.unmatch}
                  error={this.props.unmatched}
                />

                <div className="pr-1 " />
                {!this.props.unmatched ? (
                  <SmallCircleButton
                    icon={
                      this.props.verified && this.state.verify
                        ? "undo"
                        : "check_circle_outline"
                    }
                    name={this.props.verified ? "Unverify" : "Verify"}
                    onClick={() =>
                      this.props.verified
                        ? this.props.onClick("unverify", this.props.product)
                        : this.props.onClick("verify", this.props.product)
                    }
                    onMouseEnter={() => this.toggleTooltips("verify", true)}
                    onMouseLeave={() => this.toggleTooltips("verify", false)}
                    showtooltip={this.state.verify}
                    success={this.props.verified}
                  />
                ) : (
                  <div className="h-full flex items-center">
                    <div className="material-icons-outlined p-2 xxl:text-lg text-md cursor-not-allowed text-grey-dark bg-table-grey rounded-full">
                      close
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div
            className={`w-1/10 px-1 h-full flex items-center text-xs text-teal-darker justify-center text-center ${
              this.props.ownProduct && "bg-table-grey"
            }`}
          >
            {this.props.ownProduct ? (
              this.props.verified
            ) : this.props.verified ? (
              <div className="h-full flex items-center">
                <i className="material-icons-outlined pr-2 text-lg">
                  check_circle_outline
                </i>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
