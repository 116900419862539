import axios from "axios";
import { history } from "helpers/history";
import { setCampaignAnalyticsPagination } from "./tablePagination";
import { getAllPriceRules } from "ducks/priceRule";
import { handleError } from "ducks/general";
import ProductPerformanceAnalytics from "pages/ProductPerformanceAnalytics";
const SET_ALL_CAMPAIGN_ANALYTICS = "SET_ALL_CAMPAIGN_ANALYTICS";
const CAMPAIGN_ANALYTICS_IS_FILTERED = "CAMPAIGN_ANALYTICS_IS_FILTERED";
const CAMPAIGN_ANALYTICS_SET_NO_DATA = "CAMPAIGN_ANALYTICS_SET_NO_DATA";
const CAMPAIGN_ANALYTICS_TOGGLE_SHOW_ALL_DATA =
  "CAMPAIGN_ANALYTICS_TOGGLE_SHOW_ALL_DATA";
const CAMPAIGN_ANALYTICS_TABLE_LOADING = "CAMPAIGN_ANALYTICS_TABLE_LOADING";
const CLEAR_ALL_CAMPAIGN_ANALYTICS = "CLEAR_ALL_CAMPAIGN_ANALYTICS";
const CLEAR_ALL_CAMPAIGN_ANALYTICS_FILTERS =
  "CLEAR_ALL_CAMPAIGN_ANALYTICS_FILTERS";
const CAMPAIGN_ANALYTICS_UPDATE_TAG_LIST = "CAMPAIGN_ANALYTICS_UPDATE_TAG_LIST";
const CAMPAIGN_ANALYTICS_REMOVE_FROM_TAG_LIST =
  "CAMPAIGN_ANALYTICS_REMOVE_FROM_TAG_LIST";
const CAMPAIGN_ANALYTICS_CLEAR_DATA = "CAMPAIGN_ANALYTICS_CLEAR_DATA";
const CAMPAIGN_ANALYTICS_SET_ID_LIST = "CAMPAIGN_ANALYTICS_SET_ID_LIST";

const SET_DISCOUNT = "SET_DISCOUNT";
const CAMPAIGN_ANALYTICS_SET_VIEW_STATE = "CAMPAIGN_ANALYTICS_SET_VIEW_STATE";
const CAMPAIGN_ANALYTICS_SET_SINGLE_DATA = "CAMPAIGN_ANALYTICS_SET_SINGLE_DATA";
const CAMPAIGN_ANALYTICS_SET_PRODUCT_ID_LIST =
  "CAMPAIGN_ANALYTICS_SET_PRODUCT_ID_LIST";
const CAMPAIGN_ANALYTICS_SET_SAVING_IN_PROGRESS =
  "CAMPAIGN_ANALYTICS_SET_SAVING_IN_PROGRESS";
const CAMPAIGN_ANALYTICS_SET_TOO_MUCH_DATA =
  "CAMPAIGN_ANALYTICS_SET_TOO_MUCH_DATA";
const CAMPAIGN_ANALYTICS_SET_SELECTED_PRODUCTS =
  "CAMPAIGN_ANALYTICS_SET_SELECTED_PRODUCTS";
const CAMPAIGN_ANALYTICS_SET_INPUTS_ONBLUR =
  "CAMPAIGN_ANALYTICS_SET_INPUTS_ONBLUR";
const CAMPAIGN_ANALYTICS_HANDLE_TABLE_INPUT =
  "CAMPAIGN_ANALYTICS_HANDLE_TABLE_INPUT";
const SET_DISCOUNTED_CAMPAIGN_ANALYTICS = "SET_DISCOUNTED_CAMPAIGN_ANALYTICS";
const SET_PAGE_CAMPAIGN_ANALYTICS = "SET_PAGE_CAMPAIGN_ANALYTICS";
const SET_ROW_SIZE_CAMPAIGN_ANALYTICS = " SET_ROW_SIZE_CAMPAIGN_ANALYTICS";
const SORT_CAMPAIGN_ANALYTICS = "SORT_CAMPAIGN_ANALYTICS";
const SET_UPDATING_DISCOUNT_INFO = "SET_UPDATING_DISCOUNT_INFO";

export const filterCampaignAnalyticsData = (idList, rowSize) => {
  return (dispatch) => {
    dispatch(tableLoading(true));
    dispatch(setNoData(false));
    dispatch(clearFilters());
    dispatch(setTooMuchData(false));
    if (localStorage.getItem("market")) {
      return axios
        .get(
          process.env.REACT_APP_API_HOST +
            "/price-optimisation/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/campaigns/products?" +
            idList
        )
        .then((res) => {
          dispatch(setIdList(idList));

          // if (res.data.content.length > 2000) {
          // dispatch(setTooMuchData(true));
          dispatch(tableLoading(false));
          // } else {
          let productIdList = res.data.content.map((data) => data.id);
          dispatch(setProductIdList(productIdList));
          dispatch(setAllCampaignAnalytics(res.data.content, rowSize));
          dispatch(tableLoading(false));
          if (res.data.content.length < 1) {
            dispatch(setNoData(true));
          }
          // }
        })
        .catch((err) => {
          dispatch(handleError(err));
          dispatch(tableLoading(false));
          dispatch(setNoData(true));
        });
    }
  };
};
export const getCampaignAnalyticsData = (
  idList,
  discount,
  discountInfo,
  isLast
) => {
  return (dispatch) => {
    dispatch(setNoData(false));
    dispatch(setViewState("update"));

    dispatch(setTooMuchData(false));
    if (localStorage.getItem("market")) {
      return axios
        .get(
          process.env.REACT_APP_API_HOST +
            "/price-optimisation/markets/" +
            localStorage.getItem("market") +
            "/channels/" +
            localStorage.getItem("channel") +
            "/campaigns/products?" +
            idList
        )
        .then((res) => {
          dispatch(setIdList(idList));
          dispatch(setDoscountInfo(discountInfo));

          // if (res.data.content.length > 2000) {
          //   dispatch(setTooMuchData(true));
          //   // dispatch(tableLoading(false));
          // } else {
          let productIds = res.data.content.map((product) => product.id);
          let discountList = { discount: discount, productIds: productIds };
          dispatch(
            setDiscountedCampaignAnalytics(
              res.data.content,
              discountList,
              20,
              isLast
            )
          );
          if (isLast) {
            dispatch(tableLoading(false));
            dispatch(setNoData(false));
          }
          // if (res.data.content.length < 1) {
          //   dispatch(setNoData(true));
          // }
          // }
        })
        .catch((err) => {
          dispatch(handleError(err));
          dispatch(tableLoading(false));
          dispatch(setNoData(true));
        });
    }
  };
};

export const getPriceRuleInformation = (row) => {
  return (dispatch) => {
    dispatch(tableLoading(true));
    dispatch(setViewState("update"));
    dispatch(setNoData(false));
    dispatch(setSingleCampaignData(row.campaign));
    return axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/campaigns/" +
          row.campaign.id +
          "/price-rules"
      )
      .then((response) => {
        if (response.data.length > 0) {
          dispatch(tableLoading(true));

          let tagList = [];
          response.data.map((priceRule, i) => {
            let discountInfo = {
              id: priceRule.campaign.id,
              name: priceRule.campaign.name,
              startDate: priceRule.campaign.startDate,
              endDate: priceRule.campaign.endDate,
            };
            let discount = priceRule.strategyValue;
            return axios
              .get(process.env.REACT_APP_API_HOST + "/product/tags")
              .then((res, index) => {
                let foundTag = res.data.find(
                  (tag) => tag.name === priceRule.groups[0].expressions[0].value
                );
                let isLast = false;
                if (foundTag) {
                  if (i + 1 === response.data.length) {
                    isLast = true;
                  }
                  dispatch(
                    getCampaignAnalyticsData(
                      "tagIds=" + foundTag.id,
                      discount,
                      discountInfo,
                      isLast
                    )
                  );
                  history.push("campaign-analytics");
                }
              })
              .catch((err) => {
                dispatch(handleError(err));
              });
          });
        } else {
          history.push("campaign-analytics");
        }
      })
      .catch((err) => console.log(err));
  };
};

export const getCampaignInformation = (row) => {
  return (dispatch) => {
    dispatch(tableLoading(true));
    dispatch(setNoData(false));
    dispatch(setViewState("update"));
    dispatch(setSingleCampaignData(row));
    return axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/campaigns/" +
          row.id +
          "/price-rules"
      )
      .then((response) => {
        if (response.data.length > 0) {
          dispatch(tableLoading(true));
          response.data.map((priceRule, i) => {
            let discountInfo = {
              id: priceRule.campaign.id,
              name: priceRule.campaign.name,
              startDate: priceRule.campaign.startDate,
              endDate: priceRule.campaign.endDate,
            };
            let discount = priceRule.strategyValue;
            // dispatch(runCampaign(priceRule.strategyValue));
            return axios
              .get(process.env.REACT_APP_API_HOST + "/product/tags")
              .then((res, index) => {
                let foundTag = res.data.find(
                  (tag) => tag.name === priceRule.groups[0].expressions[0].value
                );
                let isLast = false;

                if (foundTag) {
                  if (i + 1 === response.data.length) {
                    isLast = true;
                  }
                  dispatch(
                    getCampaignAnalyticsData(
                      "tagIds=" + foundTag.id,
                      discount,
                      discountInfo,
                      isLast
                    )
                  );
                  history.push("campaign-analytics");
                }
              })

              .catch((err) => {
                dispatch(handleError(err));
              });
          });
        } else {
          history.push("campaign-analytics");
        }
      })
      .catch((err) => console.log(err));
  };
};

export const createCampaign = (campaign) => {
  return (dispatch) => {
    dispatch(setSavingInProgress(true, false));
    return axios
      .post(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/campaigns/discount",
        campaign
      )
      .then((res) => {
        dispatch(setSavingInProgress(false, true));
      })
      .catch((err) => {
        dispatch(handleError(err));
        dispatch(setSavingInProgress(false, false));
      });
  };
};
export const updateCampaign = (id, campaign) => {
  return (dispatch) => {
    dispatch(setSavingInProgress(true, false));
    return axios
      .put(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/campaigns/discount/" +
          id,
        campaign
      )
      .then((res) => {
        dispatch(setSavingInProgress(false, true));
      })
      .catch((err) => {
        dispatch(handleError(err));
        dispatch(setSavingInProgress(false, false));
      });
  };
};

export const deleteCampaignAnalyticsFromView = (id) => {
  return (dispatch) => {
    return axios
      .delete(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/campaigns/discount/" +
          id
      )
      .then((res) => {
        dispatch(clearAllData());
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};
export const deleteCampaignAnalytics = (id, page, rowSize, attr, direction) => {
  return (dispatch) => {
    return axios
      .delete(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/campaigns/discount/" +
          id
      )
      .then((res) => {
        dispatch(getAllPriceRules(page, rowSize, attr, direction));
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

const setDoscountInfo = (discountInfo) => {
  return {
    type: SET_UPDATING_DISCOUNT_INFO,
    discountInfo: discountInfo,
  };
};

export const updateProductTableInputsOnBlur = (id, col, value) => {
  return {
    type: CAMPAIGN_ANALYTICS_SET_INPUTS_ONBLUR,
    id,
    col,
    value,
  };
};

export const handleProductsInputChange = (value, id, col) => {
  return {
    type: CAMPAIGN_ANALYTICS_HANDLE_TABLE_INPUT,
    value,
    id,
    col,
  };
};

export const runCampaign = (discount) => {
  return {
    type: SET_DISCOUNT,
    discount: discount,
  };
};

const showAllProducts = (value) => {
  return {
    type: CAMPAIGN_ANALYTICS_TOGGLE_SHOW_ALL_DATA,
    showAllProducts: value,
  };
};

const setAllCampaignAnalytics = (data, rowSize) => {
  const split = (input, output = []) => {
    if (!input.length) return output;
    output.push(input.slice(0, rowSize));
    return split(input.slice(rowSize), output);
  };
  let paginateData = split(data);
  return {
    type: SET_ALL_CAMPAIGN_ANALYTICS,
    campaignAnalytics: data,
    campaignAnalyticsPages: paginateData,
    rowSize: rowSize,
  };
};

export const sortCampaignAnalyticsData = (data, attr, direction, rowSize) => {
  let sortedData = [];
  if (direction === "desc") {
    sortedData = [...data];
    if (attr === "discount" || attr === "dynamicPrice") {
      sortedData.sort((a, b) =>
        parseFloat(a[attr].value) < parseFloat(b[attr].value) ? 1 : -1
      );
    } else {
      sortedData.sort((a, b) => (a[attr] < b[attr] ? 1 : -1));
    }
  }
  if (direction === "asc") {
    sortedData = [...data];
    if (attr === "discount" || attr === "dynamicPrice") {
      sortedData.sort((a, b) =>
        parseFloat(a[attr].value) > parseFloat(b[attr].value) ? 1 : -1
      );
    } else {
      sortedData.sort((a, b) => (a[attr] > b[attr] ? 1 : -1));
    }
  }
  if (direction === null) {
    sortedData = [...data];
  }
  const split = (input, output = []) => {
    if (!input.length) return output;
    output.push(input.slice(0, rowSize));
    return split(input.slice(rowSize), output);
  };
  let paginateData = split(sortedData);
  return {
    type: SORT_CAMPAIGN_ANALYTICS,
    campaignAnalytics: data,
    campaignAnalyticsPages: paginateData,
    rowSize: rowSize,
  };
};

const setDiscountedCampaignAnalytics = (data, discount, rowSize, isLast) => {
  return {
    type: SET_DISCOUNTED_CAMPAIGN_ANALYTICS,
    campaignAnalytics: data,
    discountedProducts: discount,
    rowSize: rowSize,
    isLast,
  };
};

export const clearAllData = () => {
  return {
    type: CLEAR_ALL_CAMPAIGN_ANALYTICS,
  };
};

export const clearFilters = () => {
  return {
    type: CLEAR_ALL_CAMPAIGN_ANALYTICS_FILTERS,
  };
};

export const setFiltered = (val) => {
  return {
    type: CAMPAIGN_ANALYTICS_IS_FILTERED,
    isFiltered: val,
  };
};

const tableLoading = (value) => {
  return {
    type: CAMPAIGN_ANALYTICS_TABLE_LOADING,
    campaignAnalyticsTableLoading: value,
  };
};

export const setNoData = (value) => {
  return {
    type: CAMPAIGN_ANALYTICS_SET_NO_DATA,
    campaignAnalyticsNoData: value,
  };
};

export const updateCampaignAnalyticsTagList = (
  tag,
  selectedCampaignAnalytics
) => {
  return {
    type: CAMPAIGN_ANALYTICS_UPDATE_TAG_LIST,
    tag: tag,
    selectedCampaignAnalytics: selectedCampaignAnalytics,
  };
};

export const removeFromCampaignAnalyticsTagList = (
  tag,
  selectedCampaignAnalytics
) => {
  return {
    type: CAMPAIGN_ANALYTICS_REMOVE_FROM_TAG_LIST,
    tag: tag,
    selectedCampaignAnalytics: selectedCampaignAnalytics,
  };
};

// export const clearData = () => {
//   return {
//     type: CAMPAIGN_ANALYTICS_CLEAR_DATA,
//   };
// };

const setIdList = (ids) => {
  return {
    type: CAMPAIGN_ANALYTICS_SET_ID_LIST,
    idList: ids,
  };
};

export const setViewState = (viewState) => {
  return {
    type: CAMPAIGN_ANALYTICS_SET_VIEW_STATE,
    viewState,
  };
};

export const setSingleCampaignData = (data) => {
  return {
    type: CAMPAIGN_ANALYTICS_SET_SINGLE_DATA,
    singleCampaignData: data,
  };
};
export const setProductIdList = (productIdList) => {
  return {
    type: CAMPAIGN_ANALYTICS_SET_PRODUCT_ID_LIST,
    productIdList,
  };
};

export const clearSavingProgress = () => {
  return {
    type: CAMPAIGN_ANALYTICS_SET_SAVING_IN_PROGRESS,
    savingInProgress: false,
    saved: false,
  };
};

export const setSavingInProgress = (savingInProgress, saved) => {
  return {
    type: CAMPAIGN_ANALYTICS_SET_SAVING_IN_PROGRESS,
    savingInProgress,
    saved,
  };
};

export const setTooMuchData = (tooMuchData) => {
  return {
    type: CAMPAIGN_ANALYTICS_SET_TOO_MUCH_DATA,
    tooMuchData,
  };
};

export const setSelectedProducts = (selectedProducts) => {
  return {
    type: CAMPAIGN_ANALYTICS_SET_SELECTED_PRODUCTS,
    selectedProducts,
  };
};

export const setCurretPageClientSide = (page) => {
  return {
    type: SET_PAGE_CAMPAIGN_ANALYTICS,
    page,
  };
};

export const setRowRize = (rowSize, data) => {
  const split = (input, output = []) => {
    if (!input.length) return output;
    output.push(input.slice(0, rowSize));
    return split(input.slice(rowSize), output);
  };
  let paginateData = split(data);
  return {
    type: SET_ROW_SIZE_CAMPAIGN_ANALYTICS,
    campaignAnalyticsPages: paginateData,
    rowSize,
  };
};

const initialState = {
  idList: [],
  productIdList: [],
  selectedProducts: [],
  isFiltered: false,
  singleCampaignData: null,
  campaignAnalytics: [],
  tag: [],
  selectedCampaignAnalytics: [],
  totalCampaignAnalytics: null,
  showAllProducts: true,
  campaignAnalyticsTableLoading: false,
  campaignAnalyticsNoData: false,
  discount: null,
  viewState: "create",
  savingInProgress: false,
  saved: false,
  tooMuchData: false,
  campaignAnalyticsPages: [],
  totalProducts: "",
  campaignAnalyticsPaginationData: [],
  currentPage: 0,
  rowSize: 20,
  discountInfo: {},
};

export default function reducer(state = initialState, action = {}) {
  let campaignAnalytics = [...state.campaignAnalytics];
  let page = [];

  switch (action.type) {
    case CAMPAIGN_ANALYTICS_HANDLE_TABLE_INPUT:
      campaignAnalytics.map((product) => {
        if (action.id === product.id) {
          if (action.col === "dynamicPrice") {
            product.dynamicPrice.value = action.value;
            product.discount.value =
              ((product.recommendedRetailPrice - action.value) /
                product.recommendedRetailPrice) *
              100;
            product.discount.value = product.discount.value.toFixed(2);
          }
          if (action.col === "discount") {
            product.dynamicPrice.value =
              product.recommendedRetailPrice < 1
                ? 0
                : product.recommendedRetailPrice -
                  (action.value / 100) * product.recommendedRetailPrice;
            product.dynamicPrice.value = product.dynamicPrice.value.toFixed(2);
            product.discount.value = action.value;
          }
          product.margin =
            Math.round(
              ((product.dynamicPrice.value / 1.25 - product.purchasePrice) /
                (product.dynamicPrice.value / 1.25)) *
                100 *
                100
            ) / 100;
          product.priceDiff =
            product.dynamicPrice.value - product.lowestCompetitorPrice;
          product.priceDiff = product.priceDiff.toFixed(2);
          if (action.col === "margin" && action.value >= 100) {
            return;
          } else {
            product[action.col].isChanged = true;
            product[action.col].isBlurred = true;
          }
        }
      });
      return {
        ...state,
        campaignAnalytics,
      };
    case SET_PAGE_CAMPAIGN_ANALYTICS:
      return {
        ...state,
        campaignAnalyticsPaginationData:
          state.campaignAnalyticsPages[action.page],
        currentPage: action.page,
      };
    case SET_ROW_SIZE_CAMPAIGN_ANALYTICS:
      return {
        ...state,
        campaignAnalyticsPages: action.campaignAnalyticsPages,
        campaignAnalyticsPaginationData: action.campaignAnalyticsPages[0],
        // totalCampaignAnalytics: state.campaignAnalytics.length,
        rowSize: action.rowSize,
      };
    case SET_ALL_CAMPAIGN_ANALYTICS:
      let filteredCampaignAnalytics = [...action.campaignAnalytics];

      filteredCampaignAnalytics.map((product) => {
        product.purchasePrice = parseFloat(product.purchasePrice).toFixed(2);
        product.recommendedRetailPrice = parseFloat(
          product.recommendedRetailPrice
        ).toFixed(2);
        product.priceDiff =
          product.priceDiff !== null ? product.priceDiff.toFixed(2) : 0;
        product.dynamicPrice = {
          value: "",
          isInput: true,
          requiresRrp: true,
          hideSorting: false,
          isChanged: false,
          isBlurred: true,
        };
        product.discount = {
          value: "",
          isInput: true,
          requiresRrp: true,
          hideSorting: false,
          isChanged: false,
          isBlurred: true,
        };
      });
      return {
        ...state,
        campaignAnalytics: filteredCampaignAnalytics,
        campaignAnalyticsPages: action.campaignAnalyticsPages,
        campaignAnalyticsPaginationData: action.campaignAnalyticsPages[0],
        totalCampaignAnalytics: filteredCampaignAnalytics.length,
        rowSize: action.rowSize,
        currentPage: 0,
      };

    case SET_DISCOUNTED_CAMPAIGN_ANALYTICS:
      let discountedCampaignAnalytics = [...action.campaignAnalytics];
      discountedCampaignAnalytics.map((product) => {
        // if (product.recommendedRetailPrice > 0) {
     
        product.purchasePrice = parseFloat(product.purchasePrice).toFixed(2);
        product.recommendedRetailPrice = parseFloat(
          product.recommendedRetailPrice
        ).toFixed(2);
        let dynamicPrice =
          product.recommendedRetailPrice *
          (1 - action.discountedProducts.discount / 100);
        let discount = action.discountedProducts.discount;
        product.priceDiff =
          product.priceDiff !== null ? product.priceDiff.toFixed(2) : 0;
        product.dynamicPrice = {
          value: dynamicPrice.toFixed(2),
          isInput: true,
          requiresRrp: true,
          hideSorting: false,
          isChanged: false,
          isBlurred: true,
        };
        product.discount = {
          value: discount.toFixed(2),
          isInput: true,
          requiresRrp: true,
          hideSorting: false,
          isChanged: false,
          isBlurred: true,
        };
        product.margin =  Math.round(
          ((dynamicPrice / 1.25 - product.purchasePrice) /
            (dynamicPrice / 1.25)) *
            100 *
            100
        ) / 100;
      });

      let paginateData = [];
      // if (action.isLast) {
      let allData = [
        ...state.campaignAnalytics,
        ...discountedCampaignAnalytics,
      ];

      const split = (input, output = []) => {
        if (!input.length) return output;
        output.push(input.slice(0, action.rowSize));
        return split(input.slice(action.rowSize), output);
      };

      paginateData = split(allData);
      // }
      return {
        ...state,
        campaignAnalytics: state.campaignAnalytics.concat(
          discountedCampaignAnalytics
        ),
        campaignAnalyticsPages: paginateData,
        campaignAnalyticsPaginationData: paginateData[0],
        totalCampaignAnalytics: discountedCampaignAnalytics.length,
        rowSize: action.rowSize,
        currentPage: 0,
      };

    case CAMPAIGN_ANALYTICS_IS_FILTERED:
      return {
        ...state,
        isFiltered: action.isFiltered,
      };
    case SORT_CAMPAIGN_ANALYTICS:
      return {
        ...state,
        campaignAnalyticsPages: action.campaignAnalyticsPages,
        campaignAnalyticsPaginationData: action.campaignAnalyticsPages[0],
        rowSize: action.rowSize,
        currentPage: 0,
      };
    case CAMPAIGN_ANALYTICS_TOGGLE_SHOW_ALL_DATA:
      return {
        ...state,
        showAllProducts: action.showAllProducts,
      };
    case CAMPAIGN_ANALYTICS_TABLE_LOADING:
      return {
        ...state,
        campaignAnalyticsTableLoading: action.campaignAnalyticsTableLoading,
      };
    case CLEAR_ALL_CAMPAIGN_ANALYTICS:
      return {
        ...state,
        idList: [],
        productIdList: [],
        selectedProducts: [],
        isFiltered: false,
        singleCampaignData: null,
        campaignAnalytics: [],
        tag: [],
        selectedCampaignAnalytics: [],
        totalCampaignAnalytics: null,
        showAllProducts: true,
        campaignAnalyticsTableLoading: false,
        campaignAnalyticsNoData: false,
        discount: null,
        viewState: "create",
        savingInProgress: false,
        saved: false,
        tooMuchData: false,
        campaignAnalyticsPages: [],
        totalProducts: "",
        campaignAnalyticsPaginationData: [],
        currentPage: 0,
        rowSize: 20,
        discountInfo: {},
      };
    case CAMPAIGN_ANALYTICS_SET_NO_DATA:
      return {
        ...state,
        campaignAnalyticsNoData: action.campaignAnalyticsNoData,
      };
    case CAMPAIGN_ANALYTICS_UPDATE_TAG_LIST:
      campaignAnalytics.map((product) => {
        return action.selectedCampaignAnalytics.forEach((item) => {
          if (
            item.id === product.id &&
            !product.tags.find((tag) => tag.id === action.tag.id)
          ) {
            product.tags.push(action.tag);
          }
        });
      });
      return {
        ...state,
        campaignAnalytics: campaignAnalytics,
      };
    case CAMPAIGN_ANALYTICS_REMOVE_FROM_TAG_LIST:
      campaignAnalytics.map((product) => {
        return action.selectedCampaignAnalytics.forEach((item) => {
          if (
            item.id === product.id &&
            product.tags.find((tag) => tag.id === action.tag.id)
          ) {
            product.tags.splice(
              product.tags.findIndex((tag) => tag.id === action.tag.id),
              1
            );
          }
        });
      });
      return {
        ...state,
        campaignAnalytics: campaignAnalytics,
      };
    case CAMPAIGN_ANALYTICS_CLEAR_DATA:
      return {
        ...state,
        idList: [],
        isFiltered: false,
        selectedCampaignAnalytics: [],
        totalCampaignAnalytics: null,
        singleProduct: null,
        productsByGroup: [],
        showAllProducts: true,
        campaignAnalyticsTableLoading: true,
        analyticsNoData: false,
        discount: null,
        selectedProducts: [],
      };

    case CAMPAIGN_ANALYTICS_SET_ID_LIST:
      return {
        ...state,
        idList: action.idList,
      };

    case SET_DISCOUNT:
      campaignAnalytics.map((product) => {
        product.discount.isChanged = false;
        product.dynamicPrice.isChanged = false;
        product.discount.isBlurred = false;
        product.dynamicPrice.isBlurred = false;
        product.discount.value = action.discount;
        product.dynamicPrice.value =
          product.recommendedRetailPrice * (1 - action.discount / 100);
        product.margin =
          Math.round(
            ((product.dynamicPrice.value / 1.25 - product.purchasePrice) /
              (product.dynamicPrice.value / 1.25)) *
              100 *
              100
          ) / 100;

        product.dynamicPrice.value = product.dynamicPrice.value.toFixed(2);
        product.priceDiff =
          product.dynamicPrice.value - product.lowestCompetitorPrice;
        product.priceDiff = product.priceDiff.toFixed(2);
      });
      return {
        ...state,
        discount: action.discount,
        campaignAnalytics,
      };
    case CAMPAIGN_ANALYTICS_SET_VIEW_STATE:
      return {
        ...state,
        viewState: action.viewState,
      };
    case CAMPAIGN_ANALYTICS_SET_SINGLE_DATA:
      return {
        ...state,
        singleCampaignData: action.singleCampaignData,
      };
    case CAMPAIGN_ANALYTICS_SET_PRODUCT_ID_LIST:
      return {
        ...state,
        productIdList: action.productIdList,
      };
    case CAMPAIGN_ANALYTICS_SET_SAVING_IN_PROGRESS:
      return {
        ...state,
        savingInProgress: action.savingInProgress,
        saved: action.saved,
      };
    case CAMPAIGN_ANALYTICS_SET_TOO_MUCH_DATA:
      return {
        ...state,
        tooMuchData: action.tooMuchData,
      };
    case CLEAR_ALL_CAMPAIGN_ANALYTICS_FILTERS:
      return {
        ...state,
        idList: [],
        campaignAnalytics: [],
        productIdList: [],
      };
    case CAMPAIGN_ANALYTICS_SET_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: action.selectedProducts,
      };
    case SET_UPDATING_DISCOUNT_INFO:
      return {
        ...state,
        discountInfo: action.discountInfo,
      };
    default:
      return state;
  }
}
