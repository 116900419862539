const TOGGLE_SCROLLING = "TOGGLE_SCROLLING";
const TOGGLE_HEADER = "TOGGLE_HEADER";
const SET_APP_LOADED = "SET_APP_LOADED";
const ERROR = "ERROR";
const CLOSE_ERROR = "CLOSE_ERROR";

export function toggleScrolling(modal) {
  return {
    type: TOGGLE_SCROLLING
  };
}

export function toggleHeader() {
  return {
    type: TOGGLE_HEADER
  };
}

export function setAppLoaded() {
  return {
    type: SET_APP_LOADED
  };
}

export const handleError = err => {
  return {
    type: ERROR,
    payload: err.response ? err.response.data.messages : null
  };
};

export const closeErrorMsg = () => {
  return {
    type: CLOSE_ERROR
  };
};

const initialState = {
  isScrollingView: false,
  hideViewHeader: false,
  appLoaded: false,
  errorMsg: null,
  toggleMessage: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_SCROLLING:
      return Object.assign({}, state, {
        isScrollingView: !state.isScrollingView
      });
    case TOGGLE_HEADER:
      return Object.assign({}, state, {
        hideViewHeader: !state.hideViewHeader
      });
    case SET_APP_LOADED:
      return Object.assign({}, state, {
        appLoaded: true
      });
    case ERROR:
      return {
        ...state,
        errorMsg: action.payload,
        toggleMessage: true
      };
    case CLOSE_ERROR:
      return {
        ...state,
        toggleMessage: false
      };

    default:
      return state;
  }
}
