import React, { Component } from "react";
import PropTypes from "prop-types";

import { TooltipLeft } from "components/Tooltips";

class SearchField extends Component {
  static propTypes = {
    /** On change function */
    onChange: PropTypes.func,
    /** The value will usually point to the value that the onChange event is changing */
    value: PropTypes.string,
    /** For tables the theme will generally be 'light-no-border' */
    theme: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      onFocus: false,
      searchButtonActive: false
    };
  }

  toggleFocus = () => {
    this.setState(prevState => ({ onFocus: !prevState.onFocus }));
  };

  buttonOnMouseEnter = () => {
    this.setState({ searchButtonActive: true });
  };
  buttonOnMouseLeave = () => {
    this.setState({ searchButtonActive: false });
  };

  render() {
    return (
      <>
        {this.state.onFocus ? (
          <div className="relative px-2">
            <div
              className="absolute pin-r xxl:mr-4 mr-3 h-full"
              onClick={() => this.toggleFocus()}
            >
              <div
                className={`flex items-center h-full material-icons cursor-pointer hover:text-teal  ${
                  this.state.onFocus
                    ? "text-teal xxl:text-xl text-lg"
                    : "text-grey-darker xxl:text-2xl text-xl"
                }`}
              >
                search
              </div>
            </div>
            <input
              autoComplete="off"
              placeholder="Filter Table"
              onChange={this.props.onChange}
              value={this.props.value}
              name={this.props.name}
              onBlur={() => this.toggleFocus()}
              className="outline-none m-auto w-full rounded-full xxl:pl-4 py-1 xxl:h-8 h-6 pl-3 pr-10 bg-white border-1 border-grey-lighter tracking-wide text-teal-darker focus:text-teal font-sans xxl:text-xs text-xxs input-placeholder"
            />
          </div>
        ) : (
          <div className="relative pl-3">
            <div
              className="flex justify-center h-full cursor-pointer"
              onClick={() => {
                this.buttonOnMouseLeave();
                this.toggleFocus();
              }}
              onMouseEnter={this.buttonOnMouseEnter}
              onMouseLeave={this.buttonOnMouseLeave}
            >
              <i className="material-icons self-center xxl:text-2xl text-lg hover:text-teal text-grey-darker">
                search
              </i>
            </div>
            {this.state.searchButtonActive && (
              <div className="absolute pin-r pin-t -mr-21 -mt-1 h-full z-20">
                <TooltipLeft text="SEARCH TABLE" right={true} />
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default SearchField;
