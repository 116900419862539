import React, { Component } from "react";
import { connect } from "react-redux";
import ViewContainer from "containers/ViewContainer";
import Table from "components/Table";
import CompetitorsFilter from "containers/Filters/CompetitorsFilter";
import { closeModal, openModal } from "ducks/modal";
import * as MODAL_TYPE from "utils/modal-types";
import { getAllCompetitors } from "ducks/priceRule";
import { getConfig } from "ducks/groups";
import { getAnalytics } from "ducks/competitorAnalytics";
import {
  getProductsInCompetitorAnalytics,
  clearProductsInCompetitorAnalytics,
} from "ducks/competitorAnalytics";

class CompetitorAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSave: false,
      topVisible: true,
      attribute: {},
      competitor: {},
      competitorAnalyticsData: [
        {
          noTableData: true,
          attribute: "",
          totalNumberOfProducts: "",
          numberOfHigher: "",
          ["higher" + "(%)"]: {
            tooltipMessage: "Where we are higher",
            value: "",
          },
          numberOfSame: "",
          ["same" + "(%)"]: {
            tooltipMessage: "Where we are the same",
            value: "",
          },
          numberOfLower: "",
          ["lower" + "(%)"]: {
            tooltipMessage: "Where we are lower",
            value: "",
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.props.getAllCompetitors();
    this.props.getConfig();
  }

  handleFilter = () => {
    if (
      Object.keys(this.state.attribute).length < 1 ||
      Object.keys(this.state.competitor).length < 1
    ) {
      return;
    } else {
      const values = {
        attribute: this.state.attribute.id,
        competitor: this.state.competitor.id,
      };
      this.props.getAnalytics(values, 1, 20, null, null);
    }
  };

  handleSelected = (name, selected) => {
    this.setState({ [name]: selected, canSave: true });
  };

  onDoubleClick = (row) => {
    this.props.clearProductsInCompetitorAnalytics();
    let dataObject = [];

    let name = "";

    if (row.category) {
      name = encodeURIComponent(row.category);
    } else {
      name = encodeURIComponent(row.brand);
    }
    dataObject = {
      attribute: this.state.attribute.id,
      category: name,
      competitor: this.state.competitor.id,
    };
    this.props.getProductsInCompetitorAnalytics(dataObject);

    this.props.openModal(
      this.props.setHigherProducts,
      MODAL_TYPE.COMPETITOR_ANAYTICS,
      MODAL_TYPE.MEDIUM
    );
  };

  getAllCompetitorAnalyticsData = (page, rowSize, attr, direction) => {
    const values = {
      attribute: this.state.attribute.id,
      competitor: this.state.competitor.id,
    };
    this.props.getAnalytics(values, page, rowSize, attr, direction);
  };

  render() {
    const viewHeaderConfig = {
      showInfo: false,
    };

    let parsedCompAnalyticsData =
      this.props.data &&
      this.props.data.map((item) => {
        return {
          id: item.id,
          [item.attribute.name]: item.attribute.value
            ? item.attribute.value
            : " ",
          totalNumberOfProducts: item.totalNumberOfProducts,
          numberOfHigher: item.higher.amount,
          higher: {
            hideSorting: true,
            tooltipMessage: "Where we are higher",
            value:
              Math.round(item.higher.percentage * 100) / 100
                ? Math.round(item.higher.percentage * 100) / 100
                : " ",
            concatString: "(%)",
          },
          numberOfSame: item.same.amount,
          same: {
            hideSorting: true,
            tooltipMessage: "Where we are the same",
            value:
              Math.round(item.same.percentage * 100) / 100
                ? Math.round(item.same.percentage * 100) / 100
                : " ",
            concatString: "(%)",
          },
          numberOfLower: item.lower.amount,
          lower: {
            hideSorting: true,
            tooltipMessage: "Where we are lower",
            value:
              Math.round(item.lower.percentage * 100) / 100
                ? Math.round(item.lower.percentage * 100) / 100
                : " ",
            concatString: "(%)",
          },
        };
      });
    const tableCompAnalytics = {
      data:
        this.props.data.length > 0
          ? parsedCompAnalyticsData
          : this.state.competitorAnalyticsData,
      tableLoading: this.props.competitiorAnalyticsTableLoading,
      noData: this.props.noDataComp,
      name: "Competitor Analytics",
      filter: true,
      onDoubleClick: this.onDoubleClick,
      hiddenCols: ["id", "noTableData"],
      paginationGetData: this.getAllCompetitorAnalyticsData,
      dataLength: this.props.totalAnalytics,
      currentPage: this.props.competitorAnalyticsPage,
      rowSize: this.props.competitorAnalyticsRowSize,
      totalPages: this.props.competitorAnalyticsTotalPages,
      attr: this.props.competitorAnalyticsSortingAttr,
      direction: this.props.competitorAnalyticsSortingDirection,
      hideSorting: true,
      isTableLarge: this.props.isTableLarge,
    };
    return (
      <ViewContainer hasPageButtons={true} topVisible={this.state.topVisible}>
        <div className="mt-10">
          <Table tableConfig={tableCompAnalytics}>
            <CompetitorsFilter
              attribute={this.state.attribute}
              competitor={this.state.competitor}
              handleSelected={this.handleSelected}
              handleFilter={this.handleFilter}
            />
          </Table>
        </div>
      </ViewContainer>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    competitiorAnalyticsTableLoading:
      state.competitorAnalytics.competitiorAnalyticsTableLoading,
    noDataComp: state.competitorAnalytics.compAnalyticsNoData,
    data: state.competitorAnalytics.competitorAnalytics,
    totalAnalytics: state.competitorAnalytics.totalCompetitorAnalytics,
    competitorAnalyticsPage: state.tablePagination.competitorAnalyticsPage,
    competitorAnalyticsRowSize:
      state.tablePagination.competitorAnalyticsRowSize,
    competitorAnalyticsTotalPages:
      state.tablePagination.competitorAnalyticsTotalPages,
    competitorAnalyticsSortingAttr:
      state.tablePagination.competitorAnalyticsSortingAttr,
    competitorAnalyticsSortingDirection:
      state.tablePagination.competitorAnalyticsSortingDirection,
    setHigherProducts: state.competitorAnalytics.setHigherProducts,
    isTableLarge: state.sidepanel.isTableLarge,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCompetitors: () => dispatch(getAllCompetitors()),
    getAnalytics: (value, page, rowSize, attr, direction) =>
      dispatch(getAnalytics(value, page, rowSize, attr, direction)),
    getConfig: () => dispatch(getConfig()),
    getProductsInCompetitorAnalytics: (data) =>
      dispatch(getProductsInCompetitorAnalytics(data)),
    clearProductsInCompetitorAnalytics: () =>
      dispatch(clearProductsInCompetitorAnalytics()),
    openModal: (targetUnitData, modalType, modalSize) =>
      dispatch(openModal(targetUnitData, modalType, modalSize)),
  };
};
export default connect(
  mapStateToprops,
  mapDispatchToProps
)(CompetitorAnalytics);
