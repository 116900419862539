import React from "react";
import { Route, Redirect } from "react-router-dom";

const RestrictedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (localStorage.getItem("features")) {
          return !rest.authorized ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          );
        }
      }}
    />
  );
};

export default RestrictedRoute;
