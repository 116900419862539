import axios from "axios";

const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
const SET_NOTIFICATIONS_FROM_PUSH = "SET_NOTIFICATIONS_FROM_PUSH";
const key = process.env.REACT_APP_FCM_AUTH_KEY;

// export const subscribeTokenToTopic = () => {
//   //using fetch to avoid middleware
//   return dispatch => {
//     if (localStorage.getItem("fcm")) {
//       return fetch(
//         "https://iid.googleapis.com/iid/v1/" +
//           localStorage.getItem("fcm") +
//           "/rel/topics/charlie",
//         {
//           method: "POST",
//           headers: {
//             Authorization: "key=" + key
//           }
//         }
//       )
//         .then(res => {
//           console.log(res);
//         })
//         .catch(err => {
//           console.log(err);
//         });
//     }
//   };
// };

export const registerTokenToServer = (token) => {
  return (dispatch) => {
    return axios
      .put(process.env.REACT_APP_API_HOST + "/auth/notification/token", token)
      .then((res) => res)
      .catch((err) => console.log(err));
  };
};

export const getAllNotifications = () => {
  return (dispatch) => {
    return axios
      .get(process.env.REACT_APP_API_HOST + "/notification/user")
      .then((res) => dispatch(setNotification(res.data)))
      .catch((err) => console.log(err));
  };
};

export const markAsRead = (notification) => {
  return (dispatch) => {
    return axios
      .put(process.env.REACT_APP_API_HOST + "/notification/read", notification)
      .then((res) => dispatch(getAllNotifications()))
      .catch((err) => console.log(err));
  };
};

export const filterNotifications = (query) => {
  return (dispatch) => {
    return axios
      .get(process.env.REACT_APP_API_HOST + "/notification/user?" + query)
      .then((res) => dispatch(setNotification(res.data)))
      .catch((err) => console.log(err));
  };
};

export const setNotification = (notifications) => {
  return {
    type: SET_NOTIFICATIONS,
    notifications: notifications,
  };
};
export const setNotificationFromPush = (notification) => {
  return {
    type: SET_NOTIFICATIONS_FROM_PUSH,
    pushNotification: {
      id: notification.data.id,
      message: notification.data.message,
      priority: notification.data.priority,
      category: notification.data.category,
      created: notification.data.created,
      data: notification.data.content ? notification.data.content : [],
      read: false,
    },
  };
};

export const clearFilter = () => {
  return (dispatch) => {
    dispatch(getAllNotifications());
  };
};

const initialState = {
  notifications: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
      };
    case SET_NOTIFICATIONS_FROM_PUSH:
      return {
        ...state,
        notifications: [action.pushNotification, ...state.notifications],
      };
    default:
      return state;
  }
}
