import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ModifiedMultiSelectDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      searchValue: "",
      searching: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (this.multiDropdown.contains(e.target)) {
      return;
    } else {
      this.closeDropdown();
    }
  };

  closeDropdown = () => {
    this.setState({ dropdownOpen: false });
  };

  handleToggle = (event) => {
    this.setState((prevSate) => ({
      dropdownOpen: !prevSate.dropdownOpen,
      searching: false,
      searchValue: "",
    }));
  };

  selectedItem = (selectedItems, item) => {
    let selectedList = [...selectedItems];
    this.setState({
      searching: false,
      searchValue: "",
    });
    let selectedItem = selectedList.indexOf(
      selectedList.find((option) => option.id === item.id)
    );
    var isMatch = selectedList.some(function (obj) {
      return obj.name === item.name;
    });
    if (selectedItems.length === 5 && !isMatch) {
      return;
    } else {
      if (selectedItem === -1) {
        selectedList.push(item);
      } else {
        selectedList.splice(selectedItem, 1);
      }

      if (this.props.handleSelect) {
        this.props.handleSelect(this.props.name, selectedList, item);
      }
      if (this.props.removeInputError) {
        this.props.removeInputError(this.props.name);
      }
    }
  };

  handleFilter = (event) => {
    this.setState({
      searching: true,
      dropdownOpen: true,
      searchValue: event.target.value,
    });
    if (this.props.removeInputError) {
      this.props.removeInputError(this.props.name);
    }
  };

  render() {
    let selectedList = this.props.selected ? this.props.selected : [];
    let disabledStyling = `min-w-full rounded-t-lg  max-w-full bg-grey-lighter  border-b  text-grey-dark xxl:text-base text-xs font-light font-sans xxl:h-10 h-8 xxl:pl-3 p-2 pr-8 truncate cursor-not-allowed input-placeholder `;
    let inputStyling = `min-w-full rounded-t-lg max-w-full bg-white  border-b focus:text-teal outline-none text-teal-darker font-light xxl:text-base text-xs font-sans xxl:h-10 h-8 xxl:pl-3 p-2 pr-8 truncate cursor-pointer input-placeholder `;
    const errorInputStyling =
      "min-w-full max-w-full bg-red-lightest border-solid border-red-lighter border-1 outline-none text-red xxl:text-base font-light text-xs font-sans xxl:h-10 h-8 xxl:pl-3 p-2 pr-8 truncate cursor-pointer input-placeholder-error";
    let dropdownItemList;
    if (this.props.options && this.props.options.length > 0) {
      let updateDropdownList = this.props.options;
      updateDropdownList.forEach((item, i) => {
        if (selectedList.find((option) => option.id === item.id)) {
          if (
            updateDropdownList.find((option) => option.name === "Select All")
          ) {
            updateDropdownList.splice(1, 0, updateDropdownList.splice(i, 1)[0]);
          } else {
            updateDropdownList.splice(i, 1);
            updateDropdownList.unshift(item);
          }
        }
      });
      dropdownItemList = updateDropdownList.map((item) => {
        return item.name
          .toLowerCase()
          .includes(this.state.searchValue.toLowerCase()) ? (
          <li
            id={this.props.name}
            onClick={() => {
              this.selectedItem(selectedList, item);
              if (this.props.removeInputError) {
                this.props.removeInputError(this.props.name);
              }
            }}
            className={`flex flex-row list-reset px-2 font-light xxl:text-base text-xs font-sans cursor-pointer ${
              selectedList.indexOf(
                selectedList.find((option) => option.id === item.id)
              ) > -1
                ? "border-t-1 border-b-1 border-solid border-grey-lightest text-grey-darker"
                : "bg-white text-grey-darker hover:bg-grey-lightest"
            }`}
            key={item.id}
          >
            <div className="h-full w-5/6 flex-no-grow">
              <div className="max-w-full break-words pl-1 pr-3 py-3">
                {item.name}
              </div>
            </div>
            {selectedList.indexOf(
              selectedList.find((option) => option.id === item.id)
            ) > -1 ? (
              <div className="min-h-full flex-1 flex flex-row justify-end">
                <div className="material-icons self-center xxl:text-lg text-md text-teal">
                  done
                </div>
              </div>
            ) : null}
          </li>
        ) : null;
      });
    }

    return (
      <div
        ref={(node) => (this.multiDropdown = node)}
        className={this.props.width ? this.props.width : "xxl:w-50 w-32"}
        key="this.props.key"
      >
        <i
          onClick={this.handleToggle}
          className="material-icons float-right text-grey-dark cursor-pointer "
        >
          filter_list
        </i>
        <div
          className={`relative cursor-pointer ${
            this.props.width ? this.props.width : "xxl:w-50 w-32"
          }`}
        >
          {this.state.dropdownOpen &&
          this.props.options &&
          this.props.options.length > 0 ? (
            <ul className="shadow-lg mt-6  rounded-lg xxl:max-h-15 max-h-10 overflow-y-auto overflow-x-hidden bg-white px-0 border-1 border-solid border-grey-lighter absolute z-30 min-w-full max-w-full">
              <div className="w-full">
                <div
                  className={`material-icons absolute h-10 flex flex-row ${
                    this.props.error
                      ? "text-red-dark"
                      : this.props.options && this.props.options.length > 0
                      ? "text-teal-darker"
                      : "text-grey-dark cursor-not-allowed"
                  } pin-r mr-2 z-3 cursor-pointer`}
                >
                  <div className="self-center xxl:text-xl text-lg">search</div>
                </div>
                <input
                  autoComplete="off"
                  disabled={
                    this.props.options === undefined ||
                    this.props.options.length < 1
                  }
                  required={this.props.required}
                  className={
                    this.props.error
                      ? errorInputStyling
                      : this.props.options === undefined ||
                        this.props.options.length < 1
                      ? disabledStyling
                      : inputStyling
                  }
                  type="text"
                  name={this.props.name}
                  value={this.state.searching ? this.state.searchValue : ""}
                  placeholder={
                    this.props.selected.length > 0
                      ? this.props.selected.map(
                          (item, index) => (index ? " " : "") + item.name
                        )
                      : this.props.placeholder
                  }
                  onChange={(event) => this.handleFilter(event)}
                />
              </div>
              {dropdownItemList}
              <li
                onClick={this.props.applyFilter}
                className="bg-teal-light text-white w-full py-2 text-center sticky bottom-0 hover:bg-teal"
              >
                Apply Filter
              </li>
            </ul>
          ) : null}
        </div>
      </div>
    );
  }
}

ModifiedMultiSelectDropdown.propTypes = {
  /** Takes a list objects of a previously selected items. */
  /**[{
      id: 1,
      name: "Option A"
    },
    {
      id: 3,
      name: "Option C"
    }];*/
  selected: PropTypes.array,
  /** Takes a list of objects of selectable items. */
  /** [
        {
          id: 1,
          name: "Option A"
        },
        {
          id: 2,
          name: "Option B"
        },
        {
          id: 3,
          name: "Option C"
        },
        {
          id: 4,
          name: "Option D"
        }
      ]
     **/
  options: PropTypes.array,
  /** Name for the multiselect group so that the selected items can be sorted correctly. */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /** Pass a function that handles the selected items as you want. */
  /** handleSelect = (name, selectedList, lastSelected ) => {
    this.setState({name: selectedItems})
  } **/
  handleSelect: PropTypes.func,
  /** Pass a function that removes input errors. */
  removeInputError: PropTypes.func,
  /** Label for the Dropdown. */
  label: PropTypes.string,
  /** To display errors. */
  error: PropTypes.bool,
  /** If it is required. */
  required: PropTypes.bool,
};
