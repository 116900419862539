import React, { Component } from "react";
import { FormContentWrapper } from "containers/Forms/FormContainer/FormContentWrapper";
import LineChart from "components/Charts/LineChart";
import PieChart from "components/Charts/PieChart";
import { closeFormpanel } from "ducks/formpanel";
import { connect } from "react-redux";

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: {},
      from: {},
      to: {},
      channels: [],
      createdBy: {},
      showDropdown: false
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);

    this.props.toggleFormWidth();
    this.props.data &&
      this.setState({
        campaign: this.props.data[0].name,
        from: this.props.data[0].from,
        to: this.props.data[0].to,
        channels: this.props.data[0].channels,
        createdBy: this.props.data[0].createdBy
      });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }
  showOptions = () => {
    this.setState(prevState => ({
      showDropdown: !prevState.showDropdown
    }));
  };

  handleOutsideClick = e => {
    if (this.optionsModal.contains(e.target)) {
      return;
    } else {
      this.showOptions();
    }
  };
  handleCancel = () => {
    this.props.closeFormpanel();
  };
  renderOptions = () => {
    return (
      <div className="flex flex-col  text-xs w-24 absolute bg-white shadow">
        <div
          className="flex flex-row p-3 py-2 hover:bg-teal-lightest
         cursor-pointer"
        >
          <span className="text-grey-dark">option 1</span>
        </div>
        <div className="flex flex-row p-3 py-2 hover:bg-teal-lightest cursor-pointer">
          <span className="text-grey-dark">option 2</span>
        </div>
        <div className="flex flex-row p-3 py-2 hover:bg-teal-lightest cursor-pointer">
          <span className="text-grey-dark">option 3</span>
        </div>
      </div>
    );
  };

  render() {
    const { campaign, from, to, channels, createdBy } = this.state;
    return (
      <FormContentWrapper
        formConfig={{
          formTitle: "Campaign Analytics",
          buttonLabel: "Close"
        }}
        handleSubmit={this.handleCancel}
        handleCancel={this.handleCancel}
        formName="analytics"
        canSave={true}
      >
        <div>
          <div className="flex flex-row my-6 pl-6">
            <div className="flex flex-col w-2/5 text-sm text-teal-dark ">
              <div className="flex flex-row ">
                <div className="flex clex-col uppercase w-1/2">campaign</div>
                <div className="flex clex-col w-1/2 text-grey-dark">
                  {campaign}
                </div>
              </div>
              <div className="flex flex-row my-1">
                <div className="flex clex-col  uppercase  w-1/2">from</div>
                <div className="flex clex-col w-1/2 text-grey-dark">{from}</div>
              </div>
              <div className="flex flex-row my-1">
                <div className="flex clex-col uppercase w-1/2">to</div>
                <div className="flex clex-col w-1/2 text-grey-dark">{to}</div>
              </div>
              <div className="flex flex-row my-1">
                <div className="flex clex-col uppercase  w-1/2">
                  number of products
                </div>
                <div className="flex clex-col w-1/2 text-grey-dark"></div>
              </div>
              <div className="flex flex-row my-1">
                <div className="flex clex-col uppercase  w-1/2">channels</div>
                {channels.map(channel => (
                  <div className="flex clex-col w-1/2 text-grey-dark">
                    {channel.name}
                  </div>
                ))}
              </div>
              <div className="flex flex-row my-1">
                <div className="flex clex-col uppercase  w-1/2">Created By</div>
                <div className="flex clex-col w-1/2 text-grey-dark">
                  {createdBy}
                </div>
              </div>
            </div>
            <div className="flex flex-col w-1/5 border-solid border-r-1">
              <div className="flex flex-row border-dashed border-b-1 h-24">
                <div className="flex flex-col m-auto ">
                  <span className="uppercase text-grey-dark text-sm pb-4 ">
                    sessions
                  </span>
                  <span className="text-teal-dark text-center text-xl">
                    100
                  </span>
                </div>
              </div>
              <div className="flex flex-row h-24">
                <div className="flex flex-col  m-auto">
                  <span className="uppercase text-grey-dark text-sm pb-4">
                    product revenue
                  </span>
                  <span className="text-teal-dark text-center  text-xl">
                    100
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-1/5 border-solid border-r-1">
              <div className="flex flex-row border-dashed border-b-1 h-24">
                <div className="flex flex-col  m-auto">
                  <span className="uppercase text-grey-dark text-sm pb-4">
                    orders
                  </span>
                  <span className="text-teal-dark text-center text-xl">
                    200
                  </span>
                </div>
              </div>
              <div className="flex flex-row h-24">
                <div className="flex flex-col  m-auto">
                  <span className="uppercase text-grey-dark text-sm pb-4">
                    add revenue
                  </span>
                  <span className="text-teal-dark text-center text-xl">
                    150k
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-1/5">
              <div className="flex flex-row border-dashed border-b-1 h-24">
                <div className="flex flex-col  m-auto ">
                  <span className="uppercase text-grey-dark text-sm pb-4">
                    conversion rate
                  </span>
                  <span className="text-teal-dark text-center text-xl">
                    20%
                  </span>
                </div>
              </div>
              <div className="flex flex-row h-24">
                <div className="flex flex-col  m-auto">
                  <span className="uppercase text-grey-dark text-sm pb-4">
                    gross profit
                  </span>
                  <span className="text-teal-dark text-center text-xl">
                    100k
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col w-1/3 p-10  m-6 shadow">
              <span className="text-grey-dark">Sessions</span>
              <hr className="text-grey-lightest mt-4" />
              <LineChart />
            </div>
            <div className="flex flex-col w-1/3 p-10 m-6 shadow">
              <span className="text-grey-dark">Sales</span>
              <hr className="text-grey-lightest mt-4" />
              <LineChart />
            </div>
            <div className="flex flex-col w-1/3 p-10 m-6 shadow">
              <span className="text-grey-dark">Gross Profit</span>
              <hr className="text-grey-lightest mt-4" />
              <LineChart />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col w-1/3 p-10 m-6 shadow">
              <div className="flex flex-row justify-between relative">
                <span className="text-grey-dark">Sources</span>
                <i
                  ref={node => (this.optionsModal = node)}
                  onClick={this.showOptions}
                  className="material-icons text-grey-dark items-center cursor-pointer absolute right-0"
                >
                  settings_applications
                </i>
                {this.state.showDropdown ? (
                  <span className="mt-4 relative">{this.renderOptions()} </span>
                ) : null}
              </div>
              <hr className="text-grey-lightest mt-4" />
              <PieChart />
            </div>
            <div className="flex flex-col w-1/3 p-10 m-6 shadow">
              <span className="text-grey-dark">Price Changes</span>
              <hr className="text-grey-lightest mt-4" />
              <LineChart />
            </div>
            <div className="flex flex-col w-1/3 p-10 m-6 shadow">
              <span className="text-grey-dark">Competitor Price Changes</span>
              <hr className="text-grey-lightest mt-4" />
              <LineChart />
            </div>
          </div>
        </div>
      </FormContentWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeFormpanel: () => dispatch(closeFormpanel())
  };
};

export default connect(null, mapDispatchToProps)(Analytics);
