import React from "react";

const TextInput = (props) => {
  let keyCap = props.keyVal.charAt(0).toLowerCase() + props.keyVal.slice(1);
  let keyName = keyCap.replace(/\s/g, "");
  let item =
    props.keyVal.length > 0 &&
    props.filterKeyOptions.find((item) =>
      item.name.toLowerCase().includes(keyName.toLowerCase())
    );

  return (
    <ul
      className={`border-solid bg-white rounded-lg w-1/6 absolute z-10 mt-12
      min-w-10 shadow-md text-grey overflow-hidden`}
    >
      <span className="relative flex">
        {props.value.length > 0 && (
          <i
            onClick={() =>
              props.handleSelect(props.indexEdit, { name: props.value })
            }
            className={`
              bg-${props.colorList[item.id]}er
             material-icons flex self-center text-white items-center h-full z-20 absolute right-0 pin-top cursor-pointer`}
          >
            add
          </i>
        )}
        <input
          onKeyDown={(e) => props.handleKeyDown(props.indexEdit, e, [], item)}
          onChange={props.handleChange}
          value={props.value}
          className={`bg-${
            props.colorList[item.id]
          } placeholder-white w-full text-white
         font-light focus:border-none focus:outline-none h-full py-3 pl-2 `}
          name={props.keyVal}
          type="text"
          placeholder={
            props.keyVal.includes("Competitor")
              ? "Enter Name"
              : "Enter " + props.keyVal
          }
        />
      </span>
    </ul>
  );
};

export default TextInput;
