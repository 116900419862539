export const testData = [{
  id:1,
  priceList: "Standard A",
  priceRule: {id: 1, name: "Price Rule A"},
  from: '2019-01-01',
  to: '2019-06-01',
  status: 'Active',
  channel: {id: 1, name: "All Channels"}
},{
  id: 2,
  priceList: "Standard B",
  priceRule: {id:2, name: "Price Rule B"},
  from: '2019-01-01',
  to: '2019-06-01',
  status: 'Inactive',
  channel: {id: 1, name: "All Channels"}
}]


export const channels = [{id: 1, name: "All Channels"}, {id:2, name: "Channel A"}, {id:3, name: "Channel B"}]

export const priceRules = [{id: 1, name: "Price Rule A"}, {id:2, name: "Price Rule B"}, {id:3, name: "Price Rule C"}]
