import React, { Component } from "react";

class MenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }
  handleClick = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };
  render() {
    const groupClassList =
      this.props.selectedMenuItem &&
      this.props.selectedMenuItem === this.props.data.id
        ? "bg-teal-dark cursor-pointer px-2 font-light text-xs font-sans text-white flex flex-row hover:bg-teal m-0 h-10"
        : "bg-teal-dark cursor-pointer px-2 font-light text-xs font-sans text-white flex flex-row hover:bg-teal m-0 h-10";
    return (
      <>
        <div className={groupClassList} onClick={this.handleClick}>
          <div className="flex-1 h-full flex flex-row items-center">
            <span className="inline-block">{this.props.data.name}</span>
          </div>
          <div className="h-full flex flex-row items-center">
            {this.state.open ? (
              <i className="material-icons self-center mr-2">expand_more</i>
            ) : (
              <i className="material-icons self-center mr-2">chevron_right</i>
            )}
          </div>
        </div>

        {this.state.open && (
          <ul className="list-reset overflow-hidden">
            {this.props.data.menuItems.map((item) => {
              return (
                <div
                  key={item.id}
                  className={`${
                    this.props.selectedMenuItem &&
                    this.props.selectedMenuItem.toString() ===
                      item.id.toString()
                      ? "bg-teal"
                      : "bg-grey-darker hover:bg-teal-dark"
                  } font-light font-sans p-2 text-xs`}
                  onClick={() =>
                    this.props.setSelectedMenuItem(
                      item.id,
                      item.name,
                      item.channels[0].domain,
                      item.name,
                      localStorage.getItem("features"),
                      undefined
                    )
                  }
                >
                  {item.name}
                </div>
              );
            })}
          </ul>
        )}
      </>
    );
  }
}

export default MenuItem;
