import React, { Component } from "react";
import { connect } from "react-redux";

import { closeModal } from "ducks/modal";
import { setSelectedMenuItem, setSelectedChannel } from "ducks/sidepanel";

import { ModalContentWrapper } from "containers/Modals/ModalContainer/ModalContentWrapper";

import { LargeLoadingSpinner } from "components/LargeLoadingSpinner/LargeLoadingSpinner";

class Markets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      canContinue: false,
    };
  }

  handleSelected = (selected) => {
    this.setState({ selected, canContinue: true });
  };

  handleContinue = () => {
    if (this.state.canContinue) {
      localStorage.setItem("channel", this.state.selected.defaultChannelId);
      this.props.setSelectedChannel(
        this.state.selected.id,
        this.state.selected.name,
        this.state.selected.channels[0].domain,
        this.state.selected.name,
        localStorage.getItem("features"),
        this.state.selected.channels[0].domain,
        this.state.selected.defaultChannelId
      );
      this.props.setSelectedMenuItem(
        this.state.selected.id,
        this.state.selected.name,
        this.state.selected.channels[0].domain,
        this.state.selected.name,
        localStorage.getItem("features"),
        this.state.selected.defaultChannelId
      );
      this.props.closeModal();
    }
  };

  render() {
    const modalConfig = {
      buttonOnClick: this.handleContinue,
      buttonLabel: "continue",
      title: "Select a market to continue.",
      hasBottomButtons: true,
      canSave: this.state.canContinue,
    };

    return (
      <ModalContentWrapper modalConfig={modalConfig} size={this.props.size}>
        <div className="h-full w-full p-6">
          {this.props.marketList.length > 0 && (
            <>
              <div className="font-sans text-teal-darker font-light text-md pb-2">
                {" "}
                Your Available Markets:
              </div>
              <div className="flex flex-row flex-wrap">
                {this.props.marketList.map((market, i) => (
                  <div
                    className={`mr-1 mb-1 px-4 py-1 bg-teal-darker text-white text-xs font-sans font-light rounded-full cursor-pointer
                    ${
                      this.state.selected.id === market.id
                        ? "opacity-100"
                        : "opacity-50 hover:opacity-100"
                    }
                    `}
                    key={i}
                    onClick={() => this.handleSelected(market)}
                  >
                    {market.name}
                  </div>
                ))}
              </div>
            </>
          )}
          {this.props.loading && (
            <div className="w-full h-full flex items-center justify-center">
              {LargeLoadingSpinner}
            </div>
          )}
          {this.props.showNoData && (
            <div className="font-sans text-red-dark font-light text-md pb-2 w-full h-full flex items-center justify-center">
              No Markets Available
            </div>
          )}
        </div>
      </ModalContentWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    setSelectedMenuItem: (id, name, domain, country, features, channelId) =>
      dispatch(
        setSelectedMenuItem(id, name, domain, country, features, channelId)
      ),
    setSelectedChannel: (
      id,
      name,
      domain,
      country,
      features,
      channels,
      channelId
    ) =>
      dispatch(
        setSelectedChannel(
          id,
          name,
          domain,
          country,
          features,
          channels,
          channelId
        )
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    marketList: state.markets.markets,
    loading: state.markets.loading,
    showNoData: state.markets.showNoData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Markets);
