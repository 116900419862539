import React, { Component } from "react";

class Warnings extends Component {
  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = e => {
    if (this.warningsModal.contains(e.target)) {
      return;
    } else {
      this.props.closeWarnings();
    }
  };

  handleClose = () => {
    this.props.closeWarnings();
  };
  render() {
    return (
      <div
        ref={node => (this.warningsModal = node)}
        className={`flex bg-grey-lightest w-full h-18 absolute z-60 ${this.props.classList}
              `}
      >
        <div className="flex-1 flex font-sans text-grey font-light xxl:text-lg text-sm h-full text-center items-center justify-center">
          {this.props.warning && this.props.warning}
        </div>
        <div className=" flex h-full items-center justify-end">
          <span className="uppercase text-red items-center mr-4">
            {" "}
            {this.props.warnings.length} warnings{" "}
          </span>
          <i className="material-icons pr-4 text-red mr-8">report_problem</i>
        </div>
        <i
          onClick={this.handleClose}
          className="material-icons pr-4 text-grey hover:text-grey-darker mr-8 cursor-pointer pt-4"
        >
          close
        </i>
      </div>
    );
  }
}

export default Warnings;
