import React from "react";

function MultiInput(props) {
  let concattedVal = "";
  if (props.multiSelect && props.multiSelect.length > 3) {
    concattedVal = props.multiSelect.slice(0, 3);
  } else {
    concattedVal = props.multiSelect;
  }
  return (
    <div>
      <span
        className={`bg-${
          props.colorList[props.item.id]
        } rounded-lg text-white self-start ml-2 py-1 h-8 px-4 flex flex-row items-center whitespace-no-wrap`}
      >
        {props.keyVal} :
        {concattedVal.length > 0 &&
          concattedVal.map((input, i) => {
            return (
              <span key={i} className="pl-2 font-medium pl-2">
                {concattedVal}{" "}
                {i < props.multiSelect && props.multiSelect.length - 1
                  ? ","
                  : " "}{" "}
              </span>
            );
          })}
        {concattedVal.length < props.multiSelect.length ? (
          <span className="ml-2 rounded-full h-5 w-5 bg-grey inline-block items-center self-center text-center">
            {props.multiSelect.length}
          </span>
        ) : null}
        {props.multiSelect.length < props.options.length && (
          <span
            className={`bg-${
              props.colorList[props.item.id]
            }er rounded-lg flex flex-col h-6 ml-2 w-12 `}
          ></span>
        )}
      </span>
    </div>
  );
}

export default MultiInput;
