const OPEN_MODAL = "OPEN_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";

const initialState = {
  isModalVisible: false,
  modalType: "",
  targetUnitData: {},
  modalSize: "",
};

export function closeModal() {
  return {
    type: CLOSE_MODAL,
  };
}

export function openModal(targetUnitData, modalType, modalSize) {
  return {
    type: OPEN_MODAL,
    targetUnitData: targetUnitData,
    modalType: modalType,
    modalSize: modalSize,
  };
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_MODAL:
      return Object.assign({}, state, {
        isModalVisible: true,
        targetUnitData: action.targetUnitData,
        modalType: action.modalType,
        modalSize: action.modalSize,
      });
    case CLOSE_MODAL:
      return Object.assign({}, state, { isModalVisible: false });
    default:
      return state;
  }
}
