import React, { Component } from "react";
import { connect } from "react-redux";
import { openModal } from "ducks/modal";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import { InputText } from "components/InputText";
import { Transition } from "react-transition-group";

import InputNumber from "components/InputNumber";
import { FormContentWrapper } from "containers/Forms/FormContainer/FormContentWrapper";
import { closeFormpanel } from "ducks/formpanel";
import {
  updatePriceRule,
  addNewPriceRule,
  getPreview,
  deletePriceRule,
  closeWarnings,
} from "ducks/priceRule";
import HandleWarnings from "components/HandleWarnings/HandleWarnings";
import * as MODAL_TYPE from "utils/modal-types";

import * as FORM_ACTION from "utils/form-actions";
import SingleSelectDropdown from "components/SingleSelectDropdown";

import SelectableList from "components/SelectableList/SelectableList";
import PreviewPanel from "components/PreviewPanel/PreviewPanel";

class PriceRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formWidthGrow: false,
      canSave: false,
      id: "",
      name: "",
      priority: "",
      groupOptions: [],
      groups: [],
      filteredSelectedGroups: [],
      filteredGroups: [],
      selectedGroups: [],
      campaign: {},
      strategyOptions: [],
      strategy: {},
      strategyValue: "",
      strategyValueOptions: [],
      minPriceLimitOptions: [],
      minPriceLimit: {},
      maxPriceLimitOptions: [],
      maxPriceLimit: {},
      competitors: [],
      competitorsOption: [],
      minPriceLimitValue: "",
      maxPriceLimitValue: "",
      limitValues: [],
      priceIntervalLimit: "",
      loweredPriceLimit: "",
      fallbackPrice: {},
      fallbackPriceValue: "",
      nameError: false,
      campaignError: false,
      strategyError: false,
      strategyValueError: false,
      priorityError: false,
      priorityFormatError: false,
      competitorsError: false,
      minPriceLimitError: false,
      maxPriceLimitError: false,
      minPriceLimitValueError: false,
      maxPriceLimitValueError: false,
      priceIntervalLimitError: false,
      loweredPriceLimitError: false,
      fallbackPriceValueError: false,
      fallbackPriceError: false,
      invalidLimitsInput: null,
      preview: false,
      priorityErrorMsg: null,
      currency: "",
      loweredPriceLimit: "",
      IisWarnings: false,
      onlyVerifiedMatches: false,
    };
  }

  componentDidMount() {
    this.props.toggleFormWidth();
    const { data } = this.props;
    let selectAll = [{ id: 0, name: "Select All" }];
    let market = this.props.market.filter(
      (market) => market.id === parseInt(this.props.selectedMenuItem)
    );
    data &&
      this.setState(
        {
          groupOptions: this.props.groups,
          strategyOptions: this.props.config.strategy,
          minPriceLimitOptions: this.props.config.minLimit,
          maxPriceLimitOptions: this.props.config.maxLimit,
          competitorsOptions: selectAll.concat(this.props.competitors),
          // limitValues: limitValues,
          filteredGroups: this.props.groups,
          currency:
            this.props.market.length > 0 ? market[0].currency.currency : "",
        },

        () => {
          if (this.props.formState === FORM_ACTION.STATE_EDIT) {
            let campaign = { ...data.campaign };
            campaign.startDate = campaign.startDate.replace(" ", "T") + ":00Z";
            campaign.endDate = campaign.endDate.replace(" ", "T") + ":00Z";
            this.setState({
              name: data.name ? data.name : "",
              groups: data.groups.value ? data.groups.value : [],
              filteredSelectedGroups: data.groups.value
                ? data.groups.value
                : [],
              maxPriceLimit: data.maxPriceLimit ? data.maxPriceLimit : {},
              minPriceLimit: data.minPriceLimit ? data.minPriceLimit : {},
              strategy: data.strategy,
              competitors: data.competitors ? data.competitors : [],
              loweredPriceLimit:
                data.loweredPriceLimit !== null &&
                data.loweredPriceLimit !== undefined
                  ? data.loweredPriceLimit.toString() === "0"
                    ? "0"
                    : data.loweredPriceLimit
                    ? data.loweredPriceLimit
                    : ""
                  : "",
              fallbackPrice: data.fallbackPrice ? data.fallbackPrice : {},
              fallbackPriceValue:
                data.fallbackPrice !== null && data.fallbackPrice !== undefined
                  ? data.fallbackPriceValue.toString() === "0"
                    ? "0"
                    : data.fallbackPriceValue
                    ? data.fallbackPriceValue
                    : ""
                  : "",
              minPriceLimitValue:
                data.minPriceLimit !== null && data.minPriceLimit !== undefined
                  ? data.minPriceLimitValue.toString() === "0"
                    ? "0"
                    : data.minPriceLimitValue
                    ? data.minPriceLimitValue
                    : ""
                  : "",
              maxPriceLimitValue:
                data.maxPriceLimit !== null && data.maxPriceLimit !== undefined
                  ? data.maxPriceLimitValue.toString() === "0"
                    ? "0"
                    : data.maxPriceLimitValue
                    ? data.maxPriceLimitValue
                    : ""
                  : "",
              priority: data.priority ? data.priority : "",
              campaign: campaign ? campaign : {},
              selectedGroups: data.groups.value ? data.groups.value : [],
              strategyValue:
                data.strategyValue !== null && data.strategyValue !== undefined
                  ? data.strategyValue.toString() === "0"
                    ? "0"
                    : data.strategyValue
                    ? data.strategyValue
                    : ""
                  : "",
              priceIntervalLimit:
                data.priceIntervalLimit !== null &&
                data.priceIntervalLimit !== undefined
                  ? data.priceIntervalLimit.toString() === "0"
                    ? "0"
                    : data.priceIntervalLimit
                    ? data.priceIntervalLimit
                    : ""
                  : "",
              onlyVerifiedMatches: data.onlyVerifiedMatches,
            });
          }
        }
      );
    if (this.props.competitors && this.props.competitors.length > 0) {
      for (let i = 1; i <= this.props.competitors.length; i++) {
        this.state.strategyValueOptions.push({
          id: i,
          name: JSON.stringify(i),
        });
      }
    }
  }

  //INPUT FUNCTIONS
  handleChange = (name, event) => {
    this.setState({ priorityFormatError: false });
    this.setState({
      [name]: event.target.value,
      [name + "Error"]: false,
      canSave: true,
    });
    if (name === "priority" && parseInt(event.target.value) < 1) {
      this.setState({ priorityFormatError: true });
      return;
    } else if (
      name === "loweredPriceLimit" &&
      (parseInt(event.target.value) < 0 || parseInt(event.target.value) > 100)
    ) {
      return;
    } else if (
      name === "minPriceLimitValue" &&
      this.state.minPriceLimit.unit &&
      this.state.minPriceLimit.unit.includes("Percentage") &&
      parseInt(event.target.value) > 100
    ) {
      return;
    } else if (
      name === "maxPriceLimitValue" &&
      this.state.maxPriceLimit.unit &&
      this.state.maxPriceLimit.unit.includes("Percentage") &&
      parseInt(event.target.value) > 100
    ) {
      return;
    } else if (
      name === "fallbackPriceValue" &&
      this.state.fallbackPrice.unit &&
      this.state.fallbackPrice.unit.includes("Percentage") &&
      parseInt(event.target.value) > 100
    ) {
      return;
    } else if (
      event.target.value.length > 0 &&
      ((name === "minPriceLimitValue" &&
        !this.state.minPriceLimit.unit.includes("Percentage")) ||
        (name === "maxPriceLimitValue" &&
          !this.state.maxPriceLimit.unit.includes("Percentage")))
    ) {
      //regex check for whole positive number
      var rx = new RegExp(/^(0|[1-9]\d*)$/);
      if (rx.test(event.target.value)) {
        return this.setState({
          [name]: event.target.value,
          minPriceLimitValueError: false,
          maxPriceLimitValueError: false,
          canSave: true,
        });
      } else {
        this.setState({
          [name]: Math.abs(event.target.value),
          minPriceLimitValueError: false,
          maxPriceLimitValueError: false,
          canSave: true,
        });
      }
    } else if (
      ((name === "minPriceLimitValue" || name === "maxPriceLimitValue") &&
        this.state.maxPriceLimit.name &&
        this.state.maxPriceLimit.unit.includes("Percentage")) ||
      (this.state.minPriceLimit.name &&
        this.state.minPriceLimit.unit.includes("Percentage")) ||
      name === "strategyValue"
    ) {
      //CHECK SO ONLY TWO DECIMALS
      var rx = new RegExp(/^-?\d+(?:\.\d{1,2})?$/);
      if (rx.test(event.target.value) || event.target.value.length < 1) {
        return this.setState({
          [name]: event.target.value,
          minPriceLimitValueError: false,
          maxPriceLimitValueError: false,
          canSave: true,
        });
      } else {
        return;
      }
    } else if (
      name === "priceIntervalLimit" &&
      (parseInt(event.target.value) < 0 || parseInt(event.target.value) > 100)
    ) {
      return;
    } else if (
      name === "loweredPriceLimit" &&
      (parseInt(event.target.value) < 0 || parseInt(event.target.value) > 100)
    ) {
      return;
    } else if (name === "loweredPriceLimit" || name === "priceIntervalLimit") {
      //CHECK SO ONLY TWO DECIMALS
      var rx = new RegExp(/^\d+(?:\.\d{1,2})?$/);
      if (rx.test(event.target.value)) {
        return this.setState({
          [name]: event.target.value,
          canSave: true,
        });
      } else {
        return;
      }
    } else {
      this.setState({ priorityFormatError: false });
      this.setState({
        [name]: event.target.value,
        [name + "Error"]: false,
        canSave: true,
      });
    }
  };

  validationCheck = () => {
    let error = false;
    const {
      name,
      strategy,
      priority,
      minPriceLimit,
      maxPriceLimit,
      competitors,
      minPriceLimitValue,
      maxPriceLimitValue,
      campaign,
      strategyValue,
      fallbackPrice,
      fallbackPriceValue,
    } = this.state;

    if (this.state.strategy.name === "Ignore") {
      if (name.length < 1) {
        error = true;
        this.setState({ nameError: true });
      }
      if (campaign && Object.keys(campaign).length < 1) {
        error = true;
        this.setState({ campaignError: true });
      }
      if (priority.length < 1) {
        error = true;
        this.setState({ priorityError: true, priorityFormatError: false });
      }
      if (strategy && Object.keys(strategy).length < 1) {
        error = true;
        this.setState({ strategyError: true });
      }
    } else {
      if (name.length < 1) {
        error = true;
        this.setState({ nameError: true });
      }
      if (campaign && Object.keys(campaign).length < 1) {
        error = true;
        this.setState({ campaignError: true });
      }
      if (priority.length < 1) {
        error = true;
        this.setState({ priorityError: true, priorityFormatError: false });
      }
      if (strategy && Object.keys(strategy).length < 1) {
        error = true;
        this.setState({ strategyError: true });
      }

      if (
        maxPriceLimit.name === minPriceLimit.name &&
        parseInt(minPriceLimitValue, 10) > parseInt(maxPriceLimitValue, 10)
      ) {
        error = true;
        this.setState({
          minPriceLimitValueError: true,
          maxPriceLimitValueError: true,
        });
      }
      if (
        maxPriceLimit.unit &&
        maxPriceLimit.unit.includes("Percentage") &&
        maxPriceLimitValue > 100
      ) {
        error = true;
        this.setState({ maxPriceLimitValueError: true });
      }

      if (
        minPriceLimit.unit &&
        minPriceLimit.id === 6 &&
        minPriceLimit.unit.includes("Percentage") &&
        minPriceLimitValue < -100
      ) {
        error = true;
        this.setState({ minPriceLimitValueError: true });
      }
      if (
        minPriceLimit.unit &&
        minPriceLimit.id !== 6 &&
        minPriceLimit.unit.includes("Percentage") &&
        minPriceLimitValue > 100
      ) {
        error = true;
        this.setState({ minPriceLimitValueError: true });
      }
      if (fallbackPrice && Object.keys(fallbackPrice).length < 1) {
        error = true;
        this.setState({
          fallbackPriceError: true,
        });
      }
      if (
        fallbackPrice.unit &&
        fallbackPrice.unit.includes("Percentage") &&
        fallbackPriceValue > 100
      ) {
        error = true;
        this.setState({ fallbackPriceValueError: true });
      }
      if (fallbackPriceValue.length < 1) {
        error = true;
        this.setState({ fallbackPriceValueError: true });
      }

      if (
        strategyValue.length < 1 &&
        this.state.strategy &&
        (this.state.strategy.name === "Fixed Price" ||
          this.state.strategy.name === "Lowest Price")
      ) {
        error = true;
        this.setState({ strategyValueError: true });
      }

      if (
        (competitors === undefined || competitors.length < 1) &&
        this.state.strategy.name === "Lowest Price"
      ) {
        error = true;
        this.setState({ competitorsError: true });
      }
    }
    return error;
  };

  //DROPDOWN SELECT FUNCTIONS
  handleSelected = (name, selected) => {
    if (selected.id !== null) {
      this.setState({
        [name]: selected,
        canSave: true,
        [name + "Error"]: false,
      });
      if (
        name === "minPriceLimit" &&
        selected.unit &&
        !selected.unit.includes("Percentage")
      ) {
        this.setState({
          minPriceLimitValue: Math.round(this.state.minPriceLimitValue),
        });
      } else if (
        name === "maxPriceLimit" &&
        selected.unit &&
        !selected.unit.includes("Percentage")
      ) {
        this.setState({
          maxPriceLimitValue: Math.round(this.state.maxPriceLimitValue),
        });
      } else if (
        this.state.maxPriceLimit.name !== this.state.minPriceLimit.name ||
        name == "maxPriceLimit" ||
        name == "minPriceLimit"
      ) {
        this.setState({
          minPriceLimitValueError: false,
          maxPriceLimitValueError: false,
        });
      }
    } else if (selected.id === null) {
      this.setState({
        [name]: null,
        canSave: true,
        minPriceLimitValueError: false,
        maxPriceLimitValueError: false,
      });
    }

    if (
      selected.length > 0 &&
      selected.find((selected) => selected.name == "Select All")
    ) {
      if (selected.map((select) => select.name.includes("Select All"))) {
        if (
          name == "competitors" &&
          this.state.competitors.length !=
            this.state.competitorsOptions.length - 1
        ) {
          this.setState({
            [name]: this.props.competitors,
            canSave: true,
          });
        } else if (
          name == "competitors" &&
          this.state.competitors.length ==
            this.state.competitorsOptions.length - 1
        ) {
          this.setState({
            [name]: [],
            canSave: true,
          });
        }
      }
    } else if (selected.id !== null) {
      this.setState({
        [name]: selected,
        [name + "Error"]: false,
        canSave: true,
      });
    }
  };

  //FORM SUBMIT FUNCTION - POSTS/MUTATIONS WILL BE MADE HERE
  handleSubmit = () => {
    const { updatePriceRule, addNewPriceRule } = this.props;
    const {
      name,
      strategy,
      priority,
      minPriceLimit,
      maxPriceLimit,
      campaign,
      competitors,
      minPriceLimitValue,
      maxPriceLimitValue,
      selectedGroups,
      loweredPriceLimit,
      priceIntervalLimit,
    } = this.state;

    //loweredPriceLimit
    //decimal
    if (!this.validationCheck()) {
      const parsedPrio = parseInt(priority, 10);

      const parsedStrategyValue = parseFloat(this.state.strategyValue);

      if (this.props.formState === FORM_ACTION.STATE_EDIT) {
        const updRule = {
          campaign,
          id: this.props.data.id,
          name,
          groups: selectedGroups,
          strategy: {
            id: strategy.id,
          },
          strategyValue:
            this.state.strategy.name === "Ignore" ? null : parsedStrategyValue,

          priority: parsedPrio,
          minPriceLimit:
            this.state.strategy.name === "Ignore"
              ? null
              : (minPriceLimit && Object.keys(minPriceLimit).length < 1) ||
                minPriceLimitValue.length < 1 ||
                minPriceLimitValue.length === null
              ? null
              : minPriceLimit,
          maxPriceLimit:
            this.state.strategy.name === "Ignore"
              ? null
              : (maxPriceLimit && Object.keys(maxPriceLimit).length < 1) ||
                maxPriceLimitValue.length < 1 ||
                maxPriceLimitValue.length === null
              ? null
              : maxPriceLimit,
          minPriceLimitValue:
            this.state.strategy.name === "Ignore"
              ? null
              : minPriceLimitValue.length < 1 ||
                minPriceLimitValue.length === null ||
                minPriceLimit === null ||
                (minPriceLimit && Object.keys(minPriceLimit).length < 1)
              ? null
              : minPriceLimitValue,
          maxPriceLimitValue:
            this.state.strategy.name === "Ignore"
              ? null
              : maxPriceLimitValue.length < 1 ||
                maxPriceLimitValue.length === null ||
                maxPriceLimit === null ||
                (maxPriceLimit && Object.keys(maxPriceLimit).length < 1)
              ? null
              : maxPriceLimitValue,
          fallbackPrice:
            Object.keys(this.state.fallbackPrice).length > 0
              ? this.state.fallbackPrice
              : null,
          fallbackPriceValue: parseInt(this.state.fallbackPriceValue),
          competitors:
            this.state.strategy.name === "Ignore" ? null : competitors,
          loweredPriceLimit:
            this.state.strategy.name === "Ignore" ? null : loweredPriceLimit,
          priceIntervalLimit:
            this.state.strategy.name === "Ignore" ? null : priceIntervalLimit,
          onlyVerifiedMatches: this.state.onlyVerifiedMatches,
        };
        updatePriceRule(
          updRule,
          this.props.page,
          this.props.rowSize,
          this.props.sortingAttr,
          this.props.sortingDirection
        );
        this.props.closeFormpanel();
      } else {
        const newRule = {
          campaign,
          groups: selectedGroups,
          name,
          strategy: {
            id: strategy.id,
          },
          strategyValue:
            this.state.strategy.name === "Ignore" ? null : parsedStrategyValue,
          priority: parsedPrio,
          minPriceLimit:
            this.state.strategy.name === "Ignore"
              ? null
              : Object.entries(minPriceLimit).length < 1 ||
                minPriceLimitValue.length < 1 ||
                minPriceLimitValue.length === null
              ? null
              : minPriceLimit,
          minPriceLimitValue:
            this.state.strategy.name === "Ignore"
              ? null
              : minPriceLimitValue.length < 1 ||
                minPriceLimitValue.length === null ||
                (minPriceLimit && Object.keys(minPriceLimit).length < 1)
              ? null
              : parseInt(minPriceLimitValue),
          maxPriceLimitValue:
            this.state.strategy.name === "Ignore"
              ? null
              : maxPriceLimitValue.length < 1 ||
                maxPriceLimitValue.length === null ||
                (maxPriceLimit && Object.keys(maxPriceLimit).length < 1)
              ? null
              : parseInt(maxPriceLimitValue),
          maxPriceLimit:
            this.state.strategy.name === "Ignore"
              ? null
              : Object.entries(maxPriceLimit).length < 1 ||
                maxPriceLimitValue.length < 1 ||
                maxPriceLimitValue.length === null
              ? null
              : maxPriceLimit,
          fallbackPrice:
            Object.keys(this.state.fallbackPrice).length > 0
              ? this.state.fallbackPrice
              : null,
          fallbackPriceValue: parseInt(this.state.fallbackPriceValue),
          competitors:
            this.state.strategy.name === "Ignore" ? null : competitors,
          loweredPriceLimit:
            this.state.strategy.name === "Ignore" ? null : loweredPriceLimit,
          priceIntervalLimit:
            this.state.strategy.name === "Ignore" ? null : priceIntervalLimit,
          onlyVerifiedMatches: this.state.onlyVerifiedMatches,

          // loweredPriceLimit: parseFloat(loweredPriceLimit)
        };
        this.props.openModal(
          newRule,
          MODAL_TYPE.APPLY_RULE_PROMPT,
          MODAL_TYPE.SMALL
        );
      }
    }
  };

  //REMOVE PRICE RULE
  handleDelete = () => {
    this.props.openModal(this.props.data, MODAL_TYPE.DELETE, MODAL_TYPE.SMALL);
  };

  //FILTER
  handleChangeFilter = (e) => {
    let currentList = [];
    let newList = [];
    //check if input is not empty
    if (e.target.value !== "") {
      if (e.target.name === "filteredGroups") {
        currentList = this.state.groupOptions;
      }
      if (e.target.name === "filteredSelectedGroups") {
        currentList = this.state.filteredSelectedGroups;
      }
      if (currentList && currentList.length <= 0) {
        currentList =
          e.target.name === "filteredSelectedGroups"
            ? this.state.selectedGroups
            : this.state.groupOptions;
      }

      //to lowercase and find match
      newList =
        currentList &&
        currentList.filter((group) => {
          const tlc = group.name.toLowerCase();
          const filter = e.target.value.toLowerCase();
          return tlc.includes(filter);
        });

      //otherwise return original list
    } else if (e.target.value === "") {
      if (e.target.name === "filteredGroups") {
        newList = this.state.groupOptions;
      }
      if (e.target.name === "filteredSelectedGroups") {
        newList = [...this.state.selectedGroups];
      }
    }
    this.setState({
      [e.target.name]: newList,
    });
  };

  //FOR SELECTABLE GROUP LIST
  handleSelectecdGroup = (id) => {
    const { groupOptions, selectedGroups } = this.state;
    //check if already selected
    const disabled =
      selectedGroups && selectedGroups.find((item) => item.id === id);
    if (disabled && disabled.id === id) {
      return null;
    } else {
      //if not set as selected
      const item = groupOptions.find((group) => id === group.id);

      this.setState({
        selectedGroups: [...selectedGroups, item],
        filteredSelectedGroups: [...selectedGroups, item],
        canSave: true,
      });
    }
  };
  //DELETE ITEM FROM SELECTED GROUP
  removeSelectedGroup = (id) => {
    const removed = this.state.filteredSelectedGroups.filter(
      (item) => item.id !== id
    );

    this.setState({
      selectedGroups: removed,
      filteredSelectedGroups: removed,
      canSave: true,
    });
  };

  //CLOSES THE FORM
  handleCancel = () => {
    this.props.closeFormpanel();
  };

  removeFromSelect = (id) => {
    const arr = [...this.state.competitors];
    const remove = this.state.competitors.find((item) => item.id === id);
    arr.splice(remove, 1);

    this.setState({ competitors: arr });
  };

  showPreview = () => {
    this.setState((prevState) => ({
      preview: !prevState.preview,
    }));
  };

  calcPreview = () => {
    const {
      name,
      strategy,
      priority,
      minPriceLimit,
      maxPriceLimit,
      campaign,
      competitors,
      minPriceLimitValue,
      maxPriceLimitValue,
      selectedGroups,
      loweredPriceLimit,
      priceIntervalLimit,
    } = this.state;
    const parsedPrio = parseInt(priority, 10);

    const parsedStrategyValue = parseFloat(this.state.strategyValue);
    if (!this.validationCheck()) {
      const calcRule = {
        campaign,
        id: this.props.data.id,
        name,
        groups: selectedGroups,
        strategy: {
          id: strategy.id,
        },
        strategyValue:
          this.state.strategy.name === "Ignore" ? null : parsedStrategyValue,

        priority: parsedPrio,
        minPriceLimit:
          this.state.strategy.name === "Ignore"
            ? null
            : (minPriceLimit && Object.keys(minPriceLimit).length < 1) ||
              minPriceLimitValue.length < 1 ||
              minPriceLimitValue.length === null
            ? null
            : minPriceLimit,
        maxPriceLimit:
          this.state.strategy.name === "Ignore"
            ? null
            : (maxPriceLimit && Object.keys(maxPriceLimit).length < 1) ||
              maxPriceLimitValue.length < 1 ||
              maxPriceLimitValue.length === null
            ? null
            : maxPriceLimit,
        minPriceLimitValue:
          this.state.strategy.name === "Ignore"
            ? null
            : minPriceLimitValue.length < 1 ||
              minPriceLimitValue.length === null ||
              minPriceLimit === null ||
              (minPriceLimit && Object.keys(minPriceLimit).length < 1)
            ? null
            : minPriceLimitValue,
        maxPriceLimitValue:
          this.state.strategy.name === "Ignore"
            ? null
            : maxPriceLimitValue.length < 1 ||
              maxPriceLimitValue.length === null ||
              maxPriceLimit === null ||
              (maxPriceLimit && Object.keys(maxPriceLimit).length < 1)
            ? null
            : maxPriceLimitValue,
        fallbackPrice:
          Object.keys(this.state.fallbackPrice).length > 0
            ? this.state.fallbackPrice
            : null,
        fallbackPriceValue: parseInt(this.state.fallbackPriceValue),
        competitors: this.state.strategy.name === "Ignore" ? null : competitors,
        loweredPriceLimit:
          this.state.strategy.name === "Ignore" ? null : loweredPriceLimit,
        priceIntervalLimit:
          this.state.strategy.name === "Ignore" ? null : priceIntervalLimit,
        onlyVerifiedMatches: this.state.onlyVerifiedMatches,
      };
      this.props.getPreview(calcRule);
      this.showPreview();
    }
  };

  handleIncludeVerified = () => {
    this.setState((prevState) => ({
      onlyVerifiedMatches: !prevState.onlyVerifiedMatches,
      canSave: true,
    }));
  };

  // SHOW WARNING ON CLICK
  // displayWarnings = () => {
  //   if (this.props.warnings.length > 0) {
  //     this.setState(prevState => ({
  //       isWarnings: !prevState.isWarnings
  //     }));
  //   } else return;
  // };

  render() {
    let filteredPriceStrategy = this.props.config.strategy
      ? this.props.config.strategy
      : [];

    if (Object.keys(this.state.campaign).length > 1) {
      if (
        this.state.campaign.type &&
        this.state.campaign.type.toLowerCase() === "block"
      ) {
        filteredPriceStrategy = filteredPriceStrategy.filter(
          (option) => option.name.toLowerCase() === "ignore"
        );
      } else {
        filteredPriceStrategy = filteredPriceStrategy.filter(
          (option) => option.name.toLowerCase() !== "ignore"
        );
      }
    }
    return (
      <FormContentWrapper
        formConfig={{
          formTitle:
            this.props.formState === FORM_ACTION.STATE_EDIT
              ? "Edit Price Rule"
              : "Create Price Rule",
          buttonLabel:
            this.props.formState === FORM_ACTION.STATE_EDIT
              ? "UPDATE"
              : "CREATE",
          // footerLeft: footerLeftExample
        }}
        formName="demandOverview"
        handleSubmit={this.handleSubmit}
        handleCancel={this.handleCancel}
        canSave={this.state.canSave}
        hasDelete={this.props.formState === FORM_ACTION.STATE_EDIT}
        handleDelete={this.handleDelete}
        deleteMessage="Delete Price Rule"
      >
        <div className="flex flex-row px-4 my-6 w-full relative">
          <div className="flex w-1/2">
            <div className="w-1/2 mx-4 flex flex-col">
              <InputText
                width="w-full"
                name="name"
                value={this.state.name}
                onChange={(event) => this.handleChange("name", event)}
                placeholder="Name"
                label="name"
                required
                error={this.state.nameError}
              />
            </div>
            <div className="w-1/2 mx-4 flex flex-col">
              {this.props.formState === FORM_ACTION.STATE_EDIT ? (
                <>
                  <p className="text-grey-dark text-xs font-light flex pt-1">
                    Campaign
                  </p>
                  <p className="text-grey-dark font-light mt-3 ">
                    {" "}
                    {this.state.campaign.name}{" "}
                  </p>
                </>
              ) : (
                <SingleSelectDropdown
                  width="w-full"
                  options={this.props.campaigns}
                  selected={this.state.campaign ? this.state.campaign : {}}
                  name="campaign"
                  label="Campaign"
                  handleSelect={this.handleSelected}
                  placeholder="Select Campaign"
                  required
                  error={this.state.campaignError}
                />
              )}
            </div>
          </div>
          <div className=" flex flex-column">
            <InputNumber
              width="w-32"
              label="Priority"
              name="priority"
              value={this.state.priority}
              onChange={(event) => this.handleChange("priority", event)}
              placeholder="0000"
              min="1"
              required={true}
              error={this.state.priorityError}
              showErrorMessage={this.state.priorityFormatError}
              errorMessage="Invalid Format"
            />
          </div>
          <div className="w-1/6 right-0 mr-6 absolute">
            <div
              onClick={this.calcPreview}
              className="flex align-middle cursor-pointer"
            >
              <i className="material-icons text-teal-dark">
                {" "}
                {!this.state.preview ? "visibility" : "visibility_off"}{" "}
              </i>
              <span className="pl-2 font-light text-teal-dark">Preview</span>
            </div>

            {this.state.preview ? (
              <PreviewPanel data={this.props.preview} />
            ) : null}
          </div>
        </div>

        <div className="flex flex-row">
          <div className="flex flex-column w-1/2 inline-block px-4">
            <SelectableList
              handleChangeFilter={(e) => this.handleChangeFilter(e)}
              handleSelectecdGroup={(id) => this.handleSelectecdGroup(id)}
              removeFromSelect={(id) => this.removeSelectedGroup(id)}
              filteredGroups={this.state.filteredGroups}
              selectedGroups={this.state.selectedGroups}
              filteredSelectedGroups={this.state.filteredSelectedGroups}
              groupOptions={this.state.groupOptions}
            />
          </div>
          <div className="flex flex-column flex-wrap w-2/5  pr-4">
            <div className="flex flex-row h-16">
              <div
                className={`"flex flex-col" ${
                  this.state.strategy.name === "Static" ||
                  this.state.strategy.name === "Ignore" ||
                  !Object.values(this.state.strategy).length > 0
                    ? "w-full"
                    : "w-1/3"
                } `}
              >
                <SingleSelectDropdown
                  width="w-full"
                  options={filteredPriceStrategy}
                  selected={this.state.strategy ? this.state.strategy : {}}
                  name="strategy"
                  label="Price Strategy"
                  handleSelect={this.handleSelected}
                  placeholder="Select price strategy"
                  required
                  error={this.state.strategyError}
                />
              </div>

              {(this.state.strategy &&
                this.state.strategy.name === "Lowest Price") ||
              this.state.strategy.name === "Fixed Price" ? (
                <>
                  <div className="flex flex-col w-1/3 ml-4">
                    <InputNumber
                      label={
                        this.state.strategy &&
                        this.state.strategy.name === "Fixed Price"
                          ? "Price"
                          : "Offset"
                      }
                      width="w-full"
                      name="strategyValue"
                      value={this.state.strategyValue}
                      onChange={(event) =>
                        this.handleChange("strategyValue", event)
                      }
                      placeholder="0000"
                      required={true}
                      error={this.state.strategyValueError}
                    />
                  </div>
                  <div className="flex h-full pt-3 ml-2 font-light">
                    <p className="text-teal-dark self-end">
                      {" "}
                      {this.state.currency}{" "}
                    </p>
                  </div>
                </>
              ) : null}
              {this.state.strategy &&
              this.state.strategy.name === "Position" ? (
                <>
                  <div className="flex flex-col w-1/3 ml-4">
                    <SingleSelectDropdown
                      label="Offset"
                      width="w-full"
                      options={this.state.strategyValueOptions}
                      selected={
                        this.state.strategyValue ? this.state.strategyValue : {}
                      }
                      name="strategyValue"
                      handleSelect={this.handleSelected}
                      placeholder="0"
                      required
                      error={this.state.strategyValueError}
                    />
                  </div>
                </>
              ) : null}
            </div>
            {this.state.strategy &&
            (this.state.strategy.name === "Fixed Price" ||
              this.state.strategy.name === "Ignore") ? null : (
              <>
                <div className="flex flex-row  w-full">
                  <div className="mt-4">
                    <MultiSelectDropdown
                      width="w-full "
                      options={this.state.competitorsOptions}
                      placeholder="Select Competitors"
                      name="competitors"
                      label="Competitors"
                      selected={this.state.competitors}
                      handleSelect={this.handleSelected}
                      required
                      error={this.state.competitorsError}
                    />
                  </div>
                </div>
                <ul className="flex flex-row max-w-20 flex-wrap mt-4 text-white">
                  {this.state.competitors &&
                    Object.keys(this.state.competitors).map((key) => {
                      return (
                        <li
                          className="h-8 bg-teal text-xs ml-2 rounded-full px-4 flex items-center justify-center mt-2 hover:bg-teal-dark"
                          key={this.state.competitors[key].id}
                        >
                          <p className="flex-1 truncate">
                            {" "}
                            {this.state.competitors[key].name}{" "}
                          </p>
                          <div className="w-2 ml-2 mr-1">
                            <i
                              className="material-icons hover:bg-teal p-1 rounded-full text-sm cursor-pointer"
                              onClick={() =>
                                this.removeFromSelect(
                                  this.state.competitors[key].id
                                )
                              }
                            >
                              close
                            </i>
                          </div>
                        </li>
                      );
                    })}
                </ul>

                <div className="flex flex-row  w-full mt-6">
                  <div className="flex flex-col w-1/3">
                    <SingleSelectDropdown
                      width="w-full"
                      label="Fallback Price"
                      options={this.state.maxPriceLimitOptions}
                      selected={
                        this.state.fallbackPrice ? this.state.fallbackPrice : {}
                      }
                      name="fallbackPrice"
                      handleSelect={this.handleSelected}
                      placeholder="Select Fallback Price"
                      required={true}
                      error={this.state.fallbackPriceError}
                    />
                  </div>
                  <div className="flex flex-col w-1/5 ml-4">
                    <InputNumber
                      width="w-full"
                      value={this.state.fallbackPriceValue}
                      name="fallbackPriceValue"
                      error={this.state.fallbackPriceValueError}
                      onChange={(event) =>
                        this.handleChange("fallbackPriceValue", event)
                      }
                      step="any"
                      placeholder="0"
                      required={true}
                      max={
                        this.state.fallbackPrice.name &&
                        this.state.fallbackPrice.unit.includes("Percentage")
                          ? 100
                          : false
                      }
                    />
                  </div>
                  {this.state.fallbackPrice && this.state.fallbackPrice.name && (
                    <div className="flex items-center pt-3 ml-2 font-light self-end">
                      <p className="text-teal-dark">
                        {this.state.fallbackPrice.unit.includes("Double")
                          ? this.state.currency
                          : "%"}
                      </p>
                    </div>
                  )}
                </div>

                <div className="w-full flex flex-row py-2 mt-4">
                  <i
                    className="material-icons text-teal-dark text-xl cursor-pointer"
                    onClick={() => this.handleIncludeVerified()}
                  >
                    {this.state.onlyVerifiedMatches
                      ? "check_circle"
                      : "radio_button_unchecked"}
                  </i>
                  <div className="px-2 font-sans font-light text-sm text-grey-darker">
                    Only include verified matches.
                  </div>
                </div>

                <div className="flex flex-row  w-full">
                  <p className="mt-6 text-teal-dark font-light flex">Limits</p>
                  <span className="mt-10  text-grey-dark text-xs pl-4 self-center ">
                    All fields below are optional
                  </span>
                </div>
                <div className="flex flex-row  w-full">
                  <div className="flex flex-col w-1/3">
                    <SingleSelectDropdown
                      width="w-full"
                      label="Max"
                      options={this.state.maxPriceLimitOptions}
                      selected={
                        this.state.maxPriceLimit ? this.state.maxPriceLimit : {}
                      }
                      name="maxPriceLimit"
                      handleSelect={this.handleSelected}
                      placeholder="Select a max"
                      // required
                      // error={this.state.maxPriceLimitError}
                    />
                  </div>
                  <div className="flex flex-col w-1/5 ml-4">
                    <InputNumber
                      width="w-full"
                      value={this.state.maxPriceLimitValue}
                      name="maxPriceLimitValue"
                      error={this.state.maxPriceLimitValueError}
                      max={
                        this.state.maxPriceLimit.name &&
                        this.state.maxPriceLimit.unit.includes("Percentage")
                          ? 100
                          : false
                      }
                      onChange={(event) =>
                        this.handleChange("maxPriceLimitValue", event)
                      }
                      step="any"
                      placeholder="0"
                    />
                  </div>
                  {this.state.maxPriceLimit && this.state.maxPriceLimit.name && (
                    <div className="flex items-center pt-3 ml-2 font-light self-end">
                      <p className="text-teal-dark">
                        {this.state.maxPriceLimit.unit.includes("Double")
                          ? this.state.currency
                          : "%"}
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex flex-row  w-full">
                  <div className="flex flex-col w-1/3">
                    <SingleSelectDropdown
                      width="w-full"
                      label="Min"
                      options={this.state.minPriceLimitOptions}
                      selected={
                        Object.keys(this.state.minPriceLimit).length > 0
                          ? this.state.minPriceLimit
                          : {}
                      }
                      name="minPriceLimit"
                      handleSelect={this.handleSelected}
                      placeholder="Select a min"
                    />
                  </div>
                  <div className="flex flex-col w-1/5 ml-4">
                    <InputNumber
                      width="w-full"
                      value={this.state.minPriceLimitValue}
                      name="minPriceLimitValue"
                      error={this.state.minPriceLimitValueError}
                      onChange={(event) =>
                        this.handleChange("minPriceLimitValue", event)
                      }
                      step="any"
                      placeholder="0"
                      max={
                        this.state.minPriceLimit.name &&
                        this.state.minPriceLimit.unit.includes("Percentage")
                          ? 100
                          : false
                      }
                    />
                  </div>
                  {this.state.minPriceLimit && this.state.minPriceLimit.name && (
                    <div className="flex items-center pt-3 ml-2 font-light self-end">
                      <p className="text-teal-dark">
                        {this.state.minPriceLimit.unit.includes("Double")
                          ? this.state.currency
                          : "%"}
                      </p>
                    </div>
                  )}
                </div>
                {this.state.minPriceLimitValueError &&
                this.state.maxPriceLimitValueError ? (
                  <span className="font-thin text-red  text-xs w-full text-center">
                    {" "}
                    * Invalid values
                  </span>
                ) : null}
                <div className="flex flex-row  w-full">
                  <div className="flex flex-col w-1/3 self-end">
                    <span className="text-teal-dark font-light text-xs ">
                      Do not follow competitor lowered more than
                    </span>
                  </div>
                  <div className="flex flex-col w-1/5 ml-4">
                    <InputNumber
                      width="w-full"
                      value={this.state.loweredPriceLimit}
                      name="loweredPriceLimit"
                      onChange={(event) =>
                        this.handleChange("loweredPriceLimit", event)
                      }
                      step="any"
                      placeholder="0"
                      max="100"
                    />
                  </div>

                  <div className="flex items-center pt-3 ml-2 font-light self-end">
                    <p className="text-teal-dark">%</p>
                  </div>
                </div>
                <div className="flex flex-row  w-full">
                  <div className="flex flex-col w-1/3 self-end">
                    <span className="text-teal-dark font-light text-xs ">
                      Max price interval per day
                    </span>
                  </div>
                  <div className="flex flex-col w-1/5 ml-4 ">
                    <InputNumber
                      width="w-full"
                      value={this.state.priceIntervalLimit}
                      name="priceIntervalLimit"
                      onChange={(event) =>
                        this.handleChange("priceIntervalLimit", event)
                      }
                      step="any"
                      placeholder="0"
                      max="100"
                    />
                  </div>
                  <div className="flex items-center pt-3 ml-2 font-light self-end">
                    <p className="text-teal-dark">%</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <Transition
          in={this.props.warnings.length > 0}
          key={2}
          timeout={{ enter: 10, exit: 100 }}
          unmountOnExit
        >
          {(status) => (
            <HandleWarnings
              warnings={this.props.warnings}
              closeWarnings={this.props.closeWarnings}
              classList={`warning-${status}`}
            />
          )}
        </Transition>
      </FormContentWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeFormpanel: () => dispatch(closeFormpanel()),
    updatePriceRule: (updRule, page, rowSize, attr, direction) =>
      dispatch(updatePriceRule(updRule, page, rowSize, attr, direction)),
    addNewPriceRule: (newRule, page, rowSize, attr, direction) =>
      dispatch(addNewPriceRule(newRule, page, rowSize, attr, direction)),
    getPreview: (priceRule) => dispatch(getPreview(priceRule)),
    deletePriceRule: (remove, page, rowSize, attr, direction) =>
      dispatch(deletePriceRule(remove, page, rowSize, attr, direction)),
    openModal: (targetUnitData, modalType, modalSize) =>
      dispatch(openModal(targetUnitData, modalType, modalSize)),
    closeWarnings: () => dispatch(closeWarnings()),
  };
};

const mapStateToProps = (state) => {
  return {
    formState: state.formpanel.formState,
    campaigns: state.campaigns.campaignsDropdown,
    priceRules: state.priceRule.priceRuleList,
    page: state.tablePagination.priceRulePage,
    rowSize: state.tablePagination.priceRuleRowSize,
    sortingAttr: state.tablePagination.priceRuleSortingAttr,
    sortingDirection: state.tablePagination.priceRuleSortingDirection,
    groups: state.groups.groupsNoPag,
    competitors: state.priceRule.competitorList,
    config: state.priceRule.config,
    selectedMenuItem: state.sidepanel.selectedMenuItem,
    market: state.markets.markets,
    preview: state.priceRule.preview,
    warnings: state.priceRule.warnings,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceRule);

//TO BE ADDED IN THE FUTURE

{
  /* <div className="flex flex-row  w-full">
              <div className="flex w-1/3">
                <div className="flex items-center pt-3 ml-2 font-light">
                  <p className="text-teal-dark xxl:text-sm text-xs mr-3">
                    Do not follow lowered price
                  </p>
                </div>
              </div>
              <div className="flex flex-col w-1/5 ml-4">
                <InputNumber
                  width="w-full"
                  min="0"
                  max="100"
                  value={this.state.loweredPriceLimit}
                  name="loweredPriceLimit"
                  onChange={event =>
                    this.handleChange("loweredPriceLimit", event)
                  }
                />
              </div>
              <div className="flex items-center pt-3 ml-2 font-light">
                <p className="text-teal-dark"> % </p>
              </div>
            </div> */
}
