import React, { Component } from "react";
import axios from "axios";
import packageJson from "../package.json";
import moment from "moment";
import { connect } from "react-redux";
import { history } from "helpers/history";
import { setAppLoaded } from "ducks/general";
import { logout } from "services/logout.jsx";
import { setSelectedMenuItem, buildMarketMenuOptions } from "ducks/sidepanel";
import { openModal } from "ducks/modal";
import { getMarkets } from "ducks/markets";
import ClearCache from "./ClearCache";
import App from "./App";

class AuthCheck extends Component {
  state = {
    isLatestBuildDate: false,
  };
  componentDidMount() {
    axios
      .get(process.env.REACT_APP_API_HOST + "/auth/token/check")
      .then((res) => {
        if (
          localStorage.getItem("user") &&
          localStorage.getItem("market") &&
          localStorage.getItem("name") &&
          localStorage.getItem("domain") &&
          localStorage.getItem("country") &&
          localStorage.getItem("features") &&
          localStorage.getItem("channel")
        ) {
          axios
            .get(process.env.REACT_APP_API_HOST + "/auth/user-info/" + 3)
            .then((res) => {
              let token = JSON.parse(
                atob(res.headers["x-access-token"].split(".")[1])
              );
              let user = localStorage.getItem("user");

              if (res.data.email === JSON.parse(user).email) {
                this.props.setAppLoaded();
                this.props.setSelectedMenuItem(
                  localStorage.getItem("market"),
                  localStorage.getItem("name"),
                  localStorage.getItem("domain"),
                  localStorage.getItem("country"),
                  localStorage.getItem("features"),
                  localStorage.getItem("channel")
                );
              } else {
                logout();
              }
            });
        } else {
          this.setUser();
        }
      })
      .catch((error) => {
        axios
          .post(process.env.REACT_APP_API_HOST + "/auth/token")
          .then((res) => {
            if (
              localStorage.getItem("user") &&
              localStorage.getItem("market") &&
              localStorage.getItem("name") &&
              localStorage.getItem("domain") &&
              localStorage.getItem("country") &&
              localStorage.getItem("features") &&
              localStorage.getItem("channel")
            ) {
              this.props.setAppLoaded();
            } else {
              this.setUser();
            }
          })
          .catch((error) => {
            localStorage.removeItem("user");
            logout();
            return Promise.reject(error);
          });
        return Promise.reject(error);
      });

    //cache
  }

  setUser = () => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/auth/user-info/" + 3)
      .then((res) => {
        let features = res.data.features;
        let access = Object.keys(features).map((key) => features[key].name);
        localStorage.setItem("features", access);
        let token = JSON.parse(
          atob(res.headers["x-access-token"].split(".")[1])
        );
        let user = {
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.email,
          extId: token.company.extId,
          id: token.user.id,
        };
        localStorage.setItem("user", JSON.stringify(user));
        this.props.setAppLoaded();
        history.push("/");
      })
      .catch((error) => {
        logout();
        return Promise.reject(error);
      });
  };

  render() {
    return <>{this.props.appLoaded && <App />}</>;
  }
}

const mapStateToProps = (state) => {
  return {
    appLoaded: state.general.appLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAppLoaded: () => dispatch(setAppLoaded()),
    setSelectedMenuItem: (id, name, domain, country, features, channelId) =>
      dispatch(
        setSelectedMenuItem(id, name, domain, country, features, channelId)
      ),
    buildMarketMenuOptions: () => dispatch(buildMarketMenuOptions()),
    openModal: (targetUnitData, modalType, modalSize) =>
      dispatch(openModal(targetUnitData, modalType, modalSize)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthCheck);
