import React, { Component } from "react";
import { connect } from "react-redux";
import { Transition } from "react-transition-group";
import { Router, Route, Link, NavLink, Switch } from "react-router-dom";
import { history } from "helpers/history.jsx";

import { SmallSearchField } from "grand_components";
import { SidePanel } from "components/SidePanel";
import { logout } from "services/logout.jsx";
import {
  filterNavItems,
  handleSearchValue,
  setSelectedNavItem,
  setSelectedMenuItem,
  setSelectedChannel,
  buildMarketMenuOptions,
} from "ducks/sidepanel";

import { closeFormpanel } from "ducks/formpanel";
import { closeModal, openModal } from "ducks/modal";
import { getMarkets } from "ducks/markets";
import { closeErrorMsg } from "ducks/general";
import { setRestrictions } from "ducks/sidepanel";

import HandleError from "components/HandleError/HandleError";
import RestrictedRoute from "components/RestrictedRoute/RestrictedRoute";
import Products from "pages/Products";
import Notifications from "pages/Notifications";
import PriceCampaigns from "pages/PriceCampaigns";
import Settings from "pages/Settings";
import NotFound from "pages/NotFound";
import ProductPerformanceAnalytics from "pages/ProductPerformanceAnalytics";
import CompetitorAnalytics from "pages/CompetitorAnalytics";
import FormContainer from "containers/Forms/FormContainer/FormContainer.jsx";
import ModalContainer from "containers/Modals/ModalContainer/ModalContainer.jsx";
import { messaging } from "./firebase";
import {
  registerTokenToServer,
  getAllNotifications,
} from "ducks/notifications";

import store from "ducks";
import PricePosition from "pages/PricePosition";
import BusinessIntelligence from "pages/BusinessIntelligence";
import PriceHistory from "pages/PriceHistory";
import CampaignAnalytics from "pages/CampaignAnalytics";
import ClearCache from "./ClearCache";

class App extends Component {
  componentDidMount() {
    window.addEventListener("focus", this.onFocus);

    let isSafari =
      navigator.userAgent.indexOf("Safari") > -1 &&
      !navigator.userAgent.includes("Chrome");

    if (isSafari) {
      setTimeout(() => {
        this.props.getAllNotifications();
      }, 300000);
    }
    if (messaging) {
      messaging
        .requestPermission()
        .then(async function () {
          const token = await messaging.getToken();

          store.dispatch(registerTokenToServer(token));
        })
        .catch(function (err) {
          console.log("Unable to get permission to notify.", err);
        });

      // navigator.serviceWorker.addEventListener("message", message => {
      //   // store.dispatch(setNotification(message.data));
      // });

      // Callback fired if Instance ID token is updated.
      messaging.onTokenRefresh(() => {
        messaging
          .getToken()
          .then((refreshedToken) => {
            store.dispatch(registerTokenToServer(refreshedToken));
            // localStorage.setItem("fcm", refreshedToken);
          })
          .catch((err) => {
            // console.log("Unable to retrieve refreshed token ", err);
          });
      });
    }

    this.props.setRestrictions();
    this.props.buildMarketMenuOptions();
    // if (localStorage.getItem("fcm")) {
    //   this.props.registerTokenToServer(localStorage.getItem("fcm"));
    // }
    if (
      !localStorage.getItem("name") ||
      !localStorage.getItem("market") ||
      !localStorage.getItem("domain") ||
      !localStorage.getItem("country") ||
      !localStorage.getItem("channel")
    ) {
      this.props.getMarkets();
    }
  }

  componentWilUnmount() {
    window.removeEventListener("focus", this.onFocus);
  }

  onFocus = () => {
    // REFETCH NOTIFICATIONS IF TAB IS IN FOCUS
    this.props.getAllNotifications();
  };

  render() {
    let navItems = filterNavItems(this.props.navItems, this.props.searchValue);

    return (
      <ClearCache>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }
          return (
            <Router history={history}>
              <div className="h-screen w-screen flex border-box font-sans flex-no-grow">
                <Transition
                  in={this.props.isSidePanelOpen}
                  key={1}
                  timeout={100}
                >
                  {(status) => (
                    <SidePanel
                      smallSearchField={
                        <SmallSearchField
                          handleOnChange={(event) =>
                            this.props.handleOnChange(event)
                          }
                        />
                      }
                      link={Link}
                      navLink={NavLink}
                      navItems={navItems}
                      menuOptions={this.props.menuOptions}
                      channelOptions={this.props.channelOptions}
                      handleLogout={logout}
                      selectedNavItem={this.props.selectedNavItem}
                      setSelectedNavItem={this.props.setSelectedNavItem}
                      selectedMenuItem={this.props.selectedMenuItem}
                      setSelectedMenuItem={this.props.setSelectedMenuItem}
                      selectedChannel={this.props.selectedChannel}
                      setSelectedChannel={this.props.setSelectedChannel}
                      classList={`sidebar max-w-14 min-w-14 bg-grey-darkest flex flex-col sidepanel-${status}`}
                    />
                  )}
                </Transition>
                <Transition
                  in={this.props.toggleMessage}
                  key={2}
                  timeout={{ enter: 10, exit: 100 }}
                  unmountOnExit
                >
                  {(status) => (
                    <HandleError
                      error={this.props.error}
                      closeErrorMsg={this.props.closeErrorMsg}
                      classList={`error-msg-${status}`}
                    />
                  )}
                </Transition>
                <Transition
                  in={this.props.isFormVisible}
                  key={3}
                  unmountOnExit
                  timeout={{ enter: 10, exit: 100 }}
                >
                  {(status) => <FormContainer classList={`form-${status}`} />}
                </Transition>
                {this.props.isFormVisible && (
                  <div
                    className="absolute w-full h-full bg-blue-darkest opacity-50 z-40"
                    onClick={() => this.props.closeFormpanel()}
                  />
                )}
                {this.props.isModalVisible && <ModalContainer />}
                <div id="view" className="flex-1 bg-grey-lighter">
                  <Switch>
                    <Route exact={true} path="/" component={Products} />

                    <RestrictedRoute
                      authorized={this.props.groupsAndProducts}
                      exact={true}
                      path="/groups-and-products"
                      component={Products}
                    />

                    <RestrictedRoute
                      authorized={this.props.campaignsAndRules}
                      exact={true}
                      path="/price-campaigns-and-rules"
                      component={PriceCampaigns}
                    />

                    <RestrictedRoute
                      authorized={this.props.productPerformanceAnalytics}
                      exact={true}
                      path="/product-performance-analytics"
                      component={ProductPerformanceAnalytics}
                    />

                    <RestrictedRoute
                      authorized={this.props.competitorAnalytics}
                      exact={true}
                      path="/competitor-analytics"
                      component={CompetitorAnalytics}
                    />
                    <RestrictedRoute
                      authorized={this.props.businessIntelligence}
                      exact={true}
                      path="/business-intelligence"
                      component={BusinessIntelligence}
                    />
                    <Route
                      exact={true}
                      path="/notifications"
                      component={Notifications}
                    />
                    <RestrictedRoute
                      authorized={this.props.pricePosition}
                      exact={true}
                      path="/price-position"
                      component={PricePosition}
                    />
                    <Route
                      exact={true}
                      path="/price-history"
                      component={PriceHistory}
                    />
                    <Route
                      exact={true}
                      path="/campaign-analytics"
                      component={CampaignAnalytics}
                    />
                    <Route exact={true} path="/settings" component={Settings} />
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </div>
            </Router>
          );
        }}
      </ClearCache>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSidePanelOpen: state.sidepanel.isSidePanelOpen,
    navItems: state.sidepanel.navItems,
    menuOptions: state.sidepanel.menuOptions,
    searchValue: state.sidepanel.searchValue,
    isFormVisible: state.formpanel.isFormVisible,
    formType: state.formpanel.formType,
    selectedNavItem: state.sidepanel.selectedNavItem,
    selectedMenuItem: state.sidepanel.selectedMenuItem,
    selectedChannel: state.sidepanel.selectedChannel,
    isModalVisible: state.modal.isModalVisible,
    error: state.general.errorMsg,
    toggleMessage: state.general.toggleMessage,
    campaignsAndRules: state.sidepanel.campaignsAndRules,
    groupsAndProducts: state.sidepanel.groupsAndProducts,
    competitorAnalytics: state.sidepanel.competitorAnalytics,
    businessIntelligence: state.sidepanel.businessIntelligence,
    pricePosition: state.sidepanel.pricePosition,
    productPerformanceAnalytics: state.sidepanel.productPerformanceAnalytics,
    manageUsers: state.sidepanel.manageUsers,
    markets: state.markets.markets,
    channelOptions: state.sidepanel.channelOptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleOnChange: (value) => dispatch(handleSearchValue(value)),
    closeFormpanel: () => dispatch(closeFormpanel()),
    openModal: (targetUnitData, modalType, modalSize) =>
      dispatch(openModal(targetUnitData, modalType, modalSize)),
    closeModal: () => dispatch(closeModal()),
    setSelectedNavItem: (id) => dispatch(setSelectedNavItem(id)),
    setSelectedMenuItem: (id, name, domain, country, feature, channelId) =>
      dispatch(
        setSelectedMenuItem(id, name, domain, country, feature, channelId)
      ),
    setSelectedChannel: (
      id,
      name,
      domain,
      country,
      features,
      channels,
      channelId
    ) =>
      dispatch(
        setSelectedChannel(
          id,
          name,
          domain,
          country,
          features,
          channels,
          channelId
        )
      ),
    buildMarketMenuOptions: () => dispatch(buildMarketMenuOptions()),
    getMarkets: () => dispatch(getMarkets()),
    closeErrorMsg: () => dispatch(closeErrorMsg()),
    setRestrictions: () => dispatch(setRestrictions()),
    registerTokenToServer: (token) => dispatch(registerTokenToServer(token)),
    getAllNotifications: () => dispatch(getAllNotifications()),
    getAllNotifications: () => dispatch(getAllNotifications()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
