import axios from "axios";
import { getAllCampaigns, getAllCampaignsDropdown } from "./campaigns";
import { getAllPriceRules, getAllCompetitors } from "./priceRule";
import { getProductData } from "./products";
import { getAllGroupsNoPagination } from "./groups";
import { getPricePositionData } from "./pricePosition";
import { handleError } from "./general";
import store from "./index";

const TOGGLE_SIDEPANEL = "TOGGLE_SIDEPANEL";
const SEARCH_VALUE = "SEARCH_VALUE";
const SET_SELECTED_NAV_ITEM = "SET_SELECTED_NAV_ITEM";
const SET_MENU_ITEMS = "SET_MENU_ITEMS";
const SET_SELECTED_MENU_ITEM = "SET_SELECTED_MENU_ITEM";
const SET_RESTRICTIONS = "SET_RESTRICTIONS";
const SET_ICON = "SET_ICON";
const SET_CHANNELS = "SET_CHANNELS";
const SET_SELECTED_CHANNEL = "SET_SELECTED_CHANNEL";

export const buildMarketMenuOptions = () => {
  return (dispatch) => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/price-optimisation/markets")
      .then((res) => {
        let markets = {
          id: 1,
          name: "Change Market",
          isView: false,
          open: true,
          menuItems: res.data,
        };
        dispatch(setMenuItems(markets));
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getChannels = (id, channelId) => {
  return (dispatch) => {
    axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/price-optimisation/markets/" +
          localStorage.getItem("market") +
          "/channels"
      )
      .then((res) => {
        let channels = res.data.map((data) => data.name);
        let defaultChannelId = res.data.find(
          (data) => data.id.toString() === localStorage.getItem("market")
        );
        dispatch(setChannels(res.data));
        dispatch(
          setSelectedChannel(
            localStorage.getItem("market"),
            localStorage.getItem("name"),
            localStorage.getItem("domain"),
            localStorage.getItem("country"),
            localStorage.getItem("features"),
            channels,
            channelId === undefined ? defaultChannelId.id : channelId
          )
        );
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

const setChannels = (channels) => {
  return {
    type: SET_CHANNELS,
    channels: channels,
  };
};

const setMenuItems = (items) => {
  return {
    type: SET_MENU_ITEMS,
    menuOptions: items,
  };
};

export const toggleSidepanel = () => {
  return {
    type: TOGGLE_SIDEPANEL,
  };
};

export const handleSearchValue = (value) => {
  return {
    type: SEARCH_VALUE,
    searchValue: value,
  };
};

export const setSelectedNavItem = (id) => {
  return {
    type: SET_SELECTED_NAV_ITEM,
    selectedNavItem: id,
  };
};

export const setSelectedMenuItem = (
  id,
  name,
  domain,
  country,
  features,
  channelId
) => {
  return (dispatch) => {
    localStorage.setItem("market", id);
    localStorage.setItem("name", name);
    localStorage.setItem("domain", domain);
    localStorage.setItem("country", country);
    localStorage.setItem("features", features);
    // localStorage.setItem("channel", channelId);
    dispatch(selectedMenuItem(id, name, domain, country, features));
    // dispatch(getAllCampaigns(1, 20, null, null));
    // dispatch(getAllPriceRules(1, 20, null, null));
    // dispatch(getProductData(1, 20, null, null));
    // dispatch(getPricePositionData(1, 20, null, null));
    dispatch(getChannels(id, channelId));
    // dispatch(getAllCompetitors());
    // dispatch(getAllCampaignsDropdown());
  };
};
export const setSelectedChannel = (
  id,
  name,
  domain,
  country,
  features,
  channels,
  channelId
) => {
  return (dispatch) => {
    localStorage.setItem("market", id);
    localStorage.setItem("name", name);
    localStorage.setItem("domain", domain);
    localStorage.setItem("country", country);
    localStorage.setItem("features", features);
    localStorage.setItem("channels", channels);
    localStorage.setItem("channel", channelId);

    dispatch(selectedChannel(channelId, domain));
    dispatch(getAllCampaigns(1, 20, null, null));
    dispatch(getAllPriceRules(1, 20, null, null));
    dispatch(getProductData(1, 20, null, null));
    dispatch(getPricePositionData(1, 20, null, null));
    dispatch(getAllCompetitors());
    dispatch(getAllGroupsNoPagination(1, 20, null, null));
    dispatch(getAllCampaignsDropdown());
  };
};

const selectedMenuItem = (id, name, domain, country, features) => {
  return {
    type: SET_SELECTED_MENU_ITEM,
    selectedMenuItem: id,
    selectedMenuName: name,
    selectedMenuDomain: domain,
    selectedMenuCountry: country,
    selectedMenuFeatures: features,
  };
};

const selectedChannel = (id, domain) => {
  return {
    type: SET_SELECTED_CHANNEL,
    selectedChannel: id,
    selectedChannelName: domain,
    selectedMenuDomain: domain,
  };
};

const filter = (list, str) => {
  let exist = false;
  list.forEach((item) => {
    if (item.link.indexOf(str.toLowerCase()) > -1) {
      exist = true;
    } else if (item.navItems !== undefined) {
      exist = filter(item.navItems, str.toLowerCase());
    }
  });
  return exist;
};

export const filterNavItems = (navItems, str) => {
  if (str && str.length > 1) {
    return navItems.filter((item) => {
      if (item.name.toLowerCase().includes(str.toLowerCase())) {
        return true;
      } else if (item.navItems !== undefined) {
        const exist = filter(item.navItems, str.toLowerCase());
        return exist;
      }
      return false;
    });
  } else {
    return navItems;
  }
};

export const setRestrictions = () => {
  return {
    type: SET_RESTRICTIONS,
    campaignsAndRules:
      localStorage.getItem("features") &&
      localStorage.getItem("features").includes("CampaignsAndRules")
        ? false
        : true,
    groupsAndProducts:
      localStorage.getItem("features") &&
      localStorage.getItem("features").includes("GroupsAndProducts")
        ? false
        : true,
    competitorAnalytics:
      localStorage.getItem("features") &&
      localStorage.getItem("features").includes("CompetitorAnalytics")
        ? false
        : true,
    productPerformanceAnalytics:
      localStorage.getItem("features") &&
      localStorage.getItem("features").includes("ProductPerformanceAnalytics")
        ? false
        : true,
    manageUsers:
      localStorage.getItem("features") &&
      localStorage.getItem("features").includes("ManageUsers")
        ? false
        : true,
    pricePosition:
      localStorage.getItem("features") &&
      localStorage.getItem("features").includes("PricePosition")
        ? false
        : true,
    businessIntelligence:
      localStorage.getItem("features") &&
      localStorage.getItem("features").includes("BusinessIntelligence")
        ? false
        : true,
  };
};

const initialState = {
  isSidePanelOpen: true,
  searchValue: "",
  selectedNavItem: null,
  selectedMenuItem: null,
  selectedMenuName: null,
  selectedMenuDomain: null,
  selectedMenuCountry: null,
  selectedMenuFeatures: null,
  selectedChannel: null,
  selectedChannelName: null,
  menuOptions: [],
  navItems: [
    {
      id: 1,
      name: "Price Campaigns & Rules",
      isView: true,
      open: false,
      link: "/price-campaigns-and-rules",
      icon: "view_compact",
      restricted: true,
    },
    {
      id: 2,
      name: "Groups & Products",
      isView: true,
      open: false,
      link: "/groups-and-products",
      icon: "view_compact",
      restricted: true,
    },
    {
      id: 3,
      name: "Analytics",
      isView: false,
      open: false,
      link: "/analytics",

      navItems: [
        {
          id: 4,
          name: "Product Performance Analytics",
          isView: true,
          open: false,
          link: "/product-performance-analytics",
          icon: "view_compact",
          restricted: true,
        },
        {
          id: 5,
          name: "Competitor Analytics",
          isView: true,
          open: false,
          link: "/competitor-analytics",
          icon: "view_compact",
          restricted: true,
        },
        {
          id: 6,
          name: "Price Position",
          isView: true,
          open: false,
          icon: "view_compact",
          link: "/price-position",
          restricted: true,
        },
        {
          id: 7,
          name: "Price History Analytics",
          isView: true,
          open: false,
          icon: "view_compact",
          link: "/price-history",
          restricted: false,
        },
        {
          id: 8,
          name: "Business Intelligence",
          isView: true,
          open: false,
          link: "/business-intelligence",
          icon: "multiline_chart",
          restricted: true,
        },
        {
          id: 9,
          name: "Campaign Analytics",
          isView: true,
          open: false,
          link: "/campaign-analytics",
          icon: "multiline_chart",
        },
      ],
    },
  ],
  campaignsAndRules: null,
  groupsAndProducts: true,
  competitorAnalytics: true,
  productPerformanceAnalytics: true,
  pricePosition: true,
  businessIntelligence: true,
  manageUsers: true,
  isTableLarge: false,
  channelOptions: [],
  channelIds: [],
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_SIDEPANEL:
      return Object.assign({}, state, {
        isSidePanelOpen: !state.isSidePanelOpen,
        isTableLarge: !state.isTableLarge,
      });
    case SEARCH_VALUE:
      return Object.assign({}, state, {
        searchValue: action.searchValue,
      });
    case SET_SELECTED_NAV_ITEM:
      return Object.assign({}, state, {
        selectedNavItem: action.selectedNavItem,
      });
    case SET_SELECTED_MENU_ITEM:
      return Object.assign({}, state, {
        selectedMenuItem: action.selectedMenuItem,
        selectedMenuName: action.selectedMenuName,
        selectedMenuDomain: action.selectedMenuDomain,
        selectedMenuCountry: action.selectedMenuCountry,
        selectedMenuFeatures: action.selectedMenuFeatures,
      });
    case SET_SELECTED_CHANNEL:
      return Object.assign({}, state, {
        selectedChannel: action.selectedChannel,
        selectedChannelName: action.selectedChannelName,
      });
    case SET_MENU_ITEMS:
      return Object.assign({}, state, {
        menuOptions: [...state.menuOptions, action.menuOptions],
      });
    case SET_RESTRICTIONS:
      const campaignsAndRules = state.navItems[0];
      campaignsAndRules.restricted = action.campaignsAndRules;
      const groupsAndProducts = state.navItems[1];
      groupsAndProducts.restricted = action.groupsAndProducts;
      const productPerformanceAnalytics = state.navItems[2].navItems[0];
      productPerformanceAnalytics.restricted =
        action.productPerformanceAnalytics;
      const competitorAnalytics = state.navItems[2].navItems[1];
      competitorAnalytics.restricted = action.competitorAnalytics;
      const pricePosition = state.navItems[2].navItems[2];
      pricePosition.restricted = action.pricePosition;
      const businessIntelligence = state.navItems[2].navItems[4];
      businessIntelligence.restricted = action.businessIntelligence;

      const campaignsAndRulesIcon = state.navItems[0];
      campaignsAndRulesIcon.icon = action.campaignsAndRules
        ? "lock"
        : "view_compact";
      const groupsAndProductsIcon = state.navItems[1];
      groupsAndProductsIcon.icon = action.groupsAndProducts
        ? "lock"
        : "view_compact";
      const productPerformanceAnalyticsIcon = state.navItems[2].navItems[0];
      productPerformanceAnalyticsIcon.icon = action.productPerformanceAnalytics
        ? "lock"
        : "view_compact";
      const competitorAnalyticsIcon = state.navItems[2].navItems[1];
      competitorAnalyticsIcon.icon = action.competitorAnalytics
        ? "lock"
        : "view_compact";

      const pricePositionIcon = state.navItems[2].navItems[2];
      pricePositionIcon.icon = action.pricePosition ? "lock" : "view_compact";
      const businessIntelligenceIcon = state.navItems[2].navItems[4];
      businessIntelligenceIcon.icon = action.businessIntelligence
        ? "lock"
        : "view_compact";
      // const businessIntelligenceIcon = state.navItems[2].navItems[2];
      // businessIntelligenceIcon.icon = action.businessIntelligence
      //   ? "lock"
      //   : "view_compact";
      return {
        ...state,
        campaignsAndRules: action.campaignsAndRules,
        groupsAndProducts: action.groupsAndProducts,
        competitorAnalytics: action.competitorAnalytics,
        productPerformanceAnalytics: action.productPerformanceAnalytics,
        pricePosition: action.pricePosition,
        businessIntelligence: action.businessIntelligence,
        // businessIntelligence: action.businessIntelligence,
        manageUsers: true,
        // manageUsers: action.manageUsers
      };
    case SET_CHANNELS:
      return {
        ...state,
        channelOptions: action.channels,
      };
    default:
      return state;
  }
}
