import { combineReducers, createStore, applyMiddleware, compose } from "redux";

import thunk from "redux-thunk";

import { authMiddleware } from "./authentication";

import sidepanel from "./sidepanel";
import general from "./general";
import formpanel from "./formpanel";

import groups from "./groups";
import modal from "./modal";
import tags from "./tags";
import campaigns from "./campaigns";
import products from "./products";
import priceRule from "./priceRule";
import tablePagination from "./tablePagination";
import markets from "./markets";
import productPerformanceAnalytics from "./productPerformanceAnalytics";
import competitorAnalytics from "./competitorAnalytics";
// import charts from "./charts";
import exportData from "./exportData";
import notifications from "./notifications";
import settings from "./settings";
import productMatching from "./productMatching";
import pricePosition from "./pricePosition";
import priceHistory from "./priceHistory";
import businessIntelligence from "./businessIntelligence";
import campaignAnalytics from "./campaignAnalytics";

const reducers = combineReducers({
  sidepanel,
  general,
  formpanel,
  groups,
  modal,
  tags,
  campaigns,
  products,
  priceRule,
  tablePagination,
  markets,
  productPerformanceAnalytics,
  competitorAnalytics,
  // charts,
  exportData,
  notifications,
  settings,
  productMatching,
  pricePosition,
  priceHistory,
  businessIntelligence,
  campaignAnalytics,
});
const store = createStore(
  reducers,
  compose(
    applyMiddleware(authMiddleware, thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

export default store;
