import React from "react";
import { InputLoadingSpinner } from "./InputLoadingSpinner/InputLoadingSpinner";

export const SearchBar = (props) => {
  return (
    <div className={`${props.large ? "h-10" : "h-8"} w-full flex flex-row `}>
      <input
        autoComplete="off"
        placeholder={props.placeholder}
        className={`outline-none ${
          props.large ? "xxl:text-md text-sm" : "xxl:text-sm text-xs"
        } truncate input-placeholder h-full border-grey-light border-1 rounded border-r-0 flex-1 px-2 rounded-r-none focus:text-teal text-teal-dark font-light font-sans`}
        type="text"
        onChange={(event) => {
          props.onChange && props.onChange(event);
        }}
        onKeyDown={(event) => {
          props.onKeyDown && props.onKeyDown(event);
        }}
      />
      <div className="w-10 h-full flex items-center justify-center text-white bg-teal-dark hover:bg-teal rounded-l-none rounded">
        {props.loading ? (
          InputLoadingSpinner
        ) : (
          <div
            className="h-full w-full flex items-center justify-center cursor-pointer"
            onClick={() => props.handleClickSearch()}
          >
            <i className="material-icons-outlined text-xl">{props.icon}</i>
          </div>
        )}
      </div>
    </div>
  );
};
