import React, { Component } from "react";

class KpiBox extends Component {
  componentDidMount() {
    this.props.getData(this.props.config);
  }

  triggerGetDateOnChange = () => {
    this.props.getData(this.props.config);
  };

  render() {
    return (
      <div className="flex flex-col h-full w-full bg-white justify-center items-center">
        <div className="flex flex-row text-md font-thin pb-2 justify-center ">
          {/* {Object.keys(this.props.data).length > 0 &&
            this.props.data.label !== null &&
            this.props.data.label} */}
          {this.props.displayName}
        </div>
        <div className="bg-teal-light w-20 h-px my-4 items-center"></div>

        <h1 className="text-7xl header-font pb-2">
          <div
            className={`${
              Object.keys(this.props.data).length > 0 &&
              typeof this.props.data.value !== "string"
                ? this.props.data.value.toFixed(2) > 9999999.99
                  ? "text-xl"
                  : this.props.data.value.toFixed(2) > 9999.99
                  ? "text-3xl"
                  : this.props.data.value.toFixed(2) > 999.99
                  ? "text-4xl"
                  : "text-7xl"
                : " "
            } inline-block pb-1 text-teal-light`}
          >
            {Object.keys(this.props.data).length > 0 &&
              typeof this.props.data.value !== "string" &&
              this.props.data.value.toFixed(2)}
          </div>
        </h1>

        <div
          className={`${
            Object.keys(this.props.data).length > 0 &&
            this.props.data.difference !== null
              ? this.props.data.difference.includes("-") === 0
                ? "text-red"
                : this.props.data.difference.includes("+") > 0
                ? "text-teal-light"
                : "text-indigo-light"
              : ""
          } flex flex-row`}
        >
          {Object.keys(this.props.data).length > 0 &&
            this.props.data.difference !== null &&
            this.props.data.difference}
        </div>
      </div>
    );
  }
}

export default KpiBox;
