import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./ducks";
import axios from "axios";
import AuthCheck from "./AuthCheck";
import { messaging } from "./firebase";
import { setNotificationFromPush } from "ducks/notifications";
import withClearCache from "./ClearCache";

axios.interceptors.request.use(
  (request) => {
    let user = JSON.parse(localStorage.getItem("user"));
    request.withCredentials = true;
    if (!request.url.includes("user-info") && !request.url.includes("token")) {
      request.headers.common = Object.assign({}, request.headers.common, {
        "X-TenantID": user.extId,
      });
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("../firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}

if (messaging) {
  messaging.onMessage((payload) => {
    // const title = payload.notification.title;
    // const options = {
    //   body: payload.notification.body,
    //   icon: payload.notification.icon
    // };
    store.dispatch(setNotificationFromPush(payload));
    // navigator.serviceWorker.ready.then(function(serviceWorker) {
    // serviceWorker.showNotification(title, options);
    // });
  });
}

ReactDOM.render(
  <Provider store={store}>
    <AuthCheck />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
