import React, { Component } from "react";
import { connect } from "react-redux";

import MultiSelectDropdown from "components/MultiSelectDropdown";
import SingleSelectDropdown from "components/SingleSelectDropdown";
import { InputText } from "components/InputText";
import InputNumber from "components/InputNumber";
import { TooltipRight } from "components/Tooltips";
import { SmallLoadingSpinner } from "components/SmallLoadingSpinner/SmallLoadingSpinner";
import {
  filterCampaignAnalyticsData,
  runCampaign,
  deleteCampaignAnalyticsFromView,
} from "ducks/campaignAnalytics";
import { getBrandsByMarket, getCategoriesByMarket } from "ducks/products";
import { getAllTags } from "ducks/tags";
import { getAllCampaignsDropdown } from "ducks/campaigns";
import { getAllCompetitors } from "ducks/priceRule";
import DatePicker from "components/AdvancedDatePicker/AdvancedDatePicker";
import InlineFilter from "./InlineFilter";
import { XLARGE } from "utils/modal-types";

class CampaignAnalyticsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSave: false,
      discount: "",
      filterValues: [],

      categories: {},
      brands: {},
      tags: [],
      campaigns: {},
      reRunDiscount: false,
      hoverTooltip: false,
      filterKeyOptions: [
        {
          id: 0,
          name: "category",
          type: "singleDropdown",
        },
        {
          id: 1,
          name: "brand",
          type: "singleDropdown",
        },
        {
          id: 2,
          name: "price",
          type: "interval",
        },
        // {
        //   id: 3,
        //   name: "Date",
        //   color: "indigo-light",
        // type: "date"
        // },
        // {
        //   id: 4,
        //   name: "buildQuery",
        //   type: "query",
        // },
        {
          id: 5,
          name: "tag",
          type: "multiDropdown",
        },
        {
          id: 6,
          name: "RRP",
          type: "interval",
        },
        {
          id: 7,
          name: "purchasePrice",
          type: "interval",
        },
        {
          id: 8,
          name: "Name",
          type: "text",
        },
        {
          id: 9,
          name: "articleNumber",
          type: "text",
        },
        // {
        //   id: 9,
        //   name: "lowestCompetitor",
        //   type: "text",
        // },
        // {
        //   id: 10,
        //   name: "lowestCompetitorPrice",
        //   type: "interval",
        // },
      ],
      priceDiffOperatorOptions: [
        { id: 1, name: "<" },
        { id: 2, name: "<=" },
        { id: 3, name: "=" },
        { id: 4, name: ">=" },
        { id: 5, name: ">" },
      ],
      showInfoBox: false,
    };
  }

  componentDidMount() {
    this.props.getBrandsByMarket();
    this.props.getCategoriesByMarket();
    if (this.props.viewState === "update") {
      this.setState({ discount: this.props.discount });
    }
  }

  handleSelected = (name, selected) => {
    if (name === "priceDiffOperator") {
      this.setState({ priceDiffOperatorError: false });
    }
    if (name === "salesPriceOperator") {
      this.setState({ salesPriceOperatorError: false });
    }
    if (
      Array.isArray(selected) &&
      selected.find((selected) => selected.name == "Select All") !== undefined
    ) {
      if (selected.map((select) => select.name.includes("Select All"))) {
        if (
          name == "category" &&
          this.state.category.length != this.props.categories.length
        ) {
          this.setState({
            [name]: this.props.categories,
          });
        } else if (
          name == "category" &&
          this.state.category.length == this.props.categories.length
        ) {
          this.setState({
            [name]: [],
          });
        } else if (
          name == "brand" &&
          this.state.brand.length != this.props.brands.length
        ) {
          this.setState({
            [name]: this.props.brands,
          });
        } else if (
          name == "brand" &&
          this.state.brand.length == this.props.brands.length
        ) {
          this.setState({
            [name]: [],
          });
        }
      }
    } else {
      this.setState({ [name]: selected, canSave: true });
    }
  };

  buildQueryString = (items, name) => {
    let urlQuery = name;
    if (name === "&tagIds=") {
      items.forEach((item, i) => {
        if (i + 1 < items.length) {
          urlQuery = urlQuery.concat(item.id + ",");
        } else {
          urlQuery = urlQuery.concat(item.id);
        }
      });

      return urlQuery;
    } else {
      items.forEach((item, i) => {
        if (i + 1 < items.length) {
          urlQuery = urlQuery.concat(item.name + ",");
        } else {
          urlQuery = urlQuery.concat(item.name);
        }
      });
      return urlQuery;
    }
  };

  tooltipOnMouseEnter = () => {
    this.setState({
      hoverTooltip: true,
    });
  };
  tooltipOnMouseLeave = () => {
    this.setState({
      hoverTooltip: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      canSave: true,
    });
    if (event.target.name === "priceDiff") {
      this.setState({ priceDiffError: false });
    }
  };
  runCampaign = (discount) => {
    this.props.runCampaign(discount);
    this.setState({
      reRunDiscount: false,
    });
  };

  removeItemFiltererArray = (val) => {
    let arr = [...this.state.filterValues];
    let newArr = arr.filter((item) => item !== val);
    this.setState({
      filterValues: newArr,
    });
    if (this.state.discount.length > 0) {
      this.setState({
        reRunDiscount: true,
      });
    }
  };

  createFilterArray = (obj) => {
    this.setState({
      filterValues: this.state.filterValues.concat(obj),
    });
    if (this.state.discount.length > 0) {
      this.setState({
        reRunDiscount: true,
      });
    }
  };

  clearSelection = () => {
    this.setState({
      filterValues: [],
      discount: "",
      reRunDiscount: false,
    });
  };

  handleFilterCampaignAnalytics = () => {
    let priceDiffError = false;
    let salesPriceError = false;
    // if (
    //   Object.keys(this.state.priceDiffOperator).length < 1 &&
    //   this.state.priceDiff.length > 0
    // ) {
    //   priceDiffError = true;
    //   this.setState({ priceDiffOperatorError: true });
    // }
    // if (
    //   Object.keys(this.state.priceDiffOperator).length > 0 &&
    //   this.state.priceDiff.length < 1
    // ) {
    //   priceDiffError = true;
    //   this.setState({ priceDiffError: true });
    // }

    if (priceDiffError) {
      return;
    } else {
      let queryString = "";
      // let categoryIds = "&categoryIds=".concat(
      //   this.state.filterValues.map((val) => val.Category)
      // );

      let categoryList = this.state.filterValues.filter((val) => val.Category);
      let categoryIdList = [];
      this.props.categories.filter((category) => {
        if (
          categoryList.length > 0 &&
          categoryList[0].Category.includes(category.name)
        ) {
          return categoryIdList.push(category.id);
        }
      });
      let categoryIds = "&categoryIds=".concat(categoryIdList);

      let brandList = this.state.filterValues.filter((val) => val.Brand);
      let brandIdList = [];
      this.props.brands.filter((brand) => {
        if (brandList.length > 0 && brandList[0].Brand.includes(brand.name)) {
          return brandIdList.push(brand.id);
        }
      });
      let brandIds = "&brandIds=".concat(brandIdList);

      let idList = [];
      let tagList = this.state.filterValues.filter((val) => val.Tag);
      this.props.tags.filter((tag) => {
        if (tagList.length > 0 && tagList[0].Tag.includes(tag.name)) {
          return idList.push(tag.id);
        }
      });
      let tagIds = "&tagIds=".concat(idList);
      let articleNumber = "&articleNumber=".concat(
        this.state.filterValues.map((val) => val["Article Number"])
      );
      let lowestCompetitorName = "&lowestCompetitorName=".concat(
        this.state.filterValues.map((val) => val["Lowest Competitor"])
      );
      let name = "&name=".concat(
        this.state.filterValues.map((val) => val.Name)
      );

      if (
        this.state.filterValues.some((obj) => obj.hasOwnProperty("Category"))
      ) {
        queryString = queryString.concat(categoryIds.replace(/,/g, ""));
      }
      if (this.state.filterValues.some((obj) => obj.hasOwnProperty("Brand"))) {
        queryString = queryString.concat(brandIds.replace(/,/g, ""));
      }
      if (
        this.state.filterValues.some((obj) =>
          obj.hasOwnProperty("Article Number")
        )
      ) {
        queryString = queryString.concat(articleNumber);
      }
      if (this.state.filterValues.some((obj) => obj.hasOwnProperty("Name"))) {
        queryString = queryString.concat(name.replace(/,/g, ""));
      }
      if (this.state.filterValues.some((obj) => obj.hasOwnProperty("Tag"))) {
        queryString = queryString.concat(tagIds);
      }

      // if (
      //   this.state.filterValues.some((obj) => obj.hasOwnProperty("Price Diff"))
      // ) {
      //   let priceDiff = this.state.filterValues.filter(
      //     (val) => val["Price Diff"]
      //   );
      //   queryString = queryString.concat(
      //     "&priceDiffOperator=" +
      //       priceDiff[0]["Price Diff"][0] +
      //       "&priceDiff=" +
      //       priceDiff[0]["Price Diff"][1]
      //   );
      // }

      if (
        this.state.filterValues.some((obj) =>
          obj.hasOwnProperty("Purchase Price")
        )
      ) {
        let purchasePrice = this.state.filterValues.filter(
          (val) => val["Purchase Price"]
        );
        queryString = queryString.concat(
          "&purchasePriceOperator=" +
            purchasePrice[0]["Purchase Price"][0].replace(/,/g, "") +
            "&pricepurchasePrice=" +
            purchasePrice[0]["Purchase Price"][1].replace(/,/g, "")
        );
      }

      if (this.state.filterValues.some((obj) => obj.hasOwnProperty("Price"))) {
        let price = this.state.filterValues.filter((val) => val["Price"]);

        queryString = queryString.concat(
          "&priceOperator=" +
            price[0]["Price"][0].replace(/,/g, "") +
            "&price=" +
            price[0]["Price"][1].replace(/,/g, "")
        );
      }
      if (this.state.filterValues.some((obj) => obj.hasOwnProperty("RRP"))) {
        let price = this.state.filterValues.filter((val) => val["RRP"]);

        queryString = queryString.concat(
          "&recommendedRetailPriceOperator=" +
            price[0]["RRP"][0].replace(/,/g, "") +
            "&recommendedRetailPrice=" +
            price[0]["RRP"][1].replace(/,/g, "")
        );
      }

      // if (
      //   this.state.filterValues.some((obj) =>
      //     obj.hasOwnProperty("Lowest Competitor Price")
      //   )
      // ) {
      //   let lowestCompPrice = this.state.filterValues.filter(
      //     (val) => val["Lowest Competitor Price"]
      //   );
      //   queryString = queryString.concat(
      //     "&lowestCompetitorPriceOperator=" +
      //       lowestCompPrice[0]["Lowest Competitor Price"][0] +
      //       "&lowestCompetitorPrice=" +
      //       lowestCompPrice[0]["Lowest Competitor Price"][1]
      //   );
      // }
      // if (
      //   this.state.filterValues.some((obj) =>
      //     obj.hasOwnProperty("Number Of Competitors")
      //   )
      // ) {
      //   let lowestCompPrice = this.state.filterValues.filter(
      //     (val) => val["Number Of Competitors"]
      //   );
      //   queryString = queryString.concat(
      //     "&numberOfCompetitorsOperator=" +
      //       lowestCompPrice[0]["Number Of Competitors"][0] +
      //       "&numberOfCompetitors=" +
      //       lowestCompPrice[0]["Number Of Competitors"][1]
      //   );
      // }
      // if (
      //   this.state.filterValues.some((obj) =>
      //     obj.hasOwnProperty("Lowest Competitor")
      //   )
      // ) {
      //   queryString = queryString.concat(lowestCompetitorName);
      // }
      // let newQueryString = queryString.replace("/,/g", "");
      this.props.filterCampaignAnalyticsData(queryString.slice(1), 20);
    }
  };

  showInfoTooltip = (value) => {
    this.setState({
      showInfoBox: value,
    });
  };

  editInput = (item, index, selected) => {
    let filteredValues = [...this.state.filterValues];

    filteredValues.splice(index, 1, { [item]: selected });
    this.setState({
      filterValues: filteredValues,
    });
  };

  render() {
    let productList =
      this.props.selectedProducts && this.props.selectedProducts.length > 0
        ? this.props.selectedProducts
        : this.props.campaignAnalytics;

    let selectedProducts =
      this.props.selectedProducts && this.props.selectedProducts.length > 0
        ? productList && productList.filter((product) => product.rrp > 0)
        : productList &&
          productList.filter((product) => product.recommendedRetailPrice > 0);
    return (
      <>
        <div className="bg-white h-full w-full mb-4">
          <div className="flex flex-row w-full">
            <div className="flex flex-col w-full">
              <div className="flex flex-row justify-start">
                <InlineFilter
                  name="campaignAnalyticsFilter"
                  filterValues={this.state.filterValues}
                  createFilterArray={this.createFilterArray}
                  removeItem={this.removeItemFiltererArray}
                  filterKeyOptions={this.state.filterKeyOptions}
                  filterValueOptions={{
                    brand: this.props.brands,
                    category: this.props.categories,
                    competitor: this.props.competitors,
                    tag: this.props.tags,
                  }}
                  setDates={this.setDates}
                  handleSubmit={this.handleFilterCampaignAnalytics}
                  editInput={this.editInput}
                />
              </div>
              <div className="flex flex-row justify-start w-3/5">
                <div className="px-2 w-1/6">
                  <InputText
                    label="Discount"
                    name="discount"
                    width="w-full"
                    value={this.state.discount}
                    onChange={this.handleChange}
                    placeholder="Discount"
                  />
                </div>
                <div className="text-grey-dark text-lg self-end items-end">
                  {" "}
                  %{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full mt-4">
            <div className="flex-1 flex justify-start items-end">
              <div
                className="mx-2 rounded bg-teal-dark hover:bg-teal px-3 flex py-2 text-xs text-white font-sans cursor-pointer"
                onClick={() => this.runCampaign(this.state.discount)}
              >
                {this.state.reRunDiscount
                  ? "Re-Calculate Campaign"
                  : "Calculate Campaign"}
                <i className=" material-icons-outlined text-white flex items-center pl-2 text-sm">
                  directions_run
                </i>
              </div>
              <span className="relative">
                <i
                  onMouseOver={() => this.showInfoTooltip(true)}
                  onMouseLeave={() => this.showInfoTooltip(false)}
                  className="material-icons text-teal items-center self-center flex cursor-pointer "
                >
                  info
                </i>
                {this.state.showInfoBox && (
                  <div className="tooltip-table-triangle-left bg-grey-darker text-white text-xs absolute z-20 w-32 pin-top ml-10 -mt-16 p-2">
                    All inputs will be rounded to 2 decimals, please take this
                    in to consideration as it might effect your price / discount
                  </div>
                )}
              </span>
            </div>
            <div className="mr-2 flex justify-end items-end">
              {selectedProducts.length > 0 ? (
                <div className="text-xxs text-grey-darker pr-2 mr-2 font-light">
                  {" "}
                  Selected:
                  <span className="text-md font-normal text-teal pl-2">
                    {selectedProducts.length}
                  </span>
                </div>
              ) : null}
              {this.props.saveButton}
            </div>
            <div className="mx-2 flex justify-end items-end">
              <div
                className="rounded bg-teal-dark hover:bg-teal px-3 py-2 text-xs text-white font-sans cursor-pointer"
                onClick={() => this.clearSelection()}
              >
                Clear Filter{" "}
              </div>
            </div>
            {this.props.viewState === "update" && (
              <div className="ml-2 flex justify-end items-end">
                <div
                  className="rounded bg-red-dark hover:bg-red px-3 py-2 text-xs text-white font-sans cursor-pointer"
                  onClick={() => {
                    this.props.deleteCampaignAnalyticsFromView(
                      this.props.discountInfo.id
                    );
                    this.clearSelection();
                  }}
                >
                  Delete Campaign{" "}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    runCampaign: (discount) => dispatch(runCampaign(discount)),
    filterCampaignAnalyticsData: (page, rowSize) =>
      dispatch(filterCampaignAnalyticsData(page, rowSize)),
    getBrandsByMarket: () => dispatch(getBrandsByMarket()),
    getCategoriesByMarket: () => dispatch(getCategoriesByMarket()),
    getAllTags: () => dispatch(getAllTags()),
    // filterCampaignAnalytics: (page, rowSize, idList, attr, direction) =>
    //   dispatch(filterCampaignAnalytics(page, rowSize, idList, attr, direction)),
    // getExportCampaignAnalytics: (query) =>
    //   dispatch(getExportCampaignAnalytics(query)),
    // setFiltered: (val) => dispatch(setFiltered(val)),
    getAllCompetitors: () => dispatch(getAllCompetitors()),
    deleteCampaignAnalyticsFromView: (id) =>
      dispatch(deleteCampaignAnalyticsFromView(id)),
  };
};

const mapStateToProps = (state) => {
  return {
    categories: state.products.categories,
    brands: state.products.brands,
    tags: state.tags.tagList,
    competitors: state.priceRule.competitorList,
    campaignAnalyticsLoading: state.exportData.campaignAnalyticsLoading,
    campaignAnalytics: state.campaignAnalytics.campaignAnalytics,
    idList: state.campaignAnalytics.idList,
    discount: state.campaignAnalytics.discount,
    discountInfo: state.campaignAnalytics.discountInfo,

    viewState: state.campaignAnalytics.viewState,
    selectedProducts: state.campaignAnalytics.selectedProducts,
    productIdList: state.campaignAnalytics.productIdList,
    singleCampaignData: state.campaignAnalytics.singleCampaignData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignAnalyticsFilter);
