import React from "react";
import PropTypes from "prop-types";
import MenuItem from "./MenuItem";
import MenuItemChannel from "./MenuItemChannel";
import NavItem from "./NavItem";
import Logo from "./G_Logo_White";

export const SidePanel = ({ link: Link, ...props }) => {
  let navItemList = props.navItems.map((item, index) => {
    return (
      <NavItem
        key={index}
        item={item}
        first={true}
        navLink={props.navLink}
        selectedNavItem={props.selectedNavItem}
        setSelectedNavItem={props.setSelectedNavItem}
      />
    );
  });
  return (
    <div id="sidebar" className={props.classList}>
      <div className="flex-1 flex flex-col overflow-y-scroll">
        <header className="flex content-center bg-teal-dark border-solid border-b-4 border-t-0 border-l-0 border-r-0 border-teal-darkest">
          <Link to="/" className="flex-1 text-white no-underline">
            {props.children ? (
              <div className="w-full h-16">{props.children}</div>
            ) : (
              <div className="w-full h-16 flex flex-row justify-center">
                <div className="self-center">
                  <Logo />
                </div>
              </div>
            )}
          </Link>
        </header>
        <div className="w-full flex justify-center">
          {props.smallSearchField}
        </div>
        <ul className="list-reset font-sans text-white capitalize text-sm mb-6">
          {props.menuOptions.length > 0 ? (
            props.menuOptions.map((data) => {
              return (
                <MenuItem
                  key={data.id}
                  data={data}
                  selectedMenuItem={props.selectedMenuItem}
                  setSelectedMenuItem={props.setSelectedMenuItem}
                />
              );
            })
          ) : (
            <div className="bg-teal-dark cursor-pointer nav-link px-2 font-light text-lg font-sans text-white flex m-0 h-10" />
          )}
        </ul>
        {props.channelOptions.length > 1 && (
          <ul className="list-reset font-sans text-white capitalize text-sm mb-6">
            <MenuItemChannel
              data={props.channelOptions}
              selectedChannel={props.selectedChannel}
              setSelectedChannel={props.setSelectedChannel}
            />
          </ul>
        )}
        <nav className="flex-1 overflow-y-auto">
          <ul
            className={`${
              props.smallSearchField ? "" : "mt-8"
            } list-reset font-sans text-white capitalize text-sm mt-0`}
          >
            {navItemList}
          </ul>
        </nav>
      </div>
      {props.bottomLogo ? (
        <div className="text-center">
          {props.bottomLogoLink ? (
            <a href="http://granditude.com/">
              <img
                className="h-16"
                src={props.bottomLogo}
                alt="granditude_logo"
              />
            </a>
          ) : (
            <img
              className="h-16"
              src={props.bottomLogo}
              alt="granditude_logo"
            />
          )}
        </div>
      ) : null}
      <div className="text-white text-right mr-3 my-2">
        {props.showInfoButton ? (
          <Link to="/info" className="text-white">
            <i className="cursor-pointer mx-1 my-2 material-icons">
              info_outline
            </i>
          </Link>
        ) : null}
        {props.showSettingsButton ? (
          <Link to="/settings" className="text-white">
            <i className="cursor-pointer mx-1 my-2 material-icons">settings</i>
          </Link>
        ) : null}
        <i
          onClick={() => props.handleLogout()}
          className="cursor-pointer mx-1 my-2 material-icons"
        >
          power_settings_new
        </i>
      </div>
    </div>
  );
};

SidePanel.propTypes = {
  link: PropTypes.func,
  navLink: PropTypes.func,
  classList: PropTypes.string,
  logo: PropTypes.object,
  companyName: PropTypes.string,
  navItems: PropTypes.array,
  handleLogout: PropTypes.func,
  smallSearchField: PropTypes.object,
  showInfoButton: PropTypes.bool,
};
