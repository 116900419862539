import axios from "axios";

import { handleError } from "./general";
import { setGroupPagination } from "./tablePagination";
const GROUP_FETCH_GROUPS = "GROUP_FETCH_GROUPS";
const GROUP_CREATE_GROUP = "GROUP_CREATE_GROUP";
const GROUP_UPDATE_GROUP = "GROUP_UPDATE_GROUP";
const GROUP_TOGGLE_SHOW_ALL_DATA = "GROUP_TOGGLE_SHOW_ALL_DATA";
const GROUP_SET_CONFIG = "GROUP_SET_CONFIG";
const GROUP_SELECTED_GROUP_ID = "GROUP_SELECTED_GROUP_ID";
const GROUP_GROUPS_TABLE_LOADING = "GROUP_GROUPS_TABLE_LOADING";
const GROUP_GROUPS_SET_NO_DATA = "GROUP_GROUPS_SET_NO_DATA";
const GROUP_SET_GROUPS_NO_PAG = "GROUP_SET_GROUPS_NO_PAG";
const GROUP_PREVIEW_PRODUCTS = "GROUP_PREVIEW_PRODUCTS";
const GROUP_PRODUCTS_PREVIEW_LOADING = "GROUP_PRODUCTS_PREVIEW_LOADING";
const SET_PAGE_GROUP = "SET_PAGE_GROUP";
const SORT_GROUPS = "SORT_GROUPS";
const SET_ROW_SIZE_GROUP = "SET_ROW_SIZE_GROUP";

//FETCH
export const getAllGroupsNoPagination = (page, rowSize, attr, direction) => {
  return (dispatch) => {
    dispatch(setNoDataGroup(false));

    dispatch(tableLoading(true));
    return axios
      .get(process.env.REACT_APP_API_HOST + "/product/groups/")
      .then((res) => {
        dispatch(setNoDataGroup(false));
        dispatch(tableLoading(false));
        if (res.data.length > 0) {
          dispatch(setGroupsNoPag(res.data, rowSize));
        }
      })
      .catch((err) => {
        dispatch(tableLoading(false));
        dispatch(setNoDataGroup(true));
        console.log(err);
      });
  };
};

export const sortGroupData = (data, attr, direction, rowSize) => {
  let sortedData = [];
  if (direction === "desc") {
    sortedData = [...data];

    sortedData.sort((a, b) => (a[attr] < b[attr] ? 1 : -1));
  }
  if (direction === "asc") {
    sortedData = [...data];
    sortedData.sort((a, b) => (a[attr] > b[attr] ? 1 : -1));
  }
  if (direction === null) {
    sortedData = [...data];
  }
  const split = (input, output = []) => {
    if (!input.length) return output;
    output.push(input.slice(0, rowSize));
    return split(input.slice(rowSize), output);
  };
  let paginateData = split(sortedData);
  return {
    type: SORT_GROUPS,
    campaignAnalytics: data,
    campaignAnalyticsPages: paginateData,
    rowSize: rowSize,
  };
};

// export const getAllGroupsNoPagination = (page, rowSize, attr, direction, autoTags) => {
// return (dispatch) => {
//   dispatch(getAllGroupsNoPagination());
//   dispatch(tableLoading(true));
//   dispatch(setNoDataGroup(false));
//   let url =
//     attr && direction
//       ? process.env.REACT_APP_API_HOST +
//         "/product/groups/pagination?page=" +
//         page +
//         "&size=" +
//         rowSize +
//         "&sort=" +
//         attr +
//         "," +
//         direction
//       : process.env.REACT_APP_API_HOST +
//         "/product/groups/pagination?page=" +
//         page +
//         "&size=" +
//         rowSize;
//   return axios
//     .get(url)
//     .then((res) => {
//       let newTotalElements = res.data.totalElements - autoTags.length;
//       let newTotalPages = newTotalElements / rowSize;
//       dispatch(setAllGroups(res.data));
//       dispatch(
//         setGroupPagination(
//           page,
//           rowSize,
//           Math.ceil(newTotalPages),
//           attr,
//           direction
//         )
//       );
//       // dispatch(showAllData(true));
//       dispatch(tableLoading(false));
//       if (res.data.content.length < 1) {
//         dispatch(setNoDataGroup(true));
//       }
//     })
//     .catch((err) => {
//       dispatch(handleError(err));
//       dispatch(tableLoading(false));
//       dispatch(setNoDataGroup(true));
//     });
// };
// };

export const getConfig = () => {
  return (dispatch) => {
    return axios
      .get(process.env.REACT_APP_API_HOST + "/product/groups/configuration")
      .then((res) => dispatch(setConfig(res.data)))
      .catch((err) => {
        // dispatch(handleError(err));
      });
  };
};

//POST
export function createNewGroup(group, page, rowSize, attr, direction) {
  return (dispatch) => {
    return axios
      .post(process.env.REACT_APP_API_HOST + "/product/groups", group)
      .then((res) =>
        dispatch(getAllGroupsNoPagination(page, rowSize, attr, direction))
      )
      .catch((err) => {
        dispatch(handleError(err));
        console.log(err);
      });
  };
}

// UPD GROUP
export const updatedGroup = (updGroup, page, rowSize, attr, direction) => {
  return (dispatch) => {
    return (
      axios
        .put(
          process.env.REACT_APP_API_HOST + "/product/groups/" + updGroup.id,
          updGroup
        )
        //re-render all groups - expression not updated but created
        .then((res) =>
          dispatch(getAllGroupsNoPagination(page, rowSize, attr, direction))
        )
        .catch((err) => {
          dispatch(handleError(err));
          console.log(err);
        })
    );
  };
};

export const previewProducts = (group) => {
  return (dispatch) => {
    dispatch(productsPreviewLoading(true));
    return axios
      .post(
        process.env.REACT_APP_API_HOST +
          "/product/groups/markets/" +
          localStorage.getItem("market") +
          "/channels/" +
          localStorage.getItem("channel") +
          "/products/preview",
        group
      )
      .then((res) => {
        dispatch(setPreviewProducts(res.data));
        dispatch(productsPreviewLoading(false));
      })
      .catch((err) => {
        dispatch(handleError(err));
        dispatch(productsPreviewLoading(false));
      });
  };
};

const setPreviewProducts = (data) => {
  return {
    type: GROUP_PREVIEW_PRODUCTS,
    previewProducts: data.length,
  };
};
const setConfig = (data) => {
  return {
    type: GROUP_SET_CONFIG,
    config: data,
  };
};

const setAllGroups = (data) => {
  return {
    type: GROUP_FETCH_GROUPS,
    groups: data.content,
    totalGroups: data.totalElements,
  };
};

const tableLoading = (value) => {
  return {
    type: GROUP_GROUPS_TABLE_LOADING,
    groupsTableLoading: value,
  };
};

export const productsPreviewLoading = (value) => {
  return {
    type: GROUP_PRODUCTS_PREVIEW_LOADING,
    previewLoading: value,
  };
};

const setGroupsNoPag = (data, rowSize) => {
  let reducedData = data.filter((group) => group.type !== "Auto");
  const split = (input, output = []) => {
    if (!input.length) return output;
    output.push(input.slice(0, rowSize));
    return split(input.slice(rowSize), output);
  };
  let paginateData = split(reducedData);
  return {
    type: GROUP_SET_GROUPS_NO_PAG,
    groupsNoPag: reducedData,
    groupPages: paginateData,
    rowSize: rowSize,
  };
};
export const selectedGroup = (id) => {
  return {
    type: GROUP_SELECTED_GROUP_ID,
    selectedGroup: id,
  };
};

export const setNoDataGroup = (value) => {
  return {
    type: GROUP_GROUPS_SET_NO_DATA,
    groupsNoData: value,
  };
};

export const setCurretPageClientSide = (page) => {
  return {
    type: SET_PAGE_GROUP,
    page,
  };
};

export const setRowRizeGroup = (rowSize, data) => {
  const split = (input, output = []) => {
    if (!input.length) return output;
    output.push(input.slice(0, rowSize));
    return split(input.slice(rowSize), output);
  };
  let paginateData = split(data);
  return {
    type: SET_ROW_SIZE_GROUP,
    groupPages: paginateData,
    rowSize,
  };
};

const initialState = {
  groups: [],
  groupsNoPag: [],
  previewLoading: false,
  totalGroups: null,
  showAllData: true,
  config: [],
  selectedGroup: null,
  groupsTableLoading: true,
  groupsNoData: false,
  previewProducts: [],
  groupPages: [],
  groupPaginationData: [],
  totalGroup: 0,
  rowSize: 20,
  currentPage: 0,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GROUP_SET_GROUPS_NO_PAG:
      return {
        ...state,
        groupsNoPag: action.groupsNoPag,
        groupPages: action.groupPages,
        groupPaginationData:
          action.groupPages.length > 0
            ? action.groupPages[0]
            : action.groupPages,
        totalGroup: action.groupsNoPag.length,
        rowSize: action.rowSize,
        currentPage: 0,

        // totalGroupsNoPag: action.groupsNoPag,
      };
    case GROUP_FETCH_GROUPS:
      let removedGroups =
        state.totalGroupsNoPag.length > 0 &&
        state.totalGroupsNoPag.filter((group) => group.type === "Auto");
      return {
        ...state,
        groups: action.groups.filter((group) => group.type === null),
        totalGroups: action.totalGroups - removedGroups.length,
      };
    case GROUP_CREATE_GROUP:
      return {
        ...state,
        groups: state.groups.concat(action.newgroup),
      };
    case GROUP_UPDATE_GROUP:
      return {
        ...state,
        groups:
          state.groups &&
          state.groups.map((group) =>
            group.id === action.updGroup.id ? { ...action.updGroup } : group
          ),
      };
    case GROUP_TOGGLE_SHOW_ALL_DATA:
      return {
        ...state,
        showAllData: action.showAllData,
      };
    case GROUP_SET_CONFIG:
      return {
        ...state,
        config: action.config,
      };
    case GROUP_SELECTED_GROUP_ID:
      return {
        ...state,
        selectedGroup: action.selectedGroup,
      };
    case GROUP_GROUPS_TABLE_LOADING:
      return {
        ...state,
        groupsTableLoading: action.groupsTableLoading,
      };
    case GROUP_GROUPS_SET_NO_DATA:
      return {
        ...state,
        groupsNoData: action.groupsNoData,
      };

    case GROUP_PREVIEW_PRODUCTS:
      return {
        ...state,
        previewProducts: action.previewProducts,
      };
    case GROUP_PRODUCTS_PREVIEW_LOADING:
      return {
        ...state,
        previewLoading: action.previewLoading,
      };
    case SET_PAGE_GROUP:
      return {
        ...state,
        groupPaginationData: state.groupPages[action.page],
        currentPage: action.page,
      };
    case SET_ROW_SIZE_GROUP:
      return {
        ...state,
        groupPages: action.groupPages,
        groupPaginationData:
          action.groupPages.length > 0
            ? action.groupPages[0]
            : action.groupPages,
        // totalgroup: state.group.length,
        rowSize: action.rowSize,
      };
    case SORT_GROUPS:
      return {
        ...state,
        groupPages: action.campaignAnalyticsPages,
        groupPaginationData: action.campaignAnalyticsPages[0],
        rowSize: action.rowSize,
        currentPage: 0,
      };
    default:
      return state;
  }
}
