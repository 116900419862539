import React from "react";
import PropTypes from "prop-types";

import "./InputText.css";

export const InputText = props => {
  const inputStyling =
    "bg-white border-solid border-grey-light focus:text-teal border-1 outline-none text-teal-darker font-light xxl:text-base text-xs font-sans xxl:h-10 h-8 xxl:px-3 p-2 input-placeholder";
  const errorInputStyling =
    "bg-red-lightest border-solid border-red-lighter border-1 outline-none text-red font-light xxl:text-base text-xs font-sans xxl:h-10 h-8 xxl:px-3 p-2 input-placeholder-error";

  return (
    <div>
      <label
        htmlFor={props.name}
        className="block text-xs font-light text-grey-dark font-sans py-1 capitalize"
      >
        {props.label}
        {props.required ? (
          <span className="text-red">
            {" "}
            *{props.error ? <span> Required.</span> : null}
          </span>
        ) : null}
      </label>
      <div className="relative">
        {props.icon ? (
          <div className="absolute pin-l ml-2 h-full">
            <div
              className={`flex items-center h-full material-icons xxl:text-md text-sm ${
                props.error || props.contenterror
                  ? "text-red-lighter"
                  : "text-grey-light"
              }`}
            >
              {props.icon}
            </div>
          </div>
        ) : null}
        <input
          autoComplete="off"
          disabled={props.disabled}
          className={`${props.disabled ? "input-disabled" : ""} ${
            props.width ? props.width : "xxl:w-50 w-32"
          } ${props.icon ? "xxl:pl-8 pl-6" : "pl-2"} ${
            props.error || props.contenterror ? errorInputStyling : inputStyling
          }`}
          type={props.type}
          name={props.name}
          onChange={props.onChange}
          value={props.value}
          placeholder={props.placeholder}
          icon={props.icon}
          label={props.label}
        />
      </div>
    </div>
  );
};

InputText.propTypes = {
  /** Optional: If the input field is required */
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Optional: if the input has an error */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** The type of input field */
  type: PropTypes.string,
  /** Unique name of input field */
  name: PropTypes.string,
  /** Optional: On change function */
  onChange: PropTypes.func,
  /** Optional: Value of the input field */
  value: PropTypes.string,
  /** Placeholder of the input field */
  placeholder: PropTypes.string,
  /** Optional: Icon of the input field */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Optional: Label for the input field */
  label: PropTypes.string,
  /** Optional: Specific to custom email error checking fucntions */
  contenterror: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

InputText.defaultProps = {
  required: null,
  error: null,
  icon: null,
  contenterror: null,
  label: null,
  placeholder: null,
  onClick: () => {},
  onChange: () => {}
};
