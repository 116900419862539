import React, { Component } from "react";

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false
    };
  }
  render() {
    const header = this.props.header();
    const isExpandable = this.props.children.length > 0;

    return (
      <div
        className={`w-full h-full flex flex-row px-2 py-1 ${
          this.state.isExpanded ? "items-center" : ""
        } cursor-pointer relative`}
        onClick={() => {
          this.setState(prevState => {
            return { isExpanded: !prevState.isExpanded };
          });
        }}
      >
        <div className="flex-1 flex flex-col justify-center">
          {header}
          <div className={this.state.isExpanded ? "" : "hidden"}>
            {this.props.children}
          </div>
        </div>
        <div className="w-4 h-full flex items-center">
          {isExpandable ? (
            this.state.isExpanded ? (
              <i className="material-icons">arrow_drop_up</i>
            ) : (
              <i className="material-icons">arrow_drop_down</i>
            )
          ) : null}
        </div>
      </div>
    );
  }
}

export default Accordion;
