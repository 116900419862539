import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import ViewContainer from "containers/ViewContainer";
import Table from "components/Table";
import { openModal, closeModal } from "ducks/modal";
import * as MODAL_TYPE from "utils/modal-types";

import { openFormpanel, closeFormpanel } from "ducks/formpanel";
import { getAllGroupsNoPagination } from "ducks/groups";
import { TooltipLeft } from "components/Tooltips";

import {
  getAllCampaigns,
  selectedCampaign,
  getAllCampaignsDropdown,
} from "ducks/campaigns";
import {
  getAllPriceRules,
  getPriceRulesByCampaignId,
  getAllCompetitors,
  getConfig,
} from "ducks/priceRule";
import {
  getCampaignInformation,
  getPriceRuleInformation,
} from "ducks/campaignAnalytics";
import { getMarkets } from "ducks/markets";
import * as FORM_TYPE from "utils/form-types";
import * as FORM_ACTION from "utils/form-actions";

import { testData } from "services/priceList";

import { CircleButton } from "components/CircleButton";

class PriceCampaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topVisible: true,
      campaignData: [
        {
          noTableData: true,
          createdBy: "",
          lastModifiedBy: "",
          name: "",
          priority: "",
        },
      ],
      priceRuleData: [
        {
          noTableData: true,
          name: "",
          createdBy: "",
          campaignName: "",
          groups: { hideSorting: true, value: [] },
        },
      ],
      selectedCampaign: {},
      hoverAddButtonPriceCamp: false,
      hoverAddButtonPriceRule: false,
      selectedPriceRule: [],
      holdSelectedPriceRule: [],
      holdSelectedPagePriceRule: [],
    };
  }

  componentDidMount() {
    // this.props.getMarkets();
    if (localStorage.getItem("market")) {
      // this.props.getAllCampaigns(1, 20, null, null);
      // this.props.getAllPriceRules(1, 20, null, null);
      this.props.getAllCompetitors();
      this.props.getConfig();
    }
    this.props.getAllGroupsNoPagination(1, 20, null, null);

    this.setState({
      data: testData.map((data) => {
        return {
          id: data.id,
          priceRuleId: data.priceRule.id,
          channelId: data.channel.id,
          priceList: data.priceList,
          priceRule: data.priceRule.name,
          from: data.from,
          to: data.to,
          status: data.status,
          channel: data.channel.name,
        };
      }),
    });
  }

  componentWillUnmount() {
    this.props.closeFormpanel();
  }
  selectPriceRuleRows = (rows, page, row) => {
    this.setState({
      holdSelectedPriceRule: rows,
      holdSelectedPagePriceRule: page,
      selectedPriceRule: row,
    });
  };
  openDeleteModal = (data) => {
    let dataObj = {
      priceRule: this.state.selectedPriceRule,
      currentPage: this.props.currentPagePriceRule,
      rowSize: this.props.rowSizePriceRule,
      attr: this.props.priceRuleSortingAttr,
      sorting: this.props.priceRuleSortingDirection,
    };

    this.props.openModal(dataObj, MODAL_TYPE.DELETE, MODAL_TYPE.SMALL);
  };

  handleHoverRowButtonClicks = (icon, data) => {
    switch (icon) {
      case "delete_forever":
        this.openDeleteModal(data);
      default:
    }
  };

  clearPriceRuleSelections = () => {
    this.setState({
      selectedPriceRule: [],
      holdSelectedPriceRule: [],
      holdSelectedPagePriceRule: [],
    });
  };

  getAllCampaignData = (page, rowSize, attr, direction) => {
    this.props.getAllCampaigns(page, rowSize, attr, direction);
  };

  getAllPriceRules = (page, rowSize, attr, direction) => {
    this.props.getAllPriceRules(page, rowSize, attr, direction);
  };

  onClickPriceCamp = (row) => {
    if (row.id === this.props.selectedCampaignId) {
      this.props.getAllPriceRules(1, this.props.rowSizePriceRule, null, null);
      this.props.selectedCampaign(null);
    } else {
      this.props.getPriceRulesByCampaignId(
        row.id,
        1,
        this.props.rowSizePriceRule,
        null,
        null
      );
      this.props.selectedCampaign(row.id);
    }
  };

  selectCampaignRow = (row) => {
    this.setState({ selectedCampaign: row });
  };

  paginationPriceRuleByCampId = (page, rowSize, attr, direction) => {
    this.props.getPriceRulesByCampaignId(
      this.props.selectedCampaignId,
      page,
      rowSize,
      attr,
      direction
    );
  };

  onDoubleClickPriceCamp = (row) => {
    if (row.type && row.type.toLowerCase() === "auto") {
      this.props.getCampaignInformation(row);
    } else {
      this.props.openFormpanel(
        FORM_ACTION.STATE_EDIT,
        row,
        FORM_TYPE.PRICECAMPAIGN
      );
    }
  };

  onDoubleClickPriceRule = (row) => {
    if (row.type && row.type.toLowerCase() === "auto") {
      this.props.getPriceRuleInformation(row);
    } else {
      this.props.getAllCompetitors();
      this.props.getAllGroupsNoPagination(1, 20, null, null);
      this.props.getAllCampaignsDropdown();
      this.props.openFormpanel(
        FORM_ACTION.STATE_EDIT,
        row,
        FORM_TYPE.PRICERULE
      );
    }
  };

  onViewAnalytics = () => {
    this.props.openFormpanel(
      FORM_ACTION.STATE_READ,
      this.state.selectedCampaign,
      FORM_TYPE.ANALYTICS
    );
  };

  topAddButtonPriceCamp = () => {
    this.props.openFormpanel(
      FORM_ACTION.STATE_CREATE,
      [],
      FORM_TYPE.PRICECAMPAIGN
    );
  };
  topAddButtonPriceRule = () => {
    this.props.getAllCompetitors();
    this.props.getAllGroupsNoPagination(1, 20, null, null);
    this.props.getAllCampaignsDropdown();
    this.props.openFormpanel(FORM_ACTION.STATE_CREATE, [], FORM_TYPE.PRICERULE);
  };
  circleButtonOnMouseEnterPriceCamp = () => {
    this.setState({ hoverAddButtonPriceCamp: true });
  };
  circleButtonOnMouseLeavePriceCamp = () => {
    this.setState({ hoverAddButtonPriceCamp: false });
  };
  circleButtonOnMouseEnterPriceRule = () => {
    this.setState({ hoverAddButtonPriceRule: true });
  };
  circleButtonOnMouseLeavePriceRule = () => {
    this.setState({ hoverAddButtonPriceRule: false });
  };
  onClick = (row) => {};

  selectRows = (row) => {};

  render() {
    const topRightButtonsProduct = [
      {
        id: 1,
        icon: "close",
        text: "clear all",
        onClick: () => this.setState({ selectedPriceRule: [] }),
      },
      {
        id: 2,
        icon: "delete_forever",
        text: "delete",
        onClick: this.handleHoverRowButtonClicks,
      },
    ];

    const viewHeaderConfig = {
      showInfo: false,
    };

    let parsedCampaignData =
      this.props.campaigns &&
      this.props.campaigns.map((item) => {
        return {
          name: item.name,
          startDate: item.startDate
            ? moment(item.startDate).local().format("YYYY-MM-DD HH:mm")
            : " ",
          endDate: item.endDate
            ? moment(item.endDate).local().format("YYYY-MM-DD HH:mm")
            : " ",
          from: item.startDate,
          to: item.endDate,
          active: item.active ? "Active" : "Not Active",
          channels: item.channels,
          priority: item.priority,
          createdBy: item.createdBy,
          id: item.id,
          type: item.type,
        };
      });

    // const topRightButtonsCampaign = [
    //   {
    //     id: 1,
    //     icon: "bar_chart",
    //     text: "analytics",
    //     onClick: this.onViewAnalytics
    //   }
    // ];

    const tableConfigPriceCamp = {
      data:
        this.props.campaigns.length > 0
          ? parsedCampaignData
          : this.state.campaignData,
      tableLoading: this.props.campTableLoading,
      noData: this.props.campNoData,
      name: "Price Campaigns",
      onClick: this.onClickPriceCamp,
      onDoubleClick: this.onDoubleClickPriceCamp,
      hiddenCols: [
        "id",
        "channels",
        "priceRules",
        "noTableData",
        "from",
        "to",
        "type",
      ],
      // selectRows: this.selectCampaignRow,
      // selectAllRows: this.selectCampaignRow,
      // topRightButtons: topRightButtonsCampaign,
      paginationGetData: this.getAllCampaignData,
      dataLength: this.props.totalCampaigns,
      currentPage: this.props.currentPageCampaign,
      rowSize: this.props.rowSizeCampaign,
      totalPages: this.props.totalCampaignPages,
      attr: this.props.campaignSortingAttr,
      isTableLarge: this.props.isTableLarge,
    };

    let parsedPriceRuleData = this.props.priceRules.map((item) => {
      let campaign = { ...item.campaign };

      campaign.startDate = campaign.startDate
        ? moment(campaign.startDate).local().format("YYYY-MM-DD HH:mm")
        : " ";
      campaign.endDate = campaign.endDate
        ? moment(campaign.endDate).local().format("YYYY-MM-DD HH:mm")
        : " ";
      return {
        name: item.name,
        createdBy: item.createdBy,
        lastModifiedBy: item.lastModifiedBy,
        campaignName: item.campaign.name,
        groups: { hideSorting: true, value: item.groups ? item.groups : [] },
        competitors: item.competitors,
        id: item.id,
        campaign: campaign,
        maxPriceLimit: item.maxPriceLimit,
        maxPriceLimitValue: item.maxPriceLimitValue,
        minPriceLimit: item.minPriceLimit,
        minPriceLimitValue: item.minPriceLimitValue,
        fallbackPrice: item.fallbackPrice,
        fallbackPriceValue: item.fallbackPriceValue,
        priority: item.priority,
        strategy: item.strategy,
        strategyValue: item.strategyValue,
        loweredPriceLimit: item.loweredPriceLimit,
        priceIntervalLimit: item.priceIntervalLimit,
        onlyVerifiedMatches: item.onlyVerifiedMatches,
        type: item.type,
      };
    });
    const tableConfigPriceRules = {
      data:
        this.props.priceRules.length > 0
          ? parsedPriceRuleData
          : this.state.priceRuleData,
      tableLoading: this.props.priceRuleTableLoading,
      noData: this.props.priceRuleNoData,
      name: "Price Rules",
      onDoubleClick: this.onDoubleClickPriceRule,
      hiddenCols: [
        "competitors",
        "id",
        "campaign",
        "lastModifiedBy",
        "maxPriceLimit",
        "maxPriceLimitValue",
        "minPriceLimit",
        "minPriceLimitValue",
        "priority",
        "strategy",
        "strategyValue",
        "noTableData",
        "loweredPriceLimit",
        "priceIntervalLimit",
        "fallbackPrice",
        "fallbackPriceValue",
        "onlyVerifiedMatches",
        "type",
      ],
      topRightButtons: topRightButtonsProduct,
      singleSelectRow: true,
      selectRows: this.selectPriceRuleRows,
      selectAllRows: this.selectPriceRuleRows,
      selectedRows: this.state.holdSelectedPriceRule,
      selectedPages: this.state.holdSelectedPagePriceRule,
      paginationGetData: this.props.showAllPriceRuleData
        ? this.getAllPriceRules
        : this.paginationPriceRuleByCampId,
      dataLength: this.props.totalPricedRule,
      currentPage: this.props.currentPagePriceRule,
      rowSize: this.props.rowSizePriceRule,
      totalPages: this.props.totalPriceRulePages,
      attr: this.props.priceRuleSortingAttr,
      direction: this.props.priceRuleSortingDirection,
      isTableLarge: this.props.isTableLarge,
    };

    return (
      <ViewContainer
        viewHeaderConfig={viewHeaderConfig}
        hasPageButtons={true}
        topVisible={this.state.topVisible}
        handleToggleTop={this.handleToggleTop}
      >
        <div className="mb-4 flex flex-row w-1/4">
          <div className="xxl:h-10 h-8">
            <CircleButton
              onClick={this.topAddButtonPriceCamp}
              canUse={localStorage.getItem("market")}
              active="false"
              iconOne="add"
              onMouseEnter={this.circleButtonOnMouseEnterPriceCamp}
              onMouseLeave={this.circleButtonOnMouseLeavePriceCamp}
            />
          </div>
          <div className="ml-4">
            {this.state.hoverAddButtonPriceCamp && (
              <div className="h-6 xxl:pt-1">
                <TooltipLeft text="CREATE CAMPAIGN" right={true} />
              </div>
            )}
          </div>
        </div>

        <Table tableConfig={tableConfigPriceCamp} />
        <div className="my-4 flex flex-row w-1/4">
          <div className="xxl:h-10 h-8">
            <CircleButton
              onClick={this.topAddButtonPriceRule}
              canUse={localStorage.getItem("market")}
              active="false"
              iconOne="add"
              onMouseEnter={this.circleButtonOnMouseEnterPriceRule}
              onMouseLeave={this.circleButtonOnMouseLeavePriceRule}
            />
          </div>
          <div className="ml-4"></div>
          {this.state.hoverAddButtonPriceRule && (
            <div className="h-6 xxl:pt-1">
              <TooltipLeft text="CREATE PRICE RULE" right={true} />
            </div>
          )}
        </div>
        <div className="mb-8">
          <Table tableConfig={tableConfigPriceRules} />
        </div>
      </ViewContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaigns: state.campaigns.campaigns,
    totalCampaigns: state.campaigns.totalCampaigns,
    selectedCampaignId: state.campaigns.selectedCampaign,
    currentPageCampaign: state.tablePagination.campaignPage,
    rowSizeCampaign: state.tablePagination.campaignRowSize,
    totalCampaignPages: state.tablePagination.campaignTotalPages,
    campaignSortingAttr: state.tablePagination.campaignSortingAttr,
    campaignSortingDirection: state.tablePagination.campaignSortingDirection,
    campNoData: state.campaigns.noData,
    priceRules: state.priceRule.priceRuleList,
    totalPricedRule: state.priceRule.totalPriceRules,
    showAllPriceRuleData: state.priceRule.showAllData,
    currentPagePriceRule: state.tablePagination.priceRulePage,
    rowSizePriceRule: state.tablePagination.priceRuleRowSize,
    totalPriceRulePages: state.tablePagination.priceRuleTotalPages,
    priceRuleSortingAttr: state.tablePagination.priceRuleSortingAttr,
    priceRuleSortingDirection: state.tablePagination.priceRuleSortingDirection,
    priceRuleTableLoading: state.priceRule.priceRuleTableLoading,
    priceRuleNoData: state.priceRule.noData,
    selectedMenuItem: state.sidepanel.selectedMenuItem,
    campTableLoading: state.campaigns.campTableLoading,
    isTableLarge: state.sidepanel.isTableLarge,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openFormpanel: (formState, targetUnitData, formType) =>
      dispatch(openFormpanel(formState, targetUnitData, formType)),
    closeFormpanel: () => dispatch(closeFormpanel()),
    getAllCampaigns: (page, rowSize, attr, direction) =>
      dispatch(getAllCampaigns(page, rowSize, attr, direction)),
    getAllPriceRules: (page, rowSize, attr, direction) =>
      dispatch(getAllPriceRules(page, rowSize, attr, direction)),
    getPriceRulesByCampaignId: (id, page, rowSize, attr, direction) =>
      dispatch(getPriceRulesByCampaignId(id, page, rowSize, attr, direction)),
    selectedCampaign: (id) => dispatch(selectedCampaign(id)),
    getAllGroupsNoPagination: (page, rowSize, attr, direction) =>
      dispatch(getAllGroupsNoPagination(page, rowSize, attr, direction)),
    getAllCompetitors: () => dispatch(getAllCompetitors()),
    getConfig: () => dispatch(getConfig()),
    getAllCampaignsDropdown: () => dispatch(getAllCampaignsDropdown()),
    // getMarkets: () => dispatch(getMarkets()),
    openModal: (targetUnitData, modalType, modalSize) =>
      dispatch(openModal(targetUnitData, modalType, modalSize)),
    getCampaignInformation: (row) => dispatch(getCampaignInformation(row)),
    getPriceRuleInformation: (row) => dispatch(getPriceRuleInformation(row)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceCampaigns);
