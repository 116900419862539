export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


export const buildQueryString = (name, data) => {
  let urlQuery = "";
  if(data.length > 0){
    data.forEach((item, i) => {
      if(i + 1 < data.length){
        urlQuery = urlQuery.concat(name + item.id + '&')
      }else{
        urlQuery = urlQuery.concat(name + item.id)
      }
    });
  }else{
    urlQuery = name + data.id
  }
  return urlQuery;
}
