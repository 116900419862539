import React, { Component } from "react";
import ViewContainer from "containers/ViewContainer";
import SearchField from "components/Table/SearchField";
import NotificationsFilter from "containers/Filters/NotificationsFilter";
import {
  getAllNotifications,
  markAsRead,
  filterNotifications,
  clearFilter,
} from "ducks/notifications";
import { connect } from "react-redux";

import moment from "moment";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.scrollTo = [];

    this.state = {
      topVisible: true,
      showMore: {},
      showFilter: false,
      searchValue: "",
      timeZoneOffset: "",
      showArrow: false,
    };
  }

  componentDidMount() {
    this.getTimeZone();
    this.props.getAllNotifications();
    this.props.notifications &&
      this.props.notifications.map((notification) => {
        if (!notification.read) {
          this.props.markAsRead({ id: notification.id });
        }
      });
    // document.addEventListener("mousedown", this.handleOutsideClick);
  }

  // componentWillUnmount() {
  // document.removeEventListener("mousedown", this.handleOutsideClick);
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.state !== this.props.location.state) {
      this.scrollToNotification();
    } else return;
  }

  markAsRead = (notification) => {
    if (!notification.read) {
      this.props.markAsRead({ id: notification.id });
    }
  };

  handleSearch = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  onClickToggleShow = (id) => {
    this.setState((prevState) => ({
      showMore: { [id]: !prevState.showMore[id] },
    }));
  };

  scrollToNotification = async () => {
    try {
      const { id } = await this.props.location.state;
      if (id !== undefined && this.props.notifications.length > 0) {
        this.scrollTo[id].scrollIntoView();
        this.setState((prevState) => ({
          showMore: { [id]: !prevState.showMore[id] },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  toggleFilter = () => {
    this.setState((prevState) => ({
      showFilter: !prevState.showFilter,
    }));
  };

  // handleOutsideClick = e => {
  //   if (this.unmark.contains(e.target)) {
  //     return;
  //   } else {
  //     this.setState({
  //       showMore: false
  //     });
  //   }
  // };

  getTimeZone = () => {
    let date = new Date();
    let timeZoneOffset = date.getTimezoneOffset();
    let hourDiff = timeZoneOffset / 60;
    let addOn = "";
    if (hourDiff < 0) {
      addOn = Math.abs(hourDiff);
    } else {
      addOn = -Math.abs(hourDiff);
    }
    this.setState({
      timeZoneOffset: addOn,
    });
  };

  render() {
    const viewHeaderConfig = {
      showInfo: false,
    };

    let notificationList = this.props.notifications.filter((notification) => {
      return Object.keys(notification).some((key) => {
        if (!Array.isArray(notification[key]) && notification[key]) {
          return notification[key]
            .toString()
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase());
        }
      });
    });

    return (
      <ViewContainer
        viewHeaderConfig={viewHeaderConfig}
        hasPageButtons={true}
        topVisible={this.state.topVisible}
        handleToggleTop={this.handleToggleTop}
      >
        <div
          ref={(node) => (this.unmark = node)}
          className="bg-white h-full w-5/6 ml-8 mt-12 p-6 pb-6"
        >
          <div className="flex flex-row">
            <div className="rounded-full bg-grey-lighter h-8 w-8 flex items-center justify-center">
              <i
                onClick={this.toggleFilter}
                className="material-icons text-grey-dark cursor-pointer"
              >
                tune
              </i>
            </div>
            <SearchField
              onChange={this.handleSearch}
              value={this.state.searchValue}
            />
          </div>
          {this.state.showFilter && (
            <NotificationsFilter
              clearFilter={() => this.props.clearFilter()}
              filterNotifications={(query) =>
                this.props.filterNotifications(query)
              }
            />
          )}
          <div className="flex flex-row mt-6 mb-2">
            <span className="grey-darker-text font-light">Notifications</span>
            {this.props.notifications.length > 0 && (
              <>
                <div
                  className={`rounded-full ${
                    this.props.notifications.length > 9 ? "h-5 w-5" : "h-4 w-4"
                  }  flex items-center justify-center bg-red ml-4 self-center`}
                >
                  <span className="text-white text-xs">
                    {this.props.notifications.filter(
                      (notification) => notification.read === false
                    ).length > 99
                      ? "99+"
                      : this.props.notifications.filter(
                          (notification) => notification.read === false
                        ).length}{" "}
                  </span>
                </div>
                <span className="font-thin grey-darker-text ml-2 ">New </span>
              </>
            )}
          </div>
          <div className="h-almost-screen overflow-y-scroll">
            <ul>
              {notificationList &&
                notificationList.map((notification, i) => {
                  let key =
                    this.state.showMore && Object.keys(this.state.showMore)[0];
                  let timestamp = Date.parse(notification.created);
                  return (
                    <li
                      key={notification.id}
                      // onClick={() => this.markAsRead(notification)}
                      ref={(ref) => (this.scrollTo[i] = ref)}
                      className="flex flex-row flex-wrap w-full mt-2 py-4 cursor-pointer pl-4 bg-grey-lightest"
                    >
                      <div className="flex flex-row w-full">
                        {notification.data && notification.data.length > 0 && (
                          <i
                            onClick={() => this.onClickToggleShow(i)}
                            className="material-icons self-center text-grey mr-4"
                          >
                            {this.state.showMore && this.state.showMore[i]
                              ? "keyboard_arrow_down"
                              : "keyboard_arrow_right"}
                          </i>
                        )}
                        <span
                          className={`rounded-full h-2 w-2 flex ${
                            notification.read ? "bg-grey" : "bg-red"
                          }  mr-4 self-center`}
                        ></span>
                        <span className="flex flex-col w-1/2">
                          <span className="flex flex-row text-grey-darkest">
                            {notification.message}
                          </span>
                          <span className="flex flex-row grey-darker-text text-sm font-light">
                            Category:{" "}
                            {notification.category && notification.category}
                          </span>
                        </span>
                        <span className="flex flex-col w-1/2">
                          <span className="flex flex-row text-grey-darkest">
                            {moment(notification.created)
                              .local()
                              .format("YYYY-MM-DD HH:mm")}
                          </span>
                          <span className="flex flex-row grey-darker-text text-sm font-light">
                            Priority:{" "}
                            {notification.priority && notification.priority}
                          </span>
                        </span>
                      </div>
                      {notification.data &&
                        notification.data.length > 0 &&
                        this.state.showMore[i] && (
                          <div className="h-full w-full">
                            <span className="w-full pt-4 flex flex-row pl-16 text-grey-darkest ">
                              {" "}
                              Affected Products:
                            </span>
                            <div className="flex flex-row pl-12  pt-2 max-h-20 overflow-y-scroll">
                              <ul className="flex flex-row w-full flex-wrap font-thin text-grey-darkerst">
                                {notification.data.map((data) => (
                                  <>
                                    <li className="px-4 w-1/4 relative">
                                      {data.articleNumber}
                                    </li>
                                  </>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      {/* {this.state.showMore[i] ? (
                        <div className="flex flex-row h-20 bg-blue-lightest">
                          <span> some more text will go in here </span>
                        </div>
                      ) : null} */}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </ViewContainer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.notifications,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllNotifications: () => dispatch(getAllNotifications()),
    markAsRead: (notification) => dispatch(markAsRead(notification)),
    filterNotifications: (query) => dispatch(filterNotifications(query)),
    clearFilter: () => dispatch(clearFilter()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
