import axios from "axios";
const SETTINGS_WIPE_ALL = "SETTINGS_WIPE_ALL";
const SETTINGS_SET_USER_DETAILS = "SETTINGS_SET_USER_DETAILS";
const SETTINGS_PASSWORD_RESPONSE = "SETTINGS_PASSWORD_RESPONSE";
const SETTINGS_ADD_USER_RESPONSE = "SETTINGS_ADD_USER_RESPONSE";
const SETTINGS_SET_USER_GROUPS = "SETTINGS_SET_USER_GROUPS";
const SETTINGS_SET_USER_LIST = "SETTINGS_SET_USER_LIST";
const SETTINGS_UPDATE_USER_LIST_ACTIVATE = "SETTINGS_UPDATE_USER_LIST_ACTIVATE";
const SETTINGS_UPDATE_USER_LIST_INACTIVATE =
  "SETTINGS_UPDATE_USER_LIST_INACTIVATE";
const SETTINGS_UPDATE_USER_DETAILS = "SETTINGS_UPDATE_USER_DETAILS";
const SETTING_UPDATE_SUCCESS_USER_INVITE_LIST =
  "SETTING_UPDATE_SUCCESS_USER_INVITE_LIST";
const SETTINGS_ADD_REMOVE_INVITE_USER = "SETTINGS_ADD_REMOVE_INVITE_USER";
const SETTINGS_HANDLE_INVITE_USER_ONCHANGE =
  "SETTINGS_HANDLE_INVITE_USER_ONCHANGE";
const SETTINGS_HANDLE_INVITE_USER_GROUP_SELECT =
  "SETTINGS_HANDLE_INVITE_USER_GROUP_SELECT";

export function updatePassword(currentPassword, newPassword) {
  return dispatch => {
    return axios
      .put(process.env.REACT_APP_API_HOST + "/auth/change-password", {
        currentPassword,
        newPassword
      })
      .then(res => {
        dispatch(updatePasswordResponse("success"));
      })
      .catch(error => {
        dispatch(updatePasswordResponse("error"));
      });
  };
}

export function addUser(users) {
  return dispatch => {
    let successfullUserList = [...users];
    dispatch(updateSuccessfullUserInviteList([]));
    const userList = users.map(user => {
      return {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        userGroupId: user.selectedUserGroup.id
      };
    });
    return axios
      .post(process.env.REACT_APP_API_HOST + "/auth/invite/3", userList)
      .then(res => {
        dispatch(updateAddUserResponse(res.data.map(user => user.email)));
        if (res.data.length > 0) {
          res.data.map(data => {
            return (successfullUserList = successfullUserList.filter(
              user => user.email !== data.email
            ));
          });
          dispatch(updateSuccessfullUserInviteList(successfullUserList));
        } else {
          successfullUserList.push(users);
          dispatch(updateSuccessfullUserInviteList(successfullUserList));
        }
      })
      .catch(error => {
        dispatch(updateAddUserResponse("error"));
      });
  };
}

export function updateUserDetails(id, values, email) {
  return dispatch => {
    if (values.email === email) {
      values.email = null;
    }
    return axios
      .put(process.env.REACT_APP_API_HOST + "/auth/users/" + id, values)
      .then(res => {
        dispatch(updateUserDetailsResponse("success"));
        let user = JSON.parse(localStorage.getItem("user"));
        user.firstName = values.firstName;
        user.lastName = values.lastName;
        if (values.email) {
          user.email = values.email;
        } else {
          user.email = email;
        }

        localStorage.setItem("user", JSON.stringify(user));
        dispatch(setUserDetails());
      })
      .catch(error => {
        if (error.response.status === 409) {
          dispatch(updateUserDetailsResponse("409"));
        } else {
          dispatch(updateUserDetailsResponse("error"));
        }
      });
  };
}

export function getUserGroups() {
  return dispatch => {
    return axios
      .get(process.env.REACT_APP_API_HOST + "/auth/user-info/3/groups")
      .then(res => {
        dispatch(setUserGroups(res.data));
      })
      .catch(error => {
        dispatch(setUserGroups([]));
      });
  };
}

export function getAllUsers() {
  return dispatch => {
    return axios
      .get(process.env.REACT_APP_API_HOST + "/auth/users/manage")
      .then(res => {
        dispatch(setUserList(res.data));
      })
      .catch(error => {
        dispatch(setUserList([]));
      });
  };
}

export function updateUsers(user, value) {
  return dispatch => {
    switch (value) {
      case "activate":
        return axios
          .put(
            process.env.REACT_APP_API_HOST +
              "/auth/users/manage/" +
              user.id +
              "/activate"
          )
          .then(res => {
            dispatch(updateUserListActivate(user));
          })
          .catch(error => {
            console.log(error);
          });

      case "inactivate":
        return axios
          .put(
            process.env.REACT_APP_API_HOST +
              "/auth/users/manage/" +
              user.id +
              "/inactivate"
          )
          .then(res => {
            dispatch(updateUserListInactivate(user));
          })
          .catch(error => {
            console.log(error);
          });
      default:
        return;
    }
  };
}

const updateSuccessfullUserInviteList = users => {
  return {
    type: SETTING_UPDATE_SUCCESS_USER_INVITE_LIST,
    successfullUserInviteList: users
  };
};
export const setUserDetails = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userDetails = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email
  };
  return {
    type: SETTINGS_SET_USER_DETAILS,
    userDetails
  };
};

export const updatePasswordResponse = value => {
  return {
    type: SETTINGS_PASSWORD_RESPONSE,
    passwordResponse: value
  };
};

export const updateAddUserResponse = value => {
  return {
    type: SETTINGS_ADD_USER_RESPONSE,
    addUserResponse: value
  };
};

export const updateUserDetailsResponse = value => {
  return {
    type: SETTINGS_UPDATE_USER_DETAILS,
    userDetailsResponse: value
  };
};
export const setUserGroups = userGroups => {
  return {
    type: SETTINGS_SET_USER_GROUPS,
    userGroups
  };
};

export const setUserList = userList => {
  return {
    type: SETTINGS_SET_USER_LIST,
    userList
  };
};

export const updateUserListActivate = user => {
  return {
    type: SETTINGS_UPDATE_USER_LIST_ACTIVATE,
    user: user
  };
};

export const updateUserListInactivate = user => {
  return {
    type: SETTINGS_UPDATE_USER_LIST_INACTIVATE,
    user: user
  };
};

export const addRemoveInviteUser = (handler, index) => {
  return {
    type: SETTINGS_ADD_REMOVE_INVITE_USER,
    handler,
    index
  };
};

export const handleInviteUserOnChange = (value, name, index) => {
  return {
    type: SETTINGS_HANDLE_INVITE_USER_ONCHANGE,
    value,
    name,
    index
  };
};

export const handleInviteUserGroupSelect = (name, option, index) => {
  return {
    type: SETTINGS_HANDLE_INVITE_USER_GROUP_SELECT,
    name,
    option,
    index
  };
};

export const wipeAllSettings = () => {
  return {
    type: SETTINGS_WIPE_ALL
  };
};

const initialState = {
  userDetails: {},
  userDetailsResponse: null,
  passwordResponse: null,
  addUserResponse: null,
  userGroups: [],
  userList: [],
  successfullUserInviteList: [],
  inviteUsers: [
    { selectedUserGroup: {}, email: "", firstName: "", lastName: "" }
  ]
};

export default function reducer(state = initialState, action = {}) {
  let inviteUsers = [...state.inviteUsers];
  switch (action.type) {
    case SETTINGS_WIPE_ALL:
      return {
        userDetails: {},
        userDetailsResponse: null,
        passwordResponse: null,
        addUserResponse: null,
        userGroups: [],
        userList: [],
        successfullUserInviteList: [],
        inviteUsers: [
          { selectedUserGroup: {}, email: "", firstName: "", lastName: "" }
        ]
      };
    case SETTINGS_ADD_REMOVE_INVITE_USER:
      if (action.handler === "add") {
        inviteUsers.push({
          selectedUserGroup: {},
          email: "",
          firstName: "",
          lastName: ""
        });
      } else {
        inviteUsers.splice(action.index, 1);
      }
      return {
        ...state,
        inviteUsers
      };
    case SETTINGS_HANDLE_INVITE_USER_ONCHANGE:
      inviteUsers[action.index][action.name] = action.value;
      return {
        ...state,
        inviteUsers
      };
    case SETTINGS_HANDLE_INVITE_USER_GROUP_SELECT:
      inviteUsers[action.index][action.name] = action.option;
      return {
        ...state,
        inviteUsers
      };
    case SETTING_UPDATE_SUCCESS_USER_INVITE_LIST:
      return {
        ...state,
        successfullUserInviteList: action.successfullUserInviteList
      };
    case SETTINGS_SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.userDetails
      };
    case SETTINGS_UPDATE_USER_DETAILS:
      return {
        ...state,
        userDetailsResponse: action.userDetailsResponse
      };
    case SETTINGS_PASSWORD_RESPONSE:
      return {
        ...state,
        passwordResponse: action.passwordResponse
      };
    case SETTINGS_ADD_USER_RESPONSE:
      if (
        Array.isArray(action.addUserResponse) &&
        action.addUserResponse.length > 0 &&
        inviteUsers.length > 1
      ) {
        action.addUserResponse.map(email => {
          return (inviteUsers = inviteUsers.filter(
            user => user.email === email
          ));
        });
      } else if (
        Array.isArray(action.addUserResponse) &&
        action.addUserResponse.length === 0
      ) {
        inviteUsers = [
          {
            selectedUserGroup: {},
            email: "",
            firstName: "",
            lastName: ""
          }
        ];
      }

      return {
        ...state,
        addUserResponse: action.addUserResponse,
        inviteUsers
      };
    case SETTINGS_SET_USER_GROUPS:
      return {
        ...state,
        userGroups: action.userGroups
      };
    case SETTINGS_SET_USER_LIST:
      return {
        ...state,
        userList: action.userList
      };
    case SETTINGS_UPDATE_USER_LIST_ACTIVATE:
      return {
        ...state,
        userList: state.userList.map(user => {
          if (user.id === action.user.id) {
            user.active = true;
          }
          return user;
        })
      };
    case SETTINGS_UPDATE_USER_LIST_INACTIVATE:
      return {
        ...state,
        userList: state.userList.map(user => {
          if (user.id === action.user.id) {
            user.active = false;
          }
          return user;
        })
      };
    default:
      return state;
  }
}
