import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

export default class DatePicker extends Component {
  static propTypes = {
    /** Date Range - From and to dates/times ? - True False **/
    dateRange: PropTypes.bool,
    /** Show Time - True False **/
    showTime: PropTypes.bool,
    /** No Past Date - Cant select a date/time in the past **/
    noPastDate: PropTypes.bool,
    /** Save Date - Function to get dates to save **/
    saveDate: PropTypes.func,
    /** Has No End Date Option - A checkbox if there will be no end date - True False  **/
    hasNoEndDateOption: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      date: null,
      yyyymmdd: "",
      currentDay: "",
      selectedStartDate: null,
      selectedEndDate: null,
      selectedStartTime: null,
      selectedEndTime: null,
      ongoingChecked: false,
      canSave: false,
    };
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);
    if (this.props.openDropdown) {
      this.setState({
        dropdownOpen: this.props.openDropdown,
      });
    }

    //TODO: SET STATE WITH INCOMING DATA
    this.setState({
      yyyymmdd: moment().format("YYYY-MM") + "-01",
      currentDay: moment().format("YYYY-MM-DD"),
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  clearAllData = () => {
    this.setState({
      dropdownOpen: false,
      selectedStartDate: null,
      selectedEndDate: null,
      selectedStartTime: null,
      selectedEndTime: null,
      ongoingChecked: false,
      canSave: false,
    });
  };

  handleToggle = () => {
    this.setState((prevSate) => ({
      dropdownOpen: !prevSate.dropdownOpen,
    }));
  };

  closeDropdown = () => {
    this.setState({
      dropdownOpen: false,
    });
  };

  handleOutsideClick = (e) => {
    if (this.singleDropdown.contains(e.target)) {
      return;
    } else {
      this.closeDropdown();
    }
  };

  changeYear = (direction) => {
    let yyyymmdd = this.state.yyyymmdd.split("-");
    if (direction === "++") {
      yyyymmdd[0] = (parseInt(yyyymmdd[0]) + 1).toString();
    } else {
      yyyymmdd[0] = (parseInt(yyyymmdd[0]) - 1).toString();
    }
    yyyymmdd = yyyymmdd.join("-");
    this.setState({ yyyymmdd: yyyymmdd.toString() });
  };

  changeMonth = (direction) => {
    let yyyymmdd = this.state.yyyymmdd.split("-");
    if (direction === "++") {
      if (yyyymmdd[1] === "12") {
        yyyymmdd[1] = "01";
        yyyymmdd[0] = (parseInt(yyyymmdd[0]) + 1).toString();
      } else {
        let month = (parseInt(yyyymmdd[1]) + 1).toString();
        yyyymmdd[1] = month < 10 ? "0" + month : month;
      }
    } else {
      if (yyyymmdd[1] === "01") {
        yyyymmdd[1] = "12";
        yyyymmdd[0] = (parseInt(yyyymmdd[0]) - 1).toString();
      } else {
        let month = (parseInt(yyyymmdd[1]) - 1).toString();
        yyyymmdd[1] = month < 10 ? "0" + month : month;
      }
    }
    yyyymmdd = yyyymmdd.join("-");
    this.setState({ yyyymmdd: yyyymmdd.toString() });
  };

  setSelectedDate = (date) => {
    let startDate =
      this.state.selectedStartDate &&
      this.state.selectedStartDate.split("-").join("");
    let incomingDate = date.split("-").join("");
    if (this.state.ongoingChecked || !this.props.dateRange) {
      this.setState({ selectedStartDate: date }, () => this.canSave());
    } else {
      if (!this.state.selectedStartDate) {
        this.setState({ selectedStartDate: date }, () => this.canSave());
      } else if (startDate === incomingDate) {
        this.setState({ selectedEndDate: date }, () => this.canSave());
      } else if (
        this.state.selectedStartDate &&
        incomingDate > startDate &&
        !this.state.selectedEndDate
      ) {
        this.setState({ selectedEndDate: date }, () => this.canSave());
      } else {
        this.setState({ selectedStartDate: date, selectedEndDate: null }, () =>
          this.canSave()
        );
      }
    }
  };

  setSelectedTime = (time) => {
    if (this.state.ongoingChecked || !this.props.dateRange) {
      this.setState({ selectedStartTime: time }, () => this.canSave());
    } else {
      if (!this.state.selectedStartTime) {
        this.setState({ selectedStartTime: time }, () => this.canSave());
      } else if (this.state.selectedStartTime && this.state.selectedEndTime) {
        this.setState({ selectedStartTime: time, selectedEndTime: null }, () =>
          this.canSave()
        );
      } else if (this.state.selectedStartTime) {
        this.setState({ selectedEndTime: time }, () => this.canSave());
      }
    }
    this.canSave();
  };

  saveDate = () => {
    if (this.state.canSave) {
      if (this.props.showTime && this.props.dateRange) {
        if (
          this.state.selectedStartDate &&
          this.state.selectedStartTime &&
          ((this.state.selectedEndDate && this.state.selectedEndTime) ||
            this.state.ongoingChecked)
        ) {
          this.props.saveDate(
            this.state.selectedStartDate,
            this.state.selectedStartTime,
            this.state.selectedEndDate,
            this.state.selectedEndTime
          );
          // this.clearAllData();
          this.closeDropdown();
        }
      } else if (!this.props.showTime && this.props.dateRange) {
        if (
          this.state.selectedStartDate &&
          (this.state.selectedEndDate || this.state.ongoingChecked)
        ) {
          this.props.saveDate(
            this.state.selectedStartDate,
            this.state.selectedEndDate,
            this.props.indexEdit
          );
          // this.clearAllData();
          this.closeDropdown();
        }
      } else if (this.props.showTime && !this.props.dateRange) {
        if (this.state.selectedStartDate && this.state.selectedStartTime) {
          this.props.saveDate(
            this.state.selectedStartDate,
            this.state.selectedStartTime
          );
          // this.clearAllData();
          this.closeDropdown();
        }
      } else if (!this.props.showTime && !this.props.dateRange) {
        if (this.state.selectedStartDate) {
          this.props.saveDate(this.state.selectedStartDate);
          // this.clearAllData();
          this.closeDropdown();
        }
      }
    }
  };

  canSave = () => {
    if (this.props.showTime && this.props.dateRange) {
      if (
        this.state.selectedStartDate &&
        this.state.selectedStartTime &&
        ((this.state.selectedEndDate && this.state.selectedEndTime) ||
          this.state.ongoingChecked)
      ) {
        this.setState({ canSave: true });
      }
    } else if (!this.props.showTime && this.props.dateRange) {
      if (
        this.state.selectedStartDate &&
        (this.state.selectedEndDate || this.state.ongoingChecked)
      ) {
        this.setState({ canSave: true });
      }
    } else if (this.props.showTime && !this.props.dateRange) {
      if (this.state.selectedStartDate && this.state.selectedStartTime) {
        this.setState({ canSave: true });
      }
    } else if (!this.props.showTime && !this.props.dateRange) {
      if (this.state.selectedStartDate) {
        this.setState({ canSave: true });
      }
    }
  };
  render() {
    let dateRange = [];
    if (this.state.selectedStartDate && this.state.selectedEndDate) {
      let startingDate = this.state.selectedStartDate;
      let endingDate = this.state.selectedEndDate;

      while (
        moment
          .utc(startingDate, "YYYY-MM-DD")
          .isSameOrBefore(moment.utc(endingDate, "YYYY-MM-DD"))
      ) {
        dateRange.push(startingDate);
        startingDate = moment(startingDate, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
      }
    }

    const getFirstDay = (d) => {
      const indexOfDay = moment(d, "YYYY-MM-DD").day();
      return indexOfDay;
    };

    const getHowManyDays = (d) => {
      return moment(d, "YYYY-MM").daysInMonth();
    };

    const days = ["S", "M", "T", "W", "T", "F", "S"];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dates = [];

    let day = this.state.yyyymmdd;

    for (let i = 0; i < 35; i++) {
      if (i < getFirstDay(this.state.yyyymmdd)) {
        dates.push(null);
      } else if (
        parseInt(day.split("-")[2]) <= getHowManyDays(this.state.yyyymmdd) &&
        parseInt(day.split("-")[1]) ===
          parseInt(this.state.yyyymmdd.split("-")[1])
      ) {
        dates.push(day);
        day = moment(day, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
      } else {
        dates.push(null);
      }
    }

    const splitDate = this.state.yyyymmdd.split("-");
    const splitStartDate =
      this.state.selectedStartDate && this.state.selectedStartDate.split("-");

    const times = [];
    let time = "00:00";

    for (let i = 0; i < 96; i++) {
      times.push(time);
      time = moment(time, "HH:mm").add(15, "minutes").format("HH:mm");
    }

    return (
      <div
        className="flex flex-col relative"
        ref={(node) => (this.singleDropdown = node)}
      >
        <div className="pb-4 text-xs xxl:text-sm flex flex-row items-center ">
          <div
            onClick={
              !this.props.hideCancel
                ? () =>
                    this.setState((prevState) => ({
                      dropdownOpen: !prevState.dropdownOpen,
                    }))
                : null
            }
            className={` ${
              this.state.dropdownOpen
                ? "bg-teal-dark text-white"
                : "bg-table-grey text-grey-darker-text"
            } xxl:w-10 xxl:h-10 w-8 h-8 rounded-full 
          material-icons-outlined  
          hover:bg-teal hover:text-white cursor-pointer flex items-center justify-center 
          xxl:text-xl text-lg mr-1`}
          >
            event
          </div>
          <div className="text-teal-dark font-medium text-right pr-1 w-20">
            {this.state.selectedStartDate ? (
              this.state.selectedStartDate
            ) : (
              <span className="text-grey-dark font-medium opacity-25">
                YYYYMMDD
              </span>
            )}
          </div>
          {this.props.showTime && (
            <div className="text-teal-dark font-medium w-8">
              {this.state.selectedStartTime ? (
                this.state.selectedStartTime
              ) : (
                <span className="text-grey-dark opacity-25">00:00</span>
              )}
            </div>
          )}

          {this.props.dateRange && (
            <>
              <div className="w-8 text-center pl-2 text-grey-dark text-xxs font-medium">
                TO
              </div>

              <div className="text-blue font-medium w-20 text-right pr-1">
                {this.state.selectedEndDate
                  ? this.state.selectedEndDate
                  : !this.state.ongoingChecked && (
                      <span className="text-grey-dark font-medium opacity-25">
                        YYYYMMDD
                      </span>
                    )}
                {this.state.ongoingChecked && "No End Date"}
              </div>
              {this.props.showTime && (
                <div className="text-blue font-medium w-8">
                  {this.state.selectedEndTime
                    ? this.state.selectedEndTime
                    : !this.state.ongoingChecked && (
                        <span className="text-grey-dark opacity-25 font-medium ">
                          00:00
                        </span>
                      )}
                </div>
              )}
            </>
          )}
        </div>
        {this.state.dropdownOpen && (
          <div
            className={` ${
              this.props.showTime ? "w-74" : "w-70"
            } flex flex-col bg-white pt-3 rounded-lg absolute z-30 mt-12 shadow`}
          >
            <div className="flex flex-row pl-4">
              <div
                className={`bg-white ${
                  this.props.showTime ? "w-2/3" : "w-full pr-4"
                }   flex flex-col font-sans text-grey-darker`}
              >
                <div className="flex flex-row w-full justify-center pb-4">
                  <div
                    className="px-2 cursor-pointer h-full flex items-center justify-center"
                    onClick={() => this.changeYear("--")}
                  >
                    <i className="material-icons text-xl hover:text-teal inline-block opacity-65 hover:opacity-100">
                      first_page
                    </i>
                  </div>
                  <div
                    className="pr-4 cursor-pointer h-full flex items-center justify-center"
                    onClick={() => this.changeMonth("--")}
                  >
                    <i className="material-icons text-xl hover:text-teal opacity-65 hover:opacity-100">
                      chevron_left
                    </i>
                  </div>
                  <div className=" text-xs text-teal font-medium w-5/6 text-center h-full flex items-center justify-center">
                    <span>
                      {
                        months[
                          splitDate[1] < 10
                            ? parseInt(splitDate[1][1]) - 1
                            : parseInt(splitDate[1]) - 1
                        ]
                      }
                    </span>
                    <span className="inline-block pl-2">{splitDate[0]}</span>
                  </div>
                  <div
                    className="pl-4 cursor-pointer h-full flex items-center justify-center"
                    onClick={() => this.changeMonth("++")}
                  >
                    <i className="material-icons text-xl hover:text-teal opacity-65 hover:opacity-100">
                      chevron_right
                    </i>
                  </div>
                  <div
                    className="px-2 cursor-pointer h-full flex items-center justify-center"
                    onClick={() => this.changeYear("++")}
                  >
                    <i className="material-icons text-xl hover:text-teal inline-block opacity-65 hover:opacity-100">
                      last_page
                    </i>
                  </div>
                </div>
                <div className="flex flex-row">
                  {days.map((day) => (
                    <div className="w-1/7 text-center text-grey-dark text-xs">
                      {day}
                    </div>
                  ))}
                </div>
                <div className="flex flex-row flex-wrap">
                  {dates.map((date) => {
                    let dateDay = date ? date.split("-")[2] : "";
                    let rangeContainsDay = false;
                    if (dateRange.length > 0) {
                      dateRange.map((range) => {
                        if (date === range) {
                          return (rangeContainsDay = true);
                        }
                      });
                    }

                    return (
                      <div
                        onClick={
                          this.props.noPastDate &&
                          date &&
                          moment(date).isAfter(this.state.currentDay)
                            ? null
                            : () =>
                                this.setSelectedDate(
                                  splitDate[0] +
                                    "-" +
                                    splitDate[1] +
                                    "-" +
                                    dateDay
                                )
                        }
                        className={`w-1/7 my-1 cursor-pointer text-xs font-medium text-center flex justify-center items-center 
                  
                  ${
                    rangeContainsDay &&
                    this.state.selectedStartDate !==
                      this.state.selectedEndDate &&
                    "bg-teal text-white"
                  }
                  ${
                    dateRange.length > 0 &&
                    dateRange[0] === date &&
                    this.state.selectedStartDate !==
                      this.state.selectedEndDate &&
                    "rounded-l-full "
                  } 

                  ${
                    dateRange.length > 0 &&
                    dateRange[dateRange.length - 1] === date &&
                    this.state.selectedStartDate !==
                      this.state.selectedEndDate &&
                    "rounded-r-full "
                  }
                    `}
                      >
                        <div
                          className={`h-6 w-6  flex justify-center items-center
                    ${
                      date === this.state.currentDay &&
                      dateRange.length < 1 &&
                      "bg-table-grey text-grey-dark rounded-full"
                    }
                    ${
                      this.props.noPastDate &&
                      date &&
                      moment(date).isAfter(this.state.currentDay) &&
                      "bg-grey text-grey-dark rounded-full"
                    }
                      ${
                        splitStartDate
                          ? parseInt(dateDay) === parseInt(splitStartDate[2]) &&
                            splitStartDate[1] === splitDate[1] &&
                            splitStartDate[0] === splitDate[0] &&
                            "bg-teal text-white  "
                          : ""
                      } 
                      ${
                        dateRange.length > 0
                          ? dateRange[0] === date
                            ? "rounded-full bg-teal-dark"
                            : ""
                          : "rounded-full"
                      }
                    `}
                        >
                          {dateDay}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {this.props.showTime && (
                <>
                  <div className="border-l-1 border-table-grey w-1 ml-3" />
                  <div className="h-56 w-1/4 ml-1 text-xs text-grey-darker relative flex flex-col items-center">
                    <div className="absolute w-full pin-t ">
                      <div className="bg-white text-xs w-full flex justify-center items-center px-2 pt-1.5">
                        <div className="text-teal font-medium">Select Time</div>
                      </div>
                      <div className="h-4 gradient-fade-bottom" />
                    </div>

                    <div className="h-full px-4 overflow-y-auto flex flex-col py-8">
                      {times.map((t) => {
                        return (
                          <div
                            onClick={() => this.setSelectedTime(t)}
                            className={`h-6 py-1 flex justify-center items-center text-xs mb-1 px-4 font-medium cursor-pointer rounded-full
                  ${
                    this.state.selectedStartTime &&
                    t === this.state.selectedStartTime
                      ? "bg-teal text-white "
                      : ""
                  }
                  ${
                    this.state.selectedEndTime &&
                    t === this.state.selectedEndTime
                      ? "bg-blue text-white"
                      : ""
                  }
                `}
                          >
                            {t}
                          </div>
                        );
                      })}
                    </div>
                    <div className="absolute w-full pin-b ">
                      <div className="h-4 gradient-fade-top" />
                    </div>
                  </div>
                </>
              )}
            </div>
            {this.props.dateRange && this.props.hasNoEndDateOption && (
              <div className="flex items-center pl-6 text-xs">
                <i
                  onClick={() => {
                    this.setState((prevSate) => ({
                      ongoingChecked: !prevSate.ongoingChecked,
                      selectedEndDate: null,
                      selectedEndTime: null,
                    }));
                  }}
                  className="material-icons text-lg text-teal hover:text-teal cursor-pointer"
                >
                  {this.state.ongoingChecked
                    ? "check_circle"
                    : "radio_button_unchecked"}
                </i>
                <span className="font-medium text-teal px-3">No End Date</span>
              </div>
            )}

            <div
              className={`h-10 bg-grey-darker text-xs flex flex-row pr-10 pl-6 py-2 ${
                this.props.dateRange ? "mt-3" : "mt-1"
              } rounded-b-lg`}
            >
              {!this.props.hideCancel && (
                <div className="flex-1 h-full flex justify-start items-center ml-1 text-white cursor-pointer hover:text-red-light ">
                  <span
                    onClick={() => this.clearAllData()}
                    className="text-xs font-medium"
                  >
                    Cancel
                  </span>{" "}
                </div>
              )}
              <div
                className={`flex-1 h-full flex justify-end items-center  ${
                  this.state.canSave
                    ? "text-white cursor-pointer hover:text-teal-light"
                    : "text-white opacity-50 cursor-not-allowed"
                } `}
              >
                <span
                  onClick={() => this.saveDate()}
                  className="text-xs font-medium"
                >
                  Apply
                </span>{" "}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
