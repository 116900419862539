import React, { Component } from "react";

import PropTypes from "prop-types";
import { Transition } from "react-transition-group";

import "./index.css";

import SearchField from "./SearchField";
import Accordion from "./Accordion";
import Pagination from "./Pagination";
import ClientSidePagination from "./ClientSidePagination";

import { TopRightButtons } from "./TopRightButtons";

import { LargeLoadingSpinner } from "components/LargeLoadingSpinner/LargeLoadingSpinner";
import { Tooltip } from "chart.js";

class Table extends Component {
  static propTypes = {
    /** The main object passed to the table that will contain everything below **/
    /** EXAMPLE: **/
    /**
      const tableConfig = {
        data: this.state.data,
        onDoubleClick: this.handleDoubleClick,
        onClick: this.handleOnClick,
        hiddenCols: ['address', 'email']
      }
    **/
    tableConfig: PropTypes.object,
    /** The data object to feed into the table to populate it, if no data then pass some like **/
    /** [{id: 1, name: "", address: "", email: ""}] or whatever your data may look like once its populated **/
    data: PropTypes.array,
    /** The name of the table **/
    name: PropTypes.string,
    /** Set a different height from the default **/
    height: PropTypes.string,
    /** Function to handle when a row is double clicked, will receive row data */
    onDoubleClick: PropTypes.func,
    /** Function to handle when a row is single clicked, will receive row data and focus row */
    onClick: PropTypes.func,
    /** A list of columns to hide. DO NOT hide id, this is ignored when building rows and is needed for some functions **/
    hiddenCols: PropTypes.array,
    /** A function that takes a list of selected rows **/
    selectRows: PropTypes.func,
    /** A function that takes an empty list or a list of all rows **/
    selectAllRows: PropTypes.func,
    /** A list of buttons to display on row hover **/
    /** They should look like this: **/
    /** const hoverRowButtons = [
      {id: 1, name: 'view', icon: "visibility", onClick: this.handleHoverRowButtonClicks},
      {id: 2, name: 'extend', icon: "library_add", onClick: this.handleHoverRowButtonClicks},
      {id: 3, name: 'edit', icon: "edit", onClick: this.handleHoverRowButtonClicks}
    ] **/
    /** an ID, name, icon and onClick function should be passed **/
    hoverRowButtons: PropTypes.array,
    /** Function to handle top add button on click **/
    topAddButton: PropTypes.func,
    /** Text that will be displayed in the tooltip of the topAddButton **/
    addText: PropTypes.string,
    /** Function to get data with pagination **/
    paginationGetData: PropTypes.func,
  };

  static defaultProps = {
    handleTargetUnitData: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      showOptions: false,
      hasThinLines: false,
      isExpanded: true,
      sortedData: [],
      sortedByAttrs: {},
      sortedByAttr: "",
      sortingData: false,
      hoverAddButton: false,
      toggleHoverRow: false,
      hoveredRow: {},
      activeRow: {},
      selectedRows: [],
      allSelectedRows: [],
      selectedPages: [],
      allSelectedRowsChecked: false,
      openFilter: false,
      sorting: null,
      hoveredHeader: null,
      showTooltipCell: null,
    };
  }

  componentDidMount() {
    let sortedByAttrs = {};
    Object.keys(this.props.tableConfig.data[0]).forEach(
      (header) => (sortedByAttrs = { ...sortedByAttrs, [header]: null })
    );
    this.setState({ sortedByAttrs });
    if (this.props.tableConfig && this.props.tableConfig.openExpanded) {
      this.setState({ isExpanded: true });
    }
  }

  minimizeTable = () => {
    if (this.state.openFilter) {
      this.setState({ openFilter: false });
      setTimeout(() => {
        this.setState((prevState) => ({
          isExpanded: !prevState.isExpanded,
        }));
      }, 200);
    } else {
      this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
    }
  };

  handleOpenFilter = () => {
    if (!this.state.isExpanded) {
      this.setState({ isExpanded: true });
      setTimeout(() => {
        this.setState((prevState) => ({
          openFilter: !prevState.openFilter,
        }));
      }, 300);
    } else {
      this.setState((prevState) => ({
        openFilter: !prevState.openFilter,
      }));
    }
  };
  handleChange = (event) => {
    this.setState({ searchValue: event.target.value, isExpanded: true });
  };

  circleButtonOnMouseEnter = () => {
    this.setState({ hoverAddButton: true });
  };
  circleButtonOnMouseLeave = () => {
    this.setState({ hoverAddButton: false });
  };

  rowOnMouseEnter = (row) => {
    this.setState({ toggleHoverRow: true, hoveredRow: row });
  };
  rowOnMouseLeave = () => {
    this.setState({ toggleHoverRow: false, hoveredRow: {} });
  };

  headerOnMouseEnter = (id) => {
    this.setState({ hoveredHeader: id });
  };
  headerOnMouseLeave = () => {
    this.setState({ hoveredHeader: null });
  };
  cellOnMouseEnter = (id) => {
    this.setState({ showTooltipCell: id });
  };
  cellOnMouseLeave = () => {
    this.setState({ showTooltipCell: null });
  };

  selectRows = (row) => {
    let selectedPages = this.props.tableConfig.selectedPages;
    let selectedRows = this.props.tableConfig.selectedRows;
    let foundPage =
      selectedPages &&
      selectedPages.filter(
        (selected) => selected.page === this.props.tableConfig.currentPage
      );
    let foundRows = selectedRows.filter(
      (selected) => selected.page === this.props.tableConfig.currentPage
    );
    let rowList = foundRows.length > 0 && foundRows[0].rows;
    if (this.props.tableConfig.singleSelectRow) {
      //single select
      if (foundRows.length > 0) {
        if (rowList.find((item) => item.id === row.id)) {
          selectedRows.splice(selectedRows.indexOf(foundRows[0]), 1);
          selectedPages.splice(selectedPages.indexOf(foundPage[0]), 1);
        } else {
          selectedRows.splice(0, 1);
          selectedRows.push({
            page: this.props.tableConfig.currentPage,
            rows: [row],
          });
        }
      } else {
        selectedRows.splice(0, 1);
        selectedRows.push({
          page: this.props.tableConfig.currentPage,
          rows: [row],
        });
      }
    } else if (foundRows.length > 0) {
      if (rowList.find((item) => item.id === row.id)) {
        if (rowList.length > 1) {
          rowList.splice(
            rowList.indexOf(
              rowList.find((selectedRow) => selectedRow.id === row.id)
            ),
            1
          );
        } else {
          selectedRows.splice(selectedRows.indexOf(foundRows[0]), 1);
          selectedPages.splice(selectedPages.indexOf(foundPage[0]), 1);
        }
      } else {
        rowList.push(row);
      }
    } else {
      selectedRows.push({
        page: this.props.tableConfig.currentPage,
        rows: [row],
      });
    }
    this.setState({ selectedRows, selectedPages }, () => {
      let mappedRows = [];
      this.props.tableConfig.selectedRows.forEach((item) => {
        item.rows.forEach((row) => mappedRows.push(row));
      });
      this.props.tableConfig.selectAllRows(
        selectedRows,
        selectedPages,
        mappedRows
      );
    });
  };

  selectAllRows = () => {
    let data = [...this.props.tableConfig.data];
    // let selectedPages = [];
    let selectedPages = [...this.props.tableConfig.selectedPages];
    let selectedRows = [...this.props.tableConfig.selectedRows];

    let foundPage = selectedPages.filter(
      (selected) => selected.page === this.props.tableConfig.currentPage
    );
    let foundRows = selectedRows.filter(
      (selected) => selected.page === this.props.tableConfig.currentPage
    );
    let rowList = foundRows.length > 0 && foundRows[0].rows;
    if (foundPage.length > 0) {
      selectedPages.splice(selectedPages.indexOf(foundPage[0]), 1);
      if (rowList.length > 0) {
        selectedRows.splice(selectedRows.indexOf(foundRows[0]), 1);
      }
    } else {
      selectedPages.push({ page: this.props.tableConfig.currentPage });
      if (rowList.length > 0) {
        foundRows[0].rows = data;
      } else {
        selectedRows.push({
          page: this.props.tableConfig.currentPage,
          rows: data,
        });
      }
    }
    this.setState({ selectedPages, selectedRows }, () => {
      let mappedRows = [];
      selectedRows.forEach((item) => {
        item.rows.forEach((row) => mappedRows.push(row));
      });
      this.props.tableConfig.selectAllRows(
        selectedRows,
        selectedPages,
        mappedRows
      );
    });
  };

  filterByStr = (list, str) => {
    return list.filter((row) => {
      return Object.keys(row).find((column) => {
        //check that the colun object doesn't contain hidden columns
        if (!this.props.tableConfig.hiddenCols.includes(column)) {
          //check if not object
          if (typeof row[column] !== "object") {
            return (
              row[column] &&
              row[column].toString().toLowerCase().includes(str.toLowerCase())
            );
          } else {
            //if object check for value prop

            return (
              row[column].value &&
              row[column].value
                .toString()
                .toLowerCase()
                .includes(str.toLowerCase())
            );
          }
        }
      });
    });
  };

  clearAllSelectedRowsAndPages = () => {
    this.setState({ selectedRows: [], allSelectedRows: [], selectedPages: [] });
    this.props.tableConfig.selectAllRows &&
      this.props.tableConfig.selectAllRows([]);
  };

  ascending = (attr) => {
    if (!this.props.tableConfig.hideSorting) {
      let sortedByAttrs = { ...this.state.sortedByAttrs };
      sortedByAttrs[attr] = "asc";
      Object.keys(sortedByAttrs).forEach((key) => {
        if (key !== attr) {
          sortedByAttrs[key] = null;
        }
      });
      this.setState(
        {
          sortingData: true,
          sortedByAttrs,
          sortedByAttr: attr,
        },
        () => {
          if (this.props.tableConfig.manual) {
            this.props.tableConfig.manualSorting("asc", attr);
          } else {
            this.clearAllSelectedRowsAndPages();
            this.props.tableConfig.paginationGetData(
              this.props.tableConfig.currentPage,
              this.props.tableConfig.rowSize,
              attr,
              "asc"
            );
          }
        }
      );
    } else {
      return;
    }
  };

  descending = (attr) => {
    if (!this.props.tableConfig.hideSorting) {
      let sortedByAttrs = { ...this.state.sortedByAttrs };
      sortedByAttrs[attr] = "desc";
      Object.keys(sortedByAttrs).forEach((key) => {
        if (key !== attr) {
          sortedByAttrs[key] = null;
        }
      });
      this.setState(
        {
          sortingData: true,
          sortedByAttr: attr,
          sortedByAttrs,
        },
        () => {
          if (this.props.tableConfig.manual) {
            this.props.tableConfig.manualSorting("desc", attr);
          } else {
            this.clearAllSelectedRowsAndPages();
            this.props.tableConfig.paginationGetData(
              this.props.tableConfig.currentPage,
              this.props.tableConfig.rowSize,
              attr,
              "desc"
            );
          }
        }
      );
    } else {
      return;
    }
  };

  withoutSorting = (attr) => {
    if (!this.props.tableConfig.hideSorting) {
      let sortedByAttrs = { ...this.state.sortedByAttrs };

      Object.keys(sortedByAttrs).forEach((key) => {
        sortedByAttrs[key] = null;
      });
      this.setState(
        {
          sortingData: false,
          sortedByAttr: attr,
          sortedByAttrs,
        },
        () => {
          if (this.props.tableConfig.manual) {
            this.props.tableConfig.manualSorting(null);

            // this.setState({ sortedData: this.props.tableConfig.data });
          } else {
            this.clearAllSelectedRowsAndPages();
            this.props.tableConfig.paginationGetData(
              this.props.tableConfig.currentPage,
              this.props.tableConfig.rowSize,
              null,
              null
            );
          }
        }
      );
    } else {
      return;
    }
  };

  handleSingleClick = (row) => {
    this.props.tableConfig.onClick(row);
    this.setState({ activeRow: row.id === this.state.activeRow.id ? "" : row });
  };

  render() {
    let foundPage = this.props.tableConfig.selectedPages
      ? this.props.tableConfig.selectedPages.filter(
          (selected) => selected.page === this.props.tableConfig.currentPage
        )
      : [];
    let foundRows = this.props.tableConfig.selectedRows
      ? this.props.tableConfig.selectedRows.filter(
          (selected) => selected.page === this.props.tableConfig.currentPage
        )
      : [];
    let rowList = foundRows.length > 0 && foundRows[0].rows;
    let dataToFilterBy = this.props.tableConfig.data;

    const rows = this.filterByStr(dataToFilterBy, this.state.searchValue).map(
      (row, index) => {
        let selected =
          (this.props.tableConfig.selectedRows &&
            this.props.tableConfig.selectedRows.length > 0 &&
            this.props.tableConfig.selectedRows.find(
              (selectedRow) => selectedRow.id === row.id
            )) ||
          (rowList.length > 0 &&
            rowList.find((selectedRow) => selectedRow.id === row.id))
            ? true
            : false;
        const cells = Object.keys(row).map((column, i) => {
          const isArray = Array.isArray(row[column]);
          const isObject = typeof row[column] === "object";
          let arrayCell = null;
          let objectCell = null;

          const buildArray = (col) => {
            let expandedRow = col
              .filter((val, i) => {
                return i !== 0;
              })
              .map((value, i) => {
                return (
                  <div className="py-1" key={`${i}-${value.id}`}>
                    {value.name && value.name.length > 12
                      ? value.name.slice(0, 12) + "..."
                      : value.name}
                  </div>
                );
              });
            const firstInArray = col[0];
            arrayCell = (
              <Accordion
                header={() => {
                  return <div>{firstInArray ? firstInArray.name : "-"}</div>;
                }}
              >
                {expandedRow}
              </Accordion>
            );
          };

          if (
            isObject &&
            row[column] &&
            (row[column].value || row[column].value === "")
          ) {
            if (row[column].isInput) {
              let product = this.props.tableConfig.data.find(
                (data) => data.id === row.id
              );

              objectCell = (
                <>
                  {row[column].requiresRrp && product.rrp < 1 && (
                    <span className="text-white absolute pl-2">
                      Missing RRP, can't calculate
                    </span>
                  )}
                  <input
                    id={column + "-" + row.id}
                    className={` outline-none border-1 text-left rounded-sm border-transparent bg-table-grey-editable-cell h-full w-full focus:border-teal focus:text-teal ${
                      !row[column].isSaved &&
                      row[column].isChanged &&
                      row[column].isBlurred &&
                      !row[column].error
                        ? "border-teal-dark text-teal-dark"
                        : row[column].error
                        ? "text-red-dark border-red-dark"
                        : "text-teal-dark"
                    }  px-2`}
                    type="number"
                    value={
                      row[column].requiresRrp && product.rrp < 1
                        ? ""
                        : row[column].value
                    }
                    disabled={
                      row[column].requiresRrp && product.rrp < 1 ? true : false
                    }
                    onMouseEnter={() => this.cellOnMouseEnter(row.id)}
                    onMouseLeave={() => this.cellOnMouseLeave()}
                    onBlur={() => {
                      if (row[column].isSaved) {
                        this.props.tableConfig.handleInputChangeOnBlur(
                          row.id,
                          column,
                          false
                        );
                      } else {
                        this.props.tableConfig.handleInputChangeOnBlur(
                          row.id,
                          column,
                          true
                        );
                      }
                    }}
                    onKeyPress={(event) => {
                      if (row[column].isChanged) {
                        if (event.which === 13 || event.keyCode === 13) {
                          this.props.tableConfig.handleInputSubmit(
                            row,
                            column,
                            row[column].value
                          );
                          document.getElementById(column + "-" + row.id).blur();
                        }
                      }
                    }}
                    onChange={(event) => {
                      this.props.tableConfig.handleInputChange(
                        event.target.value,
                        row.id,
                        column
                      );
                    }}
                  />
                </>
              );
            } else {
              objectCell = (
                <div className="table-cell font-light h-full w-full text-grey-darker xxl:text-xs text-xxs">
                  <div
                    onDoubleClick={() =>
                      this.props.tableConfig.onDoubleClick &&
                      this.props.tableConfig.onDoubleClick(row)
                    }
                    onClick={() =>
                      this.props.tableConfig.onClick &&
                      this.handleSingleClick(row)
                    }
                    className={`h-full flex items-center justify-start max-w-full min-w-full`}
                  >
                    <div className="w-full break-words text-left">
                      {Array.isArray(row[column].value)
                        ? buildArray(row[column].value)
                        : row[column].value}
                    </div>
                  </div>
                </div>
              );
            }
          }

          if (isArray && row[column] !== undefined && row[column].length > 0) {
            buildArray(row[column]);
          }

          let col = " ";

          if (arrayCell) {
            col = arrayCell;
          } else if (objectCell) {
            col = objectCell;
          } else {
            col = (
              <div className="h-full w-full flex items-center justify-start text-left px-2 py-1">
                {row[column]}
              </div>
            );
          }

          let lastCol =
            column === this.props.tableConfig.lastCol &&
            this.state.hoveredRow.id === row.id;

          let topRightButtonsAndSelected =
            this.props.tableConfig.topRightButtons &&
            this.props.tableConfig.selectedRows.length > 0;
          return (
            !this.props.tableConfig.hiddenCols.includes(column) && (
              <div
                className={`table-cell border-b-4 border-white font-light h-full text-grey-darker xxl:text-xs text-xxs align-top
            ${
              lastCol
                ? topRightButtonsAndSelected
                  ? ""
                  : "sticky pin-r z-10"
                : ""
            }`}
                key={`${i}-${row.id}`}
              >
                <div className="relative w-full h-full align-top flex justify-start items-center">
                  <div
                    onDoubleClick={() =>
                      this.props.tableConfig.onDoubleClick &&
                      this.props.tableConfig.onDoubleClick(row)
                    }
                    onClick={() =>
                      this.props.tableConfig.onClick &&
                      this.handleSingleClick(row)
                    }
                    className={`h-full flex items-center justify-start max-w-full min-w-full
                    ${
                      this.props.tableConfig.hoverRowButtons && lastCol
                        ? topRightButtonsAndSelected
                          ? ""
                          : "opacity-0"
                        : ""
                    }`}
                  >
                    {col ? (col.value !== undefined ? col.value : col) : " "}
                  </div>
                  {this.props.tableConfig.hoverRowButtons && lastCol ? (
                    topRightButtonsAndSelected ? null : (
                      <div className="absolute pin-r pin-t h-full hoverRowButtons rounded-l-sm bg-teal-dark text-white flex items-center justify-start px-6">
                        {this.props.tableConfig.hoverRowButtons.map(
                          (button) => {
                            return (
                              <i
                                key={button.id}
                                className="material-icons px-6 text-lg cursor-pointer opacity-75 hover:opacity-100"
                                onClick={() => button.onClick(button.icon, row)}
                              >
                                {button.icon}
                              </i>
                            );
                          }
                        )}
                      </div>
                    )
                  ) : null}
                </div>
              </div>
            )
          );
        });

        let foundRows =
          this.props.tableConfig.selectedRows &&
          this.props.tableConfig.selectedRows.filter(
            (selected) => selected.page === this.props.tableConfig.currentPage
          );

        return (
          <div
            key={row.id}
            className={`table-row xxl:h-10 h-8
          ${index % 2 !== 0 ? "bg-table-grey-editable-cell" : "bg-table-grey"}
            ${
              (selected || this.state.activeRow.id === row.id) &&
              !(
                row.dynamicPrice &&
                row.dynamicPrice.requiresRrp &&
                (row.rrp < 1 || row.rrp === null)
              )
                ? "bg-blue-lighter "
                : "hover:bg-blue-lightest"
            }
          `}
            onMouseEnter={() => this.rowOnMouseEnter(row)}
            onMouseLeave={() => this.rowOnMouseLeave()}
          >
            {this.props.tableConfig.selectRows && (
              <div
                className={`table-cell align-top h-full border-b-4 border-white  w-8 sticky pin-l ${
                  index % 2 !== 0
                    ? "bg-table-grey-editable-cell"
                    : "bg-table-grey"
                }
        ${
          this.state.activeRow.id === row.id
            ? "bg-blue-lighter "
            : this.state.hoveredRow.id === row.id &&
              !selected &&
              "bg-blue-lightest"
        } ${
                  selected &&
                  !(
                    row.dynamicPrice &&
                    row.dynamicPrice.requiresRrp &&
                    (row.rrp < 1 || row.rrp === null)
                  ) &&
                  "bg-blue-lighter "
                } z-12`}
              >
                <div className="h-full flex items-center px-2">
                  {row.dynamicPrice &&
                  row.dynamicPrice.requiresRrp &&
                  (row.rrp < 1 || row.rrp === null) ? (
                    " "
                  ) : (
                    <i
                      className={`material-icons xxl:text-xl text-lg cursor-pointer ${
                        selected
                          ? "text-teal-dark"
                          : "text-grey-light hover:text-teal"
                      }`}
                      onClick={() => this.selectRows(row)}
                    >
                      {selected ? "check_circle" : "radio_button_unchecked"}
                    </i>
                  )}
                </div>
              </div>
            )}
            {cells}
          </div>
        );
      }
    );

    const headers =
      this.props.tableConfig.data &&
      Object.keys(this.props.tableConfig.data[0]).map((header, i) => {
        let modHeader = header;
        for (var index = 0; index < header.length; index++) {
          if (/[A-Z\s]+/.test(header.charAt(index))) {
            modHeader = modHeader.replace(/([a-z])([A-Z])/g, "$1 $2");
            // modHeader = header.substr(0, index) + " " + header.substr(index);
          }
        }

        let headerSplit = modHeader.split("");
        headerSplit[0] = headerSplit[0].toUpperCase();
        headerSplit = headerSplit.join("");
        return (
          !this.props.tableConfig.hiddenCols.includes(header) && (
            <div
              className={` table-cell min-w-6 max-w-6 sticky pin-t align-top text-left z-12 font-sans text-grey-darker xxl:text-xs text-xxs font-normal  ${
                !this.props.tableConfig.data[0][header].hideSorting
                  ? "cursor-pointer hover:text-teal-dark"
                  : "cursor-default"
              }`}
              key={`${i} -
                ${
                  this.props.tableConfig.data[0].id
                    ? this.props.tableConfig.data[0].id
                    : i
                }
              `}
              onMouseEnter={() => this.headerOnMouseEnter(i)}
              onMouseLeave={() => this.headerOnMouseLeave()}
              onClick={() => {
                if (!this.props.tableConfig.data[0][header].hideSorting) {
                  if (this.state.sortedByAttrs[header] === null) {
                    this.ascending(header);
                  } else if (this.state.sortedByAttrs[header] === "asc") {
                    this.descending(header);
                  } else {
                    this.withoutSorting(header);
                  }
                }
              }}
            >
              <div className="w-full h-full flex flex-col bg-white py-2 pr-2">
                <div
                  className={` ${
                    !this.state.isExpanded ? "flex-1" : ""
                  } flex flex-row w-full justify-start align-top cells`}
                >
                  <div className="flex pl-2 break-words items-center">
                    {headerSplit}{" "}
                    <span>
                      {this.props.tableConfig.data[0][header].concatString &&
                        this.props.tableConfig.data[0][header].concatString}
                    </span>
                  </div>

                  {!this.props.tableConfig.hideSorting &&
                    !this.props.tableConfig.data[0][header].hideSorting && (
                      <div className="h-full flex justify-start pl-1">
                        {this.state.sortedByAttr === header ? (
                          <>
                            {this.state.sortedByAttrs[header] === "asc" ? (
                              <i className="material-icons text-lg">
                                arrow_drop_up
                              </i>
                            ) : null}
                            {this.state.sortedByAttrs[header] === "desc" ? (
                              <i className="material-icons text-lg">
                                arrow_drop_down
                              </i>
                            ) : null}
                            {!this.state.sortedByAttrs[header] ? (
                              <i className="material-icons text-lg">
                                swap_vert
                              </i>
                            ) : null}
                          </>
                        ) : (
                          <i className="material-icons text-lg">swap_vert</i>
                        )}
                      </div>
                    )}
                </div>

                {this.props.tableConfig.data[0][header].tooltipMessage &&
                  this.state.hoveredHeader === i && (
                    <div className="pl-2 flex-1 h-1 relative">
                      <div className="absolute mt-4 w-full">
                        <div className="relative w-full h-full bg-grey-darker py-2 text-white break-words">
                          <div className="absolute w-full flex justify-start pin-t -mt-2">
                            <div className="tooltip-table-triangle-top" />
                          </div>
                          {
                            this.props.tableConfig.data[0][header]
                              .tooltipMessage
                          }
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )
        );
      });

    return (
      <div className="max-w-full min-w-full flex flex-col bg-white relative">
        <div className="max-w-full min-w-full z-16">
          <div
            className={`w-full flex flex-row ${
              this.state.isExpanded ? "my-8 pl-2" : " pl-2 my-8"
            } justify-between`}
          >
            <div className="flex flex-row">
              {this.props.tableConfig.hideExpanding ? null : (
                <i
                  onClick={() => this.minimizeTable()}
                  className="material-icons self-center text-grey-darker cursor-pointer"
                >
                  {this.state.isExpanded
                    ? "keyboard_arrow_right"
                    : "keyboard_arrow_down"}
                </i>
              )}
              <div className="self-center pl-2 xxl:text-lg text-md font-sans text-grey-darker font-light">
                {this.props.tableConfig.name}
              </div>
              {this.props.tableConfig.filter ? (
                <i
                  className={`material-icons self-center ml-3 cursor-pointer text-lg ${
                    this.state.openFilter
                      ? "bg-teal-dark text-white p-1 rounded-full"
                      : "hover:text-teal-dark p-1"
                  }`}
                  onClick={this.handleOpenFilter}
                >
                  tune
                </i>
              ) : null}
              <div className="self-center">
                <SearchField
                  onChange={this.handleChange}
                  value={this.state.searchValue}
                />
              </div>

              {this.props.tableConfig.topAddButton && (
                <div className="relative pl-3 self-center">
                  <div
                    className="flex justify center cursor-pointer"
                    onClick={() => this.props.tableConfig.topAddButton()}
                    onMouseOver={this.circleButtonOnMouseEnter}
                    onMouseLeave={this.circleButtonOnMouseLeave}
                  >
                    <i className="material-icons self-center hover:text-teal xxl:text-2xl text-lg text-grey-darker">
                      add
                    </i>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-row">
              {this.props.tableConfig.topRightButtons &&
                this.props.tableConfig.selectedRows.length > 0 && (
                  <TopRightButtons
                    buttons={this.props.tableConfig.topRightButtons}
                    selectedRows={this.props.tableConfig.selectedRows}
                    clearData={this.clearAllSelectedRowsAndPages}
                  />
                )}
            </div>
          </div>
        </div>

        <Transition
          in={this.state.openFilter}
          key={1}
          timeout={{ enter: 30, exit: 300 }}
          unmountOnExit
        >
          {(status) => (
            <div
              className={`filter${this.props.tableConfig.name.replace(
                /\s/g,
                ""
              )}-${status} flex flex-row px-4`}
            >
              {this.props.children}
            </div>
          )}
        </Transition>
        <Transition
          in={this.state.isExpanded}
          key={2}
          timeout={{ enter: 30, exit: 300 }}
          unmountOnExit
        >
          {(status) => (
            <div
              className={`table-${status}${
                this.props.tableConfig.isTableLarge ? "-large" : ""
              } `}
            >
              <div className="z-11 table table-fixed w-full px-6 bg-white">
                <div
                  className={`block overflow-x-auto overflow-y-auto inner-table-${status}${
                    this.props.tableConfig.isTableLarge ? "-large" : ""
                  }  `}
                >
                  <div className="table w-full h-full">
                    <div className="table-row bg-white">
                      {this.props.tableConfig.selectAllRows && (
                        <div className="table-cell align-top z-14 sticky pin-t pin-l w-8 align-top">
                          <div className="h-full flex items-center px-2 bg-white py-2">
                            {!this.props.tableConfig.singleSelectRow && (
                              <i
                                className={`material-icons xxl:text-xl text-lg ${
                                  foundPage.length > 0
                                    ? "text-teal-dark cursor-pointer"
                                    : !this.props.tableConfig.data[0]
                                        .noTableData
                                    ? "text-grey-light hover:text-teal cursor-pointer"
                                    : "text-grey-light cursor-not-allowed"
                                } ${
                                  this.props.tableConfig.name ===
                                  "Price Campaigns"
                                    ? "invisible"
                                    : ""
                                }`}
                                onClick={() =>
                                  !this.props.tableConfig.data[0].noTableData &&
                                  this.selectAllRows()
                                }
                              >
                                {foundPage.length > 0
                                  ? "check_circle"
                                  : "radio_button_unchecked"}
                              </i>
                            )}
                          </div>
                        </div>
                      )}

                      {headers}
                    </div>
                    {!this.props.tableConfig.data[0].noTableData && rows}
                  </div>
                  {this.props.tableConfig.tableLoading &&
                    !this.props.tableConfig.manual && (
                      <div
                        className={`flex items-center justify-center ${
                          this.props.tableConfig.height
                            ? this.props.tableConfig.height
                            : "xxl:min-h-25 xxl:max-h-25 min-h-15 max-h-15"
                        }`}
                      >
                        {LargeLoadingSpinner}
                      </div>
                    )}
                  {this.props.tableConfig.noData &&
                    !this.props.tableConfig.manual && (
                      <div
                        className={`relative z-14 flex items-center justify-center w-full text-grey-darker font-light font-sans bg-white ${
                          this.props.tableConfig.height
                            ? this.props.tableConfig.height
                            : "xxl:min-h-25 xxl:max-h-25 min-h-15 max-h-15"
                        }`}
                      >
                        No Data Available
                      </div>
                    )}
                  {this.props.tableConfig.manual ? (
                    this.props.tableConfig.tableLoading ? (
                      <div
                        className={`flex items-center justify-center ${
                          this.props.tableConfig.height
                            ? this.props.tableConfig.height
                            : "xxl:min-h-25 xxl:max-h-25 min-h-15 max-h-15"
                        }`}
                      >
                        {LargeLoadingSpinner}
                      </div>
                    ) : this.props.tableConfig.manualError ? (
                      <div
                        className={`relative z-14 flex items-center  justify-center w-full text-grey-darker font-light font-sans bg-white ${
                          this.props.tableConfig.height
                            ? this.props.tableConfig.height
                            : "xxl:min-h-25 xxl:max-h-25 min-h-15 max-h-15"
                        }`}
                      >
                        {this.props.tableConfig.manualErrorMessage}
                      </div>
                    ) : this.props.tableConfig.noData ? (
                      <div
                        className={`relative z-14 flex items-center justify-center w-full text-grey-darker font-light font-sans bg-white ${
                          this.props.tableConfig.height
                            ? this.props.tableConfig.height
                            : "xxl:min-h-25 xxl:max-h-25 min-h-15 max-h-15"
                        }`}
                      >
                        No Data Available
                      </div>
                    ) : (
                      this.props.tableConfig.data[0].noTableData && (
                        <div
                          className={`relative z-14 flex items-center justify-center w-full text-grey-darker font-light font-sans bg-white ${
                            this.props.tableConfig.height
                              ? this.props.tableConfig.height
                              : "xxl:min-h-25 xxl:max-h-25 min-h-15 max-h-15"
                          }`}
                        >
                          {this.props.tableConfig.manualErrorMessage}
                        </div>
                      )
                    )
                  ) : null}
                </div>

                <div className={`pagination-${status} flex-1 flex items-end`}>
                  <div className="w-full bg-white flex flex-row relative">
                    {this.props.tableConfig.hasClientSidePagination && (
                      <ClientSidePagination
                        showPagination={
                          !this.props.tableConfig.noData &&
                          !this.props.tableConfig.tableLoading &&
                          this.props.tableConfig.dataLength > 0
                        }
                        getData={this.props.tableConfig.clientSidePagination}
                        setPageClientSide={
                          this.props.tableConfig.setPageClientSide
                        }
                        dataLength={this.props.tableConfig.dataLength}
                        totalPages={this.props.tableConfig.totalPages}
                        currentPage={this.props.tableConfig.currentPage}
                        rowSize={this.props.tableConfig.rowSize}
                        setRowRize={this.props.tableConfig.setRowRize}
                        clearData={this.clearAllSelectedRowsAndPages}
                      />
                    )}

                    {this.props.tableConfig.paginationGetData && (
                      <Pagination
                        showPagination={
                          !this.props.tableConfig.noData &&
                          !this.props.tableConfig.tableLoading &&
                          this.props.tableConfig.paginationGetData &&
                          this.props.tableConfig.dataLength &&
                          this.props.tableConfig.totalPages
                        }
                        getData={this.props.tableConfig.paginationGetData}
                        dataLength={this.props.tableConfig.dataLength}
                        totalPages={this.props.tableConfig.totalPages}
                        currentPage={this.props.tableConfig.currentPage}
                        rowSize={this.props.tableConfig.rowSize}
                        attr={this.props.tableConfig.attr}
                        direction={this.props.tableConfig.direction}
                        clearData={this.clearAllSelectedRowsAndPages}
                      />
                    )}
                    {this.props.tableConfig.saveButton && (
                      <div className="p-2 w-full flex justify-end">
                        {this.props.tableConfig.saveButton}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Transition>
        {/* )} */}
      </div>
    );
  }
}

export default Table;
