import * as firebase from "firebase/app";
import "firebase/messaging";
const initializedFirebaseApp = firebase.initializeApp({
  // Project Settings => Add Firebase to your web app

  authDomain: "grand-suite.firebaseapp.com",
  databaseURL: "https://grand-suite.firebaseio.com",
  storageBucket: "grand-suite.appspot.com",
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  projectId: "grand-suite",
  messagingSenderId: process.env.REACT_APP_FCM_MSG_ID,
  appId: process.env.REACT_APP_FCM_APP_KEY
});
let messaging = null;

if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(process.env.REACT_APP_FCM_VAPID_KEY);
}

export { messaging };
