import React, { Component } from "react";
import { connect } from "react-redux";
import { TooltipLeft } from "components/Tooltips";
import moment from "moment";

import ViewContainer from "containers/ViewContainer";
import Table from "components/Table";
import { CircleButton } from "components/CircleButton";
import { openFormpanel, closeFormpanel } from "ducks/formpanel";
import { openModal, closeModal } from "ducks/modal";

import {
  // fetchAllGroups,
  getConfig,
  selectedGroup,
  getAllGroupsNoPagination,
  setCurretPageClientSide,
  setRowRizeGroup,
  sortGroupData,
} from "ducks/groups";
import { getAllTags, getCommonTagsByProducts } from "ducks/tags";
import { getPricePositionChart } from "ducks/charts";
import {
  getProductData,
  filterProducts,
  getProductsByGroup,
  getProductbyId,
  getCategoriesByMarket,
  getBrandsByMarket,
  handleProductsInputChange,
  updateProductTableInputsOnBlur,
  submitTableInput,
  setCurrentlySelectedProduct,
} from "ducks/products";

import * as FORM_TYPE from "utils/form-types";
import * as FORM_ACTION from "utils/form-actions";
import * as MODAL_TYPE from "utils/modal-types";

import ProductFilter from "containers/Filters/ProductFilter";
import { filterNavItems } from "ducks/sidepanel";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topVisible: true,
      groupData: [
        {
          noTableData: true,
          name: "",
          description: "",
          created: "",
          createdBy: "",
          lastModifiedBy: "",
        },
      ],
      productData: [
        {
          noTableData: true,
          name: "",
          articleNumber: "",
          brand: "",
          category: "",
          price: "",
          ["Margin(%)"]: " ",
          ["Lowest Margin(%)"]: {
            hideSorting: true,
            value: " ",
          },
          purchasePrice: "",
          campaigns: "",
          tags: "",
        },
      ],
      selectedProducts: [],
      holdSelectedProducts: [],
      holdSelectedPageProducts: [],
      hoverAddButtonGroup: false,
    };
  }

  componentDidMount() {
    this.props.getAllTags();
    this.props.getCategoriesByMarket();
    this.props.getBrandsByMarket();
    this.props.getConfig();
    this.props.getAllGroupsNoPagination(1, 20, null, null);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.brands !== prevProps.brands) {
  //     this.props.getProductData(1, 20, null, null);
  //   }
  // }

  getAllGroups = (page, rowSize, attr, direction) => {
    this.props.getAllGroupsNoPagination(page, rowSize, attr, direction);

    // this.props.fetchAllGroups(page, rowSize, attr, direction);
  };

  componentWillUnmount() {
    this.props.closeFormpanel();
    this.props.closeModal();
  }

  selectProductRows = (rows, page, row) => {
    this.setState({
      holdSelectedProducts: rows,
      holdSelectedPageProducts: page,
      selectedProducts: row,
    });
  };

  openTagModal = (data) => {
    this.props.getAllTags();
    this.props.getCommonTagsByProducts(this.state.selectedProducts);
    this.props.openModal(
      this.state.selectedProducts,
      MODAL_TYPE.TAGS,
      MODAL_TYPE.LARGE
    );
  };

  openTableInputSingleSubmitModal = (row, col, value) => {
    this.props.openModal(
      { row, col, value },
      MODAL_TYPE.TABLE_INPUT_SUBMIT,
      MODAL_TYPE.SMALL
    );
  };

  handleHoverRowButtonClicks = (icon, data) => {
    switch (icon) {
      case "visibility":
      case "edit":
      case "local_offer":
        this.openTagModal(data);
      default:
    }
  };

  onDoubleClickGroup = (row) => {
    // this.props.getAllGroupsNoPagination(1, 20, null, null);
    this.props.getCategoriesByMarket();
    this.props.getBrandsByMarket();

    this.props.openFormpanel(FORM_ACTION.STATE_EDIT, row, FORM_TYPE.GROUPLIST);
  };

  onDoubleClickProducts = (row) => {
    if (localStorage.getItem("market")) {
      this.props.setCurrentlySelectedProduct(row.id);
      this.props.openFormpanel(FORM_ACTION.STATE_EDIT, row, FORM_TYPE.PRODUCTS);
    }
  };

  topAddButtonGroup = () => {
    this.props.openFormpanel(FORM_ACTION.STATE_CREATE, [], FORM_TYPE.GROUPLIST);
  };

  topAddButtonProducts = () => {
    this.props.openFormpanel(FORM_ACTION.STATE_CREATE, [], FORM_TYPE.PRODUCTS);
  };

  paginationProductsByGroupId = (page, rowSize, attr, direction) => {
    this.props.getProductsByGroup(
      this.props.selectedGroupId,
      page,
      rowSize,
      attr,
      direction
    );
  };

  onClickGroups = (row) => {
    this.setState({ holdSelectedProducts: [], holdSelectedPageProducts: [] });
    if (row.id === this.props.selectedGroupId) {
      this.props.getProductData(1, this.props.rowSizeProduct, null, null);
      this.props.selectedGroup(null);
    } else {
      this.props.getProductsByGroup(
        row.id,
        1,
        this.props.rowSizeProduct,
        null,
        null
      );
      this.props.selectedGroup(row.id);
    }
  };

  getProductsData = (page, rowSize, attr, direction) => {
    if (this.props.isFiltered) {
      this.props.filterProducts(
        page,
        rowSize,
        this.props.idList,
        attr,
        direction
      );
    } else {
      this.props.getProductData(page, rowSize, attr, direction);
    }
  };

  circleButtonOnMouseEnterGroup = () => {
    this.setState({ hoverAddButtonGroup: true });
  };
  circleButtonOnMouseLeaveGroup = () => {
    this.setState({ hoverAddButtonGroup: false });
  };

  clearProductSelections = () => {
    this.setState({
      selectedProducts: [],
      holdSelectedProducts: [],
      holdSelectedPageProducts: [],
    });
  };

  setCurrentPageGroup = (page) => {
    this.props.setCurretPageClientSide(page);
  };

  setCurrentRowSizeGroup = (row) => {
    this.props.setRowRizeGroup(row, this.props.groups);
  };

  manualSorting = (direction, attr) => {
    this.props.sortGroupData(
      this.props.groupPaginationData,
      attr,
      direction,
      this.props.rowSize
    );
  };

  render() {
    const topRightButtonsProduct = [
      {
        id: 1,
        icon: "close",
        text: "clear all",
        onClick: () => this.setState({ selectedProducts: [] }),
      },
      {
        id: 2,
        icon: "local_offer",
        text: "tag",
        onClick: this.handleHoverRowButtonClicks,
      },
    ];
    let parsedGroupData =
      this.props.groupPaginationData &&
      this.props.groupPaginationData.length > 0 &&
      this.props.groupPaginationData.map((item) => {
        return {
          name: item.name,
          description: item.description,
          created: moment(item.created).local().format("YYYY-MM-DD"),
          createdBy: item.createdBy,
          expressions: item.expressions,
          firstParent: item.firstParent,
          secondParent: item.secondParent,
          id: item.id,
          logicalOperator: item.logicalOperator,
          // created: item.created,
          updated: item.updated,
        };
      });
    const tableConfigGroups = {
      data:
        this.props.groups.length > 0 ? parsedGroupData : this.state.groupData,
      tableLoading: this.props.groupsTableLoading,
      noData: this.props.groupsNoData,
      name: "Groups",
      onDoubleClick: this.onDoubleClickGroup,
      onClick: this.onClickGroups,
      hiddenCols: [
        "id",
        "logicalOperator",
        "expressions",
        "firstParent",
        "secondParent",
        "noTableData",
        // "created",
        "updated",
      ],
      manual: true,
      // paginationGetData: this.getAllGroups,
      // dataLength: this.props.totalGroups,
      // currentPage: this.props.currentPageGroups,
      // rowSize: this.props.rowSizeGroups,
      // totalPages: this.props.totalGroupsPages,
      // attr: this.props.groupsSortingAttr,
      // direction: this.props.groupsSortingDirection,
      isTableLarge: this.props.isTableLarge,
      manualSorting: this.manualSorting,

      hasClientSidePagination: true,
      clientSidePagination: this.props.groupPaginationData,
      dataLength: this.props.groups.length,
      currentPage: this.props.currentPage,
      setPageClientSide: this.setCurrentPageGroup,
      rowSize: this.props.rowSize,
      totalPages: this.props.groupPages.length,
      setRowRize: this.setCurrentRowSizeGroup,
    };

    let productDataKeys = [
      "articleNumber",
      "brand",
      "campaigns",
      "category",
      "id",
      "price",
      "productName",
      "tags",
      "updated",
      "exists",
      "purchasePrice",
      "purchasePriceScd",
      "recommendedRetailPriceScd",
      "salesPriceScd",
      "parentId",
      "mongoId",
      "extId",
      "created",
      "channel",
      "name",
      "margin",
      "lowestMargin",
      "matchStatus",
    ];

    let parsedProductData =
      this.props.products &&
      this.props.products.map((item) => {
        let mainData = {
          mongoId: item.mongoId,
          id: item.id,
          name: item.productName
            ? item.productName
            : item.name
            ? item.name
            : " ",
          articleNumber: item.articleNumber ? item.articleNumber : " ",
          brand: item.brand
            ? typeof item.brand === "object"
              ? item.brand.name
              : item.brand
            : "-",
          category: item.category
            ? typeof item.category === "object"
              ? item.category.name
              : item.category
            : "-",
          price: item.price,
          margin: item.margin,
          lowestMargin: {
            hideSorting: true,
            value: item.lowestMargin ? item.lowestMargin : " ",
            concatString: "(%)",
          },
          purchasePrice: item.purchasePrice
            ? Math.round(item.purchasePrice * 100) / 100
            : "",
          campaigns: item.campaigns ? item.campaigns : [],
          matchStatus: item.matchStatus ? item.matchStatus : " ",
          tags: item.tags ? item.tags : [],
        };
        Object.keys(item).map((key) => {
          if (!productDataKeys.includes(key)) {
            return (mainData = {
              ...mainData,
              [key]: { hideSorting: true, value: item[key] ? item[key] : " " }, //empty string needs to contain a space or it will return null and break
            });
          }
        });
        return mainData;
      });

    const unsavedProducts =
      parsedProductData.filter(
        (product) =>
          product.price.isChanged &&
          !product.price.isSaved &&
          product.price.isBlurred
      ).length +
      parsedProductData.filter(
        (product) =>
          product.margin.isChanged &&
          !product.margin.isSaved &&
          product.margin.isBlurred
      ).length;

    const tableConfigProducts = {
      data:
        parsedProductData.length > 0
          ? parsedProductData
          : this.state.productData,

      name: "Products",
      tableLoading: this.props.productsTableLoading,
      noData: this.props.productsNoData,
      onDoubleClick: this.onDoubleClickProducts,
      onClick: this.onClick,
      hiddenCols: [
        "id",
        "noTableData",
        "parentId",
        "mongoId",
        "extId",
        "created",
        "channel",
        "updated",
        "existz",
        "campaigns",
        "purchasePriceScd",
        "recommendedRetailPriceScd",
        "salesPriceScd",
      ],
      topRightButtons: topRightButtonsProduct,
      filter: true,
      selectRows: this.selectProductRows,
      selectAllRows: this.selectProductRows,
      selectedRows: this.state.holdSelectedProducts,
      selectedPages: this.state.holdSelectedPageProducts
        ? this.state.holdSelectedPageProducts
        : [],
      paginationGetData: this.props.showAllProducts
        ? this.getProductsData
        : this.paginationProductsByGroupId,
      dataLength: this.props.totalProducts,
      currentPage: this.props.currentPageProduct,
      rowSize: this.props.rowSizeProduct,
      totalPages: this.props.totalProductPages,
      attr: this.props.productSortingAttr,
      direction: this.props.productSortingDirection,
      isTableLarge: this.props.isTableLarge,
      handleInputSubmit: this.openTableInputSingleSubmitModal,
      handleInputChange: this.props.handleProductsInputChange,
      handleInputChangeOnBlur: this.props.updateProductTableInputsOnBlur,
      unSavedInputs: unsavedProducts,
    };

    return (
      <ViewContainer
        hasPageButtons={true}
        topVisible={false}
        handleToggleTop={this.handleToggleTop}
      >
        <div className="mb-4 flex flex-row w-1/4">
          <div className="xxl:h-10 h-8">
            <CircleButton
              onClick={this.topAddButtonGroup}
              canUse={true}
              active="false"
              iconOne="add"
              onMouseEnter={this.circleButtonOnMouseEnterGroup}
              onMouseLeave={this.circleButtonOnMouseLeaveGroup}
            />
          </div>
          <div className="ml-4">
            {this.state.hoverAddButtonGroup && (
              <div className="h-6 xxl:pt-1">
                <TooltipLeft text="CREATE GROUP" right={true} />
              </div>
            )}
          </div>
        </div>
        <div className="mb-4">
          <Table tableConfig={tableConfigGroups} />
        </div>
        <div className="relative mb-8">
          <Table tableConfig={tableConfigProducts}>
            <ProductFilter clearSelections={this.clearProductSelections} />
          </Table>
        </div>
      </ViewContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groups: state.groups.groupsNoPag,
    products: state.products.products,
    totalGroup: state.groups.totalGroup,
    selectedGroupId: state.groups.selectedGroup,
    groupsNoData: state.groups.groupsNoData,
    groupPaginationData: state.groups.groupPaginationData,
    currentPage: state.groups.currentPage,
    rowSize: state.groups.rowSize,
    groupPages: state.groups.groupPages,

    // currentPageGroups: state.tablePagination.groupPage,
    // rowSizeGroups: state.tablePagination.groupRowSize,
    // totalGroupsPages: state.tablePagination.groupTotalPages,
    // groupsSortingAttr: state.tablePagination.groupSortingAttr,
    // groupsSortingDirection: state.tablePagination.groupSortingDirection,
    groupsTableLoading: state.groups.groupsTableLoading,
    groupsSearchData: state.groups.groupsNoPag,
    productsByGroup: state.products.productsByGroup,

    isFiltered: state.products.isFiltered,
    idList: state.products.idList,
    currentPageProduct: state.tablePagination.productPage,
    rowSizeProduct: state.tablePagination.productRowSize,
    productSortingAttr: state.tablePagination.productSortingAttr,
    productSortingDirection: state.tablePagination.productSortingDirection,
    totalProductPages: state.tablePagination.productTotalPages,
    showAllProducts: state.products.showAllProducts,
    productsTableLoading: state.products.productsTableLoading,
    totalProducts: state.products.totalProducts,
    productsNoData: state.products.productsNoData,

    isTableLarge: state.sidepanel.isTableLarge,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConfig: () => dispatch(getConfig()),
    getProductData: (page, rowSize, attr, direction) =>
      dispatch(getProductData(page, rowSize, attr, direction)),
    openFormpanel: (formState, targetUnitData, formType) =>
      dispatch(openFormpanel(formState, targetUnitData, formType)),
    closeFormpanel: () => dispatch(closeFormpanel()),
    openModal: (targetUnitData, modalType, modalSize) =>
      dispatch(openModal(targetUnitData, modalType, modalSize)),
    closeModal: () => dispatch(closeModal()),
    getAllTags: () => dispatch(getAllTags()),
    getCommonTagsByProducts: (products) =>
      dispatch(getCommonTagsByProducts(products)),
    filterProducts: (page, rowSize, idList, attr, sorting) =>
      dispatch(filterProducts(page, rowSize, idList, attr, sorting)),
    // fetchAllGroups: (page, rowSize, attr, direction) =>
    //   dispatch(fetchAllGroups(page, rowSize, attr, direction)),
    getProductsByGroup: (id, page, rowSize, attr, direction) =>
      dispatch(getProductsByGroup(id, page, rowSize, attr, direction)),
    selectedGroup: (id) => dispatch(selectedGroup(id)),
    getProductbyId: (id) => dispatch(getProductbyId(id)),
    getCategoriesByMarket: () => dispatch(getCategoriesByMarket()),
    getBrandsByMarket: () => dispatch(getBrandsByMarket()),
    getPricePositionChart: (id) => dispatch(getPricePositionChart(id)),
    getAllGroupsNoPagination: (page, rowSize, attr, direction) =>
      dispatch(getAllGroupsNoPagination(page, rowSize, attr, direction)),
    submitTableInput: (id, col, value) => {
      dispatch(submitTableInput(id, col, value));
    },
    handleProductsInputChange: (value, id, col) =>
      dispatch(handleProductsInputChange(value, id, col)),
    updateProductTableInputsOnBlur: (id, col, value) =>
      dispatch(updateProductTableInputsOnBlur(id, col, value)),
    setCurrentlySelectedProduct: (id) =>
      dispatch(setCurrentlySelectedProduct(id)),
    setCurretPageClientSide: (page) => dispatch(setCurretPageClientSide(page)),
    setRowRizeGroup: (rowSize, data) =>
      dispatch(setRowRizeGroup(rowSize, data)),
    sortGroupData: (data, attr, dir, row) =>
      dispatch(sortGroupData(data, attr, dir, row)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
