import axios from "axios";
import { buildQueryString } from "utils/general";
import { handleError } from "ducks/general";
import { updateProductTagList, removeFromProductTagList } from "ducks/products";
import {
  updatePricePositionTagList,
  removeFromPricePositionTagList,
} from "ducks/pricePosition";

const SET_ALL_TAGS = "SET_ALL_TAGS";
const SET_TAGS_BY_PRODUCT = "SET_TAGS_BY_PRODUCT";
const UPDATE_TAG_LISTS = "UPDATE_TAG_LISTS";
const REMOVE_TAG_FROM_TAGS_BY_PRODUCTS = "REMOVE_TAG_FROM_TAGS_BY_PRODUCTS";
const SET_EXISTING_TAG_ERROR = "SET_EXISTING_TAG_ERROR";

//GET EXISTING TAGS
export const getAllTags = () => {
  return (dispatch) => {
    dispatch(setAllTags([]));
    return axios
      .get(process.env.REACT_APP_API_HOST + "/product/tags")
      .then((res) => {
        dispatch(setAllTags(res.data));
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

//GET COMMON TAGS FOR PRODUCTS
export const getCommonTagsByProducts = (products) => {
  return (dispatch) => {
    const productQueryString = buildQueryString("productId=", products);
    return axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/product/markets/" +
          localStorage.getItem("market") +
          "/products/tags?" +
          productQueryString
      )
      .then((res) => {
        dispatch(setTagsByProduct(res.data));
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

//CREATE A NEW TAG
export const saveTag = (name, products) => {
  return (dispatch) => {
    return axios
      .post(process.env.REACT_APP_API_HOST + "/product/tags", {
        name: name,
      })
      .then((res) => {
        dispatch(addTag(res.data, products));
      })
      .catch((err) => {
        if (err.response.status === 409) {
          dispatch(setExistingTagError(name));
        }
      });
  };
};

//CONNECT AN EXISTING TAG TO A PRODUCT
export const addTag = (tag, products) => {
  return (dispatch) => {
    const productQueryString = buildQueryString("productId=", products);
    return axios
      .put(
        process.env.REACT_APP_API_HOST +
          "/product/markets/" +
          localStorage.getItem("market") +
          "/products/tags/" +
          tag.id +
          "?" +
          productQueryString
      )
      .then((res) => {
        dispatch(updateTagLists(tag));
        dispatch(updateProductTagList(tag, products));
        dispatch(updatePricePositionTagList(tag, products));
      })
      .catch((err) => dispatch(handleError(err)));
  };
};

//REMOVE A TAG FROM A PRODUCT
export const removeTag = (tag, products) => {
  return (dispatch) => {
    const productQueryString = buildQueryString("productId=", products);
    return axios
      .delete(
        process.env.REACT_APP_API_HOST +
          "/product/markets/" +
          localStorage.getItem("market") +
          "/products/tags/" +
          tag.id +
          "?" +
          productQueryString
      )
      .then((res) => {
        dispatch(getAllTags());
        dispatch(removeTagFromTagsByProduct(tag));
        dispatch(removeFromProductTagList(tag, products));
        dispatch(removeFromPricePositionTagList(tag, products));
        // dispatch(updateProductTagList(tag));
      })
      .catch((err) => dispatch(handleError(err)));
  };
};

const updateTagLists = (tag) => {
  return {
    type: UPDATE_TAG_LISTS,
    tagList: tag,
    tagsByProduct: tag,
  };
};

const setAllTags = (data) => {
  return {
    type: SET_ALL_TAGS,
    tagList: data,
  };
};

const setTagsByProduct = (data) => {
  return {
    type: SET_TAGS_BY_PRODUCT,
    tagsByProduct: data,
  };
};

const removeTagFromTagsByProduct = (tag) => {
  return {
    type: REMOVE_TAG_FROM_TAGS_BY_PRODUCTS,
    tagsByProduct: tag,
  };
};

export const setExistingTagError = (tag) => {
  return {
    type: SET_EXISTING_TAG_ERROR,
    existingTag: tag,
  };
};

const initialState = {
  tagList: [],
  tagsByProduct: [],
  existingTag: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ALL_TAGS:
      return {
        ...state,
        tagList: action.tagList.filter((tag) => tag.type !== "Auto"),
      };
    case SET_TAGS_BY_PRODUCT:
      return {
        ...state,
        tagsByProduct: action.tagsByProduct,
      };
    case UPDATE_TAG_LISTS:
      let tagListCheck = state.tagList.includes(action.tagList);
      let newTagList = [];
      if (tagListCheck) {
        newTagList = state.tagList;
      } else {
        newTagList = [{ ...action.tagList }, ...state.tagList];
      }
      return {
        ...state,
        tagList: newTagList,
        tagsByProduct: [{ ...action.tagsByProduct }, ...state.tagsByProduct],
      };
    case REMOVE_TAG_FROM_TAGS_BY_PRODUCTS:
      let updatedTagsByProductList = [...state.tagsByProduct];
      updatedTagsByProductList.splice(
        state.tagsByProduct.indexOf(action.tagsByProduct),
        1
      );
      return {
        ...state,
        tagsByProduct: updatedTagsByProductList,
      };
    case SET_EXISTING_TAG_ERROR:
      return {
        ...state,
        existingTag: action.existingTag,
      };
    default:
      return state;
  }
}
