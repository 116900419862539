import React, { Component } from "react";
import { connect } from "react-redux";

import ViewContainer from "containers/ViewContainer";
import Table from "components/Table";

import ProductPerformanceAnalyticsFilter from "containers/Filters/ProductPerformanceAnalyticsFilter";

import {
  getProductPerformance,
  clearAllProductsPerformance,
  setCurretPageClientSide,
  setRowRize,
} from "ducks/productPerformanceAnalytics";

class ProductPerformanceAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topVisible: true,
      tableData: [
        {
          id: 1,
          noTableData: true,
          brand: "",
          viewsLatest: "",
          viewsPrev: "",
          viewsRel: "",
          convLatest: "",
          convPrev: "",
          convRel: "",
          revenueLatest: "",
          revenuePrev: "",
          revenueRel: "",
        },
      ],
    };
  }

  componentWillUnmount() {
    this.props.clearAllProductsPerformance();
  }

  getProductData = () => {
    this.props.getProductPerformance(
      this.props.queryString,
      this.props.rowSize
    );
  };

  setCurrentPage = (page) => {
    this.props.setCurretPageClientSide(page);
  };
  setCurrentRowSize = (row) => {
    this.props.setRowRize(row, this.props.data);
  };

  render() {
    let parsedProductPerformanceAnalyticsData = [];
    parsedProductPerformanceAnalyticsData =
      this.props.productPerformancePaginationData &&
      this.props.productPerformancePaginationData.length > 0 &&
      this.props.productPerformancePaginationData.map((item) => {
        return {
          id: item.id,
          [item.attribute]: item.attributeName ? item.attributeName : " ",
          viewsLatest: item.latest ? item.latest.views : "",
          viewsPrev: item.previous ? item.previous.views : "",
          viewsRel: item.relative ? item.relative.views : "",
          convLatest: item.latest ? item.latest.conversion.toFixed(2) : "",
          convPrev: item.previous ? item.previous.conversion.toFixed(2) : "",
          convRel: item.relative ? item.relative.conversion.toFixed(2) : "",
          revenueLatest: item.latest ? item.latest.revenue.toFixed(2) : "",
          revenuePrev: item.previous ? item.previous.revenue.toFixed(2) : "",
          revenueRel: item.relative ? item.relative.revenue.toFixed(2) : "",
        };
      });

    const tableConfig = {
      data:
        this.props.data.length < 1
          ? this.state.tableData
          : parsedProductPerformanceAnalyticsData,
      tableLoading: this.props.loadingProductPerformance,
      noData: this.props.noDataProductPerformance,
      name: "Product Performance Analytics",
      hiddenCols: ["noTableData", "id"],
      filter: true,
      hideSorting: true,
      isTableLarge: this.props.isTableLarge,
      hasClientSidePagination: true,
      clientSidePagination: this.props.productPerformancePaginationData,
      dataLength: this.props.data.length,
      currentPage: this.props.currentPage,
      setPageClientSide: this.setCurrentPage,
      rowSize: this.props.rowSize,
      totalPages: this.props.productPerformancePages.length,
      setRowRize: this.setCurrentRowSize,
    };
    return (
      <ViewContainer hasPageButtons={true} topVisible={this.state.topVisible}>
        <div className="mt-10">
          <Table tableConfig={tableConfig}>
            <ProductPerformanceAnalyticsFilter />
          </Table>
        </div>
      </ViewContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.productPerformanceAnalytics.productPerformanceData,
    queryString: state.productPerformanceAnalytics.queryString,
    noDataProductPerformance:
      state.productPerformanceAnalytics.productPerformanceNoData,
    loadingProductPerformance:
      state.productPerformanceAnalytics.productPerformanceLoading,
    isTableLarge: state.sidepanel.isTableLarge,
    productPerformancePages:
      state.productPerformanceAnalytics.productPerformancePages,
    totalProductPerformance:
      state.productPerformanceAnalytics.totalproductPerformance,
    rowSize: state.productPerformanceAnalytics.rowSize,
    productPerformancePaginationData:
      state.productPerformanceAnalytics.productPerformancePaginationData,
    currentPage: state.productPerformanceAnalytics.currentPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductPerformance: (data, rowSize) =>
      dispatch(getProductPerformance(data, rowSize)),
    clearAllProductsPerformance: () => dispatch(clearAllProductsPerformance()),
    setCurretPageClientSide: (page) => dispatch(setCurretPageClientSide(page)),
    setRowRize: (row, data) => dispatch(setRowRize(row, data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductPerformanceAnalytics);
