export const logout = () => {
  //TODO: CALL TO BACKEND TO LOGOUT AND DELETE COOKIES
  localStorage.removeItem("user");
  localStorage.removeItem("name");
  localStorage.removeItem("market");
  localStorage.removeItem("domain");
  localStorage.removeItem("country");
  localStorage.removeItem("feature");
  localStorage.removeItem("channel");
  localStorage.removeItem("channels");
  localStorage.clear()
  window.location = "https://login.granditude.com/?logout=true";
  // window.location = "https://localhost:3001/?logout=true";
};
