import React, { Component } from "react";
import { connect } from "react-redux";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import SingleSelectDropdown from "components/SingleSelectDropdown";
import { InputText } from "components/InputText";
import InputNumber from "components/InputNumber";

import { TooltipRight } from "components/Tooltips";
import {
  getBrandsByMarket,
  getCategoriesByMarket,
  filterProducts,
  setFiltered,
  getProductData,
  filterBySkuProducts,
  updateProductFilterLockStatus,
} from "ducks/products";
import { getAllTags } from "ducks/tags";
import { getAllCampaignsDropdown } from "ducks/campaigns";
import { getAllCompetitors } from "ducks/priceRule";
import { getExportProducts } from "ducks/exportData";
import { SmallLoadingSpinner } from "components/SmallLoadingSpinner/SmallLoadingSpinner";

class ProductFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSave: false,
      category: [],
      brand: [],
      campaign: [],
      tags: [],
      competitor: [],
      hoverTooltip: false,
      selectedTag: [],
      searchedTags: [],
      truncate: true,
      searching: false,
      categoryOptions: [],
      brandOptions: [],
      campaignOptions: [],
      competitorOptions: [],
      articleNumber: "",
      disableDropdown: false,
      disableTextInput: false,
      showChannelSelection: false,
      channelOptions: [],
      selectedLockOption: "all",
      purchasePriceOperatorOptions: [
        { id: 1, name: "<" },
        { id: 2, name: "<=" },
        { id: 3, name: "=" },
        { id: 4, name: ">=" },
        { id: 5, name: ">" },
      ],
      approvedOptions: [
        { id: 1, name: "Approved" },
        { id: 2, name: "Reapprove" },
        { id: 3, name: "Not Approved" },
      ],
      purchasePriceOperator: {},
      purchasePriceValue: "",
      salesPriceOperator: {},
      salesPriceValue: "",
      purchasePriceOperatorError: false,
      purchasePriceValueError: false,
      salesPriceOperatorError: false,
      salesPriceValueError: false,
      approvedStatus: {},
    };
  }

  componentDidMount() {
    this.props.getBrandsByMarket();
    this.props.getCategoriesByMarket();
    this.props.getAllTags();
    this.props.getAllCampaignsDropdown();
    this.props.getAllCompetitors();
    this.setState({ channelOptions: this.props.channelOptions });
  }

  handleSelected = (name, selected) => {
    if (name === "purchasePriceOperator") {
      this.setState({ purchasePriceOperatorError: false });
    }
    if (name === "salesPriceOperator") {
      this.setState({ salesPriceOperatorError: false });
    }
    if (
      Array.isArray(selected) &&
      selected.find((selected) => selected.name == "Select All") !== undefined
    ) {
      if (selected.map((select) => select.name.includes("Select All"))) {
        if (
          name == "category" &&
          this.state.category.length != this.props.categories.length
        ) {
          this.setState({
            [name]: this.props.categories,
          });
        } else if (
          name == "category" &&
          this.state.category.length == this.props.categories.length
        ) {
          this.setState({
            [name]: [],
          });
        } else if (
          name == "competitor" &&
          this.state.competitor.length != this.props.competitors.length
        ) {
          this.setState({
            [name]: this.props.competitors,
          });
        } else if (
          name == "competitor" &&
          this.state.competitor.length == this.props.competitors.length
        ) {
          this.setState({
            [name]: [],
          });
        } else if (
          name == "brand" &&
          this.state.brand.length != this.props.brands.length
        ) {
          this.setState({
            [name]: this.props.brands,
          });
        } else if (
          name == "brand" &&
          this.state.brand.length == this.props.brands.length
        ) {
          this.setState({
            [name]: [],
          });
        } else if (
          name == "campaign" &&
          this.state.campaign.length != this.props.campaigns.length
        ) {
          this.setState({
            [name]: this.props.campaigns,
          });
        } else if (
          name == "campaign" &&
          this.state.campaign.length == this.props.campaigns.length
        ) {
          this.setState({
            [name]: [],
          });
        }
      }
    } else {
      this.setState({ [name]: selected, canSave: true });
    }
    this.disableInput(name, selected);
  };

  handleExport = () => {
    this.props.getExportProducts();
  };

  showAllTags = () => {
    this.setState({ truncate: false, searchedTags: this.props.tagList });
  };

  hideTags = () => {
    let truncatedArray = this.props.tagList;
    let shortList = truncatedArray.slice(0, 1);
    this.setState({ truncate: true, searchedTags: shortList });
  };

  handleLockedProducts = (selectedLockOption) => {
    this.props.updateProductFilterLockStatus(selectedLockOption);
  };

  clearFilters = () => {
    this.setState({
      category: [],
      brand: [],
      selectedTag: [],
      campaign: [],
      competitors: [],
      articleNumber: "",
      purchasePriceValue: "",
      purchasePriceOperator: {},
      salesPriceValue: "",
      salesPriceOperator: {},
      disableTextInput: false,
      disableDropdown: false,
      lockedProducts: false,
      approvedStatus: {},
    });
    this.props.updateProductFilterLockStatus("all");
    this.props.setFiltered(false);
    this.props.getProductData(1, 20, null, null);
  };

  buildQueryString = (items, name) => {
    let urlQuery = name;

    items.forEach((item, i) => {
      if (i + 1 < items.length) {
        urlQuery = urlQuery.concat(item.id + ",");
      } else {
        urlQuery = urlQuery.concat(item.id);
      }
    });
    return urlQuery;
  };

  disableInput = (name, selected) => {
    if (name === "articleNumber" && selected.length > 0) {
      return this.setState({
        disableDropdown: true,
        disableTextInput: false,
        selectedLockOption: "all",
      });
    }
    if (
      (name === "brand" ||
        name === "category" ||
        name === "campaign" ||
        name === "tags" ||
        name === "purchasePriceValue" ||
        name === "salesPriceValue" ||
        name === "purchasePriceOperator" ||
        name === "salesPriceOperator") &&
      (selected.length > 0 || Object.keys(selected).length > 0)
    ) {
      return this.setState({
        disableTextInput: true,
        disableDropdown: false,
      });
    }
    if (
      (name === "brand" ||
        name === "category" ||
        name === "campaign" ||
        name === "tags" ||
        name === "purchasePriceValue" ||
        name === "salesPriceValue" ||
        name === "purchasePriceOperator" ||
        name === "salesPriceOperator") &&
      (selected.length < 1 || Object.keys(selected).length < 1)
    ) {
      return this.setState({
        disableTextInput: false,
      });
    }
    if (name === "articleNumber" && selected.length < 1) {
      return this.setState({
        disableDropdown: false,
      });
    }
  };

  tooltipOnMouseEnter = () => {
    this.setState({
      hoverTooltip: true,
    });
  };
  tooltipOnMouseLeave = () => {
    this.setState({
      hoverTooltip: false,
    });
  };

  tagSelect = (tag) => {
    if (!this.state.disableDropdown) {
      if (
        this.state.selectedTag.find((selectedTag) => selectedTag.id === tag.id)
      ) {
        return this.removeSelectedTag(tag.id);
      } else {
        this.setState((state) => {
          const selectedTag = state.selectedTag.concat(tag);
          return {
            selectedTag,
          };
        });
      }
    }
  };

  removeSelectedTag = (id) => {
    let remove = this.state.selectedTag.filter((tag) => tag.id !== id);
    if (remove == undefined) {
      this.setState({ selectedTag: [] });
    } else {
      this.setState({ selectedTag: remove });
    }
  };

  handleSearchTags = (e) => {
    let tagList = [];
    let newListTags = [];

    let truncatedArray = this.props.tagList;
    let shortList = truncatedArray.slice(0, 1);
    //check if input is not empty
    if (e.target.value !== "") {
      this.setState({ searching: true });

      tagList = this.props.tagList;

      if (tagList.length <= 0) {
        this.state.truncate
          ? (tagList = shortList)
          : (tagList = this.props.tagList);
      }
      //to lowercase and find match
      newListTags = tagList.filter((tag) => {
        const tlc = tag.name.toLowerCase();
        const filter = e.target.value.toLowerCase();
        return tlc.includes(filter);
      });
      //otherwise return original list
    } else if (e.target.value === "") {
      this.setState({ searching: false });
      this.state.truncate
        ? (newListTags = shortList)
        : (newListTags = this.props.tagList);
    }
    this.setState({
      searchedTags: newListTags,
    });
  };

  handleChange = (event) => {
    this.disableInput(event.target.name, event.target.value);
    this.setState({
      [event.target.name]: event.target.value,

      canSave: true,
    });

    if (event.target.name === "purchasePriceValue") {
      this.setState({ purchasePriceValueError: false });
    }

    if (event.target.name === "salesPriceValue") {
      this.setState({ salesPriceValueError: false });
    }
  };

  handleFilterProducts = () => {
    let purchasePriceError = false;
    let salesPriceError = false;
    if (
      Object.keys(this.state.purchasePriceOperator).length < 1 &&
      this.state.purchasePriceValue.length > 0
    ) {
      purchasePriceError = true;
      this.setState({ purchasePriceOperatorError: true });
    }
    if (
      Object.keys(this.state.purchasePriceOperator).length > 0 &&
      this.state.purchasePriceValue.length < 1
    ) {
      purchasePriceError = true;
      this.setState({ purchasePriceValueError: true });
    }
    if (
      Object.keys(this.state.salesPriceOperator).length < 1 &&
      this.state.salesPriceValue.length > 0
    ) {
      salesPriceError = true;
      this.setState({ salesPriceOperatorError: true });
    }
    if (
      Object.keys(this.state.salesPriceOperator).length > 0 &&
      this.state.salesPriceValue.length < 1
    ) {
      salesPriceError = true;
      this.setState({ salesPriceValueError: true });
    }

    if (purchasePriceError || salesPriceError) {
      return;
    } else {
      this.props.clearSelections();
      let queryString = "";
      let categoryIds = this.buildQueryString(
        this.state.category,
        "&categoryIds="
      );
      let brandIds = this.buildQueryString(this.state.brand, "&brandIds=");
      let tagIds = this.buildQueryString(this.state.selectedTag, "&tagIds=");
      let campaignIds = this.buildQueryString(
        this.state.campaign,
        "&campaignIds="
      );
      let competitorIds = this.buildQueryString(
        this.state.competitor,
        "&competitorIds="
      );
      let articleNumber = "&search=".concat(this.state.articleNumber);

      let matchStatus = "&matchStatus=".concat(this.state.approvedStatus.name);

      if (this.state.category.length > 0) {
        queryString = queryString.concat(categoryIds);
      }
      if (this.state.brand.length > 0) {
        queryString = queryString.concat(brandIds);
      }
      if (this.state.selectedTag.length > 0) {
        queryString = queryString.concat(tagIds);
      }
      if (this.state.campaign.length > 0) {
        queryString = queryString.concat(campaignIds);
      }
      if (this.state.competitor.length > 0) {
        queryString = queryString.concat(competitorIds);
      }
      if (this.state.articleNumber.length > 0) {
        queryString = queryString.concat(articleNumber);
      }
      if (this.props.productFilterLockStatus === "locked") {
        queryString = queryString.concat("&locked=true");
      }
      if (this.props.productFilterLockStatus === "unlocked") {
        queryString = queryString.concat("&locked=false");
      }
      if (Object.keys(this.state.approvedStatus).length > 0) {
        queryString = queryString.concat(matchStatus);
      }
      if (
        Object.keys(this.state.purchasePriceOperator).length > 0 &&
        this.state.purchasePriceValue.length > 0
      ) {
        queryString = queryString.concat(
          "&purchasePriceOperator=" +
            this.state.purchasePriceOperator.name +
            "&purchasePriceValue=" +
            this.state.purchasePriceValue.toString()
        );
      }
      if (
        Object.keys(this.state.salesPriceOperator).length > 0 &&
        this.state.salesPriceValue.length > 0
      ) {
        queryString = queryString.concat(
          "&salesPriceOperator=" +
            this.state.salesPriceOperator.name +
            "&salesPriceValue=" +
            this.state.salesPriceValue.toString()
        );
      }
      if (this.state.articleNumber < 1) {
        this.props.filterProducts(1, 20, queryString, null, null);
      } else {
        this.props.filterBySkuProducts(
          1,
          20,
          "&search=".concat(encodeURIComponent(this.state.articleNumber)),
          null,
          null
        );
      }
    }
  };

  render() {
    let selectAll = [{ id: 0, name: "Select All" }];
    let tagList = [];
    if (this.state.truncate && !this.state.searching) {
      tagList = this.props.tagList.slice(0, 1);
    } else if (this.state.searching) {
      tagList = this.state.searchedTags;
    } else {
      tagList = this.props.tagList;
    }
    return (
      <>
        <div className="bg-white xxl:w-4/5 h-full w-full">
          <div className="flex-row flex w-full flex-wrap">
            <div className="px-2 w-1/5">
              <MultiSelectDropdown
                name="category"
                label="Categories"
                width="w-full"
                options={
                  this.state.disableDropdown
                    ? undefined
                    : this.props.categories &&
                      selectAll.concat(this.props.categories)
                }
                selected={this.state.category}
                handleSelect={this.handleSelected}
                placeholder="Select Categories"
              />
            </div>
            <div className="px-2 w-1/5">
              <MultiSelectDropdown
                name="brand"
                label="Brands"
                width="w-full"
                options={
                  this.state.disableDropdown
                    ? undefined
                    : this.props.brands && selectAll.concat(this.props.brands)
                }
                selected={this.state.brand}
                handleSelect={this.handleSelected}
                placeholder="Select Brands"
              />
            </div>

            <div className="px-2 w-1/5">
              <MultiSelectDropdown
                name="campaign"
                label="Campaigns"
                width="w-full"
                options={
                  this.state.disableDropdown
                    ? undefined
                    : this.props.campaigns.length > 0
                    ? selectAll.concat(this.props.campaigns)
                    : []
                }
                selected={this.state.campaign}
                handleSelect={this.handleSelected}
                placeholder="Select Campaigns"
              />
            </div>
            <div className="px-2 w-1/5">
              <MultiSelectDropdown
                name="competitor"
                label="Competitors"
                width="w-full"
                options={
                  this.props.competitors &&
                  selectAll.concat(this.props.competitors)
                }
                selected={this.state.competitor}
                handleSelect={this.handleSelected}
                placeholder="Select Competitors"
                disabled={"true"}
              />
            </div>

            <div className="px-2 w-1/5">
              <InputText
                label="Article Number"
                name="articleNumber"
                width="w-full"
                value={this.state.articleNumber}
                onChange={this.handleChange}
                placeholder="Article Number"
                disabled={this.state.disableTextInput ? true : false}
              />
            </div>
            <div className="flex flex-col w-1/5 pt-2 px-2 ">
              <div className="text-grey-dark font-light text-xs pb-1">
                Price
              </div>
              <div className="border-1 borer-grey-light flex flex-row pb-2">
                <div className="px-2">
                  <SingleSelectDropdown
                    name="salesPriceOperator"
                    label="Operator"
                    width="w-16"
                    options={
                      this.state.disableDropdown
                        ? []
                        : this.state.purchasePriceOperatorOptions
                    }
                    selected={this.state.salesPriceOperator}
                    handleSelect={this.handleSelected}
                    error={this.state.salesPriceOperatorError}
                  />
                </div>
                <div className="px-2 flex-1">
                  <InputNumber
                    label="Price"
                    name="salesPriceValue"
                    width="w-full"
                    value={this.state.salesPriceValue}
                    onChange={this.handleChange}
                    placeholder="Sales Price"
                    disabled={this.state.disableDropdown ? true : false}
                    error={this.state.salesPriceValueError}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col w-1/5 pt-2 pl-2 ">
              <div className="text-grey-dark font-light text-xs pb-1">
                Purchase Price
              </div>
              <div className="border-1 borer-grey-light flex flex-row pb-2">
                <div className="px-2 ">
                  <SingleSelectDropdown
                    name="purchasePriceOperator"
                    label="Operator"
                    width="w-16"
                    options={
                      this.state.disableDropdown
                        ? []
                        : this.state.purchasePriceOperatorOptions
                    }
                    selected={this.state.purchasePriceOperator}
                    handleSelect={this.handleSelected}
                    error={this.state.purchasePriceOperatorError}
                  />
                </div>
                <div className="px-2 flex-1">
                  <InputNumber
                    label="Price"
                    name="purchasePriceValue"
                    width="w-full"
                    value={this.state.purchasePriceValue}
                    onChange={this.handleChange}
                    placeholder="Price"
                    disabled={this.state.disableDropdown ? true : false}
                    error={this.state.purchasePriceValueError}
                  />
                </div>
              </div>
            </div>

            <div className="px-2 w-1/5 self-end">
              <SingleSelectDropdown
                name="approvedStatus"
                label="filter by approved status"
                width="w-full"
                placeholder="Select Approved Status"
                options={
                  this.state.disableDropdown ? [] : this.state.approvedOptions
                }
                selected={this.state.approvedStatus}
                handleSelect={this.handleSelected}
                // error={this.state.salesPriceOperatorError}
              />
            </div>
          </div>
          <div className="flex xxl:max-w-4/5 w-9/10 mb-2">
            <div className="px-2">
              <div className=" flex inline-block w-1/6 pt-2 h-17">
                <i className="material-icons xxl:text-2xl text-lg self-center text-grey-darker">
                  search
                </i>
                <input
                  className="appearance-none border-none cursor-pointer font-light text-sm focus:text-teal text-teal-dark input-placeholder focus:outline-none p-2"
                  placeholder="Tags"
                  onChange={(e) => this.handleSearchTags(e)}
                />
              </div>
              <div className="flex flex-row flex-wrap max-h-7 overflow-y-auto">
                {!this.state.searching ? (
                  this.state.truncate ? (
                    <div
                      onClick={this.showAllTags}
                      className="bg-table-grey my-1 text-grey-darker rounded-full flex items-center  px-2 xxl:mx-2 mx-1 flex whitespace-no-wrap hover:bg-teal hover:text-white  cursor-pointer"
                    >
                      <i className="material-icons xxl:text-lg text-md">
                        {" "}
                        more_horiz
                      </i>
                    </div>
                  ) : (
                    <div
                      onClick={this.hideTags}
                      className="bg-teal-dark text-white my-1 rounded-full flex items-center  px-2 xxl:mx-2 mx-1 flex whitespace-no-wrap hover:bg-teal  cursor-pointer"
                    >
                      <i className="material-icons xxl:text-lg text-md">
                        {" "}
                        more_horiz
                      </i>
                    </div>
                  )
                ) : null}
                {tagList.map((tag) => {
                  return (
                    <div
                      key={tag.id}
                      onClick={() => this.tagSelect(tag)}
                      className={`${
                        this.state.selectedTag.find(
                          (selectedTag) => selectedTag.id === tag.id
                        )
                          ? "bg-teal-dark text-white"
                          : "bg-table-grey text-grey-darker"
                      } flex flex-row my-1 items-center font-light xxl:text-sm text-xs rounded-full py-1 pr-2 pl-3 xxl:mx-2 mx-1 flex whitespace-no-wrap ${
                        this.state.selectedTag.find(
                          (selectedTag) => selectedTag.id === tag.id
                        )
                          ? "hover:bg-teal-darker hover:text-white "
                          : "hover:bg-teal hover:text-white"
                      } cursor-pointer`}
                    >
                      {tag.name}{" "}
                      <i className="material-icons xxl:text-lg text-md ml-1">
                        {this.state.selectedTag.find(
                          (selectedTag) => selectedTag.id === tag.id
                        )
                          ? "clear"
                          : "add"}
                      </i>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-end flex-wrap">
            <div>
              <div className="p-1 text-grey-dark font-sans font-light xxl:text-xs text-xxs">
                Filter products by
              </div>
              <div className="font-sans font-light xxl:text-sm text-xs">
                <div
                  onClick={() => this.handleLockedProducts("all")}
                  className={`inline-block rounded-l-full w-24 py-1 text-center cursor-pointer ${
                    this.props.productFilterLockStatus === "all"
                      ? "bg-teal-dark text-white"
                      : "bg-table-grey text-grey-darker hover:bg-teal hover:text-white"
                  }`}
                >
                  All
                </div>
                <div
                  onClick={() => this.handleLockedProducts("unlocked")}
                  className={`inline-block w-24 py-1 text-center cursor-pointer ${
                    this.props.productFilterLockStatus === "unlocked"
                      ? "bg-teal-dark text-white"
                      : "bg-table-grey text-grey-darker hover:bg-teal hover:text-white"
                  }`}
                >
                  Unlocked
                </div>
                <div
                  onClick={() => this.handleLockedProducts("locked")}
                  className={`inline-block rounded-r-full w-24 py-1 text-center cursor-pointer ${
                    this.props.productFilterLockStatus === "locked"
                      ? "bg-teal-dark text-white"
                      : "bg-table-grey text-grey-darker hover:bg-teal hover:text-white"
                  }`}
                >
                  Locked
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1 flex justify-end items-end">
            <div
              className="xxl:py-2 xxl:px-3 py-1 px-2 h-10 flex items-center justify-center xxl:text-sm text-xs text-teal-dark hover:text-teal mx-2"
              onClick={() => this.clearFilters()}
            >
              <span className="cursor-pointer font-light font-sans">CLEAR</span>
            </div>

            <div
              className="xxl:py-2 xxl:px-3 py-1 px-2 h-10 flex items-center justify-center bg-teal-dark hover:bg-teal xxl:text-sm text-xs text-white mx-2 rounded-sm cursor-pointer"
              onClick={() => this.handleFilterProducts()}
            >
              FILTER
            </div>

            <div className="pl-2 flex flex-row h-10">
              <div className="w-1 h-full relative ">
                {this.state.hoverTooltip && (
                  <div className="h-6 absolute pin-r mr-4 xxl:mt-1">
                    <TooltipRight text="EXPORT" />
                  </div>
                )}
              </div>

              <div
                onClick={() => this.handleExport()}
                onMouseEnter={this.tooltipOnMouseEnter}
                onMouseLeave={this.tooltipOnMouseLeave}
                className="h-full px-2 flex items-center justify-center text-white bg-teal-dark hover:bg-teal cursor-pointer rounded-sm"
              >
                <i className="material-icons">
                  {this.props.productLoading ? SmallLoadingSpinner : "save_alt"}
                </i>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end py-1">
            {this.props.productLoading && (
              <div className="text-grey-dark font-light xxl:text-sm text-xs">
                Please wait. Exporting may take a minute.
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBrandsByMarket: () => dispatch(getBrandsByMarket()),
    getCategoriesByMarket: () => dispatch(getCategoriesByMarket()),
    getAllTags: () => dispatch(getAllTags()),
    filterProducts: (page, rowSize, idList, attr, direction) =>
      dispatch(filterProducts(page, rowSize, idList, attr, direction)),
    filterBySkuProducts: (page, rowSize, idList, attr, direction) =>
      dispatch(filterBySkuProducts(page, rowSize, idList, attr, direction)),
    setFiltered: (val) => dispatch(setFiltered(val)),
    getProductData: (page, rowSize, attr, direction) =>
      dispatch(getProductData(page, rowSize, attr, direction)),
    getAllCampaignsDropdown: () => dispatch(getAllCampaignsDropdown()),
    getAllCompetitors: () => dispatch(getAllCompetitors()),
    getExportProducts: () => dispatch(getExportProducts()),
    updateProductFilterLockStatus: (lockStatus) =>
      dispatch(updateProductFilterLockStatus(lockStatus)),
  };
};

const mapStateToProps = (state) => {
  return {
    categories: state.products.categories,
    brands: state.products.brands,
    tagList: state.tags.tagList,
    campaigns: state.campaigns.campaignsDropdown,
    competitors: state.priceRule.competitorList,
    productLoading: state.exportData.productLoading,
    channelOptions: state.campaigns.channels,
    productFilterLockStatus: state.products.productFilterLockStatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductFilter);
