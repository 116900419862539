import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal } from "ducks/modal";
import { closeFormpanel } from "ducks/formpanel";
import { ModalContentWrapper } from "containers/Modals/ModalContainer/ModalContentWrapper";
import { submitTableInput } from "ducks/products";
import { getProductMatchingCompetitors } from "ducks/productMatching";

class ApproveProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canContinue: true
    };
  }

  handleReset = () => {
    this.props.getProductMatchingComp(this.props.data.id);
    this.props.data.clearData();
    this.props.closeModal();
  };

  handleApprove = () => {
    this.props.data.confirmData();
    this.props.closeModal();
  };

  render() {
    const modalConfig = {
      title: "Approve Product Changes ?",
      hasBottomButtons: true,
      hideSubmitButton: true,
      closeText: "Cancel",
      handleClose: this.props.closeModal,
      warning: false
    };
    return (
      <ModalContentWrapper modalConfig={modalConfig} size={this.props.size}>
        <div className="w-full text-center h-full flex flex-col justify-center items-center py-4">
          <div
            onClick={() => this.handleApprove()}
            className="text-white bg-teal-dark hover:bg-teal rounded w-48 cursor-pointer py-2 text-center my-4"
          >
            Approve Changes
          </div>
          <div
            onClick={() => this.handleReset()}
            className="text-white bg-red-dark hover:bg-red rounded w-48 cursor-pointer py-2 text-center my-4"
          >
            Reset Changes
          </div>
        </div>
      </ModalContentWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeFormpanel: () => dispatch(closeFormpanel()),
    closeModal: () => dispatch(closeModal()),
    submitTableInput: (id, col, value) => {
      dispatch(submitTableInput(id, col, value));
    },
    getProductMatchingComp: id => dispatch(getProductMatchingCompetitors(id))
  };
};

export default connect(null, mapDispatchToProps)(ApproveProduct);
